import { useState } from 'react'
import { useTranslation } from 'react-i18next'
// Hooks
import { useDebounce } from 'modules/core/hooks'
import { useInviteDelete, useInviteList } from 'modules/user/hooks'
// Components
import { IconButton, SearchInput } from 'modules/core/components'
import { UserInviteModal, UserInviteList } from 'modules/user/components'
// Constants
import { INVITES_PAGE_LIMIT } from 'modules/user/constants'

import {
  Wrapper,
  Root,
  HeaderRow,
  SearchWrapper,
  UserAddWrapper,
} from './Invites.styled'

export const Invites = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'admin' })
  const [dialogOpen, setDialogOpen] = useState(false)
  const [search, setSearch] = useState<string>('')
  const debounceSearchValue = useDebounce(search, 500)

  const deleteInvite = useInviteDelete()
  const invites = useInviteList({
    expand: ['issued_to'],
    page_limit: INVITES_PAGE_LIMIT,
    search_by: ['email'],
    search: debounceSearchValue,
  })

  const handleInviteDelete = (inviteId: string) => deleteInvite.mutate(inviteId)

  return (
    <Root>
      <Wrapper>
        <HeaderRow>
          <SearchWrapper>
            <SearchInput
              placeholder={t('context.search')}
              onChange={e => setSearch(e.target.value)}
            />
          </SearchWrapper>

          <UserAddWrapper onClick={() => setDialogOpen(true)}>
            {t('context.inviteUser')} <IconButton name='plus-circle' />
          </UserAddWrapper>
        </HeaderRow>
        <UserInviteList invites={invites} onInviteDelete={handleInviteDelete} />
      </Wrapper>
      <UserInviteModal open={dialogOpen} setOpen={setDialogOpen} />
    </Root>
  )
}
