import { styled } from '@mui/material'

export const Main = styled('div')`
  margin: ${({ theme }) => theme.spacing(2)};
  column-gap: ${({ theme }) => theme.spacing(2)};
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const ComponentItem = styled('div')`
  padding: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.palette.primary.main};
  height: 100%;
`

export const ProfileContainer = styled(ComponentItem)`
  flex: 1;
`

export const EditContainer = styled(ComponentItem)`
  flex: 3;
  height: 100%;
`
