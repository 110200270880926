import { css, FormControl, styled, TextField } from '@mui/material'

export const InputTextField = styled(TextField, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>`
  background-color: ${({ theme }) => theme.palette.darkest};

  & .MuiInputBase-input {
    padding: ${({ theme }) => theme.spacing(1)};
    font-size: 14px;
  }

  & .MuiInputBase-formControl {
    border-radius: 0;
    padding: 0;
  }

  & .MuiInputAdornment-root {
    width: 30px;
  }

  & .MuiOutlinedInput-notchedOutline {
    outline: none;
    border: none;
    border-radius: 0;
  }

  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.palette.error.main};
    `}
`

export const InputWrapper = styled('div')`
  width: 100%;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.75)};
  margin-bottom: ${({ theme }) => theme.spacing(0.75)};
`

export const FormControlWrapper = styled(FormControl)`
  flex: 3;
`

export const PlayerSelectorWrapper = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`
