import {
  AdminUserTeam,
  Team,
  TeamList,
} from '@sporttotal-tv/dip-coaching-client'
import { userMockup } from 'modules/user/utils/user.mockup'

export const teamsMockup: Team[] = [
  {
    id: '0a5ac8d3-63bb-48cf-9c1a-cfb7b03a228e',
    name: 'Principal Optimization Facilitator',
    logo_url: 'https://andy.org',
    club_id: '992cc866-b3a8-4f17-8d76-7359e0102507',
    created_at: 1655886764,
    updated_at: 1655886764,
    system_ref: '7ebf3688-70d4-46ed-858e-95d2829c22fd',
  },
  {
    id: '1af4364b-c625-415a-8ac1-3a0c15631110',
    name: 'District Accounts Strategist',
    logo_url: 'http://willard.org',
    club_id: 'f68a1498-b13c-4b19-a0db-9185fd5ce2f5',
    created_at: 1655886764,
    updated_at: 1655886764,
    system_ref: '5e5f56c7-2714-454c-83b0-d718fbbe3ae3',
  },
]

export const mockupTeamList: TeamList = {
  results: teamsMockup,
  total: 2,
}

export const adminTeamsMockup: AdminUserTeam[] = [
  {
    id: 'admin-team-id',
    team_id: teamsMockup[0].id,
    user_id: userMockup.id,
    user: userMockup,
    team: teamsMockup[0],
    created_at: 1655801910214,
    updated_at: 1655801910214,
  },
]
