import React, { useMemo } from 'react'
import { useDragLayer, XYCoord } from 'react-dnd'
import EventMarkGhost from './EventMarkGhost'
import { useReduxSelector } from 'modules/core/hooks'
import { selectGhostProperties } from 'modules/calendar/redux'

const layerStyles: React.CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
}

type TimeLineElement = {
  begin: number
  end: number
  y: number
  size: number
  hour: number
  minute: number
}

const CalendarBoardDragLayer = () => {
  const ghostProperties = useReduxSelector(selectGhostProperties)
  const { width } = ghostProperties

  const dayColumnGrid = useMemo(() => {
    const dayColumnArray = Array.from(
      document.querySelectorAll('[data-group="day-column"]')
    )

    return dayColumnArray.map<{
      column: number
      begin: number
      size: number
      date: Date
    }>((dom, ix: number) => {
      const dateAttr = dom.getAttribute('data-date')

      return {
        column: ix + 1,
        begin: dom.getBoundingClientRect().x,
        size: dom.getBoundingClientRect().x + dom.getBoundingClientRect().width,
        date: dateAttr ? new Date(dateAttr) : new Date(),
      }
    })
  }, [])

  //TODO: execute only once
  const timeLine = document.querySelectorAll('[data-group="time-line"]')

  const timeLineGrid = useMemo<TimeLineElement[]>(() => {
    const LINE_SIZE = 15

    const timeLinesArray = Array.from(
      document.querySelectorAll('[data-group="time-line"]')
    )

    return timeLinesArray
      .map((dom, ix: number) => {
        const position = dom.getBoundingClientRect().y - 2

        return Array(4)
          .fill(0)
          .map((_, quarterIndex: number) => {
            const begin = position + LINE_SIZE * quarterIndex
            return {
              begin: begin <= 166 ? 0 : begin,
              end: begin + LINE_SIZE,
              y: begin - 9,
              size: LINE_SIZE,
              hour: ix,
              minute: LINE_SIZE * quarterIndex,
            }
          })
      })
      .flat()
  }, [timeLine])

  function snapToXGrid(x: number) {
    let dataDate: Date | null = null

    dayColumnGrid &&
      dayColumnGrid.forEach(item => {
        if (x > item.begin && x < item.size) {
          x = item.begin
          dataDate = item.date
        }
      })

    return [x, dataDate]
  }

  function snapToYGrid(y: number) {
    let hour: number = 0
    let min: number = 0
    timeLineGrid &&
      timeLineGrid.forEach(item => {
        if (y >= item.begin && y < item.end) {
          y = item.y
          hour = item.hour
          min = item.minute
        }
      })
    return [y, hour, min]
  }

  let dataDate: number | null
  let dataHour: number
  let dataMin: number

  const getItemStyles = (
    initialOffset: XYCoord | null,
    currentOffset: XYCoord | null,
    clientOffset: XYCoord | null
  ) => {
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      }
    }

    let x: number | null = clientOffset?.x ?? 0
    let y = currentOffset?.y ?? 0

    x = x < 309.02 ? 309.015625 : x
    y = y < 157 ? 157 : y
    ;[x, dataDate] = snapToXGrid(x)
    ;[y, dataHour, dataMin] = snapToYGrid(y)

    const transform = `translate(${x}px, ${y}px)`

    return {
      transform,
      WebkitTransform: transform,
      x,
      y,
    }
  }

  const { itemType, isDragging, initialOffset, currentOffset, clientOffset } =
    useDragLayer(monitor => {
      return {
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        clientOffset: monitor.getClientOffset(),
        isDragging: monitor.isDragging(),
        w: width,
      }
    })

  const info = getItemStyles(initialOffset, currentOffset, clientOffset)

  function renderItem() {
    switch (itemType) {
      case 'box':
        return (
          <EventMarkGhost
            dataDate={dataDate}
            dataHour={dataHour}
            dataMin={dataMin}
          />
        )
      default:
        return null
    }
  }

  if (!isDragging) return null

  return (
    <div style={layerStyles}>
      <div style={info}>{renderItem()}</div>
    </div>
  )
}
export default CalendarBoardDragLayer
