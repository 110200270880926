import * as yup from 'yup'

export const teamPlayerStatusSchema = yup.object().shape({
  mathLoad: yup
    .string()
    .required('components:teamPlayers.modal.status.error.mathLoad'),
  trainingLoad: yup
    .string()
    .required('components:teamPlayers.modal.status.error.trainingLoad'),
  injurySusceptible: yup
    .string()
    .required('components:teamPlayers.modal.status.error.injurySusceptible'),
  health: yup
    .number()
    .typeError('components:teamPlayers.modal.status.error.health.required')
    .required('components:teamPlayers.modal.status.error.health.required')
    .min(0, 'components:teamPlayers.modal.status.error.health.invalid'),
  form: yup
    .number()
    .typeError('components:teamPlayers.modal.status.error.form.required')
    .required('components:teamPlayers.modal.status.error.form.required')
    .min(0, 'components:teamPlayers.modal.status.error.form.invalid'),
  overallRisk: yup
    .string()
    .required('components:teamPlayers.modal.status.error.overallRisk'),
})
