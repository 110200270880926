import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
// Hooks
import { useTranslation } from 'react-i18next'
import {
  FilterValues,
  useOutsideClick,
  useTagControls,
  useServerTranslatedProperty,
} from 'modules/core/hooks'
import { useEventList } from 'modules/event/hooks'
import { useUserSports, useUserCompetitions } from 'modules/user/hooks'
// Components
import { DatePicker } from '@mui/x-date-pickers'
import { Icon } from 'modules/core/components'
// Utils
import { format, parse } from 'date-fns'
// Types
import { TextFieldProps } from '@mui/material'
import { Sport } from '@sporttotal-tv/dip-coaching-client'
import { FilterModalProps } from './FilterModal.interface'

// Styled
import { TagsWrapper } from 'modules/core/styled'
import {
  Filter,
  ResetFilters,
  FilterTitle,
  FilterElementsWrapper,
  FilterElement,
  TagInput,
  FilterBlock,
  DateBlock,
  TagBlock,
  DateWrapper,
  DateTitle,
  DateLabel,
  ClickableTag,
  InputTextField,
} from './FilterModal.styled'

// TODO: make FilterModal more generic and show data based on filterData
export const FilterModal: FC<FilterModalProps> = ({
  open,
  filterData,
  setFilterData,
  resetFilter,
  fields,
}) => {
  const {
    tagValue,
    tags,
    setTagValue,
    handleTagRemove,
    handleKeyPress,
    handleResetTag,
  } = useTagControls()

  const { data: sports } = useUserSports()
  const [selectedSport, setSelectedSport] = useState<Sport>()
  const competitions = useUserCompetitions()
  const eventList = useEventList({ sport_id: selectedSport?.id })

  const modalRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation(['components', 'time'])
  const tServer = useServerTranslatedProperty()

  useEffect(() => {
    setFilterData({ type: 'sportId', value: selectedSport?.id })
    // eslint-disable-next-line
  }, [selectedSport])

  useEffect(() => {
    setFilterData({ type: 'tags', value: Array.from(tags) })
    // eslint-disable-next-line
  }, [tags])

  useEffect(() => {
    const hasOneSport = sports && sports.length === 1
    if (hasOneSport) setSelectedSport(sports[0])
  }, [sports])

  const handleOutsideClick = useCallback(() => {
    setTimeout(() => {
      setFilterData({
        type: 'filterOpen',
        value: false,
      })
    }, 100)
  }, [])

  useOutsideClick<HTMLDivElement>(modalRef, handleOutsideClick)

  const handleResetFilters = useCallback(() => {
    handleResetTag()
    resetFilter()
  }, [handleResetTag, resetFilter])

  const getDateValue = useCallback(
    (dateValue: FilterValues) =>
      typeof dateValue === 'string'
        ? parse(dateValue, 'MM/dd/yyyy', new Date())
        : null,
    []
  )

  const hasCompetitions = useMemo(() => {
    return !!(
      competitions?.data?.results && competitions?.data.results.length > 1
    )
  }, [competitions.data])

  const handleDataChange = useCallback(
    (changeType: string) => (newDate?: Date | null) => {
      const minimumDate = new Date('01/01/2000')
      if (
        !newDate ||
        newDate.toString() === 'Invalid Date' ||
        minimumDate > newDate
      ) {
        setFilterData({
          type: changeType,
          value: newDate === null ? '' : 'Invalid Date',
        })

        return
      }

      setFilterData({
        type: changeType,
        value: newDate ? format(newDate, 'MM/dd/yyyy') : '',
      })
    },
    [setFilterData]
  )

  const getDateRenderInput = useCallback(
    (dateValue: FilterValues) => (params: TextFieldProps) => {
      return (
        <InputTextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: params.InputProps?.endAdornment,
            endAdornment: undefined,
          }}
          error={dateValue === '' ? undefined : params.error}
        />
      )
    },
    []
  )

  const handleSelectSport = useCallback(
    (sport: Sport) => {
      setSelectedSport(prev => {
        const isSameSport = prev?.id === sport.id

        if (!isSameSport) {
          handleResetTag()
        }

        return isSameSport ? undefined : sport
      })
    },
    [handleResetTag]
  )

  const hasEvents = useMemo(
    () =>
      typeof eventList.data?.total === 'number' && eventList.data?.total > 0,
    [eventList.data?.total]
  )

  return (
    <Filter hidden={!open} ref={open ? modalRef : undefined}>
      <ResetFilters onClick={handleResetFilters}>
        {t('components:filter.form.filter.clearAll')}
      </ResetFilters>
      {fields.includes('date') && (
        <DateBlock>
          <DateWrapper>
            <DateLabel>
              <DateTitle>{t('time:startDate')}</DateTitle>
              <Icon name='chevron-small-down' />
            </DateLabel>

            {/*TODO: find a way to remove border by default on the data picker */}
            <DatePicker
              value={getDateValue(filterData.startDate)}
              onChange={handleDataChange('startDate')}
              renderInput={getDateRenderInput(filterData.startDate)}
            />
          </DateWrapper>
          <DateWrapper>
            <DateLabel>
              <DateTitle>{t('time:endDate')}</DateTitle>
              <Icon name='chevron-small-down' />
            </DateLabel>

            <DatePicker
              value={getDateValue(filterData.endDate)}
              onChange={handleDataChange('endDate')}
              renderInput={getDateRenderInput(filterData.endDate)}
            />
          </DateWrapper>
        </DateBlock>
      )}
      {fields.includes('tags') && (
        <TagBlock>
          <TagInput
            placeholder={t('components:filter.form.filter.searchTags')}
            value={tagValue}
            onKeyDown={handleKeyPress}
            onChange={e => setTagValue(e.target.value)}
          />
          <TagsWrapper>
            {Array.from(tags).map((tag, index) => (
              <ClickableTag onClick={handleTagRemove(tag)} key={index}>
                {tag}
              </ClickableTag>
            ))}
          </TagsWrapper>
        </TagBlock>
      )}
      {fields.includes('events') && (
        <>
          {sports && sports.length > 1 ? (
            <FilterBlock>
              <FilterTitle>
                {t('components:filter.form.filter.sport')}
              </FilterTitle>
              <FilterElementsWrapper>
                {sports.map(sport => (
                  <FilterElement
                    key={sport.id}
                    onClick={() => handleSelectSport(sport)}
                    selected={selectedSport?.id === sport.id}
                  >
                    {sport.name}
                  </FilterElement>
                ))}
              </FilterElementsWrapper>
            </FilterBlock>
          ) : null}
          {selectedSport && hasEvents && (
            <FilterBlock>
              <FilterTitle>
                {t('components:filter.form.filter.events')}
              </FilterTitle>
              <FilterElementsWrapper>
                {eventList.data?.results.map(event => (
                  <FilterElement
                    key={event.id}
                    onClick={() =>
                      setFilterData({ type: 'events', value: event.id })
                    }
                    // @ts-ignore
                    selected={filterData.events?.has(event.id)}
                  >
                    {tServer(event.name)}
                  </FilterElement>
                ))}
              </FilterElementsWrapper>
            </FilterBlock>
          )}
        </>
      )}
      {fields.includes('liga') && hasCompetitions && (
        <FilterBlock>
          <FilterTitle>
            {t('components:filter.form.filter.competition')}
          </FilterTitle>
          <FilterElementsWrapper>
            {/*TODO: revert when duplicates issue is fixed*/}
            {competitions.data?.results?.map((competitionItem, index) => (
              <FilterElement
                key={`${competitionItem.id}-${index}`}
                selected={filterData.competition === competitionItem.id}
                onClick={() =>
                  setFilterData({
                    type: 'competition',
                    value: competitionItem.id,
                  })
                }
              >
                {competitionItem.name}
              </FilterElement>
            ))}
          </FilterElementsWrapper>
        </FilterBlock>
      )}
    </Filter>
  )
}
