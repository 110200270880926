import { parseSystemRef } from 'modules/core/helpers'

export const isInternalMatch = (systemRef: string) => {
  try {
    const { service } = parseSystemRef(systemRef)
    return service === 'schedule-service'
  } catch (err) {
    return false
  }
}
