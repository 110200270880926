import { Asset } from '@sporttotal-tv/dip-coaching-client'
import { AssetUpdateParams } from 'modules/asset/hooks'

type Collection = {
  results: Array<{ assets?: Array<Asset> }>
}

export const updateAssetsInCollection = <T extends Collection>(
  collection: T,
  data: AssetUpdateParams
): T['results'] => {
  return collection.results.map(collectionData => {
    const reducedAssets =
      collectionData.assets?.reduce<Asset[]>((newAssets, oldDataAsset) => {
        if (oldDataAsset.id === data.id) {
          return [...newAssets, { ...oldDataAsset, ...data.params }]
        }

        return [...newAssets, oldDataAsset]
      }, []) ?? []

    return {
      ...collectionData,
      assets: reducedAssets,
    }
  })
}
