import { FC, PropsWithChildren, ReactNode } from 'react';
import { CardProps } from '@mui/material/Card';
import { StyledCard } from '.';
import { CardContent, CardMedia } from '@mui/material';

interface Props extends PropsWithChildren {
  image?: ReactNode;
  body?: ReactNode;
}

export const Card: FC<CardProps & Props> = ({
  image = null,
  body,
  children,
  ...cardProps
}) => {
  if (children) return <StyledCard {...cardProps}>{children}</StyledCard>;

  return (
    <StyledCard {...cardProps}>
      {image && <CardMedia>{image}</CardMedia>}
      <CardContent sx={{ padding: 0 }}>{body}</CardContent>
    </StyledCard>
  );
};
