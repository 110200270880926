import { forwardRef } from 'react'
import { Search } from '@mui/icons-material'
import { InputProps } from '@mui/material'

import { SearchInputComponent, SearchIconAdornment } from './SearchInput.styled'

export const SearchInput = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => (
    <SearchInputComponent
      ref={ref}
      disableUnderline
      startAdornment={
        <SearchIconAdornment position='start'>
          <Search />
        </SearchIconAdornment>
      }
      {...props}
    />
  )
)
