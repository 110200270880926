import { FC, ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// components
import { AppBar } from 'modules/core/components'
import { LoaderContainer } from 'modules/core/components'
// constants
import { teamLayoutKeys, teamPageDefaultTabStore } from 'modules/team/constants'
// hooks
import { useTabStore } from 'modules/analyser/hooks'
import { useTabData } from 'modules/analyser/hooks'
import { useUserStorage } from 'modules/user/hooks'

import { Main } from './TeamPageLayout.styled'

const defaultTeamTabPath = 'dashboard'

export const TeamPageLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const { tabs, addTab, removeTab, renameTab, switchTab } = useTabStore({
    tabsKey: teamLayoutKeys.tabsKey,
    tabsDataKey: teamLayoutKeys.tabsDataKey,
    layoutTypesKey: teamLayoutKeys.layoutTypesKey,
    defaultTabPath: defaultTeamTabPath,
    defaultTabStore: teamPageDefaultTabStore,
  })
  const { currentTabPath, rootPath } = useTabData()
  const userTabs = useUserStorage({ keys: [teamLayoutKeys.tabsKey] })

  const navigate = useNavigate()

  useEffect(() => {
    if (
      !tabs.map(tab => tab.path).includes(currentTabPath) &&
      !userTabs.isLoading &&
      tabs.length !== 0
    ) {
      navigate(`${rootPath}/dashboard`)
    }
  }, [tabs, currentTabPath, rootPath, navigate, userTabs.isLoading])

  return (
    <>
      <AppBar
        editable
        tabs={tabs}
        addTab={addTab}
        removeTab={removeTab}
        renameTab={renameTab}
        clickTab={switchTab}
      />
      <LoaderContainer isLoading={userTabs.isLoading} transitionDelay={0.5}>
        <Main>{children}</Main>
      </LoaderContainer>
    </>
  )
}
