import { useState, useEffect, SyntheticEvent } from 'react'
import { MediaPlayer } from 'modules/media-render/types/media-render.types'

export const usePreciseDuration = (player?: MediaPlayer) => {
  const [preciseDuration, setPreciseDuration] = useState(0)

  useEffect(() => {
    if (!player) return

    const handleDurationUpdate = (event: SyntheticEvent<HTMLVideoElement>) =>
      setPreciseDuration(event.currentTarget.duration)

    player.addEventListener(
      'durationchange',
      handleDurationUpdate as () => void
    )
    player.addEventListener('abort', handleDurationUpdate as () => void)

    return () => {
      player?.removeEventListener(
        'durationchange',
        handleDurationUpdate as () => void
      )
      player?.removeEventListener('abort', handleDurationUpdate as () => void)
    }
  }, [player])

  return preciseDuration
}
