import { useTranslation } from 'react-i18next'
import { Text } from 'modules/landing/components/shared/Text/Text'
import {
  FlexColumn,
  FlexRow,
} from 'modules/landing/components/shared/Flex/styled'
import { ContactUsForm } from 'modules/landing/components/shared/ContactUsForm/ContactUsForm'
import { BREAKPOINTS } from 'modules/landing/constants'
import { useAdaptive } from 'modules/landing/hooks/useAdaptive'
import { useTheme } from '@mui/material'

import background from 'modules/landing/assets/backgrounds/contact-us-bg.png'

export const ContactUsSection = () => {
  const theme = useTheme()

  const { t } = useTranslation(['pages'])

  const isMobile = useAdaptive(0, BREAKPOINTS.mobile)
  const isTablet = useAdaptive(BREAKPOINTS.mobile, BREAKPOINTS.tablet)

  return (
    <FlexRow
      id='contact'
      sx={{
        width: '100%',
        height: isTablet || isMobile ? 'auto' : '640px',
        background: `url(${background})`,
        backgroundSize: 'cover',
        alignItems: 'center',
        justifyContent: 'center',
        padding: isMobile || isTablet ? '40px' : '0',
      }}
    >
      <FlexColumn
        sx={{
          width: '811px',
          transform: 'translateY(40px)',
        }}
      >
        <Text
          sx={{
            font: 'normal normal normal 40px/44px Bebas Neue',
            letterSpacing: '0',
            color: theme.palette.secondary.contrastText,
            textAlign: isMobile || isTablet ? 'center' : 'left',
          }}
        >
          {t(`landing.sections.contact_us.title`)}
        </Text>
        <FlexRow
          sx={{
            width: '100%',
            marginTop: '20px',
            gap: '25px',
            flexDirection: isTablet || isMobile ? 'column !important' : 'row',
          }}
        >
          <FlexColumn
            sx={{
              width: isTablet || isMobile ? '100%' : '50%',
              padding: isTablet || isMobile ? '20px 0' : '0',
            }}
          >
            <Text
              sx={{
                font: "normal normal normal 16px/22px 'Exo 2'",
                letterSpacing: '0',
                color: theme.palette.secondary.contrastText,
                textAlign: isMobile || isTablet ? 'center' : 'left',
                width: isMobile || isTablet ? '100%' : 'auto',
              }}
            >
              {t(`landing.sections.contact_us.text`)}
            </Text>
          </FlexColumn>
          <FlexColumn
            sx={{
              width: isMobile || isTablet ? '100%' : '50%',
              alignItems: 'center',
            }}
          >
            <ContactUsForm />
          </FlexColumn>
        </FlexRow>
      </FlexColumn>
    </FlexRow>
  )
}
