import {
  AssetListParams,
  CreateAssetBody,
  Asset,
  AssetList,
  UpdateAssetBody,
} from '@sporttotal-tv/dip-coaching-client'
import { api } from 'modules/core/api'
import {
  transformApiResults,
  transformIdApiResults,
} from 'modules/core/helpers'

export const AssetApiService = {
  retrieve: transformApiResults<string, Asset>(api.asset.retrieve),
  create: transformApiResults<CreateAssetBody, Asset>(api.asset.create),
  list: transformApiResults<AssetListParams, AssetList>(api.asset.list),
  delete: transformApiResults<string, Asset>(api.asset.delete),
  update: transformIdApiResults<UpdateAssetBody, Asset>(api.asset.update),
}
