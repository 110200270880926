import { css, styled } from '@mui/material'

export const Root = styled('div')`
  height: 100%;
`

export const PlayerWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'cursor' && prop !== 'isLoadingShape',
})<{ cursor?: string; isLoadingShape?: boolean }>`
  // TODO: 46px should be in const change when fixed issue with tests
  height: calc(100% - 46px);
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.darkest};
  overflow: hidden;
  flex-direction: column;

  ${({ isLoadingShape }) =>
    isLoadingShape &&
    css`
      cursor: wait;
    `}

  ${({ cursor, isLoadingShape }) =>
    cursor &&
    !isLoadingShape &&
    css`
      cursor: ${cursor};
    `}
`

export const ControlsWrapper = styled('div')`
  padding: ${({ theme }) => theme.spacing(1, 0)};
  width: 100%;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  justify-content: space-between;
  align-items: center;
`

export const FillSpace = styled('div')`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: ${({ theme }) => theme.spacing(1.5)};
`

export const transformStyles = {
  width: '100%',
  height: '100%',
}
