import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
// Hooks
import { useMatch } from 'modules/match/hooks'
// Components
import { OptionsSelectorMenuItem } from 'modules/core/styled'
import { ToolbarControls, ToolbarHeader } from 'modules/generic/components'
import { LayoutItemToolbarContainer } from 'modules/layout/components/LayoutItemToolbar/LayoutItemToolbar.styled'
import { ToolbarDivider } from 'modules/generic/styled'
import { VideoPlayerSelector } from 'modules/video-player/components'
// Context
import { TeamContext } from 'modules/team/context'
// Types
import { IToolbarComponent } from 'modules/layout/components/LayoutItem/LayoutItem.interface'
import { Team as TeamType } from 'modules/team/types'

import { ToolbarContainer, TeamsOptionsSelector } from './TeamToolbar.styled'

const TeamToolbar: IToolbarComponent = ({ layoutIndex, layoutId }) => {
  const { t } = useTranslation('core', { keyPrefix: 'team' })
  const { team, setTeam } = useContext(TeamContext)
  const { match } = useMatch()

  const handleTeamSelect = (team: TeamType) => setTeam && setTeam(team)

  const teams = [
    {
      value: 'teamA',
      label: t('home'),
    },
    {
      value: 'teamB',
      label: t('away'),
    },
  ]

  return (
    <LayoutItemToolbarContainer>
      <ToolbarHeader layoutId={layoutId} layoutIndex={layoutIndex}>
        <ToolbarDivider />
        <VideoPlayerSelector />
      </ToolbarHeader>
      <ToolbarContainer>
        {match && (
          <TeamsOptionsSelector
            value={team}
            onChange={({ target }) =>
              handleTeamSelect(target.value as TeamType)
            }
          >
            {teams.map(({ value, label }) => (
              <OptionsSelectorMenuItem value={value}>
                {label}
              </OptionsSelectorMenuItem>
            ))}
          </TeamsOptionsSelector>
        )}
      </ToolbarContainer>
      <ToolbarControls layoutId={layoutId} layoutIndex={layoutIndex} />
    </LayoutItemToolbarContainer>
  )
}

export { TeamToolbar }
