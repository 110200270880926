import { Autocomplete, Popper, TextField, styled, css } from '@mui/material'

// @TODO: Use this autocomplete in all places
export const StyledAutocomplete = styled(Autocomplete, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>`
  & .MuiTextField-root {
    background-color: ${({ theme }) => theme.palette.darkest};
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiAutocomplete-endAdornment {
    display: flex;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
  }

  & .MuiOutlinedInput-root {
    padding: 0;
  }

  ${({ error, theme }) =>
    error &&
    css`
      & .MuiOutlinedInput-root {
        border: 1px solid ${theme.palette.error.main};
      }
    `}
`

export const StyledPopper = styled(Popper)`
  & .MuiPaper-root {
    background-color: ${({ theme }) => theme.palette.primary.dark};
    box-shadow: ${({ theme }) => theme.shadow.main};
  }

  & .MuiAutocomplete-listbox {
    background-color: ${({ theme }) => theme.palette.primary.dark};
  }

  & .MuiAutocomplete-option {
    background-color: ${({ theme }) => theme.palette.primary.dark};
    font-size: 14px;
  }
`

export const InputTextField = styled(TextField)`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  border: none;

  & .MuiInputBase-input {
    padding: ${({ theme }) => theme.spacing(1)};
    font-size: 14px;
    border: none;
  }

  & .MuiInputBase-formControl {
    border-radius: 0;
    border: none;
    padding: 0;
  }

  & .MuiInputAdornment-root {
    width: 30px;
  }
`
