import { useCallback, FC } from 'react'
import { useTranslation } from 'react-i18next'
// Hooks
import { useOrganizationAdminUserDelete } from 'modules/organization-admin/hooks'

import {
  DisplayIcon,
  DisplayItem,
  DisplayItemText,
  DisplayMenu,
} from './OrganizationUserList.styled'
import { UserContextMenuProps } from './OrganizationUserList.interface'

export const UserContextMenu: FC<UserContextMenuProps> = ({
  organizationId,
  user,
  open,
  onClose,
  anchorEl,
}) => {
  const { t } = useTranslation(['pages', 'userRole'])
  const adminUserUpdate = useOrganizationAdminUserDelete(organizationId)

  const handleOrganizationUserDelete = useCallback(() => {
    adminUserUpdate.mutate(user.organizationUserId)
    onClose()
  }, [user, adminUserUpdate, onClose])

  return (
    <DisplayMenu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <DisplayItem onClick={handleOrganizationUserDelete}>
        <DisplayIcon name='delete' />
        <DisplayItemText>{t('pages:admin.button.delete')}</DisplayItemText>
      </DisplayItem>
    </DisplayMenu>
  )
}
