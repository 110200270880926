import { useCallback, useEffect, useState } from 'react'

const EXCLUDED_TARGET_ELEMENTS = [
  HTMLInputElement,
  HTMLSelectElement,
  HTMLTextAreaElement,
]

type UseKeyOptions = (
  keys: number | number[],
  callback: (key: number) => void | Promise<number>,
  options?: { disabled?: boolean }
) => { error: Error | undefined }

export const useKey: UseKeyOptions = (keys, callback, options = {}) => {
  const [error, setError] = useState<Error>()

  const handleKeyPress = useCallback(
    async (event: KeyboardEvent) => {
      if (EXCLUDED_TARGET_ELEMENTS.some(ele => event.target instanceof ele))
        return

      try {
        setError(undefined)

        const allKeys = Array.isArray(keys) ? keys : [keys]
        const isKeyPressed = allKeys.includes(event.keyCode)

        if (isKeyPressed) await callback(event.keyCode)
      } catch (err) {
        setError(err as Error)
      }
    },
    [callback, keys]
  )

  useEffect(() => {
    if (options.disabled) return
    document.addEventListener('keyup', handleKeyPress)

    return () => document.removeEventListener('keyup', handleKeyPress)
  }, [options.disabled, handleKeyPress])

  return { error }
}
