import { createContext } from 'react'

import {
  MatchPeriodContextProps,
  MatchPeriodContextProviderProps,
} from './MatchPeriodContext.interface'

export const MatchPeriodContext = createContext<MatchPeriodContextProps>({
  matchPeriod: 'first_half',
})

export type { MatchPeriodContextProviderProps, MatchPeriodContextProps }
