import { styled, css, IconButton, Typography } from '@mui/material'
import { convertSecondsToTime } from 'modules/core/utils'
import { FixedSizeList } from 'react-window'

export const Timeline = styled('div')`
  flex: 1;
  overflow-x: hidden;
`

export const StyledFixedSizeList = styled(FixedSizeList, {
  shouldForwardProp: prop => prop !== 'offset' && prop !== 'rightOffset',
})<{ offset: number; rightOffset: number }>`
  overflow: hidden !important;
  will-change: unset !important;

  & > div {
    position: relative;
    margin-left: ${({ offset }) => offset}px;
    margin-right: ${({ rightOffset }) => rightOffset}px;
  }
`

export const TimelineNotch = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'timestamp' && prop !== 'showTimestamp' && prop !== 'hidden',
})<{
  timestamp: number
  showTimestamp: boolean
}>`
  height: 15%;
  position: relative;

  // We use pesudo elements with absolute to remove the width from the content flow,
  // Each notch as a relative element, will add 1px of inaccuracy.
  // E.g the 30th notch on the timeline would have an inaccuracy of 30px
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 20%;
    border-right: 1px solid ${({ theme }) => theme.palette.secondary.light};
  }

  ${({ showTimestamp, timestamp, theme }) =>
    showTimestamp &&
    css`
      height: 20%;

      &:after {
        content: '${convertSecondsToTime(timestamp)}';
        font-size: 10px;
        font-weight: 500;
        color: ${theme.palette.primary.light};
        position: absolute;
        transform: translate(-50%, 120%);
      }
    `}

  ${({ hidden }) =>
    hidden &&
    css`
      opacity: 0;
    `}
`

export const TimelineNotchContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'offset',
})<{ offset: number }>`
  position: absolute;
  top: 0;
  left: ${({ offset }) => offset}%;
  transform: translate(-50%, 0);
`

export const AmountOfFrames = styled(Typography)`
  position: absolute;
  right: 0;
  top: 0;
  font-size: 8px;
  line-height: 8px;
  color: ${({ theme }) => theme.palette.secondary.main};
  transform: translate(100%, -10%);
`

export const TelestrationFrameButton = styled(IconButton)`
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.palette.primary.light};
  padding: ${({ theme }) => theme.spacing(0.5)};

  & path {
    fill: ${({ theme }) => theme.palette.common.white};
  }

  &::before {
    transition: border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -2px);
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: ${({ theme }) => theme.palette.primary.light} transparent
      transparent transparent;
  }

  &:hover {
    &::before {
      border-color: #484949 transparent transparent transparent;
    }
  }

  &:disabled {
    background-color: ${({ theme }) => theme.palette.darkest};

    &::after {
      position: absolute;
      content: '';
      left: 0;
      top: 50%;
      width: 60%;
      height: 1px;
      background-color: ${({ theme }) => theme.palette.common.white};
      border-radius: 2px;
      transform: translateX(30%) rotate(-130deg);
    }

    &::before {
      border-color: ${({ theme }) => theme.palette.darkest} transparent
        transparent transparent;
    }
  }
`
