import { FC, useCallback, MouseEvent } from 'react'
import { Popover } from '@mui/material'
// Hooks
import { useAnchor } from 'modules/core/hooks'
// Components
import { Icon } from 'modules/core/components'
import { Tooltip } from 'modules/core/styled'

import {
  TelestrationIconButton,
  TelestrationActionWrapper,
  Triangle,
  SubActionsWrapper,
} from './TelestrationToolbar.styled'
import {
  TelestrationToolItem,
  TelestrationToolSelectProps,
} from './TelestrationToolbar.interface'

export const TelestrationToolSelect: FC<TelestrationToolSelectProps> = ({
  action: {
    main: { name, icon, onClick, tool: toolName, active },
    subActions,
  },
  tool,
  disabled = false,
}) => {
  const { anchor, isOpen, handleOpen, handleClose } = useAnchor()

  const handleSubActionClick = useCallback(
    (subAction: TelestrationToolItem) => (e: MouseEvent<HTMLButtonElement>) => {
      if (subAction.active) {
        subAction.onClick(e)
        handleClose()
      }
    },
    [handleClose]
  )

  const mainActionClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      const isToolSelected = toolName && toolName === tool
      if (isToolSelected) {
        onClick(e)
        return
      }

      if (active) {
        handleOpen(e)
      }
    },
    [active, handleOpen, tool, toolName, onClick]
  )

  if (subActions && subActions?.length > 0) {
    return (
      <TelestrationActionWrapper>
        <TelestrationIconButton
          disabled={!active || disabled}
          onClick={mainActionClick}
          size='small'
        >
          <Icon
            name={icon}
            filter={
              toolName && !disabled && toolName === tool ? 'brightness(2)' : ''
            }
          />
          <Triangle active={toolName && toolName === tool} />
        </TelestrationIconButton>
        <Popover
          open={isOpen}
          anchorEl={anchor}
          onClose={handleClose}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <SubActionsWrapper>
            {subActions.map((subAction, index) => (
              <Tooltip
                key={subAction.name}
                arrow
                title={subAction.name}
                placement='bottom'
              >
                <span>
                  <TelestrationIconButton
                    key={`${subAction.name}-${index}`}
                    disabled={!subAction.active}
                    onClick={handleSubActionClick(subAction)}
                    size='small'
                  >
                    <Icon name={icon} />
                  </TelestrationIconButton>
                </span>
              </Tooltip>
            ))}
          </SubActionsWrapper>
        </Popover>
      </TelestrationActionWrapper>
    )
  }

  return (
    <Tooltip arrow title={name} placement='top'>
      <span>
        <TelestrationIconButton
          disabled={!active || disabled}
          onClick={active ? onClick : undefined}
          size='small'
        >
          <Icon
            name={icon}
            filter={
              toolName && !disabled && toolName === tool ? 'brightness(2)' : ''
            }
          />
        </TelestrationIconButton>
      </span>
    </Tooltip>
  )
}
