import { ShapeHandler } from 'modules/annotator/types'
import {
  ShapeOptions,
  TelestrationPlayerSelection,
} from 'modules/telestration/types'

const PlayerSelection: ShapeHandler<TelestrationPlayerSelection> = {
  onCreate({ currentTarget }, options?: ShapeOptions) {
    const points = currentTarget.getStage()?.getPointerPosition()
    const X = points?.x ?? null
    const Y = points?.y ?? null

    if (!X || !Y) return

    return {
      ...options,
      type: 'PLAYER_SELECTION',
      x: X - 20,
      y: Y,
      fill: options?.telestrationColor,
      width: 160,
      height: 70,
      scaleX: 0.25,
      scaleY: 0.25,
    }
  },

  // TODO: for this tool there shouldn't be any shaping
  onShaping(rect, { currentTarget }) {
    const point = currentTarget.getStage()?.getPointerPosition()
    const X = point?.x ?? null
    const Y = point?.y ?? null

    if (!X || !Y) return

    return { ...rect, width: 160, height: 70, scaleX: 0.25, scaleY: 0.25 }
  },

  onShapeUpdated({ currentTarget }) {
    return currentTarget
  },
}

export { PlayerSelection }
