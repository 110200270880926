import { CameraRetreiveData } from '@sporttotal-tv/dip-coaching-client'

export const parseCameraNetworkInterface = (
  health: CameraRetreiveData['health']
): { networkInterface: string; speed: number } => {
  if (!health?.interface_speed) return { networkInterface: '', speed: 0 }

  const entries = Object.entries(health.interface_speed)
  const [networkInterface, speed] = entries.reduce<[string, number]>(
    (acc, [key, value]) => (value > acc[1] ? [key, value] : acc),
    entries.at(0) || ['', 0]
  )

  return { networkInterface, speed }
}
