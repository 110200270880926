import { styled } from '@mui/material'
import { motion } from 'framer-motion'

export const StyledMotionDiv = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 3000px) {
    position: absolute;
    left: calc(100% - 338px);
    width: auto;
    top: 80px;
    z-index: 100;
  }

  @media (max-width: 1000px) {
    position: fixed;
    z-index: 101;
    top: 0;
    left: 0;
    transform: scale(1.5);
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 480px) {
    transform: scale(1);
  }
`
