import { KeyboardEvent, useCallback, useState } from 'react'

export const useTagControls = (startValues?: string[]) => {
  const [tagValue, setTagValue] = useState('')
  const [tags, setTags] = useState<Set<string>>(new Set(startValues))

  const handleKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        const newTags = tagValue.split(' ').filter(tag => tag !== '')
        setTags(prev => new Set([...Array.from(prev), ...newTags]))
        setTagValue('')
      }
    },
    [tagValue]
  )

  const handleTagRemove = useCallback(
    (tag: string) => () => {
      setTags(prev => {
        const newTags = new Set(prev)
        newTags.delete(tag)
        return newTags
      })
    },
    []
  )

  const handleTagAdd = useCallback(
    (tag: string) => () => {
      setTags(prev => {
        const newTags = new Set(prev)
        newTags.add(tag)
        return newTags
      })
    },
    []
  )

  const handleResetTag = useCallback(() => {
    setTags(new Set())
  }, [])

  return {
    tagValue,
    tags,
    setTagValue,
    handleKeyPress,
    handleTagRemove,
    handleResetTag,
    handleTagAdd,
  }
}
