import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import {
  FlexColumn,
  FlexRow,
} from 'modules/landing/components/shared/Flex/styled'
import { Text } from 'modules/landing/components/shared/Text/Text'
import { StyledDeviceImage, StyledFeaturesImage } from './styled'
import { texts, features } from './utils'
import { useTheme } from '@mui/material'

import featuresImg from 'modules/landing/assets/features.png'

export const FeaturesSection = () => {
  const theme = useTheme()

  const { t } = useTranslation(['pages'])

  const isSmallDesktop = useMediaQuery({
    maxWidth: `${1350}px`,
  })

  const isTablet = useMediaQuery({
    maxWidth: `${1065}px`,
  })

  const wrapperPadding = `${isSmallDesktop ? '15px' : '87px'}`

  return (
    <FlexColumn
      id='features'
      sx={{
        width: `100%`,
        height: !isTablet ? '1101px' : 'auto',
        paddingBottom: !isTablet ? 0 : '40px',
        background: theme.palette.darkest,
        zIndex: -1,
        alignItems: 'center',
      }}
    >
      <FlexColumn
        sx={{
          padding: `124px ${wrapperPadding} 0 ${wrapperPadding}`,
          alignItems: 'center',
          gridGap: 35,
        }}
      >
        <Text
          variant='h2'
          sx={{
            font: 'normal normal normal 28px/24px Bebas Neue',
            fontWeight: '800',
            letterSpacing: '0',
            textAlign: 'center',
            color: theme.palette.primary.contrastText,
            width: 'fit-content',
            marginLeft: !isTablet ? '-50px' : '0',
            marginTop: !isTablet ? '-14px' : '0',
          }}
        >
          {t(`landing.sections.features.title`)}
        </Text>

        <FlexRow sx={{ justifyContent: 'center', width: '100%' }}>
          {!isTablet && (
            <FlexColumn sx={{ alignItems: 'end', flex: 1, width: '60%' }}>
              <StyledDeviceImage src={featuresImg} />
            </FlexColumn>
          )}

          <FlexColumn
            sx={{
              width: !isTablet ? '50%' : '100%',
              alignItems: !isTablet ? 'start' : 'center',
              justifyContent: 'center',
              flex: 1,
              gap: '59px',
            }}
          >
            {texts.map((_, i) => (
              <FlexRow
                key={`feature-text-${i}`}
                sx={{
                  maxWidth: '574px',
                  gap: '28px',
                  alignItems: 'center',
                  height: '73px',
                }}
              >
                <StyledFeaturesImage src={features[i]} />
                <FlexColumn
                  sx={{
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  <Text
                    sx={{
                      font: 'normal normal normal 28px/24px Bebas Neue',
                      textTransform: 'uppercase',
                      fontWeight: '600',
                      color: theme.palette.secondary.main,
                    }}
                  >
                    {t(`landing.sections.features.item.${i}.title`)}
                  </Text>
                </FlexColumn>
              </FlexRow>
            ))}
          </FlexColumn>
        </FlexRow>
      </FlexColumn>
    </FlexColumn>
  )
}
