import { useState, useEffect, SyntheticEvent } from 'react'
import { MediaPlayer } from 'modules/media-render/types/media-render.types'

export const usePreciseCurrentTime = (player?: MediaPlayer) => {
  const [preciseCurrentTime, setPreciseCurrentTime] = useState(0)

  useEffect(() => {
    if (!player) return

    const handleTimeUpdate = (event: SyntheticEvent<HTMLVideoElement>) =>
      setPreciseCurrentTime(event.currentTarget.currentTime)

    player.addEventListener('timeupdate', handleTimeUpdate as () => void)

    return () =>
      player?.removeEventListener('timeupdate', handleTimeUpdate as () => void)
  }, [player])

  return preciseCurrentTime
}
