import { FC, ReactNode } from 'react'
import { AppBar } from 'modules/core/components'
import { Main } from './SubscriptionPageLayout.styled'

export const SubscriptionPageLayout: FC<{ children: ReactNode }> = ({
  children,
}) => (
  <>
    <AppBar tabs={[]} />
    <Main>{children}</Main>
  </>
)
