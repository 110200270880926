import { ApiService } from 'services/Api.service'
import { createMutationHookWithBody } from 'modules/core/api/hooks/utils'
import { ORGANIZATION_ADMIN_USER_INVITE_LIST_CACHE_KEY } from './useOrganizationAdminUserInviteList'

export const [useOrganizationUserInviteCreate] = createMutationHookWithBody(
  ApiService.organizationAdmin.invite.create,
  queryClient => ({
    onSuccess: () =>
      queryClient.invalidateQueries(
        ORGANIZATION_ADMIN_USER_INVITE_LIST_CACHE_KEY
      ),
  })
)
