import { FC, useMemo } from 'react'
// styled
import { Loader } from 'modules/core/styled'

import { LoaderWrapper } from './LoaderContainer.styled'
import { LoaderContainerProps } from './LoaderContainer.interface'

export const LoaderContainer: FC<LoaderContainerProps> = ({
  children,
  isLoading,
  variant = 'default',
  transitionTime = 0.3,
  transitionDelay = 0,
}) => {
  const size = useMemo(() => {
    switch (variant) {
      case 'default':
        return 80

      case 'small':
        return 30

      default:
        return 80
    }
  }, [variant])

  return (
    <>
      {children}
      <LoaderWrapper
        role='dialog'
        variant={variant}
        hidden={!isLoading}
        transitionTime={transitionTime}
        transitionDelay={transitionDelay}
      >
        <Loader size={size} />
      </LoaderWrapper>
    </>
  )
}
