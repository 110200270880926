import { FC, useCallback, useContext, useMemo, useState } from 'react'
import useDownloader from 'react-use-downloader'
// Hooks
import { useDrag } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { usePlaylistAssetDelete } from 'modules/playlist/hooks'
import { useReduxDispatch } from 'modules/core/hooks'
// Redux
import { setMediaItem } from 'modules/video-player/redux'
// Components
import { Icon, IconButton } from 'modules/core/components'
import { ResourceControls } from 'modules/core/components'
import { ClipEditModal } from 'modules/asset/components'
// Utils
import { format } from 'date-fns'
import { convertSecondsToTime } from 'modules/core/utils'
// context
import { VideoPlayerContext } from 'modules/video-player/context'
// Constants
import { DragItem } from 'modules/core/constants'
import {
  defaultIconName,
  eventIndexToIconDictionary,
} from 'modules/core/constants'
// Type
import { EventIndex } from 'modules/event/types'

import { ClipPlaylistPreviewProps } from './ClipPlaylistPreview.interface'
import {
  ClipDescription,
  ClipDescriptionWrapper,
  ClipInfoItem,
  ClipInfoWrapper,
  Root,
  Title,
  EventIconWrapper,
  MainInfoWrapper,
  TitleRow,
  ClipLastEditedWrapper,
  DownloadWrapper,
  DownloadProgress,
} from './ClipPlaylistPreview.styled'

export const ClipPlaylistPreview: FC<ClipPlaylistPreviewProps> = ({
  clip,
  playlistId,
}) => {
  const playlistAssetDelete = usePlaylistAssetDelete()
  const { t } = useTranslation('time')
  const { percentage, download, cancel, isInProgress } = useDownloader()
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const { playerId } = useContext(VideoPlayerContext)
  const dispatch = useReduxDispatch()
  const [, drag] = useDrag(
    () => ({
      type: DragItem.PLAYLIST_ASSET,
      item: clip,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    []
  )

  const clipUrl = useMemo(
    () => clip.media_locator?.url,
    [clip.media_locator?.url]
  )

  const handleEdit = useCallback(() => setModalOpen(true), [])

  const handleModalClose = useCallback(() => setModalOpen(false), [])

  const handleDownload = useCallback(() => {
    if (clipUrl) {
      download(clipUrl, `${clip.title}.mp4`)
    }
  }, [clipUrl, clip, download])

  const handleDelete = useCallback(
    () =>
      playlistAssetDelete.mutate({
        asset_id: clip.id,
        playlist_id: playlistId,
      }),
    [clip.id, playlistAssetDelete, playlistId]
  )

  const handlePlay = useCallback(() => {
    if (clip) {
      dispatch(
        setMediaItem({
          mediaLocator: clip.media_locator,
          activeVideoItemId: clip.id,
          activeVideoItemType: 'clip',
          id: playerId ?? '',
        })
      )
    }
  }, [clip, dispatch, playerId])

  return (
    <Root ref={drag}>
      {/* <ClipPreviewInfoItem onClick={handlePlay}>
        <Play />
        <ClipPreviewImage src={ClipPreviewImageSrc} alt='Clip' />
      </ClipPreviewInfoItem> */}
      <MainInfoWrapper>
        <ClipInfoWrapper>
          <ClipInfoItem>
            <TitleRow>
              <EventIconWrapper onClick={handlePlay}>
                <Icon
                  name={
                    eventIndexToIconDictionary[
                      clip.event?.type as EventIndex
                    ] ?? defaultIconName
                  }
                />
              </EventIconWrapper>

              <Title onClick={handlePlay}>{clip.title}</Title>
              <ResourceControls
                onDelete={handleDelete}
                onDownload={Boolean(clipUrl) ? handleDownload : undefined}
                onEdit={handleEdit}
              />
            </TitleRow>
            {isInProgress && (
              <DownloadWrapper>
                <DownloadProgress value={percentage} variant='determinate' />
                <IconButton name='close-circle' onClick={() => cancel()} />
              </DownloadWrapper>
            )}
            <ClipDescriptionWrapper onClick={handlePlay}>
              <ClipDescription>
                {format(new Date(+clip.created_at * 1000), 'dd/MM/yy')}
              </ClipDescription>
              <ClipDescription>
                {convertSecondsToTime(clip.duration)}
              </ClipDescription>
            </ClipDescriptionWrapper>
            <ClipLastEditedWrapper>
              <ClipDescription>
                {t('lastEdited')}:{' '}
                {format(new Date(+clip.updated_at * 1000), 'dd/MM/yy')}
              </ClipDescription>
            </ClipLastEditedWrapper>
          </ClipInfoItem>
        </ClipInfoWrapper>
      </MainInfoWrapper>
      <ClipEditModal clip={clip} open={modalOpen} onClose={handleModalClose} />
    </Root>
  )
}
