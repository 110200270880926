import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import {
  FrameList,
  UpdateFrameBody,
  Frame,
} from '@sporttotal-tv/dip-coaching-client'
import {
  FRAME_LIST_CACHE_KEY,
  FRAME_RETRIEVE_CACHE_KEY,
} from 'modules/frame/hooks'
import { MutateContext } from 'modules/core/types/mutate-context.type'

export const FRAME_UPDATE_CACHE_KEY = 'frame-update'

export type FrameUpdateParams = {
  id: string
  params: UpdateFrameBody
}

export const useFrameUpdate = () => {
  const queryClient = useQueryClient()

  return useMutation<Frame, Error, FrameUpdateParams, MutateContext<FrameList>>(
    FRAME_UPDATE_CACHE_KEY,
    ({ id, params }: FrameUpdateParams) => ApiService.frame.update(id, params),
    {
      onMutate: async data => {
        await queryClient.cancelQueries(FRAME_LIST_CACHE_KEY, { exact: false })

        const previousData =
          queryClient.getQueriesData<FrameList>(FRAME_LIST_CACHE_KEY)

        if (previousData) {
          queryClient.setQueriesData<FrameList | undefined>(
            FRAME_LIST_CACHE_KEY,
            oldData =>
              oldData && {
                total: oldData.total,
                results: oldData.results.map(item =>
                  item.id === data.id ? { ...item, ...data.params } : item
                ),
              }
          )
        }

        return { previousData: [...previousData] }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData(prevData[0], prevData[1])
          )
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(FRAME_LIST_CACHE_KEY, { exact: false })
        queryClient.invalidateQueries(FRAME_RETRIEVE_CACHE_KEY, {
          exact: false,
        })
      },
    }
  )
}
