import { forwardRef } from 'react'
import { ButtonProps } from '@mui/material'
import { useWithBlur } from 'modules/core/hooks'
import { ButtonWrapper, ActionIcon, StyledButton } from './ActionButton.styled'
import { ActionButtonProps } from './ActionButton.interface'

export const ActionButton = forwardRef<
  HTMLButtonElement,
  ActionButtonProps & ButtonProps
>(({ text, icon, position = 'center', onClick, ...props }, ref) => {
  const onClickHandler = useWithBlur(onClick)

  return (
    <ButtonWrapper
      sx={{
        justifyContent: position,
      }}
    >
      <StyledButton
        ref={ref}
        color='secondary'
        variant='contained'
        onClick={onClickHandler}
        {...props}
      >
        {icon && <ActionIcon name={icon} />}
        {text}
      </StyledButton>
    </ButtonWrapper>
  )
})
