type ButtonPropsVariants = {
  [key in BUTTON_VARIANTS]: string;
};

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides extends ButtonPropsVariants {}
}

export enum BUTTON_VARIANTS {
  default = "DEFAULT",
  primary = "PRIMARY",
  video = "VIDEO",
}

export interface ButtonProps {
  variant: BUTTON_VARIANTS;
}
