// Hooks
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'modules/core/hooks/useSnackbar'
import {
  usePlayingSurfaceList,
  usePlayingSurfaceDelete,
} from 'modules/playing-surface/hooks'
// Components
import { IconButton } from 'modules/core/components'
import { PlayingSurfaceTable } from 'modules/playing-surface/components/PlayingSurfaceTable'
import { PlayingSurfaceCreateModal } from 'modules/playing-surface/components/PlayingSurfaceCreateModal'
import { PlayingSurfaceEditModal } from 'modules/playing-surface/components/PlayingSurfaceEditModal'
// Types
import { PlayingSurface as IPlayingSurface } from '@sporttotal-tv/dip-coaching-client'
// Styled
import {
  Root,
  Wrapper,
  HeaderRow,
  TableWrapper,
  CreateWrapper,
} from './PlayingSurface.styled'

export const PlayingSurface = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'playingSurface' })
  const showSnackbar = useSnackbar()

  const { data } = usePlayingSurfaceList()
  const { mutate: deletePlayingSurface } = usePlayingSurfaceDelete()
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [selectedSurface, setSelectedSurface] = useState<
    IPlayingSurface | undefined
  >()

  const handleEdit = useCallback(
    (surface: IPlayingSurface) => setSelectedSurface(surface),
    []
  )

  const handleDelete = useCallback(
    (id: string) => {
      deletePlayingSurface(id, {
        onSuccess: () =>
          showSnackbar({
            message: t('notification.creationSuccess'),
            type: 'success',
          }),
        onError: () =>
          showSnackbar({
            message: t('notification.creationFailed'),
            type: 'error',
          }),
      })
    },
    [deletePlayingSurface, showSnackbar, t]
  )

  return (
    <Root>
      <Wrapper>
        <HeaderRow>
          <div />
          <CreateWrapper onClick={() => setIsCreateModalOpen(true)}>
            {t('actions.create')} <IconButton name='plus-circle' />
          </CreateWrapper>
        </HeaderRow>
        <TableWrapper>
          <PlayingSurfaceTable
            playingSurfaces={data?.results || []}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </TableWrapper>
      </Wrapper>
      <PlayingSurfaceCreateModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
      />
      {selectedSurface && (
        <PlayingSurfaceEditModal
          isOpen={!!selectedSurface}
          onClose={() => setSelectedSurface(undefined)}
          playingSurface={selectedSurface}
        />
      )}
    </Root>
  )
}
