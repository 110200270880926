import { styled } from '@mui/material'

export const FoulWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.5)};
`

export const CardElement = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  align-items: center;
`

export const PlayerSelectorWrapper = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`
