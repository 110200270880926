import { styled } from '@mui/material'

export const Root = styled('div')`
  height: 100%;
`

export const PlayerControllerContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'toolbarHeight',
})<{ toolbarHeight: number }>`
  display: flex;
  flex-direction: column;
  height: calc(100% - ${({ toolbarHeight }) => toolbarHeight}px);
`
