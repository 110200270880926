import { useState, MouseEvent, FocusEvent, useCallback } from 'react'

export const useAnchor = <T = HTMLElement>() => {
  const [anchor, setAnchor] = useState<null | T>(null)
  const isOpen = Boolean(anchor)
  const handleOpen = useCallback((event: MouseEvent<T> | FocusEvent<T>) => {
    setAnchor(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => setAnchor(null), [])

  return {
    isOpen,
    handleOpen,
    handleClose,
    anchor,
  }
}
