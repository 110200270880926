import { useCallback, useMemo, useState, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SelectChangeEvent } from '@mui/material'
// Hooks
import { useAdminUserUpdate, useUser } from 'modules/user/hooks'
import { useAdminUserDelete } from 'modules/admin/hooks'
// Components
import {
  ConfirmDialog,
  ConfirmDialogState,
  adminContextList,
} from 'modules/core/components'
// Utils
import { doesUserHaveRole } from 'modules/user/utils'
// Constants
import {
  nonSensitiveUserRoles,
  userFeatureTiers,
  userRoles,
} from 'modules/user/constants'

// Types
import { UserFeatureTier, UserRole } from 'modules/user/types'
import { ProfileAdminEditProps } from './ProfileAdminEdit.interface'

import {
  FormContainer,
  Header,
  Main,
  Title,
  Label,
  InputWrapper,
  FullWidthFormControl,
  Row,
  EditContainer,
  AddButton,
  RoleSelector,
  StyledMenuItem,
  DeleteButton,
  Spacer,
} from './ProfileAdminEdit.styled'
import { RelationEdit } from 'modules/admin/components'

export const ProfileAdminEdit: FC<ProfileAdminEditProps> = ({ user }) => {
  const { t } = useTranslation(['pages', 'userRole'])
  const navigate = useNavigate()
  const adminUser = useUser()
  const userUpdate = useAdminUserUpdate()
  const { mutate: deleteUser } = useAdminUserDelete()
  const [role, setRole] = useState<UserRole | undefined>(user?.role)
  const [featureTier, setFeatureTier] = useState<UserFeatureTier | undefined>(
    user?.feature_tier
  )

  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = useState(false)

  const isRoot = doesUserHaveRole(adminUser.data, [userRoles.ROOT])
  const isEditingUserRoot = doesUserHaveRole(user, [userRoles.ROOT])

  const roles = useMemo(
    () => (isRoot ? Object.entries(nonSensitiveUserRoles) : []),
    [isRoot]
  )

  const featureTiers = useMemo(
    () => (isRoot ? Object.entries(userFeatureTiers) : []),
    [isRoot]
  )

  const handleRoleChange = useCallback((event: SelectChangeEvent<unknown>) => {
    setRole(event.target.value as UserRole)
  }, [])

  const handleSaveRole = useCallback(() => {
    if (!role || !user) return

    userUpdate.mutate({
      id: user.id,
      params: {
        role,
      },
    })
  }, [role, user, userUpdate])

  const handleFeatureTierChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      setFeatureTier(event.target.value as UserFeatureTier)
    },
    []
  )

  const handleSaveFeatureTier = useCallback(() => {
    if (!featureTier || !user) return

    userUpdate.mutate({
      id: user.id,
      params: {
        feature_tier: featureTier,
      },
    })
  }, [featureTier, user, userUpdate])

  const handleDeleteUser = useCallback(
    (dialogResult: ConfirmDialogState) => {
      if (!isRoot) return
      if (!user) return

      const confirmDelete = dialogResult === ConfirmDialogState.SUBMITTED
      if (!confirmDelete) {
        setIsDeleteUserDialogOpen(false)
        return
      }

      // Verbose since its critical
      if (confirmDelete)
        deleteUser(user.id, {
          onSuccess: () => navigate(adminContextList.path),
        })
    },
    [user, isRoot, deleteUser, navigate]
  )

  if (!adminUser.isFetched || !user)
    return (
      <Main>
        <Header>
          <Title>{t('pages:profile.form.profile.update.disabled')}</Title>
        </Header>
      </Main>
    )

  return (
    <>
      <ConfirmDialog
        open={isDeleteUserDialogOpen}
        onResult={handleDeleteUser}
        options={{
          title: t('admin.button.deleteUser'),
          description: t('admin.modal.deleteUser.description', {
            firstName: user.firstname,
          }),
        }}
      />
      <Main>
        <Header>
          <Title>{t('pages:profile.form.profile.update.title')}</Title>
        </Header>
        <FormContainer>
          <EditContainer>
            <Row>
              <InputWrapper>
                <Label>
                  {t('pages:profile.form.profile.update.association')}
                </Label>
                <FullWidthFormControl>
                  <RelationEdit
                    entity='user'
                    resource='association'
                    entityId={user.id}
                  />
                </FullWidthFormControl>
              </InputWrapper>
              <InputWrapper>
                <Label>
                  {t('pages:profile.form.profile.update.competition')}
                </Label>
                <FullWidthFormControl>
                  <RelationEdit
                    entity='user'
                    resource='competition'
                    entityId={user.id}
                  />
                </FullWidthFormControl>
              </InputWrapper>
            </Row>
            <Row>
              <InputWrapper>
                <Label>{t('pages:profile.form.profile.update.club')}</Label>
                <FullWidthFormControl>
                  <RelationEdit
                    entity='user'
                    resource='club'
                    entityId={user.id}
                  />
                </FullWidthFormControl>
              </InputWrapper>
              <InputWrapper>
                <Label>{t('pages:profile.form.profile.update.team')}</Label>
                <FullWidthFormControl>
                  <RelationEdit
                    entity='user'
                    resource='team'
                    entityId={user.id}
                  />
                </FullWidthFormControl>
              </InputWrapper>
            </Row>
            {isRoot && (
              <Row>
                {!isEditingUserRoot && (
                  <InputWrapper>
                    <Label>{t('pages:profile.form.profile.update.role')}</Label>
                    <FullWidthFormControl>
                      <RoleSelector
                        inputProps={{ 'aria-label': 'role' }}
                        value={role}
                        onChange={handleRoleChange}
                      >
                        {roles.map(([key, role]) => (
                          <StyledMenuItem key={key} value={key}>
                            {t(`userRole:${role}`)}
                          </StyledMenuItem>
                        ))}
                      </RoleSelector>
                      <AddButton
                        onClick={handleSaveRole}
                        disabled={!role || !user}
                        color='secondary'
                        variant='contained'
                        aria-label='role-save-button'
                      >
                        {t('pages:profile.form.buttons.save')}
                      </AddButton>
                    </FullWidthFormControl>
                  </InputWrapper>
                )}
                <InputWrapper>
                  <Label>{t('pages:profile.form.profile.update.access')}</Label>
                  <FullWidthFormControl>
                    <RelationEdit
                      resource='access_group'
                      entity='user'
                      entityId={user.id}
                    />
                  </FullWidthFormControl>
                </InputWrapper>
              </Row>
            )}
            <Row>
              <InputWrapper>
                <Label>
                  {t('pages:profile.form.profile.update.featureTier')}
                </Label>
                <FullWidthFormControl>
                  <RoleSelector
                    inputProps={{ 'aria-label': 'feature-tier' }}
                    value={featureTier}
                    onChange={handleFeatureTierChange}
                  >
                    {featureTiers.map(([key, role]) => (
                      <StyledMenuItem key={key} value={key}>
                        {t(
                          `pages:profile.form.profile.update.featureTierVariant.${role}`
                        )}
                      </StyledMenuItem>
                    ))}
                  </RoleSelector>
                  <AddButton
                    onClick={handleSaveFeatureTier}
                    disabled={!featureTier || !user}
                    color='secondary'
                    variant='contained'
                    aria-label='feature-tier-save-button'
                  >
                    {t('pages:profile.form.buttons.save')}
                  </AddButton>
                </FullWidthFormControl>
              </InputWrapper>
            </Row>
            {isRoot && (
              <Row>
                <div>
                  <Spacer />
                  <DeleteButton
                    text={t('admin.button.deleteUser')}
                    icon='danger-circle'
                    onClick={() => setIsDeleteUserDialogOpen(true)}
                    disabled={user.id === adminUser.data?.id}
                  />
                </div>
              </Row>
            )}
          </EditContainer>
        </FormContainer>
      </Main>
    </>
  )
}
