import { Layout } from 'react-grid-layout'
import { UserTabsData } from 'modules/user/types'
import { LayoutConfigBreakpoints } from 'modules/layout/types'
import {
  BREAKPOINTS,
  LAYOUT_CONFIGS,
  LayoutIndex,
} from 'modules/layout/constants'
import { updateUserTabsData } from 'modules/user/utils'

export const updateUserTabsDataWithParsingLayoutItem = (
  tabsData: UserTabsData | undefined,
  activeTab: string,
  breakpoint: LayoutConfigBreakpoints | undefined,
  dataToChange: Layout[],
  id: string,
  type: LayoutIndex,
  dropData: Layout
): UserTabsData => {
  const updatedUserTabsData = updateUserTabsData(
    tabsData,
    activeTab,
    breakpoint,
    dataToChange
  )

  return {
    ...updatedUserTabsData,
    [activeTab]: {
      ...tabsData?.[activeTab],
      ...BREAKPOINTS.reduce((acc, layoutConfigBreakpoint) => {
        const itemsData = tabsData?.[activeTab]?.[layoutConfigBreakpoint] || []

        const updatedItemsData =
          breakpoint === layoutConfigBreakpoint
            ? [dropData]
            : [{ ...LAYOUT_CONFIGS[type]?.[layoutConfigBreakpoint], i: id }]

        return {
          ...acc,
          [layoutConfigBreakpoint]: [
            ...itemsData,
            ...(itemsData.find(item => item.i === id) ? [] : updatedItemsData),
          ],
        }
      }, {}),
    },
  }
}
