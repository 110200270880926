import { Button, FormLabel, Input, styled, Typography } from '@mui/material'
import { getContrastColor } from 'modules/core/helpers/getContrastColor'
import { OptionsSelector } from 'modules/core/styled'

export const Root = styled('div')`
  height: 100%;
`

export const ToolbarContainer = styled('div')`
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  justify-content: center;
`

export const Tab = styled('div', {
  shouldForwardProp: prop => prop !== 'active',
})<{ active: boolean }>`
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  color: ${({ theme, active }) =>
    active ? theme.palette.text.primary : theme.palette.text.disabled};
`

export const Divider = styled('div')`
  height: 14px;
  background-color: ${({ theme }) => theme.palette.primary.light};
  width: 1px;
  margin: 0 5px;
`

export const Content = styled('div')`
  padding: ${({ theme }) => theme.spacing(1)};
  height: calc(100% - 46px);
  overflow: auto;
`

export const HighlightButtonGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: min-content;
  grid-gap: ${({ theme }) => theme.spacing(0.75)};
`

export const HighlightsList = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.spacing(1)};
`

export const HighlightWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(1.5)} 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.light};

  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.palette.primary.light};
  }
`

export const HighlightMeta = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.5)};
`

export const HightlightIconContainer = styled('div')`
  display: flex;
  align-items: center;

  & > svg {
    width: 20px;
    height: 20px;
  }
`

export const HighlightTextContainer = styled('div')``

export const HighlightTextPrimaryContainer = styled('div')`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
`

export const HighlightTextSecondaryContainer = styled('div')``

export const HighlightTitle = styled(Typography)`
  font-size: 15px;
`

export const HighlightTimestamp = styled('div')`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.light};
`

export const HighlightDescription = styled(Typography)`
  font-size: 14px;
`

export const StyledButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'backgroundColor',
})<{ backgroundColor: string }>`
  font-size: 18px;
  aspect-ratio: 1/1;
  box-shadow: none;
  border-radius: 3px;
  text-transform: capitalize;
  display: flex;
  flex-direction: column-reverse;
  row-gap: ${({ theme }) => theme.spacing(1)};
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.palette.secondary.main};

  color: ${({ theme, backgroundColor }) =>
    getContrastColor(backgroundColor || theme.palette.secondary.main)};

  & svg {
    width: 20px;
    height: 20px;
  }

  & path {
    fill: ${({ theme, backgroundColor }) =>
      getContrastColor(backgroundColor || theme.palette.secondary.main)};
  }

  &:hover {
    background-color: ${({ theme, backgroundColor }) =>
      backgroundColor ? `${backgroundColor}90` : theme.palette.secondary.main};
  }
`

export const ActionText = styled(Typography)`
  word-break: break-word;
  hyphens: auto;
`

export const Label = styled(FormLabel)`
  font-size: 14px;
  text-align: right;
  position: relative;
`

export const ContentToolbar = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`

export const InputText = styled(Input)`
  background-color: ${({ theme }) => theme.palette.darkest};
  padding: 0;
  width: 50px;

  & .MuiInput-input {
    padding: 4px;
    font-size: 14px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
`

export const ContentToolbarItem = styled('div')`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(1)};
`

export const HighlightOptionsSelector = styled(OptionsSelector)`
  font-size: ${({ theme }) => theme.spacing(1.5)};
  min-width: 100px;
  border-radius: 5px;
  margin-left: ${({ theme }) => theme.spacing(1)};
`
