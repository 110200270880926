import { FC, ReactNode } from 'react'
import { styled, css } from '@mui/material'

type BaseComponentWithDisableProps = {
  disabled?: boolean
  children: ReactNode
}

const disabledCss = css`
  cursor: not-allowed;
  filter: brightness(0.5);
  user-select: none;

  & * {
    cursor: not-allowed !important;
  }
`

export const ComponentWithDisable = (
  component: FC<BaseComponentWithDisableProps>
) =>
  styled(component)<BaseComponentWithDisableProps>`
    // Disable styles for supported components
    &:disabled {
      ${disabledCss};
    }

    // Disable styles when none-input component
    ${({ disabled }) => disabled && disabledCss}
  `
