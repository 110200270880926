import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { useQueryRetry } from 'modules/core/hooks'
import { transformProductsData } from '../utils'

export const PRODUCTS_RETRIEVE_CACHE_KEY = 'products-retrieve'

export const useProductList = () => {
  const handleQueryRetry = useQueryRetry({ maxRetries: 0 })

  return useQuery(
    [PRODUCTS_RETRIEVE_CACHE_KEY],
    async () => {
      const response = await ApiService.product.list()
      if (!('data' in response)) return null

      return transformProductsData(response.data)
    },
    {
      staleTime: Infinity,
      retry: handleQueryRetry,
    }
  )
}
