import iconBasic from 'modules/landing/assets/pricing/ico-basic.svg'
import iconPro from 'modules/landing/assets/pricing/ico-pro.svg'
import iconAdvanced from 'modules/landing/assets/pricing/ico-advanced.svg'

export const TEXT = new Array(3).fill(null)

export const FEATURES = [
  new Array(6).fill(null),
  new Array(8).fill(null),
  new Array(2).fill(null),
]

export const PRICING = [
  {
    icon: iconBasic,
    href: `${window.__ENV__.REACT_APP_SSO_AUTH_URL}/users/sign_in`,
  },
  { icon: iconPro, href: '' },
  { icon: iconAdvanced, href: '' },
]

export interface ProductInterface {
  active: boolean
  metadata: any
  price: {
    currency: string
    unit_amount: number
  }
}
