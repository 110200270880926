import { theme } from 'theme'

export const componentItemColors = [
  theme.palette.componentIcons.first,
  theme.palette.componentIcons.second,
  theme.palette.componentIcons.third,
]

export const componentItemColorsWithDefault = [
  theme.palette.componentIcons.default,
  theme.palette.componentIcons.first,
  theme.palette.componentIcons.second,
  theme.palette.componentIcons.third,
]
