// Merge events from different sports and throw if event id overlaps
export const eventMerge = (...sportEvents: Record<string, number>[]) =>
  sportEvents.reduce((acc, events) => {
    Object.entries(events).forEach(([key, value]) => {
      const allEventIds = Object.values(acc)
      if (value in allEventIds)
        throw new Error(`Event type ${key} is already used with id ${value}`)

      acc[key] = value
    })

    return acc
  }, {})
