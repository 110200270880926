import { UserRole } from 'modules/user/types'

export const userRoles: Record<UserRole, string> = {
  ADMIN: 'admin',
  PLAYER: 'player',
  COACH: 'coach',
  ROOT: 'root',
  AGENT: 'agent',
}

export const nonSensitiveUserRoles: Record<
  Exclude<UserRole, 'ROOT'>,
  string
> = {
  ADMIN: 'admin',
  PLAYER: 'player',
  COACH: 'coach',
  AGENT: 'agent',
}

export const nonInternalUserRoles: Record<
  Exclude<UserRole, 'ROOT' | 'ADMIN'>,
  string
> = {
  PLAYER: 'player',
  COACH: 'coach',
  AGENT: 'agent',
}
