import { api } from 'modules/core/api'
import { transformApiResults } from 'modules/core/helpers'
import {
  StripeConfig,
  SubscriptionPortal,
} from '@sporttotal-tv/dip-coaching-client'

export const StripeApiService = {
  // TODO: Move to stripe controller
  retriveCustomerPortal: transformApiResults<void, SubscriptionPortal>(
    api.subscription.portal
  ),
  retrieveConfig: transformApiResults<void, StripeConfig>(api.stripe.config),
}
