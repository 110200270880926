import { useCallback } from 'react'
// Hooks
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'modules/core/hooks/useSnackbar'
import { useOrganizationCamerasList } from 'modules/organization-admin/hooks'
// import { useCameraReportIssue } from 'modules/camera/hooks'
// Components
import { CameraTable } from 'modules/camera/components'
// Types
import { CameraRetreiveData } from '@sporttotal-tv/dip-coaching-client'

// Styled
import { Root, Wrapper, TableWrapper } from './Camera.styled'

export const Camera = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'admin' })
  const showSnackbar = useSnackbar()

  const { data } = useOrganizationCamerasList()

  const handleStartStop = useCallback(
    (camera: CameraRetreiveData) => {
      // const action = camera.healthStatus === 'healthy' ? 'stopped' : 'started'

      showSnackbar({
        message: `Camera ${
          camera.system_information?.serial_number
        } has been ${'action'}`,
        type: 'success',
      })
    },
    [showSnackbar]
  )

  const handleUpdate = useCallback(
    (camera: CameraRetreiveData) => {
      showSnackbar({
        message: `Software update started for ${camera.system_information?.serial_number}`,
        type: 'success',
      })
    },
    [showSnackbar]
  )

  return (
    <Root>
      <Wrapper>
        <TableWrapper>
          <CameraTable
            cameras={data?.results || []}
            onStartStop={handleStartStop}
            onUpdate={handleUpdate}
          />
        </TableWrapper>
      </Wrapper>
    </Root>
  )
}
