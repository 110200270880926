import { Router } from 'modules/core/components'
import { AppProvider } from 'providers/AppProvider'

const App = () => (
  <AppProvider>
    <Router />
  </AppProvider>
)

export default App
