import { teamPlayerStatusMockup } from 'modules/team-player/utils'
import {
  CreatePlayerStatusBody,
  PlayerStatus,
} from '@sporttotal-tv/dip-coaching-client'
import { generateId } from 'modules/core/helpers/generateId'

export const placeholderPlayerStatus = (
  payload?: CreatePlayerStatusBody
): PlayerStatus => ({
  ...teamPlayerStatusMockup,
  id: generateId(),
  ...payload,
})
