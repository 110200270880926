import {
  PlayerContract,
  PlayerTransfer,
} from '@sporttotal-tv/dip-coaching-client'

export const getTransferValue = (item: PlayerContract | PlayerTransfer) => {
  return `${item.transfer_value_min}${
    item.transfer_value_currency?.currency_symbol ?? ''
  } - ${item.transfer_value_max}${
    item.transfer_value_currency?.currency_symbol ?? ''
  }`
}
