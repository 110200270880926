import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { FrameListParams } from '@sporttotal-tv/dip-coaching-client'

export const FRAME_LIST_CACHE_KEY = 'frame-list'

export const useFrameList = (params?: FrameListParams) => {
  return useQuery(
    [FRAME_LIST_CACHE_KEY, params],
    () => ApiService.frame.list(params),
    {
      enabled:
        params?.parent_media_locator_id !== undefined &&
        params?.parent_media_locator_id !== '',
      refetchOnWindowFocus: false,
    }
  )
}
