import { css, styled, Typography, List } from '@mui/material'
import { Event as EventIcon } from '@mui/icons-material'
import { IconButton } from 'modules/core/components/IconButton'
import { ComponentWithDisable } from 'modules/core/components/Custom'

export const Root = styled('div', {
  shouldForwardProp: prop => prop !== 'active',
})<{ active?: boolean }>`
  position: relative;

  padding: ${({ theme }) => theme.spacing(1.5, 0)};
  margin: ${({ theme }) => theme.spacing(0, 2)};

  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};

  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.palette.divider};
  }

  &:before {
    content: '';
    width: 7px;
    height: calc(100% + 2px);
    top: -1px;
    transition: all 0.3s;
    position: absolute;
    left: -${({ theme }) => theme.spacing(4)};
    background-color: ${({ theme }) => theme.palette.secondary.main};
    opacity: 0;
  }

  ${({ active }) =>
    active &&
    css`
      &:before {
        opacity: 1;
      }
    `}
`

export const MatchInfoWrapper = ComponentWithDisable(styled('div', {
  shouldForwardProp: prop => prop !== 'disabled',
})`
  display: flex;
  align-items: flex-start;
  column-gap: ${({ theme }) => theme.spacing(2)};
`)

export const MatchInfoItem = styled('div', {
  shouldForwardProp: prop => prop !== 'height' && prop !== 'clickable',
})<{ clickable: boolean; height?: number }>`
  flex: 1;
  overflow: hidden;
  position: relative;

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `};

  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `};
`

export const MatchImageWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'clickable',
})<{ clickable?: boolean }>`
  position: relative;
  max-height: 100%;
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `};
`

export const TitleRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const TitleRows = styled('div')`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`

export const MatchPreviewImage = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const Title = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.secondary.main};
  margin: 0;
`

export const TitleSecondary = styled(Title)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
`

export const Vs = styled('span')`
  font-size: 14px;
  margin-right: 5px;
  color: ${({ theme }) => theme.palette.primary.contrastText};

  &:after {
    content: 'vs';
  }
`

export const MatchDescriptionItem = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  color: inherit;
`

export const MatchDescriptionWrapper = styled('div')`
  color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  justify-content: space-between;
`

export const CollapseControlsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 22px;
`

export const TagWrapper = styled('div')`
  display: flex;
  flex: 1;
  height: 23px;
  column-gap: ${({ theme }) => theme.spacing(1)};
  row-gap: ${({ theme }) => theme.spacing(1)};
  flex-wrap: wrap;
  overflow: hidden;
`

export const Tag = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.light};
  padding: ${({ theme }) => theme.spacing(0.5, 2)};
  text-transform: uppercase;
  font-size: 10px;
`

export const Play = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  width: 21px;
  height: 24px;
  border-style: solid;
  border-width: 12px 0 12px 21px;
  border-color: transparent transparent transparent
    ${({ theme }) => theme.palette.common.white};
  transform: translate(-50%, -50%);
  opacity: 0.7;
`

export const AnimatedChevronIcon = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'chevronUp',
})<{ chevronUp: boolean }>`
  transition: all 0.3s;
  transform: ${({ chevronUp }) => (chevronUp ? 'rotate(0)' : 'rotate(180deg)')};
`

export const IconWrapper = styled('div')`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LiveIndicator = styled('div')`
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 20px;
  background: ${({ theme }) => theme.palette.secondary.main};
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse 1.75s infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
  }
`

export const UpcomingIcon = styled(EventIcon)`
  font-size: 20px;
`

export const DownloadAssetList = styled(List)`
  padding: 0;
`
