import { Typography, Button as BaseButton, styled } from '@mui/material'

export const Main = styled('div')`
  height: 100%;
  margin-top: 35vh;
  display: flex;
  flex-direction: column;
  place-items: center;
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 120px;
`

export const CoachingButton = styled(BaseButton)`
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  background-color: ${({ theme }) => theme.palette.primary.main};
  margin-top: ${({ theme }) => theme.spacing(10)};
  padding: ${({ theme }) => theme.spacing(1.25, 4)};

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`
