import { css, styled } from '@mui/material'

export const Root = styled('div')`
  height: 100%;
`

export const PlayersWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'compact',
})<{ compact?: boolean }>`
  padding: ${({ theme }) => theme.spacing(1, 4)};
  // TODO: 46px should be in const  change when fixed issue with tests
  height: calc(100% - 46px);

  ${({ compact, theme }) =>
    compact &&
    css`
      padding: ${theme.spacing(0, 1, 1)};
    `}
`
