import { useCallback } from 'react'
import format from 'date-fns/format'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material'
import { ModalDialog } from 'modules/core/components'
import Button from '@mui/material/Button'
import { useReduxDispatch, useReduxSelector } from 'modules/core/hooks'
import {
  selectCalendarEvent,
  selectOpenViewDialog,
  setViewDialogOpen,
} from 'modules/calendar/redux'
import { useScheduleDelete } from 'modules/schedule/hooks'

export const StyledButton = styled(Button)`
  font-size: 14px;
  box-shadow: none;
  padding: 0 ${({ theme }) => theme.spacing(1.5)};
  border-radius: 0;
  text-transform: capitalize;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
`

const FormControlFlex = styled(FormControl)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
`

const StyledAccessTimeIcon = styled(AccessTimeIcon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
  height: 14px;
  width: 14px;
`

const CalendarEventViewDialog = () => {
  const dispatch = useReduxDispatch()
  const openViewDialog = useReduxSelector(selectOpenViewDialog)
  const calendarEvent = useReduxSelector(selectCalendarEvent)
  const { t } = useTranslation('pages', { keyPrefix: 'calendar' })
  const deleteEvent = useScheduleDelete()

  // const setCalendarEditEventFunc = useCallback(
  //   (data: CalendarEditData) => {
  //     dispatch(setCalendarEditEvent(data))
  //   },
  //   [dispatch]
  // )

  const handleCloseViewDialog = useCallback(() => {
    dispatch(setViewDialogOpen(false))
  }, [dispatch])

  const handleDeleteEvent = useCallback(() => {
    if (calendarEvent?.id) {
      deleteEvent.mutate(`${calendarEvent.id}`)
    }
    handleCloseViewDialog()
  }, [calendarEvent?.id, deleteEvent, handleCloseViewDialog])

  return (
    <ModalDialog
      title={calendarEvent?.title || ''}
      icon={'calendar-week'}
      open={openViewDialog}
      onClose={handleCloseViewDialog}
      aria-labelledby='max-width-dialog-title'
      keepMounted={false}
    >
      <>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography>{calendarEvent?.description || ''}</Typography>
          <FormControlFlex>
            <StyledAccessTimeIcon />
            <Typography sx={{ fontSize: 14 }}>
              {calendarEvent?.begin &&
                format(
                  new Date(calendarEvent.begin),
                  'EEEE, d MMMM yyyy ⋅ HH:mm -'
                )}{' '}
              {calendarEvent?.end &&
                format(new Date(calendarEvent.end), 'HH:mm')}
            </Typography>
          </FormControlFlex>
        </DialogContent>
        <DialogActions>
          {/* TODO: Return when we have edit option */}
          {/*<StyledButton*/}
          {/*  color='secondary'*/}
          {/*  variant='contained'*/}
          {/*  onClick={eventEl => {*/}
          {/*    createEditEvent({*/}
          {/*      eventEl,*/}
          {/*      setStateCalendar: setCalendarEditEventFunc,*/}
          {/*      calendarEvent,*/}
          {/*    })*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {t('modal.buttons.edit')}*/}
          {/*</StyledButton>*/}
          {calendarEvent?.editable && (
            <StyledButton variant='contained' onClick={handleDeleteEvent}>
              {t('modal.buttons.delete')}
            </StyledButton>
          )}
        </DialogActions>
      </>
    </ModalDialog>
  )
}

export default CalendarEventViewDialog
