import { FC, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { Player } from '@sporttotal-tv/dip-coaching-client'
// components
import {
  ActionButton,
  ModalDialog,
  TextInput,
  TextInputErrorMessage,
  TextInputLabel,
  CurrencySelector,
} from 'modules/core/components'
import { TeamPlayerSelector } from 'modules/team-player/components'
// hooks
import { useTError } from 'modules/core/i18n/hooks'
import {
  useTeamPlayerTransferCreate,
  useTeamPlayerTransferUpdate,
} from 'modules/team-player/hooks'
import { useUserList } from 'modules/user/hooks'
// schema
import { teamPlayerTransferSchema } from 'modules/team-player/schemas'
// types
import { TeamPlayerTransferFormData } from 'modules/team-player/types'

import { AgentOption, TransferModalProps } from './TransferView.interface'
import {
  FormControlWrapper,
  InputWrapper,
  PlayerSelectorWrapper,
  InputTextField,
  StyledAutocomplete,
  StyledPopper,
  StyledCheckbox,
} from './TransferView.styled'

export const TransferModal: FC<TransferModalProps> = ({
  open,
  onClose,
  title,
  item,
}) => {
  const { t } = useTranslation('components', { keyPrefix: 'teamPlayers' })
  const { tError } = useTError(['components'])
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null)
  const userList = useUserList({
    role: 'AGENT',
  })
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<TeamPlayerTransferFormData>({
    resolver: yupResolver(teamPlayerTransferSchema),
    defaultValues: {
      agent: item?.agent?.id,
      option: item?.option,
      transferStatus: item?.transfer_status ?? null,
      loan: item?.loan,
      loanCurrency: item?.loan_currency_id,
      isLoanAvailable: !!item?.is_loan_available,
      askingPrice: item?.asking_price,
      askingPriceCurrency: item?.asking_price_currency_id,
      transferValue: item?.transfer_value,
      transferValueMin: item?.transfer_value_min,
      transferValueMax: item?.transfer_value_max,
      transferValueCurrency: item?.transfer_value_currency_id,
    },
  })

  const teamPlayerTransferUpdate = useTeamPlayerTransferUpdate()
  const teamPlayerTransferCreate = useTeamPlayerTransferCreate()

  const agentOptions = useMemo(() => {
    return (
      userList.data?.pages?.reduce<AgentOption[]>((acc, page) => {
        return [
          ...acc,
          ...page.results.map(user => ({
            value: user.id,
            label: `${user.firstname} ${user.lastname}`,
          })),
        ]
      }, []) || []
    )
  }, [userList.data])

  const onSubmit = (data: TeamPlayerTransferFormData) => {
    if (item) {
      teamPlayerTransferUpdate.mutate(
        {
          id: item.id,
          params: {
            agent_id: data.agent,
            option: data.option,
            transfer_status: data.transferStatus,
            loan: data.loan,
            loan_currency_id: data.loanCurrency,
            is_loan_available: data.isLoanAvailable,
            asking_price: data.askingPrice,
            asking_price_currency_id: data.askingPriceCurrency,
            transfer_value: data.transferValue,
            transfer_value_min: data.transferValueMin,
            transfer_value_max: data.transferValueMax,
            transfer_value_currency_id: data.transferValueCurrency,
          },
        },
        {
          onSuccess: onClose,
        }
      )
      return
    }

    if (selectedPlayer) {
      teamPlayerTransferCreate.mutate(
        {
          player_id: selectedPlayer.id,
          agent_id: data.agent,
          option: data.option,
          transfer_status: data.transferStatus ?? null,
          loan: data.loan,
          loan_currency_id: data.loanCurrency,
          is_loan_available: data.isLoanAvailable,
          asking_price: data.askingPrice,
          asking_price_currency_id: data.askingPriceCurrency,
          transfer_value: data.transferValue,
          transfer_value_min: data.transferValueMin,
          transfer_value_max: data.transferValueMax,
          transfer_value_currency_id: data.transferValueCurrency,
        },
        {
          onSuccess: onClose,
        }
      )
    }
  }

  return (
    <ModalDialog open={open} onClose={onClose} title={title}>
      <>
        {!item && (
          <PlayerSelectorWrapper>
            <TeamPlayerSelector
              showError={isSubmitted}
              player={selectedPlayer}
              setPlayer={setSelectedPlayer}
            />
          </PlayerSelectorWrapper>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper>
            <TextInputLabel>{t('modal.transfer.fields.agent')}</TextInputLabel>
            <FormControlWrapper>
              {userList.data && (
                <Controller
                  name='agent'
                  control={control}
                  render={({ field: { onChange, ref, ...field } }) => (
                    <StyledAutocomplete
                      onChange={(_, data: any) => onChange(data?.value ?? '')}
                      PopperComponent={StyledPopper}
                      renderInput={params => (
                        <InputTextField
                          {...field}
                          {...params}
                          inputProps={{
                            'aria-label': 'agent',
                            ...params.inputProps,
                          }}
                          inputRef={ref}
                        />
                      )}
                      options={agentOptions}
                      error={!!errors.agent?.message}
                    />
                  )}
                />
              )}
              <TextInputErrorMessage>
                {tError(errors.agent?.message)}
              </TextInputErrorMessage>
            </FormControlWrapper>
          </InputWrapper>
          <TextInput
            type='number'
            label={t(`modal.transfer.fields.option`)}
            errorMessage={tError(errors.option?.message)}
            inputProps={{ 'aria-label': 'option' }}
            {...register('option')}
          />
          <TextInput
            type='number'
            label={t('modal.transfer.fields.loan')}
            errorMessage={tError(errors.loan?.message)}
            inputProps={{ 'aria-label': 'loan' }}
            endAdornment={
              <Controller
                name='loanCurrency'
                control={control}
                render={({ field }) => (
                  <CurrencySelector
                    inputProps={{ 'aria-label': 'loanCurrency' }}
                    {...field}
                  />
                )}
              />
            }
            {...register('loan')}
          />
          <InputWrapper>
            <TextInputLabel>
              {t('modal.transfer.fields.isLoanAvailable')}
            </TextInputLabel>
            <FormControlWrapper>
              <div>
                <StyledCheckbox
                  size='small'
                  {...register('isLoanAvailable')}
                  inputProps={{ 'aria-label': 'isLoanAvailable' }}
                />
              </div>
            </FormControlWrapper>
          </InputWrapper>
          <TextInput
            type='number'
            label={t('modal.transfer.fields.askingPrice')}
            errorMessage={tError(errors.askingPrice?.message)}
            inputProps={{ 'aria-label': 'askingPrice' }}
            endAdornment={
              <Controller
                name='askingPriceCurrency'
                control={control}
                render={({ field }) => (
                  <CurrencySelector
                    inputProps={{ 'aria-label': 'askingPriceCurrency' }}
                    {...field}
                  />
                )}
              />
            }
            {...register('askingPrice')}
          />
          <TextInput
            type='number'
            label={t('modal.transfer.fields.transferValue')}
            errorMessage={tError(errors.transferValue?.message)}
            inputProps={{ 'aria-label': 'transferValue' }}
            endAdornment={
              <Controller
                name='transferValueCurrency'
                control={control}
                render={({ field }) => (
                  <CurrencySelector
                    inputProps={{ 'aria-label': 'transferValueCurrency' }}
                    {...field}
                  />
                )}
              />
            }
            {...register('transferValue')}
          />
          <TextInput
            type='number'
            label={t('modal.transfer.fields.transferValueMin')}
            errorMessage={tError(errors.transferValueMin?.message)}
            inputProps={{ 'aria-label': 'transferValueMin' }}
            {...register('transferValueMin')}
          />
          <TextInput
            type='number'
            label={t('modal.transfer.fields.transferValueMax')}
            errorMessage={tError(errors.transferValueMax?.message)}
            inputProps={{ 'aria-label': 'transferValueMax' }}
            {...register('transferValueMax')}
          />
          <ActionButton
            type='submit'
            icon='save'
            text={t('actions.save')}
            position='end'
          />
        </form>
      </>
    </ModalDialog>
  )
}
