import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// Hooks
import {
  useOrganizationAdminUserInviteList,
  useOrganizationUserInviteDelete,
} from 'modules/organization-admin/hooks'
// components
import { IconButton } from 'modules/core/components'
import { OrganizationUserInviteModal } from 'modules/organization-admin/components'
import { UserInviteList } from 'modules/user/components'

import {
  Wrapper,
  Root,
  HeaderRow,
  UserAddWrapper,
} from './OrganizationInvites.styled'

export const OrganizationInvites = () => {
  const { id } = useParams()
  const { t } = useTranslation('pages', { keyPrefix: 'admin' })

  const invites = useOrganizationAdminUserInviteList({
    group: id ?? '',
    expand: ['issued_to'],
  })

  const deleteInvite = useOrganizationUserInviteDelete(id ?? '')

  const [organizationUserInviteOpen, isOrganizationUserInviteOpen] =
    useState(false)

  const handleInviteDelete = (inviteId: string) => deleteInvite.mutate(inviteId)

  return (
    <Root>
      <Wrapper>
        <HeaderRow>
          <div />
          <UserAddWrapper onClick={() => isOrganizationUserInviteOpen(true)}>
            {t('context.inviteUser')} <IconButton name='plus-circle' />
          </UserAddWrapper>
        </HeaderRow>
        <UserInviteList invites={invites} onInviteDelete={handleInviteDelete} />
      </Wrapper>
      {id && (
        <OrganizationUserInviteModal
          open={organizationUserInviteOpen}
          setOpen={isOrganizationUserInviteOpen}
          organizationId={id}
        />
      )}
    </Root>
  )
}
