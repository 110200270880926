import {
  Button,
  Dialog,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Typography,
  css,
  styled,
} from '@mui/material'
import { Icon } from 'modules/core/components'
import { MenuItem } from 'modules/core/components/Menu'

export const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    min-width: 470px;
    padding: ${({ theme }) => theme.spacing(1.5, 2)};
    background-color: ${({ theme }) => theme.palette.primary.dark};
  }
`

export const DialogHeader = styled('div')`
  display: flex;
  justify-content: space-between;
`

export const DialogBody = styled('div')`
  padding: ${({ theme }) => theme.spacing(4, 3.75, 4, 2)};
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(2)};
`

export const DialogTitleWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.25)};
  align-items: center;
  justify-content: center;
`

export const DialogTitle = styled(Typography)`
  font-size: 14px;
`

export const FormControlWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.75)};
  width: 100%;
  align-items: center;
`

export const Label = styled(FormLabel)`
  width: 20%;
  font-size: 12px;
  text-align: right;
  color: ${({ theme }) => theme.palette.primary.light};
`

export const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
`

export const SaveButton = styled(Button)`
  font-size: 14px;
  box-shadow: none;
  padding: 0 ${({ theme }) => theme.spacing(1.5)};
  border-radius: 0;
  text-transform: capitalize;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.common.white};

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.dark};
  }
`

export const SaveIcon = styled(Icon)`
  height: 11px;
  width: 11px;

  & path {
    fill: ${({ theme }) => theme.palette.common.white};
  }
`

export const RoleSelector = styled(Select, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: 0;
  font-size: 14px;
  outline: none;
  border-radius: 0;

  & .MuiSelect-select {
    padding: 8px;
    border: 1px solid transparent;
  }

  & .MuiOutlinedInput-notchedOutline {
    outline: none;
    border: none;
    border-radius: 0;
  }

  ${({ error, theme }) =>
    error &&
    css`
      & .MuiSelect-select {
        border-radius: 0;
        border: 1px solid ${theme.palette.error.main};
      }
    `}
`

export const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
`

export const ErrorMessage = styled(FormHelperText)`
  margin: ${({ theme }) => theme.spacing(0.25, 1, 0)};
  color: ${({ theme }) => theme.palette.error.main};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const FullWidthFormControl = styled(FormControl)`
  display: flex;
  width: 100%;
`

export const InputText = styled(Input, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: 0;
  border: 1px solid transparent;

  & .MuiInput-input {
    padding: 8px;
    font-size: 14px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      font-size: 14px;
      font-family: Roboto, sans-serif;
      -webkit-text-fill-color: ${({ theme }) =>
        theme.palette.common.white} !important;
      -webkit-box-shadow: 0 0 0 30px
        ${({ theme }) => theme.palette.primary.main} inset !important;
      caret-color: ${({ theme }) => theme.palette.common.white};
    }
  }

  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.palette.error.main};
    `}
`
