import React, { MouseEvent, FC } from 'react'
import { format } from 'date-fns'
import Grid from '@mui/material/Grid'
import { grey } from '@mui/material/colors'
import { css, styled } from '@mui/material'
import { useReduxDispatch, useReduxSelector } from 'modules/core/hooks'
import {
  selectCalendarLayout,
  selectCalendarSelectedDate,
  setCalendarLayout,
  setCalendarSelectedDate,
} from 'modules/calendar/redux'

const Root = styled(Grid)`
  height: 100px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 100px;
    left: 40px;
    height: 1px;
    width: calc(100% - 48px);
    border-top: 1px solid #dadce0;
  }
`

const HeaderFirstColumn = styled('div')`
  height: 15px;
  margin-top: 85px;
  padding-left: 8px;
  border-right: 1px solid #dadce0;
`

const HeaderLabelFirst = styled('div')`
  position: absolute;
  top: -75px;
  left: -1px;
  height: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: ${({ theme }) => theme.palette.common.white};
  font-weight: 500;
  text-transform: uppercase;
`

const HeaderLabelSecond = styled('div')`
  position: absolute;
  top: -55px;
  left: -1px;
  height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #70757a;
`

const HeaderLabelDate = styled('span', {
  shouldForwardProp: prop => prop !== 'isToday',
})<{ isToday: boolean }>`
  width: 45px;
  height: 45px;
  line-height: 45px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  text-align: center;
  border: 1px solid ${({ theme }) => theme.palette.common.white};
  border-radius: 100%;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.common.white};
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${grey[200]};
    color: ${({ theme }) => theme.palette.primary.main};
  }

  ${({ isToday, theme }) =>
    isToday &&
    css`
      border-color: ${theme.createAlpha(theme.palette.secondary.main, 0.8)};
      background-color: ${theme.createAlpha(theme.palette.secondary.main, 0.8)};
      color: #ffffff;
      &:hover {
        border-color: ${theme.palette.secondary.main};
        background-color: ${theme.palette.secondary.main};
      }
    `}
`

const TimeColumnContainer = styled(Grid)`
  color: ${({ theme }) => theme.palette.text.secondary};
  background-color: transparent;
  height: auto;
  overflow-y: hidden;
  flex: none;
  display: flex;
  align-items: flex-start;
  min-width: 40px !important;
  max-width: 40px !important;
  margin-top: -8px;
  position: relative;
  box-sizing: border-box;
  margin-left: auto;
`

const TimeColumnElement = styled('div')`
  position: relative;
  height: 60px;
  padding-right: 8px;
  text-align: right;
  color: #70757a;
  font-size: 12px;
`

type CalendarHeaderProps = {
  selectedWeekIndex: number
  selectedWeek: Date[]
}

const CalendarHeader: FC<CalendarHeaderProps> = ({
  selectedWeekIndex,
  selectedWeek,
}) => {
  const dispatch = useReduxDispatch()
  const selectedDate = useReduxSelector(selectCalendarSelectedDate)
  const layout = useReduxSelector(selectCalendarLayout)

  const viewLayout = Array.from(
    Array(layout === 'week' ? 7 : layout === 'day' ? 1 : 0).keys()
  )

  const handleDayClick = (event: MouseEvent<HTMLSpanElement>) => {
    const gridParent = event.currentTarget.parentElement?.parentElement
    const dayData = gridParent?.getAttribute('data-day')
    if (dayData) {
      dispatch(setCalendarSelectedDate(new Date(dayData).getTime()))
      dispatch(setCalendarLayout('day'))
    }
  }

  return (
    <Root
      container
      spacing={0}
      direction='row'
      justifyItems='center'
      alignItems='stretch'
    >
      <TimeColumnContainer item xs={1}>
        <TimeColumnElement />
      </TimeColumnContainer>

      <Grid item xs>
        <Grid
          container
          spacing={0}
          direction='row'
          justifyItems='center'
          alignItems='flex-start'
          sx={{
            maxWidth: 'calc(100% - 8px)',
            height: '100%',
            flex: 'none',
            verticalAlign: 'top',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <HeaderFirstColumn />

          {viewLayout.map(index => {
            const day = layout === 'week' ? selectedWeek[index] : selectedDate
            const isToday =
              format(day, 'ddMMyyyy') === format(new Date(), 'ddMMyyyy')

            return (
              <Grid
                item
                xs
                id={`headerDay${index}`}
                data-group='day-header'
                data-day={day}
                sx={{
                  borderRight: '1px solid #e8eaed',
                  position: 'relative',
                  paddingRight: 12,
                  flex: '1 1 auto',
                  height: 15,
                  marginTop: '85px !important',
                }}
                key={`header-label-${layout}-${selectedWeekIndex}-${day}`}
              >
                <HeaderLabelFirst>
                  <span>{format(day, 'EEEEEE')}</span>
                </HeaderLabelFirst>
                <HeaderLabelSecond>
                  <HeaderLabelDate onClick={handleDayClick} isToday={isToday}>
                    {format(day, 'd')}
                  </HeaderLabelDate>
                </HeaderLabelSecond>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </Root>
  )
}

export default CalendarHeader
