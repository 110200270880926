import { CreateScheduleScheduleBody } from '@sporttotal-tv/dip-coaching-client'
import { api } from 'modules/core/api'
import {
  transformApiResults,
  transformIdApiResults,
} from 'modules/core/helpers'

export const CalendarApiService = {
  scheduleCreate: transformApiResults<CreateScheduleScheduleBody, void>(
    api.schedule.createSchedule
  ),
  scheduleDelete: transformIdApiResults<void, void>(
    api.schedule.deleteSchedule
  ),
}
