import * as yup from 'yup'
import { convertSecondsToTime, convertTimeToSeconds } from 'modules/core/utils'

export const assetSchema = yup.object().shape({
  title: yup.string().required('error.title').max(255, 'error.maxNumber'),
  description: yup.string().max(255, 'error.maxNumber'),
  start: yup
    .number()
    .typeError('error.wrongFormat')
    .min(0, 'error.start.AtLeastZero')
    .transform((value, originalValue) =>
      convertTimeToSeconds(originalValue, true)
    )
    .when('$maxTime', (maxTime, schema) =>
      maxTime
        ? schema.max(
            maxTime,
            JSON.stringify({
              translation: 'error.start.lessThan',
              params: { time: convertSecondsToTime(maxTime) },
            })
          )
        : schema
    )
    .required(),
  end: yup
    .number()
    .typeError('error.wrongFormat')
    .transform((value, originalValue) =>
      convertTimeToSeconds(originalValue, true)
    )
    .positive('error.end.greaterThanZero')
    .required('error.end.isRequired')
    .when('start', (start: number, schema) =>
      isNaN(start)
        ? schema.min(1, `error.end.greaterThanZero`)
        : schema.min(
            start + 1,
            JSON.stringify({
              translation: 'error.end.moreThan',
              params: { time: convertSecondsToTime(start) },
            })
          )
    )
    .when('$maxTime', (maxTime, schema) =>
      typeof maxTime === 'number'
        ? schema.max(
            maxTime,
            JSON.stringify({
              translation: 'error.end.lessThan',
              params: { time: convertSecondsToTime(maxTime) },
            })
          )
        : schema
    ),
  event: yup
    .string()
    .notOneOf([''], 'error.eventRequired')
    .required('error.eventRequired'),
  tags: yup.string(),
})
