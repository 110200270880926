import { ShapeHandler } from 'modules/annotator/types'
import {
  TelestrationShapeType,
  TelestrationTool,
} from 'modules/telestration/types'
import { Rect } from './Rect'
import { Circle } from './Circle'
import { Line } from './Line'
import { Arrow } from './Arrow'
import { Text } from './Text'
import { PlayerSelection } from './PlayerSelection'
import { Highlight } from './Highlight'
import { CurvedLine } from './CurvedLine'
import { MultiLine } from './MultiLine'

const shapeHandlers: Record<
  TelestrationTool,
  ShapeHandler<TelestrationShapeType>
> = {
  RECT: Rect,
  CIRCLE: Circle,
  PLAYER_SELECTION: PlayerSelection,
  LINE: Line,
  ARROW: Arrow,
  TEXT: Text,
  CURVED_LINE: CurvedLine,
  HIGHLIGHT: Highlight,
  MULTI_LINE: MultiLine,
} as const

export { shapeHandlers }
