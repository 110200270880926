import { teamPlayerInjuryMockup } from 'modules/team-player/utils'
import {
  CreatePlayerInjuryBody,
  PlayerInjury,
} from '@sporttotal-tv/dip-coaching-client'
import { generateId } from 'modules/core/helpers/generateId'

export const placeholderPlayerInjury = (
  payload?: CreatePlayerInjuryBody
): PlayerInjury => ({
  ...teamPlayerInjuryMockup,
  id: generateId(),
  ...payload,
})
