import { UserTabsData } from 'modules/user/types'

export const updateUserTabsDataWithRemovedItem = (
  tabsData: UserTabsData | undefined,
  id: string | undefined
) => ({
  ...Object.entries(tabsData || {}).reduce(
    (accTop, [keyTop, valueTop]) => ({
      ...accTop,
      [keyTop]: {
        ...Object.entries(valueTop || {}).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value.filter(item => item.i !== id),
          }),
          {}
        ),
      },
    }),
    {}
  ),
})
