import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

const rootElement = document.querySelector('#root') as HTMLElement
const root = ReactDOM.createRoot(rootElement)

root.render(
  <StrictMode>
    <App />
  </StrictMode>
)
