import { FC, useMemo, useRef } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { differenceInYears, format } from 'date-fns'
import { useTranslation } from 'react-i18next'
// components
import { Icon } from 'modules/core/components'
import { TeamPlayerSelector } from 'modules/team-player/components'
// hooks
import { useTeamPlayerProfileRetrieve } from 'modules/team-player/hooks'
import { useResizeObserver } from 'modules/core/hooks'
// styled
import { Badge } from 'modules/core/styled'
// utils
import {
  getComponentBreakpoint,
  media,
  checkValuePlaceholder,
} from 'modules/core/utils'
// constants
import { positions } from 'modules/team-player/constants'
// types
import { ComponentBreakpoints } from 'modules/layout/types'

import {
  FlexBlock,
  FlexItem,
  PhysicalAttributesWrapper,
  ItemWrapper,
  Label,
  NoPlayer,
  PlayerAvatar,
  DataWrapper,
  StatsWrapper,
  Title,
  Value,
  FoulWrapper,
  CardElement,
  Content,
  INFO_TITLE,
  INFO_CONTENT,
  STATUS_TITLE,
  STATUS_CONTENT,
  ATTRIBUTES_TITLE,
  POSITION_CONTENT,
  STATS_CONTENT,
  Root,
  StyledRating,
  StatsItem,
  AttributesLabel,
  IconWrapper,
  FootballField,
  TeamSelectWrapper,
  NoWrapSpan,
} from './TeamPlayerProfileView.styled'
import { GenericViewProps as TeamPlayerProfileViewProps } from 'modules/team-player/types'

export const TeamPlayerProfileView: FC<TeamPlayerProfileViewProps> = ({
  teamPlayer,
  setTeamPlayer,
}) => {
  const { t } = useTranslation('components', { keyPrefix: 'teamPlayers' })
  const teamPlayerProfileRetrieve = useTeamPlayerProfileRetrieve(teamPlayer?.id)

  const playerInfo = teamPlayerProfileRetrieve.data
  const containerRef = useRef<HTMLDivElement>(null)

  const { width, height } = useResizeObserver(containerRef)

  const heightBreakpoint: ComponentBreakpoints = useMemo(() => {
    return getComponentBreakpoint(height, {
      xs: 0,
      sm: 400,
      md: 550,
      lg: 800,
    })
  }, [height])

  const widthBreakpoint: ComponentBreakpoints = useMemo(() => {
    return getComponentBreakpoint(width, {
      xs: 0,
      sm: 400,
      md: 650,
      lg: 1000,
    })
  }, [width])

  const isCompactDisplay = useMemo(
    () => media.max(heightBreakpoint, 'sm') || media.max(widthBreakpoint, 'sm'),
    [heightBreakpoint, widthBreakpoint]
  )

  const physicalAttributes = useMemo(
    () => (
      <PhysicalAttributesWrapper>
        <ItemWrapper>
          <Label>{t('profile.height')}</Label>
          <Value>
            {checkValuePlaceholder(
              playerInfo?.player?.height_cm,
              Number(+(playerInfo?.player?.height_cm ?? 0) / 100).toPrecision(3)
            )}
          </Value>
        </ItemWrapper>
        <ItemWrapper>
          <Label>{t('profile.weight')}</Label>
          <Value>{checkValuePlaceholder(playerInfo?.player?.weight_kg)}</Value>
        </ItemWrapper>
        <ItemWrapper>
          <Label>{t('profile.age')}</Label>
          <Value>
            {checkValuePlaceholder(
              playerInfo?.player?.birthdate,
              differenceInYears(
                new Date(),
                new Date(+(playerInfo?.player?.birthdate ?? 0) * 1000)
              )
            )}
          </Value>
        </ItemWrapper>
      </PhysicalAttributesWrapper>
    ),
    [playerInfo?.player, t]
  )

  if (!teamPlayer || !playerInfo) {
    return (
      <Root ref={containerRef}>
        <NoPlayer>
          <TeamPlayerSelector
            showError={false}
            player={teamPlayer ?? null}
            setPlayer={setTeamPlayer ?? (() => {})}
            centered={true}
          />
        </NoPlayer>
      </Root>
    )
  }

  return (
    <Root ref={containerRef}>
      <DataWrapper
        widthBreakpoint={widthBreakpoint}
        heightBreakpoint={heightBreakpoint}
      >
        <Title area={INFO_TITLE} sx={{ justifyContent: 'space-between' }}>
          <div>{t('profile.info')}</div>
          {media.max(widthBreakpoint, 'sm') && (
            <TeamSelectWrapper spacing={widthBreakpoint === 'sm'}>
              <TeamPlayerSelector
                showError={false}
                player={teamPlayer}
                setPlayer={setTeamPlayer ?? (() => {})}
              />
            </TeamSelectWrapper>
          )}
        </Title>
        <Content area={INFO_CONTENT} widthBreakpoint={widthBreakpoint}>
          <FlexBlock compact={isCompactDisplay}>
            <ItemWrapper sx={{ flex: 'unset' }}>
              <PlayerAvatar>Image</PlayerAvatar>
            </ItemWrapper>
            <FlexItem compact={isCompactDisplay}>
              <ItemWrapper>
                <Label>{t('profile.name')}</Label>
                <Value>
                  {checkValuePlaceholder(playerInfo.player?.first_name)}
                  {checkValuePlaceholder(playerInfo.player?.last_name)}
                </Value>
              </ItemWrapper>
              <FlexBlock compact={media.max(widthBreakpoint, 'sm')}>
                <ItemWrapper>
                  <Label>{t('profile.position')}</Label>
                  <Value>
                    {checkValuePlaceholder(
                      playerInfo.player?.normal_position,
                      playerInfo.player &&
                        positions[playerInfo.player.normal_position]
                    )}
                  </Value>
                </ItemWrapper>
                {media.max(widthBreakpoint, 'sm') && <ItemWrapper />}
                <ItemWrapper>
                  <Label>{t('profile.basedIn')}</Label>
                  <Value>
                    {checkValuePlaceholder(
                      playerInfo.player?.nationality,
                      <>
                        {playerInfo.player?.nationality && (
                          <ReactCountryFlag
                            countryCode={playerInfo.player.nationality}
                            svg
                          />
                        )}

                        {playerInfo.player?.nationality}
                      </>
                    )}
                  </Value>
                </ItemWrapper>
              </FlexBlock>
              {media.max(widthBreakpoint, 'sm') && physicalAttributes}
            </FlexItem>
          </FlexBlock>

          {media.min(widthBreakpoint, 'md') && physicalAttributes}
          <FlexBlock compact={isCompactDisplay}>
            <ItemWrapper>
              <Label>{t('profile.reputation')}</Label>
              <Value>
                {checkValuePlaceholder(
                  playerInfo.player?.rating,
                  <StyledRating
                    defaultValue={playerInfo.player?.rating}
                    readOnly
                  />
                )}
              </Value>
            </ItemWrapper>
            <ItemWrapper>
              <Label>{t('profile.alert')}</Label>
              <Value>
                <Badge variant='success'>Info</Badge>
              </Value>
            </ItemWrapper>
          </FlexBlock>
          <FlexBlock compact={isCompactDisplay}>
            <ItemWrapper>
              <Label>{t('profile.opinion')}</Label>
              <Value>-</Value>
            </ItemWrapper>

            <ItemWrapper>
              <Label>{t('profile.agent')}</Label>
              <Value>{`${checkValuePlaceholder(
                playerInfo.transfer?.agent?.firstname
              )} ${checkValuePlaceholder(
                playerInfo.transfer?.agent?.lastname
              )}`}</Value>
            </ItemWrapper>
          </FlexBlock>
        </Content>

        <Title
          area={STATUS_TITLE}
          hidden={
            media.max(widthBreakpoint, 'xs') &&
            media.max(heightBreakpoint, 'sm')
          }
        >
          {t('profile.status')}
        </Title>
        <Content
          area={STATUS_CONTENT}
          widthBreakpoint={widthBreakpoint}
          hidden={
            media.max(widthBreakpoint, 'xs') &&
            media.max(heightBreakpoint, 'sm')
          }
          dividerDisabled={media.max(widthBreakpoint, 'sm')}
        >
          <FlexBlock compact={isCompactDisplay}>
            <ItemWrapper>
              <Label>{t('profile.mathLoad')}</Label>
              <Value>
                {checkValuePlaceholder(
                  playerInfo.status?.math_load,
                  t(`profile.mathLoadValues.${playerInfo.status?.math_load}`)
                )}
              </Value>
            </ItemWrapper>
            <ItemWrapper>
              <Label>{t('profile.trainingLoad')}</Label>
              <Value>
                {checkValuePlaceholder(
                  playerInfo.status?.training_load,
                  t(
                    `profile.trainingLoadValues.${playerInfo.status?.training_load}`
                  )
                )}
              </Value>
            </ItemWrapper>
          </FlexBlock>
          <FlexBlock compact={isCompactDisplay}>
            <ItemWrapper>
              <Label>{t('profile.injurySusceptible')}</Label>
              <Value>
                {checkValuePlaceholder(
                  playerInfo.status?.injury_suscetible,
                  t(
                    `profile.injurySusceptibleValues.${playerInfo.status?.injury_suscetible}`
                  )
                )}
              </Value>
            </ItemWrapper>
            <ItemWrapper>
              <Label>{t('profile.constitution')}</Label>
              <Value>{checkValuePlaceholder(playerInfo.status?.health)}</Value>
            </ItemWrapper>
          </FlexBlock>
          <FlexBlock compact={isCompactDisplay}>
            <ItemWrapper>
              <Label>{t('profile.sharpness')}</Label>
              <Value>{checkValuePlaceholder(playerInfo.status?.form)}</Value>
            </ItemWrapper>
            <ItemWrapper>
              <Label>{t('profile.overallRisk')}</Label>
              <Value>
                {checkValuePlaceholder(
                  playerInfo.status?.overall_risk,
                  t(
                    `profile.overallRiskValues.${playerInfo.status?.overall_risk}`
                  )
                )}
              </Value>
            </ItemWrapper>
          </FlexBlock>
          <FlexBlock compact={isCompactDisplay}>
            <ItemWrapper>
              <Label>{t('profile.currentInjury')}</Label>
              <Value>
                {checkValuePlaceholder(playerInfo.injury?.current_injury)}
              </Value>
            </ItemWrapper>
            <ItemWrapper>
              <Label>{t('profile.dateInjury')}</Label>
              <Value>
                {checkValuePlaceholder(
                  playerInfo.injury?.date_of_injury,
                  playerInfo.injury &&
                    format(
                      new Date(+playerInfo.injury?.date_of_injury * 1000),
                      'dd/MM/yy'
                    )
                )}
              </Value>
            </ItemWrapper>
          </FlexBlock>
          <FlexBlock compact={isCompactDisplay}>
            <ItemWrapper>
              <Label>{t('profile.rehabPeriod')}</Label>
              <Value>
                {checkValuePlaceholder(
                  playerInfo.injury?.rehab_period,
                  `${playerInfo.injury?.rehab_period_min}-${
                    playerInfo.injury?.rehab_period_min
                  } ${t(
                    `profile.rehabPeriodValues.${playerInfo.injury?.rehab_period}`
                  )}`
                )}
              </Value>
            </ItemWrapper>
            <ItemWrapper>
              <Label>{t('profile.injuryStatus')}</Label>
              <Value>
                <Badge variant='error'>
                  {checkValuePlaceholder(
                    playerInfo.injury?.injury_status,
                    t(
                      `profile.injuryStatusValues.${playerInfo.injury?.injury_status}`
                    )
                  )}
                </Badge>
              </Value>
            </ItemWrapper>
          </FlexBlock>
        </Content>
        <Title
          area={ATTRIBUTES_TITLE}
          hidden={
            (media.max(widthBreakpoint, 'xs') &&
              media.max(heightBreakpoint, 'md')) ||
            widthBreakpoint === 'sm'
          }
        >
          <div>{t('profile.attributes')}</div>
          <AttributesLabel hidden={media.min(heightBreakpoint, 'md')}>
            {t('profile.positions')}
          </AttributesLabel>
        </Title>
        <Content
          area={POSITION_CONTENT}
          dividerDisabled={media.max(widthBreakpoint, 'md')}
          hidden={
            (media.max(widthBreakpoint, 'xs') &&
              media.max(heightBreakpoint, 'md')) ||
            widthBreakpoint === 'sm'
          }
        >
          <ItemWrapper>
            <AttributesLabel hidden={media.max(heightBreakpoint, 'sm')}>
              {t('profile.positions')}
            </AttributesLabel>
            <IconWrapper>
              <FootballField
                type='Football'
                selectedPositions={playerInfo.player?.normal_position ?? ''}
                wrapperHeight={height}
                smallView={media.max(widthBreakpoint, 'sm')}
              />
            </IconWrapper>
          </ItemWrapper>
        </Content>
        <Content
          area={STATS_CONTENT}
          hidden={media.max(widthBreakpoint, 'md')}
          dividerDisabled={media.max(heightBreakpoint, 'sm')}
        >
          <AttributesLabel>{t('profile.stats')}</AttributesLabel>
          <StatsWrapper
            columnView={
              media.min(widthBreakpoint, 'lg') &&
              media.max(heightBreakpoint, 'sm')
            }
          >
            <StatsItem>
              <Label>{t('profile.goals')}</Label>
              <Value>{checkValuePlaceholder(playerInfo.stats?.goals)}</Value>
            </StatsItem>
            <StatsItem>
              <Label>{t('profile.shots')}</Label>
              <Value>{checkValuePlaceholder(playerInfo.stats?.shots)}</Value>
            </StatsItem>
            <StatsItem>
              <Label>
                {t('profile.shots')}{' '}
                <NoWrapSpan>{t('profile.onTarget')}</NoWrapSpan>
              </Label>
              <Value>
                {checkValuePlaceholder(playerInfo.stats?.shots_on_target)}
              </Value>
            </StatsItem>
            <StatsItem>
              <Label>{t('profile.penaltiesScored')}</Label>
              <Value>
                {checkValuePlaceholder(playerInfo.stats?.penalties_scored)}
              </Value>
            </StatsItem>
            <StatsItem>
              <Label>{t('profile.penaltiesTaken')}</Label>
              <Value>
                {checkValuePlaceholder(playerInfo.stats?.penalties_taken)}
              </Value>
            </StatsItem>
            <StatsItem>
              <Label>{t('profile.passesCompleted')}</Label>
              <Value>
                {checkValuePlaceholder(playerInfo.stats?.passes_completed)}
              </Value>
            </StatsItem>
            <StatsItem>
              <Label>{t('profile.passesAttempt')}</Label>
              <Value>
                {checkValuePlaceholder(playerInfo.stats?.passes_attempt)}
              </Value>
            </StatsItem>
            <StatsItem>
              <Label>{t('profile.mistakes')}</Label>
              <Value>
                {checkValuePlaceholder(
                  playerInfo.stats?.mistake_leading_to_goal
                )}
              </Value>
            </StatsItem>
            <StatsItem>
              <Label>{t('profile.crossAttempted')}</Label>
              <Value>
                {checkValuePlaceholder(playerInfo.stats?.cross_attempted)}
              </Value>
            </StatsItem>
            <StatsItem>
              <Label>{t('profile.crossCompleted')}</Label>
              <Value>
                {checkValuePlaceholder(playerInfo.stats?.cross_completed)}
              </Value>
            </StatsItem>
            <StatsItem
              rowView={
                media.min(widthBreakpoint, 'lg') &&
                media.max(heightBreakpoint, 'sm')
              }
            >
              <Label>{t('profile.fouls')}</Label>
              <FoulWrapper>
                <CardElement>
                  {playerInfo.stats &&
                    (playerInfo.stats.red_card ?? 0) +
                      (playerInfo.stats.yellow_card ?? 0)}
                </CardElement>
                <CardElement>
                  <Icon name='yellow-card' />
                  {checkValuePlaceholder(playerInfo.stats?.yellow_card)}
                </CardElement>
                <CardElement>
                  <Icon name='red-card' />
                  {checkValuePlaceholder(playerInfo.stats?.red_card)}
                </CardElement>
              </FoulWrapper>
            </StatsItem>
          </StatsWrapper>
        </Content>
      </DataWrapper>
    </Root>
  )
}
