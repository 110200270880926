import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { useQueryRetry } from 'modules/core/hooks'

export const MATCH_DATA_RETRIEVE_CACHE_KEY = 'match-data-retrieve'

export const useMatchData = (id?: string) => {
  const handleQueryRetry = useQueryRetry({ suppressError: true })

  return useQuery(
    [MATCH_DATA_RETRIEVE_CACHE_KEY, id],
    () => ApiService.match.retrieveData(id),
    {
      enabled: !!id,
      retry: handleQueryRetry,
      staleTime: Infinity,
    }
  )
}
