import { Navigate } from 'react-router-dom'
import { useUser } from 'modules/user/hooks'

import { DEV_ENV_HOSTS } from './constants'
import { Wrapper, Root } from './Dev.styled'

export const Dev = () => {
  const user = useUser()

  if (!DEV_ENV_HOSTS.includes(window.location.origin)) {
    return <Navigate to={'users'} />
  }

  return (
    <Root>
      <Wrapper>
        <pre>{JSON.stringify(user.data, null, 2)}</pre>
      </Wrapper>
    </Root>
  )
}
