import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { Match } from '@sporttotal-tv/dip-coaching-client'

export interface MatchState {
  prevMatch: Match | null
  nextMatch: Match | null
}

const initialState: MatchState = {
  prevMatch: null,
  nextMatch: null,
}

export const matchSlice = createSlice({
  name: 'match',
  initialState,
  reducers: {
    setPrevMatch: (state, action: PayloadAction<Match | null>) => {
      state.prevMatch = action.payload
    },
    setNextMatch: (state, action: PayloadAction<Match | null>) => {
      state.nextMatch = action.payload
    },
  },
})

export const { setPrevMatch, setNextMatch } = matchSlice.actions

export const matchReducer = matchSlice.reducer
