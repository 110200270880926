import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
// Components
import { IconButton } from 'modules/core/components'
import { Menu, MenuItem } from 'modules/core/components/Menu/PopoverMenu'
import {
  Table,
  Row,
  HeaderElement,
  BodyElement,
  BodyElementSmall,
  HeaderElementSmall,
} from 'modules/core/styled'
// Types
import { PlayingSurface } from '@sporttotal-tv/dip-coaching-client'
import { PlayingSurfaceTableProps } from './PlayingSurfaceTable.interface'

export const PlayingSurfaceTable: FC<PlayingSurfaceTableProps> = ({
  playingSurfaces,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'playingSurface' })
  const [selectedSurface, setSelectedSurface] = useState<PlayingSurface>()
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()

  const handleMenuClick =
    (surface: PlayingSurface) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      setSelectedSurface(surface)
      setAnchorEl(event.currentTarget)
    }

  const handleClose = () => {
    setAnchorEl(undefined)
    setSelectedSurface(undefined)
  }

  return (
    <>
      <Table>
        <Row>
          <HeaderElement>{t('table.row.name')}</HeaderElement>
          <HeaderElement>{t('table.row.location')}</HeaderElement>
          <HeaderElementSmall />
        </Row>
        {playingSurfaces.map(surface => (
          <Row key={surface.id}>
            <BodyElement>{surface.name}</BodyElement>
            <BodyElement>{surface.location}</BodyElement>
            <BodyElementSmall>
              <IconButton
                name='vertical-menu'
                onClick={handleMenuClick(surface)}
              />
            </BodyElementSmall>
          </Row>
        ))}
      </Table>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose()
            selectedSurface && onEdit(selectedSurface)
          }}
        >
          {t('table.actions.edit')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            selectedSurface && onDelete(selectedSurface.id)
          }}
        >
          {t('table.actions.delete')}
        </MenuItem>
      </Menu>
    </>
  )
}
