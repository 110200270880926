import { Player } from '@sporttotal-tv/dip-coaching-client'

type FootballPositions = Extract<
  Player['normal_position'],
  | 'GK'
  | 'RB'
  | 'LB'
  | 'RWB'
  | 'LWB'
  | 'CB'
  | 'CDM'
  | 'CM'
  | 'CAM'
  | 'RM'
  | 'LM'
  | 'RW'
  | 'LW'
  | 'RF'
  | 'LF'
  | 'CF'
  | 'ST'
>

export const footballPositionPlacements: Record<
  FootballPositions,
  { left: string; top: string }
> = {
  GK: { left: '50%', top: '85%' },
  RB: { left: '85%', top: '74%' },
  LB: { left: '15%', top: '74%' },
  RWB: { left: '85%', top: '62%' },
  LWB: { left: '15%', top: '62%' },
  CB: { left: '50%', top: '74%' },
  CDM: { left: '50%', top: '62%' },
  CM: { left: '50%', top: '50%' },
  CAM: { left: '50%', top: '38%' },
  RM: { left: '85%', top: '50%' },
  LM: { left: '15%', top: '50%' },
  RW: { left: '85%', top: '38%' },
  LW: { left: '15%', top: '38%' },
  RF: { left: '85%', top: '26%' },
  LF: { left: '15%', top: '26%' },
  CF: { left: '50%', top: '26%' },
  ST: { left: '50%', top: '15%' },
}
