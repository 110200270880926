import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
// components
import { ComponentsView } from 'modules/layout/components'
// hooks
import { useReduxDispatch } from 'modules/core/hooks'
import { useTabLayoutsStore } from 'modules/layout/hooks'
// constants
import { BREAKPOINTS_VALUES } from 'modules/layout/constants'
import { teamLayoutKeys, teamLayoutPalette } from 'modules/team/constants'
// types
import { LayoutConfigBreakpoints } from 'modules/layout/types'
// redux
import {
  setBreakpoint,
  setLayoutCache,
  setLayoutKeys,
  setLayoutsWithBreakpoints,
  setLayoutTypes,
} from 'modules/layout/redux'

export const Team = () => {
  const { tab } = useParams()
  const dispatch = useReduxDispatch()
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const { layoutsWithBreakpoints, isLoading, layoutTypes, layoutCache } =
    useTabLayoutsStore({
      tab: tab ?? '',
      tabsDataKey: teamLayoutKeys.tabsDataKey,
      layoutTypesKey: teamLayoutKeys.layoutTypesKey,
    })

  const breakpoint = useMemo(() => {
    const breakpointValue = Object.entries(BREAKPOINTS_VALUES).find(
      ([, value]) => {
        return window.innerWidth >= value
      }
    )

    return (breakpointValue?.[0] as LayoutConfigBreakpoints) ?? 'xxs'
  }, [])

  useEffect(() => {
    if (isInitialized || isLoading) return
    dispatch(setBreakpoint(breakpoint))
    dispatch(setLayoutTypes(layoutTypes))
    dispatch(setLayoutsWithBreakpoints(layoutsWithBreakpoints))
    dispatch(setLayoutKeys(teamLayoutKeys))
    dispatch(setLayoutCache(layoutCache))
    setIsInitialized(true)
  }, [
    layoutCache,
    isLoading,
    isInitialized,
    breakpoint,
    layoutTypes,
    layoutsWithBreakpoints,
    dispatch,
  ])

  if (isLoading || !isInitialized) return null
  return <ComponentsView paletteInfo={teamLayoutPalette} />
}
