import {
  STATISTIC_TEAM_REGEX,
  STATISTIC_STAT_REGEX,
} from 'modules/match-info/constants'
import { Statistic } from '../types'

export const parseMatchStatistics = (
  data: Record<string, string | number>
): Statistic[] =>
  Object.entries(data).reduce<Statistic[]>(
    (matchStats, [statName, statValue]) => {
      const statTeamMatch = statName.match(STATISTIC_TEAM_REGEX)
      const statTypeMatch = statName.match(STATISTIC_STAT_REGEX)

      if (!statTeamMatch || !statTypeMatch) return matchStats

      const [statTeam] = statTeamMatch
      const [statType] = statTypeMatch

      const matchStat = matchStats.find(({ name }) => name === statType)
      const statTeamKey =
        statTeam === 'team_A' ? 'homeTeamValue' : 'awayTeamValue'

      if (!matchStat) {
        const otherTeamKey =
          statTeamKey === 'homeTeamValue' ? 'awayTeamValue' : 'homeTeamValue'

        const parsedStat = {
          name: statType,
        } as Statistic

        parsedStat[statTeamKey] = statValue
        parsedStat[otherTeamKey] = 0

        matchStats.push(parsedStat)
      } else {
        matchStat[statTeamKey] = statValue
      }

      return matchStats
    },
    []
  )
