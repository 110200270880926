import React from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import CalendarToolbar from './CalendarToolbar'
import CalendarDrawer from './CalendarDrawer'
import CalendarMain from './CalendarMain'
import CalendarEventDialog from './CalendarEventDialog'
import CalendarEventViewDialog from './CalendarEventViewDialog'
import { styled } from '@mui/material'

const Root = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`

const MainContentWrapper = styled('div')`
  display: flex;
  height: calc(100% - 64px);
  background-color: ${({ theme }) => theme.palette.primary.main};
`

export const Calendar = () => {
  return (
    <Root>
      <CssBaseline />
      <CalendarToolbar />

      <MainContentWrapper>
        <CalendarDrawer />
        <CalendarMain />
      </MainContentWrapper>

      <CalendarEventDialog />
      <CalendarEventViewDialog />
    </Root>
  )
}
