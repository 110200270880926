import {
  FrameListParams,
  CreateFrameBody,
  FrameRetrieveParams,
  CreateFramesPresentationBody,
  FramesPresentation,
  Frame,
  FrameList,
  UpdateFrameBody,
} from '@sporttotal-tv/dip-coaching-client'
import { api } from 'modules/core/api'
import {
  transformApiResults,
  transformIdApiResults,
} from 'modules/core/helpers'

export const FrameApiService = {
  retrieve: transformApiResults<FrameRetrieveParams, Frame>(
    api.frames.retrieve
  ),
  create: transformApiResults<CreateFrameBody, Frame>(api.frames.create),
  list: transformApiResults<FrameListParams, FrameList>(api.frames.list),
  delete: transformApiResults<string, Frame>(api.frames.delete),
  update: transformIdApiResults<UpdateFrameBody, Frame>(api.frames.update),
  createPresentation: transformApiResults<
    CreateFramesPresentationBody,
    FramesPresentation
  >(api.framesPresentation.create),
}
