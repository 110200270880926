import { css, styled, Typography } from '@mui/material'

export const Root = styled('div', {
  shouldForwardProp: prop => prop !== 'active' && prop !== 'isDragging',
})<{ active?: boolean; isDragging?: boolean }>`
  position: relative;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.25)};

  padding: ${({ theme }) => theme.spacing(1.5, 0)};
  margin: ${({ theme }) => theme.spacing(0, 2)};

  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.light};

  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.palette.primary.light};
  }

  &:before {
    content: '';
    width: 2px;
    height: calc(100% + 2px);
    top: -1px;
    transition: all 0.3s;
    position: absolute;
    left: -${({ theme }) => theme.spacing(3)};
    background-color: ${({ theme }) => theme.palette.secondary.main};
    opacity: 0;
  }

  ${({ active }) =>
    active &&
    css`
      &:before {
        opacity: 1;
      }
    `}

  ${({ isDragging }) =>
    isDragging &&
    css`
      opacity: 0.4;
    `}
`

export const EventIconWrapper = styled('div')`
  min-width: 20px;
  margin-top: ${({ theme }) => theme.spacing(1)};

  & > svg {
    width: 100%;
    max-height: 20px;
    height: auto;
  }
`
export const MainInfoWrapper = styled('div')`
  width: calc(100% - 30px);
`

export const ClipInfoWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(2)};
`

export const ClipInfoItem = styled('div')`
  flex: 1;
  overflow: hidden;
  position: relative;
`

export const TitleRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StatusRow = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
`

export const Title = styled(Typography, {
  shouldForwardProp: prop => prop !== 'active',
})<{ active?: boolean }>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.secondary.main};
  margin: ${({ theme }) => theme.spacing(0.5, 0)};
  cursor: not-allowed;

  ${({ active }) =>
    active &&
    css`
      cursor: pointer;
    `}
`

export const Status = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.light};
  margin: ${({ theme }) => theme.spacing(0.5, 0)};
`

export const PlayClip = styled(Typography)`
  text-overflow: ellipsis;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  margin: ${({ theme }) => theme.spacing(0.5, 0)};
`

export const ClipDescription = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.light};
`

export const ClipDescriptionWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(2)};
`

export const ClipDescriptionInfo = styled('div')`
  padding: ${({ theme }) => theme.spacing(1, 0)};
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.light};
`

export const CollapseControlsWrapper = styled('div')`
  display: flex;
  padding-top: ${({ theme }) => theme.spacing(1)};
`

export const TagWrapper = styled('div')`
  display: flex;
  flex: 1;
  height: 23px;
  column-gap: ${({ theme }) => theme.spacing(1)};
  row-gap: ${({ theme }) => theme.spacing(1)};
  flex-wrap: wrap;
  overflow: hidden;
`

export const Tag = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.light};
  padding: ${({ theme }) => theme.spacing(0.5, 2)};
  text-transform: uppercase;
  font-size: 10px;
`

export const FlexSpaceConsumer = styled('div')`
  flex: 1;
`
