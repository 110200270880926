import { Player } from '@sporttotal-tv/dip-coaching-client'

export const teamPlayerMockup: Player = {
  id: 'some-id',
  avatar_url: null,
  first_name: 'First Name',
  middle_name: 'Middle Name',
  last_name: 'Last Name',
  nationality: 'en',
  birthdate: 124,
  height_cm: 200,
  weight_kg: 100,
  rating: 3,
  is_capitan: false,
  normal_position: 'GK',
  created_at: 1655886764,
  updated_at: 1655886764,
}
