import React, { FC } from 'react'
import Grid from '@mui/material/Grid'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import CalendarHeader from './CalendarHeader'
import CalendarBoard from './CalendarBoard'
import CalendarBoardDragLayer from './CalendarBoardDragLayer'
import { styled } from '@mui/material'

const TimeColumnContainer = styled(Grid)`
  color: ${({ theme }) => theme.palette.text.secondary};
  background-color: transparent;
  height: auto;
  overflow-y: hidden;
  flex: none;
  display: flex;
  align-items: flex-start;
  min-width: 40px !important;
  max-width: 40px !important;
  margin-top: -8px;
`

const TimeColumnElement = styled('div')`
  position: relative;
  height: 60px;
  padding-right: 8px;
  text-align: right;
  color: #70757a;
  font-size: 16px;
  transform: translateY(-4px);
`

const Root = styled('div')`
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
  padding-top: 1px;
  background-color: ${({ theme }) => theme.palette.primary.main};
`

const Body = styled(Grid)`
  height: calc(100% - 64px);
  overflow: scroll;
  align-items: stretch;
`

const TimeColumn = styled('div')`
  position: relative;
  box-sizing: border-box;
  margin-left: auto;
`

type CalendarLayoutDayWeekProps = {
  selectedWeekIndex: number
  selectedWeek: Date[]
}

const CalendarLayoutDayWeek: FC<CalendarLayoutDayWeekProps> = ({
  selectedWeekIndex,
  selectedWeek,
}) => {
  return (
    <Root>
      <CalendarHeader
        selectedWeekIndex={selectedWeekIndex}
        selectedWeek={selectedWeek}
      />

      <Body
        container
        spacing={0}
        direction='row'
        justifyItems='center'
        alignItems='stretch'
      >
        <TimeColumnContainer item xs={1}>
          <TimeColumn>
            <TimeColumnElement />
            {Array.from(Array(23).keys()).map(index => {
              return (
                <TimeColumnElement key={`time-${index}`}>
                  <span>{index + 1}</span>
                </TimeColumnElement>
              )
            })}
            <TimeColumnElement />
          </TimeColumn>
        </TimeColumnContainer>

        <Grid
          item
          xs
          sx={{
            overflowX: 'auto',
            overflowY: 'scroll',
            display: 'flex',
            alignItems: 'flex-start',
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <CalendarBoard
              selectedWeekIndex={selectedWeekIndex}
              selectedWeek={selectedWeek}
            />
            <CalendarBoardDragLayer />
          </DndProvider>
        </Grid>
      </Body>
    </Root>
  )
}

export default CalendarLayoutDayWeek
