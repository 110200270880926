import { FC } from 'react'
// Hooks
import { useTranslation } from 'react-i18next'
// Components
import { Button, DialogActions, DialogContent, Typography } from '@mui/material'
import { StyledDialog, Title } from './ConfirmDialog.styled'
// Types
import {
  ConfirmDialogProps,
  ConfirmDialogState,
} from './ConfirmDialog.interface'

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  options: { title, description, text },
  onResult,
  open,
}) => {
  const { t } = useTranslation('components', { keyPrefix: 'dialog' })

  return (
    <StyledDialog
      open={open}
      onClose={() => onResult(ConfirmDialogState.CLOSED)}
    >
      {title && <Title>{title}</Title>}
      {description && (
        <DialogContent>
          <Typography variant='body1'>{description}</Typography>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          onClick={() => onResult(ConfirmDialogState.CANCELED)}
          color='error'
        >
          {text?.cancel ?? t('cancel')}
        </Button>
        <Button
          onClick={() => onResult(ConfirmDialogState.SUBMITTED)}
          color='success'
        >
          {text?.submit ?? t('yes')}
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export { ConfirmDialogState }
