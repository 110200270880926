import { styled, css } from '@mui/material'
import { IconButton } from 'modules/core/components/IconButton'

export const Dots = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'active',
})<{ active?: boolean }>`
  transition: all 0.3s;

  ${({ active }) =>
    active &&
    css`
      transform: rotate(90deg);
    `}
`
