import { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { Player } from '@sporttotal-tv/dip-coaching-client'
// components
import {
  ActionButton,
  ModalDialog,
  TextInput,
  TextInputErrorMessage,
  TextInputLabel,
} from 'modules/core/components'
import { TeamPlayerSelector } from 'modules/team-player/components'
// hooks
import { useTError } from 'modules/core/i18n/hooks'
import {
  useTeamPlayerStatusCreate,
  useTeamPlayerStatusUpdate,
} from 'modules/team-player/hooks'
// styled
import { OptionsSelector, OptionsSelectorMenuItem } from 'modules/core/styled'
// constants
import {
  teamPlayerStatusInjurySusceptible,
  teamPlayerStatusMathLoads,
  teamPlayerStatusOverallRisks,
  teamPlayerStatusTrainingLoads,
} from 'modules/team-player/constants'
// schema
import { teamPlayerStatusSchema } from 'modules/team-player/schemas'
// types
import { TeamPlayerStatusFormData } from 'modules/team-player/types'

import { StatusModalProps } from './StatusView.interface'
import {
  PlayerSelectorWrapper,
  InputWrapper,
  FormControlWrapper,
} from './StatusView.styled'

export const StatusModal: FC<StatusModalProps> = ({
  open,
  onClose,
  title,
  item,
}) => {
  const { t } = useTranslation('components', { keyPrefix: 'teamPlayers' })
  const { tError } = useTError(['components'])
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
  } = useForm<TeamPlayerStatusFormData>({
    resolver: yupResolver(teamPlayerStatusSchema),
    defaultValues: {
      mathLoad: item?.math_load,
      trainingLoad: item?.training_load,
      injurySusceptible: item?.injury_suscetible,
      health: item?.health,
      form: item?.form,
      overallRisk: item?.overall_risk,
    },
  })

  const teamPlayerStatusUpdate = useTeamPlayerStatusUpdate()
  const teamPlayerStatusCreate = useTeamPlayerStatusCreate()

  const onSubmit = (data: TeamPlayerStatusFormData) => {
    if (item) {
      teamPlayerStatusUpdate.mutate(
        {
          id: item.id,
          params: {
            math_load: data.mathLoad,
            training_load: data.trainingLoad,
            injury_suscetible: data.injurySusceptible,
            health: data.health,
            form: data.form,
            overall_risk: data.overallRisk,
          },
        },
        {
          onSuccess: onClose,
        }
      )
      return
    }

    if (selectedPlayer) {
      teamPlayerStatusCreate.mutate(
        {
          player_id: selectedPlayer.id,
          math_load: data.mathLoad,
          training_load: data.trainingLoad,
          injury_suscetible: data.injurySusceptible,
          health: data.health,
          form: data.form,
          overall_risk: data.overallRisk,
        },
        {
          onSuccess: onClose,
        }
      )
    }
  }

  return (
    <ModalDialog open={open} onClose={onClose} title={title}>
      <>
        {!item && (
          <PlayerSelectorWrapper>
            <TeamPlayerSelector
              showError={isSubmitted}
              player={selectedPlayer}
              setPlayer={setSelectedPlayer}
            />
          </PlayerSelectorWrapper>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper>
            <TextInputLabel>{t('modal.status.fields.mathLoad')}</TextInputLabel>
            <FormControlWrapper>
              <Controller
                name='mathLoad'
                control={control}
                render={({ field }) => (
                  <OptionsSelector
                    inputProps={{ 'aria-label': 'mathLoad' }}
                    error={!!errors.mathLoad?.message}
                    {...field}
                    value={field.value ?? ''}
                  >
                    {teamPlayerStatusMathLoads.map(item => (
                      <OptionsSelectorMenuItem key={item} value={item}>
                        {t(`modal.status.values.mathLoad.${item}`)}
                      </OptionsSelectorMenuItem>
                    ))}
                  </OptionsSelector>
                )}
              />
              <TextInputErrorMessage>
                {tError(errors.mathLoad?.message)}
              </TextInputErrorMessage>
            </FormControlWrapper>
          </InputWrapper>
          <InputWrapper>
            <TextInputLabel>
              {t('modal.status.fields.trainingLoad')}
            </TextInputLabel>
            <FormControlWrapper>
              <Controller
                name='trainingLoad'
                control={control}
                render={({ field }) => (
                  <OptionsSelector
                    inputProps={{ 'aria-label': 'trainingLoad' }}
                    error={!!errors.trainingLoad?.message}
                    {...field}
                    value={field.value ?? ''}
                  >
                    {teamPlayerStatusTrainingLoads.map(item => (
                      <OptionsSelectorMenuItem key={item} value={item}>
                        {t(`modal.status.values.trainingLoad.${item}`)}
                      </OptionsSelectorMenuItem>
                    ))}
                  </OptionsSelector>
                )}
              />
              <TextInputErrorMessage>
                {tError(errors.trainingLoad?.message)}
              </TextInputErrorMessage>
            </FormControlWrapper>
          </InputWrapper>
          <InputWrapper>
            <TextInputLabel>
              {t('modal.status.fields.injurySusceptible')}
            </TextInputLabel>
            <FormControlWrapper>
              <Controller
                name='injurySusceptible'
                control={control}
                render={({ field }) => (
                  <OptionsSelector
                    inputProps={{ 'aria-label': 'injurySusceptible' }}
                    error={!!errors.injurySusceptible?.message}
                    {...field}
                    value={field.value ?? ''}
                  >
                    {teamPlayerStatusInjurySusceptible.map(item => (
                      <OptionsSelectorMenuItem key={item} value={item}>
                        {t(`modal.status.values.injurySusceptible.${item}`)}
                      </OptionsSelectorMenuItem>
                    ))}
                  </OptionsSelector>
                )}
              />
              <TextInputErrorMessage>
                {tError(errors.injurySusceptible?.message)}
              </TextInputErrorMessage>
            </FormControlWrapper>
          </InputWrapper>
          <TextInput
            type='number'
            label={t(`modal.status.fields.health`)}
            errorMessage={tError(errors.health?.message)}
            inputProps={{ 'aria-label': 'health' }}
            {...register('health')}
          />
          <TextInput
            type='number'
            label={t(`modal.status.fields.form`)}
            errorMessage={tError(errors.form?.message)}
            inputProps={{ 'aria-label': 'form' }}
            {...register('form')}
          />
          <InputWrapper>
            <TextInputLabel>
              {t('modal.status.fields.overallRisk')}
            </TextInputLabel>
            <FormControlWrapper>
              <Controller
                name='overallRisk'
                control={control}
                render={({ field }) => (
                  <OptionsSelector
                    inputProps={{ 'aria-label': 'overallRisk' }}
                    error={!!errors.overallRisk?.message}
                    {...field}
                    value={field.value ?? ''}
                  >
                    {teamPlayerStatusOverallRisks.map(item => (
                      <OptionsSelectorMenuItem key={item} value={item}>
                        {t(`modal.status.values.overallRisk.${item}`)}
                      </OptionsSelectorMenuItem>
                    ))}
                  </OptionsSelector>
                )}
              />
              <TextInputErrorMessage>
                {tError(errors.overallRisk?.message)}
              </TextInputErrorMessage>
            </FormControlWrapper>
          </InputWrapper>

          <ActionButton
            type='submit'
            icon='save'
            text={t('actions.save')}
            position='end'
          />
        </form>
      </>
    </ModalDialog>
  )
}
