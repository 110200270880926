import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import {
  CreateAssetBody,
  Asset,
  MatchList,
  AssetList,
} from '@sporttotal-tv/dip-coaching-client'
import { MATCH_LIST_CACHE_KEY } from 'modules/match/hooks'
import { MutateContext } from 'modules/core/types/mutate-context.type'
import { InfiniteQuery } from 'modules/core/types'
import { ASSET_LIST_CACHE_KEY } from 'modules/asset/hooks/useAssetList'
import { placeholderAsset } from 'modules/asset/constants'

export const ASSET_CREATE_CACHE_KEY = 'asset-create'

type UseAssetCreate = {
  onAssetCreationComplete?: (asset: Asset) => void
}

export const useAssetCreate = ({
  onAssetCreationComplete,
}: UseAssetCreate = {}) => {
  const queryClient = useQueryClient()

  return useMutation<Asset, Error, CreateAssetBody, MutateContext<any>>(
    ASSET_CREATE_CACHE_KEY,
    (params: CreateAssetBody) => ApiService.asset.create(params),
    {
      onMutate: async data => {
        await queryClient.cancelQueries(MATCH_LIST_CACHE_KEY, { exact: false })
        await queryClient.cancelQueries(ASSET_LIST_CACHE_KEY, { exact: false })

        const previousData =
          queryClient.getQueriesData<InfiniteQuery<MatchList>>(
            MATCH_LIST_CACHE_KEY
          )

        const previousAssetData =
          queryClient.getQueriesData<InfiniteQuery<AssetList>>(
            ASSET_LIST_CACHE_KEY
          )

        queryClient.setQueriesData<InfiniteQuery<MatchList> | undefined>(
          MATCH_LIST_CACHE_KEY,
          oldData => {
            if (!oldData) return

            const pages = oldData.pages.map(page => {
              if (!page) return
              const { total, results } = page

              return {
                total,
                results: results.map(match => {
                  if (!match.match_assets) return match

                  const matchAssetIds = match.match_assets.map(({ id }) => id)
                  if (!matchAssetIds.includes(data.match_asset_id)) return match

                  return {
                    ...match,
                    assets: [...(match.assets || []), placeholderAsset(data)],
                  }
                }),
              }
            })

            return {
              pageParams: oldData.pageParams,
              pages,
            }
          }
        )

        if (previousAssetData) {
          queryClient.setQueriesData<AssetList | undefined>(
            ASSET_LIST_CACHE_KEY,
            oldData => {
              if (!oldData) return

              return {
                total: oldData.total,
                results: [...oldData.results, placeholderAsset(data)],
              }
            }
          )
        }

        return { previousData: [...previousData] }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData(prevData[0], prevData[1])
          )
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(MATCH_LIST_CACHE_KEY, { exact: false })
        queryClient.invalidateQueries(ASSET_LIST_CACHE_KEY, { exact: false })
      },
      onSuccess: response => {
        async function makeRequest(id: string) {
          const data = await ApiService.asset.retrieve(id)

          if (data.status === 'completed') {
            onAssetCreationComplete && onAssetCreationComplete(response)
          }

          if (data.status === 'pending') {
            const secondsBeforeRetryFetching = 5
            setTimeout(() => makeRequest(id), secondsBeforeRetryFetching * 1000)
          } else {
            queryClient.invalidateQueries(MATCH_LIST_CACHE_KEY, {
              exact: false,
            })
          }
        }

        if (response.id) makeRequest(response.id)
      },
    }
  )
}
