import { useContext, useEffect } from 'react'
import { FlexColumn } from '../Flex/styled'
import { Text } from '../Text/Text'
import { BREAKPOINTS } from 'modules/landing/constants'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { StyledInput } from '../ContactUsForm'
import { BUTTON_VARIANTS } from '../Button/utils'
import { Button } from '../Button/Button'
import { StyledMotionDiv } from './styled'
import { PopupContext } from '../../../contexts/PopupContext/context'
import { useAdaptive } from 'modules/landing/hooks/useAdaptive'
import { useTheme } from '@mui/material'
import { useRequestDemo } from 'modules/contact/hooks'
import { SnackbarContext } from 'modules/core/context'

// TODO: Move to constant file
const INITAL_VALUES = {
  email: '',
  name: '',
}

const IS_MOBILE = false

// TODO: use yup
const validate = (values: typeof INITAL_VALUES) => {
  const errors: Partial<typeof INITAL_VALUES> = {}
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  if (values.name.length < 2) {
    errors.name = 'Text is too short'
  }

  return errors
}

export const RequestADemoForm = () => {
  const theme = useTheme()

  const { t } = useTranslation(['pages'])

  const isMobile = useAdaptive(0, BREAKPOINTS.mobile)
  const isTablet = useAdaptive(BREAKPOINTS.mobile, BREAKPOINTS.tablet)
  const { mutate: requestDemo } = useRequestDemo()
  const { isRequestDemoPopupOpened, setIsRequestDemoPopupOpened } =
    useContext(PopupContext)!

  const { showSnackbar } = useContext(SnackbarContext)

  const onSubmit = (body: typeof INITAL_VALUES) =>
    requestDemo(body, {
      onSuccess: () => {
        setIsRequestDemoPopupOpened(false)
        showSnackbar({
          type: 'success',
          message: t('landing.request_demo.success'),
        })
      },
      onError: () => {
        showSnackbar({
          type: 'error',
          message: t('landing.request_demo.error'),
        })
      },
    })

  const handleBackdropClick = () => {
    setIsRequestDemoPopupOpened(false)
  }

  const handleModalClick = (e: React.MouseEvent) => e.stopPropagation()

  useEffect(() => {
    if (isRequestDemoPopupOpened && (isMobile || isTablet)) {
      document.body.style.overflow = 'hidden'
      return () => (document.body.style.overflow = 'scroll')
    }
    return () => {}
  })

  return (
    <StyledMotionDiv
      key='modal'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      onClick={() => handleBackdropClick()}
    >
      <FlexColumn
        sx={{
          width: isMobile ? '100%' : 'calc(338px - 64px)',
          minHeight: '452px',
          padding: IS_MOBILE ? '31px 32px' : '21px 32px',
          paddingBottom: '31px',
          background: theme.palette.primary.main,
          alignItems: 'center',
        }}
        onClick={e => handleModalClick(e)}
      >
        <Text
          sx={{
            width: '100%',
            font: IS_MOBILE
              ? 'normal normal normal 36px/24px Bebas Neue'
              : 'normal normal normal 32px/24px Bebas Neue',
            letterSpacing: 0,
            color: theme.palette.secondary.main,
            marginBottom: '21px',
            textAlign: IS_MOBILE ? 'center' : 'start',
          }}
        >
          {t('landing.request_demo.title')}
        </Text>

        <Text
          sx={{
            font: IS_MOBILE
              ? "normal normal normal 16px/24px 'Exo 2'"
              : "normal normal normal 14px/24px 'Exo 2'",
            letterSpacing: 0,
            color: theme.palette.primary.contrastText,
            marginBottom: '21px',
          }}
        >
          {t('landing.request_demo.text')}
        </Text>

        <FlexColumn sx={{ width: '100%' }}>
          <Formik
            initialValues={INITAL_VALUES}
            validate={validate}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <FlexColumn sx={{ gap: '11px' }}>
                <StyledInput
                  type='email'
                  name='email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  placeholder={t('landing.request_demo.form.email')}
                />
                <Text
                  sx={{
                    color: theme.palette.primary.contrastText,
                    font: 'normal normal normal 14px/16px Arial',
                  }}
                >
                  {errors.email && touched.email && errors.email}
                </Text>

                <StyledInput
                  type='text'
                  name='name'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  placeholder={t('landing.request_demo.form.name')}
                />
                <Text
                  sx={{
                    color: theme.palette.primary.contrastText,
                    font: 'normal normal normal 14px/16px Arial',
                  }}
                >
                  {errors.name && touched.name && errors.name}
                </Text>
                <Button
                  sx={{ width: '100%', alignSelf: 'flex-end' }}
                  variant={BUTTON_VARIANTS.primary}
                  onClick={() => handleSubmit()}
                >
                  <Text
                    sx={{
                      font: 'normal normal bold 10px/12px Arial',
                      letterSpacing: '0',
                    }}
                  >
                    {t(`landing.request_demo.form.button`)}
                  </Text>
                </Button>
              </FlexColumn>
            )}
          </Formik>
        </FlexColumn>
      </FlexColumn>
    </StyledMotionDiv>
  )
}
