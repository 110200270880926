import {
  Autocomplete,
  Checkbox,
  css,
  FormControl,
  Popper,
  styled,
  TextField,
} from '@mui/material'

export const PlayerSelectorWrapper = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`

export const StyledPopper = styled(Popper)`
  & .MuiPaper-root {
    background-color: ${({ theme }) => theme.palette.darkest};
    box-shadow: ${({ theme }) => theme.shadow.main};
  }

  & .MuiAutocomplete-listbox {
    background-color: ${({ theme }) => theme.palette.darkest};
  }

  & .MuiAutocomplete-option {
    background-color: ${({ theme }) => theme.palette.darkest};
    font-size: 14px;
  }
`

export const StyledAutocomplete = styled(Autocomplete, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>`
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiAutocomplete-endAdornment {
    display: flex;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
  }

  & .MuiOutlinedInput-root {
    padding: 0;
  }

  & .MuiOutlinedInput-root {
    border: 1px solid transparent;
  }

  ${({ error, theme }) =>
    error &&
    css`
      & .MuiOutlinedInput-root {
        border: 1px solid ${theme.palette.error.main};
      }
    `}
`

export const FormControlWrapper = styled(FormControl)`
  flex: 3;
`

export const InputWrapper = styled('div')`
  width: 100%;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.75)};
  margin-bottom: ${({ theme }) => theme.spacing(0.75)};
`

export const InputTextField = styled(TextField, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>`
  background-color: ${({ theme }) => theme.palette.darkest};
  border: none;

  & .MuiInputBase-input {
    padding: ${({ theme }) => theme.spacing(1)};
    font-size: 14px;
    border: none;
  }

  & .MuiInputBase-formControl {
    border-radius: 0;
    border: none;
    padding: 0;
  }

  & .MuiInputAdornment-root {
    width: 30px;
  }

  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.palette.error.main};
    `}
`

export const StyledCheckbox = styled(Checkbox)`
  padding-left: 0;
  padding-right: 0;
  & svg {
    background-color: ${({ theme }) => theme.palette.darkest};
    height: 16px;
    width: 16px;

    & path {
      fill: ${({ theme }) => theme.palette.darkest};
    }
  }

  &.Mui-checked {
    & svg {
      background-color: ${({ theme }) => theme.palette.darkest};

      & path {
        fill: ${({ theme }) => theme.palette.primary.light};
      }
    }
  }
`
