import { Rating, styled, Typography } from '@mui/material'
import { GridTableCell } from 'modules/core/styled/GridTable'
import { Icon } from 'modules/core/components/Icon'

export const Root = styled('div')`
  height: 100%;

  // TODO: check if we can put styles in specific component
  container-type: inline-size;
  container-name: players-wrapper;

  @container players-wrapper (min-width: 800px) {
    & span.short {
      display: none;
    }

    & span.full {
      display: inline;
    }

    & .lg-element-hidden {
      display: none !important;
    }

    & .table-wrapper {
      height: 100%;
    }
  }

  @container players-wrapper (max-width: 799px) {
    & span.short {
      display: inline;
    }

    & span.full {
      display: none;
    }

    & .lg-element-shown {
      display: none !important;
    }

    & .table-wrapper {
      height: calc(100% - 40px);
    }
  }
`

export const TableWrapper = styled('div')``

export const ActionsWrapper = styled('div')`
  height: 40px;
  display: flex;
  justify-content: space-between;
`

export const PlayerAddWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
  cursor: pointer;
  user-select: none;
`

export const PlayerAddText = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.common.white};
`

export const PlayerAddIcon = styled(Icon)``

export const NationalityCell = styled(GridTableCell)`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
`

export const MenuCell = styled(GridTableCell)`
  position: relative;
  display: flex;
  justify-content: flex-end;
`

export const MenuIconsWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(0.5)};
  width: 80px;

  & path {
    fill: ${({ theme }) => theme.palette.primary.light};
  }
`

export const ActionIcon = styled(Icon)`
  height: 16px;
  width: 16px;
  & path {
    fill: ${({ theme }) => theme.palette.common.white};
  }
`

export const ActionSelectedNumber = styled(Typography)`
  font-size: 12px;
`

export const InteractiveActionWrapper = styled('div')`
  color: ${({ theme }) => theme.palette.primary.light};
`

export const ActionNameWrapper = styled('div')`
  display: flex;
  cursor: pointer;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  user-select: none;
`

export const ActionName = styled(Typography)`
  font-size: 16px;
`

export const AnimatedChevronIcon = styled(Icon, {
  shouldForwardProp: prop => prop !== 'chevronUp',
})<{ chevronUp: boolean }>`
  transition: all 0.3s;
  transform: ${({ chevronUp }) => (chevronUp ? 'rotate(0)' : 'rotate(180deg)')};
`

export const InputWrapper = styled('div')`
  width: 100%;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.75)};
  margin-bottom: ${({ theme }) => theme.spacing(0.75)};
`

export const StyledRating = styled(Rating)`
  font-size: 14px;
`
