import { Translation } from 'modules/core/components/Translation'
import { PresetTagGroup } from 'modules/asset/types'

export const footballPresetTags: PresetTagGroup[] = [
  {
    category: {
      name: Translation('presetTags:category.finalThird.name'),
      identifier: 'presetTags:category.finalThird.identifier',
    },
    tags: [],
  },
  {
    category: {
      name: Translation('presetTags:category.setPieces.name'),
      identifier: 'presetTags:category.setPieces.identifier',
    },
    tags: [
      {
        name: Translation('presetTags:tag.kickOff.name'),
        identifier: 'presetTags:tag.kickOff.identifier',
      },
      {
        name: Translation('presetTags:tag.penalties.name'),
        identifier: 'presetTags:tag.penalties.identifier',
      },
      {
        name: Translation('presetTags:tag.cornerKick.name'),
        identifier: 'presetTags:tag.cornerKick.identifier',
      },
      {
        name: Translation('presetTags:tag.throwIns.name'),
        identifier: 'presetTags:tag.throwIns.identifier',
      },
    ],
  },
  {
    category: {
      name: Translation('presetTags:category.offensivePhase.name'),
      identifier: 'presetTags:category.offensivePhase.identifier',
    },
    tags: [
      {
        name: Translation('presetTags:tag.shortPass.name'),
        identifier: 'presetTags:tag.shortPass.identifier',
      },
      {
        name: Translation('presetTags:tag.longPass.name'),
        identifier: 'presetTags:tag.longPass.identifier',
      },
    ],
  },

  {
    category: {
      name: Translation('presetTags:category.defensivePhase.name'),
      identifier: 'presetTags:category.defensivePhase.identifier',
    },
    tags: [
      {
        name: Translation('presetTags:tag.defensivePhaseHigh.name'),
        identifier: 'presetTags:tag.defensivePhaseHigh.identifier',
      },
      {
        name: Translation('presetTags:tag.defensivePhaseMedium.name'),
        identifier: 'presetTags:tag.defensivePhaseMedium.identifier',
      },
      {
        name: Translation('presetTags:tag.defensivePhaseLow.name'),
        identifier: 'presetTags:tag.defensivePhaseLow.identifier',
      },
    ],
  },
  {
    category: {
      name: Translation('presetTags:category.midfield.name'),
      identifier: 'presetTags:category.midfield.identifier',
    },
    tags: [
      {
        name: Translation('presetTags:tag.midfieldHigh.name'),
        identifier: 'presetTags:tag.midfieldHigh.identifier',
      },
      {
        name: Translation('presetTags:tag.midfieldMedium.name'),
        identifier: 'presetTags:tag.midfieldMedium.identifier',
      },
      {
        name: Translation('presetTags:tag.midfieldLow.name'),
        identifier: 'presetTags:tag.midfieldLow.identifier',
      },
    ],
  },
]
