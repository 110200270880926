import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { MatchLocatorType } from 'modules/match/types'
import type { LayoutIndex } from 'modules/layout/constants'
import type { ContentItem } from 'services/PlayersData.service'
import type { MediaLocator } from 'modules/video-player/types'
import type { MediaItemParams } from 'modules/video-player/types'

export interface MediaItem extends ContentItem {
  id: string
  isFullscreen: boolean
  linkedComponents: { id: string; type: LayoutIndex }[]
}

export type PlayerPayloadAction<T> = PayloadAction<
  {
    id: string
  } & T
>

export const videoPlayersAdapter = createEntityAdapter<MediaItem>()
const initialState = videoPlayersAdapter.getInitialState()

export const videoPlayerSlice = createSlice({
  name: 'videoPlayers',
  initialState,
  reducers: {
    setVideoPlayer: (state, action: PayloadAction<MediaItem>) => {
      const existingItem = state.entities[action.payload.id]
      if (!existingItem) videoPlayersAdapter.addOne(state, action.payload)
    },
    removePlayer: (state, action: PayloadAction<{ id: string }>) => {
      videoPlayersAdapter.removeOne(state, action.payload.id)
    },
    setMediaItem: (state, action: PlayerPayloadAction<MediaItemParams>) => {
      const existingItem = state.entities[action.payload.id]
      if (!existingItem) return

      existingItem.mediaLocator = action.payload.mediaLocator
      existingItem.matchType = action.payload.matchType
      existingItem.title = action.payload.title

      existingItem.activeVideoItemId = action.payload.activeVideoItemId
      existingItem.activeVideoItemType = action.payload.activeVideoItemType
    },
    setMatchType: (
      state,
      action: PlayerPayloadAction<{
        matchType: MatchLocatorType
        mediaLocator: MediaLocator
      }>
    ) => {
      const existingItem = state.entities[action.payload.id]

      if (existingItem) {
        existingItem.matchType = action.payload.matchType
        existingItem.mediaLocator = action.payload.mediaLocator
      }
    },
    setIsFullscreen: (
      state,
      action: PlayerPayloadAction<{ isFullscreen: boolean }>
    ) => {
      const existingItem = state.entities[action.payload.id]
      if (existingItem) existingItem.isFullscreen = action.payload.isFullscreen
    },
    addLinkedComponent: (
      state,
      action: PlayerPayloadAction<{
        componentId: string
        layoutIndex: LayoutIndex
      }>
    ) => {
      const existingItem = state.entities[action.payload.id]

      if (existingItem) {
        existingItem.linkedComponents = [
          ...existingItem.linkedComponents,
          {
            id: action.payload.componentId,
            type: action.payload.layoutIndex,
          },
        ]
      }
    },
    removeLinkedComponent: (
      state,
      action: PlayerPayloadAction<{ componentId: string }>
    ) => {
      const existingItem = state.entities[action.payload.id]

      if (existingItem) {
        existingItem.linkedComponents = existingItem.linkedComponents.filter(
          index => index.id !== action.payload.componentId
        )
      }
    },
    removeAllLinkedComponent: (
      state,
      action: PayloadAction<{ componentId: string }>
    ) => {
      state.ids.forEach(id => {
        const el = state.entities[id]
        if (
          el &&
          el.linkedComponents.find(
            index => index.id === action.payload.componentId
          )
        ) {
          el.linkedComponents = el.linkedComponents.filter(
            index => index.id !== action.payload.componentId
          )
        }
      })
    },
    setIsKeyboardControlsEnabled: (
      state,
      action: PlayerPayloadAction<{ isKeyboardControlsEnabled: boolean }>
    ) => {
      const existingItem = state.entities[action.payload.id]

      if (existingItem)
        existingItem.isKeyboardControlsEnabled =
          action.payload.isKeyboardControlsEnabled
    },
  },
})

export const {
  removePlayer,
  setVideoPlayer,
  setIsFullscreen,
  setMatchType,
  setIsKeyboardControlsEnabled,
  setMediaItem,
  addLinkedComponent,
  removeLinkedComponent,
  removeAllLinkedComponent,
} = videoPlayerSlice.actions

export const videoPlayersReducer = videoPlayerSlice.reducer
