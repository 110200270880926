import {
  TelestrationShapeType,
  TelestrationTool,
} from 'modules/telestration/types'
import { Arrow, KonvaNodeComponent, Line } from 'react-konva'
import { FC } from 'react'
import { ShapeProps } from 'modules/annotator/components'
import { ShapeCurvedLine } from 'modules/annotator/components/ShapeCurvedLine'
import { ShapeMultiLine } from 'modules/annotator/components/ShapeMultiLine'

export const TelestrationShapesParams: Record<
  TelestrationTool,
  Partial<TelestrationShapeType> & {
    generateParams?: (
      params: Partial<TelestrationShapeType>
    ) => Partial<TelestrationShapeType>
    generateSelectionAreaParams?: (
      params: Partial<TelestrationShapeType>
    ) => Partial<TelestrationShapeType>
    shapeSelectionArea?: TelestrationShapeType['sceneFunc']
    shapeSelectionAreaComponent?: KonvaNodeComponent<any, any>
    CustomShapeTransformComponent?: FC<ShapeProps>
  }
> = {
  LINE: {
    generateSelectionAreaParams: () => {
      return {
        stroke: '#00000000',
        strokeWidth: 12,
      }
    },
    shapeSelectionAreaComponent: Line,
  },
  RECT: {},
  CIRCLE: {},
  PLAYER_SELECTION: {
    generateParams: params => {
      const resultParams: typeof params = {}
      if (params.stroke && typeof params.stroke === 'string')
        resultParams.fill = params.stroke

      return resultParams
    },
    sceneFunc: (context, shape) => {
      // Path to create small C circle shape
      context.beginPath()
      context.moveTo(60, 20)
      context.lineTo(60, 5)
      context.bezierCurveTo(-10, 5, -10, 65, 80, 65)
      context.bezierCurveTo(170, 65, 170, 5, 100, 5)
      context.lineTo(100, 20)
      context.bezierCurveTo(140, 15, 140, 45, 80, 45)
      context.bezierCurveTo(10, 45, 30, 15, 60, 20)

      context.closePath()
      // (!) Konva specific method, it is very important
      context.fillStrokeShape(shape)
    },

    shapeSelectionArea: (context, shape) => {
      context.beginPath()
      context.moveTo(5, 0)
      context.lineTo(155, 0)
      context.lineTo(155, 70)
      context.lineTo(5, 70)

      context.closePath()
      // (!) Konva specific method, it is very important
      context.fillStrokeShape(shape)
    },
  },
  CURVED_LINE: {
    CustomShapeTransformComponent: ShapeCurvedLine,
  },
  MULTI_LINE: { CustomShapeTransformComponent: ShapeMultiLine },
  HIGHLIGHT: {
    generateParams: params => {
      const resultParams: typeof params = {}
      if (params.stroke) resultParams.fill = `${params.stroke}40`

      return resultParams
    },
    strokeWidth: 0,
    sceneFunc: (context, shape) => {
      // Path to create highlight shape
      context.beginPath()
      context.moveTo(30, -shape.attrs.y)
      context.lineTo(-35, 10)
      context.bezierCurveTo(-30, 40, 10, 40, 25, 25)
      context.lineTo(40, -shape.attrs.y)

      context.closePath()
      // (!) Konva specific method, it is very important
      context.fillStrokeShape(shape)
    },
  },
  ARROW: {
    generateSelectionAreaParams: () => {
      return {
        stroke: '#00000000',
        strokeWidth: 12,
      }
    },
    shapeSelectionAreaComponent: Arrow,
  },
  TEXT: {},
} as const
