import { styled } from '@mui/material'
import { Icon } from 'modules/core/components/Icon'

export const LayoutItemToolbar = styled('div')`
  display: flex;
  flex: 1;
  column-gap: ${({ theme }) => theme.spacing(1)};
  user-select: none;
  align-items: center;
  cursor: default;
`

export const LayoutItemTitle = styled('div')``

export const ToolbarIcon = styled(Icon, {
  shouldForwardProp: prop => prop !== 'color',
})<{ color?: string }>`
  height: 23px;

  & * {
    transition: all 0.3s;
    fill: ${({ theme, color }) => color ?? theme.palette.secondary.main};
  }
`

export const LayoutItemToolbarDivider = styled('div')`
  display: flex;
  align-items: center;
`
