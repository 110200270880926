import { ComponentBreakpoints } from 'modules/layout/types'

const breakpoints: ComponentBreakpoints[] = ['xs', 'sm', 'md', 'lg']

/*
  Check if current component size min or max with expected
 */
export const media = {
  min: (
    currentBreakPoint: ComponentBreakpoints,
    compareBreakPoint: ComponentBreakpoints
  ) => {
    const currentBreakPointIndex = breakpoints.indexOf(currentBreakPoint)
    const compareBreakPointIndex = breakpoints.indexOf(compareBreakPoint)

    return currentBreakPointIndex >= compareBreakPointIndex
  },
  max: (
    breakPoint: ComponentBreakpoints,
    compareBreakPoint: ComponentBreakpoints
  ) => {
    const currentBreakPointIndex = breakpoints.indexOf(breakPoint)
    const compareBreakPointIndex = breakpoints.indexOf(compareBreakPoint)

    return currentBreakPointIndex <= compareBreakPointIndex
  },
}
