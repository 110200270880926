import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
// components
import { InfoTable } from 'modules/team-player/components'
// hooks
import {
  useTeamPlayerStatusDelete,
  useTeamPlayerStatusList,
} from 'modules/team-player/hooks'
// utils
import { isStatus } from 'modules/team-player/utils'
// constants
import { COLUMN_TEMPLATES, PlayersView } from 'modules/team-player/constants'
// types
import type {
  InfoTableElement,
  InfoTableHeaderElement,
  InfoItem,
} from 'modules/team-player/components'

import { GenericViewProps as StatusViewProps } from 'modules/team-player/types'
import { StatusModal } from './StatusModal'

export const StatusView: FC<StatusViewProps> = () => {
  // hooks
  const { t } = useTranslation('components', { keyPrefix: 'teamPlayers' })

  const tableRows: InfoTableElement[] = useMemo(
    () => [
      {
        type: 'checkbox',
        render: ({ item, Component }) => <Component item={item} />,
      },
      {
        type: 'stickyLeftText',
        render: ({ item, Component }) => (
          <Component>
            {isStatus(item) &&
              `${item.player?.first_name} ${item.player?.last_name}`}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component>
            {isStatus(item) &&
              t(`table.status.mathLoadValues.${item.math_load}`)}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component>
            {isStatus(item) &&
              t(`table.status.trainingLoadValues.${item.training_load}`)}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component>
            {isStatus(item) &&
              t(
                `table.status.injurySusceptibleValues.${item.injury_suscetible}`
              )}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component>{isStatus(item) && item.health}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component>{isStatus(item) && item.form}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component>
            {isStatus(item) &&
              t(`table.status.overallRiskValues.${item.overall_risk}`)}
          </Component>
        ),
      },
      {
        type: 'menu',
        render: ({ item, Component }) => <Component item={item} />,
      },
    ],
    [t]
  )

  const tableHeaderRows: InfoTableHeaderElement[] = useMemo(
    () => [
      {
        type: 'actions',
        render: ({ Component }) => (
          <Component
            translation={{
              main: t('table.status.selected'),
              secondary: t('table.status.action'),
            }}
          />
        ),
      },
      {
        type: 'stickyLeftText',
        render: ({ Component }) => (
          <Component>{t('table.status.name')}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>
            <span className='full'>{t('table.status.mathLoad')}</span>
            <span className='short'>{t('table.status.mathLoadShort')}</span>
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>
            <span className='full'>{t('table.status.trainingLoad')}</span>
            <span className='short'>{t('table.status.trainingLoadShort')}</span>
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>
            <span className='full'>{t('table.status.injurySusceptible')}</span>
            <span className='short'>
              {t('table.status.injurySusceptibleShort')}
            </span>
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>
            <span className='full'>{t('table.status.constitution')}</span>
            <span className='short'>{t('table.status.constitutionShort')}</span>
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>
            <span className='full'>{t('table.status.sharpness')}</span>
            <span className='short'>{t('table.status.sharpnessShort')}</span>
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>
            <span className='full'>{t('table.status.overallRisk')}</span>
            <span className='short'>{t('table.status.overallRiskShort')}</span>
          </Component>
        ),
      },
      {
        type: 'add',
        render: ({ Component }) => (
          <Component
            justify='flex-end'
            translation={{ main: t('table.status.add') }}
          />
        ),
      },
    ],
    [t]
  )

  const transformName = useCallback((item: InfoItem) => {
    return item && 'player' in item
      ? `${item.player?.first_name} ${item.player?.last_name}`
      : ''
  }, [])

  return (
    <InfoTable
      tableRows={tableRows}
      tableHeaderRows={tableHeaderRows}
      resource='status'
      transformName={transformName}
      Modal={StatusModal}
      useList={useTeamPlayerStatusList}
      useDelete={useTeamPlayerStatusDelete}
      useListArgs={{
        expand: ['player'],
      }}
      columnsTemplate={COLUMN_TEMPLATES[PlayersView.STATUS]}
    />
  )
}
