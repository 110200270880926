import {
  PlayerInjury,
  PlayerInjuryListParams,
  PlayerInjuryList,
  CreatePlayerInjuryBody,
  UpdatePlayerInjuryBody,
} from '@sporttotal-tv/dip-coaching-client'
import { api } from 'modules/core/api'
import {
  transformApiResults,
  transformIdApiResults,
} from 'modules/core/helpers'

export const TeamPlayerInjuryApiService = {
  list: transformApiResults<PlayerInjuryListParams, PlayerInjuryList>(
    api.playerInjury.list
  ),
  retrieve: transformApiResults<string, PlayerInjury>(
    api.playerInjury.retrieve
  ),
  create: transformApiResults<CreatePlayerInjuryBody, PlayerInjury>(
    api.playerInjury.create
  ),
  delete: transformApiResults<string, PlayerInjury>(api.playerInjury.delete),
  update: transformIdApiResults<UpdatePlayerInjuryBody, PlayerInjury>(
    api.playerInjury.update
  ),
}
