import { styled } from '@mui/material'

export const Root = styled('div')`
  height: 100%;
`

export const Wrapper = styled('div')`
  height: calc(100% - 46px);
  display: flex;
  justify-content: center;
  align-items: center;
`
