import { FC, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { Tooltip } from '@mui/material'
import { Player } from '@sporttotal-tv/dip-coaching-client'
import { DatePicker } from '@mui/x-date-pickers'
// components
import {
  ActionButton,
  ModalDialog,
  TextInput,
  TextInputErrorMessage,
  TextInputLabel,
  CurrencySelector,
} from 'modules/core/components'
import { TeamPlayerSelector } from 'modules/team-player/components'
// styled
import { OptionsSelector, OptionsSelectorMenuItem } from 'modules/core/styled'
// hooks
import { useTError } from 'modules/core/i18n/hooks'
import {
  useTeamPlayerContractCreate,
  useTeamPlayerContractUpdate,
} from 'modules/team-player/hooks'
// constants
import { teamPlayerContractAgreementTypes } from 'modules/team-player/constants'
// schema
import { teamPlayerContractSchema } from 'modules/team-player/schemas'
// types
import { TeamPlayerContractFormData } from 'modules/team-player/types'

import {
  InputTextField,
  InputWrapper,
  FormControlWrapper,
  CheckboxWrapper,
  StyledCheckbox,
  CheckboxInputWrapper,
  PlayerSelectorWrapper,
} from './ContractView.styled'
import { ContractModalProps } from './ContractView.interface'

export const ContractModal: FC<ContractModalProps> = ({
  open,
  onClose,
  title,
  item,
}) => {
  const { t } = useTranslation('components', { keyPrefix: 'teamPlayers' })
  const { tError } = useTError(['components'])
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
  } = useForm<TeamPlayerContractFormData>({
    resolver: yupResolver(teamPlayerContractSchema),
    defaultValues: {
      agreement: item?.agreement,
      agreementType: item?.agreement_type,
      salary: item?.salary,
      salaryCurrency: item?.salary_currency_id,
      expiration: item ? new Date(+item?.expires_on * 1000) : undefined,
      transferValueMin: item?.transfer_value_min,
      transferValueMax: item?.transfer_value_max,
      transferCurrency: item?.transfer_value_currency_id,
      release: !!item?.is_released,
    },
  })

  const contractUpdate = useTeamPlayerContractUpdate()
  const contractCreate = useTeamPlayerContractCreate()
  const onSubmit = (data: TeamPlayerContractFormData) => {
    if (item) {
      contractUpdate.mutate(
        {
          id: item.id,
          params: {
            agreement: data.agreement,
            agreement_type: data.agreementType,
            salary_currency_id: data.salaryCurrency,
            expires_on: new Date(data.expiration).getTime() / 1000,
            transfer_value_min: data.transferValueMin,
            transfer_value_max: data.transferValueMax,
            transfer_value_currency_id: data.transferCurrency,
            is_released: data.release,
          },
        },
        {
          onSuccess: onClose,
        }
      )
      return
    }

    if (selectedPlayer) {
      contractCreate.mutate(
        {
          player_id: selectedPlayer.id,
          agreement: data.agreement,
          agreement_type: data.agreementType,
          salary: data.salary,
          salary_currency_id: data.salaryCurrency,
          expires_on: new Date(data.expiration).getTime() / 1000,
          transfer_value_min: data.transferValueMin,
          transfer_value_max: data.transferValueMax,
          transfer_value_currency_id: data.transferCurrency,
          is_released: data.release,
        },
        {
          onSuccess: onClose,
        }
      )
    }
  }

  return (
    <ModalDialog open={open} onClose={onClose} title={title}>
      <>
        {!item && (
          <PlayerSelectorWrapper>
            <TeamPlayerSelector
              showError={isSubmitted}
              player={selectedPlayer}
              setPlayer={setSelectedPlayer}
            />
          </PlayerSelectorWrapper>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            type='number'
            label={t('modal.contract.fields.agreement')}
            errorMessage={tError(errors.agreement?.message)}
            inputProps={{ 'aria-label': 'agreement' }}
            {...register('agreement')}
          />
          <InputWrapper>
            <TextInputLabel>
              {t('modal.contract.fields.agreementType')}
            </TextInputLabel>
            <FormControlWrapper>
              <Controller
                name='agreementType'
                control={control}
                render={({ field }) => (
                  <OptionsSelector
                    inputProps={{ 'aria-label': 'agreementType' }}
                    error={!!errors.agreementType?.message}
                    {...field}
                    value={field.value ?? ''}
                  >
                    {teamPlayerContractAgreementTypes.map(agreementType => (
                      <OptionsSelectorMenuItem
                        key={agreementType}
                        value={agreementType}
                      >
                        {t(
                          `modal.contract.values.agreementTypes.${agreementType}`
                        )}
                      </OptionsSelectorMenuItem>
                    ))}
                  </OptionsSelector>
                )}
              />
              <TextInputErrorMessage>
                {tError(errors.agreementType?.message)}
              </TextInputErrorMessage>
            </FormControlWrapper>
          </InputWrapper>
          <TextInput
            type='number'
            label={t('modal.contract.fields.salary')}
            errorMessage={tError(errors.salary?.message)}
            inputProps={{ 'aria-label': 'salary' }}
            endAdornment={
              <Controller
                name='salaryCurrency'
                control={control}
                render={({ field }) => (
                  <CurrencySelector
                    inputProps={{ 'aria-label': 'salaryCurrency' }}
                    {...field}
                  />
                )}
              />
            }
            {...register('salary')}
          />
          <InputWrapper>
            <TextInputLabel>
              {t('modal.contract.fields.expiration')}
            </TextInputLabel>
            <FormControlWrapper>
              <Controller
                name='expiration'
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    renderInput={params => (
                      <InputTextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: params.InputProps?.endAdornment,
                          endAdornment: undefined,
                        }}
                        inputProps={{
                          ...params.inputProps,
                          'aria-label': 'expiration',
                        }}
                        error={!!errors.expiration?.message}
                      />
                    )}
                  />
                )}
              />
              <Tooltip title={tError(errors.expiration?.message)}>
                <TextInputErrorMessage>
                  {tError(errors.expiration?.message)}
                </TextInputErrorMessage>
              </Tooltip>
            </FormControlWrapper>
          </InputWrapper>
          <TextInput
            type='number'
            label={t('modal.contract.fields.transferValueMin')}
            errorMessage={tError(errors.transferValueMin?.message)}
            inputProps={{ 'aria-label': 'transferValueMin' }}
            endAdornment={
              <Controller
                name='transferCurrency'
                control={control}
                render={({ field }) => (
                  <CurrencySelector
                    inputProps={{ 'aria-label': 'transferCurrency' }}
                    {...field}
                  />
                )}
              />
            }
            {...register('transferValueMin')}
          />
          <TextInput
            type='number'
            label={t('modal.contract.fields.transferValueMax')}
            errorMessage={tError(errors.transferValueMax?.message)}
            inputProps={{ 'aria-label': 'transferValueMax' }}
            endAdornment={
              <Controller
                name='transferCurrency'
                control={control}
                render={({ field }) => (
                  <CurrencySelector
                    inputProps={{ 'aria-label': 'transferCurrency' }}
                    {...field}
                  />
                )}
              />
            }
            {...register('transferValueMax')}
          />
          <CheckboxInputWrapper>
            <TextInputLabel>
              {t('modal.contract.fields.release')}
            </TextInputLabel>
            <FormControlWrapper>
              <CheckboxWrapper>
                <StyledCheckbox
                  size='small'
                  {...register('release')}
                  inputProps={{ 'aria-label': 'release' }}
                />
              </CheckboxWrapper>
            </FormControlWrapper>
          </CheckboxInputWrapper>
          <ActionButton
            type='submit'
            icon='save'
            text={t('actions.save')}
            position='end'
          />
        </form>
      </>
    </ModalDialog>
  )
}
