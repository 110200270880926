import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
// components
import { InfoTable } from 'modules/team-player/components'
// hooks
import {
  useTeamPlayerContractList,
  useTeamPlayerContractDelete,
} from 'modules/team-player/hooks'
// styled
import { Badge } from 'modules/core/styled'
import { getTransferValue, isContract } from 'modules/team-player/utils'
// constants
import { COLUMN_TEMPLATES, PlayersView } from 'modules/team-player/constants'
// types
import type {
  InfoTableElement,
  InfoTableHeaderElement,
  InfoItem,
} from 'modules/team-player/components'

import { ContractModal } from './ContractModal'
import { GenericViewProps as ContractViewProps } from 'modules/team-player/types'

export const ContractView: FC<ContractViewProps> = ({ search }) => {
  // hooks
  const { t } = useTranslation('components', { keyPrefix: 'teamPlayers' })

  const tableRows: InfoTableElement[] = useMemo(
    () => [
      {
        type: 'checkbox',
        render: ({ item, Component }) => <Component item={item} />,
      },
      {
        type: 'stickyLeftText',
        render: ({ item, Component }) => (
          <Component>
            {isContract(item) &&
              `${item.player?.first_name} ${item.player?.last_name}`}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component justify='center'>
            {isContract(item) && item.agreement}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component>
            {isContract(item) &&
              t(`table.contract.agreementTypes.${item.agreement_type}`)}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component>
            {isContract(item) &&
              `${item.salary}${item.salary_currency?.currency_symbol ?? ''}`}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component justify='center'>
            {format(
              new Date(+('expires_on' in item && item.expires_on) * 1000),
              'dd/MM/yy'
            )}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component justify='center'>
            {isContract(item) && getTransferValue(item)}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component justify='center'>
            <Badge
              variant={
                isContract(item) && item.is_released ? 'success' : 'error'
              }
            >
              {isContract(item) && item.is_released ? 'Yes' : 'No'}
            </Badge>
          </Component>
        ),
      },
      {
        type: 'menu',
        render: ({ item, Component }) => <Component item={item} />,
      },
    ],
    [t]
  )

  const tableHeaderRows: InfoTableHeaderElement[] = useMemo(
    () => [
      {
        type: 'actions',
        render: ({ Component }) => (
          <Component
            translation={{
              main: t('table.contract.selected'),
              secondary: t('table.contract.action'),
            }}
          />
        ),
      },
      {
        type: 'stickyLeftText',
        render: ({ Component }) => (
          <Component>{t('table.contract.name')}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>
            <span className='full'>{t('table.contract.agreement')}</span>
            <span className='short'>{t('table.contract.agreementShort')}</span>
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>
            <span className='full'>{t('table.contract.agreementType')}</span>
            <span className='short'>
              {t('table.contract.agreementTypeShort')}
            </span>
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>{t('table.contract.salary')}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component justify='center'>
            <span className='full'>{t('table.contract.expiration')}</span>
            <span className='short'>{t('table.contract.expirationShort')}</span>
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component justify='center'>
            <span className='full'>{t('table.contract.transfer')}</span>
            <span className='short'>{t('table.contract.transferShort')}</span>
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component justify='center'>{t('table.contract.release')}</Component>
        ),
      },
      {
        type: 'add',
        render: ({ Component }) => (
          <Component
            justify='flex-end'
            translation={{ main: t('table.contract.add') }}
          />
        ),
      },
    ],
    [t]
  )

  const transformName = useCallback((item: InfoItem) => {
    return item && 'player' in item
      ? `${item.player?.first_name} ${item.player?.last_name}`
      : ''
  }, [])

  return (
    <InfoTable
      tableRows={tableRows}
      tableHeaderRows={tableHeaderRows}
      resource='contract'
      transformName={transformName}
      Modal={ContractModal}
      useList={useTeamPlayerContractList}
      useDelete={useTeamPlayerContractDelete}
      useListArgs={{
        search,
        search_by: ['first_name', 'last_name'],
        expand: ['player', 'salary_currency', 'transfer_value_currency'],
      }}
      columnsTemplate={COLUMN_TEMPLATES[PlayersView.CONTRACT]}
    />
  )
}
