import { Paper, styled } from '@mui/material'

export const LayoutItemContainer = styled(Paper)`
  overflow: hidden;
  padding: 5px;
  background-color: transparent;
`

export const LayoutItemBackground = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  overflow: hidden;
  box-shadow: 0 2px 5px ${({ theme }) => theme.palette.primary.dark},
    0 -2px 5px ${({ theme }) => theme.palette.primary.dark};
`

export const LayoutItemContent = styled('div', {
  shouldForwardProp: prop => prop !== 'toolbarHeight',
})<{ toolbarHeight: number }>`
  height: calc(100% - ${({ toolbarHeight }) => toolbarHeight}px);
`
