import { ZoomValue } from 'modules/telestration/types'

export const zoomLevels = Object.freeze<ZoomValue[]>([
  0.25, 0.5, 1, 1.15, 1.3, 1.45, 1.6, 1.75, 1.9, 2.2, 2.5, 2.9, 5, 10, 50, 100,
])

export const lowestZoomLevel = Math.max(...zoomLevels)
export const maxZoomLevel = Math.min(...zoomLevels)
export const defaultZoomLevel = 1.3 as ZoomValue

export const LEFT_OFFSET_PERCENTAGE = 0.15
export const RIGHT_OFFSET_PERCENTAGE = 0.85
