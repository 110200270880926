import { styled } from '@mui/material'

export const Root = styled('div')`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  justify-content: flex-end;
`

export const IconsWrapper = styled('div')`
  display: flex;
  align-items: center;
`
