import { ApiService } from 'services/Api.service'
import { createMutationHook } from 'modules/core/api/hooks/utils'
import { ADMIN_CAMERA_LIST } from './useAdminCameraList'

export const [useAdminCameraCreate] = createMutationHook(
  ApiService.admin.camera.create,
  queryClient => ({
    onSuccess: () =>
      queryClient.invalidateQueries(ADMIN_CAMERA_LIST, { exact: false }),
  })
)
