import { api } from 'modules/core/api'
import { transformApi } from 'modules/core/helpers'

export const PlayingSurfaceApiService = {
  list: transformApi(api.playingSurface.list),
  retrieve: transformApi(api.playingSurface.retrieve),
  create: transformApi(api.playingSurface.create),
  update: transformApi(api.playingSurface.update),
  delete: transformApi(api.playingSurface.delete),
}
