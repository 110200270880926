import { ComponentBreakpoints } from 'modules/layout/types'

export const getComponentBreakpoint = (
  width: number,
  breakpoints: { [key: string]: number }
): ComponentBreakpoints => {
  if (width >= breakpoints.lg) {
    return 'lg'
  } else if (width >= breakpoints.md) {
    return 'md'
  } else if (width >= breakpoints.sm) {
    return 'sm'
  } else {
    return 'xs'
  }
}
