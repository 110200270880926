import { MatchLocatorType } from 'modules/match/types'

export const MatchViewDataService = {
  _STORAGE_KEY: 'matchViewData',

  getMatchViewData: () => {
    const matchViewDataStorage = localStorage.getItem(
      MatchViewDataService._STORAGE_KEY
    )

    return matchViewDataStorage ? JSON.parse(matchViewDataStorage) : {}
  },

  getMatchViewType: (
    playerId: string,
    matchId: string
  ): MatchLocatorType | undefined => {
    const matchViewData = MatchViewDataService.getMatchViewData()
    return matchViewData[playerId]?.[matchId]
  },

  addMatchViewData: (
    playerId: string,
    matchId: string,
    type: MatchLocatorType
  ) => {
    const matchViewData = MatchViewDataService.getMatchViewData()
    const updatedMatchViewData = JSON.stringify({
      ...matchViewData,
      [playerId]: { ...matchViewData[playerId], [matchId]: type },
    })

    localStorage.setItem(
      MatchViewDataService._STORAGE_KEY,
      updatedMatchViewData
    )
  },
}
