import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { useQueryRetry } from 'modules/core/hooks'

export const PLAYLIST_RETRIEVE_CACHE_KEY = 'playlist-retrieve-data'

export const usePlaylistRetrieve = (id?: string) => {
  const handleQueryRetry = useQueryRetry()

  return useQuery(
    [PLAYLIST_RETRIEVE_CACHE_KEY, id],
    () => ApiService.playlist.retrieve(id),
    {
      enabled: Boolean(id),
      retry: handleQueryRetry,
    }
  )
}
