import {
  PlaylistListParams,
  PlaylistList,
  Playlist,
  CreatePlaylistBody,
  UpdatePlaylistBody
} from '@sporttotal-tv/dip-coaching-client'
import { api } from 'modules/core/api'
import {transformApiResults, transformIdApiResults} from 'modules/core/helpers/transformApiResults'

export const PlaylistApiService = {
  list: transformApiResults<PlaylistListParams, PlaylistList>(
    api.playlist.list
  ),
  create: transformApiResults<CreatePlaylistBody, Playlist>(
    api.playlist.create
  ),
  update: transformIdApiResults<UpdatePlaylistBody, Playlist>(
      api.playlist.update
  ),
  retrieve: transformApiResults<string, Playlist>(api.playlist.retrieve),
  delete: transformApiResults<string, Playlist>(api.playlist.delete),
}
