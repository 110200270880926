import { api } from 'modules/core/api'
import {
  TelestrationGroupList,
  TelestrationGroupListParams,
  TelestrationGroupCreatePayload,
  UpdateTelestrationGroupBody,
  BulkUpdateTelestrationGroupBody,
  TelestrationGroup,
} from '@sporttotal-tv/dip-coaching-client'
import {
  transformApiResults,
  transformIdApiResults,
} from 'modules/core/helpers'

export const TelestrationGroupApiService = {
  list: transformApiResults<TelestrationGroupListParams, TelestrationGroupList>(
    api.telestrationGroup.list
  ),
  retrieve: transformApiResults<string, TelestrationGroup>(
    api.telestrationGroup.retrieve
  ),
  create: transformApiResults<
    TelestrationGroupCreatePayload,
    TelestrationGroup
  >(api.telestrationGroup.create),
  update: transformIdApiResults<UpdateTelestrationGroupBody, TelestrationGroup>(
    api.telestrationGroup.update
  ),
  delete: transformApiResults<string, TelestrationGroup>(
    api.telestrationGroup.delete
  ),
  bulkUpdate: transformApiResults<
    BulkUpdateTelestrationGroupBody,
    TelestrationGroup[]
  >(api.telestrationGroup.bulkUpdate),
}
