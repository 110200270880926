import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import {
  CreatePlaylistBody,
  Playlist,
  PlaylistList,
} from '@sporttotal-tv/dip-coaching-client'
import { PLAYLIST_CACHE_KEY } from 'modules/playlist/hooks'
import { playlistItemMockup } from 'modules/playlist/utils'
import { MutateContext, InfiniteQuery } from 'modules/core/types'
import { generateId } from 'modules/core/helpers/generateId'

export const PLAYLIST_CREATE_CACHE_KEY = 'playlist-create'

export const usePlaylistCreate = () => {
  const queryClient = useQueryClient()

  return useMutation<
    Playlist,
    Error,
    CreatePlaylistBody,
    MutateContext<InfiniteQuery<PlaylistList>>
  >(
    PLAYLIST_CREATE_CACHE_KEY,
    (params: CreatePlaylistBody) => ApiService.playlist.create(params),
    {
      onMutate: async newPlaylist => {
        await queryClient.cancelQueries(PLAYLIST_CACHE_KEY, { exact: false })

        const previousData =
          queryClient.getQueriesData<InfiniteQuery<PlaylistList>>(
            PLAYLIST_CACHE_KEY
          )

        if (previousData) {
          queryClient.setQueriesData<InfiniteQuery<PlaylistList> | undefined>(
            PLAYLIST_CACHE_KEY,
            oldData =>
              oldData && {
                pageParams: oldData.pageParams,
                pages: oldData.pages.map(
                  (page, pageIndex) =>
                    page && {
                      total: page.total + 1,
                      results:
                        pageIndex + 1 === oldData.pages.length
                          ? [
                              ...page.results,
                              {
                                ...playlistItemMockup,
                                ...newPlaylist,
                                id: `draft-${generateId()}`,
                              },
                            ]
                          : page.results,
                    }
                ),
              }
          )
        }

        return { previousData }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData<InfiniteQuery<PlaylistList>>(
              prevData[0],
              prevData[1]
            )
          )
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(PLAYLIST_CACHE_KEY, { exact: false })
      },
    }
  )
}
