import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
// Hooks
import { useSnackbar } from 'modules/core/hooks/useSnackbar'
import { useAdminCameraCreate } from 'modules/admin/hooks'
// Components
import { TextInput, ModalDialog } from 'modules/core/components'
import { ActionButton } from 'modules/core/components'
// Types
import { AdminCameraCreatePayload } from '@sporttotal-tv/dip-coaching-client'
import { CameraCreateModalProps } from './CameraCreateModal.interface'
// Styled
import { Form, ButtonContainer } from './CameraCreateModal.styled'

export const CameraCreateModal: FC<CameraCreateModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'camera' })
  const { mutate } = useAdminCameraCreate()

  const showSnackbar = useSnackbar()

  const { register, handleSubmit, reset } = useForm<AdminCameraCreatePayload>()

  const onSubmit = (data: AdminCameraCreatePayload) =>
    mutate(data, {
      onSuccess: () => {
        showSnackbar({
          message: t('notifications.camera.success'),
          type: 'success',
        })
        reset()
        onClose()
      },
      onError: () =>
        showSnackbar({
          message: t('notifications.camera.failed'),
          type: 'error',
        }),
    })

  return (
    <ModalDialog
      open={isOpen}
      onClose={onClose}
      title={t('actions.create')}
      icon='plus-circle'
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          {...register('camera_name', { required: true })}
          label={t('modal.create.camera_name')}
          fullWidth
        />
        <TextInput
          {...register('display_name', { required: true })}
          label={t('modal.create.display_name')}
          fullWidth
        />
        <ButtonContainer>
          <ActionButton type='submit' text={t('actions.create')} />
        </ButtonContainer>
      </Form>
    </ModalDialog>
  )
}
