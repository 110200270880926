import {
  CreateAdminUserInviteBody,
  AdminUserInvite,
  AdminUserInviteList,
  ListAdminUserInviteListOptions,
} from '@sporttotal-tv/dip-coaching-client'
import { api } from 'modules/core/api'
import {
  transformApiResults,
  transformIdApiResults,
} from 'modules/core/helpers'

export const InviteApiService = {
  list: transformApiResults<
    ListAdminUserInviteListOptions,
    AdminUserInviteList
  >(api.adminUserInvite.list),
  delete: transformIdApiResults<void, AdminUserInvite>(
    api.adminUserInvite.delete
  ),
  create: transformApiResults<CreateAdminUserInviteBody, AdminUserInvite>(
    api.adminUserInvite.create
  ),
}
