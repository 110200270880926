import { FC, useCallback } from 'react'
// Hooks
import { useTranslation } from 'react-i18next'
// components
import { Icon, ResourceControls } from 'modules/core/components'
// constants
import { defaultIconName } from 'modules/core/constants'
// utils
import { format } from 'date-fns'
import { convertSecondsToTime } from 'modules/core/utils'
import { eventIndexToIconDictionary } from 'modules/core/constants'

import { IClipPreview } from './ClipStandalonePreview.interface'
import {
  ClipDescription,
  ClipDescriptionWrapper,
  ClipInfoItem,
  ClipInfoWrapper,
  Root,
  Tag,
  TagWrapper,
  Title,
  CollapseControlsWrapper,
  EventIconWrapper,
  MainInfoWrapper,
  ClipDescriptionInfo,
  TitleRow,
  FlexSpaceConsumer,
} from './ClipStandalonePreview.styled'

export const ClipStandalonePreview: FC<IClipPreview> = ({
  clip,
  handlePlay,
  active = false,
}) => {
  const { t } = useTranslation('time')

  const clipUrl = clip.media_locator?.url

  const handleDownload = useCallback(() => {
    if (clipUrl) {
      window.open(clipUrl, '_blank', 'noopener,noreferrer')
    }
  }, [clipUrl])

  return (
    <Root active={active}>
      <EventIconWrapper>
        <Icon
          name={
            eventIndexToIconDictionary[clip.event?.type ?? ''] ??
            defaultIconName
          }
        />
      </EventIconWrapper>
      <MainInfoWrapper>
        <ClipInfoWrapper>
          <ClipInfoItem>
            <TitleRow>
              <Title
                active={Boolean(clipUrl)}
                onClick={Boolean(clipUrl) ? handlePlay : undefined}
              >
                {clip.title}
              </Title>
              <FlexSpaceConsumer />
              <ResourceControls
                onDownload={Boolean(clipUrl) ? handleDownload : undefined}
              />
            </TitleRow>
            <ClipDescriptionWrapper>
              <ClipDescription>
                {format(new Date(+clip.created_at * 1000), 'dd/MM/yy')}
              </ClipDescription>
              <ClipDescription>
                {convertSecondsToTime(clip.duration)}
              </ClipDescription>
              <ClipDescription>
                {t('lastEdited')}:{' '}
                {format(new Date(+clip.updated_at * 1000), 'dd/MM/yy')}
              </ClipDescription>
            </ClipDescriptionWrapper>
            <ClipDescriptionInfo>{clip.description}</ClipDescriptionInfo>
          </ClipInfoItem>
        </ClipInfoWrapper>
        {clip.tags && clip.tags.length > 0 && (
          <CollapseControlsWrapper>
            <TagWrapper>
              {clip.tags.slice(0, 6).map(tag => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </TagWrapper>
          </CollapseControlsWrapper>
        )}
      </MainInfoWrapper>
    </Root>
  )
}
