import { useContext } from 'react'
import { StripeContext } from 'modules/stripe/context'

export const useStripeContext = () => {
  const context = useContext(StripeContext)

  return {
    state: context.state!,
    dispatch: context.dispatch!,
    stripe: context.stripe!,
  }
}
