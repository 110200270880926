enum LayoutIndex {
  PLAYER = 'PLAYER',
  PLAYER_CONTROLS = 'PLAYER_CONTROLS',
  HEATMAP = 'HEATMAP',
  MINIMAP = 'MINIMAP',
  STATISTICS = 'STATISTICS',
  TELESTRATION = 'TELESTRATION',
  ASSETS = 'ASSETS',
  PLAYLIST = 'PLAYLIST',
  PLAYERS = 'PLAYERS',
  HIGHLIGHTS = 'HIGHLIGHTS',
}

export { LayoutIndex }
