import { FC, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import ReactCountryFlag from 'react-country-flag'
import { Rating, Tooltip } from '@mui/material'
// components
import {
  ActionButton,
  ModalDialog,
  TextInput,
  TextInputErrorMessage,
  TextInputLabel,
} from 'modules/core/components'
// hooks
import { useTError } from 'modules/core/i18n/hooks'
import {
  useTeamPlayerCreate,
  useTeamPlayerUpdate,
} from 'modules/team-player/hooks'
// constants
import { positions } from 'modules/team-player/constants'
import { countryDictionary } from 'modules/core/constants'
// schema
import { teamPlayerCreateSchema } from 'modules/team-player/schemas'
// types
import { TeamPlayerFormData } from 'modules/team-player/types'
import { DatePicker } from '@mui/x-date-pickers'
import {
  StyledAutocomplete,
  StyledPopper,
  InputTextField,
  InputWrapper,
  FormControlWrapper,
  RatingWrapper,
  RatingFormControlWrapper,
  AutocompleteOption,
} from './GeneralView.styled'
import { PlayerModalProps } from './GeneralVewi.interface'
import { useUserTeams } from 'modules/user/hooks'

export const PlayerModal: FC<PlayerModalProps> = ({
  open,
  onClose,
  title,
  item,
}) => {
  const { t } = useTranslation('components', { keyPrefix: 'teamPlayers' })
  const { tError } = useTError(['components'])
  const { data: userTeams } = useUserTeams({ expand: ['team'] })
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TeamPlayerFormData>({
    resolver: yupResolver(teamPlayerCreateSchema),
    defaultValues: {
      height: item?.height_cm,
      weight: item?.weight_kg,
      rating: item?.rating ?? 0,
      name: item?.first_name,
      surname: item?.last_name,
      position: item?.normal_position,
      nationality: item?.nationality,
      birthdate: item ? new Date(+item?.birthdate * 1000) : undefined,
    },
  })
  const teamPlayerUpdate = useTeamPlayerUpdate()
  const teamPlayerCreate = useTeamPlayerCreate()

  const teamOptions = useMemo(() => {
    return (
      userTeams?.results?.map(({ team }) => ({
        label: team?.name,
        value: team?.id,
      })) ?? []
    )
  }, [userTeams])

  const positionsOptions = useMemo(() => {
    return (
      Object.entries(positions).map(([positionKey, position]) => ({
        label: position,
        value: positionKey,
      })) ?? []
    )
  }, [])

  const nationalityOptions = useMemo(() => {
    return (
      Object.entries(countryDictionary).map(([countryKey, country]) => ({
        label: country,
        value: countryKey,
      })) ?? []
    )
  }, [])

  const onSubmit = (data: TeamPlayerFormData) => {
    if (item) {
      teamPlayerUpdate.mutate(
        {
          id: item.id,
          params: {
            height_cm: data.height,
            weight_kg: data.weight,
            rating: data.rating,
            first_name: data.name,
            last_name: data.surname,
            normal_position: data.position,
            team_id: data.team_id,
            shirt_number: data.shirt_number,
            nationality: data.nationality,
            birthdate: new Date(data.birthdate).getTime() / 1000,
          },
        },
        {
          onSuccess: onClose,
        }
      )
      return
    }

    teamPlayerCreate.mutate(
      {
        middle_name: 'none',
        avatar_url: null,
        is_capitan: false,
        height_cm: data.height,
        weight_kg: data.weight,
        rating: data.rating,
        first_name: data.name,
        last_name: data.surname,
        normal_position: data.position,
        nationality: data.nationality,
        team_id: data.team_id,
        shirt_number: data.shirt_number,
        birthdate: new Date(data.birthdate).getTime() / 1000,
      },
      {
        onSuccess: onClose,
      }
    )
  }

  return (
    <ModalDialog open={open} onClose={onClose} title={title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          label={t('modal.player.fields.name')}
          errorMessage={tError(errors.name?.message)}
          inputProps={{ 'aria-label': 'name' }}
          {...register('name')}
        />
        <TextInput
          label={t('modal.player.fields.surname')}
          errorMessage={tError(errors.surname?.message)}
          inputProps={{ 'aria-label': 'surname' }}
          {...register('surname')}
        />
        <InputWrapper>
          <TextInputLabel>{t('modal.player.fields.birthdate')}</TextInputLabel>
          <FormControlWrapper>
            <Controller
              name='birthdate'
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  renderInput={params => (
                    <InputTextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: params.InputProps?.endAdornment,
                        endAdornment: undefined,
                      }}
                      inputProps={{
                        ...params.inputProps,
                        'aria-label': 'birthdate',
                      }}
                      error={!!errors.birthdate?.message}
                    />
                  )}
                />
              )}
            />
            <Tooltip title={tError(errors.birthdate?.message)}>
              <TextInputErrorMessage>
                {tError(errors.birthdate?.message)}
              </TextInputErrorMessage>
            </Tooltip>
          </FormControlWrapper>
        </InputWrapper>
        <InputWrapper>
          <TextInputLabel>{t('modal.player.fields.team')}</TextInputLabel>
          <FormControlWrapper>
            <Controller
              name='team_id'
              control={control}
              render={({ field: { onChange, value, ref, ...field } }) => (
                <StyledAutocomplete
                  onChange={(_, data: any) => {
                    onChange(data?.value ?? '')
                  }}
                  value={
                    teamOptions.find(option => option.value === value) ?? null
                  }
                  PopperComponent={StyledPopper}
                  renderInput={params => (
                    <InputTextField
                      {...field}
                      {...params}
                      inputProps={{
                        'aria-label': 'team',
                        ...params.inputProps,
                      }}
                      inputRef={ref}
                    />
                  )}
                  options={teamOptions}
                  error={!!errors.team_id?.message}
                />
              )}
            />
            <Tooltip title={tError(errors.team_id?.message)}>
              <TextInputErrorMessage>
                {tError(errors.team_id?.message)}
              </TextInputErrorMessage>
            </Tooltip>
          </FormControlWrapper>
        </InputWrapper>
        <TextInput
          type='shirt_number'
          label={t('modal.player.fields.shirt_number')}
          errorMessage={tError(errors.shirt_number?.message)}
          inputProps={{ 'aria-label': 'shirt_number' }}
          {...register('shirt_number')}
        />
        <InputWrapper>
          <TextInputLabel>{t('modal.player.fields.position')}</TextInputLabel>
          <FormControlWrapper>
            <Controller
              name='position'
              control={control}
              render={({ field: { onChange, value, ref, ...field } }) => (
                <StyledAutocomplete
                  onChange={(_, data: any) => {
                    onChange(data?.value ?? '')
                  }}
                  value={
                    positionsOptions.find(
                      position => position.value === value
                    ) ?? null
                  }
                  PopperComponent={StyledPopper}
                  renderInput={params => (
                    <InputTextField
                      {...field}
                      {...params}
                      inputProps={{
                        'aria-label': 'position',
                        ...params.inputProps,
                      }}
                      inputRef={ref}
                    />
                  )}
                  options={positionsOptions}
                  error={!!errors.position?.message}
                />
              )}
            />
            <Tooltip title={tError(errors.position?.message)}>
              <TextInputErrorMessage>
                {tError(errors.position?.message)}
              </TextInputErrorMessage>
            </Tooltip>
          </FormControlWrapper>
        </InputWrapper>
        <InputWrapper>
          <TextInputLabel>
            {t('modal.player.fields.nationality')}
          </TextInputLabel>
          <FormControlWrapper>
            <Controller
              name='nationality'
              control={control}
              render={({ field: { onChange, value, ref, ...field } }) => (
                <StyledAutocomplete
                  onChange={(_, data: any) => {
                    onChange(data?.value ?? '')
                  }}
                  value={
                    nationalityOptions.find(
                      nationality => nationality.value === value
                    ) ?? null
                  }
                  PopperComponent={StyledPopper}
                  renderOption={(props, option: any) => (
                    <AutocompleteOption {...props}>
                      <ReactCountryFlag
                        width={20}
                        countryCode={option.value}
                        svg
                      />
                      {option.label} ({option.value})
                    </AutocompleteOption>
                  )}
                  renderInput={params => (
                    <InputTextField
                      {...field}
                      {...params}
                      inputProps={{
                        'aria-label': 'nationality',
                        ...params.inputProps,
                      }}
                      inputRef={ref}
                    />
                  )}
                  options={nationalityOptions}
                  error={!!errors.nationality?.message}
                />
              )}
            />
            <Tooltip title={tError(errors.nationality?.message)}>
              <TextInputErrorMessage>
                {tError(errors.nationality?.message)}
              </TextInputErrorMessage>
            </Tooltip>
          </FormControlWrapper>
        </InputWrapper>
        <TextInput
          type='number'
          label={t('modal.player.fields.height')}
          errorMessage={tError(errors.height?.message)}
          inputProps={{ 'aria-label': 'height' }}
          {...register('height')}
        />
        <TextInput
          type='number'
          label={t('modal.player.fields.weight')}
          errorMessage={tError(errors.weight?.message)}
          inputProps={{ 'aria-label': 'weight' }}
          {...register('weight')}
        />
        <RatingWrapper>
          <TextInputLabel>{t('modal.player.fields.rating')}</TextInputLabel>
          <FormControlWrapper>
            <RatingFormControlWrapper>
              <Controller
                name='rating'
                control={control}
                render={({ field: { value, ...props } }) => (
                  <Rating precision={0.25} {...props} value={+value} />
                )}
              />
            </RatingFormControlWrapper>
          </FormControlWrapper>
        </RatingWrapper>

        <ActionButton
          type='submit'
          icon='save'
          text={t('actions.save')}
          position='end'
        />
      </form>
    </ModalDialog>
  )
}
