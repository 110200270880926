import { teamPlayerStatMockup } from 'modules/team-player/utils'
import {
  CreatePlayerStatsBody,
  PlayerStats,
} from '@sporttotal-tv/dip-coaching-client'
import { generateId } from 'modules/core/helpers/generateId'

export const placeholderPlayerStats = (
  payload?: CreatePlayerStatsBody
): PlayerStats => ({
  ...teamPlayerStatMockup,
  id: generateId(),
  ...payload,
})
