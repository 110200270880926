import { ShapeHandler } from 'modules/annotator/types'
import { ShapeOptions, TelestrationRect } from 'modules/telestration/types'

const Rect: ShapeHandler<TelestrationRect> = {
  onCreate({ currentTarget }, options?: ShapeOptions) {
    const points = currentTarget.getStage()?.getPointerPosition()
    const X = points?.x ?? null
    const Y = points?.y ?? null

    if (!X || !Y) return

    return {
      ...options,
      type: 'RECT',
      x: X,
      y: Y,
    }
  },

  onShaping(rect, { currentTarget }) {
    const point = currentTarget.getStage()?.getPointerPosition()
    const X = point?.x ?? null
    const Y = point?.y ?? null

    if (!X || !Y) return

    const width = X - rect.x!
    const height = Y - rect.y!
    return { ...rect, width, height }
  },

  onShapeUpdated({ currentTarget }) {
    return currentTarget
  },
}

export { Rect }
