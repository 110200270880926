import { forwardRef } from 'react'
import { Dots as DotsBase } from './Dots.styled'
import { DotsProps } from './Dots.interface'

export const Dots = forwardRef<HTMLButtonElement, DotsProps>(
  ({ active, ...others }, ref) => (
    <DotsBase
      {...others}
      name='vertical-menu'
      active={active}
      size='small'
      ref={ref}
    />
  )
)
