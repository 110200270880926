import { FC } from 'react'
import { Icon } from 'modules/core/components/Icon'
import { StyledMenuItem, StyledMenuItemText } from './PopeoverMenu.styled'
import { MenuItemProps } from './MenuItem.interface'

export const MenuItem: FC<MenuItemProps> = ({ icon, children, ...others }) => (
  <StyledMenuItem {...others}>
    {icon ? <Icon name={icon} /> : null}
    <StyledMenuItemText>{children}</StyledMenuItemText>
  </StyledMenuItem>
)
