import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
// Hooks
import { useSnackbar } from 'modules/core/hooks/useSnackbar'
// Components
import { ModalDialog } from 'modules/core/components/ModalDialog'
import { TextInput } from 'modules/core/components/TextInput'
import { ActionButton } from 'modules/core/components/ActionButton'
// Types
import { PlayingSurfaceUpdatePayload } from '@sporttotal-tv/dip-coaching-client'
import { PlayingSurfaceEditModalProps } from './PlayingSurfaceEditModal.interface'
// Styled
import { Form, ButtonContainer } from './PlayingSurfaceEditModal.styled'
import { usePlayingSurfaceUpdate } from 'modules/playing-surface/hooks'

export const PlayingSurfaceEditModal: FC<PlayingSurfaceEditModalProps> = ({
  isOpen,
  onClose,
  playingSurface,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'playingSurface' })
  const { register, handleSubmit } = useForm<PlayingSurfaceUpdatePayload>({
    defaultValues: {
      name: playingSurface.name,
      location: playingSurface.location,
    },
  })
  const showSnackbar = useSnackbar()
  const { mutate } = usePlayingSurfaceUpdate(playingSurface.id)

  const onSubmit = (data: PlayingSurfaceUpdatePayload) =>
    mutate(data, {
      onSuccess: () => {
        showSnackbar({
          message: t('notification.update.success'),
          type: 'success',
        })
        onClose()
      },
      onError: () =>
        showSnackbar({
          message: t('notification.update.failed'),
          type: 'error',
        }),
    })

  return (
    <ModalDialog
      open={isOpen}
      onClose={onClose}
      title={t('actions.edit')}
      icon='pencil'
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          {...register('name', { required: true })}
          label={t('modal.edit.name')}
          fullWidth
        />
        <TextInput
          {...register('location')}
          label={t('modal.edit.location')}
          fullWidth
          multiline
          rows={3}
        />
        <ButtonContainer>
          <ActionButton type='submit' text={t('actions.edit')} />
        </ButtonContainer>
      </Form>
    </ModalDialog>
  )
}
