import { styled, Typography } from '@mui/material'

export const Tag = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.light};
  padding: ${({ theme }) => theme.spacing(0.5, 2)};
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 10px;
  max-height: 23px;
`
