import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { resolveNamespaces } from 'modules/core/i18n/utils'
import {
  languageCodes,
  DEFAULT_LANGUAGE_CODE,
} from 'modules/core/i18n/constants'

// Translations
import * as resources from './locales'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: resolveNamespaces(resources),
    supportedLngs: languageCodes,
    fallbackLng: DEFAULT_LANGUAGE_CODE,
    // debug: true,
    resources,
  })
