import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useContactUs } from 'modules/contact/hooks'
import { FlexColumn } from 'modules/landing/components/shared/Flex'
import { Text } from 'modules/landing/components/shared/Text'
import { Button } from 'modules/landing/components/shared/Button/Button'
import { BUTTON_VARIANTS } from 'modules/landing/components/shared/Button/utils'
import { useAdaptive } from 'modules/landing/hooks/useAdaptive'
import { BREAKPOINTS, INITIAL_VALUE } from 'modules/landing/constants'
import { StyledInput } from './styled'
import { useTheme } from '@mui/material'
import { SnackbarContext } from 'modules/core/context'
import { useContext, useMemo } from 'react'
import * as yup from 'yup'

export const ContactUsForm = () => {
  const theme = useTheme()

  const { t, i18n } = useTranslation(['pages'])
  const { mutate: contactUs } = useContactUs()

  const isMobile = useAdaptive(0, BREAKPOINTS.mobile)
  const isTablet = useAdaptive(BREAKPOINTS.mobile, BREAKPOINTS.tablet)

  const { showSnackbar } = useContext(SnackbarContext)

  const FormSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup
          .string()
          .required(t('landing.sections.contact_us.error.email.required'))
          .email(t('landing.sections.contact_us.error.email.invalid')),
        message: yup
          .string()
          .required(t('landing.sections.contact_us.error.message.required'))
          .min(5, t('landing.sections.contact_us.error.message.min')),
      }),
    [i18n.language]
  )

  const onSubmit = (body: typeof INITIAL_VALUE) => {
    contactUs(body, {
      onSuccess: () => {
        showSnackbar({
          type: 'success',
          message: t('landing.sections.contact_us.success'),
        })
      },
      onError: () => {
        showSnackbar({
          type: 'error',
          message: t('landing.sections.contact_us.error_message'),
        })
      },
    })
  }

  return (
    <FlexColumn
      sx={{
        width: !isTablet ? '100%' : '50%',
        padding: isTablet || isMobile ? '20px 0' : '0',
      }}
    >
      <Formik
        initialValues={INITIAL_VALUE}
        validationSchema={FormSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <FlexColumn sx={{ width: '100%', gap: '12px' }}>
            <StyledInput
              type='email'
              name='email'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder={t('landing.sections.contact_us.form.email')}
            />
            <Text
              sx={{
                color: theme.palette.secondary.contrastText,
                font: 'normal normal normal 14px/16px Arial',
              }}
            >
              {errors.email && touched.email && errors.email}
            </Text>

            <StyledInput
              as='textarea'
              type='text'
              name='message'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.message}
              placeholder={t('landing.sections.contact_us.form.text')}
              sx={{ height: '130px' }}
            />
            <Text
              sx={{
                color: theme.palette.secondary.contrastText,
                font: 'normal normal normal 14px/16px Arial',
              }}
            >
              {errors.message && touched.message && errors.message}
            </Text>
            <Button
              sx={{
                alignSelf: isMobile || isTablet ? 'center' : 'flex-end',
                padding: '0',
              }}
              variant={BUTTON_VARIANTS.primary}
              onClick={() => handleSubmit()}
            >
              <Text
                sx={{
                  font: 'normal normal bold 10px/12px Arial',
                  letterSpacing: '0',
                }}
              >
                {t(`landing.sections.contact_us.form.button`)}
              </Text>
            </Button>
          </FlexColumn>
        )}
      </Formik>
    </FlexColumn>
  )
}
