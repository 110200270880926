import { Box, Paper, styled, Typography } from '@mui/material'
import { laptopMedia, smallLaptopMedia } from 'theme/media'

export const ANALYSER_HEADER_HEIGHT = 50

export const AnalyserHeaderContainer = styled(Paper, {
  shouldForwardProp: prop => prop !== 'hidden',
})<{ hidden?: boolean }>`
  width: inherit;
  min-height: ${ANALYSER_HEADER_HEIGHT}px;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  padding: 17px 10px 12px;
  display: flex;
  column-gap: 80px;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s;

  ${({ hidden }) => hidden && 'opacity: 0'};
`

export const TextWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;

  @media (${smallLaptopMedia}) {
    flex-direction: column;
  }
`

export const Text = styled(Typography, {
  shouldForwardProp: prop => prop !== 'accent',
})<{ accent?: boolean }>`
  transition: color 0.3s;
  color: ${({ theme, accent }) =>
    accent ? theme.palette.primary.contrastText : theme.palette.primary.light};
`

export const ProjectName = styled(Box)`
  display: flex;
  flex-wrap: wrap;

  @media (${smallLaptopMedia}) {
    flex-direction: column;
  }
`

export const SwitchWrapper = styled(Box)`
  display: flex;
  justify-content: end;
  align-items: center;
  column-gap: 7px;
`

export const SecondaryContentWrapper = styled(Box)`
  display: flex;
  flex: 2;
  column-gap: 30px;
`

export const Logins = styled(Box)`
  width: 24.9%;
  display: flex;
  column-gap: 11px;
  justify-content: end;
  align-items: center;

  @media (${laptopMedia}) {
    width: 16.6%;
  }
`

export const LoginName = styled(Text)`
  border-radius: 50%;
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 12px;
  border: 1px solid ${({ theme }) => theme.palette.primary.contrastText};
  background-color: ${({ theme }) => theme.palette.primary.light};
`
