import { api } from 'modules/core/api'
import { transformApi } from 'modules/core/helpers'

export const CameraService = {
  retreive: transformApi(api.camera.retreive),
  retreiveData: transformApi(api.camera.retreiveData),
  update: transformApi(api.camera.update),
  updateData: transformApi(api.camera.updateData),
  reportIssue: transformApi(api.camera.reportIssue),
}
