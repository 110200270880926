import { useState } from 'react'
import { useTranslation } from 'react-i18next'
// Hooks
import { useAdminCameraList } from 'modules/admin/hooks'
// Components
import { IconButton } from 'modules/core/components'
import { AdminCameraTable } from 'modules/camera/components'
import { CameraCreateModal } from 'modules/camera/components'
// Styled
import {
  Root,
  Wrapper,
  HeaderRow,
  TableWrapper,
  CreateWrapper,
} from './AdminCamera.styled'

export const AdminCamera = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'camera' })

  const { data } = useAdminCameraList()
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)

  return (
    <Root>
      <Wrapper>
        <HeaderRow>
          <div />
          <CreateWrapper onClick={() => setIsCreateModalOpen(true)}>
            {t('actions.create')} <IconButton name='plus-circle' />
          </CreateWrapper>
        </HeaderRow>
        <TableWrapper>
          <AdminCameraTable cameras={data?.results || []} />
        </TableWrapper>
      </Wrapper>
      <CameraCreateModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
      />
    </Root>
  )
}
