import { FC, useState } from 'react'
// Components
// import { Chip } from '@mui/material'
import { IconButton } from 'modules/core/components'
import { Menu, MenuItem } from 'modules/core/components/Menu/PopoverMenu'
import {
  HeaderElement,
  HeaderElementSmall,
  Row,
  Table,
  BodyElementSmall,
  BodyElement,
  // Tooltip,
} from 'modules/core/styled'
import { CameraReportIssue } from '../CameraReportIssue'
// Utils
import { parseCameraNetworkInterface } from 'modules/camera/utils'
// Types
import { CameraRetreiveData } from '@sporttotal-tv/dip-coaching-client'
import { CameraTableProps } from './CameraTable.interface'

// import { BodyCameraStatus, HeaderCameraStatus } from './CameraTable.styled'

export const CameraTable: FC<CameraTableProps> = ({
  cameras,
  onStartStop,
  onUpdate,
}) => {
  const [selectedCamera, setSelectedCamera] = useState<CameraRetreiveData>()
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const [showReportIssue, setShowReportIssue] = useState(false)

  const handleMenuClick =
    (camera: CameraRetreiveData) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      setSelectedCamera(camera)
      setAnchorEl(event.currentTarget)
    }

  const handleClose = () => {
    console.log('run')
    setAnchorEl(undefined)
    setSelectedCamera(undefined)
  }

  return (
    <>
      <Table>
        <Row>
          {/* <HeaderCameraStatus /> */}
          <HeaderElement>Name</HeaderElement>
          <HeaderElement>Software Version</HeaderElement>
          {/* <HeaderElement>Health</HeaderElement> */}
          <HeaderElement>Temperature</HeaderElement>
          <HeaderElement>Network</HeaderElement>
          <HeaderElement>Storage</HeaderElement>
          <HeaderElementSmall />
        </Row>
        {cameras.map(camera => {
          const { networkInterface, speed } = parseCameraNetworkInterface(
            camera.health
          )

          return (
            <Row key={camera.system_status?.serial_number}>
              {/* <BodyCameraStatus>
                    {camera.status === 'restarting' ||
                    camera.status === 'updating' ? (
                      <Tooltip title='Camera is updating' arrow placement='top'>
                        <StatusSpinner size='small' />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={getStatusTooltip(camera.status, camera.healthStatus)}
                        arrow
                        placement='top'
                      >
                        <StatusIndicator
                          status={
                            !camera.status || camera.status === 'off'
                              ? 'off'
                              : camera.healthStatus === 'healthy'
                              ? 'online'
                              : camera.healthStatus === 'warning'
                              ? 'warning'
                              : 'error'
                          }
                        />
                      </Tooltip>
                    )}
                  </BodyCameraStatus> */}
              <BodyElement>{camera.system_status?.serial_number}</BodyElement>
              <BodyElement>
                {camera.system_status?.software_version?.version_name}
              </BodyElement>
              {/* <StatusElement>
                    <Chip
                      label={
                        camera.healthStatus.charAt(0).toUpperCase() +
                        camera.healthStatus.slice(1)
                      }
                      color={
                        camera.healthStatus === 'healthy'
                          ? 'success'
                          : camera.healthStatus === 'warning'
                          ? 'warning'
                          : 'error'
                      }
                      size='small'
                    />
                  </StatusElement> */}
              <BodyElement>{camera.health?.temperature}°C</BodyElement>
              <BodyElement>
                {networkInterface} ({speed})
              </BodyElement>
              <BodyElement>{camera.health?.disk_usage}%</BodyElement>
              <BodyElementSmall>
                <IconButton
                  name='vertical-menu'
                  onClick={handleMenuClick(camera)}
                />
              </BodyElementSmall>
            </Row>
          )
        })}
      </Table>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {/* <MenuItem
          onClick={() => {
            handleClose()
            selectedCamera && onStartStop(selectedCamera)
          }}
        >
          Start/Stop Recording
        </MenuItem> */}
        {/* <MenuItem
          onClick={() => {
            handleClose()
            selectedCamera && onUpdate(selectedCamera)
          }}
        >
          Update Software
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            setAnchorEl(undefined)
            setShowReportIssue(true)
          }}
        >
          Report Issue
        </MenuItem>
      </Menu>

      <CameraReportIssue
        open={showReportIssue}
        onClose={() => setShowReportIssue(false)}
        camera={selectedCamera}
      />
    </>
  )
}
