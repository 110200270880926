import { format, getYear, getMonth, getDate, addMinutes } from 'date-fns'
import { defaultEventDuration } from '../utils/constants'
import { CalendarEditData } from 'modules/calendar/redux'
import { CalendarEvent } from 'modules/calendar/types'

// TODO: we need to remove dataset to be able add type to eventEl
export default function createEditEvent(props: {
  eventEl: any
  eventDuration?: number
  setStateCalendar: (data: CalendarEditData) => void
  calendarEvent?: CalendarEvent
}) {
  const {
    eventEl,
    eventDuration = defaultEventDuration,
    setStateCalendar,
    calendarEvent = null,
  } = props

  let datasetDate
  let eventBeginDate
  let eventEndDate
  let beginTime
  let endTime

  let title = ''
  let description = ''

  if (calendarEvent !== null) {
    eventBeginDate = new Date(calendarEvent.begin)
    eventEndDate = new Date(calendarEvent.end)

    beginTime = format(eventBeginDate, 'H:mm')
    endTime = format(eventEndDate, 'H:mm')
    title = calendarEvent.title
    description = calendarEvent.description
  } else {
    if (eventEl.target.dataset.date === undefined) return false

    datasetDate = new Date(eventEl.target.dataset.date)

    let position = eventEl.clientY - eventEl.target.getBoundingClientRect().top
    if (Object.keys(eventEl.target.dataset).length === 0) {
      position =
        eventEl.clientY -
        (eventEl.clientY - +eventEl.target.style.marginTop.replace('px', ''))
      datasetDate = new Date(eventEl.target.parentElement.dataset)
    }

    const hour = Math.trunc(position / 60)
    const isHalfHour = Math.trunc(position / 30) % 2 !== 0

    const minute = isHalfHour ? 30 : 0

    eventBeginDate = new Date(
      getYear(datasetDate),
      getMonth(datasetDate),
      getDate(datasetDate),
      hour > 23 ? 23 : hour,
      hour > 23 ? 30 : minute
    )
    eventEndDate = addMinutes(eventBeginDate, eventDuration)

    beginTime = format(eventBeginDate, 'H:mm')
    endTime = format(eventEndDate, 'H:mm')
  }

  setStateCalendar({
    eventBeginDate: eventBeginDate.getTime(),
    eventBeginTime: { value: beginTime, label: beginTime },
    eventEndDate: eventEndDate.getTime(),
    eventEndTime: { value: endTime, label: endTime },
    eventID: (calendarEvent && calendarEvent.id) || 0,
    title,
    description,
  })
}
