import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { useQueryRetry } from 'modules/core/hooks'
import { ListAssociationListOptions } from '@sporttotal-tv/dip-coaching-client'

export const ASSOCIATION_LIST_CACHE_KEY = 'association-list'

// @TODO: Move to association module
export const useAssociationList = (options?: ListAssociationListOptions) => {
  const handleQueryRetry = useQueryRetry({ maxRetries: 0 })

  return useQuery(
    [ASSOCIATION_LIST_CACHE_KEY, options],
    () => ApiService.association.list(options),
    {
      staleTime: Infinity,
      retry: handleQueryRetry,
    }
  )
}
