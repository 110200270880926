import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
// Hooks
import { useTranslation } from 'react-i18next'
import {
  useAnalyserPlayer,
  useAnalyserPlayerTimestamp,
} from 'modules/analyser/hooks'
import {
  useTelestrationDelete,
  useTelestrationGroupDelete,
  useTelestrationGroupsList,
} from 'modules/telestration/hooks'
import { useReduxDispatch, useReduxSelector, useKey } from 'modules/core/hooks'
import {
  useFrameCreate,
  useFrameDelete,
  useFrameList,
  useFrameRetrieve,
  useFrameUpdate,
  useFramePresentationCreate,
} from 'modules/frame/hooks'
// Components
import { HexColorPicker } from 'react-colorful'
import { ShapeOptions, TelestrationTool } from 'modules/telestration/types'
import { Tooltip } from 'modules/core/styled'
import {
  ActionButton,
  ConfirmDialog,
  ConfirmDialogState,
  Icon,
} from 'modules/core/components'
import { ToolbarDivider } from 'modules/generic/styled'
import { VideoPlayerSelector } from 'modules/video-player/components'
import {
  TelestrationActionContainer,
  Toolbar,
  ToolbarHeader,
  ColorIndicator,
  ToolbarFrameWrapper,
  ToolbarFrameItem,
  ToolbarControls,
  ToolbarActionDescription,
  ToolbarActionDescriptionWrapper,
  ControlButtonsWrapper,
  CloseButton,
  ToolbarInput,
  TelestrationFrameButton,
  TelestrationToolbarContainer,
  TelestrationToolbarGridContainer,
  GRID_TOOLBAR_HEADER,
  GRID_FRAME_ACTIONS,
  GRID_DRAWING_TOOLS,
  GRID_ADDITIONAL_TOOLS,
  GRID_ACTION_BUTTONS,
  GRID_TOOLBAR_CONTROLS,
  AreaWrapper,
  AcceptIconButton,
  AcceptIcon,
  PresentationIcon,
} from './TelestrationToolbar.styled'
// context
import { ComponentInfoContext } from 'modules/generic/context'
import { VideoPlayerContext } from 'modules/video-player/context'
import { SnackbarContext } from 'modules/core/context'
// redux
import {
  selectTelestrationActiveData,
  selectToolsData,
  setActiveGroup,
  setActiveShape,
  setColor,
  setTool,
  setZoom,
} from 'modules/telestration/redux'
import {
  selectActiveVideoItemType,
  selectFrameId,
  selectMediaLocator,
  setMediaItem,
} from 'modules/video-player/redux'
import { selectLayoutCacheById, updateLayoutCache } from 'modules/layout/redux'
// Types
import { TelestrationAction as ITelestrationAction } from './TelestrationToolbar.interface'
// Constants
import { keyCodes } from '@fluentui/keyboard-keys'
import {
  zoomLevels,
  lowestZoomLevel,
  maxZoomLevel,
} from 'modules/telestration/constants'

import { TelestrationToolSelect } from './TelestrationToolSelect'
import { TelestrationFramePresentationCreateModal } from './TelestrationFramePresentationCreateModal'

export const TelestrationToolbar = () => {
  const { layoutIndex, componentId: layoutId } =
    useContext(ComponentInfoContext)

  const { playerId } = useContext(VideoPlayerContext)
  const { showSnackbar } = useContext(SnackbarContext)
  const componentCache = useReduxSelector(state =>
    selectLayoutCacheById(state, layoutId)
  )

  const player = useAnalyserPlayer()
  const currentTimestamp = useAnalyserPlayerTimestamp({ precise: true })

  const [frameDuration, setFrameDuration] = useState<string>('')
  const [confirmFrameDeleteDialogOpen, setConfirmFrameDeleteDialogOpen] =
    useState<boolean>(false)
  const [presentationCreateModalOpen, setPresentationCreateModalOpen] =
    useState<boolean>(false)

  const handleFramePresentationCreateModalClose = useCallback(() => {
    setPresentationCreateModalOpen(false)
  }, [])

  const dispatch = useReduxDispatch()
  const mediaLocator = useReduxSelector(state =>
    selectMediaLocator(state, playerId ?? '')
  )

  const activeVideoItemType = useReduxSelector(state =>
    selectActiveVideoItemType(state, playerId ?? '')
  )
  const activeFrameId = useReduxSelector(state =>
    selectFrameId(state, playerId ?? '')
  )
  const { tool, zoom, color } = useReduxSelector(state =>
    selectToolsData(state, layoutId)
  )
  const { activeGroup, activeShape } = useReduxSelector(state =>
    selectTelestrationActiveData(state, layoutId)
  )
  const { mutate: telestrationDelete } = useTelestrationDelete()
  const { mutate: telestrationGroupDelete } = useTelestrationGroupDelete()
  const { mutate: frameCreate } = useFrameCreate()
  const { mutate: frameUpdate } = useFrameUpdate()
  const { mutate: frameDelete } = useFrameDelete()
  const { mutate: framePresentationCreate } = useFramePresentationCreate()
  const telestrationGroups = useTelestrationGroupsList({
    media_locator_id: mediaLocator?.id,
    expand: ['telestrations'],
    sort_by: 'created_at',
  })

  const frames = useFrameList({
    parent_media_locator_id: mediaLocator?.id ?? '',
  })

  const { t } = useTranslation('components', {
    keyPrefix: 'telestration',
  })

  const isFrameView = useMemo(() => {
    return activeVideoItemType === 'frame'
  }, [activeVideoItemType])

  const isFramePresentationPresent = useMemo(
    () => frames.data?.results.some(frame => frame.is_presentation) ?? false,
    [frames.data]
  )

  const isFrameCreateDisabled = isFrameView || activeVideoItemType === 'match'

  const { data: activeFrame } = useFrameRetrieve(
    {
      id: activeFrameId ?? '',
      expand: ['parent_media_locator'],
    },
    {
      enabled: !!activeFrameId,
    }
  )

  const handleCloseFrameClick = useCallback(() => {
    if (activeFrame && activeFrame.parent_media_locator && isFrameView) {
      dispatch(
        setMediaItem({
          mediaLocator: activeFrame.parent_media_locator,
          activeVideoItemId: activeFrame.parent_media_locator_id,
          activeVideoItemType: 'clip',
          id: playerId ?? '',
        })
      )
    }
  }, [dispatch, activeFrame, isFrameView, playerId])

  useEffect(() => {
    const itemInStorage = localStorage.getItem('returnTime')
    const isAutoFrame = itemInStorage !== null

    if (isAutoFrame) {
      player?.addEventListener('ended', handleCloseFrameClick)
    }

    return () => player?.removeEventListener('ended', handleCloseFrameClick)
  }, [player, handleCloseFrameClick])

  const { nextTelestration, previousTelestration } = useMemo(() => {
    const activeTelestrationGroup = telestrationGroups.data?.results.find(
      group => group.id === activeGroup
    )
    const telestrations =
      activeTelestrationGroup?.telestrations?.filter(
        telestration => telestration.media_locator_id === mediaLocator?.id
      ) ?? []

    const nextTelestration = [...telestrations]
      ?.sort((telestration1, telestration2) =>
        telestration1.start_time > telestration2.start_time ? 1 : -1
      )
      .find(telestration => telestration.start_time > currentTimestamp)

    const previousTelestration = [...telestrations]
      ?.sort((telestration1, telestration2) =>
        telestration1.start_time > telestration2.start_time ? -1 : 1
      )
      .find(telestration => telestration.start_time < currentTimestamp)

    return {
      nextTelestration,
      previousTelestration,
    }
  }, [telestrationGroups.data, mediaLocator?.id, activeGroup, currentTimestamp])

  const handleSetTelestrationTool = (
    tool: TelestrationTool | null,
    toolOptions?: ShapeOptions
  ) =>
    dispatch(
      setTool({
        id: layoutId,
        tool,
        toolOptions,
      })
    )

  const handleSetZoom = useCallback(
    (variant: 'up' | 'down') => {
      const stepDirection = variant === 'up' ? -1 : 1
      const currentZoomIndex = zoomLevels.findIndex(z => z === zoom)
      const nextZoomLevel = zoomLevels[currentZoomIndex + stepDirection]

      if (!nextZoomLevel || nextZoomLevel === zoom) return

      dispatch(setZoom({ id: layoutId, zoom: nextZoomLevel }))
    },
    [zoom, dispatch, layoutId]
  )

  const handleChangeDuration = useCallback(() => {
    if (activeFrame && !isNaN(+frameDuration)) {
      frameUpdate({
        id: activeFrame.id,
        params: {
          duration: +frameDuration,
        },
      })
    }
  }, [activeFrame, frameDuration, frameUpdate])

  const handleDelete = useCallback(() => {
    if (activeFrameId && typeof activeGroup === 'string') {
      dispatch(setActiveGroup({ id: layoutId, group: undefined }))
      dispatch(
        setActiveShape({
          id: layoutId,
          shape: null,
        })
      )
      telestrationGroupDelete(activeGroup)
      return
    }
    if (typeof activeShape === 'string') {
      dispatch(
        setActiveShape({
          id: layoutId,
          shape: null,
        })
      )
      telestrationDelete(activeShape)
    }
  }, [
    telestrationDelete,
    dispatch,
    activeShape,
    layoutId,
    activeFrameId,
    activeGroup,
    telestrationGroupDelete,
  ])

  useKey(keyCodes.Delete, handleDelete)

  const handleColorChange = useCallback(
    (color: string) =>
      dispatch(
        setColor({
          id: layoutId,
          color: color,
        })
      ),
    [dispatch, layoutId]
  )

  const handleGoToTime = useCallback(
    (time: number | undefined) => {
      if (time === undefined) return

      if (player) player.currentTime = time
    },
    [player]
  )

  const handleFrameDurationChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFrameDuration(e.target.value)
    },
    []
  )

  const handleAutoFrameToggle = useCallback(() => {
    dispatch(
      updateLayoutCache({
        ...componentCache,
        id: layoutId,
        autoFrameEnabled: !componentCache?.autoFrameEnabled,
      })
    )
  }, [componentCache, layoutId, dispatch])

  const handleConfirmRemoveFrame = useCallback(
    (result: ConfirmDialogState) => {
      setConfirmFrameDeleteDialogOpen(false)
      if (result === ConfirmDialogState.SUBMITTED && activeFrameId) {
        handleCloseFrameClick()
        if (activeFrameId) {
          frameDelete(activeFrameId)
        }
      }
    },
    [handleCloseFrameClick, activeFrameId, frameDelete]
  )

  const handleFrameCreate = useCallback(() => {
    if (mediaLocator?.id === undefined) return
    if (mediaLocator?.type !== 'VIDEO') return

    showSnackbar({
      message: 'Your frame is being created.',
      type: 'warning',
    })

    frameCreate(
      {
        parent_media_locator_id: mediaLocator.id,
        duration: 20,
        start_time: Math.floor(currentTimestamp * 1000),
      },
      {
        onSuccess: () => {
          showSnackbar({
            message: 'Your frame has been created',
            type: 'success',
          })
        },
        onError: () => {
          showSnackbar({
            message: 'Something went wrong. Please try again later.',
            type: 'error',
          })
        },
      }
    )
  }, [mediaLocator, frameCreate, currentTimestamp, showSnackbar])

  const handleOpenFramePresentationModal = useCallback(() => {
    setPresentationCreateModalOpen(true)
  }, [])

  const handleFramePresentationCreate = useCallback(
    (data: { color: string; duration: number }) => {
      if (mediaLocator?.id === undefined) return
      if (mediaLocator?.type !== 'VIDEO') return

      showSnackbar({
        message: 'Your presentation frame is being created.',
        type: 'warning',
      })

      framePresentationCreate(
        {
          parent_media_locator_id: mediaLocator.id,
          duration: data.duration,
          start_time: 0,
          color: data.color,
        },
        {
          onSuccess: () => {
            showSnackbar({
              message: 'Your frame has been created',
              type: 'success',
            })
          },
          onError: () => {
            showSnackbar({
              message: 'Something went wrong. Please try again later.',
              type: 'error',
            })
          },
        }
      )
      setPresentationCreateModalOpen(false)
    },
    [mediaLocator, framePresentationCreate, showSnackbar]
  )

  const additionalActions: ITelestrationAction[] = [
    {
      main: {
        name: t('tools.zoomIn'),
        icon: 'zoom-in',
        active: zoom !== maxZoomLevel,
        onClick: () => handleSetZoom('up'),
      },
    },
    {
      main: {
        name: t('tools.zoomOut'),
        icon: 'zoom-out',
        active: zoom !== lowestZoomLevel,
        onClick: () => handleSetZoom('down'),
      },
    },
  ]

  const drawingActions: ITelestrationAction[] = [
    {
      main: {
        name: t('tools.prev'),
        icon: 'skip-start',
        active: previousTelestration !== undefined,
        onClick: () => handleGoToTime(previousTelestration?.start_time),
      },
    },
    {
      main: {
        name: t('tools.next'),
        icon: 'skip-end',
        active: nextTelestration !== undefined,
        onClick: () => handleGoToTime(nextTelestration?.start_time),
      },
    },
    {
      main: {
        name: t('tools.arrow'),
        icon: 'arrow',
        tool: 'ARROW',
        active: true,
        onClick: () =>
          handleSetTelestrationTool(tool === 'ARROW' ? null : 'ARROW'),
      },
      subActions: [
        {
          name: t('tools.straightArrow'),
          icon: 'arrow',
          tool: 'ARROW',
          active: true,
          onClick: () =>
            handleSetTelestrationTool(tool === 'ARROW' ? null : 'ARROW'),
        },
        {
          name: t('tools.dashedArrow'),
          icon: 'arrow',
          tool: 'ARROW',
          active: true,
          onClick: () =>
            handleSetTelestrationTool(tool === 'ARROW' ? null : 'ARROW', {
              dash: [20, 15],
            }),
        },
      ],
    },
    {
      main: {
        name: t('tools.draw'),
        icon: 'pencil',
        tool: 'LINE',
        active: true,
        onClick: () =>
          handleSetTelestrationTool(tool === 'LINE' ? null : 'LINE'),
      },
      subActions: [
        {
          name: t('tools.freehandLine'),
          icon: 'pencil',
          tool: 'LINE',
          active: true,
          onClick: () =>
            handleSetTelestrationTool(tool === 'LINE' ? null : 'LINE'),
        },
        {
          name: t('tools.freehandLineDashed'),
          icon: 'pencil',
          tool: 'LINE',
          active: true,
          onClick: () =>
            handleSetTelestrationTool(tool === 'LINE' ? null : 'LINE', {
              dash: [20, 15],
            }),
        },
      ],
    },
    {
      main: {
        name: t('tools.rect'),
        icon: 'rectangle',
        tool: 'RECT',
        active: true,
        onClick: () =>
          handleSetTelestrationTool(tool === 'RECT' ? null : 'RECT'),
      },
    },
    {
      main: {
        name: t('tools.circle'),
        icon: 'circle',
        tool: 'CIRCLE',
        active: true,
        onClick: () =>
          handleSetTelestrationTool(tool === 'CIRCLE' ? null : 'CIRCLE'),
      },
    },
    {
      main: {
        name: t('tools.playerSelect'),
        icon: 'shield',
        tool: 'PLAYER_SELECTION',
        active: true,
        onClick: () =>
          handleSetTelestrationTool(
            tool === 'PLAYER_SELECTION' ? null : 'PLAYER_SELECTION'
          ),
      },
    },
    {
      main: {
        name: t('tools.curvedLine'),
        icon: 'single-select',
        tool: 'CURVED_LINE',
        active: true,
        onClick: () =>
          handleSetTelestrationTool(
            tool === 'CURVED_LINE' ? null : 'CURVED_LINE'
          ),
      },
    },
    {
      main: {
        name: t('tools.multiLine'),
        icon: 'free-line',
        tool: 'MULTI_LINE',
        active: true,
        onClick: () =>
          handleSetTelestrationTool(
            tool === 'MULTI_LINE' ? null : 'MULTI_LINE'
          ),
      },
    },
    {
      main: {
        name: t('tools.highlight'),
        icon: 'map-marker',
        tool: 'HIGHLIGHT',
        active: true,
        onClick: () =>
          handleSetTelestrationTool(tool === 'HIGHLIGHT' ? null : 'HIGHLIGHT'),
      },
    },
    {
      main: {
        name: t('tools.text'),
        icon: 'fonts',
        tool: 'TEXT',
        active: true,
        onClick: () =>
          handleSetTelestrationTool(tool === 'TEXT' ? null : 'TEXT'),
      },
    },
    {
      main: {
        name: t('tools.delete'),
        icon: 'delete',
        active: true,
        onClick: handleDelete,
      },
    },
  ]

  return (
    <TelestrationToolbarContainer>
      <TelestrationToolbarGridContainer className='telestration-toolbar-grid-container'>
        <AreaWrapper
          sx={{
            gridArea: GRID_TOOLBAR_HEADER,
          }}
        >
          <ToolbarHeader layoutId={layoutId} layoutIndex={layoutIndex}>
            <ToolbarDivider />
            <VideoPlayerSelector />
          </ToolbarHeader>
        </AreaWrapper>

        <ToolbarFrameWrapper
          sx={{
            gridArea: GRID_FRAME_ACTIONS,
          }}
        >
          <ToolbarFrameItem
            disabled={
              activeVideoItemType !== 'clip' || isFramePresentationPresent
            }
          >
            <Tooltip
              title={t('actions.presentationCreate')}
              arrow
              placement='top'
            >
              <TelestrationFrameButton
                onClick={handleOpenFramePresentationModal}
                disabled={
                  activeVideoItemType !== 'clip' || isFramePresentationPresent
                }
                size='small'
              >
                <PresentationIcon />
              </TelestrationFrameButton>
            </Tooltip>
            <TelestrationFramePresentationCreateModal
              open={presentationCreateModalOpen}
              onClose={handleFramePresentationCreateModalClose}
              onSave={handleFramePresentationCreate}
            />
          </ToolbarFrameItem>
          <ToolbarFrameItem disabled={isFrameCreateDisabled}>
            <Tooltip title={t('actions.frameCreate')} arrow placement='top'>
              <TelestrationFrameButton
                active={isFrameView}
                disabled={isFrameCreateDisabled}
                onClick={handleFrameCreate}
                size='small'
                name='camera'
              />
            </Tooltip>
          </ToolbarFrameItem>
          <ToolbarFrameItem>
            <Tooltip
              title={t(
                `actions.frameDisplay${
                  componentCache?.autoFrameEnabled ? 'On' : 'Off'
                }`
              )}
              arrow
              placement='top'
            >
              <TelestrationFrameButton
                active={componentCache?.autoFrameEnabled}
                onClick={handleAutoFrameToggle}
                size='small'
              >
                A
              </TelestrationFrameButton>
            </Tooltip>
          </ToolbarFrameItem>
          {isFrameView && (
            <>
              <ToolbarActionDescriptionWrapper>
                <Icon name='stopwatch' />
                <ToolbarActionDescription>
                  {t('duration')}
                </ToolbarActionDescription>
              </ToolbarActionDescriptionWrapper>
              <ToolbarInput
                value={frameDuration}
                onChange={handleFrameDurationChange}
                type='number'
                inputProps={{ 'aria-label': 'frame-seconds' }}
                disableUnderline
                endAdornment={
                  <AcceptIconButton onClick={handleChangeDuration}>
                    <AcceptIcon name='check' />
                  </AcceptIconButton>
                }
              />
            </>
          )}
          <Toolbar />
        </ToolbarFrameWrapper>

        <TelestrationActionContainer
          sx={{
            gridArea: GRID_DRAWING_TOOLS,
          }}
        >
          <Toolbar />
          {drawingActions.map((action, index) => (
            <TelestrationToolSelect
              key={`${action.main.name}-${index}`}
              action={action}
              tool={tool}
              disabled={activeVideoItemType === 'match'}
            />
          ))}
          <Toolbar />
        </TelestrationActionContainer>
        <TelestrationActionContainer
          sx={{
            gridArea: GRID_ADDITIONAL_TOOLS,
          }}
        >
          <Toolbar />
          {additionalActions.map((action, index) => (
            <TelestrationToolSelect
              key={`${action.main.name}-${index}`}
              action={action}
              tool={tool}
            />
          ))}
          <Tooltip
            title={
              <HexColorPicker color={color} onChange={handleColorChange} />
            }
            placement='top'
            backgroundColor='unset'
          >
            <ColorIndicator color={color} />
          </Tooltip>
        </TelestrationActionContainer>

        <ControlButtonsWrapper
          sx={{
            gridArea: GRID_ACTION_BUTTONS,
          }}
        >
          <Toolbar />
          {isFrameView && (
            <>
              <CloseButton
                onClick={() => setConfirmFrameDeleteDialogOpen(true)}
                color={'primary'}
                text={t('buttons.delete')}
                position='end'
              />
              <ActionButton
                onClick={handleCloseFrameClick}
                text={t('buttons.close')}
                position='end'
              />
            </>
          )}
        </ControlButtonsWrapper>

        <AreaWrapper
          sx={{
            gridArea: GRID_TOOLBAR_CONTROLS,
          }}
        >
          <ToolbarControls layoutId={layoutId} layoutIndex={layoutIndex} />
        </AreaWrapper>
      </TelestrationToolbarGridContainer>
      <ConfirmDialog
        open={confirmFrameDeleteDialogOpen}
        onResult={handleConfirmRemoveFrame}
        options={{
          title: t('dialog.removeFrame.title'),
          description: t('dialog.removeFrame.message'),
        }}
      />
    </TelestrationToolbarContainer>
  )
}
