// Hooks
import { useTranslation } from 'react-i18next'
import { useUserCache, useUserCacheCreate } from 'modules/cache/hooks'
// Components
import { FormControlLabel, Switch } from '@mui/material'
import { LoaderContainer } from 'modules/core/components'
// Constants
import { USER_SETTINGS_OPTIONS } from './constants'

import { Main } from './ProfileSettings.styled'

export const ProfileSettings = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'profile' })
  const { mutate: userCacheCreate } = useUserCacheCreate()

  const { isLoading, data } = useUserCache({
    keys: Object.keys(USER_SETTINGS_OPTIONS),
  })

  return (
    <LoaderContainer isLoading={isLoading}>
      <Main>
        {Object.values(USER_SETTINGS_OPTIONS).map(key => (
          <FormControlLabel
            label={t(`form.settings.${key}`)}
            control={
              <Switch
                checked={data ? data[key] : false}
                color='primary'
                onChange={({ currentTarget }) =>
                  userCacheCreate({
                    [key]: currentTarget.checked,
                  })
                }
              />
            }
          />
        ))}
      </Main>
    </LoaderContainer>
  )
}
