import { useInfiniteQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { useQueryRetry } from 'modules/core/hooks'
import { ListAdminUserListOptions } from '@sporttotal-tv/dip-coaching-client'
import { USER_PAGE_LIMIT } from 'modules/user/constants'

export const USER_LIST_CACHE_KEY = 'user-list'

export const useUserList = (options?: ListAdminUserListOptions) => {
  const handleQueryRetry = useQueryRetry({ maxRetries: 0 })

  return useInfiniteQuery(
    [USER_LIST_CACHE_KEY, options],
    ({ pageParam = 0 }) =>
      ApiService.user.list({ ...options, page: pageParam }),
    {
      retry: handleQueryRetry,
      getNextPageParam: (lastPage, allPages) => {
        const allResults = allPages.reduce(
          (numberOfResults, page) =>
            numberOfResults + (page?.results?.length ?? 0),
          0
        )

        if (allResults >= lastPage.total) {
          return undefined
        }

        return allResults / USER_PAGE_LIMIT
      },
    }
  )
}
