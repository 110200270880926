import { useContext, useEffect, useState, useRef, useMemo } from 'react'
// Hooks
import { useTranslation } from 'react-i18next'
import { useAnalyserPlayerTimestamp } from 'modules/analyser/hooks'
import { useMatch } from 'modules/match/hooks'
import { useResizeObserver } from 'modules/core/hooks'
// Components
import { Circle } from 'react-konva'
import { BaseAnnotator } from 'modules/annotator/components/BaseAnnotator'
import { VideoPlayerSelector } from 'modules/video-player/components'
import { LayoutItemToolbarContainer } from 'modules/layout/components/LayoutItemToolbar/LayoutItemToolbar.styled'
import { ToolbarControls, ToolbarHeader } from 'modules/generic/components'
import { ToolbarDivider } from 'modules/generic/styled'
// Context
import { ComponentInfoContext } from 'modules/generic/context'
// Utils
import { calculateMinimapPosition } from 'modules/match-info/utils'
import { convertTimeToSeconds } from 'modules/core/utils'
// Constants
import {
  VIDEO_FPS,
  PLAYER_CLASS_COLOR_MAP,
  PENALTY_BOX_LENGTH,
  MINIMAP_SPORT_FIELD,
} from 'modules/match-info/constants'
// Types
import {
  TrackingData,
  PlayerPosition,
  BallPosition,
  StartEndTimings,
} from 'modules/match-info/types'
import { SportType } from 'modules/sport/types'

import {
  Root,
  Wrapper,
  Field,
} from 'modules/match-info/components/Minimap/Minimap.styled'

export const Minimap = () => {
  const { t: tMatch } = useTranslation('match')
  const { t: tMinimap } = useTranslation('components', { keyPrefix: 'minimap' })

  const { match, matchData } = useMatch()
  const { componentId, layoutIndex } = useContext(ComponentInfoContext)
  const currentTimestamp = useAnalyserPlayerTimestamp()

  const [trackingData, setTrackingData] = useState<TrackingData>()
  const [playerPositions, setPlayerPositions] = useState<PlayerPosition>()
  const [ballPosition, setBallPosition] = useState<BallPosition>()
  const [startEndTimings, setStartEndTimings] = useState<StartEndTimings>()

  const wrapperRef = useRef<HTMLDivElement>(null)
  const { width, height } = useResizeObserver(wrapperRef)

  const minimapPositionTimeOffset = useMemo(() => {
    if (!startEndTimings) return 360 // 6 minutes
    const { start_time } = startEndTimings

    return convertTimeToSeconds(start_time)
  }, [startEndTimings])

  useEffect(() => {
    const trackingEndpoint = matchData?.first_half?.tracking_data
    if (!trackingEndpoint) return

    fetch(trackingEndpoint)
      .then(r => r.json())
      .then(setTrackingData)
      .catch(console.error)

    const startEndTimings = matchData?.first_half?.start_end_times
    if (!startEndTimings) return

    fetch(startEndTimings)
      .then(r => r.json())
      .then(setStartEndTimings)
      .catch(console.error)
  }, [matchData])

  useEffect(() => {
    if (!trackingData) return

    const timestampToFrameId =
      (currentTimestamp - minimapPositionTimeOffset) * VIDEO_FPS
    const frameIdToNearestSecond =
      Math.round(timestampToFrameId / VIDEO_FPS) * VIDEO_FPS

    const presentPlayerPositions =
      trackingData.playerPositions[frameIdToNearestSecond]

    const currentBallPosition =
      trackingData.ballPositions[frameIdToNearestSecond]

    setPlayerPositions(presentPlayerPositions)
    setBallPosition(currentBallPosition)
  }, [currentTimestamp, trackingData, minimapPositionTimeOffset])

  if (!match || !trackingData)
    return (
      <Root>
        <LayoutItemToolbarContainer>
          <ToolbarHeader layoutId={componentId} layoutIndex={layoutIndex}>
            <ToolbarDivider />
            <VideoPlayerSelector />
          </ToolbarHeader>
          <ToolbarControls layoutId={componentId} layoutIndex={layoutIndex} />
        </LayoutItemToolbarContainer>
        <Wrapper ref={wrapperRef}>
          {!match ? tMatch('no_match_selected') : tMinimap('no_source')}
        </Wrapper>
      </Root>
    )

  const handleSelectPlayer = (player: string) => console.log(player)

  const pitchWidth = trackingData.pitchWidth // Full Y
  const pitchLength = (trackingData.pitchLength + PENALTY_BOX_LENGTH) * 2 // Full X

  const pitchWidthFactor = height / pitchWidth
  const pitchLengthFactor = width / pitchLength

  const [ballX, ballY] = ballPosition?.XY || [0, 0]

  const [displayBallX, displayBallY] = calculateMinimapPosition(
    pitchWidth,
    pitchLength,
    pitchWidthFactor,
    pitchLengthFactor,
    ballX,
    ballY
  )

  return (
    <Root>
      <LayoutItemToolbarContainer>
        <ToolbarHeader layoutId={componentId} layoutIndex={layoutIndex}>
          <ToolbarDivider />
          <VideoPlayerSelector />
        </ToolbarHeader>
        <ToolbarControls layoutId={componentId} layoutIndex={layoutIndex} />
      </LayoutItemToolbarContainer>
      <Wrapper ref={wrapperRef}>
        <Field src={MINIMAP_SPORT_FIELD[match.sport?.name as SportType]} />
        <BaseAnnotator
          onShapeSelected={handleSelectPlayer}
          allowShapeResize={false}
          allowShapeRotate={false}
        >
          {playerPositions &&
            Object.entries(playerPositions).map(
              ([
                playerId,
                {
                  XY: [X, Y],
                  ballPossession,
                },
              ]) => {
                const playerIdNumber = parseInt(playerId)
                const playerIdClass =
                  trackingData.players[playerIdNumber].teamClass

                const [displayX, displayY] = calculateMinimapPosition(
                  pitchWidth,
                  pitchLength,
                  pitchWidthFactor,
                  pitchLengthFactor,
                  X,
                  Y
                )

                return (
                  <Circle
                    key={playerId}
                    id={playerId}
                    x={displayX}
                    y={displayY}
                    radius={6}
                    stroke={ballPossession ? 'white' : undefined}
                    fill={PLAYER_CLASS_COLOR_MAP[playerIdClass]}
                  />
                )
              }
            )}
          {ballPosition && (
            <Circle
              key={'ball'}
              id={'ball'}
              x={displayBallX}
              y={displayBallY}
              radius={5}
              fill={'white'}
            />
          )}
        </BaseAnnotator>
      </Wrapper>
    </Root>
  )
}
