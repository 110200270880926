import { RefObject, useEffect, useState } from 'react'

type IResizeObserverDOMRect = Omit<DOMRectReadOnly, 'toJSON'>

const INIT: IResizeObserverDOMRect = {
  width: 0,
  height: 0,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  x: 0,
  y: 0,
}

const useResizeObserver = (
  elementRef: RefObject<HTMLElement> | HTMLElement
) => {
  const [containerSize, setContainerSize] =
    useState<IResizeObserverDOMRect>(INIT)

  useEffect(() => {
    const element = 'current' in elementRef ? elementRef.current : elementRef

    if (element) {
      setContainerSize(element.getBoundingClientRect())

      const containerObserver = new ResizeObserver(([resizeEntry]) => {
        const { contentRect } = resizeEntry

        setContainerSize(contentRect)
      })

      containerObserver.observe(element)

      return () => containerObserver.disconnect()
    }
  }, [elementRef])

  return containerSize
}

export { useResizeObserver }
