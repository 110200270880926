import { useParams } from 'react-router-dom'
// components
import { PlaylistPlayer } from 'modules/playlist/components'
import { ClipList } from 'modules/asset/components'
// hooks
import { usePlaylistRetrieve } from 'modules/playlist/hooks'
import { useAssetList } from 'modules/asset/hooks'
// context
import { PlaylistPageContextProvider } from 'modules/playlist/context'

import { Main, VideoContainer, ClipsContainer } from './PlaylistPage.styled'

export const PlaylistPage = () => {
  const { id } = useParams()

  const playlist = usePlaylistRetrieve(id)
  const clipList = useAssetList(
    {
      playlist_id: id,
      expand: ['event', 'media_locator'],
      page_limit: 200,
    },
    {
      enabled: Boolean(id),
    }
  )

  return (
    <PlaylistPageContextProvider>
      <Main>
        <VideoContainer>
          <PlaylistPlayer title={playlist.data?.title ?? ''} />
        </VideoContainer>
        <ClipsContainer>
          <ClipList clips={clipList.data?.results ?? []} />
        </ClipsContainer>
      </Main>
    </PlaylistPageContextProvider>
  )
}
