import { useQueryClient, useMutation } from 'react-query'
import { ApiService } from 'services/Api.service'
import {
  UpdateTelestrationGroupBody,
  TelestrationGroup,
  TelestrationGroupList,
} from '@sporttotal-tv/dip-coaching-client'
import { MutateContext } from 'modules/core/types'
import { TELESTRATION_GROUPS_LIST_CACHE_KEY } from './useTelestrationGroupsList'

export type TelestrationGroupUpdateParams = {
  id: string
  params: UpdateTelestrationGroupBody
}

export const TELETSTRATION_GROUP_UPDATE_CACHE_KEY = 'telestration-group-update'

export const useTelestrationGroupUpdate = () => {
  const queryClient = useQueryClient()

  return useMutation<
    TelestrationGroup,
    Error,
    TelestrationGroupUpdateParams,
    MutateContext<TelestrationGroupList>
  >(
    TELETSTRATION_GROUP_UPDATE_CACHE_KEY,
    (body: TelestrationGroupUpdateParams) =>
      ApiService.telestrationGroup.update(body.id, body.params),
    {
      onMutate: async params => {
        await queryClient.cancelQueries(TELESTRATION_GROUPS_LIST_CACHE_KEY, {
          exact: false,
        })

        const previousData = queryClient.getQueriesData<TelestrationGroupList>(
          TELESTRATION_GROUPS_LIST_CACHE_KEY
        )

        if (previousData) {
          queryClient.setQueriesData<TelestrationGroupList | undefined>(
            TELESTRATION_GROUPS_LIST_CACHE_KEY,
            oldData =>
              oldData && {
                total: oldData.total,
                results: oldData.results.map(telestrationGroup => {
                  if (telestrationGroup.id !== params.id) {
                    return telestrationGroup
                  }

                  return {
                    ...telestrationGroup,
                    ...params.params,
                  }
                }),
              }
          )
        }

        return { previousData }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData<TelestrationGroupList>(
              prevData[0],
              prevData[1]
            )
          )
        }
      },
      onSettled: () =>
        queryClient.invalidateQueries(TELESTRATION_GROUPS_LIST_CACHE_KEY, {
          exact: false,
        }),
    }
  )
}
