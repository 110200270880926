import { createContext, FC } from 'react'
// layout
import { LayoutIndex } from 'modules/layout/constants'

import {
  ComponentInfoContextProps,
  ComponentInfoContextProviderProps,
} from './ComponentInfoContext.interface'

export const ComponentInfoContext = createContext<ComponentInfoContextProps>({
  componentId: '',
  layoutIndex: LayoutIndex.PLAYER, // Default value
  componentCacheKey: '',
})

export const ComponentInfoContextProvider: FC<
  ComponentInfoContextProviderProps
> = ({ componentId, layoutIndex, children }) => {
  return (
    <ComponentInfoContext.Provider
      value={{
        componentId,
        layoutIndex,
        componentCacheKey: `${layoutIndex}-${componentId}`,
      }}
    >
      {children}
    </ComponentInfoContext.Provider>
  )
}
