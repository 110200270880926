import { usePreciseCurrentTime } from 'modules/video-player/hooks'
import { useAnalyserPlayer } from './useAnalyserPlayer'

interface UseAnalyserPlayerTimestampOptions {
  precise?: boolean
  rounderFunction?: (timestamp: number) => number
}

const DEFAULT_OPTIONS = {
  precise: false,
  rounderFunction: Math.round,
}

export const useAnalyserPlayerTimestamp = (
  opts: UseAnalyserPlayerTimestampOptions = DEFAULT_OPTIONS
) => {
  const player = useAnalyserPlayer()
  const currentTimestamp = usePreciseCurrentTime(player)

  const { precise, rounderFunction } = { ...DEFAULT_OPTIONS, ...opts }

  return precise ? currentTimestamp : rounderFunction(currentTimestamp)
}
