import { FormControl, styled } from '@mui/material'

export const PlayerSelectorWrapper = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`

export const InputWrapper = styled('div')`
  width: 100%;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.75)};
  margin-bottom: ${({ theme }) => theme.spacing(0.75)};
`

export const FormControlWrapper = styled(FormControl)`
  flex: 3;
`
