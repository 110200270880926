import { FC, ReactNode } from 'react'
// Components
import { AppBar } from 'modules/core/components'

import { Main } from './OrganizationPageLayout.styled'

export const OrganizationPageLayout: FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <>
      <AppBar tabs={[]} />
      <Main>{children}</Main>
    </>
  )
}
