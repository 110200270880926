import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Asset, Playlist } from '@sporttotal-tv/dip-coaching-client'
import { Collapse } from '@mui/material'
import { format } from 'date-fns'
// components
import { Icon } from 'modules/core/components'
// hooks
import {
  useDebounce,
  useOutsideClick,
  usePageScrolling,
  useReduxDispatch,
} from 'modules/core/hooks'
import { usePlaylist } from 'modules/playlist/hooks'
// redux
import { setMediaItem } from 'modules/video-player/redux'
// styled
import { HorizontalLoader, Tooltip } from 'modules/core/styled'
// constants
import { routeNames } from 'modules/core/constants'
import { PLAYLIST_PAGE_LIMIT } from 'modules/playlist/constants'
// context
import { ComponentInfoContext } from 'modules/generic/context'

import {
  SelectIcon,
  SelectorWrapper,
  SelectorModal,
  SelectorIntractable,
  Search,
  Divider,
  ItemsList,
  Item,
  FilterWrapper,
  ClipName,
  ItemWrapper,
  ClipTime,
  AnimatedChevronIcon,
  IconWrapper,
  ClipItem,
} from './VideoPlayerToolbar.styled'

export const PlaylistSearch = () => {
  const { componentId: layoutId } = useContext(ComponentInfoContext)
  const navigate = useNavigate()
  const [openState, setOpenState] = useState<{ [key: string]: boolean }>({})
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const modalRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const debounceSearchValue = useDebounce(searchValue, 500)
  const dispatch = useReduxDispatch()
  const { t } = useTranslation('components', { keyPrefix: 'player' })

  const handleOutsideClick = useCallback(() => setModalOpen(false), [])
  useOutsideClick<HTMLDivElement>(
    modalRef,
    handleOutsideClick,
    wrapperRef,
    'MuiCalendarPicker-root'
  )
  const { containerRef, isBottom, resetIsBottom } = usePageScrolling(100)

  const playList = usePlaylist({
    expand: ['assets', 'assets.event', 'assets.media_locator'],
    search_by: ['title', 'description'],
    page_limit: PLAYLIST_PAGE_LIMIT,
    search: debounceSearchValue,
  })

  const handlePlay = useCallback(
    (playlistItem: Playlist) => () => {
      if (playlistItem.assets && playlistItem.assets.length > 0) {
        navigate(`${routeNames.playlist.path}/${playlistItem.id}`)
      }
    },
    [navigate]
  )

  const handlePlayClip = useCallback(
    (clip: Asset) => () => {
      dispatch(
        setMediaItem({
          id: layoutId,
          mediaLocator: clip.media_locator,
          activeVideoItemType: 'clip',
          activeVideoItemId: clip.id,
          title: clip.title,
        })
      )
    },
    [dispatch, layoutId]
  )

  const toggleOpenState = useCallback(
    (id: string) => () => {
      setOpenState(prevState => ({
        ...prevState,
        [id]: !prevState[id],
      }))
    },
    []
  )

  useEffect(() => {
    if (
      isBottom &&
      playList.hasNextPage &&
      !playList.isFetchingNextPage &&
      !playList.isLoading
    ) {
      resetIsBottom()
      playList.fetchNextPage()
    }
  }, [playList, isBottom, resetIsBottom])

  return (
    <SelectorWrapper>
      <SelectorModal ref={modalRef} hidden={!modalOpen}>
        <FilterWrapper>
          <Search
            inputProps={{ 'aria-label': 'playlist-search' }}
            placeholder='Search'
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          />
        </FilterWrapper>
        <Divider />
        <ItemsList ref={containerRef}>
          {playList.data?.pages.map(page =>
            page.results.map(playlistItem => (
              <Fragment key={playlistItem.id}>
                <ItemWrapper>
                  <Item
                    key={playlistItem.id}
                    onClick={handlePlay(playlistItem)}
                  >
                    {playlistItem.title}
                  </Item>
                  {playlistItem.assets && playlistItem.assets?.length > 0 && (
                    <IconWrapper>
                      <AnimatedChevronIcon
                        name='chevron-up'
                        onClick={toggleOpenState(playlistItem.id)}
                        chevronUp={openState[playlistItem.id]}
                      />
                    </IconWrapper>
                  )}
                </ItemWrapper>

                <Collapse in={openState[playlistItem.id]}>
                  {playlistItem.assets?.map(asset => (
                    <ClipItem key={asset.id} onClick={handlePlayClip(asset)}>
                      <ClipName>{asset.title} </ClipName>
                      <ClipTime>
                        (
                        {format(new Date(+asset.created_at * 1000), 'dd/MM/yy')}
                        )
                      </ClipTime>
                    </ClipItem>
                  ))}
                </Collapse>
              </Fragment>
            ))
          )}
          {playList.isFetchingNextPage && <HorizontalLoader />}
        </ItemsList>
      </SelectorModal>
      <Tooltip
        title={!modalOpen ? t('playlistSearch') : ''}
        placement='top'
        arrow
      >
        <SelectorIntractable
          ref={wrapperRef}
          onClick={() => setModalOpen(prev => !prev)}
        >
          <Icon name='playlist' />
          <SelectIcon name='chevron-small-down' chevronUp={true} />
        </SelectorIntractable>
      </Tooltip>
    </SelectorWrapper>
  )
}
