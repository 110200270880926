import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'
import { LayoutIndex } from 'modules/layout/constants'
import { videoPlayersAdapter } from './videoPlayer.slice'

export const selectPlayerState = (state: RootState) => state.videoPlayers

export const {
  selectAll: selectAllVideoPlayers,
  selectById: selectVideoPlayerById,
} = videoPlayersAdapter.getSelectors<RootState>(state => state.videoPlayers)

export const selectAllVideoPlayersConnectedTypes = createSelector(
  selectAllVideoPlayers,
  videoPlayers =>
    videoPlayers.reduce<Record<string, LayoutIndex[]>>(
      (el, item) => ({
        ...el,
        [item.id]: item.linkedComponents.map(
          linkedComponent => linkedComponent.type
        ),
      }),
      {}
    )
)

export const selectVideoPlayerLinkedComponents = createSelector(
  selectVideoPlayerById,
  videoPlayer => videoPlayer?.linkedComponents
)

export const selectPlayerComponentId = createSelector(
  selectVideoPlayerById,
  videoPlayer => videoPlayer?.id
)

export const selectIsFullscreen = createSelector(
  selectVideoPlayerById,
  videoPlayer => videoPlayer?.isFullscreen
)

export const selectIsClipSelected = createSelector(
  selectVideoPlayerById,
  videoPlayer => videoPlayer?.activeVideoItemType === 'clip'
)

export const selectActiveVideoItemType = createSelector(
  selectVideoPlayerById,
  videoPlayer => videoPlayer?.activeVideoItemType
)

export const selectMediaItemTitle = createSelector(
  selectVideoPlayerById,
  mediaRenderer => mediaRenderer?.title
)

export const selectMatchId = createSelector(
  selectVideoPlayerById,
  videoPlayer => {
    if (videoPlayer?.activeVideoItemType === 'match') {
      return videoPlayer?.activeVideoItemId
    }
  }
)

export const selectFrameId = createSelector(
  selectVideoPlayerById,
  videoPlayer => {
    if (videoPlayer?.activeVideoItemType === 'frame') {
      return videoPlayer?.activeVideoItemId
    }
  }
)

export const selectMediaLocator = createSelector(
  selectVideoPlayerById,
  videoPlayer => videoPlayer?.mediaLocator
)

export const selectMatchType = createSelector(
  selectVideoPlayerById,
  videoPlayer => videoPlayer?.matchType
)

export const selectActiveUrl = createSelector(
  selectVideoPlayerById,
  videoPlayer => videoPlayer?.mediaLocator?.url
)

export const selectVideoPlayerTelestrationComponentId = createSelector(
  selectVideoPlayerById,
  videoPlayer => {
    const connection = videoPlayer?.linkedComponents.find(
      item => item.type === LayoutIndex.TELESTRATION
    )

    return connection?.id
  }
)

export const selectIsKeyboardControlsEnabled = createSelector(
  selectVideoPlayerById,
  videoPlayer => videoPlayer?.isKeyboardControlsEnabled ?? true
)
