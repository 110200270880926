import { useTranslation } from 'react-i18next'
import { Divider } from '@mui/material'
import { Root, Title, Wrapper, Content } from './Terms.styled'

export const Terms = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'terms' })

  return (
    <Root>
      <Wrapper>
        <Divider />
        <Title variant='h1'>{t('title')}</Title>
        <Content>
          <p>Allgemeine Geschäftsbedingungen [Stand: August 2023]</p>
          <ol>
            <li>
              Anwendungsbereich
              <ol>
                <li>
                  Die vorliegenden Allgemeinen Geschäftsbedingungen regeln das
                  Vertragsverhältnis zwischen der sporttotal.tv GmbH, Am
                  Coloneum 2, 50829 Köln (nachfolgend “sporttotal“) und Ihnen in
                  Bezug auf die Nutzung der von sporttotal insbesondere über die
                  coaching.sporttotal.tv-Website sowie sporttotal.tv-Apps
                  (nachfolgend insgesamt als „sporttotal.tv“ bezeichnet)
                  angebotenen und zur Verfügung gestellten Dienste im
                  Zusammenhang mit der Zugänglich¬machung von Inhalten,
                  einschließlich dem Angebot des sporttotal Coaching Tools. Mit
                  dem Zugriff auf und der Nutzung von den Diensten erklären Sie
                  sich mit der Geltung der vorliegenden Allgemeinen
                  Geschäftsbedingungen einverstanden.
                </li>
                <li>
                  Ein wesentlicher Bestandteil des sporttotal Coaching Tools ist
                  die Möglichkeit auf audiovisuelle Inhalte der teameigenen
                  Spiele zuzugreifen und diese umfassend auszuwerten. Die
                  Dienste beinhalten z.B. Live-Streams, ebenso wie auch
                  On-Demand-Angebote von Sportereignissen sowohl in voller Länge
                  als auch in Form von Kurzclips, Zusammenfassungen,
                  Ausschnitten, Zusammenschnitten etc. Die Verfügbarkeit und
                  Gestaltung der Dienste und Inhalte können mit der Zeit
                  variieren. Die Bereitstellung der Dienste kann für eine
                  Probephase unentgeltlich oder gegen Entrichtung eines
                  monatlichen Entgelts erfolgen.
                </li>
                <li>
                  Für bestimmte Dienste, die sporttotal Ihnen über
                  coaching.sporttotal.tv anbietet, gelten möglicherweise
                  zusätzliche oder abweichende Bedingungen („Besondere
                  Bedingungen“), auf die sporttotal Sie rechtzeitig hinweisen
                  wird. Die vorliegenden Allgemeinen Geschäftsbedingungen und
                  etwaige Besondere Bedingungen sind nachfolgend insgesamt als
                  „Nutzungsbedingungen“ bezeichnet. Im Fall von Widersprüchen
                  zwischen diesen Allgemeinen Bedingungen und Besonderen
                  Bedingungen haben letztere Vorrang. Einzelheiten zum
                  Funktions- und Leistungsumfang einzelner Dienste ergeben sich
                  zudem aus den entsprechenden Preis- und
                  Leistungsbeschreibungen auf coaching.sporttotal.tv in der zum
                  Bestellzeitpunkt gültigen Fassung.
                </li>
                <li>
                  Zur Nutzung von coaching.sporttotal.tv und der Dienste müssen
                  Sie 18 Jahre oder älter sein.
                </li>
              </ol>
            </li>
            <li>
              Registrierung
              <ol>
                <li>
                  Die Nutzung der Dienste erfordert aufgrund ihrer
                  Individualisierung eine Registrierung für ein Benutzerkonto
                  unter Angabe des zugehörigen Teams. Die Dienste können nur
                  erbracht werden, wenn Sie angemeldet sind. Die Registrierung
                  erfordert mindestens die Angabe des Namens, einer gültigen
                  E-Mail-Adresse, die Auswahl eines Teams sowie eines frei
                  wählbaren Passworts. Ein Anspruch auf Einrichtung eines
                  Benutzerkontos, das zur Anmeldung berechtigt, besteht nicht.
                </li>
                <li>
                  Sie sind verpflichtet, sämtliche im Registrierungsformular
                  vorgesehenen Felder wahrheitsgemäß und korrekt auszufüllen.
                  Eine Registrierung ist nur bei vollständigem Ausfüllen der
                  Pflichtfelder möglich. sporttotal prüft im Anschluss Ihre
                  Angaben, ordnet das entsprechende Team oder die entsprechenden
                  Teams Ihrem Benutzerkonto zu und schaltet dieses dann für die
                  Nutzung frei. sporttotal behält sich jedoch vor, einen
                  Nachweis oder entsprechende Belege für die Richtigkeit der
                  Angaben im Einzelfall anzufordern. Bei einer Änderung der
                  erhobenen Daten sind Sie verpflichtet, die Registrierungsdaten
                  in Ihrem Benutzerkonto unverzüglich zu aktualisieren oder –
                  soweit dies nicht möglich ist – sporttotal die Änderungen
                  unverzüglich per E-Mail an coaching@sporttotal.tv mitzuteilen.
                </li>
                <li>
                  Mehrfachregistrierungen sind untersagt. Sie sind zudem
                  verpflichtet Ihr Passwort geheim zu halten und nicht an Dritte
                  weiterzugeben. Sollten Sie Kenntnis von Tatsachen erlangen,
                  die darauf schließen lassen, dass Dritte Kenntnis von Ihrem
                  Passwort erlangt haben oder dies nutzen, sind Sie
                  verpflichtet, Ihr Passwort umgehend zu ändern.
                </li>
                <li>
                  sporttotal ist bei falschen Angaben im Rahmen der
                  Registrierung, im Falle der Passwort-Weitergabe oder im Fall
                  einer Doppelanmeldung berechtigt, die Benutzerkonten zu
                  sperren und Sie im Wege der außerordentlichen Kündigung ohne
                  Einhaltung einer Frist von der Nutzung von dem sporttotal
                  Coaching Tool oder weiterer Dienste auszuschließen.
                </li>
                <li>
                  Die gleichzeitige Nutzung der Dienste mit einem Benutzerkonto
                  auf mehreren Endgeräten ist nicht möglich, es sei denn, in der
                  Leistungsbeschreibung des jeweiligen Dienstes ist etwas
                  anderes geregelt.
                </li>
              </ol>
            </li>
            <li>
              Leistungsumfang und Nutzungsbeschränkungen
              <ol>
                <li>
                  sporttotal räumt Ihnen als Nutzer nach Maßgabe der
                  nachfolgenden Bestimmungen ein einfaches, widerrufliches,
                  nicht abtretbares und nicht unterlizenzierbares Recht ein, auf
                  die Dienste zuzugreifen und diese in dem jeweils im Rahmen der
                  digitalen Bestellung mitgeteilten und gestatteten, normalen
                  Umfang bestimmungsgemäß zu nutzen.
                </li>
                <li>
                  sporttotal behält sich vor, Sie von der Nutzung von den
                  Diensten ganz oder teilweise auszuschließen, wenn Sie die
                  hiernach übernommenen Verpflichtungen verletzen:
                  <ol>
                    <li>
                      Sie dürfen keinen Teil von coaching.sporttotal.tv oder der
                      Dienste verändern, zerlegen, dekompilieren,
                      zurückentwickeln, oder ganz oder teilweise, kopieren,
                      aufzeichnen oder speichern (sofern nicht von uns in der
                      Anwendungsfunktion erlaubt), umleiten, oder anderweitig an
                      andere Personen verbreiten noch anderen Personen erlauben
                      oder ermöglichen, dies zu tun.
                    </li>
                    <li>
                      Der Zugriff auf die Dienste ist ausschließlich über
                      coaching.sporttotal.tv oder Drittplattformen, auf denen
                      Dienste von sporttotal rechtmäßig eingebunden sind,
                      zulässig. Ihnen ist untersagt, andere Technologien oder
                      Mittel als die von sporttotal zur Nutzung der Dienste
                      bereitgestellten oder zugelassenen einzusetzen.
                    </li>
                    <li>
                      Sie dürfen keine sicherheitsbezogenen Funktionen von
                      coaching.sporttotal.tv und der Dienste oder solche
                      Funktionen umgehen, ausschalten oder sonst
                      beeinträchtigen, die die Nutzung oder Vervielfältigung von
                      sporttotal.tv oder der Dienste verhindern oder
                      beschränken.
                    </li>
                    <li>
                      Eine öffentliche Wiedergabe oder kommerzielle Nutzung der
                      sporttotal Dienste ist verboten.
                    </li>
                    <li>
                      Ihnen ist es insbesondere untersagt die abrufbaren Inhalte
                      und sonstige Dienste über Streamingseiten wie z.B.
                      „Twitch“ weiterzuverbreiten. Weiter sind die folgenden
                      Handlungen nicht gestattet:
                      <ul>
                        <li>
                          Die Vervielfältigung von Inhalten, ihre Verbreitung,
                          Veränderung, die öffentliche Vorführung, die
                          Lizensierung, der Verkauf oder jede sonstige
                          gewerbliche Nutzung, die nicht im Einklang mit den
                          eingeräumten Rechten stehen,
                        </li>
                        <li>die Veränderung des Kopierschutzes der Dienste,</li>
                        <li>
                          die eigene, nicht durch sporttotal erfolgte Auswertung
                          von den Inhalten, insbesondere die elektronische
                          Auswertung von Laufwegen, Ballkontakten, Toren,
                          Eckstößen, etc.,
                        </li>
                        <li>
                          die Speicherung von Inhalten, soweit es sich nicht um
                          Offlineangebote handelt oder die Speicherung über das
                          sporttotal Coaching Tool ausdrücklich angeboten wird,
                        </li>
                        <li>
                          die elektronische Bearbeitung oder sonstige
                          Veränderung von Inhalten.
                        </li>
                      </ul>
                      Im Falle einer missbräuchlichen Nutzung der Dienste ist
                      sporttotal berechtigt, das mit Ihnen bestehende Abonnement
                      außerordentlich und fristlos zu kündigen. Weitergehende
                      gesetzliche Ansprüche, insbesondere auf Schadensersatz,
                      behält sich sporttotal in diesem Fall ausdrücklich vor.
                    </li>
                    <li>
                      Sofern sporttotal aus rechtlichen oder technischen Gründen
                      Maßnahmen einsetzt, um sicherzustellen, dass
                      coaching.sporttotal.tv oder die Dienste nur in Bestimmten
                      Regionen zur Verfügung stehen (z. B. Geolokalisierung
                      anhand der IP-Adresse des genutzten Internetanschlusses),
                      dürfen Sie diese nicht umgehen oder stören.
                    </li>
                    <li>
                      Sie dürfen keine automatischen Systeme, wie – aber nicht
                      ausschließlich – Robots, Spiders oder Offline-Reader, zum
                      Zwecke des Zugriffs auf coaching.sporttotal.tv oder die
                      Dienste einsetzen, sodass mehr Anfragen an die Server der
                      Plattform gerichtet werden als eine natürliche Person
                      innerhalb desselben Zeitraums vernünftigerweise unter
                      Nutzung eines regulären Standard-Webbrowsers produzieren
                      könnte.
                    </li>
                  </ol>
                </li>
                <li>
                  coaching.sporttotal.tv und die Dienste stellen kein statisches
                  Angebot dar, sondern werden laufend weiterentwickelt und
                  angepasst. Hierdurch können jederzeit neue Funktionen
                  eingeführt oder bestehende Funktionen entfernt werden, ohne
                  dass sporttotal Sie hiervon zuvor in Kenntnis setzen muss.
                </li>
                <li>
                  Ein Anspruch auf ständige Verfügbarkeit oder eine bestimmte
                  Qualität der über coaching.sporttotal.tv angebotenen
                  unentgeltlichen Dienste besteht nicht. sporttotal kann zudem
                  nach eigenem Ermessen (zeitweise oder dauerhaft) einzelne
                  Dienste oder Teile einzelner Dienste einstellen, ohne Sie
                  davon zuvor in Kenntnis zu setzen. Bei entgeltlichen Diensten
                  bedarf Letzteres zunächst einer Kündigung/Beendigung des
                  jeweiligen Vertrags über die entgeltlich zu erbringende
                  Leistung.
                </li>
                <li>
                  Die Dienste auf coaching.sporttotal.tv können sowohl
                  unentgeltlich als auch entgeltlich angeboten werden; abhängig
                  von den jeweiligen Paketen.
                  <ol>
                    <li>
                      Entgeltlich und unentgeltlich bereitgestellte Dienste
                      können durch Werbung unterstützt sein. Insofern behält
                      sich sporttotal vor, z.B. vor Werbebanner für eigene
                      Angebote und Angebote von Dritten anzuzeigen (weitere
                      Infos: siehe Datenschutzerklärung).
                    </li>
                    <li>
                      Entgeltpflichtige Dienste sind als solche gekennzeichnet.
                      Nach Zustandekommen des jeweiligen Einzelvertrags über
                      einen entgeltlichen Dienst stellt sporttotal Ihnen den
                      jeweiligen Inhalt zeitlich auf die Vertragslaufzeit
                      befristet, codiert und verschlüsselt bereit.
                    </li>
                  </ol>
                </li>
                <li>
                  Nutzer, die die Dienste mit ihrem Benutzerkonto nutzen,
                  erhalten von sporttotal spezielle, zu ihren individuellen
                  Nutzungsgewohnheiten passende Funktionen, Informationen und
                  Angebote, z. B. Videoempfehlungen, Funktionsempfehlungen und
                  exklusive Sonderangebote, die sich an den Sehgewohnheiten und
                  thematischen Interessen des Nutzers orientieren
                  (Personalisierung) sowie den personalisierten
                  sporttotal-Newsletter (siehe zum Newsletter unten, Ziffer 10).
                  Zudem werden die Nutzungsgewohnheiten verwendet, um
                  coaching.sporttotal.tv kontinuierlich besser an die
                  Bedürfnisse und Interessen der Nutzer anzupassen. In der
                  Datenschutzerklärung finden sich weitere Informationen, welche
                  Daten hierzu verwendet werden.
                </li>
                <li>
                  Sie sind im Verhältnis zu sporttotal sowie gegenüber Dritten
                  persönlich verantwortlich wegen der Verletzung von Plichten,
                  die aus dem mit der Annahme dieser Allgemeinen
                  Geschäftsbedingungen begründeten Schuldverhältnis resultieren,
                  z.B. auch wenn Dritte unberechtigt Ihr Benutzerkonto
                  verwenden.
                </li>
                <li>
                  sporttotal ist berechtigt, über die Plattform weitere Produkte
                  und Dienstleistungen anzubieten, die von sporttotal selbst
                  oder von Dritten im Zusammenhang mit angebotenen Diensten
                  erbracht werden. sporttotal agiert in diesem Fall nicht
                  zwingend als Anbieter, sondern bewirbt diese Angebote
                  lediglich. Sollten Sie diese Dienste wünschen, so wird hierzu
                  ein weiterer Vertrag mit den jeweiligen Vertragspartnern
                  fällig. sporttotal haftet nicht für die Inhalte der werbenden
                  Drittanbieter.
                </li>
              </ol>
            </li>
            <li>
              Nutzung entgeltlicher Dienste
              <br />
              <span>
                Bei der Inanspruchnahme entgeltlicher Dienste zur zeitlich
                befristeten Nutzung gelten folgende besondere Bedingungen:
              </span>
              <ol>
                <li>
                  <b>Abonnements</b>
                  <br />
                  Bei Abschluss eines Abonnements können Sie die in dem
                  jeweiligen Paket enthaltenen Dienste und Inhalte während der
                  jeweiligen Nutzungsperiode des Abonnements beliebig häufig
                  abrufen und nutzen. Im Rahmen eines nach dem Typ der Inhalte
                  abstrakt beschriebenen Abonnement-Paketes haben Sie keinen
                  Anspruch auf einen bestimmten Inhalt, es sei denn, sporttotal
                  hat Ihnen einen bestimmten Inhalt konkret zugesagt.
                </li>
                <li>
                  Mit der Bereitstellung der abonnierten Dienste und Inhalte
                  beginnt sporttotal mit der Ausführung des Vertrages vor Ablauf
                  der Widerrufsfrist. Dem stimmen Sie zu und bestätigen Ihre
                  Kenntnis davon, dass Sie durch diese Zustimmung mit Beginn der
                  Ausführung des Vertrags auf Ihr Widerrufsrecht verzichten.
                </li>
                <li>
                  Die jeweils aktuelle Preis- und Leistungsübersicht ist
                  einsehbar unter www.coaching.sporttotal.tv. sporttotal behält
                  sich vor, das Portfolio, die jeweilige Preis- und
                  Leistungsbeschreibung und die Realisierung des technischen
                  Abrufs zu ändern (z. B. durch Aktualisierung des
                  Inhaltbestandes), wenn triftige Gründe dies erfordern, Sie
                  durch die Änderung objektiv nicht wesentlich schlechter
                  gestellt werden und die Änderung für Sie zumutbar ist. Als
                  triftige Gründe gelten z.B. Leistungsänderungen von
                  Lizenzgebern oder Vertragspartnern von sporttotal, sowie die
                  Anpassung der Dienste an technische Neuerungen.
                </li>
              </ol>
            </li>
            <li>
              Technische Voraussetzungen
              <ol>
                <li>
                  Um coaching.sporttotal.tv und die Dienste nutzen zu können,
                  müssen Sie über eine geeignete Hardware, Online-Technologie
                  sowie Streaming-Software (z.B. Flash-Player) verfügen und sich
                  auf eigene Kosten und Gefahr Zugang zu elektronischen Diensten
                  und Medien, insbesondere zum Internet, verschaffen. Sie müssen
                  diese Hardware und Online-Technologie den sich verändernden
                  technischen Standards im Internet und von
                  coaching.sporttotal.tv oder der Dienste auf eigene Kosten
                  anpassen.
                </li>
                <li>
                  Die Qualität der Dienste kann von Computer zu Computer und von
                  Gerät zu Gerät variieren und verschiedenen Faktoren
                  unterliegen, wie etwa Ihrem Standort, der verfügbaren
                  Bandbreite und/oder der Geschwindigkeit Ihrer
                  Internetverbindung. sporttotal empfiehlt für die Nutzung der
                  Dienste eine Datenübertragungsrate von mindestens 20 Mbits/s
                  und einen Flatrate-Tarif. Bandbreiten unterhalb dieser
                  Empfehlung erlauben die Nutzung der Dienste nur mit
                  erheblichen Qualitätsverlusten oder in einer verlangsamten
                  Darstellung. Je nach benutzter Internet-Verbindung können
                  abhängig von Anbieter und Tarif, insbesondere bei Zeit- oder
                  Volumentarifen, bei der Nutzung des Dienstes erhebliche
                  Mehrkosten entstehen, die von Ihnen zu tragen sind. 5.3. Für
                  die Darstellungsqualität der Inhalte und Dienste übernimmt
                  sporttotal keine Haftung, da diese nicht im
                  Verantwortungsbereich der sporttotal liegen. Sie allein sind
                  dafür verantwortlich sicherzustellen, dass Sie über sämtliche
                  Hardware und Software verfügen, die zum Abruf des
                  Streaming-Angebotes technisch erforderlich sind.
                </li>
              </ol>
            </li>
            <li>
              Software
              <ol>
                <li>
                  Soweit die Leistungen softwarebasierte Dienste enthalten,
                  erhalten Sie keine Kopie der Software auf einem Datenträger
                  und kein Download-Recht. Sie erhalten zur Nutzung der Dienste
                  ein Zugriffsrecht auf den externen, zentralen Speicherort der
                  Software (sporttotal Server oder Cloud).
                </li>
                <li>
                  Sofern die Software oder Teile davon lokal auf einem
                  vorhandenen Gerät im Eigentum oder Besitz von Ihnen oder auf
                  einem lokalen sporttotal Server, gespeichert werden sollen,
                  geschieht dies allein auf Veranlassung durch sporttotal. Sie
                  erklären sich damit einverstanden. Sie erwerben auch in diesem
                  Fall keine Rechte an der Softwarekopie auf ihrem Gerät, die
                  über die einfache Nutzung hinausgehen. Die Software auf dem
                  Gerät von Ihnen kann von sporttotal automatisch aktualisiert
                  werden, wenn eine neue Version oder Funktion verfügbar ist.
                </li>
                <li>
                  Sie sind nicht berechtigt die Software oder Teile davon,
                  unabhängig vom Speicherort, zu kopieren, zu modifizieren, zu
                  vertreiben, zu verkaufen oder zu vermieten. Sie sind ferner
                  nicht berechtigt, die Software zu untersuchen, zu analysieren,
                  den Quellcode zurückentwickeln (Reverse Engineering) oder zu
                  extrahieren oder darauf einzuwirken, soweit es nicht von
                  sporttotal genehmigt oder gesetzlich zulässig ist. Sie dürfen
                  die Software keinem Dritten zur Nutzung, Analyse oder
                  Einwirkung überlassen oder sonst zugänglich zu machen, gleich
                  ob diese Dritte selbst einen Vertrag mit sporttotal
                  geschlossen haben.
                </li>
                <li>
                  Inhalte des zugehörigen Teams
                  <br />
                  Die sporttotal Dienste ermöglichen es dem Nutzer, eigene
                  Inhalte, insbesondere in Form audiovisueller Inhalte in dem
                  von den sporttotal Diensten ermöglichten Umfang zu erstellen
                  und in verschiedenen vom Dienst bereitgestellten Formen zu
                  downloaden. Darüber hinaus besteht die Möglichkeit eigene
                  audiovisuelle Inhalte und weitere Datensätze innerhalb der
                  Software und innerhalb des in Ziffer 7 näher beschriebenen
                  sporttotal Coaching Tool zu hinterlegen. Sie erteilen an
                  hochgeladenen und zur Verfügung gestellten Inhalten und Daten,
                  die durch Rechte des Teams, insbesondere, jedoch nicht nur des
                  geistigen Eigentums, geschützt sind, Nutzungs- und
                  Verwertungsrechte an sporttotal. Sie garantieren, dass Sie
                  spätestens im maßgeblichen Zeitpunkt über alle erforderlichen
                  Rechte, Einwilligungen und Genehmigungen zur Erstellung
                  audiovisueller Inhalte und Daten und, soweit Sie davon
                  Gebrauch machen, zur Veröffentlichung von diesen Inhalten
                  verfügen und die Inhalte rechtmäßig sind. Dies umfasst
                  insbesondere die Einwilligungen der betroffenen Personen
                  (Spieler, Trainer, Schiedsrichter, Zuschauer), die auf den
                  audiovisuellen Inhalten zu sehen sind und kann im Einzelfall
                  auch Genehmigungen von Behörden, Eigentümern der Sportanlage,
                  Inhabern von Markenrechten und anderen Rechtsinhabern und
                  -trägern umfassen. Die erstellten Inhalte unterliegen
                  diesbezüglich vollständig und allein der Verantwortung des
                  Erstellers. Der maßgebliche Zeitpunkt ist für die Produktion
                  audiovisuellen Inhalte der Start der Aufnahme, bei der
                  Veröffentlichung von Inhalten der Start des Uploads.
                  sporttotal behält sich vor im technischen Prozess, das
                  Vorliegen der Rechte und Einwilligungen separat bestätigen zu
                  lassen und ohne entsprechende Bestätigung die jeweilige
                  Funktion nicht für den Nutzer freizuschalten.
                </li>
                <li>
                  sporttotal ist zu jeder Zeit berechtigt, die Dienste
                  umzustrukturieren, umzugestalten und umzubenennen, soweit dies
                  zur Wahrung des unternehmerischen Interesses von sporttotal
                  erforderlich ist und Ihre wesentlichen Rechte und Interessen
                  nicht beeinträchtigt werden.
                </li>
              </ol>
            </li>
            <li>
              sporttotal Coaching Tool
              <ol>
                <li>
                  Das sporttotal Coaching Tool ermöglicht dem Team und
                  Teamvertretern die von sporttotal oder dritten hergestellten
                  audiovisuellen Aufnahmen des Teams (und anderen Teams, sofern
                  von diesen dazu freigegeben) dafür zu nutzen, eigene
                  Zusammenschnitte zu erstellen, diese mit Grafiktools zu
                  bearbeiten, mit Text- und Audiokommentierungen zu unterlegen,
                  mittels sporttotal eigener künstlicher Intelligenz
                  auszuwerten, Playlisterstellung, Clipping, Ansicht von
                  verschiedenen Views, technical Views und Telestration.
                  Teilweise können die Ergebnisse der Nutzung des sporttotal
                  Coaching Tool unter einem Link gespeichert und in dieser Form
                  von dem Team an von ihm auszuwählende Dritte weitergeleitet
                  werden.
                </li>
                <li>
                  Das sporttotal Coaching Tool ist Software im Sinne der Ziffer
                  6.
                </li>
                <li>
                  Das Entgelt für das nicht-exklusive Nutzungsrecht des
                  sporttotal Coaching Tool richtet sich, soweit nicht zwischen
                  den Parteien etwas anderes vereinbart ist, nach der bei der
                  jeweiligen Bestellung gültigen Preisliste und kann in
                  Paketpreisen inkludiert sein.
                </li>
                <li>
                  Das Nutzungsrecht gemäß Ziffer 7.1 und Ziffer 6 ist begrenzt
                  auf die gebuchte Anzahl an Nutzern. Soweit durch den
                  Bestellvorgang oder individuelle Vereinbarung (Buchung bzw.
                  Nachbuchung) keine andere Nutzerzahl für sporttotal Coaching
                  Tool festgelegt ist, steht dem Team die Nutzung des sporttotal
                  Coaching Tool für zwei Nutzer gleichzeitig zu. Der Nutzer
                  und/oder Team dürfen die Zugangsdaten des sporttotal Coaching
                  Tools im Falle des Ausscheidens eines Nutzers aus dem Team
                  oder aus seiner Funktion im Team auf einen anderen Nutzer
                  übertragen. Der neue Nutzer hat die Zugangsdaten entsprechend
                  zu ändern, um einen weiteren Zugang des ersetzten Nutzers zu
                  verhindern. Darüber hinaus dürfen weder der Nutzer noch das
                  Team die Zugangsdaten zum sporttotal Coaching Tool einem
                  Dritten zugänglich machen.
                </li>
                <li>
                  Das Team ist für die Erstellung, Nutzung, Verbreitung und
                  Veröffentlichung sämtlicher Werke, die er bzw. die von ihm
                  benannten Nutzer mit Hilfe des sporttotal Coaching Tools aus
                  den audiovisuellen Aufnahmen erstellen, selbst verantwortlich.
                  Ihm obliegt es insbesondere, alle notwendigen Einwilligungen
                  von betroffenen Personen (z.B. Spieler) einzuholen.
                </li>
                <li>
                  Vor der Nutzung des sporttotal Coaching Tools verpflichtet
                  sich der Nutzer und/oder das Team die von sporttotal zur
                  Verfügung gestellten Hinweisschilder an seinen Sportstätten
                  für alle an dem Spiel beteiligten Personen erkennbar
                  aufzuhängen und auf die durch sporttotal gefertigten Aufnahmen
                  sowie einer Auswertung der Aufnahmen hinzuweisen.
                </li>
                <li>
                  sporttotal ist vollumfänglich berechtigt, die über das
                  sporttotal Coaching Tool vom Nutzer eingepflegten Inhalte und
                  Daten zu nutzen, zu analysieren, öffentlich zugänglich zu
                  machen und zu verwerten. Eine kommerzielle Nutzung der Inhalte
                  und Daten ist nicht ausgeschlossen. Diesbezüglich erteilen Sie
                  durch Abschluss des Vertrages ihre ausdrückliche Zustimmung.
                </li>
              </ol>
            </li>
            <li>
              Vertragsschluss und Bestellung einzelner Dienste <br />
              <ol>
                <li>
                  Die Möglichkeit zur Registrierung für ein Benutzerkonto und
                  zum Erwerb von registrierungs- und entgeltpflichtigen Diensten
                  sind keine Angebote, sondern nur Aufforderungen zur Abgabe
                  eines Angebots. Durch Abschluss des Registrierungsprozesses
                  oder durch die Bestellung registrierungs- und/oder
                  entgeltpflichtiger Dienste geben Sie ein Angebot zum Abschluss
                  eines Vertrags über die Nutzung des betreffenden Dienstes ab.
                  Etwaige Leistungsbeschreibungen und Preisangaben gelten als
                  Bestandteil dieses Angebots und werden, sofern ein
                  Einzelvertrag über die Nutzung des betreffenden Dienstes
                  zustande kommt, Vertragsbestandteil. sporttotal nimmt das
                  Angebot durch Bereitstellung des jeweiligen Dienstes
                  konkludent oder durch ausdrückliche Erklärung (z. B.
                  Registrierungsbestätigung und Freigabe des Benutzerkontos) an.
                  Erst bei Annahme durch sporttotal kommt ein Vertrag über die
                  Nutzung des jeweiligen Dienstes zustande.
                </li>
                <li>
                  sporttotal behält sich vor, die Bereitstellung oder Nutzung
                  entgeltpflichtiger Dienste davon abhängig zu machen, dass Sie
                  Ihre für die Bestellung des jeweiligen Dienstes genutzte
                  E-Mail-Adresse über einen per E-Mail zugesandten
                  Bestätigungslink bestätigen.
                </li>
                <li>
                  sporttotal behält sich außerdem vor, Ihr Angebot auf Abschluss
                  eines Vertrags über die Nutzung eines kostenpflichtigen
                  Dienstes im Einzelfall bei berechtigtem Interesse (z. B.
                  unzureichender Altersnachweis, unzureichender Nachweis über
                  Teamzugehörigkeit, etc.) abzulehnen.
                </li>
                <li>
                  Der Vertragstext wird von sporttotal nicht gesondert
                  gespeichert. Der Vertragsinhalt ergibt sich aus den Details
                  der Bestellung (einschließlich der zum Bestellzeitpunkt ggf.
                  geltenden Leistungsbeschreibung und ggf. Preisangaben), diesen
                  Allgemeinen Geschäftsbedingungen sowie ggf. besonderen
                  Bedingungen.
                </li>
              </ol>
            </li>
            <li>
              Jugendschutz und Altersverifikation <br />
              <span>
                Bestimmte über coaching.sporttotal.tv angebotene Dienste, die
                nur für bestimmte Altersgruppen geeignet oder freigegeben sind
                oder deren Bereitstellung können von einem Altersnachweis
                abhängig gemacht werden. Über die Notwendigkeit einer
                Altersverifikation werden Sie vor Abschluss des
                Registrierungsprozesses oder vor der Bestellung konkreter
                Dienste informiert.
              </span>
            </li>
            <li>
              Newsletter
              <br />
              <br />
              <span>
                Sofern Sie eingewilligt haben einen Newsletter mit aktuellen
                Informationen zu coaching.sporttotal.tv oder den Diensten der
                sporttotal zu erhalten oder sofern eine andere Rechtsgrundlage
                besteht, fügt sporttotal die dem Benutzerkonto zugrunde gelegte
                E-Mail-Adresse zur Newsletterliste hinzu. Ein Anspruch auf
                diesen Dienst besteht nicht. Sie können jederzeit der Zusendung
                des Newsletters per E-Mail oder Klick auf einen Abmeldelink im
                Newsletter widersprechen.
              </span>
            </li>
            <li>
              Urheberrecht/Schutzrechte Dritter/Verantwortlichkeit
              <br />
              <br />
              <span>
                Die Ihnen als Nutzer zur Verfügung gestellte Plattform und die
                Dienste sind urheberrechtlich oder anderweitig durch
                Leistungsschutzrechte geschützt. Als Nutzer erkennen Sie hiermit
                ausdrücklich die nach dem Urheberrechtsgesetz geschützten Rechte
                und/oder sonstigen Rechte (z.B. Markenrechte) von sporttotal und
                Dritten an und verpflichten sich, diese nicht zu verletzen.
              </span>
            </li>
            <li>
              Preise und Zahlungsbedingungen <br />
              <ol>
                <li>
                  Die Preise für entgeltliche Dienste bei coaching.sporttotal.tv
                  werden im Rahmen der diesbezüglichen Bestellvorgänge bzw. in
                  der Preis- und Leistungsbeschreibung ausgewiesen. Alle Preise
                  gelten einschließlich der jeweils geltenden gesetzlichen
                  Mehrwertsteuer.
                </li>
                <li>
                  Das Entgelt für kostenpflichtige Dienste ist mit
                  Bereitstellung der Leistung sofort fällig. Bei Abonnements
                  sind Zahlungen monatlich zu Beginn jedes Abrechnungsmonats
                  fällig. Der erste Abrechnungsmonat beginnt am Tag des
                  Vertragsschlusses und endet am Tag vor dem gleichen Tag des
                  folgenden Kalendermonats.
                </li>
                <li>
                  Zahlungen können durch alle im Rahmen des Bestellprozesses
                  angegebene, von sporttotal akzeptierte Zahlungsmethoden
                  erfolgen. Mit Zustimmung zum Lastschrifteinzug ermächtigen Sie
                  sporttotal angefallene Entgelte über das von Ihnen angegebene
                  Konto einzuziehen. Sofern Sie einen externen
                  Zahlungssystemanbieter wählen, gelten für die
                  Zahlungsabwicklung ausschließlich die Nutzungs- und
                  Geschäftsbedingungen des jeweiligen Zahlungssystemanbieters.
                </li>
                <li>
                  Im Falle des Zahlungsverzuges gelten die gesetzlichen
                  Regelungen. sporttotal hat im Falle des Verzugs das Recht, Sie
                  von der weiteren Nutzung der sporttotal Dienste zu sperren.
                  Eine solche Sperre entbindet Sie nicht von der Verpflichtung
                  zur Zahlung der angefallenen Entgelte. Auch bei Sperrung
                  aufgrund von erheblichen Pflichtverletzungen insbesondere
                  gegen Ziffer 3 oder bei einem von Ihnen zu vertretenden Fehlen
                  eines Altersnachweises gemäß Ziffer 7 bestehen die
                  Zahlungspflicht sowie die Geltendmachung weiterer Ansprüche
                  wegen Zahlungsverzugs fort.
                </li>
              </ol>
            </li>
            <li>
              Gewährleistungsausschluss <br />
              <ol>
                <li>
                  sporttotal ist bemüht, ein hohes Maß an Verfügbarkeit und
                  Ausfallsicherheit zu erreichen. Durch technische Störungen,
                  Wartungsarbeiten, ungewöhnlich hohe Inanspruchnahme durch
                  Nutzer oder andere Einflüsse kann es jedoch zu Systemausfällen
                  kommen, die die Verfügbarkeit der Plattform und der Dienste
                  beeinträchtigen. sporttotal unternimmt angemessene
                  Anstrengungen und Sorgfalt, um sicherzustellen, dass Ihnen die
                  Dienste zu jeder Zeit zur Verfügung stehen. Darüber hinaus
                  übernimmt sporttotal keine Gewähr für die Verfügbarkeit und
                  Leistungsfähigkeit der Plattform und der Dienste und schließt
                  – soweit gesetzlich zulässig und vorbehaltlich Ziffer 14 –
                  gesetzliche Gewährleistungen aus.
                </li>
                <li>
                  sporttotal ist bemüht, gleichwohl nicht rechtlich
                  verpflichtet, Ihnen mit einer jeweils für gut gefundenen
                  Gestaltung der Plattform oder Dienste ein bestmögliches
                  Erlebnis zu verschaffen. Alle auf der Plattform vorgehaltenen
                  Informationen und Darstellungen haben rein informatorischen
                  Charakter. sporttotal bemüht sich um die Richtigkeit der
                  Angaben, übernimmt jedoch keine Gewähr für diese. sporttotal
                  gewährleistet auch nicht, dass coaching.sporttotal.tv oder die
                  Dienste Ihren oder den Anforderungen anderer Nutzer
                  entsprechen und zu jeder Zeit ohne Unterbrechung, zeitgerecht,
                  sicher und fehlerfrei zur Verfügung stehen und etwaige Fehler
                  von coaching.sporttotal.tv oder der Dienste und/oder der dazu
                  genutzten Hard- oder Software behoben werden.
                </li>
                <li>
                  sporttotal ist ebenfalls nicht verpflichtet und schließt eine
                  Gewährleistung dafür aus, dass bestimmte Sportwettbewerbe
                  (z.B. bestimmte Fußballspiele) oder Highlights von bestimmten
                  Sportwettbewerben in Rahmen der Dienste überhaupt, zu einem
                  bestimmten Zeitpunkt oder ohne Unterbrechungen verfügbar sind.
                  Bitte informieren Sie sich über die Plattform zu den jeweils
                  von den Diensten umfassten Wettbewerben, die zeitliche
                  Verfügbarkeit der Dienste (z.B. Beginn eines Live-Streams oder
                  Dauer von On-Demand-Angeboten) und den etwaigen Ausfall eines
                  Wettbewerbs (z.B. aufgrund schlechter Witterung).
                </li>
              </ol>
            </li>
            <li>
              Laufzeit und Vertragsbeendigung <br />
              <ol>
                <li>
                  Sie können Ihr unentgeltliches Benutzerkonto jederzeit ohne
                  Einhaltung einer Frist kündigen. Die Kündigung kann sowohl
                  schriftlich gegenüber sporttotal erfolgen als auch
                  elektronisch über ein entsprechendes Formular in Ihrem
                  Benutzerkonto.
                </li>
                <li>
                  Verträge über kostenpflichtige Dienste in Form von Abonnements
                  haben die in der jeweiligen Leistungsbeschreibung angegeben
                  Laufzeit und verlängern sich um einen Monat, sofern sie nicht
                  mit einer Frist von 14 Tagen vor Ende der Mindestlaufzeit oder
                  einer Verlängerungslaufzeit gekündigt werden. Die Kündigung
                  kann sowohl schriftlich gegenüber sporttotal erfolgen als auch
                  elektronisch über ein entsprechendes Formular in Ihrem
                  Benutzerkonto.
                </li>
                <li>
                  sporttotal ist berechtigt, diesen Streaming-Vertrag mit einer
                  Frist von sechs Wochen zum Ende eines jeweiligen Monats
                  ordentlich zu kündigen. Das Recht zur außerordentlichen
                  Kündigung bleibt unberührt. Sollte zum Kündigungszeitpunkt
                  aufgrund des gewählten Abrechnungszeitraumes ein
                  Vorauszahlungssaldo zu Ihren Gunsten bestehen, wird Ihnen
                  dieses nach der Beendigung des Vertrages unverzüglich
                  erstattet.
                </li>
                <li>
                  Das Recht beider Parteien zur Kündigung aus wichtigem Grund
                  bleibt unberührt. Ein wichtiger Grund liegt für sporttotal
                  insbesondere dann vor, wenn Sie gegen Vorgaben dieser
                  Allgemeinen Bedingungen, der jeweils einschlägigen Besonderen
                  Bedingungen, gesetzliche Vorschriften oder Rechte Dritter
                  verstoßen und diese Verstöße trotz vorheriger Abmahnung und
                  Fristsetzung sowie ggf. Sperrung Ihres Benutzerkontos nicht
                  abstellen.
                </li>
              </ol>
            </li>
            <li>
              Widerrufsbelehrung <br />
              <br />
              Wenn Sie als Verbraucher einen Vertrag über entgeltpflichtige
              Dienste abschließen gilt Folgendes:
              <ol>
                <li>
                  Widerrufsrecht
                  <br />
                  Sofern Sie nicht rechtgültig auf Ihr Widerrufsrecht verzichtet
                  haben, haben Sie das Recht, binnen vierzehn Tagen ohne Angabe
                  von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist
                  beträgt vierzehn Tage ab dem Tag des Vertragsschlusses. Um Ihr
                  Widerrufsrecht auszuüben, müssen Sie uns (sporttotal.tv GmbH,
                  Am Coloneum 2, 50829 Köln, Tel.: 0221-78877-0, E-Mail:
                  info@sporttotal.tv) mittels eindeutiger Erklärung (z.B. ein
                  mit der Post versandter Brief oder E-Mail) über Ihren
                  Entschluss, diesen Vertrag zu widerrufen, informieren. Sie
                  können das Muster-Widerrufsformular oder eine andere
                  eindeutige Erklärung elektronisch ausfüllen und übermitteln.
                  Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die
                  Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf
                  der Widerrufsfrist absenden.
                  <br />
                  <br />
                  Muster-Widerrufsformular
                  <br />
                  (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte
                  dieses Formular aus und senden Sie es zurück.)
                  <br />
                  An sporttotal.tv GmbH, Am Coloneum 2, 50829 Köln, Tel.:
                  0221-78877-0, E-Mail: coaching@sporttotal.tv:
                  <br />
                  „Hiermit widerrufe(n) ich/wir () das von mir/uns ()
                  abgeschlossene Abonnement über die Erbringung der folgenden
                  Dienstleistung ()
                  <br />
                  Bestellt am ()/erhalten am ()
                  <br />
                  Name des/der Kund:innen(s)
                  <br />
                  Anschrift des/der Kund:innen(s)
                  <br />
                  Unterschrift des/der Kund:innen(s) (nur bei Mitteilung auf
                  Papier)
                  <br />
                  Datum“
                </li>
                <li>
                  Folgen des Widerrufs
                  <br /> Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen
                  alle Zahlungen, die wir von Ihnen erhalten haben,
                  einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen
                  Kosten, die sich daraus ergeben, dass Sie eine andere Art der
                  Lieferung als die von uns angebotene, günstigste
                  Standardlieferung gewählt haben), unverzüglich und spätestens
                  binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die
                  Mitteilung über Ihren Widerruf dieses Vertrags bei uns
                  eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe
                  Zahlungsmittel, das Sie bei der ursprünglichen Transaktion
                  eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich
                  etwas anderes vereinbart; in keinem Fall werden Ihnen wegen
                  dieser Rückzahlung Entgelte berechnet.
                </li>
                <li>
                  Erlöschen des Widerrufsrechts <br />
                  Ihr Widerrufsrecht erlischt bei einem Vertrag über die
                  Lieferung von nicht auf einem körperlichen Datenträger
                  befindlichen digitalen Inhalten oder Diensten vor Ablauf der
                  unter Ziffer 13.1genannten Widerrufsfrist, wenn sporttotal mit
                  der Ausführung des Vertrags begonnen hat, sofern Sie
                  ausdrücklich zugestimmt haben, dass wir mit der Ausführung des
                  Vertrages vor Ablauf der Widerrufsfrist beginnen und Sie Ihre
                  Kenntnis davon bestätigt haben, dass Sie durch Ihre Zustimmung
                  mit Beginn der Ausführung des Vertrags Ihr Widerrufsrecht
                  verlieren.
                </li>
                <li>
                  Automatische Verlängerung und Kündigung kostenpflichtiger
                  Abonnements <br />
                  Sofern Ihr kostenpflichtiges Abonnement nicht für einen
                  vorausbezahlten Zeitraum erworben wurde, verlängert sich Ihre
                  Zahlungspflicht an sporttotal am Ende des jeweiligen
                  Abonnementzeitraums automatisch, es sei denn, Sie kündigen Ihr
                  kostenpflichtiges Abonnement mit einer Frist von 14 Tagen zum
                  Ende des jeweils aktuellen Abonnementzeitraums entweder
                  schriftlich an sporttotal.tv GmbH, Am Coloneum 2, 50829 Köln
                  senden oder elektronisch über eine entsprechende Fläche in
                  Ihrem Benutzerkonto. Die Kündigung wird an dem Tag wirksam,
                  der auf den letzten Tag des aktuellen Abonnementzeitraums
                  folgt. Wir weisen Sie darauf hin, dass es auch im Falle eines
                  unentgeltlichen Probemonats oder einer unentgeltlichen
                  Testphase einer Kündigung bedarf und sich dieser andernfalls
                  in ein entgeltliches Abonnement wandelt.{' '}
                </li>
              </ol>
            </li>
            <li>
              Haftungsbeschränkung <br />
              <ol>
                <li>
                  sporttotal haftet unter der Vereinbarung uneingeschränkt für
                  Schäden aufgrund einer vorsätzlichen oder fahrlässigen
                  Verletzung des Lebens, des Körpers oder der Gesundheit sowie
                  für sonstige Schäden, die auf Vorsatz oder grober
                  Fahrlässigkeit von sporttotal oder seinen gesetzlichen
                  Vertretern oder Erfüllungsgehilfen beruhen. Soweit sporttotal
                  nicht nach den Bestimmungen des ersten Absatzes
                  uneingeschränkt haftet, ist im Falle einer auf einfacher
                  Fahrlässigkeit beruhenden Verletzung einer wesentlichen
                  Vertragspflicht die Haftung von sporttotal auf Schadens- oder
                  Aufwendungsersatz auf den typischerweise vorhersehbaren
                  Schaden beschränkt. Wesentliche Vertragspflichten sind
                  Pflichten, deren Erfüllung die ordnungsgemäße Durchführung der
                  Vereinbarung überhaupt erst ermöglicht und auf deren Erfüllung
                  Sie regelmäßig vertrauen und auch vertrauen dürfen
                  (Kardinalpflichten). Die Haftung von sporttotal aufgrund der
                  anwendbaren produkthaftungsrechtlichen Vorschriften bleibt
                  unberührt. Über die vorgenannten Fälle hinaus ist eine Haftung
                  von sporttotal ausgeschlossen. In diesen Fällen steht Ihnen
                  bei Problemen oder Unzufriedenheit mit den sporttotal Diensten
                  als einzige und ausschließliche Abhilfe die Beendigung der
                  Nutzung der sporttotal Dienste zur Verfügung. sporttotal
                  haftet nicht für Schäden aufgrund eines Datenverlusts, soweit
                  der Schaden hätte verhindert werden können, wenn Sie in
                  angemessenen Abständen Sicherungskopien angefertigt hätten.
                </li>
                <li>
                  Die vorstehenden Haftungsbegrenzungen gelten auch für mit
                  sporttotal verbundene Unternehmen sowie für eine persönliche
                  Haftung der Angestellten, Vertreter, Gesellschafter und
                  Erfüllungsgehilfen von sporttotal und/oder von mit sporttotal
                  verbundenen Unternehmen, sofern Sie Ansprüche unmittelbar
                  gegenüber diesen geltend machen.
                </li>
              </ol>
            </li>
            <li>
              Höhere Gewalt
              <br />
              <span>
                Weder sporttotal noch dritte Rechteinhaber haften für die
                verzögerte Erbringung, die nur teilweise Erbringung oder die
                Nichterbringung der Dienste, soweit sich diese Verzögerung oder
                Nichterbringung der Kontrolle von sporttotal entzieht (z. B.
                Spielabsagen), auf höherer Gewalt beruht (z.B. Arbeitskämpfe,
                Unruhen, kriegerische oder terroristische Auseinandersetzungen
                oder Epidemien/Pandemien) oder des dritten Rechteinhabers
                entzieht, einschließlich, aber nicht begrenzt auf den Ausfall
                von elektronischen oder mechanischen Einrichtungen oder
                Kommunikationswegen, Computerviren, extreme
                Witterungsbedingungen, Naturereignisse, behördlichen
                Anordnungen, Pandemien, Krieg oder Aufruhr.
              </span>
            </li>
            <li>
              Änderungen dieser Nutzungsbedingungen
              <span>
                sporttotal wird diese Allgemeinen Nutzungsbedingungen von Zeit
                zu Zeit ändern. Durch die weitere Nutzung von
                coaching.sporttotal.tv und der Dienste nach dem Datum, zu dem
                sich die Bestimmungen geändert haben, erklären sie sich mit den
                Änderungen einverstanden. Sofern Sie coaching.sporttotal.tv und
                Dienste als registrierter Nutzer verwenden, wird
                coaching.sporttotal.tv Ihnen zudem die Möglichkeit einräumen,
                den geänderten Nutzungsbedingungen innerhalb von vier Wochen
                nach Benachrichtigung über die Änderungen zu widersprechen.
                Andernfalls gilt Ihre Zustimmung zu den geänderten
                Nutzungsbedingungen als erteilt. Sollten Sie einer Änderung der
                Nutzungsbedingungen widersprechen, behält sich sporttotal das
                Recht vor, den Vertrag mit Ihnen zu kündigen. Über diese
                mögliche Folge eines Widerspruchs werden Sie im Rahmen des
                Änderungshinweises informiert.
              </span>
            </li>
            <li>
              Schlussbestimmungen <br />
              <ol>
                <li>
                  Auf das Vertragsverhältnis zwischen sporttotal und Ihnen
                  findet ausschließlich das Recht der Bundesrepublik Deutschland
                  Anwendung, unter Ausschluss des UN-Kaufrechts. Zwingende
                  Bestimmungen des Landes, in dem Sie Ihren gewöhnlichen
                  Aufenthalt haben, bleiben unberührt.
                </li>
                <li>
                  Mit Abschluss des Abonnements erteilen Sie sporttotal die
                  Zustimmung, Informationen über die Häufigkeit abgerufener
                  Inhalte anonymisiert zu erfassen, um die Akzeptanz unseres
                  Streaming-Angebotes zu messen.
                </li>
                <li>
                  sporttotal ist berechtigt, sämtliche Rechte und Pflichten aus
                  diesen Nutzungsbedingungen sowie dem Vertragsverhältnis mit
                  Ihnen insgesamt auf ein mit sporttotal verbundenes Unternehmen
                  oder einen sonstigen Dritten zu übertragen. In diesem Fall
                  informiert sporttotal Sie mindestens vier Wochen im Voraus, so
                  dass Sie ggf. noch laufende bzw. bereits verlängerte Verträge
                  (Abonnements) vor der Durchführung der Übertragung innerhalb
                  der vereinbarten Mindestlaufzeit kündigen können.
                </li>
                <li>
                  Sollten einzelne Bestimmungen dieser Vereinbarung unwirksam
                  oder undurchführbar sein oder zu einem späteren Zeitpunkt
                  werden, bleibt die Wirksamkeit der übrigen Vereinbarung davon
                  unberührt. Die Vertragsparteien verpflichten sich für diesen
                  Fall schon jetzt, einen Ersatz der Bestimmung, welcher dem
                  wirtschaftlichen Zweck der unwirksamen oder undurchführbaren
                  Bestimmung am nächsten kommt, zu vereinbaren. Gleiches gilt
                  für eine Vertragslücke.
                </li>
                <li>
                  Die Europäische Kommission stellt unter der Internetadresse
                  http://ec.europa.eu/consumers/odr eine Plattform zur
                  Online-Streitbeilegung zur Verfügung. sporttotal ist
                  allerdings weder verpflichtet noch bereit, an einem
                  Streitbeilegungsverfahren vor einer
                  Verbraucherschlichtungsstelle teilzunehmen.
                </li>
              </ol>
            </li>
          </ol>
        </Content>
      </Wrapper>
    </Root>
  )
}
