// Utils
import { eventIndexToIconDictionary } from 'modules/core/constants'
import { makeGroupEvents } from 'modules/event/utils'
// Constants
import { defaultIconName } from 'modules/core/constants'
import { DEFAULT_EVENT_NAME } from 'modules/event/constants'
// Types
import { GroupEvent } from 'modules/event/types'
import { TimelineEvent } from '../types'

const EVENT_MIN_WIDTH = 36

/*
  Used to make group of clips that closed to each other
  Returns object with next parameters:
  - names - list of iconNames to display
  - start - start position on layout
  - end - end position on layout
  - duration - length of
 */
export const groupEventsByTime = (
  timelineEvents: TimelineEvent[],
  widthPerSecond: number
): GroupEvent[] => {
  const parsedArray = timelineEvents.map(event => {
    const durationInSeconds = event.duration
    const startInSeconds = event.startTime

    // TODO: clarify problem below
    // we need to have this variable to ensure we display event correctly,
    // as if event duration is 0 seconds is unclear how to display it
    const minSecondsBasedOnWidth = EVENT_MIN_WIDTH / widthPerSecond
    const eventDuration =
      durationInSeconds > minSecondsBasedOnWidth
        ? durationInSeconds
        : minSecondsBasedOnWidth

    return {
      name: event.event?.name ?? DEFAULT_EVENT_NAME,
      icon:
        eventIndexToIconDictionary[event.event?.type ?? ''] ?? defaultIconName,
      start: startInSeconds,
      end: startInSeconds + eventDuration,
    }
  })

  return makeGroupEvents(parsedArray)
}
