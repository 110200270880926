import { css, styled } from '@mui/material'
import { LayoutItemToolbarContainer } from 'modules/layout/components/LayoutItemToolbar/LayoutItemToolbar.styled'
import { LayoutItemToolbarDivider } from 'modules/generic/components/ToolbarHeader/ToolbarHeader.styled'
import { AnimatedChevronIcon } from '../AssetList/AssetList.styled'

export const ToolbarContainer = styled(LayoutItemToolbarContainer)`
  position: relative;
  padding: ${({ theme }) => theme.spacing(1.25)};
  height: ${({ theme }) => theme.componentToolbarDefaultHeight}px;
`

export const ToolbarTitle = styled(LayoutItemToolbarDivider)`
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  padding-right: ${({ theme }) => theme.spacing(0.5)};
  flex: 1;
`

export const ToolbarActions = styled(LayoutItemToolbarDivider, {
  shouldForwardProp: prop => prop !== 'full',
})<{ full?: boolean }>`
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  justify-content: center;
  flex: 1;

  ${({ full }) =>
    full &&
    css`
      flex-grow: 5;
    `}
`

export const ToolbarControlsWrapper = styled(LayoutItemToolbarDivider)`
  display: flex;
  justify-content: end;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  padding-right: ${({ theme }) => theme.spacing(0.5)};
  flex: 1;
`

export const EventSelectorWrapper = styled('div')`
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  width: fit-content;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.light};
  height: 26px;
`

export const EventSelectIcon = styled(AnimatedChevronIcon)`
  width: 10px;
  height: 10px;
  padding: 0;
`
