import {
  ClubList,
  ListClubListOptions,
} from '@sporttotal-tv/dip-coaching-client'
import { api } from 'modules/core/api'
import { transformApiResults } from 'modules/core/helpers'

export const ClubApiService = {
  list: transformApiResults<ListClubListOptions, ClubList>(api.club.list),
}
