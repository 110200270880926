import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import {
  CreatePlayerContractBody,
  PlayerContract,
  PlayerContractList,
} from '@sporttotal-tv/dip-coaching-client'
import { TEAM_PLAYER_CONTRACT_LIST_CACHE_KEY } from 'modules/team-player/hooks'
import { MutateContext } from 'modules/core/types/mutate-context.type'
import { InfiniteQuery } from 'modules/core/types'
import { placeholderContractPlayer } from 'modules/team-player/constants/placeholderContract'

export const CONTRACT_CREATE_CACHE_KEY = 'contract-create'

export const useTeamPlayerContractCreate = () => {
  const queryClient = useQueryClient()

  return useMutation<
    PlayerContract,
    Error,
    CreatePlayerContractBody,
    MutateContext<InfiniteQuery<PlayerContractList>>
  >(
    CONTRACT_CREATE_CACHE_KEY,
    (params: CreatePlayerContractBody) =>
      ApiService.teamPlayerContract.create(params),
    {
      onMutate: async data => {
        await queryClient.cancelQueries(TEAM_PLAYER_CONTRACT_LIST_CACHE_KEY, {
          exact: false,
        })

        const previousData = queryClient.getQueriesData<
          InfiniteQuery<PlayerContractList>
        >(TEAM_PLAYER_CONTRACT_LIST_CACHE_KEY)

        if (previousData) {
          queryClient.setQueriesData<
            InfiniteQuery<PlayerContractList> | undefined
          >(
            TEAM_PLAYER_CONTRACT_LIST_CACHE_KEY,
            oldData =>
              oldData && {
                pageParams: oldData.pageParams,
                pages: oldData.pages.map(
                  (page, pageIndex) =>
                    page && {
                      total: page.total + 1,
                      results:
                        pageIndex + 1 === oldData.pages.length
                          ? [...page.results, placeholderContractPlayer(data)]
                          : page.results,
                    }
                ),
              }
          )
        }

        return { previousData }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData(prevData[0], prevData[1])
          )
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(TEAM_PLAYER_CONTRACT_LIST_CACHE_KEY, {
          exact: false,
        })
      },
    }
  )
}
