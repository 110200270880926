import {
  PlayerListParams,
  PlayerList,
  Player,
  CreatePlayerBody,
  UpdatePlayerBody,
  PlayerProfile,
} from '@sporttotal-tv/dip-coaching-client'
import { api } from 'modules/core/api'
import {
  transformApiResults,
  transformIdApiResults,
} from 'modules/core/helpers'

export const TeamPlayerApiService = {
  list: transformApiResults<PlayerListParams, PlayerList>(api.player.list),
  retrieve: transformApiResults<string, Player>(api.player.retrieve),
  create: transformApiResults<CreatePlayerBody, Player>(api.player.create),
  delete: transformApiResults<string, Player>(api.player.delete),
  update: transformIdApiResults<UpdatePlayerBody, Player>(api.player.update),
  teamPlayerProfileRetrieve: transformApiResults<string, PlayerProfile>(
    api.playerProfile.retrieve
  ),
}
