import { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
// Hooks
import {
  useAdminOrganization,
  useAdminOrganizationUserList,
} from 'modules/organization/hooks'
// Components
import { Root, Main, Wrapper } from './AdminOrganizationView.styled'
import {
  OrganizationAdminEdit,
  OrganizationUserList,
} from 'modules/organization/components'
// Constants
import { routeNames } from 'modules/core/constants'

export const AdminOrganizationView = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const organization = useAdminOrganization(id ?? '')

  const { data } = useAdminOrganizationUserList({
    organization_id: id,
    expand: ['user'],
  })

  const users = useMemo(
    () =>
      data?.results.map(({ id, user }) => ({
        ...user!,
        organizationUserId: id,
      })) ?? [],
    [data?.results]
  )

  const handleUserOnClick = useCallback(
    (id: string) => () => {
      navigate(`${routeNames.admin.path}/user/${id}`)
    },
    [navigate]
  )

  return (
    <Root>
      <Main>
        {organization.data && (
          <>
            <Wrapper>
              <OrganizationUserList
                organizationId={id ?? ''}
                users={users}
                handleOnUserClick={handleUserOnClick}
              />
            </Wrapper>
            <Wrapper>
              <OrganizationAdminEdit organization={organization.data} />
            </Wrapper>
          </>
        )}
      </Main>
    </Root>
  )
}
