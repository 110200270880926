import {
  MatchListParams,
  Match,
  MatchList,
  MatchData,
  MatchRetrieveParams,
} from '@sporttotal-tv/dip-coaching-client'
import { api } from 'modules/core/api'
import { transformApiResults } from 'modules/core/helpers'

export const MatchApiService = {
  list: transformApiResults<MatchListParams, MatchList>(api.match.list),
  retrieve: transformApiResults<MatchRetrieveParams, Match>(api.match.retrieve),
  retrieveData: transformApiResults<string, MatchData>(api.match.retrieveData),
}
