import { ApiService } from 'services/Api.service'
import { createMutationHook } from 'modules/core/api/hooks/utils'
import { MATCH_RETRIEVE_CACHE_KEY } from 'modules/match/hooks'
import { HIGHLIGHT_LIST_CACHE_KEY } from './useHighlightList'

export const [useHighlightDelete] = createMutationHook(
  ApiService.highlight.delete,
  queryClient => ({
    onSettled: () => {
      queryClient.invalidateQueries(HIGHLIGHT_LIST_CACHE_KEY)
      queryClient.invalidateQueries(MATCH_RETRIEVE_CACHE_KEY)
    },
  })
)
