export const calculateDiscount = (
  yearlyPriceCents: number,
  monthlyPriceCents: number
) => {
  const monthlyPriceForYear = monthlyPriceCents * 12
  const centsSavings = monthlyPriceForYear - yearlyPriceCents
  const discount = centsSavings / monthlyPriceForYear

  return Math.round(discount * 100)
}
