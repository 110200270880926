import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
// Hooks
import { useSnackbar } from 'modules/core/hooks/useSnackbar'
// Components
import { ModalDialog } from 'modules/core/components/ModalDialog'
import { TextInput } from 'modules/core/components/TextInput'
import { ActionButton } from 'modules/core/components/ActionButton'
// Types
import { PlayingSurfaceCreatePayload } from '@sporttotal-tv/dip-coaching-client'
import { PlayingSurfaceCreateModalProps } from './PlayingSurfaceCreateModal.interface'
// Styled
import { Form, ButtonContainer } from './PlayingSurfaceCreateModal.styled'
import { usePlayingSurfaceCreate } from 'modules/playing-surface/hooks'

export const PlayingSurfaceCreateModal: FC<PlayingSurfaceCreateModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'playingSurface' })
  const { register, handleSubmit } = useForm<PlayingSurfaceCreatePayload>()
  const showSnackbar = useSnackbar()

  const { mutate } = usePlayingSurfaceCreate()

  const onSubmit = (data: PlayingSurfaceCreatePayload) =>
    mutate(data, {
      onSuccess: () => {
        showSnackbar({
          message: t('notification.create.success'),
          type: 'success',
        })
        onClose()
      },
      onError: () =>
        showSnackbar({
          message: t('notification.create.failed'),
          type: 'error',
        }),
    })

  return (
    <ModalDialog
      open={isOpen}
      onClose={onClose}
      title={t('actions.create')}
      icon='plus-circle'
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          {...register('name', { required: true })}
          label={t('modal.create.name')}
          fullWidth
        />
        <TextInput
          {...register('location')}
          label={t('modal.create.location')}
          fullWidth
          multiline
          rows={3}
        />
        <ButtonContainer>
          <ActionButton
            type='submit'
            variant='contained'
            text={t('actions.create')}
          />
        </ButtonContainer>
      </Form>
    </ModalDialog>
  )
}
