import { useMemo } from 'react'
import { useUserCache } from 'modules/cache/hooks'

export const useUserStorageMatchData = (
  componentKey: string,
  matchKey: string
) => {
  const userCache = useUserCache({ keys: [componentKey] })

  const matchData = useMemo(
    () => userCache.data?.[componentKey]?.[matchKey],
    [userCache.data, componentKey, matchKey]
  )

  const collapsed: boolean = useMemo(
    () => matchData?.collapsed ?? true,
    [matchData]
  )
  const focused: boolean = useMemo(
    () => matchData?.focused ?? false,
    [matchData]
  )

  return { collapsed, focused }
}
