import { FC, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
// Context
import { SnackbarContext } from 'modules/core/context'
// Hooks
import { useOrganizationUserInviteCreate } from 'modules/organization-admin/hooks'
import { yupResolver } from '@hookform/resolvers/yup'
// Components
import { Icon, IconButton } from 'modules/core/components'
// Constants
import { nonInternalUserRoles } from 'modules/user/constants'
// Schema
import { organizationUserInviteSchema } from 'modules/organization-admin/schemas'

import {
  DialogHeader,
  DialogTitleWrapper,
  StyledDialog,
  DialogTitle,
  DialogBody,
  FormControlWrapper,
  Label,
  ButtonWrapper,
  SaveButton,
  SaveIcon,
  FullWidthFormControl,
  ErrorMessage,
  RoleSelector,
  StyledMenuItem,
  InputText,
} from './OrganizationUserInviteModal.styled'
import {
  OrganizationInviteModalProps,
  OrganizationUserInviteCreatePayload,
} from './OrganizationUserInviteModal.interface'

const textInputs: Record<
  Exclude<keyof OrganizationUserInviteCreatePayload, 'role'>,
  string
> = {
  first_name: 'name',
  last_name: 'surname',
  email: 'email',
}

export const OrganizationUserInviteModal: FC<OrganizationInviteModalProps> = ({
  organizationId,
  open,
  setOpen,
}) => {
  const { t } = useTranslation(['pages', 'userRole'])
  const { showSnackbar } = useContext(SnackbarContext)

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<OrganizationUserInviteCreatePayload>({
    resolver: yupResolver(organizationUserInviteSchema),
  })

  const createOrganizationUserInvite =
    useOrganizationUserInviteCreate(organizationId)

  const roles = useMemo(() => Object.entries(nonInternalUserRoles), [])

  const onSubmit = (data: OrganizationUserInviteCreatePayload) => {
    createOrganizationUserInvite.mutate(data, {
      onError: () =>
        showSnackbar({
          message: 'Something went wrong. Please try again later.',
          type: 'error',
        }),
      onSuccess: setOpen.bind(null, false),
    })
  }

  return (
    <StyledDialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        elevation: 0,
      }}
    >
      <DialogHeader>
        <DialogTitleWrapper>
          <Icon name='pencil' />
          <DialogTitle>{t('pages:admin.context.inviteUser')}</DialogTitle>
        </DialogTitleWrapper>
        <IconButton name='close' onClick={() => setOpen(false)} />
      </DialogHeader>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogBody>
          {Object.entries(textInputs).map(([key, field], index) => (
            <FormControlWrapper key={`${field}-${index}`}>
              <Label>{t(`pages:admin.modal.inviteUser.${field}`)}</Label>
              <FullWidthFormControl>
                <InputText
                  inputProps={{ 'aria-label': key }}
                  placeholder=''
                  disableUnderline
                  // @ts-ignore
                  error={!!errors[key]?.message}
                  {...register(
                    key as keyof OrganizationUserInviteCreatePayload
                  )}
                />

                <ErrorMessage>
                  {t(
                    errors?.[key as keyof OrganizationUserInviteCreatePayload]
                      ?.message ?? ''
                  )}
                </ErrorMessage>
              </FullWidthFormControl>
            </FormControlWrapper>
          ))}

          <FormControlWrapper>
            <Label>{t('pages:admin.modal.inviteUser.role')}</Label>
            <FullWidthFormControl>
              <Controller
                name='role'
                control={control}
                render={({ field }) => (
                  <RoleSelector
                    inputProps={{ 'aria-label': 'role' }}
                    error={!!errors.role?.message}
                    defaultValue=''
                    {...field}
                  >
                    {roles.map(([key, role]) => (
                      <StyledMenuItem key={key} value={key}>
                        {t(`userRole:${role}`)}
                      </StyledMenuItem>
                    ))}
                  </RoleSelector>
                )}
              />
              <ErrorMessage>{t(errors.role?.message ?? '')}</ErrorMessage>
            </FullWidthFormControl>
          </FormControlWrapper>

          <ButtonWrapper>
            <SaveButton type='submit'>
              <SaveIcon name='save' />
              {t('pages:admin.button.save')}
            </SaveButton>
          </ButtonWrapper>
        </DialogBody>
      </form>
    </StyledDialog>
  )
}
