import { ShapeHandler } from 'modules/annotator/types'
import { ShapeOptions, TelestrationMultiLine } from 'modules/telestration/types'

const MultiLine: ShapeHandler<TelestrationMultiLine> = {
  onCreate({ currentTarget }, options?: ShapeOptions) {
    const points = currentTarget.getStage()?.getPointerPosition()
    const X = points?.x ?? null
    const Y = points?.y ?? null

    if (!X || !Y) return

    return {
      ...options,
      type: 'MULTI_LINE',
      points: [X, Y],
    }
  },

  onShaping(line, { currentTarget }) {
    const point = currentTarget.getStage()?.getPointerPosition()
    const X = point?.x ?? null
    const Y = point?.y ?? null

    if (!X || !Y) return

    if (!line.points) return { ...line, points: [X, Y] }

    const points = [...(Array.from(line.points) ?? []), X, Y]
    return { ...line, points }
  },

  onShapeUpdated({ currentTarget }) {
    return currentTarget
  },
}

export { MultiLine }
