import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Player } from '@sporttotal-tv/dip-coaching-client'
// hooks
import { useTeamPlayerList } from 'modules/team-player/hooks'
import {
  useDebounce,
  useOutsideClick,
  usePageScrolling,
} from 'modules/core/hooks'
// styled
import { HorizontalLoader } from 'modules/core/styled'
// constants
import { TEAM_PAGE_LIMIT } from 'modules/team/constants'

import {
  PlayerSelectorModal,
  Search,
  Divider,
  PlayersList,
  PlayerItem,
  PlayerSelectIcon,
  PlayerSelectorIntractable,
  PlayerSelectorWrapper,
  PlayerName,
  PlayerErrorMessage,
} from './TeamPlayerSelector.styled'
import { TeamPlayerSelectorProps } from './TeamPlayerSelector.interface'

export const TeamPlayerSelector: FC<TeamPlayerSelectorProps> = ({
  showError,
  player,
  setPlayer,
  centered,
}) => {
  const { t } = useTranslation('components', { keyPrefix: 'teamPlayers' })
  const [searchValue, setSearchValue] = useState<string>('')
  const { containerRef, isBottom, resetIsBottom } = usePageScrolling(100)
  const debounceSearchValue = useDebounce(searchValue, 500)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const modalRef = useRef<HTMLDivElement>(null)

  const wrapperRef = useRef<HTMLDivElement>(null)
  const handleOutsideClick = useCallback(() => setModalOpen(false), [])
  useOutsideClick<HTMLDivElement>(modalRef, handleOutsideClick, wrapperRef)

  const teamPlayerList = useTeamPlayerList({
    page_limit: TEAM_PAGE_LIMIT,
    search_by: ['first_name', 'last_name'],
    search: debounceSearchValue,
  })

  const handlePlayerClick = useCallback(
    (player: Player) => () => {
      setPlayer(player)
      setModalOpen(false)
    },
    [setPlayer]
  )

  useEffect(() => {
    if (
      isBottom &&
      teamPlayerList.hasNextPage &&
      !teamPlayerList.isFetchingNextPage &&
      !teamPlayerList.isLoading
    ) {
      resetIsBottom()
      teamPlayerList.fetchNextPage()
    }
  }, [teamPlayerList, isBottom, resetIsBottom])

  return (
    <PlayerSelectorWrapper>
      <PlayerSelectorModal ref={modalRef} hidden={!modalOpen} center={centered}>
        <Search
          placeholder='Search'
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          inputProps={{ 'aria-label': 'team-player-search' }}
        />
        <Divider />
        <PlayersList
          ref={containerRef}
          role='listbox'
          aria-label='team-player-list'
        >
          {teamPlayerList.data?.pages.map(page =>
            page.results.map(teamPlayer => (
              <PlayerItem
                key={teamPlayer.id}
                onClick={handlePlayerClick(teamPlayer)}
              >
                {teamPlayer.first_name} {teamPlayer.last_name}
              </PlayerItem>
            ))
          )}
          {teamPlayerList.isFetchingNextPage && <HorizontalLoader />}
        </PlayersList>
      </PlayerSelectorModal>
      <PlayerSelectorIntractable
        ref={wrapperRef}
        onClick={() => setModalOpen(prev => !prev)}
      >
        <PlayerName>
          {player
            ? `${player.first_name} ${player.last_name}`
            : t('modal.general.selectPlayer')}
        </PlayerName>
        <PlayerSelectIcon name='chevron-small-down' chevronUp={true} />
      </PlayerSelectorIntractable>
      {!player && showError && (
        <PlayerErrorMessage>
          {t('modal.general.playerError')}
        </PlayerErrorMessage>
      )}
    </PlayerSelectorWrapper>
  )
}
