type CalendarFilterDates = {
  startDate: number
  endDate: number
}

export const createCalendarFilterDates = (
  startDate: Date,
  endDate?: Date
): CalendarFilterDates => {
  const start = new Date(startDate)
  const end = new Date(endDate ? endDate : startDate)

  start.setHours(0, 0, 0, 0)
  end.setHours(23, 59, 59, 999)

  return {
    startDate: Math.round(start.getTime() / 1000),
    endDate: Math.round(end.getTime() / 1000),
  }
}
