import { Typography, Button as BaseButton, styled } from '@mui/material'
import { Icon } from 'modules/core/components'

export const Main = styled('div')`
  margin: ${({ theme }) => theme.spacing('20%', '10%')};
  column-gap: ${({ theme }) => theme.spacing(2)};
  height: 100%;
  display: flex;
  flex-direction: column;
  place-items: center;
`

export const StatusText = styled(Typography)`
  font-size: 50px;
  font-weight: 600;
`

export const StatusIcon = styled(Icon)`
  width: 50px;
  height: 50px;
  margin-top: 50px;
`

export const CoachingButton = styled(BaseButton)`
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  background-color: ${({ theme }) => theme.palette.primary.main};
  margin-top: ${({ theme }) => theme.spacing(10)};
  padding: ${({ theme }) => theme.spacing(1.25, 4)};

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`
