import ISO639 from 'iso-639-1'

// Types
import { Language } from 'modules/core/i18n/types'

// Icons
import EnIcon from 'language-icons/icons/en.svg'
import DeIcon from 'language-icons/icons/de.svg'
// import ItIcon from 'language-icons/icons/it.svg'

export const languages = Object.freeze<Language[]>([
  {
    displayName: ISO639.getNativeName('en'),
    languageCode: ISO639.getCode('english'),
    iconSrc: EnIcon,
  },
  {
    displayName: ISO639.getNativeName('de'),
    languageCode: ISO639.getCode('german'),
    iconSrc: DeIcon,
  },
  // {
  //   displayName: ISO639.getNativeName('it'),
  //   languageCode: ISO639.getCode('italian'),
  //   iconSrc: ItIcon,
  // },
])
