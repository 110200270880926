import {
  useEffect,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
  useContext,
  useCallback,
} from 'react'
import { contain } from 'intrinsic-scale'
// Hooks
import { useKey, useReduxSelector, useResizeObserver } from 'modules/core/hooks'
import { Annotator } from 'modules/annotator/components'
import { useFrameRetrieve } from 'modules/frame/hooks'
// Context
import { ComponentInfoContext } from 'modules/generic/context'
import {
  VideoPlayerContext,
  VideoPlayersContext,
} from 'modules/video-player/context'
// Redux
import { selectActiveUrl, selectFrameId } from 'modules/video-player/redux'
// Constants
import { keyCodes } from '@fluentui/keyboard-keys'
// Types
import { RenderElementProps } from 'modules/video-player/types'
import { MediaPlayer } from 'modules/media-render/types'
// Classes
import { MockedVideoPlayer } from 'modules/video-player/classes'

export const ImageRender = forwardRef<
  MediaPlayer | undefined,
  RenderElementProps
>(
  (
    {
      isTelestrationToolAvailable,
      setIsLoadingShape,
      isKeyboardControlEnabled,
    },
    videoRef
  ) => {
    const imageRenderObject = useMemo(() => new MockedVideoPlayer(), [])
    const imageRef = useRef<HTMLImageElement>(null)
    const { componentId } = useContext(ComponentInfoContext)
    const activeUrl = useReduxSelector(state =>
      selectActiveUrl(state, componentId)
    )

    const handlePlayPause = useCallback(() => {
      imageRenderObject.paused
        ? imageRenderObject.play()
        : imageRenderObject.pause()
    }, [imageRenderObject])

    useKey(keyCodes.Space, handlePlayPause, {
      disabled: !isKeyboardControlEnabled,
    })

    const activeFrameId = useReduxSelector(state =>
      selectFrameId(state, componentId ?? '')
    )
    const { setPlayer: setPlayerContext } = useContext(VideoPlayerContext)
    const { addPlayer: addPlayerToPlayersContext } =
      useContext(VideoPlayersContext)

    const { data: activeFrame } = useFrameRetrieve(
      {
        id: activeFrameId ?? '',
        expand: ['parent_media_locator'],
      },
      {
        enabled: !!activeFrameId,
      }
    )

    useEffect(() => {
      if (imageRenderObject) {
        setPlayerContext(imageRenderObject, componentId)
        addPlayerToPlayersContext(imageRenderObject, componentId)
      }
    }, [
      imageRenderObject,
      componentId,
      setPlayerContext,
      addPlayerToPlayersContext,
    ])

    useImperativeHandle(
      videoRef,
      () => {
        return imageRenderObject
      },
      [imageRenderObject]
    )

    useEffect(() => {
      imageRenderObject.play().catch(console.error)
      return () => {
        imageRenderObject.clearData()
      }
    }, [imageRenderObject])

    useEffect(() => {
      if (activeFrame && activeFrame.duration) {
        imageRenderObject.updateDuration(activeFrame.duration)
      }
    }, [activeFrame, imageRenderObject])

    const { height: playerContainerHeight, width: playerContainerWidth } =
      useResizeObserver(imageRef)

    const {
      width: videoWidth,
      height: videoHeight,
      x: videoLeft,
      y: videoTop,
    } = contain(
      playerContainerWidth,
      playerContainerHeight,
      imageRef.current?.naturalWidth ?? 0,
      imageRef.current?.naturalHeight ?? 0
    )

    return (
      <div
        style={{
          position: 'relative',
          display: 'flex',
          width: '100%',
          height: '100%',
        }}
      >
        <img
          ref={imageRef}
          src={activeUrl}
          alt='frame of match'
          style={{ width: '100%', background: '#000', objectFit: 'contain' }}
        />

        <div style={{ position: 'absolute', inset: 0 }}>
          {isTelestrationToolAvailable && (
            <Annotator
              onLoadingStatusChange={setIsLoadingShape}
              containerHeight={playerContainerHeight}
              containerWidth={playerContainerWidth}
              elementHeight={videoHeight}
              elementWidth={videoWidth}
              elementLeft={videoLeft}
              elementTop={videoTop}
            />
          )}
        </div>
      </div>
    )
  }
)
