import { FC } from 'react'
// Hooks
import { useLayoutItemControl } from 'modules/layout/hooks'
import { useUserCache } from 'modules/cache/hooks'
// Components
import { IconButton } from 'modules/core/components'
// Types
import { ToolbarControlsProps } from './ToolbarControls.interface'
// constants
import { USER_SETTINGS_OPTIONS } from 'modules/user/components/ProfileSettings/constants'

import { ToolbarDivider, DragIcon } from './ToolbarControls.styled'

export const ToolbarControls: FC<ToolbarControlsProps> = ({
  layoutId,
  layoutIndex,
}) => {
  const { isLocked, handleLockingLayoutItem, handleRemoveLayoutItem } =
    useLayoutItemControl(layoutId, layoutIndex)

  const { data } = useUserCache({
    keys: Object.keys(USER_SETTINGS_OPTIONS),
  })

  const isCustomLayout =
    data && USER_SETTINGS_OPTIONS.CUSTOM_LAYOUTS in data
      ? data[USER_SETTINGS_OPTIONS.CUSTOM_LAYOUTS]
      : true

  return (
    <ToolbarDivider>
      {!isLocked && <DragIcon className='draggable-handle' />}
      <IconButton
        name={isCustomLayout ? 'lock' : isLocked ? 'lock' : 'unlock'}
        onClick={e => {
          e.stopPropagation()
          handleLockingLayoutItem()
        }}
      />
      <IconButton
        name='close'
        onClick={e => {
          e.stopPropagation()
          handleRemoveLayoutItem()
        }}
      />
    </ToolbarDivider>
  )
}
