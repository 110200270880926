import { RouteNames } from 'modules/core/types'

export const routeNames: RouteNames = {
  main: {
    path: '/',
    relative: '/*',
  },
  analysis: {
    path: '/analysis',
    relative: 'analysis/*',
  },
  playlist: {
    path: '/playlist',
    relative: 'playlist/*',
  },
  help: {
    path: '/help',
    relative: 'help/*',
  },
  admin: {
    path: '/admin',
    relative: 'admin/*',
  },
  team: {
    path: '/team',
    relative: 'team/*',
  },
  profile: {
    path: '/profile',
    relative: 'profile/*',
    tabs: [
      { name: 'Profile', path: 'home' },
      { name: 'Login', path: 'login' },
      { name: 'Embed', path: 'embed' },
      { name: 'Notification', path: 'notification' },
    ],
  },
  calendar: {
    path: '/calendar',
    relative: 'calendar/*',
  },
  subscription: {
    path: '/subscription',
    relative: 'subscription/*',
  },
  redirecting: {
    path: '/redirecting',
    relative: '/redirecting/*',
  },
  organization: {
    path: '/organization',
    relative: '/organization/*',
  },
  camera: {
    path: '/cameras',
    relative: 'cameras/*',
  },
}
