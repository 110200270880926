import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react'
// components
import { VideoRender } from 'modules/video-player/components/VideoRender'
import { ImageRender } from 'modules/video-player/components/ImageRenderer'
// types
import { MediaPlayer } from 'modules/media-render/types'
import {
  MediaLocatorType,
  RenderElementProps,
} from 'modules/video-player/types'

export const videoPlayerRenderComponent: Record<
  MediaLocatorType,
  ForwardRefExoticComponent<
    RenderElementProps & RefAttributes<MediaPlayer | undefined>
  >
> = {
  VIDEO: VideoRender,
  IMAGE: ImageRender,
  AUDIO: forwardRef(() => null),
}
