import { useInfiniteQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { MatchListParams, MatchList } from '@sporttotal-tv/dip-coaching-client'
import { MATCH_PAGE_LIMIT } from 'modules/match/constants'
import { useQueryRetry } from 'modules/core/hooks'
import { getNextPageParamWithLimit } from 'modules/core/utils'

export const MATCH_LIST_CACHE_KEY = 'match-list'

export const useMatchList = (options?: MatchListParams) => {
  const handleQueryRetry = useQueryRetry()

  return useInfiniteQuery(
    [MATCH_LIST_CACHE_KEY, options],
    ({ pageParam = 0 }) =>
      ApiService.match.list({ ...options, page: pageParam }),
    {
      retry: handleQueryRetry,
      // refetchInterval: 10000,
      getNextPageParam: getNextPageParamWithLimit<MatchList>(MATCH_PAGE_LIMIT),
    }
  )
}
