import { useInfiniteQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { useQueryRetry } from 'modules/core/hooks'
import { ListAdminUserInviteListOptions } from '@sporttotal-tv/dip-coaching-client'
import { INVITES_PAGE_LIMIT } from 'modules/user/constants'

export const INVITE_LIST_CACHE_KEY = 'invite-list'

export const useInviteList = (options?: ListAdminUserInviteListOptions) => {
  const handleQueryRetry = useQueryRetry({ maxRetries: 0 })

  return useInfiniteQuery(
    [INVITE_LIST_CACHE_KEY, options],
    ({ pageParam = 0 }) =>
      ApiService.invite.list({ ...options, page: pageParam }),
    {
      staleTime: Infinity,
      retry: handleQueryRetry,
      getNextPageParam: (lastPage, allPages) => {
        const allResults = allPages.reduce(
          (numberOfResults, page) =>
            numberOfResults + (page?.results?.length ?? 0),
          0
        )

        if (allResults >= lastPage.total) {
          return undefined
        }

        return allResults / INVITES_PAGE_LIMIT
      },
    }
  )
}
