import { Button, styled } from '@mui/material'

export const Form = styled('form')`
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.spacing(6)};

  & > button {
    align-self: center;
  }
`

export const CheckoutButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  padding: ${({ theme }) => theme.spacing(1.75, 2.5)};
  width: 185px;
  transition: all 0.3s;
  user-select: none;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  background-color: ${({ theme }) => theme.palette.primary.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`
