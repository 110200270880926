export enum ConfirmDialogState {
  CANCELED = 'CANCELED',
  SUBMITTED = 'SUBMITTED',
  CLOSED = 'CLOSED',
}

export type ConfirmDialogOptions = {
  title: string
  description: string
  text?: {
    submit?: string
    cancel?: string
  }
}

export type ConfirmDialogProps = {
  open: boolean
  options: ConfirmDialogOptions
  onResult: (result: ConfirmDialogState) => any
}
