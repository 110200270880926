import { TimelineEvent } from 'modules/video-player-controller/types'
import { Event, Highlight } from '@sporttotal-tv/dip-coaching-client'

export const highlightToTimelineEvent = (
  highlight: Highlight,
  event: Event
): TimelineEvent => ({
  startTime: highlight.start_time,
  duration: highlight.duration,
  event,
})
