import { Menu, MenuItem, styled, Typography } from '@mui/material'

export const DisplayMenu = styled(Menu)`
  & .MuiList-root {
    padding: 0;
    min-width: 110px;
  }
`

export const DisplayMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  padding: ${({ theme }) => theme.spacing(0.25, 0.75)};
`

export const DisplayText = styled(Typography)`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.common.white};
`

export const DisplayIconWrapper = styled('div')`
  width: 10px;
  display: flex;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacing(0.5)};
`
