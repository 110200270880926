import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import {
  CreateAdminUserInviteBody,
  AdminUserInvite,
} from '@sporttotal-tv/dip-coaching-client'
import { INVITE_LIST_CACHE_KEY, USER_LIST_CACHE_KEY } from 'modules/user/hooks'

export const INVITE_CREATE_CACHE_KEY = 'invite-create'

export const useInviteCreate = () => {
  const queryClient = useQueryClient()

  return useMutation<AdminUserInvite, Error, CreateAdminUserInviteBody, void>(
    INVITE_CREATE_CACHE_KEY,
    (params: CreateAdminUserInviteBody) => ApiService.invite.create(params),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(INVITE_LIST_CACHE_KEY, {
          exact: false,
        })
      },
      onError: () => {
        queryClient.invalidateQueries(INVITE_LIST_CACHE_KEY, { exact: false })
      },
      onSettled: () => {
        queryClient.invalidateQueries(INVITE_LIST_CACHE_KEY, { exact: false })
        queryClient.invalidateQueries(USER_LIST_CACHE_KEY, { exact: false })
      },
    }
  )
}
