import logoTelecom from 'modules/landing/assets/partners/logo-telekom.svg'
import logoFootball from 'modules/landing/assets/partners/logo-footbal.svg'
import logoLazio from 'modules/landing/assets/partners/logo-lazio.svg'

export const texts = new Array(3).fill('text')
export const partners = [
  {
    logo: logoTelecom,
    url: 'https://geschaeftskunden.telekom.de/digitale-loesungen/spezielle-loesungen/sporttotal',
  },
  {
    logo: logoFootball,
    url: 'https://www.dfb.de/start/',
  },
  {
    logo: logoLazio,
    url: 'https://www.sslazio.it/en',
  },
]
