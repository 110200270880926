import { ApiService } from 'services/Api.service'
import { createMutationHookWithBody } from 'modules/core/api/hooks/utils'
import { CAMERA_CACHE_KEY } from './'

export const [useCameraUpdate] = createMutationHookWithBody(
  ApiService.camera.update,
  (queryClient, params) => ({
    onSuccess: () => {
      queryClient.invalidateQueries(CAMERA_CACHE_KEY, { exact: false })
    },
  })
)
