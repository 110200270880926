import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import {
  Asset,
  MatchList,
  PlaylistList,
} from '@sporttotal-tv/dip-coaching-client'
import { MATCH_LIST_CACHE_KEY } from 'modules/match/hooks'
import { PLAYLIST_CACHE_KEY } from 'modules/playlist/hooks'
import { MutateContext } from 'modules/core/types/mutate-context.type'
import { InfiniteQuery } from 'modules/core/types'
import { removeAssetFromCollection } from 'modules/asset/helpers/removeAssetFromCollection'

export const ASSET_DELETE_CACHE_KEY = 'asset-delete'

export const useAssetDelete = () => {
  const queryClient = useQueryClient()

  return useMutation<Asset, Error, string, MutateContext<InfiniteQuery<any>>>(
    ASSET_DELETE_CACHE_KEY,
    (id: string) => ApiService.asset.delete(id),
    {
      onMutate: async assetId => {
        await queryClient.cancelQueries(PLAYLIST_CACHE_KEY, { exact: false })
        await queryClient.cancelQueries(MATCH_LIST_CACHE_KEY, { exact: false })

        const previousData =
          queryClient.getQueriesData<InfiniteQuery<PlaylistList>>(
            PLAYLIST_CACHE_KEY
          )

        const previousMatchData =
          queryClient.getQueriesData<InfiniteQuery<MatchList>>(
            MATCH_LIST_CACHE_KEY
          )

        if (previousData) {
          queryClient.setQueriesData<InfiniteQuery<PlaylistList> | undefined>(
            PLAYLIST_CACHE_KEY,
            oldData =>
              oldData && {
                pageParams: oldData.pageParams,
                pages: oldData.pages.map(page =>
                  removeAssetFromCollection<PlaylistList>(page, assetId)
                ),
              }
          )
        }

        if (previousMatchData) {
          queryClient.setQueriesData<InfiniteQuery<MatchList> | undefined>(
            MATCH_LIST_CACHE_KEY,
            oldData =>
              oldData && {
                pageParams: oldData.pageParams,
                pages: oldData.pages.map(page =>
                  removeAssetFromCollection<MatchList>(page, assetId)
                ),
              }
          )
        }

        return { previousData: [...previousData, ...previousMatchData] }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData(prevData[0], prevData[1])
          )
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(PLAYLIST_CACHE_KEY, { exact: false })
        queryClient.invalidateQueries(MATCH_LIST_CACHE_KEY, { exact: false })
      },
    }
  )
}
