import { styled } from '@mui/material'

export const Main = styled('div')`
  margin: ${({ theme }) => theme.spacing('5%', '10%')};
  column-gap: ${({ theme }) => theme.spacing(2)};
  height: 100%;
  display: flex;
  flex-direction: column;
  place-items: center;
`
