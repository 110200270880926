import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import {
  TelestrationGroup,
  TelestrationGroupList,
} from '@sporttotal-tv/dip-coaching-client'
import { TELESTRATION_GROUPS_LIST_CACHE_KEY } from './useTelestrationGroupsList'
import { MutateContext } from 'modules/core/types'

export const TELESTRATION_GROUP_DELETE_CACHE_KEY = 'telestration-group-delete'

export const useTelestrationGroupDelete = () => {
  const queryClient = useQueryClient()

  return useMutation<
    TelestrationGroup,
    Error,
    string,
    MutateContext<TelestrationGroupList>
  >(
    TELESTRATION_GROUP_DELETE_CACHE_KEY,
    (id: string) => ApiService.telestrationGroup.delete(id),
    {
      onMutate: async id => {
        await queryClient.cancelQueries(TELESTRATION_GROUPS_LIST_CACHE_KEY, {
          exact: false,
        })

        const previousData = queryClient.getQueriesData<TelestrationGroupList>(
          TELESTRATION_GROUPS_LIST_CACHE_KEY
        )

        if (previousData) {
          queryClient.setQueriesData<TelestrationGroupList | undefined>(
            TELESTRATION_GROUPS_LIST_CACHE_KEY,
            oldData =>
              oldData && {
                total: oldData.total - 1,
                results: oldData.results.filter(
                  telestrationGroup => telestrationGroup.id !== id
                ),
              }
          )
        }

        return { previousData }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData<TelestrationGroupList>(
              prevData[0],
              prevData[1]
            )
          )
        }
      },
      onSettled: _ =>
        queryClient.invalidateQueries(TELESTRATION_GROUPS_LIST_CACHE_KEY, {
          exact: false,
        }),
    }
  )
}
