import { Paper, styled } from '@mui/material'
import { isPlainObject } from '@mui/utils'

export const LayoutPaletteContainer = styled(Paper)`
  position: fixed;
  // background: red;
  display: flex;
  box-shadow: 0 3px 6px ${({ theme }) => theme.palette.common.black};
  flex-direction: column;
  align-content: center;
  // TODO should be in LAYOUT_PALETTE_WIDTH at the top of the file
  width: 60px;
  // TODO: fix this to get data from variable ANALYSER_HEADER_HEIGHT
  padding-top: 50px;
  right: 0;
  bottom: 0;
  ${({ theme }) => {
    const topCss: any = {}

    for (const [prop, height] of Object.entries(theme.mixins.toolbar)) {
      if (typeof height === 'number') topCss.top = height
      if (isPlainObject(height)) {
        topCss[prop] = {}
        topCss[prop].top = height.minHeight
      }
    }

    return topCss
  }}
`

export const PaletteIcon = styled('div', {
  shouldForwardProp: prop => prop !== 'color' && prop !== 'isDraggingAllowed',
})<{ color: string; isDraggingAllowed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  text-align: center;
  grid-gap: 10px;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(1.75, 0)};
  cursor: ${({ isDraggingAllowed }) =>
    isDraggingAllowed ? 'grabbing' : 'pointer'};

  & * {
    transition: all 0.3s;
    fill: ${({ theme, color }) => color ?? theme.palette.primary.light};
  }
`
