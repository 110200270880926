import { useState, useEffect, FormEvent, useCallback, FC, useMemo } from 'react'
import { useStripeContext } from 'modules/stripe/hooks'
import { useProductList } from 'modules/product/hooks'
import {
  useSubscription,
  useSubscriptionCreate,
  useSubscriptionDelete,
} from 'modules/subscription/hooks'
import { Stripe, StripeElements } from '@stripe/stripe-js/types/stripe-js'
import { StripeContextProvider } from 'modules/stripe/context'
import { StripeFormProvider, PaymentForm } from 'modules/stripe/components'
import { LoaderContainer, ModalDialog } from 'modules/core/components'
import { SubscriptionPageLayout } from 'modules/core/layouts'
import {
  TierCard,
  BillingPeriodSelector,
} from 'modules/subscription/components'
import { BILLING_PERIODS_ARRAY, TIERS } from 'modules/subscription/constants'
import { BilligPeriod } from 'modules/subscription/types'

import {
  BillingPeriodContainer,
  TierCardContainer,
} from './Subscription.styled'
import { calculateDiscount } from 'modules/subscription/utils'

const SubscriptionContent = () => {
  const { dispatch, state } = useStripeContext()

  const [billingPeriod, setBillingPeriod] = useState<BilligPeriod>('MONTHLY')
  const { data: productListData } = useProductList()

  const { data: subscription } = useSubscription()
  const { mutate: createSubscription, data: subscriptionCreated } =
    useSubscriptionCreate()
  const { mutate: deleteSubscription } = useSubscriptionDelete()

  const yearlyDiscount = useMemo(() => {
    if (!productListData) return
    const [monthly] = productListData.MONTHLY
    const [yearly] = productListData.YEARLY

    return calculateDiscount(
      yearly.price.unit_amount,
      monthly.price.unit_amount
    )
  }, [productListData])

  useEffect(() => {
    const invoice = subscription ? subscription : subscriptionCreated
    const clientSecret = invoice?.invoice.payment_intent.client_secret
    if (!clientSecret) return

    dispatch({ clientSecret: clientSecret })
  }, [dispatch, subscriptionCreated, subscription])

  const handleSelectSubscription = (priceId: string) =>
    createSubscription({ priceId })

  const handleOnPaymentSubmit = async (
    event: FormEvent<HTMLFormElement>,
    stripe: Stripe,
    elements: StripeElements
  ) => {
    event.preventDefault()

    const clientSecret =
      subscriptionCreated?.invoice.payment_intent.client_secret
    if (!clientSecret) return

    elements.submit()

    await stripe.confirmPayment({
      clientSecret: clientSecret,
      elements,
      confirmParams: {
        return_url: `${window.location}/payment-status`,
      },
    })
  }

  const handleOnCloseSubscriptionDialog = useCallback(() => {
    dispatch({ clientSecret: undefined })
    deleteSubscription()
  }, [dispatch, deleteSubscription])

  const hasActiveSubscription = useMemo(
    () => subscription?.status === 'SUCCESSFUL',
    [subscription]
  )

  return (
    <LoaderContainer isLoading={productListData ? false : true}>
      <ModalDialog
        title='Payment'
        icon='info'
        onClose={handleOnCloseSubscriptionDialog}
        open={
          typeof state?.clientSecret === 'string' &&
          subscription?.status !== 'SUCCESSFUL'
        }
      >
        <StripeFormProvider>
          <PaymentForm onSubmit={handleOnPaymentSubmit} />
        </StripeFormProvider>
      </ModalDialog>
      <BillingPeriodContainer>
        {BILLING_PERIODS_ARRAY.map(period => (
          <BillingPeriodSelector
            key={period}
            active={billingPeriod === period}
            period={period}
            discount={yearlyDiscount}
            onClick={() => setBillingPeriod(period)}
          />
        ))}
      </BillingPeriodContainer>
      <TierCardContainer>
        {productListData &&
          productListData[billingPeriod].map(
            ({ id, name, price, metadata }: any) => {
              const productTier = metadata.TIER in TIERS
              if (!productTier) return null

              const tier = TIERS[metadata.TIER as keyof typeof TIERS]

              return (
                <TierCard
                  key={id}
                  name={name}
                  config={tier}
                  billingPeriod={billingPeriod}
                  price={price.unit_amount / 100}
                  onClick={() => handleSelectSubscription(price.id)}
                  disabled={hasActiveSubscription}
                />
              )
            }
          )}
      </TierCardContainer>
    </LoaderContainer>
  )
}

export const Subscription: FC = () => (
  <SubscriptionPageLayout>
    <StripeContextProvider>
      <SubscriptionContent />
    </StripeContextProvider>
  </SubscriptionPageLayout>
)
