import { useTranslation } from 'react-i18next'
import { Text } from 'modules/landing/components/shared/Text/Text'
import {
  FlexColumn,
  FlexRow,
} from 'modules/landing/components/shared/Flex/styled'
import { Card } from 'modules/landing/components/shared/Card/Card'
import { useAdaptive } from 'modules/landing/hooks/useAdaptive'
import { BREAKPOINTS } from 'modules/landing/constants'
import { StyledCardImage } from './styled'
import { cards } from './utils'
import { useTheme } from '@mui/material'

import background from 'modules/landing/assets/backgrounds/cards-bg.png'

export const CardSection = () => {
  const theme = useTheme()

  const { t } = useTranslation(['pages'])

  const isMobile = useAdaptive(0, BREAKPOINTS.mobile)
  const isTablet = useAdaptive(BREAKPOINTS.mobile, BREAKPOINTS.tablet)

  return (
    <FlexRow
      sx={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '39px',
        height: isTablet || isMobile ? 'auto' : '243px',
        background: `url(${background})`,
        backgroundSize: 'cover',
        flexWrap: isTablet || isMobile ? 'wrap' : 'auto',
      }}
    >
      {cards.map((cardImage, i) => (
        <Card
          key={`card-${i}`}
          sx={{
            width: '360px',
            height: 'calc(340px + 22px)',
            background: theme.palette.primary.dark,
            transform: 'translateY(10px)',
          }}
          image={<StyledCardImage src={cardImage} />}
          body={
            <FlexColumn sx={{ padding: '11px 0 0 19px' }}>
              <Text
                sx={{
                  marginBottom: '8px',
                  letterSpacing: '0px',
                  font: "normal normal bold 20px/30px 'Exo 2'",
                  color: theme.palette.secondary.main,
                }}
              >
                {t(`landing.sections.cards.${i}.title`)}
              </Text>
              <Text
                sx={{
                  font: "normal normal normal 14px/24px 'Exo 2'",
                  letterSpacing: '0px',
                  color: theme.palette.primary.contrastText,
                  maxWidth: '314px',
                }}
              >
                {t(`landing.sections.cards.${i}.text`)}
              </Text>
            </FlexColumn>
          }
        />
      ))}
    </FlexRow>
  )
}
