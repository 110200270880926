import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
// components
import { InfoTable } from 'modules/team-player/components'
// hooks
import {
  useTeamPlayerInjuryList,
  useTeamPlayerInjuryDelete,
} from 'modules/team-player/hooks'
// styled
import { Badge } from 'modules/core/styled'
import { isInjury } from 'modules/team-player/utils'
// constants
import { COLUMN_TEMPLATES, PlayersView } from 'modules/team-player/constants'
// types
import type {
  InfoTableElement,
  InfoTableHeaderElement,
  InfoItem,
} from 'modules/team-player/components'

import { GenericViewProps as InjuryViewProps } from 'modules/team-player/types'
import { InjuryModal } from './InjuryModal'

export const InjuriesView: FC<InjuryViewProps> = () => {
  // hooks
  const { t } = useTranslation('components', { keyPrefix: 'teamPlayers' })

  const tableRows: InfoTableElement[] = useMemo(
    () => [
      {
        type: 'checkbox',
        render: ({ item, Component }) => <Component item={item} />,
      },
      {
        type: 'stickyLeftText',
        render: ({ item, Component }) => (
          <Component>
            {isInjury(item) &&
              `${item.player?.first_name} ${item.player?.last_name}`}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component>{isInjury(item) && item.current_injury}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component>
            {format(
              new Date(
                +('date_of_injury' in item && item.date_of_injury) * 1000
              ),
              'dd/MM/yy'
            )}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component>
            {isInjury(item) &&
              `${item.rehab_period_min} - ${item.rehab_period_max} ${t(
                `table.injury.rehabPeriodValues.${item.rehab_period}`
              )}`}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component justify='center'>
            <Badge variant='error'>
              {isInjury(item) &&
                t(`table.injury.injuryStatusValues.${item.injury_status}`)}
            </Badge>
          </Component>
        ),
      },
      {
        type: 'menu',
        render: ({ item, Component }) => <Component item={item} />,
      },
    ],
    [t]
  )

  const tableHeaderRows: InfoTableHeaderElement[] = useMemo(
    () => [
      {
        type: 'actions',
        render: ({ Component }) => (
          <Component
            translation={{
              main: t('table.injury.selected'),
              secondary: t('table.injury.action'),
            }}
          />
        ),
      },
      {
        type: 'stickyLeftText',
        render: ({ Component }) => (
          <Component>{t('table.injury.name')}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>
            <span className='full'>{t('table.injury.injury')}</span>
            <span className='short'>{t('table.injury.injuryShort')}</span>
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>
            <span className='full'>{t('table.injury.dateInjury')}</span>
            <span className='short'>{t('table.injury.dateInjuryShort')}</span>
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>
            <span className='full'>{t('table.injury.rehabPeriod')}</span>
            <span className='short'>{t('table.injury.rehabPeriodShort')}</span>
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component justify='center'>{t('table.injury.status')}</Component>
        ),
      },

      {
        type: 'add',
        render: ({ Component }) => (
          <Component
            justify='flex-end'
            translation={{ main: t('table.injury.add') }}
          />
        ),
      },
    ],
    [t]
  )

  const transformName = useCallback((item: InfoItem) => {
    return item && 'player' in item
      ? `${item.player?.first_name} ${item.player?.last_name}`
      : ''
  }, [])

  return (
    <InfoTable
      tableRows={tableRows}
      tableHeaderRows={tableHeaderRows}
      resource='injury'
      transformName={transformName}
      Modal={InjuryModal}
      useList={useTeamPlayerInjuryList}
      useDelete={useTeamPlayerInjuryDelete}
      useListArgs={{
        expand: ['player'],
      }}
      columnsTemplate={COLUMN_TEMPLATES[PlayersView.INJURIES]}
    />
  )
}
