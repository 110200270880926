import { css, styled, Typography } from '@mui/material'
import { SearchInput } from 'modules/core/components/SearchInput'
import { Icon } from 'modules/core/components/Icon'
import { TextInputErrorMessage } from 'modules/core/components/TextInput'

export const PlayerSelectorModal = styled('div', {
  shouldForwardProp: prop => prop !== 'hidden' && prop !== 'center',
})<{ hidden?: boolean; center?: boolean }>`
  width: 232px;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  position: absolute;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 20%);
  z-index: 1;
  padding: ${({ theme }) => theme.spacing(1.5, 2)};
  transition: padding 0.3s, opacity 0.3s, transform 0s 0s;
  overflow: auto;
  right: 0;
  top: ${({ theme }) => theme.spacing(2.5)};

  ${({ center }) =>
    center &&
    css`
      left: 50%;
      transform: translateX(-50%);
    `}

  ${({ hidden }) =>
    hidden &&
    css`
      transition: padding 0.3s, opacity 0.3s, transform 0s 0.3s;
      opacity: 0;
      transform: translateY(-100000px);
      overflow: hidden;
    `}
`

export const Search = styled(SearchInput)`
  min-width: 150px;
  background-color: ${({ theme }) => theme.palette.primary.main};
`

export const Divider = styled('div')`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.divider};
  margin: ${({ theme }) => theme.spacing(1.75, 0)};
`

export const PlayersList = styled('div')`
  max-height: 150px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(2)};
`

export const PlayerItem = styled(Typography)`
  padding: ${({ theme }) => theme.spacing(0.5)};
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const PlayerSelectorIntractable = styled('div')`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: ${({ theme }) => theme.spacing(0.75)};
`

export const PlayerName = styled(Typography)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.light};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
`

export const PlayerSelectIcon = styled(Icon, {
  shouldForwardProp: prop => prop !== 'chevronUp',
})<{ chevronUp: boolean }>`
  transition: all 0.3s;
  transform: ${({ chevronUp }) => (chevronUp ? 'rotate(0)' : 'rotate(180deg)')};
  width: 10px;
  height: 10px;
`

export const PlayerSelectorWrapper = styled('div')`
  position: relative;
`

export const PlayerErrorMessage = styled(TextInputErrorMessage)`
  width: 100%;
  text-align: right;
  margin-left: 0;
`
