import { PlayerContract } from '@sporttotal-tv/dip-coaching-client'
import { teamPlayerMockup } from './teamPlayer.mockup'

export const teamPlayerContractMockup: PlayerContract = {
  id: '1',
  agreement: 1,
  agreement_type: 'start_player',
  salary: 1000,
  salary_currency_id: 'salary_currency_id',
  salary_currency: {
    id: 'salary_currency_id',
    name: {
      en: 'Dollar',
      de: 'Dollar',
    },
    currency_code: 'USD',
    currency_symbol: '$',
    created_at: new Date().getTime() / 1000,
    updated_at: new Date().getTime() / 1000,
  },
  expires_on: new Date().getTime() / 1000,
  transfer_value_min: 100,
  transfer_value_max: 10000,
  transfer_value_currency_id: 'transfer_value_currency_id',
  transfer_value_currency: {
    id: 'transfer_value_currency_id',
    name: {
      en: 'Dollar',
      de: 'Dollar',
    },
    currency_code: 'USD',
    currency_symbol: '$',
    created_at: new Date().getTime() / 1000,
    updated_at: new Date().getTime() / 1000,
  },
  is_released: false,
  player_id: 'player_id',
  player: teamPlayerMockup,
  created_at: new Date().getTime() / 1000,
  updated_at: new Date().getTime() / 1000,
}
