import { ApiService } from 'services/Api.service'
import { createMutationHook } from 'modules/core/api/hooks/utils'
import { USER_SPORTS_RETRIEVE_CACHE_KEY } from 'modules/user/hooks'
import { ADMIN_USER_TEAM_LIST_CACHE_KEY } from './useList'

export const [useAdminUserTeamCreate] = createMutationHook(
  ApiService.admin.user.team.create,
  queryClient => ({
    onSuccess: () => {
      queryClient.invalidateQueries(ADMIN_USER_TEAM_LIST_CACHE_KEY)
      queryClient.invalidateQueries(USER_SPORTS_RETRIEVE_CACHE_KEY)
    },
  })
)
