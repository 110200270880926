import { styled } from '@mui/material'
import {
  FlexColumn,
  FlexRow,
} from 'modules/landing/components/shared/Flex/styled'

export const StyledDeviceImage = styled('img')`
  width: 859px;
  height: 484px;
  margin-left: -30px;
  margin-top: -5%;

  @media (max-width: 1580px) {
    width: calc(859px / 1.2);
    height: calc(484px / 1.2);
    align-self: center;
    margin-left: 0px;
    margin-top: 10px;
  }

  @media (max-width: 1280px) {
    width: calc(859px / 1.5);
    height: calc(484px / 1.5);
    align-self: center;
    margin-left: 0px;
    margin-top: 10px;
  }

  @media (max-width: 1050px) {
    width: calc(859px / 1.8);
    height: calc(484px / 1.8);
    align-self: center;
    margin-left: 0px;
    margin-top: 10px;
  }

  @media (max-width: 500px) {
    width: calc(859px / 2.3);
    height: calc(484px / 2.3);
    align-self: center;
    margin: 10px 0;
  }
`

export const MainWrapper = styled(FlexColumn)`
  width: 100vw;
  height: 724px;
  padding: 58px 0 0 140px;
  background: ${({ theme }) => theme.palette.darkest};
  align-items: center;

  @media (max-width: 1280px) {
    padding: 50px 50px;
  }

  @media (max-width: 1000px) {
    height: auto;
  }

  @media (max-width: 800px) {
    padding: 50px 0px;
  }
`

export const Wrapper = styled(FlexRow)`
  justify-content: center;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: start;
  }
`
