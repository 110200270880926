import { useEffect } from 'react'
import { activateTextTruncateScroll } from 'text-truncate-scroll'
import { TextHoverScrollProps } from './TextHoverScroll.interface'

export const TextHoverScroll = ({
  element: Element,
  children,
}: TextHoverScrollProps) => {
  useEffect(activateTextTruncateScroll, [])

  return <Element className='text-truncate-scroll'>{children}</Element>
}
