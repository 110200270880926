import { FC } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { CheckoutButton, Form } from './PaymentForm.styled'
import { PaymentFormProps } from './PaymentForm.interface'

export const PaymentForm: FC<PaymentFormProps> = ({ onSubmit }) => {
  const stripe = useStripe()
  const elements = useElements()

  if (!stripe || !elements) return <></>

  return (
    <Form onSubmit={e => onSubmit(e, stripe, elements)}>
      <PaymentElement />
      <CheckoutButton type='submit'>Checkout</CheckoutButton>
    </Form>
  )
}
