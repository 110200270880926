import {
  PlayerContractList,
  PlayerContract,
  UpdatePlayerContractBody,
} from '@sporttotal-tv/dip-coaching-client'
import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import { TEAM_PLAYER_CONTRACT_LIST_CACHE_KEY } from 'modules/team-player/hooks'
import { MutateContext } from 'modules/core/types/mutate-context.type'
import { InfiniteQuery } from 'modules/core/types'

export const TEAM_PLAYER_CONTRACT_UPDATE_CACHE_KEY =
  'team-player-contract-update'

export type ContractUpdateParams = {
  id: string
  params: UpdatePlayerContractBody
}

export const useTeamPlayerContractUpdate = () => {
  const queryClient = useQueryClient()

  return useMutation<
    PlayerContract,
    Error,
    ContractUpdateParams,
    MutateContext<any>
  >(
    TEAM_PLAYER_CONTRACT_UPDATE_CACHE_KEY,
    ({ id, params }: ContractUpdateParams) =>
      ApiService.teamPlayerContract.update(id, params),
    {
      onMutate: async data => {
        await queryClient.cancelQueries(TEAM_PLAYER_CONTRACT_LIST_CACHE_KEY, {
          exact: false,
        })

        const previousData = queryClient.getQueriesData<
          InfiniteQuery<PlayerContractList>
        >(TEAM_PLAYER_CONTRACT_LIST_CACHE_KEY)

        if (previousData) {
          queryClient.setQueriesData<
            InfiniteQuery<PlayerContractList> | undefined
          >(
            TEAM_PLAYER_CONTRACT_LIST_CACHE_KEY,
            oldData =>
              oldData && {
                pageParams: oldData.pageParams,
                pages: oldData.pages.map(
                  page =>
                    page && {
                      total: page.total,
                      results: page.results.map(item =>
                        item.id === data.id ? { ...item, ...data.params } : item
                      ),
                    }
                ),
              }
          )
        }

        return { previousData }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData(prevData[0], prevData[1])
          )
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(TEAM_PLAYER_CONTRACT_LIST_CACHE_KEY, {
          exact: false,
        })
      },
    }
  )
}
