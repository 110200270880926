// Hooks
import { useContext } from 'react'
import { useReduxSelector } from 'modules/core/hooks'
import { useMatchData, useMatchRetrieve } from 'modules/match/hooks'
// Context
import { VideoPlayerContext } from 'modules/video-player/context'
// Redux
import { selectMatchId } from 'modules/video-player/redux'

export const useMatch = () => {
  const { playerId } = useContext(VideoPlayerContext)
  const matchId = useReduxSelector(state =>
    selectMatchId(state, playerId ?? '')
  )

  const { data: matchData } = useMatchData(matchId)

  // Expand what we need to reuse same react-query cache
  const { data: match } = useMatchRetrieve({
    id: matchId ?? '',
    expand: [
      'sport',
      'highlights',
      'match_assets',
      'match_assets.media_locator',
    ],
  })

  return { match, matchData }
}
