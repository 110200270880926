import { Match } from '@sporttotal-tv/dip-coaching-client'
import { fetchAvailableMatchLocators } from 'modules/match/utils'

export const hasAnyMatchLocators = (match?: Match) => {
  if (!match) return false

  const { pano, broadcast, tactical, broadcast_live } =
    fetchAvailableMatchLocators(match)

  return pano || broadcast || tactical || broadcast_live
}
