import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import { UserCacheData } from 'modules/user/types'
import { MutateContext } from 'modules/core/types/mutate-context.type'
import { CreateUserCacheBody } from '@sporttotal-tv/dip-coaching-client'
import { USER_CACHE_RETRIEVE_CACHE_KEY } from 'modules/cache/hooks'

export const USER_CACHE_CREATE_CACHE_KEY = 'user-cache-create'

export const useUserCacheCreate = () => {
  const queryClient = useQueryClient()

  return useMutation<
    UserCacheData,
    Error,
    UserCacheData,
    MutateContext<UserCacheData>
  >(
    USER_CACHE_CREATE_CACHE_KEY,
    (params: CreateUserCacheBody) => ApiService.cache.create(params),
    {
      onMutate: async data => {
        const keys = Object.keys(data)
        await queryClient.cancelQueries([
          USER_CACHE_RETRIEVE_CACHE_KEY,
          { keys },
        ])
        for (const key of keys) {
          await queryClient.cancelQueries([
            USER_CACHE_RETRIEVE_CACHE_KEY,
            { keys: [key] },
          ])
        }

        const previousData = queryClient.getQueriesData<UserCacheData>(
          USER_CACHE_RETRIEVE_CACHE_KEY
        )

        if (previousData) {
          queryClient.setQueriesData<UserCacheData | undefined>(
            USER_CACHE_RETRIEVE_CACHE_KEY,
            oldData => oldData && { ...oldData, ...data }
          )
        }

        return { previousData }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData<UserCacheData>(prevData[0], prevData[1])
          )
        }
      },
      onSettled: (_, __, variables) => {
        const keys = Object.keys(variables)
        queryClient.invalidateQueries([USER_CACHE_RETRIEVE_CACHE_KEY, { keys }])
        for (const key of keys) {
          queryClient.invalidateQueries([
            USER_CACHE_RETRIEVE_CACHE_KEY,
            { keys: [key] },
          ])
        }
      },
    }
  )
}
