import {
  Autocomplete,
  Button,
  css,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Popper,
  styled,
  TextField,
  Typography,
} from '@mui/material'

export const Main = styled('div')`
  padding: ${({ theme }) => theme.spacing(4)} 10%;
`

export const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const Title = styled(Typography)`
  font-size: 36px;
  line-height: 36px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.common.white};
`

export const FormContainer = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(5)};
`

export const SaveButton = styled(Button)`
  width: 50%;
  font-size: 20px;
  box-shadow: none;
  padding: ${({ theme }) => theme.spacing(0.25, 0)};
  border-radius: 0;
  text-transform: capitalize;
`

export const Label = styled(FormLabel)`
  font-size: 24px;
  height: 40px;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  align-items: flex-end;
`

export const DescriptionComment = styled('span')`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.common.white};
  margin-left: ${({ theme }) => theme.spacing(1)};
`

export const InputText = styled(Input, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  padding: 0;
  border: 1px solid transparent;

  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.palette.error.main};
    `}

  & .MuiInput-input {
    appearance: none;
    -moz-appearance: textfield;
    padding: 10px 16px;
    font-size: 14px;
  }

  & .MuiInput-input::-webkit-outer-spin-button,
  & .MuiInput-input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
`

export const FullWidthFormControl = styled(FormControl)`
  width: 100%;
`

export const StaticWrapper = styled('div')`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(2)};
`

export const StaticText = styled(Typography, {
  shouldForwardProp: prop => prop !== 'capitalize',
})<{ capitalize?: boolean }>`
  font-size: 20px;
  color: ${({ theme }) => theme.palette.primary.light};
  text-transform: ${({ capitalize = false }) => capitalize && 'capitalize'};
`

export const StaticButton = styled(Button)`
  font-size: 12px;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  text-transform: none;

  & path {
    fill: ${({ theme }) => theme.palette.common.white};
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.darkest};
  }
`

export const InputWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Row = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(2)};
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(2)};

  &:first-of-type {
    margin-top: ${({ theme }) => theme.spacing(0)};
  }
`

export const EditContainer = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(8)};
`

export const SaveContainer = styled('div')`
  display: flex;
  justify-content: center;
`

export const ErrorMessage = styled(FormHelperText)`
  height: 16px;
  margin: ${({ theme }) => theme.spacing(0.25, 1, 0)};
  color: ${({ theme }) => theme.palette.error.main};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const StyledAutocomplete = styled(Autocomplete, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>`
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiAutocomplete-endAdornment {
    display: flex;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
  }

  & .MuiOutlinedInput-root {
    padding: 0;
  }

  & .MuiOutlinedInput-root {
    border: 1px solid transparent;
  }

  ${({ error, theme }) =>
    error &&
    css`
      & .MuiOutlinedInput-root {
        border: 1px solid ${theme.palette.error.main};
      }
    `}
`

export const AutocompleteOption = styled('li')`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(1)};
`

export const InputTextField = styled(TextField, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>`
  background-color: ${({ theme }) => theme.palette.primary.dark};

  & .MuiInputBase-input {
    padding: 10px 16px !important;
    font-size: 14px;
  }

  & .MuiInputBase-formControl {
    border-radius: 0;
    padding: 0;
  }

  & .MuiInputAdornment-root {
    width: 30px;
  }

  & .MuiOutlinedInput-notchedOutline {
    outline: none;
    border: none;
    border-radius: 0;
  }

  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.palette.error.main};
    `}
`

export const StyledPopper = styled(Popper)`
  & .MuiPaper-root {
    background-color: ${({ theme }) => theme.palette.primary.dark};
    box-shadow: ${({ theme }) => theme.shadow.main};
  }

  & .MuiAutocomplete-listbox {
    background-color: ${({ theme }) => theme.palette.primary.dark};
  }

  & .MuiAutocomplete-option {
    background-color: ${({ theme }) => theme.palette.primary.dark};
    font-size: 14px;
  }
`
