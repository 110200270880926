import {
  call,
  takeLatest,
  delay,
  select,
  takeEvery,
  put,
} from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { ApiService } from 'services/Api.service'
import {
  setDefaultLayoutCacheComponentDataRequest,
  setDefaultLayoutCacheComponentDataSuccess,
  updateLayoutCache,
} from './layoutCache.slice'
import { selectLayoutCacheById } from './layoutCache.selectors'

function* changeLayoutCache(
  action: PayloadAction<{
    id: string
    [key: string]: any
  }>
) {
  // debounce by 500ms
  yield delay(500)

  try {
    const { id, ...rest } = action.payload
    const layoutCache: object = yield select(state =>
      selectLayoutCacheById(state, id)
    )

    yield call(ApiService.cache.create, {
      [id]: { ...layoutCache, ...rest },
    })
  } catch {
    yield call(console.error, 'error')
  }
}

function* setDefaultDataForComponent(
  action: PayloadAction<{
    id: string
    [key: string]: any
  }>
) {
  try {
    const { id, ...rest } = action.payload
    const layoutCache: {
      id: string
      [key: string]: any
    } = yield select(state => selectLayoutCacheById(state, id))

    if (!layoutCache?.createdAt) {
      yield call(ApiService.cache.create, {
        [id]: { ...layoutCache, ...rest },
      })
      yield put(setDefaultLayoutCacheComponentDataSuccess({ id, ...rest }))
      return
    }
  } catch {
    yield call(console.error, 'error')
  }
}

export function* layoutCacheSaga() {
  yield takeLatest(updateLayoutCache, changeLayoutCache)
  yield takeEvery(
    setDefaultLayoutCacheComponentDataRequest,
    setDefaultDataForComponent
  )
}
