import { User } from '@sporttotal-tv/dip-coaching-client'
import { UserFeatureTier } from 'modules/user/types'

const tierRanking: Record<UserFeatureTier, number> = {
  basic: 1,
  advanced: 2,
  professional: 3,
}

export const doesUserHaveTierAccess = (
  user: User | undefined,
  tier: UserFeatureTier
) => {
  if (!user) return false

  const tierRank = tierRanking[tier] ?? 0
  const userTierRank = tierRanking[user.feature_tier] ?? 0

  return userTierRank >= tierRank
}
