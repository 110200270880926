import { FC, useCallback, useContext, useEffect, useMemo } from 'react'
import { Asset } from '@sporttotal-tv/dip-coaching-client'
// components
import { ClipStandalonePreview } from 'modules/asset/components'
// context
import { PlaylistPageContext } from 'modules/playlist/context'

import { Root } from './ClipList.styled'
import { IClipList } from './ClipList.interface'

export const ClipList: FC<IClipList> = ({ clips }) => {
  const { activeClip, setActiveClip, clipEnded, setPreviousClip, setNextClip } =
    useContext(PlaylistPageContext)

  const handlePlay = useCallback(
    (clip: Asset) => () => {
      setActiveClip(clip)
    },
    [setActiveClip]
  )

  const nextClip = useMemo(() => {
    const index = clips.findIndex(c => c.id === activeClip?.id)
    if (index === -1 || index === clips.length - 1) {
      return null
    }
    return clips[index + 1]
  }, [clips, activeClip])

  const previousClip = useMemo(() => {
    const index = clips.findIndex(c => c.id === activeClip?.id)
    if (index === -1 || index === 0) {
      return null
    }
    return clips[index - 1]
  }, [clips, activeClip])

  const clipUrl = activeClip?.media_locator?.url

  useEffect(() => {
    if (clips.length > 0 && activeClip === null) {
      setActiveClip(clips[0])
    }
  }, [clips, activeClip, setActiveClip])

  useEffect(() => {
    if (nextClip && clipEnded) {
      setActiveClip(nextClip)
    }
  }, [clipEnded, nextClip, setActiveClip])

  useEffect(() => {
    setNextClip(nextClip)
  }, [nextClip, setNextClip])

  useEffect(() => {
    setPreviousClip(previousClip)
  }, [previousClip, setPreviousClip])

  return (
    <Root>
      {clips.map(clip => (
        <ClipStandalonePreview
          handlePlay={handlePlay(clip)}
          active={clipUrl === clip.media_locator?.url}
          key={clip.id}
          clip={clip}
        />
      ))}
    </Root>
  )
}
