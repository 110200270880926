import { api } from 'modules/core/api'
import { transformApiResults } from 'modules/core/helpers'
import {
  CreateSubscriptionBody,
  Subscription,
} from '@sporttotal-tv/dip-coaching-client'

export const SubscriptionApiService = {
  retrieve: transformApiResults<void, Subscription>(api.subscription.retrive),
  create: transformApiResults<CreateSubscriptionBody, Subscription>(
    api.subscription.create
  ),
  delete: transformApiResults<void, Subscription>(api.subscription.delete),
}
