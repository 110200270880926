import { css, styled } from '@mui/material'

export const OverComponentSkeleton = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'hidden' && prop !== 'backgroundColor' && prop !== 'full',
})<{ hidden?: boolean; backgroundColor?: string; full?: boolean }>`
  width: 100%;
  height: 100%;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ?? theme.palette.primary.main};
  opacity: ${({ full }) => (full ? 1 : 0.7)};
  position: absolute;
  cursor: default;
  transition: opacity 0.3s 0s, transform 0.1s 0s;
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

  ${({ hidden }) =>
    hidden &&
    css`
      transition: opacity 0.3s 0s, transform 0s 0.3s;
      opacity: 0;
      transform: translateY(-100000px);
      overflow: hidden;
    `}
`
