import { useMemo } from 'react'
import { Layouts } from 'react-grid-layout'
import { EMPTY_LAYOUT, LayoutIndex } from 'modules/layout/constants'
import { useUserStorage } from 'modules/user/hooks'
import { convertUserTabDataToCurrentConfig } from 'modules/layout/utils/converters'
import { UserLayoutTypesVariant, UserTabsDataVariant } from 'modules/user/types'
import { useUserCache } from 'modules/cache/hooks'

interface UseTabLayoutsStoreParams {
  tab: string
  tabsDataKey: UserTabsDataVariant
  layoutTypesKey: UserLayoutTypesVariant
  defaultTabLayoutData?: Record<string, Layouts>
  defaultLayoutTypes?: Record<string, LayoutIndex>
}

export const useTabLayoutsStore = ({
  tab,
  tabsDataKey,
  layoutTypesKey,
  defaultTabLayoutData = {},
  defaultLayoutTypes = {},
}: UseTabLayoutsStoreParams) => {
  const userData = useUserStorage({ keys: [tabsDataKey] })
  const userLayoutTypes = useUserStorage({ keys: [layoutTypesKey] })

  // We need this variable, so we will always have the same keys

  const initialUserLayoutTypes = useMemo(() => {
    return userLayoutTypes.data?.[layoutTypesKey] ?? {}
  }, [layoutTypesKey, userLayoutTypes.isLoading])

  const userCache = useUserCache(
    {
      keys: [...Object.keys(initialUserLayoutTypes)],
    },
    {
      enabled:
        !userLayoutTypes.isLoading &&
        Object.keys(initialUserLayoutTypes).length > 0,
    }
  )

  const layoutsWithBreakpoints = useMemo(() => {
    const tabData: Layouts | undefined = userData.data?.[tabsDataKey]?.[tab]
    const layoutTypesData = userLayoutTypes?.data?.[layoutTypesKey]

    if (tabData && layoutTypesData) {
      return convertUserTabDataToCurrentConfig(tabData, layoutTypesData)
    }

    if (tabData) {
      return tabData
    }

    return defaultTabLayoutData[tab] ?? EMPTY_LAYOUT
  }, [
    tab,
    userData.data,
    userLayoutTypes.data,
    tabsDataKey,
    layoutTypesKey,
    defaultTabLayoutData,
  ])

  const layoutCache = useMemo(() => {
    return userCache.data ?? {}
  }, [userCache.data])

  const layoutTypes = useMemo(() => {
    const layoutTypesInfo = userLayoutTypes.data?.[layoutTypesKey]
    if (!layoutTypesInfo) {
      return defaultLayoutTypes
    }

    const layoutTypesData = Object.entries(layoutTypesInfo)
      .sort()
      .reduce<Record<string, LayoutIndex>>((acc, [key, value]) => {
        return { ...acc, [key]: value }
      }, {})

    if (layoutTypesData) {
      return layoutTypesData
    }

    return defaultLayoutTypes
  }, [userLayoutTypes.data, layoutTypesKey, defaultLayoutTypes])

  return {
    layoutsWithBreakpoints,
    isLoading:
      userData.isLoading || userLayoutTypes.isLoading || userCache.isLoading,
    layoutTypes,
    layoutCache,
  }
}
