import { useState, FC, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
// Components
import { IconButton } from 'modules/core/components'

// Types
import {
  OrganizationUser,
  OrganizationUserListProps,
} from './OrganizationUserList.interface'

// Styled
import {
  HeaderElement,
  HeaderElementSmall,
  Row,
  Table,
  BodyElementSmall,
  BodyElement,
} from 'modules/core/styled'

import {
  RoleElement,
  TableWrapper,
  TierElement,
  TierBadge,
} from './OrganizationUserList.styled'
import { UserContextMenu } from './UserContextMenu'

export const OrganizationUserList: FC<OrganizationUserListProps> = ({
  organizationId,
  users,
  handleOnUserClick,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'admin' })

  const [selectedUser, setSelectedUser] = useState<OrganizationUser | null>(
    null
  )
  const [contextAnchor, setContextAnchor] = useState<HTMLElement | null>(null)

  const isDisplayContextMenuOpen = Boolean(contextAnchor)

  const handleMenuOpen =
    (user: OrganizationUser) => (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      setSelectedUser(user)
      setContextAnchor(event.currentTarget)
    }

  const handleMenuClose = () => setContextAnchor(null)

  return (
    <>
      <TableWrapper>
        <Table>
          <Row>
            <HeaderElement>{t('table.row.name')}</HeaderElement>
            <HeaderElement>{t('table.row.surname')}</HeaderElement>
            <HeaderElement>{t('table.row.email')}</HeaderElement>
            <HeaderElement>{t('table.row.role')}</HeaderElement>
            <HeaderElement>{t('table.row.tier')}</HeaderElement>
            <HeaderElementSmall />
          </Row>
          {users.map(user => (
            <Row
              interactive
              key={user.id}
              onClick={handleOnUserClick && handleOnUserClick(user.id)}
            >
              <BodyElement>{user.firstname}</BodyElement>
              <BodyElement>{user.lastname}</BodyElement>
              <BodyElement>{user.email}</BodyElement>
              <RoleElement>{user.role.toLowerCase()}</RoleElement>
              <TierElement>
                <TierBadge>{user.feature_tier.toLowerCase()}</TierBadge>
              </TierElement>
              <BodyElementSmall>
                <IconButton
                  name='vertical-menu'
                  onClick={handleMenuOpen(user!)}
                />
              </BodyElementSmall>
            </Row>
          ))}
        </Table>
      </TableWrapper>
      {selectedUser && (
        <UserContextMenu
          key={selectedUser.id}
          organizationId={organizationId}
          user={selectedUser}
          anchorEl={contextAnchor}
          open={isDisplayContextMenuOpen}
          onClose={handleMenuClose}
        />
      )}
    </>
  )
}
