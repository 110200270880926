import { MatchViewDataService } from 'services/MatchViewData.service'
import { fetchAvailableMatchLocators } from 'modules/match/utils'
import type { Match } from '@sporttotal-tv/dip-coaching-client'
import type { MatchLocatorType } from 'modules/match/types'
import { MediaItemParams } from 'modules/video-player/types'
import { PlayerPayloadAction } from 'modules/video-player/redux'

export const createSetMatchPayload = (
  entityId: string,
  match: Match,
  matchType: MatchLocatorType
): PlayerPayloadAction<MediaItemParams>['payload'] => {
  const lastMatchType = MatchViewDataService.getMatchViewType(
    entityId,
    match.id
  )

  const matchTypes = Object.entries(fetchAvailableMatchLocators(match))
    .filter(([view, hasType]) => hasType && view)
    ?.map<MatchLocatorType>(([view]) => view as MatchLocatorType)

  const resultMatchType =
    lastMatchType && matchTypes.includes(lastMatchType)
      ? lastMatchType
      : matchTypes[0] ?? 'pano'

  const mediaLocator = match.match_assets?.find(
    ({ type }) => type === resultMatchType
  )?.media_locator

  return {
    id: entityId,
    mediaLocator,
    matchType,
    title: match.title,
    activeVideoItemType: 'match',
    activeVideoItemId: match.id,
  }
}
