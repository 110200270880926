import { useContext } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { FlexColumn, FlexRow } from 'modules/landing/components/shared/Flex'
import { Link } from 'modules/landing/components/shared/Link'
import { BUTTON_VARIANTS } from 'modules/landing/components/shared/Button/utils'
import { Button } from 'modules/landing/components/shared/Button/'
import { Text } from 'modules/landing/components/shared/Text/'
import {
  BurgerButton,
  LanguagesListMobile,
  Logo,
  StyledLanguageIcon,
} from './styled'
import { useTranslation } from 'react-i18next'
import { PopupContext } from 'modules/landing/contexts/PopupContext/context'
import { LINKS } from 'modules/landing/constants'
import { useTheme } from '@mui/material'
import { Tooltip } from '@mui/material'
import { changeLanguage } from 'i18next'
import { languages } from 'modules/core/i18n/constants'
import { RequestADemoForm } from 'modules/landing/components/shared/RequestADemoForm/RequestADemoForm'
// Utils
import { handleRedirect } from 'modules/auth/utils'
// Constants
import { APP_SSO_LOGIN_URL, APP_SSO_REGISTER_URL } from 'modules/auth/constants'

import LogoSrc from 'modules/landing/assets/logo.svg'
import MenuIcon from '@mui/icons-material/Menu'

export const HeaderMobile = () => {
  const theme = useTheme()

  const { t } = useTranslation(['pages'])

  const {
    isRequestDemoPopupOpened,
    setIsRequestDemoPopupOpened,
    isBurgerOpened,
    setIsBurgerOpened,
  } = useContext(PopupContext)!

  const onBurgerClick = () => setIsBurgerOpened(!isBurgerOpened)

  const onRequestDemoClick = () => {
    setIsBurgerOpened(!isBurgerOpened)
    setIsRequestDemoPopupOpened(!isRequestDemoPopupOpened)
  }

  return (
    <>
      <FlexRow
        as='header'
        sx={{
          width: '100%',
          height: '70px',
          padding: '14px 25px',
          background: theme.palette.common.black,
        }}
      >
        <FlexRow
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '10px',
          }}
        >
          <Logo sx={{ width: '30px', height: '30px' }} src={LogoSrc} />

          <FlexRow
            sx={{
              gap: '20px',
            }}
          >
            <LanguagesListMobile>
              {languages.map(lang => (
                <Tooltip key={lang.languageCode} title={lang.displayName}>
                  <StyledLanguageIcon
                    className='lang'
                    src={lang.iconSrc}
                    alt={lang.displayName}
                    onClick={() => {
                      changeLanguage(lang.languageCode)
                    }}
                  />
                </Tooltip>
              ))}
            </LanguagesListMobile>

            <BurgerButton
              variant={BUTTON_VARIANTS.default}
              onClick={onBurgerClick}
            >
              <MenuIcon />
            </BurgerButton>
          </FlexRow>
        </FlexRow>
      </FlexRow>

      {isBurgerOpened && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, repeat: 0 }}
          style={{ height: '100vh' }}
        >
          <FlexColumn
            sx={{
              width: '100%',
              height: '100%',
              padding: '25px',
              background: theme.palette.common.black,
              position: 'absolute',
              top: '70px',
              gap: '80px',
              zIndex: 99,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {LINKS.map(link => (
              <Link
                key={`link-${link}`}
                href={`#${link}`}
                onClick={onBurgerClick}
              >
                <Text
                  sx={{
                    font: "normal normal normal 25px/12px 'Exo 2'",
                    textTransform: 'uppercase',
                    textAlign: 'center',
                  }}
                >
                  {t(`landing.header.links.${link}`)}
                </Text>
              </Link>
            ))}

            <FlexColumn sx={{ gap: '25px', marginTop: '10px' }}>
              <Button
                variant={BUTTON_VARIANTS.default}
                sx={{ width: '90vw', fontSize: '15px' }}
                onClick={() => handleRedirect(APP_SSO_LOGIN_URL)}
              >
                {t(`landing.header.buttons.login`)}
              </Button>
              <Button
                variant={BUTTON_VARIANTS.default}
                sx={{ width: '90vw', fontSize: '15px' }}
                onClick={() => handleRedirect(APP_SSO_REGISTER_URL)}
              >
                {t(`landing.header.buttons.sign_up`)}
              </Button>

              <Button
                sx={{ width: '90vw', fontSize: '15px' }}
                variant={BUTTON_VARIANTS.primary}
                onClick={onRequestDemoClick}
              >
                {t(`landing.header.buttons.request_demo`)}
              </Button>
            </FlexColumn>
          </FlexColumn>
        </motion.div>
      )}

      <AnimatePresence>
        {isRequestDemoPopupOpened && <RequestADemoForm />}
      </AnimatePresence>
    </>
  )
}
