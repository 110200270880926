import { BehaviorSubject } from 'rxjs'
import { CalendarEvent } from 'modules/calendar/types'

export class MarkersService {
  private static instance: MarkersService
  private _markers = new BehaviorSubject<CalendarEvent[]>([])
  private constructor(markers: CalendarEvent[]) {
    this._markers.next(markers)
  }

  public static getInstance(): MarkersService {
    if (!MarkersService.instance) {
      const localStorageMarkers = window.localStorage.getItem('markers')
      const markers: CalendarEvent[] = localStorageMarkers
        ? JSON.parse(localStorageMarkers)
        : []
      MarkersService.instance = new MarkersService(markers)
    }

    return MarkersService.instance
  }

  public subscribeToMarkers(func: (value: CalendarEvent[]) => void) {
    this._markers.subscribe(func)
  }

  public updateMarkers(newMarkers: CalendarEvent[]) {
    window.localStorage.setItem('markers', JSON.stringify(newMarkers))
    this._markers.next(newMarkers)
  }
}
