import { PlayersView } from './playersView'

export const COLUMN_TEMPLATES: Record<PlayersView, string[]> = {
  [PlayersView.GENERAL]: [
    'auto',
    '2fr',
    '0.5fr',
    '0.5fr',
    '0.5fr',
    'auto',
    'auto',
    'auto',
    '0.7fr',
    'auto',
  ],
  [PlayersView.CONTRACT]: [
    'auto',
    '2fr',
    '0.5fr',
    '0.5fr',
    '0.3fr',
    '0.5fr',
    '0.5fr',
    '0.7fr',
    'auto',
  ],
  [PlayersView.TRANSFER]: [
    'auto',
    '2fr',
    '2fr',
    '0.8fr',
    '0.8fr',
    '0.8fr',
    '0.8fr',
    '0.8fr',
    'auto',
  ],
  [PlayersView.STATUS]: [
    'auto',
    '2fr',
    '0.8fr',
    '0.8fr',
    '0.8fr',
    '0.8fr',
    '0.8fr',
    '0.8fr',
    'auto',
  ],
  [PlayersView.INJURIES]: [
    'auto',
    '2fr',
    '1fr',
    'auto',
    'auto',
    '0.7fr',
    'auto',
  ],
  [PlayersView.STATS]: [
    'auto',
    '2fr',
    '0.5fr',
    '0.5fr',
    '0.5fr',
    '0.5fr',
    '0.5fr',
    '0.5fr',
    '0.5fr',
    '0.5fr',
    '0.5fr',
    '0.5fr',
    '0.5fr',
    'auto',
  ],
  [PlayersView.PROFILE]: [],
}
