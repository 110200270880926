import { Match } from '@sporttotal-tv/dip-coaching-client'
import { fetchAvailableMatchLocators } from 'modules/match/utils'
import { MatchLocatorType } from '../types'

export const findDesireableMatchLocator = (
  match?: Match
): MatchLocatorType | null => {
  if (!match) return null

  const { pano, broadcast, tactical, broadcast_live } =
    fetchAvailableMatchLocators(match)

  if (broadcast_live) return 'broadcast_live'
  if (pano) return 'pano'
  if (broadcast) return 'broadcast'
  if (tactical) return 'tactical'

  return null
}
