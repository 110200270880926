import {
  CreateUserCacheBody,
  DeleteUserCacheBody,
  UserCache,
  RetrieveUserCacheOptions,
} from '@sporttotal-tv/dip-coaching-client'
import { api } from 'modules/core/api'
import { transformApiResults } from 'modules/core/helpers'

export const CacheApiService = {
  retrieve: transformApiResults<RetrieveUserCacheOptions, UserCache>(
    api.userCache.retrieve
  ),
  create: transformApiResults<CreateUserCacheBody, UserCache>(
    api.userCache.create
  ),
  delete: transformApiResults<DeleteUserCacheBody, UserCache>(
    api.userCache.delete
  ),
}
