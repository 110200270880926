import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import { css } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import { Icon } from 'modules/core/components/Icon'
import { IconButton } from 'modules/core/components/IconButton'

export const DrawerHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spacing(0, 1)};
  // necessary for content to be below app bar
  ${({ theme }) => theme.mixins.toolbar};
`

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})<{ open: boolean }>`
  width: ${({ theme }) => theme.drawerWidth}px;
  flex-shrink: 0;
  white-space: nowrap;

  box-sizing: border-box;

  & .MuiPaper-root {
    box-shadow: 0 3px 6px #000000;
    border: none;
  }

  ${({ open, theme }) =>
    open
      ? css`
          width: ${theme.drawerWidth}px;
          transition: ${theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          })};
          overflow-x: hidden;

          & .MuiDrawer-paper {
            width: ${theme.drawerWidth}px;
            transition: ${theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            })};
            overflow-x: hidden;
          }
        `
      : css`
          transition: ${theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          })};
          overflow-x: hidden;
          width: calc(${theme.spacing(7)} + 1px);
          @media (min-width: ${theme.breakpoints.up('sm')}) {
            width: calc(${theme.spacing(8)} + 1px);
          }

          & .MuiDrawer-paper {
            transition: ${theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            })};
            overflow-x: hidden;
            width: calc(${theme.spacing(7)} + 1px);
            @media (min-width: ${theme.breakpoints.up('sm')}) {
              width: calc(${theme.spacing(8)} + 1px);
            }
          }
        `};
`

export const LogoWrapper = styled('div')`
  width: 28px;
  height: 28px;
  margin-left: ${({ theme }) => theme.spacing(1)};
  padding-left: ${({ theme }) => theme.spacing(1)};

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

export const FlexStretch = styled('div')`
  flex: 1;
`

export const BottomBlock = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(1)};
  margin: ${({ theme }) => theme.spacing(2, 1)};
`

export const ToggleWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
`

export const ToggleIcon = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'closed',
})<{ closed?: boolean }>`
  transition: all 0.3s;

  ${({ closed }) =>
    closed &&
    css`
      transform: rotate(180deg);
    `}
`

export const AnimatedChevronIcon = styled(Icon, {
  shouldForwardProp: prop => prop !== 'chevronUp',
})<{ chevronUp: boolean }>`
  transition: transform 0.3s;
  transform: ${({ chevronUp }) => (chevronUp ? 'rotate(0)' : 'rotate(180deg)')};
`

export const NavExpandIcon = styled(AnimatedChevronIcon)`
  width: 10px;
  height: 10px;
`

export const ListItemButtonStyled = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== 'active',
})<{ active?: boolean }>`
  ${({ theme, active }) =>
    active &&
    css`
      background-color: ${theme.palette.primary.dark};

      &:hover {
        background-color: ${theme.palette.darkest};
      }
    `}
`

export const NavIcon = styled(Icon, {
  shouldForwardProp: prop => prop !== 'active',
})<{ active?: boolean }>`
  & path {
    transition: all 0.3s;
  }
  ${({ theme, active }) =>
    active &&
    css`
      & path {
        fill: ${theme.palette.secondary.main};
      }
    `}
`
