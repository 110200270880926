import {
  PlayerStatus,
  PlayerStatusListParams,
  PlayerStatusList,
  CreatePlayerStatusBody,
  UpdatePlayerStatusBody,
} from '@sporttotal-tv/dip-coaching-client'
import {
  transformApiResults,
  transformIdApiResults,
} from 'modules/core/helpers'
import { api } from 'modules/core/api'

export const TeamPlayerStatusApiService = {
  list: transformApiResults<PlayerStatusListParams, PlayerStatusList>(
    api.playerStatus.list
  ),
  retrieve: transformApiResults<string, PlayerStatus>(
    api.playerStatus.retrieve
  ),
  create: transformApiResults<CreatePlayerStatusBody, PlayerStatus>(
    api.playerStatus.create
  ),
  delete: transformApiResults<string, PlayerStatus>(api.playerStatus.delete),
  update: transformIdApiResults<UpdatePlayerStatusBody, PlayerStatus>(
    api.playerStatus.update
  ),
}
