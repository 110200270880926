import { styled } from '@mui/material'

export const Root = styled('div')`
  margin: ${({ theme }) => theme.spacing(6)} 10%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  height: calc(
    100vh - ${({ theme }) => theme.toolbarHeight}px -
      ${({ theme }) => theme.spacing(12)}
  );
`

export const Wrapper = styled('div')`
  height: 100%;
  padding: ${({ theme }) => theme.spacing(2.5)};
  background-color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(5)};
  overflow: auto;
`
