import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { differenceInYears } from 'date-fns'
// components
import {
  InfoItem,
  InfoTable,
  InfoTableElement,
  InfoTableHeaderElement,
} from 'modules/team-player/components'
// hooks
import {
  useTeamPlayerDelete,
  useTeamPlayerList,
} from 'modules/team-player/hooks'
// constants
import {
  COLUMN_TEMPLATES,
  PlayersView,
  positions,
} from 'modules/team-player/constants'
import { TEAM_PAGE_LIMIT } from 'modules/team/constants'
import { isTeamPlayer } from 'modules/team-player/utils'

// styled
import { Badge } from 'modules/core/styled'

import { GenericViewProps as GeneralViewProps } from 'modules/team-player/types'
import { PlayerModal } from './PlayerModal'
import { useUserTeams } from 'modules/user/hooks'

export const GeneralView: FC<GeneralViewProps> = ({
  search,
  setTeamPlayer,
  setView,
}) => {
  const { t } = useTranslation('components', { keyPrefix: 'teamPlayers' })
  const { data } = useUserTeams()

  const selectPlayer = useCallback(
    (player: InfoItem) => () => {
      if (isTeamPlayer(player) && setTeamPlayer && setView) {
        setTeamPlayer(player)
        setView(PlayersView.PROFILE)
      }
    },
    [setTeamPlayer, setView]
  )

  const tableItems: InfoTableElement[] = useMemo(
    () => [
      {
        type: 'checkbox',
        render: ({ item, Component }) => <Component item={item} />,
      },
      {
        type: 'stickyLeftText',
        render: ({ item, Component }) => (
          <Component
            props={{
              onClick: selectPlayer(item),
              style: { cursor: 'pointer' },
            }}
          >
            {isTeamPlayer(item) && `${item.first_name} ${item.last_name}`}
          </Component>
        ),
      },
      {
        type: 'rating',
        render: ({ item, Component }) => <Component item={item} />,
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component>
            {isTeamPlayer(item) && (
              <>
                <span className='full'>{positions[item.normal_position]}</span>
                <span className='short'>{item.normal_position}</span>
              </>
            )}
          </Component>
        ),
      },
      {
        type: 'nationality',
        render: ({ item, Component }) => (
          <Component item={item}>
            {isTeamPlayer(item) && item.nationality}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component justify='center'>
            {isTeamPlayer(item) && Number(item.height_cm / 100).toPrecision(3)}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component justify='center'>
            {isTeamPlayer(item) && item.weight_kg}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component justify='center'>
            {isTeamPlayer(item) &&
              differenceInYears(new Date(), new Date(+item.birthdate * 1000))}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component justify='center'>
            <Badge variant='default'>Info</Badge>
          </Component>
        ),
      },
      {
        type: 'menu',
        render: ({ item, Component }) => <Component item={item} />,
      },
    ],
    [selectPlayer]
  )

  const tableHeaderItems: InfoTableHeaderElement[] = useMemo(
    () => [
      {
        type: 'actions',
        render: ({ Component }) => (
          <Component
            translation={{
              main: t('table.player.selected'),
              secondary: t('table.player.action'),
            }}
          />
        ),
      },
      {
        type: 'stickyLeftText',
        render: ({ Component }) => (
          <Component>{t('table.player.name')}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>{t('table.player.rating')}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>
            <span className='full'>{t('table.player.position')}</span>
            <span className='short'>{t('table.player.positionShort')}</span>
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>
            <span className='full'>{t('table.player.nationality')}</span>
            <span className='short'>{t('table.player.nationalityShort')}</span>
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component justify='center'>{t('table.player.height')}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component justify='center'>{t('table.player.weight')}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component justify='center'>{t('table.player.age')}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component justify='center'>{t('table.player.alerts')}</Component>
        ),
      },
      {
        type: 'add',
        render: ({ Component }) => (
          <Component
            justify='flex-end'
            translation={{ main: t('table.player.add') }}
            disabled={data?.total === 0}
          />
        ),
      },
    ],
    [t, data]
  )

  const transformName = useCallback((item: InfoItem) => {
    return item && 'first_name' in item
      ? `${item?.first_name} ${item?.last_name}`
      : ''
  }, [])

  return (
    <InfoTable
      tableRows={tableItems}
      tableHeaderRows={tableHeaderItems}
      resource='player'
      transformName={transformName}
      Modal={PlayerModal}
      useList={useTeamPlayerList}
      useDelete={useTeamPlayerDelete}
      useListArgs={{
        page_limit: TEAM_PAGE_LIMIT,
        search_by: ['first_name', 'last_name'],
        search: search,
      }}
      columnsTemplate={COLUMN_TEMPLATES[PlayersView.GENERAL]}
    />
  )
}
