import { useMutation } from 'react-query'
import { ApiService } from 'services/Api.service'
import { ContactUsContactBody } from '@sporttotal-tv/dip-coaching-client'
import { MutateContext } from 'modules/core/types/mutate-context.type'

export const CONTACT_US_CACHE_KEY = 'contact-us-create'

export const useContactUs = () =>
  useMutation<any, Error, ContactUsContactBody, MutateContext<any>>(
    CONTACT_US_CACHE_KEY,
    (params: ContactUsContactBody) => ApiService.contact.contactUs(params)
  )
