import { FC, ReactNode } from 'react'
import {
  SmartToyOutlined,
  SubscriptionsOutlined,
  AdminPanelSettingsOutlined,
  ContentPasteOutlined,
} from '@mui/icons-material'
import { Translation } from 'modules/core/components/Translation'
import { EventType } from 'modules/event/types'

export const TIMELINE_TEXT: Record<EventType, ReactNode> = {
  AI: Translation('components:playerControls.ai'),
  ASSET: Translation('components:playerControls.clips'),
  OFFICIAL_HIGHLIGHTS: Translation('components:playerControls.officialTicker'),
  USER_HIGHLIGHTS: Translation('components:playerControls.userTicker'),
}

export const TIMELINE_ICON: Record<EventType, FC> = {
  AI: SmartToyOutlined,
  ASSET: SubscriptionsOutlined,
  OFFICIAL_HIGHLIGHTS: AdminPanelSettingsOutlined,
  USER_HIGHLIGHTS: ContentPasteOutlined,
}
