import {
  FC,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
// components
import { BaseVideoPlayer, PlayTrack } from 'modules/video-player/components'
// hooks
import { useVideoPlayerControls } from 'modules/video-player/hooks'
// context
import { PlaylistPageContext } from 'modules/playlist/context'

import { IPlaylistPlayer } from './PlaylistPlayer.interface'
import {
  Title,
  TitleContainer,
  PlayControls,
  PlayControlsIcon,
} from './PlaylistPlayer.styled'

export const PlaylistPlayer: FC<IPlaylistPlayer> = ({ title }) => {
  const { activeClip, setClipEnded, previousClip, nextClip, setActiveClip } =
    useContext(PlaylistPageContext)

  const playerRef = useRef<HTMLVideoElement>(null)
  const { stop, pause, play, status, fastForward } = useVideoPlayerControls(
    playerRef.current
  )

  const [, setTime] = useState(0)

  const handleTimeChange = useCallback(
    ({ currentTarget }: SyntheticEvent<HTMLVideoElement>) => {
      setTime(currentTarget.currentTime)
      if (currentTarget.ended) {
        setClipEnded(true)
      }
    },
    [setClipEnded]
  )

  useEffect(() => {
    if (activeClip) {
      play()
    }
  }, [activeClip, play])

  const handlePlaybackChange = useCallback(
    (__: Event, val: number | number[]) => {
      const timeVal = Array.isArray(val) ? 0 : val

      setTime(timeVal)

      if (playerRef.current?.currentTime) {
        playerRef.current.currentTime = timeVal
      }
    },
    [playerRef]
  )

  return (
    <>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      <BaseVideoPlayer
        onTimeUpdate={handleTimeChange}
        ref={playerRef}
        src={activeClip?.media_locator?.url ?? ''}
      />
      <PlayControls>
        <PlayControlsIcon
          disabledClick={!previousClip}
          name='skip-start'
          onClick={() => setActiveClip(previousClip)}
        />
        <PlayControlsIcon
          active={status === 'stop'}
          name='stop'
          onClick={stop}
        />
        <PlayControlsIcon
          active={status === 'play'}
          name='play'
          onClick={play}
        />
        <PlayControlsIcon
          active={status === 'pause'}
          name='pause'
          onClick={pause}
        />
        <PlayControlsIcon
          active={status === 'fast-forward'}
          name='skip-forward'
          onClick={fastForward}
        />
        <PlayControlsIcon
          disabledClick={!nextClip}
          name='skip-end'
          onClick={() => setActiveClip(nextClip)}
        />
      </PlayControls>
      <PlayTrack
        player={playerRef.current ?? undefined}
        onChange={handlePlaybackChange}
      />
    </>
  )
}
