import { fetchAvailableMatchLocators } from './fetchAvailableMatchLocators'
import { Match } from '@sporttotal-tv/dip-coaching-client'

export const findDownloadableMatchLocators = (match?: Match) => {
  if (!match) return []

  const availableMatchLocators = Object.entries(
    fetchAvailableMatchLocators(match)
  )

  return availableMatchLocators.reduce<{ type: string; url: string }[]>(
    (downloadable, [type, hasType]) => {
      if (!hasType) return downloadable

      const matchAsset = match.match_assets?.find(
        asset => asset.type === type && asset.is_playable
      )

      if (!matchAsset) return downloadable

      const { url } = matchAsset.media_locator
      if (url.endsWith('m3u8')) return downloadable

      downloadable.push({ type, url })
      return downloadable
    },
    []
  )
}
