import { ShapeHandler } from 'modules/annotator/types'
import { ShapeOptions, TelestrationHighlight } from 'modules/telestration/types'

export const Highlight: ShapeHandler<TelestrationHighlight> = {
  onCreate({ currentTarget }, options?: ShapeOptions) {
    const points = currentTarget.getStage()?.getPointerPosition()
    const X = points?.x ?? null
    const Y = points?.y ?? null

    if (!X || !Y) return

    return {
      ...options,
      type: 'HIGHLIGHT',
      x: X,
      y: Y,
    }
  },

  // TODO: for this tool there shouldn't be any shaping
  onShaping(highlight, { currentTarget }) {
    const point = currentTarget.getStage()?.getPointerPosition()
    const X = point?.x ?? null
    const Y = point?.y ?? null

    if (!X || !Y) return

    return { ...highlight }
  },

  onShapeUpdated({ currentTarget }) {
    return currentTarget
  },
}
