import { createMutationHook } from 'modules/core/api/hooks/utils'
import { ApiService } from 'services/Api.service'
import { PLAYING_SURFACE_LIST_CACHE_KEY } from './usePlayingSurfaceList'

export const [usePlayingSurfaceDelete] = createMutationHook(
  ApiService.playingSurface.delete,
  queryClient => ({
    onSuccess: () => {
      queryClient.invalidateQueries(PLAYING_SURFACE_LIST_CACHE_KEY, {
        exact: false,
      })
    },
  })
)
