import { Elements } from '@stripe/react-stripe-js'
import { useStripeContext } from 'modules/stripe/hooks'
import { StripeFormProviderProps } from './StripeFormProvider.interface'

export const StripeFormProvider: StripeFormProviderProps = ({ children }) => {
  const { stripe, state } = useStripeContext()

  if (!state.clientSecret || !stripe) return null

  return (
    <Elements
      stripe={stripe}
      options={{
        clientSecret: state.clientSecret,
      }}
    >
      {children}
    </Elements>
  )
}
