import { styled, Modal, Typography } from '@mui/material'

export const Root = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ModalContent = styled('div')`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  padding: ${({ theme }) => theme.spacing(1.25)};
  width: 30%;

  &:focus-visible {
    outline: none;
  }
`

export const ModalHeader = styled('div')`
  height: 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`

export const ModalTitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(1.25)};
`

export const ModalTitle = styled(Typography)`
  font-size: 14px;
`

export const ModalBody = styled('div')`
  max-height: 70vh;
  overflow: auto;
  padding-right: ${({ theme }) => theme.spacing(0.75)};
`
