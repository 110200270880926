import {
  useEffect,
  useMemo,
  useCallback,
  useRef,
  FC,
  useContext,
  useState,
  KeyboardEvent,
} from 'react'
import { Html } from 'react-konva-utils'
// Hooks
import { useAnalyserPlayerTimestamp } from 'modules/analyser/hooks'
import {
  useTelestrationCreate,
  useTelestrationGroupsList,
  TelestrationUpdateParams,
  useTelestrationGroupCreate,
} from 'modules/telestration/hooks'
import { useTelestrationUpdate } from 'modules/telestration/hooks'
import { useReduxDispatch, useReduxSelector } from 'modules/core/hooks'
// Components
import { Group } from 'react-konva'
import { BaseAnnotator, Shape } from 'modules/annotator/components'
import {
  TelestrationShapeType,
  TelestrationTool,
} from 'modules/telestration/types'
import {
  IKonvaStage,
  IKonvaTransformer,
  IKonvaNode,
} from 'modules/annotator/types'
import { InputText } from './Annotator.styled'
// context
import { ComponentInfoContext } from 'modules/generic/context'
// Utils
import { isNumberBetween } from 'modules/core/utils'
import {
  transformShapeFromPercentage,
  transformShapeToPercentage,
} from 'modules/annotator/utils'
// constants
import {
  TelestrationShapesTransformParams,
  defaultTransformAttrs,
  SELECTION_AREA_NAME,
} from 'modules/annotator/constants'
// Types
import { AnnotatorProps } from './Annotator.interface'
// redux
import {
  selectTelestrationActiveData,
  selectToolsData,
  setActiveGroup,
  setActiveShape,
} from 'modules/telestration/redux'
import {
  selectVideoPlayerTelestrationComponentId,
  selectMediaLocator,
  selectFrameId,
} from 'modules/video-player/redux'

export const Annotator: FC<AnnotatorProps> = ({
  onLoadingStatusChange,
  containerHeight,
  containerWidth,
  elementHeight,
  elementWidth,
  elementLeft,
  elementTop,
}) => {
  const annotatorRef = useRef<IKonvaStage>(null)
  const { componentId } = useContext(ComponentInfoContext)
  const dispatch = useReduxDispatch()

  const currentTimestamp = useAnalyserPlayerTimestamp({ precise: true })

  const mediaLocator = useReduxSelector(state =>
    selectMediaLocator(state, componentId)
  )
  const frameId = useReduxSelector(state => selectFrameId(state, componentId))
  const telestrationId = useReduxSelector(state =>
    selectVideoPlayerTelestrationComponentId(state, componentId)
  )
  const { tool, color } = useReduxSelector(state =>
    selectToolsData(state, telestrationId ?? '')
  )
  const { activeGroup, activeShape, shapeOptions } = useReduxSelector(state =>
    selectTelestrationActiveData(state, telestrationId ?? '')
  )

  const { mutate: telestrationCreate } = useTelestrationCreate()
  const { mutate: telestrationUpdate } = useTelestrationUpdate()
  const { mutate: telestrationGroupCreate } = useTelestrationGroupCreate()
  const { data: telestrationGroupList } = useTelestrationGroupsList({
    media_locator_id: mediaLocator?.id,
    expand: ['telestrations'],
    sort_by: 'created_at',
  })

  const [editingShape, setEditingShape] = useState<string | null>(null)

  useEffect(() => {
    const stage = annotatorRef.current
    if (!(stage instanceof IKonvaStage)) return

    const transformer = stage.findOne('Transformer')
    if (!(transformer instanceof IKonvaTransformer)) return

    let currentShape: IKonvaNode | null = null

    if (activeShape) {
      currentShape = stage.findOne(
        ({ attrs }: IKonvaNode) => attrs?.id === activeShape
      )

      const selectionArea = stage.findOne(
        ({ attrs }: IKonvaNode) =>
          attrs?.id === `${SELECTION_AREA_NAME}-${activeShape}`
      )

      const shapeToolType: TelestrationTool | undefined =
        currentShape?.attrs?.shapeToolType

      const transformParams =
        shapeToolType && TelestrationShapesTransformParams[shapeToolType]

      if (transformParams?.isCustom) {
        return
      }

      // Create specific transformer for shape
      // It apply when we click on shape in the telestration
      if (transformParams) {
        transformer.attrs = {
          ...transformer.attrs,
          ...defaultTransformAttrs,
          ...transformParams,
        }
      }

      if (selectionArea) {
        currentShape && transformer.nodes([currentShape, selectionArea])
      } else {
        currentShape && transformer.nodes([currentShape])
      }
    }

    // No active shape, detatch transformer from active shape
    if (!currentShape) {
      transformer.detach()
      transformer.getStage()?.batchDraw()
    }
  }, [activeShape, currentTimestamp])

  const handleShapeDrawn = useCallback(
    (shape: TelestrationShapeType) => {
      const haveNecessaryData = !!(mediaLocator?.id && tool)

      // When Telestration Group and Telestrations do not exist for the current mediaLocator
      // Or when we are in frame view then create new group
      if (
        (!activeGroup && haveNecessaryData) ||
        (Boolean(frameId) && haveNecessaryData)
      ) {
        onLoadingStatusChange(true)
        telestrationGroupCreate(
          {
            name: `Group ${(telestrationGroupList?.results.length ?? 0) + 1}`,
            media_locator_id: mediaLocator.id,
            is_visible: true,
            is_locked: false,
          },
          {
            onSuccess: result => {
              onLoadingStatusChange(false)
              telestrationCreate(
                {
                  telestration_group_id: result.id,
                  telestration_tool: tool,
                  start_time: currentTimestamp,
                  duration: 1,
                  data: transformShapeToPercentage(
                    shape,
                    elementWidth ?? 0,
                    elementHeight ?? 0
                  ),
                  media_locator_id: mediaLocator.id,
                },
                {
                  onSuccess: telestrationResult => {
                    if (telestrationId) {
                      dispatch(
                        setActiveShape({
                          id: telestrationId,
                          shape: telestrationResult.id,
                        })
                      )
                    }
                  },
                }
              )

              if (telestrationId) {
                dispatch(
                  setActiveGroup({
                    id: telestrationId,
                    group: result.id,
                  })
                )
              }
            },
          }
        )
        return
      }

      if (!activeGroup || !tool || !mediaLocator?.id) return

      telestrationCreate(
        {
          telestration_group_id: activeGroup,
          telestration_tool: tool,
          start_time: currentTimestamp,
          duration: 1,
          data: transformShapeToPercentage(
            shape,
            elementWidth ?? 0,
            elementHeight ?? 0
          ),
          media_locator_id: mediaLocator.id,
        },
        {
          onSuccess: telestrationResult => {
            if (telestrationId) {
              dispatch(
                setActiveShape({
                  id: telestrationId,
                  shape: telestrationResult.id,
                })
              )
            }
          },
        }
      )
    },
    [
      telestrationGroupList,
      frameId,
      mediaLocator?.id,
      dispatch,
      telestrationGroupCreate,
      telestrationCreate,
      activeGroup,
      tool,
      currentTimestamp,
      elementWidth,
      elementHeight,
      onLoadingStatusChange,
      telestrationId,
    ]
  )

  const handleShapeSelected = useCallback(
    (shapeUuid: string | null) => {
      if (telestrationId) {
        dispatch(
          setActiveShape({
            id: telestrationId,
            shape: shapeUuid,
          })
        )
      }
    },
    [dispatch, telestrationId]
  )

  const handleShapeUpdate = useCallback(
    (params: TelestrationUpdateParams) => telestrationUpdate(params),
    [telestrationUpdate]
  )

  const handleEscapeKeys = useCallback(
    (id: string, data: object) => (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if ((e.key === 'Enter' && !e.shiftKey) || e.key === 'Escape') {
        // @ts-ignore
        if (e.target.value && e.target.value !== '') {
          handleShapeUpdate({
            id,
            params: {
              // @ts-ignore
              data: { ...data, text: e.target.value },
            },
          })
        }

        setEditingShape(null)
      }
    },
    [handleShapeUpdate]
  )

  const eventTelestrationGroups = useMemo(() => {
    if (!telestrationGroupList?.results) return null

    return telestrationGroupList.results
      .filter(({ is_visible }) => is_visible)
      .map(({ name, telestrations }, index) => {
        const groupShapes = (telestrations ?? [])
          .filter(({ start_time, duration }) => {
            const endTime = start_time + duration

            return isNumberBetween(currentTimestamp, start_time, endTime)
          })
          .map(({ id, data, telestration_tool }) => {
            const percentageData = transformShapeFromPercentage(
              data,
              elementWidth ?? 0,
              elementHeight ?? 0
            )
            if (id === editingShape) {
              // TODO: fix issue with synchronously unmount when changing text
              // It seems to be related to the react-konva Group component
              return (
                <Html
                  key={id}
                  groupProps={{ x: percentageData.x, y: percentageData.y }}
                  divProps={{ style: { opacity: 1 } }}
                >
                  <InputText
                    autoFocus
                    multiline
                    disableUnderline
                    onFocus={event => event.target.select()}
                    defaultValue={percentageData.text}
                    onKeyDown={handleEscapeKeys(id, data)}
                    color={percentageData.stroke}
                  />
                </Html>
              )
            }
            return (
              // @ts-ignore
              <Shape
                {...percentageData}
                key={id}
                id={id}
                type={telestration_tool}
                isActive={activeShape === id}
                draggable={activeShape !== null}
                onChange={shape => {
                  handleShapeUpdate({
                    id,
                    params: {
                      data: transformShapeToPercentage(
                        shape.attrs,
                        elementWidth ?? 0,
                        elementHeight ?? 0
                      ),
                    },
                  })
                }}
              />
            )
          })

        return <Group key={index} name={name} children={groupShapes} />
      })
  }, [
    editingShape,
    currentTimestamp,
    activeShape,
    telestrationGroupList?.results,
    handleShapeUpdate,
    containerHeight,
    containerWidth,
  ])

  return (
    <BaseAnnotator
      ref={annotatorRef}
      color={color}
      telestrationTool={tool ?? null}
      telestrationOptions={shapeOptions}
      onShapeDrawn={handleShapeDrawn}
      onShapeSelected={handleShapeSelected}
      onShapeAction={setEditingShape}
      width={elementWidth}
      height={elementHeight}
      top={elementTop}
      left={elementLeft}
    >
      {eventTelestrationGroups}
    </BaseAnnotator>
  )
}
