import icoPlayer from "modules/landing/assets/features/ico-player.svg";
import icoLiveAnction from "modules/landing/assets/features/ico-liveaction.svg";
import icoPlaylist from "modules/landing/assets/features/ico-playlist.svg";
import icoTelestration from "modules/landing/assets/features/ico-telestration.svg";
import icoStats from "modules/landing/assets/features/ico-stats.svg";
import icoHeatmap from "modules/landing/assets/features/ico-heatmap.svg";

export const texts = new Array(6).fill('text');

export const features = [
  icoPlayer,
  icoLiveAnction,
  icoPlaylist,
  icoTelestration,
  icoStats,
  icoHeatmap
];
