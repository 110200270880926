import _ from 'lodash'
import { GroupEvent } from 'modules/event/types'
import { ServerTranslatedProperty } from 'modules/core/i18n/types'
import { IconName } from 'modules/core/components/Icon/Icon.interface'

export const makeGroupEvents = (
  parsedArray: Array<{
    name: ServerTranslatedProperty
    icon: IconName
    start: number
    end: number
  }>
): GroupEvent[] => {
  const removableArray = _.orderBy([...parsedArray], 'start')
  const iterableArray = _.orderBy([...parsedArray], 'start')

  return iterableArray.reduce<GroupEvent[]>((groupEvents, event) => {
    const groupSizes = removableArray.reduce(
      (sizes, eventItem) => {
        if (
          (eventItem.start >= sizes.start && eventItem.start <= sizes.end) ||
          (eventItem.end >= sizes.start && eventItem.end <= sizes.end)
        ) {
          const maxGroupEnd =
            sizes.end > eventItem.end ? sizes.end : eventItem.end
          const minGroupStart =
            sizes.start < eventItem.start ? sizes.start : eventItem.start

          return { start: minGroupStart, end: maxGroupEnd }
        }

        return sizes
      },
      { start: event.start, end: event.end }
    )

    const eventsInGroup = _.remove(
      removableArray,
      removableEvent =>
        (removableEvent.start >= groupSizes.start &&
          removableEvent.start <= groupSizes.end) ||
        (removableEvent.end >= groupSizes.start &&
          removableEvent.end <= groupSizes.end)
    )

    // get all events that in range of current group
    const accepted = _.orderBy(eventsInGroup, 'start')

    if (accepted.length === 0) {
      return groupEvents
    }

    const result = {
      events: accepted.map(event => ({
        name: event.name,
        icon: event.icon,
        start: event.start,
      })),
      start: groupSizes.start,
      end: groupSizes.end,
      duration: groupSizes.end - groupSizes.start,
    }

    return [...groupEvents, result]
  }, [])
}
