import { useContext } from 'react'
// Hooks
import { useMatch } from 'modules/match/hooks'
// Components
import { ToolbarControls, ToolbarHeader } from 'modules/generic/components'
import { OptionsSelectorMenuItem } from 'modules/core/styled'
import { LayoutItemToolbarContainer } from 'modules/layout/components/LayoutItemToolbar/LayoutItemToolbar.styled'
import { ToolbarDivider } from 'modules/generic/styled'
import { VideoPlayerSelector } from 'modules/video-player/components'
// Context
import { MatchPeriodContext } from 'modules/match-period/context'
// Constants
import { SPORT_MATCH_PERIODS } from 'modules/match-period/constants'
// Types
import { IToolbarComponent } from 'modules/layout/components/LayoutItem/LayoutItem.interface'
import { MatchPeriod as IMatchPeriod } from 'modules/match-period/types'

import {
  ToolbarContainer,
  MatchPeriodOptionsSelector,
} from './MatchPeriodToolbar.styled'
import { SportType } from 'modules/sport/types'

export const MatchPeriodToolbar: IToolbarComponent = ({
  layoutIndex,
  layoutId,
}) => {
  const { matchPeriod, setMatchPeriod } = useContext(MatchPeriodContext)
  const { match } = useMatch()

  const handlePeriodSelect = (matchPeriod: IMatchPeriod) =>
    setMatchPeriod && setMatchPeriod(matchPeriod)

  return (
    <LayoutItemToolbarContainer>
      <ToolbarHeader layoutId={layoutId} layoutIndex={layoutIndex}>
        <ToolbarDivider />
        <VideoPlayerSelector />
      </ToolbarHeader>
      {match && (
        <ToolbarContainer>
          <MatchPeriodOptionsSelector
            value={matchPeriod}
            onChange={({ target }) =>
              handlePeriodSelect(target.value as IMatchPeriod)
            }
          >
            {match &&
              SPORT_MATCH_PERIODS[match?.sport?.name as SportType]?.map(
                ({ value, label }) => (
                  <OptionsSelectorMenuItem value={value}>
                    {label}
                  </OptionsSelectorMenuItem>
                )
              )}
          </MatchPeriodOptionsSelector>
        </ToolbarContainer>
      )}
      <ToolbarControls layoutId={layoutId} layoutIndex={layoutIndex} />
    </LayoutItemToolbarContainer>
  )
}
