import { InfoItem } from '../components'
import {
  Player,
  PlayerContract,
  PlayerInjury,
  PlayerStats,
  PlayerStatus,
  PlayerTransfer,
} from '@sporttotal-tv/dip-coaching-client'

export const isContract = (item: InfoItem): item is PlayerContract => {
  return (item as PlayerContract).agreement !== undefined
}

export const isTransfer = (item: InfoItem): item is PlayerTransfer => {
  return (item as PlayerTransfer).loan !== undefined
}

export const isStatus = (item: InfoItem): item is PlayerStatus => {
  return (item as PlayerStatus).math_load !== undefined
}

export const isStats = (item: InfoItem): item is PlayerStats => {
  return (item as PlayerStats).shots !== undefined
}

export const isInjury = (item: InfoItem): item is PlayerInjury => {
  return (item as PlayerInjury).current_injury !== undefined
}

export const isTeamPlayer = (item: InfoItem): item is Player => {
  return (item as Player).normal_position !== undefined
}
