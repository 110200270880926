import {
  styled,
  Typography,
  Menu as BaseMenu,
  MenuItem as BaseMenuItem,
} from '@mui/material'

export const StyledMenu = styled(BaseMenu)`
  transform: translate(5px, -5px);

  & .MuiList-root {
    padding: 0;
    min-width: 110px;
  }
`

export const StyledMenuItem = styled(BaseMenuItem)`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(0.25, 0.75)};
`

export const StyledMenuItemText = styled(Typography)`
  font-size: 12px;
  width: 100%;
  color: ${({ theme }) => theme.palette.primary.light};
`
