import {
  useMutation,
  useQueryClient,
  UseMutationResult,
  QueryClient,
} from 'react-query'
import { uniqueId } from 'lodash'
import { UseMutationOptions } from 'modules/core/types'

export const createMutationHook = <
  Func extends (...args: any) => any,
  Response = Awaited<ReturnType<Func>>,
  Param = Parameters<Func>[0]
>(
  mutateFunc: Func,
  createOptions?: (
    queryClient: QueryClient
  ) => UseMutationOptions<Response, Param>
) => {
  const cacheKey = uniqueId()

  const useHook = () => {
    const queryClient = useQueryClient()

    return useMutation(
      cacheKey,
      (params?: Param) => mutateFunc(params),
      createOptions && createOptions(queryClient)
    ) as UseMutationResult<Response, any, Param, unknown>
  }

  return [useHook, cacheKey] as [typeof useHook, string]
}

export const createMutationHookWithBody = <
  Func extends (param: any, body: any) => any,
  Response = Awaited<ReturnType<Func>>,
  Param = Parameters<Func>[0],
  Body = Parameters<Func>[1]
>(
  mutateFunc: Func,
  createOptions?: (
    queryClient: QueryClient,
    params: Param
  ) => UseMutationOptions<Response, Body>
) => {
  const cacheKey = uniqueId()

  const useHook = (param: Param) => {
    const queryClient = useQueryClient()

    return useMutation(
      cacheKey,
      (body?: Body) => mutateFunc(param, body),
      createOptions && createOptions(queryClient, param)
    ) as UseMutationResult<Response, any, Body, unknown>
  }

  return [useHook, cacheKey] as [typeof useHook, string]
}
