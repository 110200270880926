const defaultTriggerEvents = ['durationchange', 'timeupdate']

export class MockedVideoPlayer {
  private _duration: number = 60
  private _ended: boolean = false
  private _currentTime: number = 0
  private _muted: boolean = true
  private _volume: number = 1
  private _paused: boolean = true
  private _playbackRate: number = 1

  private _onPlay: Function = () => {}
  private _onPause: Function = () => {}
  private _onTimeUpdateTimeout: NodeJS.Timeout | null = null
  private _eventListeners: { [key: string]: Function[] } = {}
  public mockedPlayer = true

  private clearOnTimeUpdateTimeout() {
    if (this._onTimeUpdateTimeout) {
      clearTimeout(this._onTimeUpdateTimeout)
      this._onTimeUpdateTimeout = null
    }
  }

  private refreshTimeout() {
    this.clearOnTimeUpdateTimeout()

    this._onTimeUpdateTimeout = setTimeout(() => {
      if (this._currentTime > this._duration) {
        this._paused = true
        this._ended = true
        this.triggerEvent('pause')
        this.triggerEvent('ended')
        return
      }

      this.currentTime = this.currentTime + 0.1
      Promise.resolve().then(() => {
        this.refreshTimeout()
      })
    }, 100)
  }

  public get duration(): number {
    return this._duration
  }

  public set duration(value: number) {
    this._duration = value
    this.triggerEvent('durationchange')
  }

  public get ended(): boolean {
    return this._ended
  }

  public get currentTime(): number {
    return this._currentTime
  }

  public set currentTime(value: number) {
    this._currentTime = value
    if (this._currentTime > this._duration) {
      this._paused = true
      this._ended = true
      this.triggerEvent('pause')
      return
    }

    this._ended = false
    this.triggerEvent('timeupdate')
  }

  public get muted(): boolean {
    return this._muted
  }

  public set muted(value: boolean) {
    this._muted = value
  }

  public get volume(): number {
    return this._volume
  }

  public set volume(value: number) {
    this._volume = value
  }

  public get paused(): boolean {
    return this._paused
  }

  public set paused(value: boolean) {
    this._paused = value

    if (value) {
      this.clearOnTimeUpdateTimeout()
      this.triggerEvent('pause')
    }

    if (!value) {
      this.refreshTimeout()
      this.triggerEvent('play')
    }
  }

  public updateDuration(value: number) {
    if (this._duration === value) return

    this._duration = value
    this.triggerEvent('durationchange')
  }

  public get playbackRate(): number {
    return this._playbackRate
  }

  public set playbackRate(value: number) {
    this._playbackRate = value
  }

  public addEventListener(eventType: string, listener: Function) {
    if (!this._eventListeners[eventType]) {
      this._eventListeners[eventType] = []
    }
    this._eventListeners[eventType].push(listener)

    if (defaultTriggerEvents.includes(eventType)) {
      this.triggerEvent(eventType)
    }

    // For image play might be triggered before adding event listener, so we need to trigger it manually
    if (eventType === 'play' && !this._paused) {
      this.triggerEvent(eventType)
    }
  }

  public removeEventListener(eventType: string, listener: Function) {
    const listeners = this._eventListeners[eventType]
    if (listeners) {
      this._eventListeners[eventType] = listeners.filter(l => l !== listener)
    }
  }

  public clearData() {
    this.clearOnTimeUpdateTimeout()
    this._eventListeners = {}
  }

  public play() {
    this._paused = false
    this.triggerEvent('play')
    this.refreshTimeout()

    return Promise.resolve()
  }

  public pause() {
    this._paused = true
    this.clearOnTimeUpdateTimeout()
    this.triggerEvent('pause')
  }

  public get onplay() {
    return this._onPlay
  }

  public set onplay(func: Function) {
    this.removeEventListener('play', this._onPlay)
    this.addEventListener('play', func)

    this._onPlay = func
  }

  public get onpause() {
    return this._onPlay
  }

  public set onpause(func: Function) {
    this.removeEventListener('pause', this._onPause)
    this.addEventListener('pause', func)

    this._onPause = func
  }

  private triggerEvent(eventType: string) {
    const listeners = this._eventListeners[eventType]
    if (listeners) {
      listeners.forEach(listener => {
        listener({
          currentTarget: {
            duration: this._duration,
            currentTime: this._currentTime,
            paused: this._paused,
            muted: this._muted,
            ended: this._ended,
            volume: this._volume,
            playbackRate: this._playbackRate,
          },
        })
      })
    }
  }
}
