import { LayoutIndex } from './LayoutIndex'

// The default layout entries for each layout index, by default there are no entries of the component, so we use 0
export const defaultLayoutEntries: Record<LayoutIndex, number> = {
  [LayoutIndex.PLAYER]: 0,
  [LayoutIndex.PLAYER_CONTROLS]: 0,
  [LayoutIndex.HEATMAP]: 0,
  [LayoutIndex.MINIMAP]: 0,
  [LayoutIndex.STATISTICS]: 0,
  [LayoutIndex.TELESTRATION]: 0,
  [LayoutIndex.ASSETS]: 0,
  [LayoutIndex.PLAYLIST]: 0,
  [LayoutIndex.PLAYERS]: 0,
  [LayoutIndex.HIGHLIGHTS]: 0,
}
