import { FC, ReactNode, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSubscription } from 'modules/subscription/hooks'
import { routeNames } from 'modules/core/constants'
import { ENABLE_SUBSCRIPTION } from 'modules/core/constants'
import { SUBSCRIPTION_FREE_ROUTES } from 'modules/subscription/constants'

export const SubscriptionProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { data: subscription, status } = useSubscription()

  useEffect(() => {
    if (!ENABLE_SUBSCRIPTION) return
    if (SUBSCRIPTION_FREE_ROUTES.test(pathname)) return
    if (status === 'loading' || status === 'idle') return

    const requiresPayment =
      status === 'error' || subscription?.status !== 'SUCCESSFUL'

    if (requiresPayment) navigate(routeNames.subscription.path)
  }, [subscription, navigate, pathname, status])

  return <>{children}</>
}
