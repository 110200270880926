import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import { Playlist, PlaylistList } from '@sporttotal-tv/dip-coaching-client'
import { PLAYLIST_CACHE_KEY } from 'modules/playlist/hooks'
import { MutateContext } from 'modules/core/types/mutate-context.type'
import { InfiniteQuery } from 'modules/core/types'

export const PLAYLIST_DELETE_CACHE_KEY = 'playlist-delete'

export const usePlaylistDelete = () => {
  const queryClient = useQueryClient()

  return useMutation<
    Playlist,
    Error,
    string,
    MutateContext<InfiniteQuery<PlaylistList>>
  >(PLAYLIST_DELETE_CACHE_KEY, (id: string) => ApiService.playlist.delete(id), {
    onMutate: async id => {
      await queryClient.cancelQueries(PLAYLIST_CACHE_KEY, { exact: false })

      const previousData =
        queryClient.getQueriesData<InfiniteQuery<PlaylistList>>(
          PLAYLIST_CACHE_KEY
        )

      if (previousData) {
        queryClient.setQueriesData<InfiniteQuery<PlaylistList> | undefined>(
          PLAYLIST_CACHE_KEY,
          oldData =>
            oldData && {
              pageParams: oldData.pageParams,
              pages: oldData.pages.map(
                page =>
                  page && {
                    total: Math.max(page.total - 1, 0),
                    results: page.results.filter(item => item.id !== id),
                  }
              ),
            }
        )
      }

      return { previousData }
    },
    onError: (err, variables, context) => {
      if (context?.previousData) {
        context?.previousData.forEach(prevData =>
          queryClient.setQueriesData<InfiniteQuery<PlaylistList>>(
            prevData[0],
            prevData[1]
          )
        )
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(PLAYLIST_CACHE_KEY, { exact: false })
    },
  })
}
