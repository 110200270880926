import { forwardRef, Fragment, useCallback, useEffect } from 'react'
// components
import {
  LayoutItemBackground,
  LayoutItemContainer,
} from 'modules/layout/components/LayoutItem/LayoutItem.styled'
// hooks
import { useReduxDispatch } from 'modules/core/hooks'
// Types
import { ILayoutItem } from './LayoutItem.interface'
// redux
import {
  setDefaultLayoutCacheComponentDataRequest,
  setLayoutsPositionStack,
} from 'modules/layout/redux'

const LayoutItem = forwardRef<HTMLDivElement, ILayoutItem>(
  (
    { itemId, style, children, ProviderComponent = Fragment, ...others },
    ref
  ) => {
    const dispatch = useReduxDispatch()

    const handleComponentClick = useCallback(() => {
      dispatch(setLayoutsPositionStack(itemId))
    }, [dispatch, itemId])

    useEffect(() => {
      dispatch(
        setDefaultLayoutCacheComponentDataRequest({
          id: itemId,
          createdAt: Date.now(),
        })
      )
    }, [itemId, dispatch])

    return (
      <LayoutItemContainer
        ref={ref}
        elevation={0}
        onClickCapture={handleComponentClick}
        style={style}
        {...others}
      >
        <LayoutItemBackground>
          <ProviderComponent>{children}</ProviderComponent>
        </LayoutItemBackground>
      </LayoutItemContainer>
    )
  }
)

export { LayoutItem }
