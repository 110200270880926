import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
// components
import { InfoTable } from 'modules/team-player/components'
import { Icon } from 'modules/core/components'
// hooks
import {
  useTeamPlayerStatsDelete,
  useTeamPlayerStatsList,
} from 'modules/team-player/hooks'
// utils
import { isStats } from 'modules/team-player/utils'
// constants
import { COLUMN_TEMPLATES, PlayersView } from 'modules/team-player/constants'
// types
import type {
  InfoTableElement,
  InfoTableHeaderElement,
  InfoItem,
} from 'modules/team-player/components'

import { GenericViewProps as StatsViewProps } from 'modules/team-player/types'
import { StatsModal } from './StatsModal'
import { CardElement, FoulWrapper } from './StatsView.styled'

export const StatsView: FC<StatsViewProps> = () => {
  // hooks
  const { t } = useTranslation('components', { keyPrefix: 'teamPlayers' })

  const tableRows: InfoTableElement[] = useMemo(
    () => [
      {
        type: 'checkbox',
        render: ({ item, Component }) => <Component item={item} />,
      },
      {
        type: 'stickyLeftText',
        render: ({ item, Component }) => (
          <Component>
            {isStats(item) &&
              `${item.player?.first_name} ${item.player?.last_name}`}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component justify='center'>{isStats(item) && item.goals}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component justify='center'>{isStats(item) && item.shots}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component justify='center'>
            {isStats(item) && item.shots_on_target}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component justify='center'>
            {isStats(item) && item.penalties_scored}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component justify='center'>
            {isStats(item) && item.penalties_taken}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component justify='center'>
            {isStats(item) && item.passes_completed}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component justify='center'>
            {isStats(item) && item.passes_attempt}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component justify='center'>
            {isStats(item) && item.mistake_leading_to_goal}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component justify='center'>
            {isStats(item) && item.cross_attempted}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component justify='center'>
            {isStats(item) && item.cross_completed}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component justify='center'>
            <FoulWrapper>
              <CardElement>
                {isStats(item) && item.yellow_card + item.red_card}
              </CardElement>
              <CardElement>
                <Icon name='yellow-card' />
                {isStats(item) && item.yellow_card}
              </CardElement>
              <CardElement>
                <Icon name='red-card' />
                {isStats(item) && item.red_card}
              </CardElement>
            </FoulWrapper>
          </Component>
        ),
      },
      {
        type: 'menu',
        render: ({ item, Component }) => <Component item={item} />,
      },
    ],
    []
  )

  const tableHeaderRows: InfoTableHeaderElement[] = useMemo(
    () => [
      {
        type: 'actions',
        render: ({ Component }) => (
          <Component
            translation={{
              main: t('table.stats.selected'),
              secondary: t('table.stats.action'),
            }}
          />
        ),
      },
      {
        type: 'stickyLeftText',
        render: ({ Component }) => (
          <Component>{t('table.stats.name')}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component justify='center'>{t('table.stats.goals')}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component justify='center'>{t('table.stats.shots')}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component justify='center'>
            {t('table.stats.shotsOnTarget')}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component justify='center'>
            {t('table.stats.penaltiesScored')}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component justify='center'>
            {t('table.stats.penaltiesTaken')}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component justify='center'>
            {t('table.stats.passesCompleted')}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component justify='center'>
            {t('table.stats.passesAttempt')}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component justify='center'>{t('table.stats.mistakes')}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component justify='center'>
            {t('table.stats.crossAttempted')}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component justify='center'>
            {t('table.stats.crossCompleted')}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component justify='center'>{t('table.stats.foul')}</Component>
        ),
      },
      {
        type: 'add',
        render: ({ Component }) => (
          <Component
            justify='flex-end'
            translation={{ main: t('table.stats.add') }}
          />
        ),
      },
    ],
    [t]
  )

  const transformName = useCallback((item: InfoItem) => {
    return item && 'player' in item
      ? `${item.player?.first_name} ${item.player?.last_name}`
      : ''
  }, [])

  return (
    <InfoTable
      tableRows={tableRows}
      tableHeaderRows={tableHeaderRows}
      resource='stats'
      transformName={transformName}
      Modal={StatsModal}
      useList={useTeamPlayerStatsList}
      useDelete={useTeamPlayerStatsDelete}
      useListArgs={{
        expand: ['player'],
      }}
      columnsTemplate={COLUMN_TEMPLATES[PlayersView.STATS]}
    />
  )
}
