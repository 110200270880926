import { FC, ReactNode } from 'react'
import { AppBar } from 'modules/core/components'
import { Main } from './CameraPageLayout.styled'

export const CameraPageLayout: FC<{ children: ReactNode }> = ({ children }) => (
  <>
    <AppBar
      tabs={[
        {
          id: 'cameras',
          name: 'Cameras',
          path: 'cameras',
          fullPath: '/cameras',
          useFullPath: true,
          static: true,
        },
        // {
        //   id: 'playing-surfaces',
        //   name: 'Playing Surfaces',
        //   path: 'playing-surfaces',
        //   fullPath: '/cameras/playing-surfaces',
        //   static: true,
        //   useFullPath: true,
        // },
      ]}
    />
    <Main>{children}</Main>
  </>
)
