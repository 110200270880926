import { useContext, useEffect, useMemo, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
// styled
import { Loader } from 'modules/core/styled'
// services
import { TokenService } from 'services/Token.service'
// constants
import { routeNames } from 'modules/core/constants'
// context
import { SnackbarContext } from 'modules/core/context'

import { Root } from './Redirecting.styled'

export const Redirecting = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const executedRef = useRef<boolean>(false)
  const { showSnackbar } = useContext(SnackbarContext)

  const code = useMemo(() => {
    const state = location.state as { code?: string }
    return state.code
  }, [location.state])

  useEffect(() => {
    if (executedRef.current) return

    executedRef.current = true
    if (code) {
      TokenService.getInstance()
        .requestToken(code)
        .then(() => {
          navigate(`${routeNames.analysis.path}/dashboard`)
        })
        .catch(() => {
          showSnackbar({
            message: 'Error while authenticating. Try again later.',
            type: 'error',
          })
          navigate('/')
        })
      return
    }

    navigate('/')
  }, [code, navigate, showSnackbar])

  return (
    <Root>
      <Loader />
    </Root>
  )
}
