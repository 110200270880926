import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { useQueryRetry } from 'modules/core/hooks'
import { MatchRetrieveParams } from '@sporttotal-tv/dip-coaching-client'

export const MATCH_RETRIEVE_CACHE_KEY = 'match-retrieve'

export const useMatchRetrieve = (params: MatchRetrieveParams) => {
  const handleQueryRetry = useQueryRetry({ maxRetries: 0 })

  return useQuery(
    [MATCH_RETRIEVE_CACHE_KEY, params.id, params],
    () => ApiService.match.retrieve(params),
    {
      enabled: !!params.id && params.id !== '',
      staleTime: Infinity,
      retry: handleQueryRetry,
    }
  )
}
