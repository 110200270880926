import {
  Line,
  Rect,
  Circle,
  Arrow,
  Text,
  Shape,
  KonvaNodeComponent,
} from 'react-konva'
import { TelestrationTool } from 'modules/telestration/types'

const TelestrationShapes: Record<
  TelestrationTool,
  KonvaNodeComponent<any, any>
> = {
  LINE: Line,
  RECT: Rect,
  CIRCLE: Circle,
  PLAYER_SELECTION: Shape,
  CURVED_LINE: Shape,
  HIGHLIGHT: Shape,
  ARROW: Arrow,
  TEXT: Text,
  MULTI_LINE: Shape,
} as const

export { TelestrationShapes }
