import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { CurrencyListParams } from '@sporttotal-tv/dip-coaching-client'
import { useQueryRetry } from 'modules/core/hooks'

export const CURRENCY_CACHE_KEY = 'currency-list'

export const useCurrencyList = (options?: CurrencyListParams) => {
  const handleQueryRetry = useQueryRetry()

  return useQuery(
    [CURRENCY_CACHE_KEY, options],
    () => ApiService.currency.list(options),
    {
      retry: handleQueryRetry,
      staleTime: Infinity,
    }
  )
}
