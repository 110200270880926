import {
  CurrencyList,
  CurrencyListParams,
} from '@sporttotal-tv/dip-coaching-client'
import { transformApiResults } from 'modules/core/helpers'
import { api } from 'modules/core/api'

export const CurrencyApiService = {
  list: transformApiResults<CurrencyListParams, CurrencyList>(
    api.currency.list
  ),
}
