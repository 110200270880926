import React, { FC, useCallback } from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import format from 'date-fns/format'
import createEditEvent from './createEditEvent'
import { css, styled } from '@mui/material'
import { useReduxDispatch, useReduxSelector } from 'modules/core/hooks'
import {
  CalendarEditData,
  selectCalendarSelectedDate,
  setCalendarEditEvent,
} from 'modules/calendar/redux'
import { useMarkers } from '../hooks/useMarkers'
import { addWeeks, endOfMonth, startOfMonth } from 'date-fns'

type event = {
  id: string | number
  title: string
  description: string
  begin: string
  end: string
}

const PaperHeader = styled('div', {
  shouldForwardProp: prop => prop !== 'isWeekend',
})<{ isWeekend?: boolean }>`
  border-bottom: 1px solid #dadce0;
  border-right: 1px solid #dadce0;
  padding: ${({ theme }) => theme.spacing(1)};
  text-align: center;
  color: ${({ theme }) => theme.palette.text.secondary};
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 0;
  min-width: 64.38px;

  ${({ isWeekend, theme }) =>
    isWeekend &&
    css`
      background-color: ${theme.palette.primary.main};
    `}
`

const PaperDefault = styled(PaperHeader)`
  height: 100%;
`

const Today = styled('span', {
  shouldForwardProp: prop => prop !== 'isToday',
})<{ isToday?: boolean }>`
  ${({ isToday, theme }) =>
    isToday &&
    css`
      color: ${theme.palette.primary.main};
      background-color: ${theme.createAlpha(theme.palette.secondary.main, 0.8)};
      border-radius: 50%;
      padding: ${theme.spacing(1)};
      cursor: pointer;
      &:hover {
        background-color: ${theme.palette.secondary.main};
      }
    `}
`

const EventsContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;
  background-color: transparent;
  position: relative;
  height: calc(100% - 25px);
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(1)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const MonthMarker = styled('div')`
  overflow: hidden;
  min-height: 23px;
  border: 1px solid #ff004490;
  background-color: #ff004490;
  padding: 1px 3px;
  margin-bottom: 2px;
  border-radius: 3px;
  cursor: default;
  z-index: 50;
  &:hover {
    z-index: 53;
    background-color: #ff004490;
  }
`

type CalendarLayoutMonthProps = { weeks: Date[][] }

const CalendarLayoutMonth: FC<CalendarLayoutMonthProps> = ({ weeks }) => {
  const dispatch = useReduxDispatch()
  const selectedDate = useReduxSelector(selectCalendarSelectedDate)
  const markers = useMarkers({
    startDate: addWeeks(startOfMonth(selectedDate), -1),
    endDate: addWeeks(endOfMonth(selectedDate), 2),
  })

  const maxHeight = (weeks: Date[][]) => {
    const size = weeks.length

    if (size === 5) {
      return {
        height: 'calc((100% / 5) - 21.2px)',
      }
    }

    return {
      height: 'calc((100% / 6) - 17.5px)',
    }
  }

  const getEventData = (day: Date) => {
    const monthEvents = markers.sort((a: event, b: event) => {
      return new Date(a.begin).getTime() - new Date(b.begin).getTime()
    })

    const dayEvents = monthEvents.filter(
      event =>
        format(new Date(event.begin), 'yyyyMMdd') === format(day, 'yyyyMMdd')
    )

    const dayHoursEvents = dayEvents
      .map(event => new Date(event.begin).getHours())
      .sort((numberA: number, numberB: number) => numberA - numberB)

    const eventsByHour = dayHoursEvents.reduce<{ hour: number; len: number }[]>(
      (acc, hour) => {
        const len = dayHoursEvents.filter(
          (eventHour: number) => eventHour === hour
        ).length
        !acc.some(accItem => accItem.hour === hour) && acc.push({ hour, len })
        return acc
      },
      []
    )

    return eventsByHour.map(evHour => {
      return dayEvents
        .filter(event => new Date(event.begin).getHours() === evHour.hour)
        .map(event => (
          <MonthMarker key={`event-${event.id}`}>{event.title}</MonthMarker>
        ))
    })
  }

  const setCalendarEditEventFunc = useCallback(
    (data: CalendarEditData) => {
      dispatch(setCalendarEditEvent(data))
    },
    [dispatch]
  )

  return (
    <>
      <Grid
        container
        spacing={0}
        direction='row'
        justifyItems='center'
        alignItems='center'
        wrap='nowrap'
      >
        {weeks[0].map((weekDay: Date, index: number) => {
          return (
            <Grid item xs key={`calendar-column-header-label-${index}`}>
              <PaperHeader isWeekend={index === 5 || index === 6}>
                <Typography sx={{ textTransform: 'capitalize' }}>
                  {format(weekDay, 'E')}
                </Typography>
              </PaperHeader>
            </Grid>
          )
        })}
      </Grid>

      {weeks.map((week, weekIndex: number) => (
        <Grid
          container
          spacing={0}
          direction='row'
          justifyItems='space-evenly'
          alignItems='stretch'
          wrap='nowrap'
          key={`calendar-main-line-${weekIndex}`}
          style={maxHeight(weeks)}
        >
          {week.map((day, dayIndex: number) => {
            const isToday =
              format(day, 'ddMMyyyy') === format(new Date(), 'ddMMyyyy')
            const eventsOfDay = getEventData(day)

            return (
              <Grid
                item
                xs
                key={`calendar-main-line-${weekIndex}-column-${dayIndex}`}
              >
                <PaperDefault isWeekend={dayIndex === 5 || dayIndex === 6}>
                  <Typography sx={{ textTransform: 'capitalize' }}>
                    <Today isToday={isToday}>{day.getDate()}</Today>

                    {day.getDate() === 1 ? format(new Date(day), ' MMM') : null}
                  </Typography>

                  {eventsOfDay && eventsOfDay.length > 0 && (
                    <EventsContainer
                      data-date={day}
                      onClick={eventEl =>
                        createEditEvent({
                          eventEl,
                          setStateCalendar: setCalendarEditEventFunc,
                        })
                      }
                    >
                      {eventsOfDay}
                    </EventsContainer>
                  )}
                </PaperDefault>
              </Grid>
            )
          })}
        </Grid>
      ))}
    </>
  )
}

export default CalendarLayoutMonth
