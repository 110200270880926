import { Player } from '@sporttotal-tv/dip-coaching-client'

export const positions: Record<Player['normal_position'], string> = {
  GK: 'Goal Keeper',
  RB: 'Right Back',
  LB: 'Left Back',
  RWB: 'Right Wing Back',
  LWB: 'Left Wing Back',
  CB: 'Center Back',
  CDM: 'Center Defensive Midfielder',
  CM: 'Center Midfielder',
  CAM: 'Center Attacking Midfielder',
  RM: 'Right Midfielder',
  LM: 'Left Midfielder',
  RW: 'Right Wing',
  LW: 'Left Wing',
  RF: 'Right Forward',
  LF: 'Left Forward',
  CF: 'Center Forward',
  ST: 'Striker',
  Setter: 'Setter',
  Libero: 'Libero',
  'Middle Hitter': 'Middle Hitter',
  'Opposite Hitter': 'Opposite Hitter',
  'Outside Hitter': 'Outside Hitter',
  'Middle Blocker': 'Middle Blocker',
}
