import { Dispatch, createContext } from 'react';

export interface PopupContextInterface {
  isRequestDemoPopupOpened: boolean;
  setIsRequestDemoPopupOpened: Dispatch<boolean>;

  isBurgerOpened: boolean;
  setIsBurgerOpened: Dispatch<boolean>;
}

export const PopupContext = createContext<PopupContextInterface | null>(null);
