import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { useQueryRetry } from 'modules/core/hooks'
import { Frame, FrameRetrieveParams } from '@sporttotal-tv/dip-coaching-client'
import { UseQueryOptions } from 'modules/core/types'

export const FRAME_RETRIEVE_CACHE_KEY = 'frame-retrieve'

export const useFrameRetrieve = (
  data: FrameRetrieveParams,
  queryOptions?: UseQueryOptions<Frame, (string | FrameRetrieveParams)[]>
) => {
  const handleQueryRetry = useQueryRetry({ maxRetries: 0 })

  return useQuery(
    [FRAME_RETRIEVE_CACHE_KEY, data],
    () => ApiService.frame.retrieve(data),
    {
      enabled: !!data.id,
      staleTime: Infinity,
      retry: handleQueryRetry,
      ...queryOptions,
    }
  )
}
