import {
  PlayerContractListParams,
  PlayerContractList,
  PlayerContract,
  CreatePlayerContractBody,
  UpdatePlayerContractBody,
} from '@sporttotal-tv/dip-coaching-client'
import {
  transformApiResults,
  transformIdApiResults,
} from 'modules/core/helpers'
import { api } from 'modules/core/api'

export const TeamPlayerContractApiService = {
  list: transformApiResults<PlayerContractListParams, PlayerContractList>(
    api.playerContract.list
  ),
  retrieve: transformApiResults<string, PlayerContract>(
    api.playerContract.retrieve
  ),
  create: transformApiResults<CreatePlayerContractBody, PlayerContract>(
    api.playerContract.create
  ),
  delete: transformApiResults<string, PlayerContract>(
    api.playerContract.delete
  ),
  update: transformIdApiResults<UpdatePlayerContractBody, PlayerContract>(
    api.playerContract.update
  ),
}
