import { styled } from '@mui/material/styles'
import { css, Input } from '@mui/material'
import { Icon } from 'modules/core/components/Icon'
import { IconButton } from 'modules/core/components/IconButton'

export const InputWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(1)};
`

export const InputIconWrapper = styled(IconButton)`
  padding: 4px;
`

export const ActionIcon = styled(Icon)`
  height: 8px;
  width: 8px;
`

export const InputElement = styled(Input, {
  shouldForwardProp: prop => prop !== 'componentVariant',
})<{ componentVariant?: 'default' | 'small' }>`
  ${({ componentVariant }) =>
    componentVariant === 'small' &&
    css`
      font-size: 12px;
    `}
`
