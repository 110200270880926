import { FC, ReactNode } from 'react'
// Components
import { AppBar } from 'modules/core/components'
// constants
import { adminPageLayoutTabs } from 'modules/core/constants'

import { Main } from './AdminPageLayout.styled'

export const AdminPageLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <AppBar tabs={adminPageLayoutTabs} />
      <Main>{children}</Main>
    </>
  )
}
