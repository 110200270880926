import { ApiService } from 'services/Api.service'
import { createMutationHook } from 'modules/core/api/hooks/utils'
import { ADMIN_ORGANIZATION_CLUB_LIST_CACHE_KEY } from './useList'

export const [useAdminOrganizationClubCreate] = createMutationHook(
  ApiService.admin.organization.club.create,
  queryClient => ({
    onSuccess: () =>
      queryClient.invalidateQueries(ADMIN_ORGANIZATION_CLUB_LIST_CACHE_KEY),
  })
)
