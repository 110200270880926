import { styled } from '@mui/material'

export const Flex = styled('div')`
  display: flex;
`

export const FlexRow = styled(Flex)`
  flex-direction: row;
`

export const FlexColumn = styled(Flex)`
  flex-direction: column;
`
