import * as yup from 'yup'

export const teamPlayerCreateSchema = yup.object().shape({
  name: yup.string().required('components:teamPlayers.modal.player.error.name'),
  surname: yup
    .string()
    .required('components:teamPlayers.modal.player.error.surname'),
  birthdate: yup
    .string()
    .required('components:teamPlayers.modal.player.error.birthdate.required')
    .test(
      'is-valid',
      'components:teamPlayers.modal.player.error.birthdate.invalid',
      value => {
        const yesterday = new Date()
        yesterday.setDate(new Date().getDate() - 1)

        return new Date(value ?? '') < yesterday
      }
    ),
  team_id: yup
    .string()
    .uuid('components:teamPlayers.modal.player.error.team.invalid')
    .required('components:teamPlayers.modal.player.error.team.required'),
  shirt_number: yup
    .number()
    .integer('components:teamPlayers.modal.player.error.shirt_number.invalid')
    .typeError('components:teamPlayers.modal.player.error.shirt_number.invalid')
    .required('components:teamPlayers.modal.player.error.shirt_number.required')
    .min(0, 'components:teamPlayers.modal.player.error.shirt_number.invalid'),
  position: yup
    .string()
    .required('components:teamPlayers.modal.player.error.position'),
  nationality: yup
    .string()
    .required('components:teamPlayers.modal.player.error.nationality'),
  height: yup
    .number()
    .typeError('components:teamPlayers.modal.player.error.height.required')
    .required('components:teamPlayers.modal.player.error.height.required')
    .min(0, 'components:teamPlayers.modal.player.error.height.invalid'),
  weight: yup
    .number()
    .typeError('components:teamPlayers.modal.player.error.weight.required')
    .required('components:teamPlayers.modal.player.error.weight.required')
    .min(0, 'components:teamPlayers.modal.player.error.weight.invalid'),
  rating: yup
    .number()
    .required('components:teamPlayers.modal.player.rating.required')
    .min(0, 'components:teamPlayers.modal.player.rating.invalid')
    .max(5, 'components:teamPlayers.modal.player.rating.invalid'),
})
