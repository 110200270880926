import { createContext, useState, useCallback, useEffect } from 'react'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { useStripeConfig } from 'modules/stripe/hooks'
import {
  StripeState,
  StripeContextState,
  StripeContextProviderProps,
} from './StripeContext.interface'

export const StripeContext = createContext<StripeContextState>({ stripe: null })

export const StripeContextProvider: StripeContextProviderProps = ({
  children,
}) => {
  const [state, setState] = useState<StripeState>({})
  const [stripe, setStripe] = useState<Stripe | null>(null)
  const { data } = useStripeConfig()

  useEffect(() => {
    if (!data || stripe) return

    loadStripe(data.publishableKey).then(setStripe)
  }, [data, stripe])

  const handleDispatch = useCallback(
    (updatedState: Partial<StripeState>) =>
      setState(prev => ({ ...prev, ...updatedState })),
    []
  )

  return (
    <StripeContext.Provider value={{ state, dispatch: handleDispatch, stripe }}>
      {children}
    </StripeContext.Provider>
  )
}
