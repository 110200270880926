import { css, styled } from '@mui/material'

export const TagsWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'compact',
})<{ compact?: boolean }>`
  display: flex;
  flex: 1;
  column-gap: ${({ theme }) => theme.spacing(1)};
  row-gap: ${({ theme }) => theme.spacing(1)};
  flex-wrap: wrap;

  ${({ compact }) =>
    compact &&
    css`
      height: 23px;
      overflow: hidden;
    `}
`
