import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import {
  Telestration,
  TelestrationGroupList,
  UpdateTelestrationBody,
} from '@sporttotal-tv/dip-coaching-client'
import { MutateContext } from 'modules/core/types'
import { TELESTRATION_GROUPS_LIST_CACHE_KEY } from './useTelestrationGroupsList'

export type TelestrationUpdateParams = {
  id: string
  params: UpdateTelestrationBody
}

export const TELESTRATION_UPDATE_CACHE_KEY = 'telestation-update'

export const useTelestrationUpdate = () => {
  const queryClient = useQueryClient()

  return useMutation<
    Telestration,
    Error,
    TelestrationUpdateParams,
    MutateContext<TelestrationGroupList>
  >(
    TELESTRATION_UPDATE_CACHE_KEY,
    (data: TelestrationUpdateParams) =>
      ApiService.telestration.update(data.id, data.params),
    {
      onMutate: async data => {
        await queryClient.cancelQueries(TELESTRATION_GROUPS_LIST_CACHE_KEY, {
          exact: false,
        })

        const previousData = queryClient.getQueriesData<TelestrationGroupList>(
          TELESTRATION_GROUPS_LIST_CACHE_KEY
        )

        if (previousData) {
          queryClient.setQueriesData<TelestrationGroupList | undefined>(
            TELESTRATION_GROUPS_LIST_CACHE_KEY,
            oldData =>
              oldData && {
                total: oldData.total,
                results: oldData.results.map(telestrationGroup => ({
                  ...telestrationGroup,
                  telestrations: telestrationGroup.telestrations?.map(
                    telestration => {
                      if (telestration.id !== data.id) {
                        return telestration
                      }

                      return {
                        ...telestration,
                        ...data.params,
                      }
                    }
                  ),
                })),
              }
          )
        }

        return { previousData }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData<TelestrationGroupList>(
              prevData[0],
              prevData[1]
            )
          )
        }
      },
      onSettled: _ =>
        queryClient.invalidateQueries(TELESTRATION_GROUPS_LIST_CACHE_KEY, {
          exact: false,
        }),
    }
  )
}
