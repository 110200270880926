import { styled } from '@mui/material'

const TIER_CARD_SPACING = 13

export const BillingPeriodContainer = styled('div')`
  display: flex;
  grid-gap: ${({ theme }) => theme.spacing(2)};
`

export const TierCardContainer = styled('div')`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(18)};
  grid-gap: ${({ theme }) => theme.spacing(TIER_CARD_SPACING)};
`
