import { styled, Typography } from '@mui/material'

export const Root = styled('div')`
  margin: ${({ theme }) => theme.spacing(6)} 9%
    ${({ theme }) => theme.spacing(2)};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  height: calc(100vh - 64px - ${({ theme }) => theme.spacing(8)});
`

export const Wrapper = styled('div')`
  padding: ${({ theme }) => theme.spacing(6)} 10%
    ${({ theme }) => theme.spacing(3)};
  background-color: ${({ theme }) => theme.palette.primary.main};
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const Title = styled(Typography)`
  font-size: 36px;
  margin: ${({ theme }) => theme.spacing(6, 0)};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-weight: bold;
`

export const Content = styled('div')`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  flex: 1;
  overflow: auto;

  & > ol > li {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  & a {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`
