import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import {
  AdminUserList,
  AdminUsersUpdatePayload,
  User,
} from '@sporttotal-tv/dip-coaching-client'
import {
  USER_ADMIN_USER_RETRIEVE_CACHE_KEY,
  USER_LIST_CACHE_KEY,
} from 'modules/user/hooks'
import { MutateContext } from 'modules/core/types/mutate-context.type'

export const ADMIN_USER_UPDATE_CACHE_KEY = 'admin-user-update'

type UseAdminUserUpdateParams = {
  id: string
  params: AdminUsersUpdatePayload
}

export const useAdminUserUpdate = () => {
  const queryClient = useQueryClient()

  return useMutation<
    User,
    Error,
    UseAdminUserUpdateParams,
    MutateContext<AdminUserList>
  >(
    ADMIN_USER_UPDATE_CACHE_KEY,
    ({ id, params }: UseAdminUserUpdateParams) =>
      ApiService.user.adminUserUpdate(id, params),
    {
      onMutate: async data => {
        await queryClient.cancelQueries(USER_LIST_CACHE_KEY, {
          exact: false,
        })

        const previousData =
          queryClient.getQueriesData<AdminUserList>(USER_LIST_CACHE_KEY)

        if (previousData) {
          queryClient.setQueriesData<AdminUserList | undefined>(
            USER_LIST_CACHE_KEY,
            oldData =>
              oldData && {
                total: oldData.total,
                results: oldData.results?.map(user => {
                  if (user.id === data.id) {
                    return { ...user, ...data.params }
                  }
                  return user
                }),
              }
          )
        }

        return { previousData }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData<AdminUserList>(prevData[0], prevData[1])
          )
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(USER_LIST_CACHE_KEY, { exact: false })
        queryClient.invalidateQueries(USER_ADMIN_USER_RETRIEVE_CACHE_KEY)
      },
    }
  )
}
