import { FC } from 'react'
// hooks
import { useTranslation } from 'react-i18next'
// type
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar'

import { ResetDate } from './VideoPlayerToolbar.styled'

export const CustomActionBar: FC<PickersActionBarProps> = ({ onClear }) => {
  const { t } = useTranslation('components', { keyPrefix: 'player' })

  return <ResetDate onClick={onClear}>{t('clearDate')}</ResetDate>
}
