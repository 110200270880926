import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Asset, Match } from '@sporttotal-tv/dip-coaching-client'
import { DatePicker } from '@mui/x-date-pickers'
import { Collapse, TextFieldProps } from '@mui/material'
import { format } from 'date-fns'
// Hooks
import {
  useDebounce,
  useOutsideClick,
  usePageScrolling,
  useReduxDispatch,
} from 'modules/core/hooks'
import { useMatchList } from 'modules/match/hooks'
// Redux
import { setMediaItem } from 'modules/video-player/redux'
// Context
import { ComponentInfoContext } from 'modules/generic/context'
// Styled
import { HorizontalLoader, Tooltip } from 'modules/core/styled'
// Utils
import { createCalendarFilterDates } from 'modules/match/utils'
import {
  createSetMatchPayload,
  findDesireableMatchLocator,
  hasAnyMatchLocators,
} from 'modules/match/utils'
// Constants
import { MATCH_PAGE_LIMIT } from 'modules/match/constants'

import {
  SelectIcon,
  SelectorWrapper,
  SelectorModal,
  SelectorIntractable,
  Search,
  Divider,
  ItemsList,
  Item,
  InputTextField,
  FilterWrapper,
  ClipName,
  ItemWrapper,
  ClipTime,
  AnimatedChevronIcon,
  IconWrapper,
  ClipItem,
  MatchSearchIcon,
} from './VideoPlayerToolbar.styled'
import { CustomActionBar } from './CustomActionBar'

export const MatchSearch = () => {
  const dispatch = useReduxDispatch()
  const { componentId: layoutId } = useContext(ComponentInfoContext)
  const { t } = useTranslation('components', { keyPrefix: 'player' })

  const [openState, setOpenState] = useState<{ [key: string]: boolean }>({})
  const [selectedDate, setSelectedData] = useState<Date | null>(null)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')

  const debounceSearchValue = useDebounce(searchValue, 500)
  const { containerRef, isBottom, resetIsBottom } = usePageScrolling(100)

  const modalRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const handleOutsideClick = useCallback(() => setModalOpen(false), [])

  useOutsideClick<HTMLDivElement>(
    modalRef,
    handleOutsideClick,
    wrapperRef,
    'MuiCalendarPicker-root'
  )

  const matchDateFilters = useMemo(
    () => selectedDate && createCalendarFilterDates(selectedDate),
    [selectedDate]
  )

  const matchList = useMatchList({
    expand: [
      'assets',
      'teams',
      'assets.event',
      'match_assets',
      'match_assets.media_locator',
      'assets.media_locator',
    ],
    page_limit: MATCH_PAGE_LIMIT,
    search_by: ['title'],
    search: debounceSearchValue,
    start_date: matchDateFilters?.startDate,
    end_date: matchDateFilters?.endDate,
  })

  const handlePlay = useCallback(
    (match: Match) => () => {
      const desiredMatchLocatorType = findDesireableMatchLocator(match)
      if (!desiredMatchLocatorType) return

      dispatch(
        setMediaItem(
          createSetMatchPayload(layoutId, match, desiredMatchLocatorType)
        )
      )
    },
    [dispatch, layoutId]
  )

  const handlePlayClip = useCallback(
    (clip: Asset) => () => {
      dispatch(
        setMediaItem({
          id: layoutId,
          mediaLocator: clip.media_locator,
          activeVideoItemId: clip.id,
          activeVideoItemType: 'clip',
          title: clip.title,
        })
      )
    },
    [dispatch, layoutId]
  )

  const toggleOpenState = useCallback(
    (id: string) => () => {
      setOpenState(prevState => ({
        ...prevState,
        [id]: !prevState[id],
      }))
    },
    []
  )

  useEffect(() => {
    if (
      isBottom &&
      matchList.hasNextPage &&
      !matchList.isFetchingNextPage &&
      !matchList.isLoading
    ) {
      resetIsBottom()
      matchList.fetchNextPage()
    }
  }, [matchList, isBottom, resetIsBottom])

  return (
    <SelectorWrapper>
      <SelectorModal ref={modalRef} hidden={!modalOpen}>
        <FilterWrapper>
          <DatePicker
            value={selectedDate}
            onChange={setSelectedData}
            components={{
              ActionBar: CustomActionBar,
            }}
            componentsProps={{
              actionBar: {
                onChange: () => setSelectedData(new Date()),
              },
            }}
            renderInput={(params: TextFieldProps) => (
              <InputTextField
                {...params}
                inputProps={{ 'aria-label': 'match-date-selector' }}
              />
            )}
          />
          <Search
            inputProps={{ 'aria-label': 'match-search' }}
            placeholder='Search'
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          />
        </FilterWrapper>
        <Divider />
        <ItemsList ref={containerRef}>
          {matchList.data?.pages.map(page =>
            page.results.map(match => (
              <Fragment key={match.id}>
                <ItemWrapper disabled={!hasAnyMatchLocators(match)}>
                  <Item key={match.id} onClick={handlePlay(match)}>
                    {match.title}
                  </Item>
                  {match.assets && match.assets?.length > 0 && (
                    <IconWrapper>
                      <AnimatedChevronIcon
                        name='chevron-up'
                        onClick={toggleOpenState(match.id)}
                        chevronUp={openState[match.id]}
                      />
                    </IconWrapper>
                  )}
                </ItemWrapper>

                <Collapse in={openState[match.id]}>
                  {match.assets?.map(asset => (
                    <ClipItem key={asset.id} onClick={handlePlayClip(asset)}>
                      <ClipName>{asset.title} </ClipName>
                      <ClipTime>
                        (
                        {format(new Date(+asset.created_at * 1000), 'dd/MM/yy')}
                        )
                      </ClipTime>
                    </ClipItem>
                  ))}
                </Collapse>
              </Fragment>
            ))
          )}
          {matchList.isFetchingNextPage && <HorizontalLoader />}
        </ItemsList>
      </SelectorModal>
      <Tooltip title={!modalOpen ? t('matchSearch') : ''} placement='top' arrow>
        <SelectorIntractable
          ref={wrapperRef}
          onClick={() => setModalOpen(prev => !prev)}
        >
          <MatchSearchIcon name='assets' />
          <SelectIcon name='chevron-small-down' chevronUp={true} />
        </SelectorIntractable>
      </Tooltip>
    </SelectorWrapper>
  )
}
