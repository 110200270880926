import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
// Hooks
import { useMatch } from 'modules/match/hooks'
// Components
import { TeamToolbar } from 'modules/team/components'
// Context
import { TeamContext } from 'modules/team/context'
import { ComponentInfoContext } from 'modules/generic/context'

import {
  Root,
  Wrapper,
} from 'modules/match-info/components/Heatmap/Heatmap.styled'

export const Heatmap = () => {
  const { t: tHeatmap } = useTranslation('components', { keyPrefix: 'heatmap' })
  const { t: tMatch } = useTranslation('match')
  const { team } = useContext(TeamContext)
  const { componentId, layoutIndex } = useContext(ComponentInfoContext)
  const { match, matchData } = useMatch()

  const heatmapImageSrc =
    match && matchData?.first_half && matchData?.first_half[team]?.heatmap

  const heatmapImage = heatmapImageSrc ? (
    <img
      style={{
        height: '100%',
        width: '100%',
        objectFit: 'contain',
        backgroundColor: '#000',
      }}
      src={heatmapImageSrc}
      alt={tHeatmap('title')}
    />
  ) : (
    tHeatmap('no_source')
  )

  return (
    <Root>
      <TeamToolbar layoutIndex={layoutIndex} layoutId={componentId} />
      <Wrapper>{match ? heatmapImage : tMatch('no_match_selected')}</Wrapper>
    </Root>
  )
}
