import { ProfileContainer, Main, EditContainer } from './Profile.styled'
import { ProfileInfo, ProfileEdit } from 'modules/user/components'
// hooks
import { useUser } from 'modules/user/hooks'

export const Profile = () => {
  const user = useUser()
  return (
    <Main>
      <ProfileContainer>
        <ProfileInfo user={user.data} />
      </ProfileContainer>
      <EditContainer>
        <ProfileEdit />
      </EditContainer>
    </Main>
  )
}
