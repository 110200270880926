import { TelestrationTool } from 'modules/telestration/types'
import { IconName } from 'modules/core/components/Icon/Icon.interface'

export const telestrationIcons: Record<TelestrationTool, IconName> = {
  ARROW: 'arrow',
  CIRCLE: 'circle',
  LINE: 'pencil',
  RECT: 'rectangle',
  TEXT: 'fonts',
  PLAYER_SELECTION: 'shield',
  CURVED_LINE: 'single-select',
  HIGHLIGHT: 'map-marker',
  MULTI_LINE: 'free-line',
}
