import {
  CreatePlaylistAssetBody,
  PlaylistAsset,
  DeletePlaylistAssetBody,
} from '@sporttotal-tv/dip-coaching-client'
import { api } from 'modules/core/api'
import { transformApiResults } from 'modules/core/helpers/transformApiResults'

export const PlaylistAssetApiService = {
  add: transformApiResults<CreatePlaylistAssetBody, PlaylistAsset>(
    api.playlistAsset.create
  ),
  delete: transformApiResults<DeletePlaylistAssetBody, PlaylistAsset>(
    api.playlistAsset.delete
  ),
}
