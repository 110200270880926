import { Translation } from 'modules/core/components/Translation'
import { MatchPeriod, SportMatchPeriod } from './types'

const FIRST_HALF = {
  label: Translation('match:period.first_half'),
  value: 'first_half' as MatchPeriod,
}

const SECOND_HALF = {
  label: Translation('match:period.second_half'),
  value: 'second_half' as MatchPeriod,
}

const THIRD_HALF = {
  label: Translation('match:period.third_half'),
  value: 'third_half' as MatchPeriod,
}

const FOURTH_HALF = {
  label: Translation('match:period.fourth_half'),
  value: 'fourth_half' as MatchPeriod,
}

export const SPORT_MATCH_PERIODS: SportMatchPeriod = {
  Football: [FIRST_HALF, SECOND_HALF],
  Volleyball: [FIRST_HALF, SECOND_HALF, THIRD_HALF, FOURTH_HALF],
  Basketball: [FIRST_HALF, SECOND_HALF, THIRD_HALF, FOURTH_HALF],
  'Ice-Hockey': [FIRST_HALF, SECOND_HALF, THIRD_HALF],
  'Field-Hockey': [FIRST_HALF, SECOND_HALF, THIRD_HALF, FOURTH_HALF],
  Futsal: [FIRST_HALF, SECOND_HALF],
}
