import { useCallback, useEffect, useState, FC, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { User } from '@sporttotal-tv/dip-coaching-client'
//Hhooks
import { useUserList } from 'modules/user/hooks'
import { usePageScrolling } from 'modules/core/hooks'
// Components
import { IconButton } from 'modules/core/components'
// Constants
import { USER_PAGE_LIMIT } from 'modules/user/constants'
import { routeNames } from 'modules/core/constants'
import { USER_TEAMS_TOOLTIP_THRESHOLD } from './UserList.constants'
// Types
import { UserListProps } from './UserList.interface'

// Styled
import {
  HeaderElement,
  HorizontalLoader,
  HeaderElementSmall,
  Row,
  Table,
  BodyElementSmall,
  BodyElement,
  Tooltip,
} from 'modules/core/styled'

import {
  RoleElement,
  TableWrapper,
  TierElement,
  TierBadge,
  TeamWrapper,
} from './UserList.styled'
import { UserContextMenu } from './UserContextMenu'

export const UserList: FC<UserListProps> = ({ search }) => {
  const navigate = useNavigate()
  const { t } = useTranslation('pages', { keyPrefix: 'admin' })
  const { containerRef, isBottom, resetIsBottom } = usePageScrolling(100)

  const users = useUserList({
    expand: ['teams'],
    search,
    search_by: ['email', 'firstname', 'lastname'],
    page_limit: USER_PAGE_LIMIT,
  })

  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const [contextAnchor, setContextAnchor] = useState<HTMLElement | null>(null)
  const isDisplayContextMenuOpen = Boolean(contextAnchor)

  useEffect(() => {
    if (
      isBottom &&
      users.hasNextPage &&
      !users.isFetchingNextPage &&
      !users.isLoading
    ) {
      resetIsBottom()
      users.fetchNextPage()
    }
  }, [users, isBottom, resetIsBottom])

  const handleMenuOpen =
    (user: User) => (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      setSelectedUser(user)
      setContextAnchor(event.currentTarget)
    }

  const handleMenuClose = () => setContextAnchor(null)

  const handleUserProfileNavigate = useCallback(
    (id: string) => () => {
      navigate(`${routeNames.admin.path}/user/${id}`)
    },
    [navigate]
  )

  return (
    <>
      <TableWrapper ref={containerRef}>
        <Table>
          <Row>
            <HeaderElement>{t('table.row.name')}</HeaderElement>
            <HeaderElement>{t('table.row.surname')}</HeaderElement>
            <HeaderElement>{t('table.row.email')}</HeaderElement>
            <HeaderElement>{t('table.row.team')}</HeaderElement>
            <HeaderElement>{t('table.row.role')}</HeaderElement>
            <HeaderElement>{t('table.row.tier')}</HeaderElement>
            <HeaderElementSmall />
          </Row>
          {users.data?.pages.map(page =>
            page.results?.map(user => (
              <Row
                interactive
                key={user.id}
                onClick={handleUserProfileNavigate(user.id)}
              >
                <BodyElement>{user.firstname}</BodyElement>
                <BodyElement>{user.lastname}</BodyElement>
                <BodyElement>{user.email}</BodyElement>
                <BodyElement>
                  {user.teams && user.teams.length > 0 && (
                    <Tooltip
                      title={
                        <div>
                          {user.teams
                            .slice(0, USER_TEAMS_TOOLTIP_THRESHOLD)
                            .map(team => (
                              <div key={team.id}>{team.name}</div>
                            ))}
                          <div>
                            {user.teams.length > USER_TEAMS_TOOLTIP_THRESHOLD &&
                              t('table.tooltip.teamMore', {
                                count: +(
                                  user.teams.length -
                                  USER_TEAMS_TOOLTIP_THRESHOLD
                                ),
                              })}
                          </div>
                        </div>
                      }
                    >
                      <TeamWrapper>
                        {user.teams.map(team => team.name).join(', ')}
                      </TeamWrapper>
                    </Tooltip>
                  )}
                </BodyElement>
                <RoleElement>{user.role.toLowerCase()}</RoleElement>
                <TierElement>
                  <TierBadge>{user.feature_tier.toLowerCase()}</TierBadge>
                </TierElement>
                <BodyElementSmall>
                  <IconButton
                    name='vertical-menu'
                    onClick={handleMenuOpen(user)}
                  />
                </BodyElementSmall>
              </Row>
            ))
          )}
        </Table>
        {users.isFetchingNextPage && <HorizontalLoader />}
      </TableWrapper>
      {selectedUser && (
        <UserContextMenu
          key={selectedUser.id}
          user={selectedUser}
          anchorEl={contextAnchor}
          open={isDisplayContextMenuOpen}
          onClose={handleMenuClose}
        />
      )}
    </>
  )
}
