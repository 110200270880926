import { useTranslation } from 'react-i18next'
import { Divider } from '@mui/material'
import { Root, Title, Wrapper, Content } from './Privacy.styled'

export const Privacy = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'privacy' })

  return (
    <Root>
      <Wrapper>
        <Divider />
        <Title variant='h1'>{t('title')}</Title>
        <Content>
          <p>
            Die <em>sporttotal.tv gmbh</em> (nachfolgend kurz „wir“ genannt)
            nimmt den Schutz Ihrer personenbezogenen Daten (nachfolgend auch
            kurz „Daten“ genannt) sehr ernst. Mit den folgenden
            Datenschutzhinweisen möchten wir Sie daher über die Art und Weise
            sowie den Umfang der Verarbeitung personenbezogener Daten auf
            unserer Internetseite coaching.sporttotal.tv (nachfolgend
            „Internetseite“ genannt“) sowie bei der Nutzung der von uns auf der
            coaching.sporttotal.tv-Plattform angebotenen Dienste (nachfolgend
            „Dienste“ genannt) informieren. Diese Informationen sind für Sie
            jederzeit unter{' '}
            <a href='/privacy'>www.coaching.sporttotal.tv/datenschutz</a>{' '}
            abrufbar. Diese Datenschutzhinweise beziehen sich allein auf die
            vorstehend genannte Internetseite und die Dienste auf unserer
            coaching.sporttotal.tv-Plattform. Für andere sporttotal-Angebote und
            Internetseiten sind die dort aufgeführten Datenschutzhinweise
            einschlägig.
          </p>
          <p>
            Unmittelbar verantwortlich für die Datenverarbeitung anlässlich der
            Nutzung der Internetseite und Verantwortlicher im Sinne der
            EU-Datenschutz-Grundverordnung (DS-GVO) ist:
          </p>
          <p>sporttotal.tv gmbh, Am Coloneum 2, 50829 Köln, Deutschland</p>
          <p>
            Die sporttotal.tv gmbh wird vertreten durch die Geschäftsführer Herr
            Peter Lauterbach und Herr Oliver Grodowski
          </p>
          <p>
            Telefax: +49 221 788 77 788 E-Mail:{' '}
            <a href='mailto:info@sporttotal.tv'>info@sporttotal.tv</a>
          </p>
          <p>
            Datenschutzbeauftragter des Verantwortlichen: Tobias Erdmann als
            Datenschutzbeauftragter der sporttotal.tv gmbh sicdata
            Unternehmensberatung, Heiligenstock 34d, 42697 Solingen, Deutschland
          </p>
          <p>
            Telefax: +49 212 73 87 24-0 E-Mail:{' '}
            <a href='mailto:erdmann@sicdata.de'>erdmann@sicdata.de</a>
          </p>
          <p>
            <strong>
              Bei allen Fragen und Anregungen zum Datenschutz wenden Sie sich am
              besten direkt an unseren Datenschutzbeauftragten.
            </strong>
          </p>
          <p>1. Was sind personenbezogene Daten?</p>
          <p>
            Gegenstand des Datenschutzes sind personenbezogene Daten.
            Personenbezogene Daten sind alle Informationen, die sich auf eine
            identifizierte oder identifizierbare natürliche Person („betroffene
            Person“) beziehen (Art. 4 Nr. 1 DS-GVO).
          </p>
          <p>
            2. Welche Daten erheben wir wofür und was geschieht mit Ihren Daten?
          </p>
          <p>
            2.1. Allgemeine Datenverarbeitung beim Aufruf unserer Internetseite{' '}
            <a href='http://www.coaching.sportttotal.tv'>
              www.coaching.sportttotal.tv
            </a>
          </p>
          <p>
            Beim Besuch unserer Internetseite werden stets, d.h. auch bei einem
            einfachen Besuch und ohne dass Sie konkrete Dienste im Einzelnen
            nutzen, folgende Daten verarbeitet, ohne dass wir dabei Rückschlüsse
            auf Ihre Person ziehen:
          </p>
          <ul>
            <li>die zuvor besuchte Internetseite (sogenannte Referrer-URL);</li>
            <li>
              die einzelnen Seiten unserer Internetseite, welche von Ihnen
              angesteuert werden;
            </li>
            <li>das Datum und die Uhrzeit des Zugriffs;</li>
            <li>
              die Internet-Protokoll-Adresse (IP-Adresse) des zugreifenden
              Endgerätes;
            </li>
            <li>
              die Art des Endgerätes, mit dem der Zugriff erfolgt (z.B.
              Computer, Mobiltelefon etc.);
            </li>
            <li>
              der Browser und das Betriebssystem des Endgeräts, einschließlich
              der jeweiligen Versionsnummer und der dort eingestellten Sprache.
            </li>
          </ul>
          <p>Diese Informationen werden benötigt, um:</p>
          <ul>
            <li>die Inhalte unserer Internetseite korrekt auszuliefern;</li>
            <li>
              die Inhalte unserer Internetseite zu optimieren, z.B. den Inhalt
              für die Ansicht auf einem mobilen Endgerät anzupassen;
            </li>
            <li>
              die dauerhafte Funktionsfähigkeit unserer
              informationstechnologischen Systeme und der Technik unserer
              Internetseite zu gewährleisten; sowie
            </li>
            <li>
              um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur
              Strafverfolgung notwendigen Informationen bereitzustellen.
            </li>
          </ul>
          <p>
            Wir verarbeiten diese Daten, solange es für die vorgenannten Zwecke
            erforderlich ist. Die Verarbeitung Ihrer Daten erfolgt somit zur
            Sicherstellung einer bestmöglichen Darstellung und Integrität der
            Internetseite und erfolgt daher in unserem diesbezüglichen,
            berechtigten Interesse gemäß Art. 6 Abs. 1 Satz 1 Buchstabe f)
            DS-GVO.
          </p>
          <p>
            Bei diesen Datenverarbeitungen setzen wir unseren Host-Provider
            Leaseweb Deutschland GmbH (Leaseweb, Kleyerstraße 75-87, 60326
            Frankfurt am Main, Germany)) als Auftragsverarbeiter i.S.d. Art. 28
            ff. DS-GVO ein.
          </p>
          <p>2.2. Nutzung technisch notwendiger Cookies</p>
          <p>
            Darüber hinaus nutzen wir für die Bereitstellung unserer
            Internetseite und unserer Dienste technisch notwendige Cookies.
            Diese Cookies sind notwendig, damit Sie durch die Seiten navigieren
            und wesentliche Funktionen nutzen können. Diese Datenverarbeitungen
            erfolgen daher in unserem diesbezüglichen, berechtigten Interesse
            gemäß Art. 6 Abs. 1 Satz 1 Buchstabe f) DS-GVO. Die detaillierte
            Auskunft über die Nutzung von Cookies finden Sie in unserer
            Cookie-Erklärung. Diese ist über den Link „Cookie-Einstellungen“ im
            unteren Bereich unserer Webseite sowie über den Link{' '}
            <a href='/cookie'>https://coaching.sporttotal.tv/cookie</a>{' '}
            einsehbar.
          </p>
          <p>
            2.3. Nutzung von technisch nicht notwendigen Cookies mit Ihrer
            Einwilligung
          </p>
          <p>
            Die Nutzung technisch nicht notwendiger Cookies ist nur mit Ihrer
            Einwilligung zulässig. Wenn Sie unsere Webseite zum ersten Mal
            besuchen, erscheint ein so genanntes Cookie-Banner, über das Sie uns
            Ihre Einwilligung für die Nutzung bestimmter Cookies erteilen
            können. Die detaillierte Auskunft über die Nutzung von Cookies und
            über Ihre Einwilligung finden Sie in unserer Cookie-Erklärung. Diese
            ist über den Link „Cookie-Einstellungen“ im unteren Bereich unserer
            Webseite sowie über den Link{' '}
            <a href='/cookie'>https://coaching.sporttotal.tv/cookie</a>{' '}
            einsehbar. In der Cookie-Erklärung können Sie auch jederzeit Ihre
            Einwilligung ändern oder widerrufen.
          </p>
          <p>
            Wenn Sie uns Ihre Einwilligung zur Nutzung bestimmter Cookies
            erteilen, so erfolgen die entsprechenden Datenverarbeitungen mit
            Ihrer Einwilligung und beruhen folglich auf Art. 6 Abs. 1 Satz 1
            Buchstabe a) DS-GVO.
          </p>
          <p>
            Die Zeiträume der Speicherung der Daten finden Sie ebenfalls
            detailliert in der Cookie-Erklärung aufgeführt.
          </p>
          <p>2.4. Daten, die wir verarbeiten, wenn Sie unsere Dienste nutzen</p>
          <p>
            Wir bieten Ihnen insbesondere über die
            coaching.sporttotal.tv-Website sowie die sporttotal.tv-Apps
            verschiedene Dienste im Zusammenhang mit der Zugänglichmachung von
            Inhalten, einschließlich Rundfunkangeboten an.
          </p>
          <p>
            2.4.1 Daten, die wir verarbeiten, wenn Sie sich als Nutzer für einen
            unserer registrierungspflichtigen Dienste registrieren und anmelden
          </p>
          <p>
            Die Nutzung bestimmter Dienste, z. B. entgeltlicher Dienste (z.B.
            Pay-per-View, Abo) und personalisierter Dienste (z. B. Watchlist,
            Empfehlungen), erfordert eine Registrierung für ein
            coaching.sporttotal.tv-Benutzerkonto durch Sie als Nutzer. Diese
            Dienste können zudem nur erbracht werden, wenn Sie angemeldet sind.
            Dabei verarbeiten wir die folgenden Daten.
          </p>
          <p>
            2.4.1.1. Registrierung für das zur Nutzung erforderliche
            coaching.sporttotal.tv-Benutzerkonto
          </p>
          <p>
            Die Registrierung erfordert mindestens die Angabe des Namens, einer
            gültigen E-Mail-Adresse, des Geburtsdatums, des Geschlechts sowie
            eines frei wählbaren Passworts.
          </p>
          <p>
            Diese Daten benötigen wir, um Sie als unseren Vertragspartner zu
            identifizieren und die Erfüllung des Vertrages und die
            Bereitstellung des jeweiligen Dienstes für Sie als registrierten
            Nutzer zu ermöglichen. Die Verarbeitung der vorstehenden Daten
            erfolgt somit zur Vertragserfüllung und beruht folglich auf Art. 6
            Abs. 1 Satz 1 Buchstabe b) DS-GVO.
          </p>
          <p>
            Darüber hinaus können Sie uns freiwillig weitere Angaben machen, die
            Ihrem Benutzerprofil zugeordnet werden und die für andere Nutzer
            unserer Dienste einsehbar sind. Dies sind:
          </p>
          <ul>
            <li>Adresse</li>
            <li>Telefonnummer</li>
            <li>User profile page</li>
            <li>Biographische Daten</li>
            <li>Nutzerbild</li>
            <li>Nutzersprache</li>
            <li>Social-Media-Profile</li>
          </ul>
          <p>
            Die Verarbeitung der vorstehenden Daten erfolgt freiwillig und mit
            Ihrer Einwilligung und beruht folglich auf Art. 6 Abs. 1 Satz 1
            Buchstabe a) DS-GVO.
          </p>
          <p>
            Wir speichern die vorstehenden Daten bis zur Löschung Ihres
            Benutzerkontos. Sie können Ihr Konto löschen, indem Sie innerhalb
            Ihres Benutzerkontos „Einstellungen“ &gt; „Account löschen“ folgen.
          </p>
          <p>
            2.4.1.2. Anmeldung in Ihrem coaching.sporttotal.tv-Benutzerkonto
          </p>
          <p>
            Die Anmeldung in Ihrem Benutzerkonto erfordert die Angabe des
            (Benutzer-)Namens sowie Ihres Passwortes.
          </p>
          <p>
            Diese Daten benötigen wir, um Sie als unseren Vertragspartner zu
            identifizieren und die Erfüllung des Vertrages und die
            Bereitstellung des jeweiligen Dienstes für Sie als registrierten
            Nutzer zu ermöglichen. Die Verarbeitung der vorstehenden Daten
            erfolgt somit zur Vertragserfüllung und beruht folglich auf Art. 6
            Abs. 1 Satz 1 Buchstabe b) DS-GVO.
          </p>
          <p>
            Wir verarbeiten die vorstehenden Daten lediglich im Rahmen und zum
            Zeitpunkt der Anmeldung.
          </p>
          <p>
            2.4.2 Daten, die wir verarbeiten, wenn Sie sich für unser „User
            Generated Content“-Angebot registrieren, anmelden und dies nutzen
          </p>
          <p>
            Wir bieten Nutzern die Möglichkeit, eigene Bewegtbild-Inhalte auf
            die coaching.sporttotal.tv-Plattform hochzuladen („User Generated
            Content“-Angebot). Die Nutzung unseres „User Generated
            Content“-Angebotes erfordert eine Registrierung für ein
            coaching.sporttotal.tv-Benutzerkonto. Registrieren können sich
            Verbände, Vereine und „Supporter“ (Einzelpersonen). Dabei ist es
            auch bei einer Registrierung als Verband oder Verein erforderlich,
            eine natürliche Person als Kontaktperson zu benennen. Die dabei
            erhobenen Daten der Kontaktperson stellen somit personenbezogene
            Daten dar. Das Angebot kann zudem nur genutzt werden, wenn Sie als
            Nutzer angemeldet sind.
          </p>
          <p>2.4.2.2 Registrierung für das „User Generated Content“-Angebot</p>
          <p>
            Die Registrierung erfordert mindestens die Angabe des Namens, einer
            gültigen E-Mail-Adresse sowie eines frei wählbaren Passworts.
          </p>
          <p>
            Diese Daten benötigen wir, um Sie als unsere Kontaktperson zu
            identifizieren und die Erfüllung des Vertrages und die
            Bereitstellung des jeweiligen Dienstes für Sie bzw. Ihre
            Organisation als registrierten Nutzer zu ermöglichen. Die
            Verarbeitung der vorstehenden Daten erfolgt somit zur
            Vertragserfüllung beruht folglich auf Art. 6 Abs. 1 Satz 1 Buchstabe
            b) DS-GVO.
          </p>
          <p>
            Darüber hinaus können Sie uns freiwillig weitere Angaben machen, die
            Ihrem Benutzerprofil zugeordnet werden und die für andere Nutzer
            unserer Dienste einsehbar sind. Dies sind:
          </p>
          <ul>
            <li>Name</li>
            <li>Adresse</li>
            <li>Land</li>
            <li>Telefonnummer</li>
            <li>Nutzersprache</li>
          </ul>
          <p>
            Die Verarbeitung der vorstehenden Daten erfolgt freiwillig und mit
            Ihrer Einwilligung und beruht folglich auf Art. 6 Abs. 1 Satz 1
            Buchstabe a) DS-GVO.
          </p>
          <p>
            Wir speichern die vorstehenden Daten bis zur Löschung Ihres
            Benutzerkontos. Sie können Ihr Konto löschen, indem Sie innerhalb
            Ihres Benutzerkontos „Einstellungen“ &gt; „Account löschen“ folgen.
          </p>
          <p>2.4.2.3 Anmeldung für das „User Generated Content“-Angebot</p>
          <p>
            Die Anmeldung in Ihrem Benutzerkonto erfordert die Angabe des
            (Benutzer-)Namens sowie Ihres Passwortes.
          </p>
          <p>
            Diese Daten benötigen wir, um Sie als unseren Vertragspartner zu
            identifizieren und die Erfüllung des Vertrages und die
            Bereitstellung des jeweiligen Dienstes für Sie als registrierten
            Nutzer zu ermöglichen. Die Verarbeitung der vorstehenden Daten
            erfolgt somit zur Vertragserfüllung und beruht folglich auf Art. 6
            Abs. 1 Satz 1 Buchstabe b) DS-GVO.
          </p>
          <p>
            Wir verarbeiten die vorstehenden Daten lediglich im Rahmen und zum
            Zeitpunkt der Anmeldung.
          </p>
          <p>2.4.2.4 Nutzung des „User Generated Content“-Angebots</p>
          <p>
            Wenn Sie unser „User Generated Content“-Angebot nutzen und
            Bewegtbilder auf unsere coaching.sporttotal.tv-Plattform hochladen,
            dann werden die hochgeladenen Dateien Ihrem Benutzerkonto
            zugeordnet. Darüber hinaus erheben wir den Zeitpunkt des Uploads und
            speichern diese Daten. Diese werden Ihrem Benutzerkonto zugeordnet.
          </p>
          <p>
            Diese Daten benötigen wir, um mögliche Rechtsverletzungen
            nachvollziehen, beseitigen und verfolgen zu können. Die Verarbeitung
            der vorstehenden Daten erfolgt somit in unserem berechtigten
            Interesse und beruht folglich auf Art. 6 Abs. 1 Satz 1 Buchstabe f)
            DS-GVO.
          </p>
          <p>
            Wir speichern die vorstehenden Daten bis zur Löschung Ihres
            Benutzerkontos. Sie können Ihr Konto löschen, indem Sie indem Sie
            innerhalb Ihres Benutzerkontos „Einstellungen“ &gt; „Account
            löschen“ folgen.
          </p>
          <p>2.5. Verarbeitung von Daten bei der Zahlungsabwicklung</p>
          <p>
            Im Rahmen unserer Paid-Content-Dienste stehen Ihnen zur
            Zahlungsabwicklung verschiedene Zahlungsmethoden zur Verfügung. Bei
            diesen werden neben dem jeweils zu zahlenden Betrag und einer
            pseudonymen Transaktions-Nummer verschiedene Daten übermittelt.
            Diese sind im unter 2.5.1 ff. beschrieben.
          </p>
          <p>
            Die Verarbeitung der Daten erfolgt insoweit zur Zahlungsabwicklung
            im Hinblick auf vertragsgegenständliche Leistungen und beruht auf
            Art. 6 Abs. 1 Satz 1 Buchstabe b) DS-GVO.
          </p>
          <p>
            Eine Weitergabe Ihrer Daten kann auch erfolgen, falls dies zum
            Schutz unserer Rechte, der Rechte des Zahlungsdienstleisters, zur
            Durchsetzung der Bedingungen des Zahlungsdienstleisters oder um
            Anforderungen von Strafverfolgungsbehörden zu entsprechen,
            erforderlich ist. Eine derartige Weitergabe beruht auf Art. 6 Abs. 1
            Satz 1 Buchstabe f) DS-GVO.
          </p>
          <p>
            Soweit dies gesetzlich vorgeschrieben ist, speichern wir die Daten
            der Zahlungsabwicklung mindestens für die gesetzlich vorgeschriebene
            Mindestdauer von 10 Jahren (§ 147 Abs. 3 der Abgabenordnung). Soweit
            keine längere Speicherdauer erforderlich ist, werden diese
            anschließend gelöscht.
          </p>
          <p>2.5.1 Kreditkarte oder SEPA-Lastschrift</p>
          <p>
            Bei der Bezahlung mit Kreditkarte oder per SEPA-Lastschrift werden
            automatisch personenbezogene Daten an den Zahlungsdienst STRIPE
            übermittelt. Die Betreibergesellschaft des Zahlungsdienstes ist die
            Stripe, Inc., 510 Townsend Street, San Francisco, CA 94103, USA
            (nachfolgend „STRIPE“ genannt).
          </p>
          <p>
            Bei der Zahlung mit Kreditkarte handelt es sich bei den zur
            Abwicklung der Zahlung erforderlichen personenbezogenen Daten um
            Vor- und Nachname des Karteninhabers, Kartennummer, CVC (Card
            Validation Code) sowie Gültigkeitsdatum.
          </p>
          <p>
            Bei der Zahlung per SEPA-Lastschrift handelt es sich bei den zur
            Abwicklung der Zahlung erforderlichen personenbezogenen Daten um
            Vor- und Nachname des Kontoinhabers und die Bankkontodaten in Form
            der IBAN. Als Teil der Zahlungsbestätigung müssen Sie zudem ein
            Mandat akzeptieren, das uns die Autorisation gibt, Ihr Konto zu
            belasten.
          </p>
          <p>
            Die Datenverarbeitung während des Zahlungsvorgangs erfolgt dort
            durch STRIPE als Verantwortlichen und gemäß dessen
            Datenschutzhinweisen, die sie auf der folgenden Internetseite
            einsehen können:{' '}
            <a href='https://stripe.com/de/privacy'>
              https://stripe.com/de/privacy
            </a>
          </p>
          <p>
            Nach Abschluss bzw. im Rahmen des Zahlungsvorgangs erhalten wir die
            folgenden Daten:
          </p>
          <ul>
            <li>eMail-Adresse</li>
            <li>die letzten 4 Ziffern der Kreditkarte</li>
            <li>die ID der ausgeliehenen Übertragung</li>
            <li>den Preis, den Ihnen belastet wurde</li>
          </ul>
          <p>2.6. Daten, die wir verarbeiten, wenn Sie uns kontaktieren</p>
          <p>
            Wenn Sie uns über die von uns angebotenen Kontaktmöglichkeiten,
            insbesondere über unsere angegebenen E-Mail-Adressen, Telefonnummern
            und Faxnummern, kontaktieren, verarbeiten wir die von Ihnen uns
            mitgeteilten Daten. Dies können etwa der Gegenstand Ihrer
            Kontaktanfrage, Ihre Anrede, Ihr (akademischer) Titel, Ihr Name,
            Ihre (Mobil-)Telefonnummer oder Ihre E-Mail-Adresse sein. Diese
            Daten verwenden wir zur Abwicklung Ihrer Kontaktanfrage.
          </p>
          <p>
            Die Verarbeitung dieser Daten erfolgt daher aufgrund Ihrer
            Einwilligung und somit auf Art. 6 Abs. 1 Satz 1 Buchstabe a) DS-GVO.
            Sie können diese Einwilligung jederzeit widerrufen.
          </p>
          <p>
            Die Daten speichern wir so lange, bis Ihre Kontaktanfrage
            abschließend bearbeitet ist.
          </p>
          <p>3. Wie gehen wir mit Ihren Daten um?</p>
          <p>
            Bei der Datenverarbeitung ist es unser Ziel, im Rahmen des
            jeweiligen Nutzungszweckes stets ein möglichst hohes
            Sicherheitsniveau zu erreichen. Obwohl ein absoluter Schutz nicht
            garantiert werden kann, haben wir daher Sicherheitsvorkehrungen
            getroffen, um Ihre Daten zu schützen.
          </p>
          <p>
            Dazu gehört etwa, dass wir Ihre Daten stets nur verschlüsselt
            übertragen. Hierzu verwenden wir das Codierungssystem SSL (Secure
            Socket Layer) bzw. TLS (Transport Layer Security), welches
            verhindern soll, dass die Datenströme von Dritten abgefangen und
            Ihre Daten im Klartext eingesehen werden können. Die Verwendung des
            SSL-Codierungssystems erkennen Sie am „https://“ in der Adresszeile
            Ihres Browsers sowie bei gängigen Browsern daran, dass neben der
            Adresszeile ein entsprechendes Schloss-Symbol erscheint. So haben
            Sie Gewissheit, dass Ihre Daten sicher an uns übermittelt werden.
          </p>
          <p>4. Wie lange bewahren wir Ihre Daten auf?</p>
          <p>
            Wir verarbeiten und speichern personenbezogene Daten für den
            Zeitraum, der zur Erreichung des angegebenen Zwecks (siehe Ziffer 2)
            erforderlich ist.
          </p>
          <p>
            Nach Erledigung des Zwecks, oder wenn Sie wünschen, dass Ihre
            personenbezogenen Daten gelöscht werden, werden wir diese Daten
            löschen, es sei denn wir sind zu deren Aufbewahrung gesetzlich
            berechtigt (etwa zu Beweiszwecken im Rahmen der Abwicklung unseres
            Vertragsverhältnisses) oder verpflichtet (etwa aus steuerlichen
            Gründen). Diese Speicherdauer kann länger sein, als dies für den
            ursprünglichen Verwendungszweck (Regel-Speicherdauer) erforderlich
            war. Im Falle der Aufbewahrung von Abrechnungsbelegen z.B. sind wir
            zur Speicherung über einen Zeitraum von 10 Jahren verpflichtet (§
            147 Abs. 3 der Abgabenordnung).
          </p>
          <p>
            Ist der ursprüngliche Verwendungszweck erreicht oder entfallen,
            werden wir die personenbezogenen Daten nicht für eine weitere
            Verarbeitung nutzen. Mit Wegfall der Berechtigung bzw. Ablauf der
            gesetzlichen Aufbewahrungspflichten löschen wir die Daten sodann
            endgültig.
          </p>
          <p>5. Welche Rechte haben Sie?</p>
          <p>
            Bezüglich der Nutzung Ihrer Daten haben Sie die nachfolgend
            genannten Rechte. Diese Rechte können Sie gegenüber uns als den
            Verantwortlichen geltend machen. Gerne können Sie sich hierfür
            direkt an unseren Datenschutzbeauftragten wenden.
          </p>
          <p>5.1. Recht auf Auskunft</p>
          <p>
            Sie haben das Recht, jederzeit von uns unentgeltliche Auskunft über
            die zu Ihrer Person gespeicherten personenbezogenen Daten und eine
            Kopie dieser Auskunft zu erhalten. Ferner haben Sie das Recht auf
            Auskunft über die in Art. 15 Abs. 1 DS-GVO genannten Informationen.
          </p>
          <p>
            Darüber hinaus steht Ihnen ein Auskunftsrecht darüber zu, ob
            personenbezogene Daten an ein Drittland oder an eine internationale
            Organisation übermittelt wurden. Sofern dies der Fall ist, steht
            Ihnen im Übrigen das Recht zu, Auskunft über die geeigneten
            Garantien im Zusammenhang mit der Übermittlung zu erhalten.
          </p>
          <p>Ihr Auskunftsrecht beruht im Wesentlichen auf Art. 15 DS-GVO.</p>
          <p>
            5.2. Recht auf Berichtigung falscher und Ergänzung unvollständiger
            Daten
          </p>
          <p>
            Sie haben das Recht, die unverzügliche Berichtigung Sie betreffender
            unrichtiger personenbezogener Daten zu verlangen. Ferner steht Ihnen
            das Recht zu, unter Berücksichtigung der Zwecke der Verarbeitung,
            die Vervollständigung unvollständiger personenbezogener Daten – auch
            mittels einer ergänzenden Erklärung – zu verlangen.
          </p>
          <p>
            Ihr Recht auf Berichtigung falscher und Ergänzung unvollständiger
            Daten beruht auf Art. 16 DS-GVO.
          </p>
          <p>5.3. Recht auf Datenlöschung (Recht auf Vergessenwerden)</p>
          <p>
            Sie haben das Recht, von uns zu verlangen, dass die Sie betreffenden
            personenbezogenen Daten unverzüglich gelöscht werden, sofern einer
            der in Art. 17 Abs. 1 DS-GVO aufgeführten Gründe zutrifft und soweit
            die Verarbeitung nicht aus den in Art. 17 Abs. 3 DS-GVO genannten
            Gründen erforderlich ist.
          </p>
          <p>Ihr Recht auf Datenlöschung beruht auf Art. 17 DS-GVO.</p>
          <p>5.4. Recht auf Einschränkung der Verarbeitung</p>
          <p>
            Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu
            verlangen, wenn eine der in Art. 18 Abs. 1 DS-GVO genannten
            Voraussetzungen gegeben ist.
          </p>
          <p>
            Ihr Recht auf Einschränkung der Verarbeitung beruht auf Art. 18
            DS-GVO.
          </p>
          <p>5.5. Recht auf Datenübertragbarkeit</p>
          <p>
            Sie haben das Recht, unter den Voraussetzungen des Art. 20 Abs. 1
            DS-GVO die Sie betreffenden personenbezogenen Daten, welche uns
            durch Sie bereitgestellt wurden, in einem strukturierten, gängigen
            und maschinenlesbaren Format zu erhalten. Sie haben weiter das
            Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung
            durch uns, zu übermitteln. Dies gilt nicht, sofern die Verarbeitung
            für die Wahrnehmung einer Aufgabe erforderlich ist, die im
            öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt
            erfolgt, welche uns übertragen wurde.
          </p>
          <p>
            Ferner haben Sie bei der Ausübung Ihres Rechts auf
            Datenübertragbarkeit das Recht, zu erwirken, dass die
            personenbezogenen Daten direkt von einem Verantwortlichen an einen
            anderen Verantwortlichen übermittelt werden, soweit dies technisch
            machbar ist und soweit Rechte und Freiheiten anderer Personen nicht
            beeinträchtigt werden.
          </p>
          <p>
            Ihr diesbezügliches Recht auf Datenübertragbarkeit beruht auf Art.
            20 DS-GVO.
          </p>
          <p>5.6. Recht auf Widerspruch</p>
          <p>
            Sie haben das Recht, bei Vorliegen der Voraussetzungen des Art. 21
            DS-GVO jederzeit gegen die Verarbeitung Sie betreffender
            personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben
            e) oder f) DS-GVO erfolgt, Widerspruch einzulegen. Dies gilt auch
            für ein auf diese Bestimmungen gestütztes Profiling.
          </p>
          <p>Ihr Widerspruchsrecht beruht auf Art. 21 DS-GVO.</p>
          <p>
            5.7. Automatisierte Entscheidungen im Einzelfall einschließlich
            Profiling
          </p>
          <p>
            Sie haben das Recht, nicht einer ausschließlich auf einer
            automatisierten Verarbeitung – einschließlich Profiling – beruhenden
            Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche
            Wirkung entfaltet oder Sie in ähnlicher Weise erheblich
            beeinträchtigt, sofern die Entscheidung nicht für den Abschluss oder
            die Erfüllung eines Vertrags zwischen Ihnen und uns erforderlich
            ist, oder aufgrund von Rechtsvorschriften der Union oder der
            Mitgliedstaaten, denen wir unterliegen, zulässig ist und diese
            Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte
            und Freiheiten sowie berechtigten Interessen enthalten oder mit
            Ihrer ausdrücklichen Einwilligung erfolgt.
          </p>
          <p>
            Ist die Entscheidung für den Abschluss oder die Erfüllung eines
            Vertrags zwischen Ihnen und uns erforderlich oder erfolgt sie mit
            Ihrer ausdrücklichen Einwilligung, treffen wir angemessene
            Maßnahmen, um Ihre Rechte und Freiheiten sowie Ihre berechtigten
            Interessen zu wahren, wozu mindestens das Recht auf Erwirkung des
            Eingreifens einer Person seitens unseres Unternehmens, auf Darlegung
            des eigenen Standpunkts und auf Anfechtung der Entscheidung gehört.
          </p>
          <p>
            Möchte Sie Rechte mit Bezug auf automatisierte Entscheidungen
            geltend machen, können Sie sich hierzu jederzeit an unseren
            Datenschutzbeauftragten oder einen anderen unserer Mitarbeiter
            wenden.
          </p>
          <p>Diese Rechte beruhen auf Art. 22 DS-GVO.</p>
          <p>
            5.8. Recht auf Widerruf einer datenschutzrechtlichen Einwilligung
          </p>
          <p>
            Sie haben das Recht, eine Einwilligung zur Verarbeitung
            personenbezogener Daten jederzeit ganz oder teilweise zu widerrufen.
          </p>
          <p>
            Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der
            aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
            nicht berührt.
          </p>
          <p>
            Ihr Recht auf Widerruf einer erteilten, datenschutzrechtlichen
            Einwilligung beruht auf Art. 7 Abs. 3 DS-GVO.
          </p>
          <p>5.9. Beschwerderecht bei der Aufsichtsbehörde</p>
          <p>
            Sie haben das Recht, bei der Aufsichtsbehörde eine Beschwerde
            einzureichen. Dieses Recht beruht auf Art. 56 Abs. 2 DS-GVO.
          </p>
          <p>6. Änderungen dieser Datenschutzhinweise</p>
          <p>
            Die Nutzung erhobener Daten wird in den Datenschutzhinweisen
            erläutert, die zu dem Zeitpunkt aktuell sind, zu dem die Daten
            erhoben werden.
          </p>
          <p>
            Wir behalten uns vor, die Datenschutzhinweise zu ändern, um sie an
            eine geänderte Sach- und Rechtslage anzupassen. In diesem Falle
            veröffentlichen wir die jeweils neue und sodann aktuelle Fassung
            dieser Datenschutzhinweise auf unserer Internetseite. Zu gegebenem
            Anlass werden wir auf etwaige Änderungen dieser Datenschutzhinweise
            an geeigneter Stelle hinweisen. Dies gilt insbesondere dann, wenn
            wir beabsichtigen, bereits erhobene Daten abweichend von der
            ursprünglichen Zwecksetzung zu nutzen.
          </p>
          <p>
            Beruht die Nutzung Ihrer personenbezogenen Daten auf Ihrer
            Einwilligung, so nutzen wir ungeachtet einer zwischenzeitlichen
            Änderung dieser Datenschutzhinweise Ihre Daten stets nur in dem
            Umfang, in den Sie eingewilligt haben. Gegebenenfalls werden wir Sie
            in diesem Fall um eine erneute Einwilligung entsprechend einer
            beabsichtigten Änderung der Datennutzung bitten.
          </p>
          <p>&nbsp;</p>
          <p>
            <strong>Pay-per-View Bedingungen</strong>
          </p>
          <p>Pay-per-View – Angebote</p>
          <p>
            Übertragung von LIVE-Veranstaltungen und/oder Aufzeichnungen ansehen
          </p>
          <p>
            Die ausgewählte Übertragung ist kostenpflichtig. Du hast durch die
            Bezahlung des angegebenen Betrages 30 Tage Zeit, dir die
            LIVE-Übertragung und/oder Aufzeichnung (On-Demand) anzuschauen. Aus
            technischen Gründen kann die Übertragung derzeit nur im Webbrowser
            gewährleistet werden. Ein Empfang über TV-Applikationen oder unseren
            mobilen Apps ist wegen Beschränkungen bei In-App Käufen derzeit
            nicht möglich.
          </p>
          <p>
            Mit dem Klicken auf den "Accept"-Button bestätigst du, dass dir
            unsere AGB und Datenschutzerklärung zur Verfügung gestellt wurden
            und du die Möglichkeit hattest diese zu Kenntnis zu nehmen. Nach dem
            Klicken auf den "Accept"-Button leiten wir dich an unseren
            Zahlungsdienstleister STRIPE weiter.
          </p>
          <p>
            Im Falle des Spielausfalls und einer etwaigen Rückerstattung der für
            die Bereitstellung des Live- bzw. On-Demand-Streams gezahlten
            Vergütung wird keine Bearbeitungsgebühr von sporttotal.tv
            einbehalten. Mit dem Abruf eines Pay-per-View-Inhalts und oder mit
            der Bereitstellung von abonnierten Inhalten beginnt die
            sporttotal.tv gmbh mit der Ausführung des Vertrages vor Ablauf der
            Widerrufsfrist. Dem stimmst du zu und bestätigst deine Kenntnis
            davon, dass du durch diese Zustimmung mit Beginn der Ausführung des
            Vertrags dein Widerrufsrecht verlierst.
          </p>
        </Content>
      </Wrapper>
    </Root>
  )
}
