import * as yup from 'yup'
import { OrganizationUserInviteCreatePayload } from '../components/OrganizationUserInviteModal/OrganizationUserInviteModal.interface'

export const organizationUserInviteSchema = yup
  .object()
  .shape<Record<keyof OrganizationUserInviteCreatePayload, any>>({
    first_name: yup.string().required('pages:admin.modal.error.name'),
    last_name: yup.string().required('pages:admin.modal.error.surname'),
    email: yup
      .string()
      .required('pages:admin.modal.error.email.required')
      .email('pages:admin.modal.error.email.invalid'),
    role: yup
      .string()
      .notOneOf([''], 'pages:admin.modal.error.role')
      .required('pages:admin.modal.error.role'),
  })
