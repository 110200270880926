import { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
// components
import { InfoTable } from 'modules/team-player/components'
// hooks
import {
  useTeamPlayerTransferDelete,
  useTeamPlayerTransferList,
} from 'modules/team-player/hooks'
import { isTransfer, getTransferValue } from 'modules/team-player/utils'
// constants
import { COLUMN_TEMPLATES, PlayersView } from 'modules/team-player/constants'
// types
import type {
  InfoTableElement,
  InfoTableHeaderElement,
  InfoItem,
} from 'modules/team-player/components'

import { GenericViewProps as TransferViewProps } from 'modules/team-player/types'
import { TransferModal } from './TransferModal'

export const TransferView: FC<TransferViewProps> = () => {
  // hooks
  const { t } = useTranslation('components', { keyPrefix: 'teamPlayers' })

  const tableRows: InfoTableElement[] = useMemo(
    () => [
      {
        type: 'checkbox',
        render: ({ item, Component }) => <Component item={item} />,
      },
      {
        type: 'stickyLeftText',
        render: ({ item, Component }) => (
          <Component>
            {isTransfer(item) &&
              `${item.player?.first_name} ${item.player?.last_name}`}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component>
            {isTransfer(item) &&
              `${item.agent?.firstname} ${item.agent?.lastname}`}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component>{isTransfer(item) && item.option}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component>{isTransfer(item) && item.transfer_status}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component>
            {isTransfer(item) &&
              `${item.loan}${item.loan_currency?.currency_symbol ?? ''}`}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component>
            {isTransfer(item) &&
              `${item.asking_price}${
                item.asking_price_currency?.currency_symbol ?? ''
              }`}
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ item, Component }) => (
          <Component>{isTransfer(item) && getTransferValue(item)}</Component>
        ),
      },
      {
        type: 'menu',
        render: ({ item, Component }) => <Component item={item} />,
      },
    ],
    []
  )

  const tableHeaderRows: InfoTableHeaderElement[] = useMemo(
    () => [
      {
        type: 'actions',
        render: ({ Component }) => (
          <Component
            translation={{
              main: t('table.transfer.selected'),
              secondary: t('table.transfer.action'),
            }}
          />
        ),
      },
      {
        type: 'stickyLeftText',
        render: ({ Component }) => (
          <Component>{t('table.transfer.name')}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>{t('table.transfer.agent')}</Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>
            <span className='full'>{t('table.transfer.option')}</span>
            <span className='short'>{t('table.transfer.optionShort')}</span>
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>
            <span className='full'>{t('table.transfer.status')}</span>
            <span className='short'>{t('table.transfer.statusShort')}</span>
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>
            <span className='full'>{t('table.transfer.loanStatus')}</span>
            <span className='short'>{t('table.transfer.loanStatusShort')}</span>
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>
            <span className='full'>{t('table.transfer.askingPrice')}</span>
            <span className='short'>
              {t('table.transfer.askingPriceShort')}
            </span>
          </Component>
        ),
      },
      {
        type: 'text',
        render: ({ Component }) => (
          <Component>
            <span className='full'>{t('table.transfer.value')}</span>
            <span className='short'>{t('table.transfer.valueShort')}</span>
          </Component>
        ),
      },
      {
        type: 'add',
        render: ({ Component }) => (
          <Component
            justify='flex-end'
            translation={{ main: t('table.transfer.add') }}
          />
        ),
      },
    ],
    [t]
  )

  const transformName = useCallback((item: InfoItem) => {
    return item && 'player' in item
      ? `${item.player?.first_name} ${item.player?.last_name}`
      : ''
  }, [])

  return (
    <InfoTable
      tableRows={tableRows}
      tableHeaderRows={tableHeaderRows}
      resource='transfer'
      transformName={transformName}
      Modal={TransferModal}
      useList={useTeamPlayerTransferList}
      useDelete={useTeamPlayerTransferDelete}
      useListArgs={{
        expand: [
          'player',
          'agent',
          'asking_price_currency',
          'loan_currency',
          'transfer_value_currency',
        ],
      }}
      columnsTemplate={COLUMN_TEMPLATES[PlayersView.TRANSFER]}
    />
  )
}
