import { useState, FC } from 'react'
import { TeamContext } from 'modules/team/context/'
import { ITeamContextProvider } from 'modules/team/context'
import { Team } from 'modules/team/types'

const TeamContextProvider: FC<ITeamContextProvider> = ({ children }) => {
  const [team, setTeam] = useState<Team>('teamA')

  return (
    <TeamContext.Provider value={{ team, setTeam }}>
      {children}
    </TeamContext.Provider>
  )
}

export { TeamContextProvider }
