import { css, styled, TextField, Typography } from '@mui/material'
import { Icon } from 'modules/core/components/Icon'
import { SearchInput } from 'modules/core/components/SearchInput'
import { IconButton } from 'modules/core/components/IconButton'
import { ComponentWithDisable } from 'modules/core/components/Custom'
import { Dots } from 'modules/core/components/Menu'
import { ActionCheckbox } from 'modules/core/styled'

export const Text = styled(Typography)`
  transition: color 0.3s;
  color: ${({ theme }) => theme.palette.primary.light};
`

export const Flex = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;
`

export const SettingsContainer = styled(Flex)`
  justify-content: flex-end;
`

export const SelectorWrapper = styled('div')`
  position: relative;
  margin-right: ${({ theme }) => theme.spacing(1)};
`

export const CameraControlWrapper = styled('div')`
  display: flex;
  margin-right: ${({ theme }) => theme.spacing(1.75)};
`

export const SelectorIntractable = styled('div')`
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(0.75)};
`

export const MatchSearchIcon = styled(Icon)`
  height: 19px;
`

export const SelectIcon = styled(Icon, {
  shouldForwardProp: prop => prop !== 'chevronUp',
})<{ chevronUp: boolean }>`
  transition: all 0.3s;
  transform: ${({ chevronUp }) => (chevronUp ? 'rotate(0)' : 'rotate(180deg)')};
  width: 10px;
  height: 10px;
`

export const SelectorModal = styled('div', {
  shouldForwardProp: prop => prop !== 'hidden',
})<{ hidden?: boolean }>`
  width: 232px;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  position: absolute;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 20%);
  z-index: 1;
  padding: ${({ theme }) => theme.spacing(1.5, 2)};
  transition: padding 0.3s, opacity 0.3s, transform 0s 0s;
  overflow: auto;
  right: 0;
  top: ${({ theme }) => theme.spacing(2.5)};

  ${({ hidden }) =>
    hidden &&
    css`
      transition: padding 0.3s, opacity 0.3s, transform 0s 0.3s;
      opacity: 0;
      transform: translateY(-100000px);
      overflow: hidden;
    `}
`

export const Search = styled(SearchInput)`
  min-width: 150px;
  background-color: ${({ theme }) => theme.palette.primary.main};
`

export const Divider = styled('div')`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.divider};
  margin: ${({ theme }) => theme.spacing(1.75, 0)};
`

export const ItemsList = styled('div')`
  max-height: 150px;
  overflow: auto;
`

export const ItemWrapper = ComponentWithDisable(styled('div', {
  shouldForwardProp: prop => prop !== 'disabled',
})`
  width: 99%;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
`)

export const Item = styled(Typography)`
  flex: 1;
  padding: ${({ theme }) => theme.spacing(0.5)};
  margin: ${({ theme }) => theme.spacing(1, 0)};
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s;
  user-select: none;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const ClipItem = styled('div')`
  padding: ${({ theme }) => theme.spacing(0.5)};
  margin: ${({ theme }) => theme.spacing(0, 0, 0.5)};
  cursor: pointer;
  transition: all 0.3s;
  user-select: none;
  font-size: 12px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`

export const ClipName = styled(Typography)`
  font-size: inherit;
`

export const ClipTime = styled('span')`
  color: ${({ theme }) => theme.palette.primary.light};
`

export const DateWrapper = styled('div')`
  position: relative;
`

export const InputTextField = styled(TextField)`
  background-color: unset;
  border: unset;

  & .MuiInputBase-input {
    height: 36px;
    padding: 0;
    font-size: 14px;
    width: 0;
  }

  & .MuiInputBase-formControl {
    border-radius: 0;
    padding: 0;
  }

  & .MuiInputAdornment-root {
    width: 30px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: unset;
  }
`

export const FilterWrapper = styled('div')`
  position: relative;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1)};
`

export const AnimatedChevronIcon = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'chevronUp',
})<{ chevronUp: boolean }>`
  transition: all 0.3s;
  transform: ${({ chevronUp }) => (chevronUp ? 'rotate(0)' : 'rotate(180deg)')};
`

export const IconWrapper = styled('div')`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ResetDate = styled(Typography)`
  padding: ${({ theme }) => theme.spacing(0, 2, 2, 0)};
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 12px;
  cursor: pointer;
  text-align: right;
`

export const StyledDots = styled(Dots)`
  & path {
    transition: all 0.3s;
    fill: ${({ theme }) => theme.palette.primary.light};
  }
`

export const StyledActionCheckbox = styled(ActionCheckbox)`
  margin-left: ${({ theme }) => theme.spacing(0.75)};
`
