import { styled } from '@mui/material'
import { Icon } from 'modules/core/components/Icon'

export const PositionsWrapper = styled('div')`
  position: relative;
`

export const PositionIconWrapper = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'top' && prop !== 'left' && prop !== 'scale',
})<{ top: string; left: string; scale: number }>`
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  transform: translate(-50%, -50%) scale(${({ scale }) => scale});
`

export const StyledIcon = styled(Icon)`
  width: inherit;
  height: inherit;
`
