import { useInfiniteQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { PlaylistListParams } from '@sporttotal-tv/dip-coaching-client'
import { PLAYLIST_PAGE_LIMIT } from 'modules/playlist/constants'
import { useQueryRetry } from 'modules/core/hooks'

export const PLAYLIST_CACHE_KEY = 'playlist-data'

export const usePlaylist = (options?: PlaylistListParams) => {
  const handleQueryRetry = useQueryRetry()

  return useInfiniteQuery(
    [PLAYLIST_CACHE_KEY, options],
    ({ pageParam = 0 }) =>
      ApiService.playlist.list({ ...options, page: pageParam }),
    {
      retry: handleQueryRetry,
      getNextPageParam: (lastPage, allPages) => {
        const allResults = allPages.reduce(
          (numberOfResults, page) =>
            numberOfResults + (page?.results?.length ?? 0),
          0
        )

        if (allResults >= lastPage.total) {
          return undefined
        }

        return allResults / PLAYLIST_PAGE_LIMIT
      },
    }
  )
}
