import { useContext, useMemo, useRef } from 'react'
// Hooks
import { useReduxSelector, useResizeObserver } from 'modules/core/hooks'
// Redux
import { selectLayoutCacheById } from 'modules/layout/redux'
// Context
import { ComponentInfoContext } from 'modules/generic/context'
// Components
import {
  EventsOverview,
  VideoPlayerControllerToolbar,
} from 'modules/video-player-controller/components'
import { ClipCreateForm } from 'modules/asset/components'

import { PlayerControllerContainer, Root } from './VideoPlayerController.styled'

export const VideoPlayerController = () => {
  const { componentId } = useContext(ComponentInfoContext)
  const componentCache = useReduxSelector(state =>
    selectLayoutCacheById(state, componentId)
  )
  const toolbarRef = useRef<HTMLDivElement>(null)
  const { height } = useResizeObserver(toolbarRef)

  const view = useMemo(
    // @ts-ignore
    () => componentCache?.view ?? 'event',
    [componentCache]
  )

  return (
    <Root>
      <div ref={toolbarRef}>
        <VideoPlayerControllerToolbar />
      </div>
      <PlayerControllerContainer toolbarHeight={height}>
        <EventsOverview isActive={view === 'event'} />
        <ClipCreateForm hidden={view !== 'clip'} />
      </PlayerControllerContainer>
    </Root>
  )
}
