import { Layout } from 'react-grid-layout'
import { UserTabsData } from 'modules/user/types'
import { LayoutConfigBreakpoints } from 'modules/layout/types'

export const updateUserTabsData = (
  tabsData: UserTabsData | undefined,
  activeTab: string,
  breakpoint: LayoutConfigBreakpoints | undefined,
  dataToChange: Layout[]
) => ({
  ...tabsData,
  [activeTab]: {
    ...tabsData?.[activeTab],
    ...(breakpoint ? { [breakpoint]: dataToChange } : {}),
  },
})
