// Constants
import { APP_SSO_AUTH_URL, APP_SSO_CLIENT_ID } from 'modules/auth/constants'

export const handleRedirect = async (redirectUrl: string) => {
  if (APP_SSO_AUTH_URL === undefined || APP_SSO_CLIENT_ID === undefined) {
    throw new Error('No auth properties set!')
  }

  const searchParams = new URLSearchParams({
    client_id: APP_SSO_CLIENT_ID,
    redirect_uri: window.origin,
    response_type: 'code',
  })

  window.location.href = `${redirectUrl}?${searchParams}`
}
