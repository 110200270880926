import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { TelestrationGroupListParams } from '@sporttotal-tv/dip-coaching-client'
import { TELESTRATION_GROUPS_PAGE_LIMIT } from '../constants'

export const TELESTRATION_GROUPS_LIST_CACHE_KEY = 'telestration-group-data'

export const useTelestrationGroupsList = (
  params: TelestrationGroupListParams
) => {
  return useQuery(
    [TELESTRATION_GROUPS_LIST_CACHE_KEY, params],
    () =>
      ApiService.telestrationGroup.list({
        ...params,
        page_limit: TELESTRATION_GROUPS_PAGE_LIMIT,
      }),
    {
      enabled: params.media_locator_id !== undefined,
      refetchOnWindowFocus: false,
    }
  )
}
