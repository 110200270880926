import { useCallback, useContext, useEffect, useState, useRef } from 'react'
import { SelectChangeEvent } from '@mui/material'
import { useTranslation } from 'react-i18next'
// Hooks
import { useReduxDispatch, useReduxSelector } from 'modules/core/hooks'
// Context
import {
  VideoPlayerContext,
  VideoPlayersContext,
} from 'modules/video-player/context'
import { ComponentInfoContext } from 'modules/generic/context'
// Redux
import {
  addLinkedComponent,
  removeLinkedComponent,
  selectAllVideoPlayersConnectedTypes,
} from 'modules/video-player/redux'
import { selectLayoutCacheById, updateLayoutCache } from 'modules/layout/redux'
// Components
import { Tooltip } from 'modules/core/styled'
// Constants
import { componentItemColorsWithDefault } from 'modules/layout/constants'

import {
  Selector,
  SelectorOption,
  ToolbarIcon,
} from './VideoPlayerSelector.styled'

const DEFAULT_VALUE = 'none'

export const VideoPlayerSelector = () => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { players } = useContext(VideoPlayersContext)
  const { layoutIndex, componentId } = useContext(ComponentInfoContext)
  const [value, setValue] = useState<string>(DEFAULT_VALUE)
  const [playerList, setPlayerList] = useState<string[]>([DEFAULT_VALUE])
  const dispatch = useReduxDispatch()
  const allConnection = useReduxSelector(selectAllVideoPlayersConnectedTypes)
  const componentCache = useReduxSelector(state =>
    selectLayoutCacheById(state, componentId)
  )
  const hasExecutedRef = useRef(false)
  const { t } = useTranslation('components', { keyPrefix: 'player' })

  const { setPlayer, removePlayer, updatePlayer, playerId } =
    useContext(VideoPlayerContext)

  const setData = useCallback(
    (prevPlayerId?: string) => (event: SelectChangeEvent<unknown>) => {
      const id = event.target.value as string
      if (prevPlayerId) {
        dispatch(
          removeLinkedComponent({
            id: prevPlayerId,
            componentId,
          })
        )
      }
      if (!players[id]) {
        setValue(DEFAULT_VALUE)
        removePlayer()
        dispatch(updateLayoutCache({ id: componentId, playerId: undefined }))
        return
      }
      dispatch(updateLayoutCache({ id: componentId, playerId: id }))
      dispatch(
        addLinkedComponent({
          id,
          componentId,
          layoutIndex,
        })
      )
      setValue(id)
      setPlayer(players[id], id)
    },
    [players, setPlayer, removePlayer, layoutIndex, dispatch, componentId]
  )

  // Component Auto-Linker logic
  useEffect(() => {
    if (hasExecutedRef.current) return
    hasExecutedRef.current = true

    // Return if component is already linked
    if (componentCache && componentCache?.playerId) return

    // This constant will try to find the first player that does not have the LayoutIndex
    // linked to it
    const playerWithLinklessLayoutIndex = Object.entries(allConnection).find(
      ([, linkedComponentsArray]) => {
        const doesLayoutIndexLinkExist =
          linkedComponentsArray.includes(layoutIndex)
        return !doesLayoutIndexLinkExist ? true : false
      }
    )

    if (!playerWithLinklessLayoutIndex) return
    const [playerId] = playerWithLinklessLayoutIndex

    dispatch(updateLayoutCache({ id: componentId, playerId }))
    dispatch(
      addLinkedComponent({
        id: playerId,
        componentId,
        layoutIndex,
      })
    )

    setValue(playerId)
  }, [])

  useEffect(() => {
    setPlayerList(prev => {
      const playersIds = Object.keys(players)
      if (prev.length === 1 && playersIds.length !== 0) {
        return [...prev, ...playersIds.sort()]
      }

      return [
        DEFAULT_VALUE,
        ...prev.filter(id => playersIds.includes(id)),
        ...playersIds.filter(id => !prev.includes(id)),
      ]
    })
  }, [players])

  useEffect(() => {
    if (playerId && players[playerId]) {
      updatePlayer(players[playerId])
    }
  }, [players, playerId, updatePlayer])

  useEffect(() => {
    if (
      componentCache?.playerId &&
      !playerId &&
      players[componentCache.playerId] &&
      !isInitialized
    ) {
      const id = componentCache.playerId
      setIsInitialized(true)
      dispatch(
        addLinkedComponent({
          id,
          componentId,
          layoutIndex,
        })
      )
      setValue(id)
      setPlayer(players[id], id)
    }
  }, [
    componentCache,
    playerId,
    setData,
    isInitialized,
    players,
    dispatch,
    componentId,
    layoutIndex,
    setPlayer,
  ])

  return (
    <Tooltip title={!isOpen ? t('selectVideoPlayer') : ''} placement='top'>
      <div>
        <Selector
          IconComponent={'div'}
          defaultValue={DEFAULT_VALUE}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          sx={{
            '& .MuiSelect-select': {
              padding: '4px !important',
              display: 'flex',
              alignItems: 'center',
            },
          }}
          value={Object.keys(players).includes(value) ? value : DEFAULT_VALUE}
          onChange={setData(playerId)}
        >
          {playerList.map((item, index) => (
            <SelectorOption
              key={`${item}-${index}`}
              value={item}
              disabled={allConnection[item]?.includes(layoutIndex)}
            >
              <ToolbarIcon
                name='player'
                color={componentItemColorsWithDefault[index]}
              />
            </SelectorOption>
          ))}
        </Selector>
      </div>
    </Tooltip>
  )
}
