import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import { TELESTRATION_GROUPS_LIST_CACHE_KEY } from './useTelestrationGroupsList'
import {
  Telestration,
  TelestrationGroupList,
} from '@sporttotal-tv/dip-coaching-client'
import { MutateContext } from 'modules/core/types'

export const TELESTRATION_DELETE_CACHE_KEY = 'telestation-delete'

export const useTelestrationDelete = () => {
  const queryClient = useQueryClient()

  return useMutation<
    Telestration,
    Error,
    string,
    MutateContext<TelestrationGroupList>
  >(
    TELESTRATION_DELETE_CACHE_KEY,
    (id: string) => ApiService.telestration.delete(id),
    {
      onMutate: async id => {
        await queryClient.cancelQueries(TELESTRATION_GROUPS_LIST_CACHE_KEY, {
          exact: false,
        })

        const previousData = queryClient.getQueriesData<TelestrationGroupList>(
          TELESTRATION_GROUPS_LIST_CACHE_KEY
        )

        if (previousData) {
          queryClient.setQueriesData<TelestrationGroupList | undefined>(
            TELESTRATION_GROUPS_LIST_CACHE_KEY,
            oldData =>
              oldData && {
                total: oldData.total - 1,
                results: oldData.results.map(group => ({
                  ...group,
                  telestrations: group.telestrations?.filter(
                    telestration => telestration.id !== id
                  ),
                })),
              }
          )
        }

        return { previousData }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData<TelestrationGroupList>(
              prevData[0],
              prevData[1]
            )
          )
        }
      },
      onSettled: _ =>
        queryClient.invalidateQueries(TELESTRATION_GROUPS_LIST_CACHE_KEY, {
          exact: false,
        }),
    }
  )
}
