import { useEffect, useState, useMemo, FC, MouseEvent } from 'react'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
// Hooks
import { usePageScrolling } from 'modules/core/hooks'
// Components
import { IconButton, LoaderContainer } from 'modules/core/components'
// Styled
import {
  HorizontalLoader,
  BodyElement,
  HeaderElement,
  HeaderElementSmall,
  BodyElementSmall,
  Row,
  Table,
} from 'modules/core/styled'

import {
  RoleElement,
  StatusBadge,
  StatusBodyElement,
  TableWrapper,
} from './UserInviteList.styled'
import { UserInviteContextMenu } from './UserInviteContextMenu'
import { UserInviteListProps, Invite } from './UserInviteList.interface'

export const UserInviteList: FC<UserInviteListProps> = ({
  invites,
  onInviteDelete,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'admin' })
  const [selectedId, setSelectedId] = useState<string | null>(null)

  const { containerRef, isBottom, resetIsBottom } = usePageScrolling(100)

  const [displayContextAnchor, setDisplayContextAnchor] =
    useState<null | HTMLElement>(null)

  const isDisplayContextMenuOpen = Boolean(displayContextAnchor)
  const isInfiniteQuery = 'hasNextPage' in invites

  useEffect(() => {
    if (!isInfiniteQuery) return

    if (
      isBottom &&
      invites.hasNextPage &&
      !invites.isFetchingNextPage &&
      !invites.isLoading
    ) {
      resetIsBottom()
      invites.fetchNextPage()
    }
  }, [invites, isInfiniteQuery, isBottom, resetIsBottom])

  const handleMenuOpen =
    (id: string) => (event: MouseEvent<HTMLButtonElement>) => {
      setDisplayContextAnchor(event.currentTarget)
      setSelectedId(id)
    }

  const handleMenuClose = () => {
    setDisplayContextAnchor(null)
    setSelectedId(null)
  }

  const inviteData = useMemo(() => {
    if (!invites.data) return []

    const data =
      'pages' in invites.data
        ? invites.data?.pages.reduce<Invite[]>((data, page) => {
            data.push(...page.results)
            return data
          }, [])
        : invites.data.results

    return data as unknown as Invite[]
  }, [invites.data])

  return (
    <>
      <TableWrapper ref={containerRef}>
        <Table>
          <Row>
            <HeaderElement>{t('table.row.name')}</HeaderElement>
            <HeaderElement>{t('table.row.surname')}</HeaderElement>
            <HeaderElement>{t('table.row.dateOfRequest')}</HeaderElement>
            <HeaderElement>{t('table.row.email')}</HeaderElement>
            <HeaderElement>{t('table.row.status')}</HeaderElement>
            <HeaderElement>{t('table.row.role')}</HeaderElement>
            <HeaderElementSmall />
          </Row>

          <LoaderContainer isLoading={invites.isLoading}>
            {inviteData.map(invite => (
              <Row key={invite.id}>
                <BodyElement>{invite.issued_to?.firstname ?? '-'}</BodyElement>
                <BodyElement>{invite.issued_to?.lastname ?? '-'}</BodyElement>
                <BodyElement>
                  {format(new Date(+invite.created_at * 1000), 'dd/MM/yy')}
                </BodyElement>
                <BodyElement>{invite.email}</BodyElement>

                <StatusBodyElement>
                  <StatusBadge status={invite.issued_to?.status}>
                    {invite.issued_to?.status}
                  </StatusBadge>
                </StatusBodyElement>
                <RoleElement>
                  {invite.issued_to?.role.toLowerCase()}
                </RoleElement>
                <BodyElementSmall>
                  <IconButton
                    name='vertical-menu'
                    disabled={invite.id.includes('draft')}
                    onClick={handleMenuOpen(invite.id)}
                  />
                </BodyElementSmall>
              </Row>
            ))}
          </LoaderContainer>
        </Table>

        {isInfiniteQuery && invites.isFetchingNextPage && <HorizontalLoader />}
      </TableWrapper>
      {selectedId && (
        <UserInviteContextMenu
          id={selectedId}
          open={isDisplayContextMenuOpen}
          anchorEl={displayContextAnchor}
          onClose={handleMenuClose}
          onInviteDelete={onInviteDelete}
        />
      )}
    </>
  )
}
