export const calculateMinimapPosition = (
  pitchWidth: number,
  pitchLength: number,
  pitchWidthFactor: number,
  pitchLengthFactor: number,
  X: number,
  Y: number
) => {
  // This value is inverted because positive Y is closest to cameras position and negative is opposite cameras position
  const invertedY = Y * -1

  const calculatedX = (X + pitchLength / 2) * pitchLengthFactor
  const calculatedY = (invertedY + pitchWidth / 2) * pitchWidthFactor

  return [calculatedX, calculatedY]
}
