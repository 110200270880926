import { useCallback, useEffect, useMemo, useRef, useState, FC } from 'react'
// Hooks
import { useTranslation } from 'react-i18next'
import {
  useResizeObserver,
  useServerTranslatedProperty,
} from 'modules/core/hooks'
import { useEventList } from 'modules/event/hooks'
import {
  useAnalyserPlayer,
  useAnalyserPlayerTimestamp,
} from 'modules/analyser/hooks'
import { useMatch } from 'modules/match/hooks'
// Components
import { Icon, Menu, MenuItem } from 'modules/core/components'
import { Tooltip } from 'modules/core/styled'
// Utils
import { groupEventsByTime } from 'modules/video-player-controller/utils'
// Constants
import {
  defaultIconName,
  eventIndexToIconDictionary,
} from 'modules/core/constants'
import {
  TIMELINE_ICON,
  TIMELINE_TEXT,
} from 'modules/video-player-controller/constants'
// Types
import { Event } from '@sporttotal-tv/dip-coaching-client'
import { GroupEvent } from 'modules/event/types'
import { EventsTimelineProps } from './EventsTimeline.interface'

import {
  EventElement,
  EventElementWrapper,
  EventInfoWrapper,
  RowTitleWithHidingWrapper,
  RowWrapper,
  // EventSelectorWrapper,
  Divider,
  // EventSelectIcon,
  // RowControlWithHidingWrapper,
  // EventName,
  EventElementItems,
  RowIconWrapper,
} from './EventsTimeline.styled'

import { convertSecondsToTime, isNumberBetween } from 'modules/core/utils'

export const EventsTimeline: FC<EventsTimelineProps> = ({ type, data }) => {
  const { t } = useTranslation('quantity')
  const tServer = useServerTranslatedProperty()

  const player = useAnalyserPlayer()
  const currentTimestamp = useAnalyserPlayerTimestamp()

  const timelineRef = useRef<HTMLDivElement>(null)
  const { width: eventsTimelineDivWidth } = useResizeObserver(timelineRef)

  // Anchors
  // const eventSelectorAnchorRef = useRef<HTMLDivElement>(null)
  const [eventSelectAnchor, setEventSelectAnchor] = useState<HTMLElement>()
  const [eventGroupAnchorRef, setEventGroupAnchorRef] =
    useState<HTMLDivElement>()

  // Data
  const { match } = useMatch()
  const events = useEventList({ sport_id: match?.sport?.id })

  const [playingEventIndex, setPlayingEventIndex] = useState<number>(-1)

  // Selected Data
  const [, setSelectedEvent] = useState<Event>()
  const [selectedEventGroup, setSelectedEventGroup] = useState<GroupEvent>()

  // Sizing
  const eventsWidthPerSecond = useMemo(() => {
    if (player?.duration && player?.duration !== 0 && eventsTimelineDivWidth) {
      return eventsTimelineDivWidth / player.duration
    }

    return 1
  }, [player?.duration, eventsTimelineDivWidth])

  const eventsGrouped = useMemo(
    () => groupEventsByTime(data, eventsWidthPerSecond),
    [data, eventsWidthPerSecond]
  )

  useEffect(() => {
    const index = eventsGrouped.findIndex(event =>
      isNumberBetween(currentTimestamp, event.start, event.end)
    )

    setPlayingEventIndex(index)
  }, [currentTimestamp, eventsGrouped])

  const handleOnClipClick = useCallback(
    (groupEvent: GroupEvent) => (event: React.MouseEvent<HTMLDivElement>) => {
      const isLargeGroup = groupEvent.events.length > 1

      if (isLargeGroup) {
        setSelectedEventGroup(groupEvent)
        setEventGroupAnchorRef(event.currentTarget)
      }

      if (!isLargeGroup && player) player.currentTime = groupEvent.start
    },
    [player]
  )

  const handleOnSelectEvent = useCallback((event?: Event) => {
    setSelectedEvent(event)
    setEventSelectAnchor(undefined)
  }, [])

  const handleOnSelectEventFromGroup = (eventStart: number) => {
    setEventGroupAnchorRef(undefined)
    if (player) player.currentTime = eventStart
  }

  const isEventFilterSelectOpened = Boolean(eventSelectAnchor)
  const TimelineIcon = TIMELINE_ICON[type]

  return (
    <RowWrapper>
      <RowTitleWithHidingWrapper>
        <Tooltip title={TIMELINE_TEXT[type]} placement='top'>
          <RowIconWrapper>
            <TimelineIcon />
          </RowIconWrapper>
        </Tooltip>
      </RowTitleWithHidingWrapper>

      <EventInfoWrapper ref={timelineRef}>
        <EventElementWrapper>
          {eventsGrouped.map((data, index) =>
            player?.duration ? (
              <Tooltip
                key={index}
                arrow
                title={data.events.map(event => tServer(event.name)).join(', ')}
                placement='top'
              >
                <EventElement
                  durationWidth={data.duration * eventsWidthPerSecond}
                  left={data.start * eventsWidthPerSecond}
                  onClick={handleOnClipClick(data)}
                  selected={index === playingEventIndex}
                >
                  <EventElementItems>
                    {data.events.map((event, index) => (
                      <Icon key={index} name={event.icon} />
                    ))}
                  </EventElementItems>
                </EventElement>
              </Tooltip>
            ) : null
          )}

          <Divider big />
          {Array(16)
            .fill(0)
            .map((__, index) => (
              <Divider key={index} />
            ))}
          <Divider big />
        </EventElementWrapper>
      </EventInfoWrapper>

      {/* <RowControlWithHidingWrapper>
        <EventSelectorWrapper
          ref={eventSelectorAnchorRef}
          onClick={() =>
            setEventSelectAnchor(eventSelectorAnchorRef.current ?? undefined)
          }
        >
          <EventName>{tServer(selectedEvent?.name) ?? 'All'}</EventName>
          <EventSelectIcon
            name='chevron-small-down'
            chevronUp={!isEventFilterSelectOpened}
          />
        </EventSelectorWrapper>
      </RowControlWithHidingWrapper> */}

      <Menu
        anchorEl={eventGroupAnchorRef}
        open={Boolean(eventGroupAnchorRef)}
        onClose={() => setEventGroupAnchorRef(undefined)}
      >
        {selectedEventGroup?.events.map((event, index) => (
          <MenuItem
            key={`${event.name}-${index}`}
            icon={event.icon ?? defaultIconName}
            onClick={() => handleOnSelectEventFromGroup(event.start)}
          >
            {tServer(event.name)} ({convertSecondsToTime(event.start)})
          </MenuItem>
        ))}
      </Menu>

      <Menu
        anchorEl={eventSelectAnchor}
        open={isEventFilterSelectOpened}
        onClose={() => setEventSelectAnchor(undefined)}
      >
        <MenuItem onClick={() => handleOnSelectEvent(undefined)}>
          {t('all')}
        </MenuItem>
        {events?.data?.results?.map(event => (
          <MenuItem
            key={event.id}
            onClick={() => handleOnSelectEvent(event)}
            icon={
              eventIndexToIconDictionary[event.type as number] ??
              defaultIconName
            }
          >
            {tServer(event.name)}
          </MenuItem>
        ))}
      </Menu>
    </RowWrapper>
  )
}
