import { FC, ReactNode } from 'react'
import { IconName } from 'modules/core/components/Icon/Icon.interface'
import { Translation } from 'modules/core/components/Translation'
import { GeneralView } from 'modules/team-player/components/GeneralView'
import { PlayersView } from 'modules/team-player/constants/playersView'
import { ContractView } from 'modules/team-player/components/ContractView'
import { TransferView } from 'modules/team-player/components/TransferView'
import { StatusView } from 'modules/team-player/components/StatusView'
import { InjuriesView } from 'modules/team-player/components/InjuriesView'
import { StatsView } from 'modules/team-player/components/StatsView'
import type { GenericViewProps } from 'modules/team-player/types'
import { TeamPlayerProfileView } from 'modules/team-player/components/TeamPlayerProfileView'

export const playersComponents: Record<
  PlayersView,
  {
    component: FC<GenericViewProps>
    icon: IconName
    name: NonNullable<ReactNode>
  }
> = {
  [PlayersView.GENERAL]: {
    component: GeneralView,
    icon: 'info',
    name: Translation('components:teamPlayers.toolbar.generalInfo'),
  },
  [PlayersView.CONTRACT]: {
    component: ContractView,
    icon: 'contract',
    name: Translation('components:teamPlayers.toolbar.contract'),
  },
  [PlayersView.TRANSFER]: {
    component: TransferView,
    icon: 'transfer',
    name: Translation('components:teamPlayers.toolbar.transfer'),
  },
  [PlayersView.STATUS]: {
    component: StatusView,
    icon: 'status',
    name: Translation('components:teamPlayers.toolbar.status'),
  },
  [PlayersView.INJURIES]: {
    component: InjuriesView,
    icon: 'injury',
    name: Translation('components:teamPlayers.toolbar.injuries'),
  },
  [PlayersView.STATS]: {
    component: StatsView,
    icon: 'stats',
    name: Translation('components:teamPlayers.toolbar.stats'),
  },
  [PlayersView.PROFILE]: {
    component: TeamPlayerProfileView,
    icon: 'person',
    name: Translation('components:teamPlayers.toolbar.profile'),
  },
}
