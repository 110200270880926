import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import { MATCH_LIST_CACHE_KEY } from 'modules/match/hooks'

export const SCHEDULE_DELETE_CACHE_KEY = 'schedule-delete'

export const useScheduleDelete = () => {
  const queryClient = useQueryClient()

  return useMutation(
    SCHEDULE_DELETE_CACHE_KEY,
    (id: string) => ApiService.calendar.scheduleDelete(id),
    {
      onSettled: () => {
        queryClient.invalidateQueries(MATCH_LIST_CACHE_KEY, { exact: false })
      },
    }
  )
}
