import { styled } from '@mui/material'

export const Main = styled('div')`
  margin: ${({ theme }) => theme.spacing(1)};
  column-gap: ${({ theme }) => theme.spacing(1)};
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

export const ComponentItem = styled('div')`
  padding: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.palette.primary.main};
`

export const VideoContainer = styled(ComponentItem)`
  flex: 2.5;
`

export const ClipsContainer = styled(ComponentItem)`
  flex: 1;
  height: 100%;
  justify-content: flex-start;
`
