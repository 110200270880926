import * as yup from 'yup'

export const teamPlayerContractSchema = yup.object().shape({
  agreement: yup
    .number()
    .typeError('components:teamPlayers.modal.contract.error.agreement.required')
    .required('components:teamPlayers.modal.contract.error.agreement.required')
    .min(0, 'components:teamPlayers.modal.contract.error.agreement.invalid'),
  agreementType: yup
    .string()
    .required('components:teamPlayers.modal.contract.error.agreementType'),
  expiration: yup
    .string()
    .required('components:teamPlayers.modal.contract.error.expiration.required')
    .test(
      'is-valid',
      'components:teamPlayers.modal.contract.error.expiration.invalid',
      value => {
        return new Date(value ?? '') > new Date()
      }
    ),
  transferValueMin: yup
    .number()
    .typeError(
      'components:teamPlayers.modal.contract.error.transferValueMin.required'
    )
    .required(
      'components:teamPlayers.modal.contract.error.transferValueMin.required'
    )
    .min(
      0,
      'components:teamPlayers.modal.contract.error.transferValueMin.invalid'
    ),
  transferValueMax: yup
    .number()
    .typeError(
      'components:teamPlayers.modal.contract.error.transferValueMax.required'
    )
    .required(
      'components:teamPlayers.modal.contract.error.transferValueMax.required'
    )
    .min(
      0,
      'components:teamPlayers.modal.contract.error.transferValueMax.invalid'
    ),
  release: yup
    .boolean()
    .required('components:teamPlayers.modal.contract.error.release'),
  salary: yup
    .number()
    .typeError('components:teamPlayers.modal.contract.error.salary.required')
    .required('components:teamPlayers.modal.contract.error.salary.required')
    .min(0, 'components:teamPlayers.modal.contract.error.salary.invalid'),
})
