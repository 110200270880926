import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { SportListParams } from '@sporttotal-tv/dip-coaching-client'
import { useQueryRetry } from 'modules/core/hooks'

export const SPORT_LIST_CACHE_KEY = 'sport-list'

export const useSportList = (options?: SportListParams) => {
  const handleQueryRetry = useQueryRetry()

  return useQuery(
    [SPORT_LIST_CACHE_KEY, options],
    () => ApiService.sport.list(options),
    {
      retry: handleQueryRetry,
      staleTime: Infinity,
    }
  )
}
