import { useContext } from 'react'
import { useReduxSelector } from 'modules/core/hooks'
import { ComponentInfoContext } from 'modules/generic/context'
import { selectVideoPlayerTelestrationComponentId } from 'modules/video-player/redux'

export const useTelestrationId = () => {
  const { componentId } = useContext(ComponentInfoContext)
  const telestrationId = useReduxSelector(state =>
    selectVideoPlayerTelestrationComponentId(state, componentId)
  )

  return telestrationId ?? ''
}
