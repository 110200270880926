import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import { ADMIN_USER_ACCESS_LIST_CACHE_KEY } from './useAdminUserAccessList'

export const ADMIN_USER_ACCESS_DELETE_CACHE_KEY = 'admin-user-access-delete'

export const useAdminUserAccessDelete = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ADMIN_USER_ACCESS_DELETE_CACHE_KEY,
    (id: string) => ApiService.admin.access.user.delete(id),
    {
      onSettled: () => {
        queryClient.invalidateQueries(ADMIN_USER_ACCESS_LIST_CACHE_KEY)
      },
    }
  )
}
