import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Circle, Group, Line, Shape as KonvaShape } from 'react-konva'
import Konva from 'konva'
import Context = Konva.Context
// constants
import {
  SELECTION_AREA_NAME,
  SELECTION_GROUP_NAME,
  SELECTION_TRANSFORMER_NAME,
} from 'modules/annotator/constants'
// types
import { IKonvaNode, IKonvaShape } from 'modules/annotator/types'
import { TelestrationShapeType } from 'modules/telestration/types'
import { CircleConfig } from 'konva/lib/shapes/Circle'
// utils
import { getDefaultQuadraticPoints } from 'modules/annotator/utils'
// hooks
import { useDebounceWithSetter } from 'modules/core/hooks'

type ShapeCurvedLineProps = TelestrationShapeType & {
  onChange?: (shape: IKonvaNode) => void
  preview?: boolean
  isActive?: boolean
}

const ShapeCurvedLine: FC<ShapeCurvedLineProps> = ({
  type,
  preview,
  id,
  name,
  onChange,
  isActive,
  ...props
}) => {
  const [linePoints, setLinePoints] = useState<number[]>([0, 0])
  const [updateCounter, setUpdateCounter] = useState(0)
  const [debounceUpdateCounter, setDebounceUpdateCounter] =
    useDebounceWithSetter(updateCounter, 500)

  const shapeRef = useRef<any>(null)
  const circleRef = useRef<IKonvaShape[]>([])
  const [circles, setCircles] = useState<CircleConfig[]>([])

  const defaultQuadraticPoints = useMemo(() => {
    if (props.points === 8) {
      return {
        start: {
          x: props.points[0],
          y: props.points[1],
        },
        control1: {
          x: props.points[2],
          y: props.points[3],
        },
        control2: {
          x: props.points[4],
          y: props.points[5],
        },
        end: {
          x: props.points[6],
          y: props.points[7],
        },
      }
    }

    const pointsLength = props.points.length
    const pointAx = props.points[0]
    const pointAy = props.points[1]
    const pointDx = props.points[pointsLength - 2]
    const pointDy = props.points[pointsLength - 1]

    return getDefaultQuadraticPoints([pointAx, pointAy], [pointDx, pointDy])
  }, [props.points])

  const updateDottedLines = useCallback(() => {
    if (shapeRef.current) {
      onChange && onChange(shapeRef.current)
    }
  }, [onChange])

  const handleDragMove = useCallback(() => {
    setUpdateCounter(prev => prev + 1)
    if (circleRef.current[0]) {
      setLinePoints([
        circleRef.current[0].x(),
        circleRef.current[0].y(),
        circleRef.current[1].x(),
        circleRef.current[1].y(),
        circleRef.current[2].x(),
        circleRef.current[2].y(),
        circleRef.current[3].x(),
        circleRef.current[3].y(),
      ])
    }
  }, [])

  useEffect(() => {
    if (debounceUpdateCounter !== 0) {
      updateDottedLines()
      setUpdateCounter(0)
      setDebounceUpdateCounter(0)
    }
  }, [updateDottedLines, debounceUpdateCounter, setDebounceUpdateCounter])

  useEffect(() => {
    if (!isActive) {
      setCircles([])
    }
  }, [isActive])

  useEffect(() => {
    if (circles.length === 0) {
      // TODO find a way to remove ternary operator
      // Currently, the points are not being set correctly if we use only the defaultQuadraticPoints
      // We do have an if that checks if the points length are 8, but it's returning shape to previous state
      const points =
        props.points.length === 8
          ? [
              [props.points[0], props.points[1]],
              [props.points[2], props.points[3]],
              [props.points[4], props.points[5]],
              [props.points[6], props.points[7]],
            ]
          : Object.values(defaultQuadraticPoints).reduce<number[][]>(
              (acc, point) => {
                acc.push([point.x, point.y])
                return acc
              },
              []
            )

      setLinePoints(points.flat())

      setCircles(
        points.map(point => ({
          x: point[0],
          y: point[1],
          radius: 20,
          stroke: '#666',
          fill: '#ddd',
          strokeWidth: 2,
          draggable: true,
        }))
      )
    }
  }, [circles.length, defaultQuadraticPoints, props.points])

  const sceneFunc = useCallback(
    (ctx: Context, shape: IKonvaShape) => {
      if (circleRef.current[0] && isActive) {
        ctx.beginPath()
        ctx.moveTo(circleRef.current[0].x(), circleRef.current[0].y())
        ctx.bezierCurveTo(
          circleRef.current[1].x(),
          circleRef.current[1].y(),
          circleRef.current[2].x(),
          circleRef.current[2].y(),
          circleRef.current[3].x(),
          circleRef.current[3].y()
        )
        ctx.bezierCurveTo(
          circleRef.current[2].x(),
          circleRef.current[2].y(),
          circleRef.current[1].x(),
          circleRef.current[1].y(),
          circleRef.current[0].x(),
          circleRef.current[0].y()
        )
        ctx.fillStrokeShape(shape)
      } else {
        if (props.points.length === 8) {
          ctx.beginPath()
          ctx.moveTo(props.points[0], props.points[1])
          ctx.bezierCurveTo(
            props.points[2],
            props.points[3],
            props.points[4],
            props.points[5],
            props.points[6],
            props.points[7]
          )
          ctx.bezierCurveTo(
            props.points[4],
            props.points[5],
            props.points[2],
            props.points[3],
            props.points[0],
            props.points[1]
          )
          ctx.fillStrokeShape(shape)
        } else {
          const { start, control1, control2, end } = defaultQuadraticPoints
          ctx.beginPath()
          ctx.moveTo(start.x, start.y)
          ctx.bezierCurveTo(
            control1.x,
            control1.y,
            control2.x,
            control2.y,
            end.x,
            end.y
          )
          ctx.bezierCurveTo(
            control2.x,
            control2.y,
            control1.x,
            control1.y,
            start.x,
            start.y
          )
          ctx.fillStrokeShape(shape)
        }
      }
    },
    [circleRef, isActive, defaultQuadraticPoints, props.points]
  )

  if (preview) {
    const { start, control1, control2, end } = defaultQuadraticPoints

    return (
      <Group
        key={id || name}
        id={`${SELECTION_GROUP_NAME}-${id}`}
        name={`${SELECTION_GROUP_NAME}-${id}`}
      >
        <KonvaShape
          id={id}
          key={id || name}
          name={name}
          shapeToolType={type}
          stroke={props.stroke}
          strokeWidth={5}
          sceneFunc={(ctx, shape) => {
            ctx.beginPath()
            ctx.moveTo(start.x, start.y)
            ctx.bezierCurveTo(
              control1.x,
              control1.y,
              control2.x,
              control2.y,
              end.x,
              end.y
            )
            ctx.fillStrokeShape(shape)
          }}
        />
      </Group>
    )
  }

  return (
    <Group
      key={id || name}
      id={`${SELECTION_GROUP_NAME}-${id}`}
      name={`${SELECTION_GROUP_NAME}-${id}`}
    >
      <KonvaShape
        ref={shapeRef}
        id={id}
        key={id || name}
        name={name}
        shapeToolType={type}
        stroke={props.stroke}
        strokeWidth={5}
        points={[
          circleRef.current[0]?.x(),
          circleRef.current[0]?.y(),
          circleRef.current[1]?.x(),
          circleRef.current[1]?.y(),
          circleRef.current[2]?.x(),
          circleRef.current[2]?.y(),
          circleRef.current[3]?.x(),
          circleRef.current[3]?.y(),
        ]}
        sceneFunc={sceneFunc}
      />
      {!isActive && (
        <KonvaShape
          id={`${SELECTION_AREA_NAME}-${id}`}
          strokeWidth={20}
          stroke={'transparent'}
          fill={'transparent'}
          sceneFunc={sceneFunc}
        />
      )}
      {isActive && (
        <Line
          dash={[10, 10, 0, 10]}
          strokeWidth={3}
          stroke={'black'}
          lineCap={'round'}
          id={SELECTION_TRANSFORMER_NAME + 'quadLinePath'}
          opacity={0.3}
          points={linePoints}
        />
      )}
      {isActive &&
        circles.map((circle, index) => (
          <Circle
            key={index}
            {...circle}
            name={`${SELECTION_TRANSFORMER_NAME}-circle-${index}`}
            onDragMove={handleDragMove}
            ref={el => {
              if (el) {
                circleRef.current[index] = el
              }
            }}
          />
        ))}
    </Group>
  )
}

export { ShapeCurvedLine }
