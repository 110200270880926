import { Match } from '@sporttotal-tv/dip-coaching-client'
import { MatchLocatorType, MatchAsset } from 'modules/match/types'

const matchAssetFilter = (type: MatchLocatorType) => (asset: MatchAsset) =>
  asset.type === type

export const fetchAvailableMatchLocators = (
  match?: Match
): Record<MatchLocatorType, boolean> => {
  const matchAssets = match?.match_assets

  const hasPano = matchAssets?.some(matchAssetFilter('pano'))
  const hasTactical = matchAssets?.some(matchAssetFilter('tactical'))
  const hasBroadcast = matchAssets?.some(matchAssetFilter('broadcast'))
  const hasBroadcastLive = matchAssets?.some(matchAssetFilter('broadcast_live'))

  const pano = Boolean(hasPano)
  const broadcast = Boolean(hasBroadcast)
  const broadcast_live = Boolean(hasBroadcastLive)
  const tactical = Boolean(hasTactical)

  return {
    pano,
    broadcast,
    broadcast_live,
    tactical,
  }
}
