import { call, takeEvery, select, put } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { Match } from '@sporttotal-tv/dip-coaching-client'
import { MatchLocatorType } from 'modules/match/types'
import { MatchViewDataService } from 'services/MatchViewData.service'
import { selectMatchId } from './videoPlayer.selectors'
import { removeLayout } from 'modules/layout/redux'
import {
  PlayerPayloadAction,
  removeAllLinkedComponent,
  setMatchType,
} from './videoPlayer.slice'

function* updateMatchType(
  action: PlayerPayloadAction<{ matchType: MatchLocatorType }>
) {
  try {
    const activeMatch: Match | undefined = yield select(state =>
      selectMatchId(state, action.payload.id)
    )

    if (activeMatch?.id) {
      yield call(
        MatchViewDataService.addMatchViewData,
        action.payload.id,
        activeMatch.id,
        action.payload.matchType
      )
    }
  } catch (e) {
    yield call(console.error, e)
  }
}

function* removeLayoutAction(action: PayloadAction<string>) {
  try {
    yield put(removeAllLinkedComponent({ componentId: action.payload }))
  } catch (e) {
    yield call(console.error, e)
  }
}

export function* videoPlayerSaga() {
  yield takeEvery(setMatchType, updateMatchType)
  yield takeEvery(removeLayout, removeLayoutAction)
}
