import { useMemo } from 'react'
import { useEventList } from 'modules/event/hooks'
import { Event, EventListParams } from '@sporttotal-tv/dip-coaching-client'
import { EventIndex } from 'modules/event/types'

type EventDictionary = {
  eventIdDictionary: Record<string, Event>
  eventIndexDictionary: Record<EventIndex, Event>
}

type UseEventDictionary = (options?: EventListParams) => EventDictionary

export const useEventDictionary: UseEventDictionary = options => {
  const events = useEventList(options)

  const { eventIdDictionary, eventIndexDictionary } = useMemo(() => {
    const eventsData = events.data?.results || []

    return eventsData.reduce<EventDictionary>(
      (dictionaries, event) => {
        dictionaries.eventIdDictionary[event.id] = event
        dictionaries.eventIndexDictionary[event.type as number] = event

        return dictionaries
      },
      { eventIdDictionary: {}, eventIndexDictionary: {} }
    )
  }, [events])

  return {
    eventIdDictionary,
    eventIndexDictionary,
  }
}
