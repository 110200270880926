import { RootState } from 'redux/store'
import { createSelector } from '@reduxjs/toolkit'

export const selectCalendar = (state: RootState) => state.calendar

export const selectCalendarLayout = createSelector(
  selectCalendar,
  calendar => calendar.layout
)

export const selectCalendarSelectedDate = createSelector(
  selectCalendar,
  calendar => new Date(calendar.selectedDate)
)

export const selectDraggingEventId = createSelector(
  selectCalendar,
  calendar => calendar.draggingEventId
)

export const selectGhostProperties = createSelector(
  selectCalendar,
  calendar => calendar.ghostProperties
)

export const selectOpenDialog = createSelector(
  selectCalendar,
  calendar => calendar.openDialog
)

export const selectOpenViewDialog = createSelector(
  selectCalendar,
  calendar => calendar.openViewDialog
)

export const selectCalendarEvent = createSelector(
  selectCalendar,
  calendar => calendar.calendarEvent
)

export const selectEventDialogData = createSelector(
  selectCalendar,
  calendar => ({
    eventBeginDateTime: calendar.eventBeginDate
      ? new Date(calendar.eventBeginDate)
      : undefined,
    eventEndDateTime: calendar.eventEndDate
      ? new Date(calendar.eventEndDate)
      : undefined,
    eventID: calendar.eventID,
    title: calendar.title,
    description: calendar.description,
  })
)
