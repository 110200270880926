import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import { Frame, FrameList } from '@sporttotal-tv/dip-coaching-client'
import { FRAME_LIST_CACHE_KEY } from 'modules/frame/hooks'
import { MutateContext } from 'modules/core/types/mutate-context.type'

export const FRAME_DELETE_CACHE_KEY = 'frame-delete'

export const useFrameDelete = () => {
  const queryClient = useQueryClient()

  return useMutation<Frame, Error, string, MutateContext<FrameList>>(
    FRAME_DELETE_CACHE_KEY,
    (id: string) => ApiService.frame.delete(id),
    {
      onMutate: async frameId => {
        await queryClient.cancelQueries(FRAME_LIST_CACHE_KEY, { exact: false })

        const previousData =
          queryClient.getQueriesData<FrameList>(FRAME_LIST_CACHE_KEY)

        if (previousData) {
          queryClient.setQueriesData<FrameList | undefined>(
            FRAME_LIST_CACHE_KEY,
            oldData =>
              oldData && {
                total: oldData.total - 1,
                results: oldData.results.filter(item => item.id !== frameId),
              }
          )
        }

        return { previousData: [...previousData] }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData(prevData[0], prevData[1])
          )
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(FRAME_LIST_CACHE_KEY, { exact: false })
      },
    }
  )
}
