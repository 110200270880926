import { useQuery } from 'react-query'
import { useObservable, useQueryRetry } from 'modules/core/hooks'
import { ApiService } from 'services/Api.service'
import { TokenService } from 'services/Token.service'
import { Subscription } from '@sporttotal-tv/dip-coaching-client'
import { ENABLE_SUBSCRIPTION } from 'modules/core/constants'
import { UseQueryOptions } from 'modules/core/types'

export const SUBSCRIPTION_RETRIEVE_CACHE_KEY = 'subscription-retrieve'

export const useSubscription = (
  options: UseQueryOptions<Subscription, any> = {}
) => {
  const handleQueryRetry = useQueryRetry({ suppressError: true, maxRetries: 0 })
  const token = useObservable(TokenService.getInstance().getToken())

  return useQuery(
    [SUBSCRIPTION_RETRIEVE_CACHE_KEY],
    () => ApiService.subscription.retrieve(),
    {
      retry: handleQueryRetry,
      staleTime: Infinity,
      enabled: ENABLE_SUBSCRIPTION && !!token,
      ...options,
    }
  )
}
