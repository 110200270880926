import { MatchLocatorType } from 'modules/match/types'
import { ActiveVideoItem, MediaLocator } from 'modules/video-player/types'

// TODO: Move to userCache because session does not persist across multiple devices

export type ContentItem = {
  mediaLocator: MediaLocator
  mediaRenderComponentId: string
  currentTime: number
  title?: string
  activeVideoItemId?: string
  activeVideoItemType?: ActiveVideoItem
  matchType?: MatchLocatorType
  isKeyboardControlsEnabled?: boolean
}

export const ContentsDataService = {
  _STORAGE_KEY: 'contentData',

  getContentData: (): ContentItem[] => {
    const contentsDataStorage = localStorage.getItem(
      ContentsDataService._STORAGE_KEY
    )

    const contentsDataParsed = contentsDataStorage
      ? JSON.parse(contentsDataStorage)
      : []

    return contentsDataParsed
  },

  setContentData: (contentData: ContentItem[]) => {
    const serializedContentsData = JSON.stringify(contentData)
    localStorage.setItem(
      ContentsDataService._STORAGE_KEY,
      serializedContentsData
    )
  },

  addContentItem: (contentData: ContentItem) => {
    const contentsData = ContentsDataService.getContentData()
    ContentsDataService.setContentData([...contentsData, contentData])
  },

  updateContentItem: (
    id: string,
    updatedContent: Partial<ContentItem>
  ): void => {
    const contentsData = ContentsDataService.getContentData()

    ContentsDataService.setContentData(
      contentsData.map(content => {
        if (content.mediaRenderComponentId === id) {
          return {
            ...content,
            ...updatedContent,
          }
        }
        return content
      })
    )
  },
}
