import { TabDto } from 'modules/user/types'

export const APP_BAR_TABS: TabDto[] = [
  {
    id: 'user',
    name: 'Users',
    path: 'users',
    static: false,
  },
  {
    id: 'invite',
    name: 'Invites',
    path: 'invites',
    static: false,
  },
]
