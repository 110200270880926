import { styled } from '@mui/material'
import { OptionsSelector } from 'modules/core/styled'

export const InputWrapper = styled('div')`
  width: 100%;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.75)};
  margin-bottom: ${({ theme }) => theme.spacing(0.75)};
`

export const StyledOptionsSelector = styled(OptionsSelector)`
  width: 100%;
`
