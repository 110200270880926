import { useInfiniteQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { useQueryRetry } from 'modules/core/hooks'
import { getNextPageParamWithLimit } from 'modules/core/utils'
import { TEAM_PAGE_LIMIT } from 'modules/team/constants'
import {
  PlayerTransferList,
  PlayerTransferListParams,
} from '@sporttotal-tv/dip-coaching-client'

export const TEAM_PLAYER_TRANSFER_LIST_CACHE_KEY = 'team-player-transfer-list'

export const useTeamPlayerTransferList = (
  options?: PlayerTransferListParams
) => {
  const handleQueryRetry = useQueryRetry()

  return useInfiniteQuery(
    [TEAM_PLAYER_TRANSFER_LIST_CACHE_KEY, options],
    ({ pageParam = 0 }) =>
      ApiService.teamPlayerTransfer.list({ ...options, page: pageParam }),
    {
      retry: handleQueryRetry,
      staleTime: Infinity,
      getNextPageParam:
        getNextPageParamWithLimit<PlayerTransferList>(TEAM_PAGE_LIMIT),
    }
  )
}
