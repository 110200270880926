import { FC, ReactNode } from 'react'
// Components
import { AppBar } from 'modules/core/components'

import { Main } from './HelpPageLayout.styled'

export const HelpPageLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <>
      <AppBar
        tabs={[
          { id: 'privacy', name: 'Privacy', path: 'privacy', static: true },
          { id: 'cookie', name: 'Cookie', path: 'cookie', static: true },
          { id: 'terms', name: 'T&C', path: 'terms', static: true },
        ]}
      />
      <Main>{children}</Main>
    </>
  )
}
