import { PlayerStatus } from '@sporttotal-tv/dip-coaching-client'
import { teamPlayerMockup } from './teamPlayer.mockup'

export const teamPlayerStatusMockup: PlayerStatus = {
  id: '1',
  math_load: 'LIGHT',
  training_load: 'LIGHT',
  injury_suscetible: 'LOW',
  health: 1000,
  form: 1000,
  overall_risk: 'LOW',
  player_id: 'player_id',
  player: teamPlayerMockup,
  created_at: new Date().getTime() / 1000,
  updated_at: new Date().getTime() / 1000,
}
