import { css, styled } from '@mui/material'
import { Icon } from 'modules/core/components/Icon'

export const PlayControls = styled('div', {
  shouldForwardProp: prop => prop !== 'size',
})<{ size?: 'default' | 'small' }>`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.5)};

  ${({ theme, size }) =>
    size === 'default' &&
    css`
      column-gap: ${theme.spacing(1.5)};
    `}

  ${({ theme, size }) =>
    size === 'small' &&
    css`
      column-gap: ${theme.spacing(0.75)};
    `}
`

export const PlayControlsIcon = styled(Icon, {
  shouldForwardProp: prop => prop !== 'active' && prop !== 'disabledClick',
})<{ active?: boolean; disabledClick?: boolean }>`
  cursor: pointer;

  ${({ theme, active }) =>
    !active &&
    css`
      &:hover path {
        fill: ${theme.palette.primary.contrastText};
        filter: brightness(70%);
      }
    `}

  & path {
    transition: all 0.3s;
    fill: ${({ theme, active }) =>
      active
        ? theme.palette.primary.contrastText
        : theme.palette.primary.light};
  }

  ${({ theme, disabledClick }) =>
    disabledClick &&
    css`
      cursor: not-allowed;

      & path {
        fill: ${theme.palette.primary.contrastText};
        filter: brightness(45%);
      }

      &:hover path {
        fill: ${theme.palette.primary.contrastText};
        filter: brightness(45%);
      }
    `}
`
