import { Button, styled } from '@mui/material'

export const StyledButton = styled(Button)`
  min-width: 66px;
  height: 40px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  border-radius: 4px;
  font: normal normal bold 10px/12px 'Exo 2';
  letter-spacing: 0px;
  cursor: pointer;
  transition: background-color 0.5s ease;
`

export const DefaultStyledButton = styled(StyledButton)`
  background: ${({ theme }) => theme.palette.common.black};
  border: 2px solid ${({ theme }) => theme.palette.primary.contrastText};
`

export const PrimaryStyledButton = styled(StyledButton)`
  width: 120px;
  color: ${({ theme }) => theme.palette.common.white}!important;
  background: ${({ theme }) => theme.palette.secondary.main};

  &:hover {
    background-color: rgba(255, 0, 68, 0.7);
  }
`

export const VideoStyledButton = styled(StyledButton)`
  width: 70px;
  height: 70px;
  border: 2px solid ${({ theme }) => theme.palette.primary.contrastText};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`
