import { FC, MouseEvent, useState } from 'react'
import { Collapse } from '@mui/material'
// Components
import { IconButton, Dots } from 'modules/core/components'

import { ResourceControlsProps } from './ResourceControls.interface'
import { IconsWrapper, Root } from './ResourceControls.styled'

export const ResourceControls: FC<ResourceControlsProps> = ({
  onBookmark,
  onNotify,
  onShare,
  onDelete,
  onDownload,
  onEdit,
  onTransfer,
}) => {
  const [collapsed, setCollapsed] = useState<boolean>(true)

  if (
    !onBookmark &&
    !onNotify &&
    !onShare &&
    !onDelete &&
    !onDownload &&
    !onEdit &&
    !onTransfer
  )
    return null

  const propagationPreventionHandler = (event: MouseEvent<HTMLDivElement>) =>
    event.stopPropagation()

  return (
    <Root onClick={propagationPreventionHandler}>
      <Collapse in={!collapsed} orientation='horizontal'>
        <IconsWrapper>
          {onEdit && <IconButton size='small' name='pencil' onClick={onEdit} />}
          {onBookmark && (
            <IconButton size='small' name='bookmark' onClick={onBookmark} />
          )}
          {onNotify && (
            <IconButton size='small' name='bell' onClick={onNotify} />
          )}
          {onDownload && (
            <IconButton size='small' name='download' onClick={onDownload} />
          )}
          {onShare && (
            <IconButton size='small' name='share' onClick={onShare} />
          )}
          {onTransfer && (
            <IconButton size='small' name='transfer' onClick={onTransfer} />
          )}
          {onDelete && (
            <IconButton size='small' name='trash' onClick={onDelete} />
          )}
        </IconsWrapper>
      </Collapse>
      <IconButton size='small' onClick={() => setCollapsed(prev => !prev)}>
        <Dots active={collapsed} />
      </IconButton>
    </Root>
  )
}
