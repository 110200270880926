import { styled } from '@mui/material'
import { FlexColumn } from 'modules/landing/components/shared/Flex'
import sectionBg from 'modules/landing/assets/backgrounds/video-section-bg.png'

export const StyledVideo = styled('video', {
  shouldForwardProp: prop => prop !== 'isVisible',
})<{ isVisible: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  background: black;
  transition: opacity 1s ease;
`

export const StyledTriangleShape = styled('div')`
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 9px 16.2px 9px;
  border-color: transparent transparent white transparent;
  transform: rotate(90deg) translateY(-3px);
`

export const StyledWrapper = styled(FlexColumn)`
  width: 100%;
  height: 830px;
  background: url(${sectionBg});
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  transition: 0.3s all;
  position: relative;
`
