import { FC } from 'react'
import { useTranslation } from 'react-i18next'
// Utils
import { format } from 'date-fns'
// Types
import { OrganizationListProps } from './OrganizationList.interface'
// Styled
import { HeaderElement, Row, Table, BodyElement } from 'modules/core/styled'

import { RoleElement, TableWrapper } from './OrganizationList.styled'

export const OrganizationList: FC<OrganizationListProps> = ({
  organizations,
  handleRowClick,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'organization' })

  return (
    <TableWrapper>
      <Table>
        <Row>
          <HeaderElement>{t('table.row.name')}</HeaderElement>
          <HeaderElement>{t('table.row.owner')}</HeaderElement>
          <HeaderElement>{t('table.row.created_at')}</HeaderElement>
          <HeaderElement>{t('table.row.updated_at')}</HeaderElement>
        </Row>
        {organizations?.results?.map(organization => (
          <Row
            interactive
            key={organization.id}
            onClick={() => handleRowClick(organization.id)}
          >
            <BodyElement>{organization.name}</BodyElement>
            <RoleElement>
              {organization.owner?.firstname} {organization.owner?.lastname}
            </RoleElement>
            <BodyElement>
              {format(new Date(+organization.created_at * 1000), 'dd/MM/yy')}
            </BodyElement>
            <BodyElement>
              {format(new Date(+organization.updated_at * 1000), 'dd/MM/yy')}
            </BodyElement>
          </Row>
        ))}
      </Table>
    </TableWrapper>
  )
}
