// Components
import { Icon } from 'modules/core/components'

import {
  ImageContainer,
  Main,
  Name,
  Position,
  Location,
  LocationWrapper,
  Tier,
  TierPositionWrapper,
} from './ProfileInfo.styled'
import { FC } from 'react'
import { ProfileInfoProps } from './ProfileInfo.interface'

export const ProfileInfo: FC<ProfileInfoProps> = ({ user }) => {
  return (
    <Main>
      <ImageContainer>
        <Icon name='person-filled' />
      </ImageContainer>
      <Name>
        {user?.firstname} {user?.lastname}
      </Name>
      <TierPositionWrapper>
        <Position>{user?.role.toLowerCase()}</Position>
        <div> - </div>
        <Tier>{user?.feature_tier.toLowerCase()}</Tier>
      </TierPositionWrapper>

      <LocationWrapper>
        <Icon name='geolocation' />
        <Location>Rome, IT</Location>
      </LocationWrapper>
    </Main>
  )
}
