import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import {
  MatchList,
  PlaylistList,
  UpdateAssetBody,
  Asset,
} from '@sporttotal-tv/dip-coaching-client'
import { PLAYLIST_CACHE_KEY } from 'modules/playlist/hooks'
import { MATCH_LIST_CACHE_KEY } from 'modules/match/hooks'
import {
  updateAssetsInCollection,
  getAssetFromMatchCollection,
} from 'modules/asset/helpers'
import { MutateContext } from 'modules/core/types/mutate-context.type'
import { InfiniteQuery } from 'modules/core/types'

export const ASSET_UPDATE_CACHE_KEY = 'asset-update'

export type AssetUpdateParams = {
  id: string
  params: UpdateAssetBody
}

export const useAssetUpdate = () => {
  const queryClient = useQueryClient()

  return useMutation<Asset, Error, AssetUpdateParams, MutateContext<any>>(
    ASSET_UPDATE_CACHE_KEY,
    ({ id, params }: AssetUpdateParams) => ApiService.asset.update(id, params),
    {
      onMutate: async data => {
        await queryClient.cancelQueries(PLAYLIST_CACHE_KEY, { exact: false })
        await queryClient.cancelQueries(MATCH_LIST_CACHE_KEY, { exact: false })

        const previousData =
          queryClient.getQueriesData<InfiniteQuery<PlaylistList>>(
            PLAYLIST_CACHE_KEY
          )

        const previousMatchData =
          queryClient.getQueriesData<InfiniteQuery<MatchList>>(
            MATCH_LIST_CACHE_KEY
          )

        const asset = getAssetFromMatchCollection(previousMatchData, [data.id])

        if (previousData && asset) {
          queryClient.setQueriesData<InfiniteQuery<PlaylistList> | undefined>(
            PLAYLIST_CACHE_KEY,
            oldData =>
              oldData && {
                pageParams: oldData.pageParams,
                pages: oldData.pages.map(
                  page =>
                    page && {
                      total: page.total,
                      results: updateAssetsInCollection<PlaylistList>(
                        page,
                        data
                      ),
                    }
                ),
              }
          )
        }

        if (previousMatchData) {
          queryClient.setQueriesData<InfiniteQuery<MatchList> | undefined>(
            MATCH_LIST_CACHE_KEY,
            oldData =>
              oldData && {
                pageParams: oldData.pageParams,
                pages: oldData.pages.map(
                  page =>
                    page && {
                      total: page.total,
                      results: updateAssetsInCollection<MatchList>(page, data),
                    }
                ),
              }
          )
        }

        return { previousData: [...previousData, ...previousMatchData] }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData(prevData[0], prevData[1])
          )
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(PLAYLIST_CACHE_KEY, { exact: false })
        queryClient.invalidateQueries(MATCH_LIST_CACHE_KEY, { exact: false })
      },
    }
  )
}
