import { call, takeLatest, select, getContext, delay } from 'redux-saga/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { setColor } from './telestration.slice'
import { ApiService } from 'services/Api.service'
import { selectMediaLocator } from 'modules/video-player/redux'
import { TelestrationGroupList } from '@sporttotal-tv/dip-coaching-client'
import { TELESTRATION_GROUPS_LIST_CACHE_KEY } from 'modules/telestration/hooks'
import { MediaLocator } from 'modules/video-player/types'
import { selectActiveShape } from './telestration.selectors'

function* changeColor(
  action: PayloadAction<{
    id: string
    color: string
  }>
) {
  // debounce by 500ms
  yield delay(500)

  try {
    const mediaLocator: MediaLocator = yield select(state =>
      selectMediaLocator(state, action.payload.id)
    )
    const activeShape: string | null | undefined = yield select(state =>
      selectActiveShape(state, action.payload.id)
    )
    const telestrationList: TelestrationGroupList = yield call(
      ApiService.telestrationGroup.list,
      {
        media_locator_id: mediaLocator?.id,
        expand: ['telestrations'],
        sort_by: 'created_at',
        page_limit: 100,
      }
    )

    if (!activeShape) return

    const groupWithShape = telestrationList?.results?.find(group =>
      group?.telestrations?.some(({ id }) => id === activeShape)
    )
    const shapeInfo = groupWithShape?.telestrations?.find(
      ({ id }) => id === activeShape
    )

    if (shapeInfo === undefined) return

    yield call(ApiService.telestration.update, activeShape, {
      data: { ...shapeInfo.data, stroke: action.payload.color },
    })

    // @ts-ignore
    const queryClient = yield getContext('queryClient')

    queryClient.invalidateQueries(TELESTRATION_GROUPS_LIST_CACHE_KEY)
  } catch (error) {
    yield call(console.error, error)
  }
}

export function* telestrationSaga() {
  yield takeLatest(setColor, changeColor)
}
