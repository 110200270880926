import { useCallback, useEffect, FC } from 'react'
import { useQueryClient } from 'react-query'
import { api } from 'modules/core/api'
import {
  OrganizationContext,
  OrganizationContextProviderProps,
} from 'modules/organization/context'
// Hooks
import {
  useUserOrganizations,
  useUserStorage,
  useUserStorageUpdate,
} from 'modules/user/hooks'
import { useIsAuthenticated } from 'modules/auth/hooks'
// Constants
import { USER_CONTENT_QUERY_CACHE_KEYS } from 'modules/core/constants'

export const OrganizationProvider: FC<OrganizationContextProviderProps> = ({
  children,
}) => {
  const queryClient = useQueryClient()
  const isAuthenticated = useIsAuthenticated()

  const { mutate } = useUserStorageUpdate()
  const { data: organizations, isFetched } = useUserOrganizations(undefined, {
    enabled: isAuthenticated,
  })

  const { data } = useUserStorage(
    {
      keys: ['activeOrganizationId'],
    },
    {
      enabled: isAuthenticated && isFetched,
    }
  )

  const activeOrganizationId = data?.activeOrganizationId ?? undefined

  const handleSetOrganizationId = useCallback(
    (organizationId?: string) =>
      mutate({ activeOrganizationId: organizationId ?? null }),
    [mutate]
  )

  useEffect(() => {
    const handleSwitchWorkspace = async () => {
      if (api.getBaseHeader('organization-id') === activeOrganizationId) return

      activeOrganizationId
        ? api.setBaseHeaders({ 'organization-id': activeOrganizationId })
        : api.removeBaseHeader('organization-id')

      await Promise.all(
        USER_CONTENT_QUERY_CACHE_KEYS.map(key => queryClient.cancelQueries(key))
      ).catch(console.error)

      for (const key of USER_CONTENT_QUERY_CACHE_KEYS) {
        queryClient.invalidateQueries(key)
      }
    }

    handleSwitchWorkspace()
  }, [activeOrganizationId, queryClient])

  return (
    <OrganizationContext.Provider
      value={{
        activeOrganizationId,
        organizations: organizations ?? [],
        setOrganization: handleSetOrganizationId,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  )
}
