import { LayoutComponent, LayoutIndex } from 'modules/layout/constants'
import { Translation } from 'modules/core/components/Translation'
import { playersLayoutConfig } from 'modules/layout/configs/PlayersLayoutConfig'

export const teamLayoutPalette: Record<string, LayoutComponent> = {
  [LayoutIndex.PLAYERS]: {
    icon: 'players',
    name: Translation('components:teamPlayers.toolbar.title'),
    layoutConfig: playersLayoutConfig,
  },
}
