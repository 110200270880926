import { routeNames } from 'modules/core/constants'

export const SUBSCRIPTION_FREE_ROUTES = new RegExp(
  [
    routeNames.subscription.relative,
    routeNames.redirecting.path,
    routeNames.help.relative,
    routeNames.profile.relative,
  ].join('|')
)
