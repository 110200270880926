import { styled } from '@mui/material'

export const StyledDeviceImage = styled('img')`
  width: 623px;
  height: 867px;
`

export const StyledFeaturesImage = styled('img')`
  width: 62px;
  height: 62px;
`
