import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import { DeleteUserCacheBody } from '@sporttotal-tv/dip-coaching-client'
import { USER_CACHE_RETRIEVE_CACHE_KEY } from 'modules/cache/hooks'

export const USER_CACHE_DELETE_CACHE_KEY = 'user-cache-delete'

export const useUserCacheDelete = () => {
  const queryClient = useQueryClient()

  return useMutation(
    [USER_CACHE_DELETE_CACHE_KEY],
    (params: DeleteUserCacheBody) => ApiService.cache.delete(params),
    {
      onSettled: (_, __, variables) => {
        const keys = Object.keys(variables)
        queryClient.invalidateQueries([USER_CACHE_RETRIEVE_CACHE_KEY, { keys }])
        for (const key of keys) {
          queryClient.invalidateQueries([
            USER_CACHE_RETRIEVE_CACHE_KEY,
            { keys: [key] },
          ])
        }
      },
    }
  )
}
