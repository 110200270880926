import { Avatar, css, Rating, styled, Typography } from '@mui/material'
import { ComponentBreakpoints } from 'modules/layout/types'
import { FieldPositions } from 'modules/sport/components/FieldPositions'

export const INFO_TITLE = 'info-title'
export const INFO_CONTENT = 'info-content'
export const STATUS_TITLE = 'status-title'
export const STATUS_CONTENT = 'status-content'
export const ATTRIBUTES_TITLE = 'attributes-title'
export const POSITION_CONTENT = 'position-content'
export const STATS_CONTENT = 'stats-content'
export const GRID_SPACE = '.'

const areaTemplates = {
  large: `
        '${INFO_TITLE} ${STATUS_TITLE} ${ATTRIBUTES_TITLE}'
        '${INFO_CONTENT} ${STATUS_CONTENT} ${POSITION_CONTENT}'
        '${INFO_CONTENT} ${STATUS_CONTENT} ${STATS_CONTENT}'
        `,
  smallHeight: `
        '${INFO_TITLE} ${STATUS_TITLE} ${ATTRIBUTES_TITLE} ${GRID_SPACE}'
        '${INFO_CONTENT} ${STATUS_CONTENT} ${POSITION_CONTENT} ${STATS_CONTENT}'
        `,
  mediumWidth: `
        '${INFO_TITLE} ${STATUS_TITLE} ${ATTRIBUTES_TITLE}'
        '${INFO_CONTENT} ${STATUS_CONTENT} ${POSITION_CONTENT}'
        `,
  smallWidth: `
        '${INFO_TITLE} ${STATUS_TITLE}'
        '${INFO_CONTENT} ${STATUS_CONTENT}'
        `,
  extraSmallWidthFull: ` 
        '${INFO_TITLE}'
        '${INFO_CONTENT}'
        '${STATUS_TITLE}'
        '${STATUS_CONTENT}'
        '${ATTRIBUTES_TITLE}'
        '${POSITION_CONTENT}'
        `,
  extraSmallWidthMedium: ` 
        '${INFO_TITLE}'
        '${INFO_CONTENT}'
        '${STATUS_TITLE}'
        '${STATUS_CONTENT}'
        `,
  extraSmallWidthSmall: ` 
        '${INFO_TITLE}'
        '${INFO_CONTENT}'
        `,
}

export const Root = styled('div')`
  height: 100%;
  width: 100%;
`

export const DataWrapper = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'heightBreakpoint' && prop !== 'widthBreakpoint',
})<{
  heightBreakpoint?: ComponentBreakpoints
  widthBreakpoint?: ComponentBreakpoints
}>`
  max-height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-template-rows: 44px auto;
  grid-template-areas: ${areaTemplates.large};

  ${({ heightBreakpoint }) =>
    (heightBreakpoint === 'xs' || heightBreakpoint === 'sm') &&
    css`
      grid-template-rows: 32px auto;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas: ${areaTemplates.smallHeight};
    `}

  ${({ widthBreakpoint }) =>
    widthBreakpoint === 'md' &&
    css`
      grid-template-rows: 32px auto;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas: ${areaTemplates.mediumWidth};
    `}

  ${({ widthBreakpoint }) =>
    widthBreakpoint === 'sm' &&
    css`
      grid-template-rows: 32px auto;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: ${areaTemplates.smallWidth};
    `}

  ${({ widthBreakpoint, heightBreakpoint }) =>
    widthBreakpoint === 'xs' &&
    heightBreakpoint === 'lg' &&
    css`
      grid-template-columns: auto;
      grid-template-rows: 32px auto 32px auto 32px auto;
      grid-template-areas: ${areaTemplates.extraSmallWidthFull};
    `}

  ${({ widthBreakpoint, heightBreakpoint }) =>
    widthBreakpoint === 'xs' &&
    heightBreakpoint === 'md' &&
    css`
      grid-template-columns: auto;
      grid-template-rows: 32px auto 32px auto;
      grid-template-areas: ${areaTemplates.extraSmallWidthMedium};
    `}

  ${({ widthBreakpoint, heightBreakpoint }) =>
    widthBreakpoint === 'xs' &&
    (heightBreakpoint === 'xs' || heightBreakpoint === 'sm') &&
    css`
      grid-template-columns: auto;
      grid-template-rows: 32px auto;
      grid-template-areas: ${areaTemplates.extraSmallWidthSmall};
    `}
`

export const NoPlayer = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Title = styled('div', {
  shouldForwardProp: prop => prop !== 'area' && prop !== 'hidden',
})<{ area?: string; hidden?: boolean }>`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;

  ${({ area }) => area && `grid-area: ${area};`}
  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`

export const Content = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'area' &&
    prop !== 'dividerDisabled' &&
    prop !== 'widthBreakpoint' &&
    prop !== 'hidden',
})<{
  area?: string
  dividerDisabled?: boolean
  widthBreakpoint?: ComponentBreakpoints
  hidden?: boolean
}>`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(2.25)};
  padding-right: ${({ theme }) => theme.spacing(1)};
  margin-right: ${({ theme }) => theme.spacing(2)};
  border-right: 1px solid ${({ theme }) => theme.palette.divider};

  ${({ area }) => area && `grid-area: ${area};`}

  ${({ dividerDisabled }) =>
    dividerDisabled &&
    css`
      border-right: none;
      margin-right: 0;
      padding-right: 0;
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    `}
  
    ${({ widthBreakpoint, theme }) =>
    widthBreakpoint === 'xs' &&
    css`
      row-gap: ${theme.spacing(1)};
      border-right: none;
      margin-right: 0;
      padding-right: 0;
      padding-bottom: ${theme.spacing(2)};
      margin-bottom: ${theme.spacing(1)};
      border-bottom: 1px solid ${theme.palette.divider};
    `}
  
    ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`

export const PlayerAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.palette.primary.light};
  border: 2px solid ${({ theme }) => theme.palette.primary.contrastText};
  width: 95px;
  height: 95px;
`

export const PhysicalAttributesWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.25)};
`

export const ItemWrapper = styled('div')`
  flex: 1;
`

export const StyledRating = styled(Rating)`
  font-size: 18px;
`

export const Label = styled(Typography)`
  font-size: 11px;
  line-height: 13px;
  color: ${({ theme }) => theme.palette.primary.light};
  padding-bottom: ${({ theme }) => theme.spacing(0.4)};
`

export const NoWrapSpan = styled('span')`
  white-space: nowrap;
`

export const AttributesLabel = styled(Typography, {
  shouldForwardProp: prop => prop !== 'hidden',
})<{ hidden?: boolean }>`
  font-size: 14px;
  line-height: 17px;
  color: ${({ theme }) => theme.palette.primary.light};

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`

export const Value = styled('div')`
  font-size: 13px;
  line-height: 20px;
`

export const IconWrapper = styled(Value)`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FootballField = styled(FieldPositions, {
  shouldForwardProp: prop => prop !== 'wrapperHeight' && prop !== 'smallView',
})<{ wrapperHeight: number; smallView?: boolean }>`
  svg.field {
    max-height: calc(
      ${({ wrapperHeight }) => wrapperHeight}px -
        ${({ theme }) => theme.spacing(4)}
    );
  }

  ${({ smallView, wrapperHeight }) =>
    smallView &&
    css`
      svg.field {
        max-height: ${wrapperHeight * 0.22}px;
      }
    `}
`

export const FlexBlock = styled('div', {
  shouldForwardProp: prop => prop !== 'compact',
})<{ compact?: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(2.25)};

  ${({ compact, theme }) =>
    compact &&
    css`
      flex-direction: row;
      column-gap: ${theme.spacing(1.25)};
    `}
`

export const FlexItem = styled('div', {
  shouldForwardProp: prop => prop !== 'compact',
})<{ compact?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(2.25)};

  ${({ compact, theme }) =>
    compact &&
    css`
      row-gap: ${theme.spacing(1)};
    `}
`

export const StatsWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'columnView',
})<{ columnView?: boolean }>`
  display: grid;
  align-items: flex-end;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  grid-template-columns: repeat(11, 1fr);

  ${({ columnView, theme }) =>
    columnView &&
    css`
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: auto;
      row-gap: ${theme.spacing(3)};
    `}
`

export const StatsItem = styled('div', {
  shouldForwardProp: prop => prop !== 'rowView',
})<{ rowView?: boolean }>`
  text-align: center;

  ${({ rowView, theme }) =>
    rowView &&
    css`
      padding-left: ${theme.spacing(1)};
      display: flex;
      align-items: center;
      column-gap: ${theme.spacing(2)};
      grid-column: span 2;
    `}
`

export const FoulWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.5)};
  font-size: 13px;
  line-height: 20px;
`

export const CardElement = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  align-items: center;
`

export const TeamSelectWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'spacing',
})<{ spacing?: boolean }>`
  max-width: 50%;

  ${({ spacing, theme }) =>
    spacing &&
    css`
      padding-right: ${theme.spacing(1)};
      margin-right: ${theme.spacing(2)};
    `}
`
