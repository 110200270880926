import { IKonvaMouseEvent, ShapeHandler } from 'modules/annotator/types'
import { ShapeOptions, TelestrationCircle } from 'modules/telestration/types'

const Circle: ShapeHandler<TelestrationCircle> = {
  onCreate({ currentTarget }: IKonvaMouseEvent, options?: ShapeOptions) {
    const points = currentTarget.getStage()?.getPointerPosition()
    const X = points?.x ?? null
    const Y = points?.y ?? null

    if (!X || !Y) return

    return {
      ...options,
      type: 'CIRCLE',
      x: X,
      y: Y,
    }
  },

  onShaping(circle, { currentTarget }) {
    const point = currentTarget.getStage()?.getPointerPosition()
    const X = point?.x ?? null

    if (!X) return
    const radius = X - circle.x!

    if (Math.sign(radius) !== 1) return circle

    return { ...circle, radius }
  },

  onShapeUpdated({ currentTarget }) {
    return currentTarget
  },
}

export { Circle }
