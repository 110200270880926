import { useCallback, useState, memo, ChangeEvent } from 'react'
import { HexColorPicker } from 'react-colorful'
import { useTranslation } from 'react-i18next'
// Components
import { ActionButton, Icon, ModalDialog } from 'modules/core/components'
import { Tooltip } from 'modules/core/styled'

import {
  ColorIndicator,
  ColorSelectLabel,
  StyledModal,
  ActionButtonWrapper,
  ToolbarActionDescriptionWrapper,
  PresentationDurationWrapper,
  ModalContent,
  FramePresentationDurationLabel,
  ToolbarInputPresentation,
} from './TelestrationToolbar.styled'
import { TelestrationFramePresentationCreateModalProps } from './TelestrationToolbar.interface'

export const TelestrationFramePresentationCreateModal =
  memo<TelestrationFramePresentationCreateModalProps>(
    ({ open, onClose, onSave }) => {
      const [color, setColor] = useState('#000000')
      const { t } = useTranslation('components', {
        keyPrefix: 'telestration',
      })
      const [presentationDuration, setPresentationDuration] =
        useState<string>('10')

      const handleSave = useCallback(() => {
        const duration = Number(presentationDuration)
        if (isNaN(duration) || duration <= 0) {
          return
        }
        onSave({
          color,
          duration,
        })
      }, [color, presentationDuration, onSave])

      const handleFramePresentationDurationChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
          setPresentationDuration(e.target.value)
        },
        []
      )

      return (
        <StyledModal>
          <ModalDialog
            title={t('dialog.createPresentation.title')}
            open={open}
            onClose={onClose}
          >
            <ModalContent>
              <PresentationDurationWrapper>
                <ToolbarActionDescriptionWrapper>
                  <Icon name='stopwatch' />
                  <FramePresentationDurationLabel>
                    {t('duration')}
                  </FramePresentationDurationLabel>
                </ToolbarActionDescriptionWrapper>
                <ToolbarInputPresentation
                  value={presentationDuration}
                  onChange={handleFramePresentationDurationChange}
                  type='number'
                  inputProps={{ 'aria-label': 'frame-presentation-seconds' }}
                  disableUnderline
                />
              </PresentationDurationWrapper>
              <div>
                <ColorSelectLabel>
                  {t('dialog.createPresentation.selectColor')}
                </ColorSelectLabel>
                <Tooltip
                  title={<HexColorPicker color={color} onChange={setColor} />}
                  placement='top'
                  backgroundColor='unset'
                >
                  <ColorIndicator color={color} />
                </Tooltip>
              </div>
              <ActionButtonWrapper>
                <ActionButton
                  text={t('actions.save')}
                  icon={'save'}
                  onClick={handleSave}
                />
              </ActionButtonWrapper>
            </ModalContent>
          </ModalDialog>
        </StyledModal>
      )
    }
  )
