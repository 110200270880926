import { styled, ButtonBase, css, Typography, Input } from '@mui/material'
import { LayoutItemToolbar } from 'modules/generic/components/ToolbarHeader/ToolbarHeader.styled'
import { ToolbarHeader as BaseToolbarHeader } from 'modules/generic/components/ToolbarHeader'
import { ToolbarControls as BaseToolbarControls } from 'modules/generic/components/ToolbarControls'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'

import { ActionButton } from 'modules/core/components/ActionButton'
import { IconButton } from 'modules/core/components/IconButton'
import { Icon } from 'modules/core/components/Icon'

export const GRID_TOOLBAR_HEADER = 'toolbar-header'
export const GRID_FRAME_ACTIONS = 'frame-actions'
export const GRID_DRAWING_TOOLS = 'drawing-tools'
export const GRID_ADDITIONAL_TOOLS = 'additional-tools'
export const GRID_ACTION_BUTTONS = 'action-buttons'
export const GRID_TOOLBAR_CONTROLS = 'toolbar-controls'

export const TelestrationToolbarContainer = styled('div')`
  padding: ${({ theme }) => theme.spacing(1)};
  min-height: ${({ theme }) => theme.componentToolbarDefaultHeight}px;
  container-type: inline-size;
  container-name: telestration-toolbar-wrapper;

  @container telestration-toolbar-wrapper (max-width: 960px) {
    & div.telestration-toolbar-grid-container {
      grid-template-columns: auto 1fr 1fr auto auto;
      grid-template-areas:
        '${GRID_TOOLBAR_HEADER} ${GRID_FRAME_ACTIONS} ${GRID_ADDITIONAL_TOOLS} ${GRID_ACTION_BUTTONS} ${GRID_TOOLBAR_CONTROLS}'
        '${GRID_DRAWING_TOOLS} ${GRID_DRAWING_TOOLS} ${GRID_DRAWING_TOOLS} ${GRID_DRAWING_TOOLS} ${GRID_DRAWING_TOOLS}';
    }
  }

  @container telestration-toolbar-wrapper (max-width: 620px) {
    & div.telestration-toolbar-grid-container {
      grid-template-columns: 1fr 1fr 1fr auto;
      grid-template-areas:
        '${GRID_TOOLBAR_HEADER} ${GRID_TOOLBAR_HEADER} ${GRID_ACTION_BUTTONS} ${GRID_TOOLBAR_CONTROLS}'
        '${GRID_FRAME_ACTIONS} ${GRID_FRAME_ACTIONS} ${GRID_ADDITIONAL_TOOLS} ${GRID_ADDITIONAL_TOOLS}'
        '${GRID_DRAWING_TOOLS} ${GRID_DRAWING_TOOLS} ${GRID_DRAWING_TOOLS} ${GRID_DRAWING_TOOLS}';
    }
  }
`

export const TelestrationToolbarGridContainer = styled('div')`
  display: grid;
  row-gap: 10px;
  grid-template-columns: minmax(170px, 1fr) 1fr 2fr 1fr 1fr auto;
  grid-template-rows: auto;

  grid-template-areas: '${GRID_TOOLBAR_HEADER} ${GRID_FRAME_ACTIONS} ${GRID_DRAWING_TOOLS} ${GRID_ADDITIONAL_TOOLS} ${GRID_ACTION_BUTTONS} ${GRID_TOOLBAR_CONTROLS}';
`

export const TelestrationActionContainer = styled('div')`
  display: flex;
`

export const ToolbarHeader = styled(BaseToolbarHeader)`
  flex: unset;
`

export const ToolbarControls = styled(BaseToolbarControls)``

export const AreaWrapper = styled('div')`
  display: flex;
  align-items: center;
`

export const ToolbarFrameWrapper = styled('div')`
  display: flex;
  align-items: center;
  cursor: default;
  column-gap: ${({ theme }) => theme.spacing(1)};
`

export const ToolbarActionDescription = styled(Typography)`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.primary.light};
`

export const FramePresentationDurationLabel = styled(ToolbarActionDescription)`
  color: ${({ theme }) => theme.palette.common.white};
`

export const ToolbarActionDescriptionWrapper = styled('div')`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  padding-left: ${({ theme }) => theme.spacing(0.75)};
  color: white !important;

  & path {
    fill: ${({ theme }) => theme.palette.common.white};
  }
`

export const ToolbarInput = styled(Input, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>`
  width: 62px;
  height: 25px;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  padding: 0;
  transition: border 0.3s;
  border: 1px solid transparent;

  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.palette.error.main};
    `}

  & .MuiInput-input {
    padding: 0 4px;
    font-size: 14px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
`

export const ToolbarInputPresentation = styled(ToolbarInput)`
  background-color: ${({ theme }) => theme.palette.darkest};
`

export const ToolbarFrameItem = styled('div', {
  shouldForwardProp: prop => prop !== 'disabled',
})<{ disabled?: boolean }>`
  background-color: ${({ theme }) => theme.palette.darkest};
  border-radius: 50%;
  transition: opacity 0.3s;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`

export const Toolbar = styled(LayoutItemToolbar)`
  column-gap: unset;
  height: 30px;
`

export const ColorIndicator = styled(ButtonBase)<{ color: string }>`
  height: 30px;
  aspect-ratio: 1/1;

  &:after {
    content: '';
    border-radius: 2px;
    border: 1px solid ${({ theme }) => theme.palette.divider};
    height: 65%;
    aspect-ratio: 1/1;
    background-color: ${({ color }) => color};
  }
`

export const TelestrationIconButton = styled(IconButton)`
  width: 30px;
  height: 30px;
  position: relative;

  &:disabled {
    &::after {
      position: absolute;
      content: '';
      left: 0;
      top: 50%;
      width: 60%;
      height: 1px;
      background-color: ${({ theme }) => theme.palette.primary.light};
      border-radius: 2px;
      transform: translateX(30%) rotate(-130deg);
    }
  }
`

export const TelestrationFrameButton = styled(TelestrationIconButton, {
  shouldForwardProp: prop => prop !== 'active',
})<{ active?: boolean }>`
  transition: background-color 0.3s, color 0.3s;

  color: ${({ theme }) => theme.palette.primary.light};

  & path {
    transition: fill 0.3s;
  }

  &:disabled {
    &::after {
      position: absolute;
      content: '';
      left: 0;
      widows: 0;
      height: 0;
    }
  }

  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.palette.primary.light};
      color: ${theme.palette.common.white};

      & path {
        fill: ${theme.palette.common.white};
      }

      &:hover {
        background-color: ${theme.palette.primary.light};
      }
    `}
`

export const TelestrationActionWrapper = styled('div')`
  position: relative;
`

export const Triangle = styled('div', {
  shouldForwardProp: prop => prop !== 'active',
})<{ active?: boolean }>`
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 6px 6px;
  border-color: transparent transparent
    ${({ theme }) => theme.palette.primary.light} transparent;

  right: 0;
  bottom: 0;
  transform: translate(-100%, -100%);

  ${({ active, theme }) =>
    active &&
    css`
      border-color: transparent transparent ${theme.palette.common.white}
        transparent;
    `}
`

export const SubActionsWrapper = styled('div')`
  display: flex;
  background-color: ${({ theme }) => theme.palette.primary.main};
`

export const ControlButtonsWrapper = styled('div')`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(1)};
`

export const CloseButton = styled(ActionButton)`
  background-color: ${({ theme }) => theme.palette.secondary.light};
`

export const AcceptIconButton = styled(IconButton)`
  padding: 4px;
`

export const AcceptIcon = styled(Icon)`
  height: 8px;
  width: 8px;
`

export const PresentationIcon = styled(OndemandVideoIcon)`
  fill: ${({ theme }) => theme.palette.primary.light};
  height: 16px;
  width: 16px;
`

export const ColorSelectLabel = styled(Typography)`
  font-size: 14px;
  padding-left: ${({ theme }) => theme.spacing(0.75)};
`

export const StyledModal = styled('div')`
  & .ModalContent {
    width: 20%;
  }
`

export const ActionButtonWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const PresentationDurationWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1)};
`

export const ModalContent = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(2)};
`
