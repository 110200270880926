import { FC, useContext, useEffect, useState } from 'react'
import { Typography } from '@mui/material'
// Hooks
import { useTranslation } from 'react-i18next'
import { useMatch } from 'modules/match/hooks'
// Components
import { MatchPeriodToolbar } from 'modules/match-period/components'
// Context
import { ComponentInfoContext } from 'modules/generic/context'
import { MatchPeriodContext } from 'modules/match-period/context'
// Images
import TeamFallbackImage from 'assets/images/team-fallback.png'
//  Utils
import { parseMatchStatistics } from 'modules/match-info/utils'

import {
  Root,
  StatisticsContainer,
  StatisticsHeader,
  TeamContainer,
  TeamIcon,
  Divider,
  Statistic,
  StatisticText,
  MiddleColumn,
  NoStatsMessage,
} from './Statistics.styled'
import { Statistic as StatisticType } from 'modules/match-info/types'

const Statistics: FC = () => {
  const { t } = useTranslation(['core', 'components'])
  const { componentId, layoutIndex } = useContext(ComponentInfoContext)
  const { matchPeriod, setMatchPeriod } = useContext(MatchPeriodContext)

  const { match, matchData } = useMatch()
  const [statistics, setStatistics] = useState<StatisticType[]>([])

  const homeTeam = match?.teams?.at(0)
  const awayTeam = match?.teams?.at(1)

  useEffect(() => setMatchPeriod && setMatchPeriod('first_half'), [match])

  useEffect(() => {
    if (!matchData) return
    if (!(matchPeriod in matchData)) return

    // @ts-ignore-next-line
    const periodStatistics = matchData[matchPeriod]?.statistics
    if (!periodStatistics) return

    fetch(periodStatistics)
      .then(r => r.json())
      .then(stats => setStatistics(parseMatchStatistics(stats)))
      .catch(console.error)
  }, [matchData, matchPeriod])

  return (
    <div>
      <MatchPeriodToolbar layoutId={componentId} layoutIndex={layoutIndex} />
      <Root>
        <StatisticsHeader>
          <TeamContainer>
            <TeamIcon
              src={homeTeam?.logo_url ?? TeamFallbackImage}
              alt={`${t('core:team.home')} Badge`}
            />
            <Typography>{t('core:team.home')}</Typography>
          </TeamContainer>
          <MiddleColumn>{t('components:statistics.title')}</MiddleColumn>
          <TeamContainer>
            <TeamIcon
              src={awayTeam?.logo_url ?? TeamFallbackImage}
              alt={`${t('core:team.away')} Badge`}
            />
            <Typography>{t('core:team.away')}</Typography>
          </TeamContainer>
        </StatisticsHeader>
        <Divider />
        <StatisticsContainer>
          {statistics.length === 0 && (
            <NoStatsMessage>
              {t('components:statistics.no_data')}
            </NoStatsMessage>
          )}
          {statistics.map(({ name, homeTeamValue, awayTeamValue }, index) => (
            <Statistic key={index}>
              <StatisticText>
                {typeof homeTeamValue === 'number'
                  ? homeTeamValue.toFixed(2)
                  : homeTeamValue}
              </StatisticText>
              {/* TODO: Create Dictionary so stat names can work with translations */}
              <MiddleColumn>{name.replaceAll(/_/g, ' ')}</MiddleColumn>
              <StatisticText>
                {typeof awayTeamValue === 'number'
                  ? awayTeamValue.toFixed(2)
                  : awayTeamValue}
              </StatisticText>
            </Statistic>
          ))}
        </StatisticsContainer>
      </Root>
    </div>
  )
}

export { Statistics }
