import { useEffect, useRef } from 'react'
import { Transformer as KonvaTransformer } from 'react-konva'
import { IKonvaTransformer, IKonvaShape } from 'modules/annotator/types'
import { TelestrationShapesTransformParams } from 'modules/annotator/constants'
import { TelestrationTool } from 'modules/telestration/types'

interface TransformerProps {
  shape?: IKonvaShape
  selectionShape?: IKonvaShape
  allowShapeRotate?: boolean
  allowShapeResize?: boolean
}

const Transformer = ({
  shape,
  selectionShape,
  allowShapeRotate = true,
  allowShapeResize = true,
}: TransformerProps) => {
  const transformerRef = useRef<IKonvaTransformer>(null)
  // Transform params that apply when we click on the shape on video
  const shapeToolType = shape?.getAttr('shapeToolType')
  const transformParams =
    shapeToolType &&
    TelestrationShapesTransformParams[shapeToolType as TelestrationTool]

  useEffect(() => {
    const transformer = transformerRef.current
    if (!shape || !transformer) return

    if (selectionShape) {
      transformer.nodes([selectionShape, shape])
    } else {
      transformer.nodes([shape])
    }

    return () => {
      transformer.detach()
      transformer.getStage()?.batchDraw()
    }
  }, [shape, selectionShape])

  if (transformParams?.isCustom) {
    return null
  }

  return (
    <KonvaTransformer
      ref={transformerRef}
      resizeEnabled={allowShapeResize}
      rotateEnabled={allowShapeRotate}
      borderStrokeWidth={1.5}
      {...transformParams}
    />
  )
}

export { Transformer }
