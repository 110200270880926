const isNumberBetween = (number: number, min: number, max: number) => {
  if (isNaN(number) || isNaN(min) || isNaN(max))
    throw new Error('Parameters are NaN')

  if (min > max) throw new Error('Min & Max have no overlap!')

  if (number < min) return false
  if (number > max) return false

  return true
}

export { isNumberBetween }
