import { styled } from '@mui/material/styles'
import { css, FormHelperText, FormLabel, Input } from '@mui/material'

export const InputWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.75)};
  margin-bottom: ${({ theme }) => theme.spacing(0.75)};
`

export const Label = styled(FormLabel)`
  font-size: 12px;
  text-align: right;
  position: relative;
  flex: 1;
  padding: 10px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const FormControlWrapper = styled('div')`
  flex: 3;
`

export const InputText = styled(Input, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.darkest};
  padding: 0;
  transition: border 0.3s;
  border: 1px solid transparent;

  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.palette.error.main};
    `}

  & .MuiInput-input {
    padding: 8px;
    font-size: 14px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
`

export const ErrorMessage = styled(FormHelperText)`
  margin: ${({ theme }) => theme.spacing(0.25, 1, 0)};
  color: ${({ theme }) => theme.palette.error.main};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
