import { useMemo, useState, useCallback, FC } from 'react'
// Hooks
import { useTranslation } from 'react-i18next'
// Components
import {
  IconButton,
  LoaderContainer,
  Autocomplete,
} from 'modules/core/components'
// Utils
import { get } from 'lodash'
// Constants
import { ADMIN_RESOURCE_PAGE_LIMIT } from 'modules/user/constants'
import { RELATION_EDIT_HOOKS } from './constants'
// Types
import { RelationEditProps, BaseRelationData } from './RelationEdit.interface'

import {
  AddButton,
  ResultContainer,
  ResultName,
  ResultRow,
} from './RelationEdit.styled'

const DEFAULT_LIST_ARGS = { page_limit: ADMIN_RESOURCE_PAGE_LIMIT }

export const RelationEdit: FC<RelationEditProps> = ({
  entity,
  resource,
  entityId,
}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'profile' })
  const [selectedItem, setSelectedItem] = useState<string>('')

  const {
    useList,
    listItemRenderPath = 'name',
    ...entities
  } = RELATION_EDIT_HOOKS[resource]
  const { useListRelations, useCreate, useDelete } = entities[entity]

  const createRelation = useCreate()
  const deleteRelation = useDelete()

  const relationsFilterKey = entity === 'user' ? 'user_id' : 'organization_id'
  const relationsFilter = useMemo(
    () => ({ [relationsFilterKey]: entityId }),
    [relationsFilterKey, entityId]
  )

  const listData = useList({
    ...DEFAULT_LIST_ARGS,
  })

  // TODO: Fix weak typing in this file
  // Our API does not allow extra fields and this ignore removes the type checking from the API.
  // We really need to fix this and enforce strict type-checking!
  const listRelations = useListRelations({
    ...DEFAULT_LIST_ARGS,
    ...relationsFilter,
    expand: [resource],
  })

  const listOptions = useMemo(() => {
    return (
      listData.data?.results?.map(item => ({
        label: get(item, listItemRenderPath, item.id),
        value: item.id,
      })) ?? []
    )
  }, [listData.data, listItemRenderPath])

  const handleAdd = useCallback(() => {
    if (selectedItem === '') return

    createRelation.mutate({
      ...relationsFilter,
      [`${resource}_id`]: selectedItem,
    })
  }, [selectedItem, createRelation, resource, relationsFilter])

  const handleDelete = useCallback(
    (id: string) => () => {
      deleteRelation.mutate(id)
    },
    [deleteRelation]
  )

  const isDisabled = useMemo(() => {
    return (
      listRelations.data?.results
        .map(result => result[`${resource}_id`])
        .includes(selectedItem) ||
      selectedItem === '' ||
      createRelation.isLoading
    )
  }, [createRelation.isLoading, selectedItem, listRelations.data, resource])

  const renderName = (relation: BaseRelationData) => {
    const resourceData = relation[resource]
    return get(resourceData, listItemRenderPath, relation.id)
  }

  const isLoading = useMemo(() => {
    return (
      listRelations.isFetching ||
      createRelation.isLoading ||
      deleteRelation.isLoading
    )
  }, [
    listRelations.isFetching,
    createRelation.isLoading,
    deleteRelation.isLoading,
  ])

  return (
    <>
      <Autocomplete
        onChange={value => value && setSelectedItem(value)}
        options={listOptions}
        inputProps={{
          'aria-label': resource,
        }}
      />
      <AddButton
        onClick={handleAdd}
        disabled={isDisabled}
        color='secondary'
        variant='contained'
      >
        {t('form.buttons.add')}
      </AddButton>
      <ResultContainer>
        <LoaderContainer isLoading={isLoading}>
          {listRelations.data?.results.map(relation => (
            <ResultRow key={relation.id}>
              <ResultName>{renderName(relation)}</ResultName>
              <IconButton name='close' onClick={handleDelete(relation.id)} />
            </ResultRow>
          ))}
        </LoaderContainer>
      </ResultContainer>
    </>
  )
}
