import { css, MenuItem, Select, SelectProps, styled } from '@mui/material'
import { forwardRef } from 'react'

export const OptionsSelector = styled(
  forwardRef<unknown, SelectProps>((props, ref) => (
    <Select
      ref={ref}
      MenuProps={{
        sx: {
          '& .MuiList-root': {
            padding: 0,
          },
        },
      }}
      {...props}
    />
  )),
  {
    shouldForwardProp: prop => prop !== 'error',
  }
)<{ error?: boolean }>`
  background-color: ${({ theme }) => theme.palette.darkest};
  padding: 0;
  font-size: 14px;
  outline: none;
  border-radius: 0;

  & .MuiSelect-select {
    padding: 8px;
    border: 1px solid transparent;
  }

  & .MuiOutlinedInput-notchedOutline {
    outline: none;
    border: none;
    border-radius: 0;
  }

  ${({ error, theme }) =>
    error &&
    css`
      & .MuiSelect-select {
        border-radius: 0;
        border: 1px solid ${theme.palette.error.main};
      }
    `}
`

export const OptionsSelectorMenuItem = styled(MenuItem)`
  font-size: 14px;

  background-color: ${({ theme }) => theme.palette.darkest};
  color: ${({ theme }) => theme.palette.primary.light};

  &:hover {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background-color: ${({ theme }) => theme.palette.background.default};
  }

  &:focus {
    background-color: ${({ theme }) => theme.palette.darkest};
    color: ${({ theme }) => theme.palette.primary.light};

    &:hover {
      color: ${({ theme }) => theme.palette.primary.contrastText};
      background-color: ${({ theme }) => theme.palette.background.default};
    }
  }
`
