import { createContext, FC, useCallback, useState } from 'react'

import {
  IVideoPlayersProvider,
  IVideoPlayersContext,
} from './VideoPlayersContext.interface'
import { MediaPlayer } from 'modules/media-render/types/media-render.types'

export const VideoPlayersContext = createContext<IVideoPlayersContext>({
  removePlayer: () => {},
  addPlayer: () => {},
  players: {},
})

export const VideoPlayersContextProvider: FC<IVideoPlayersProvider> = ({
  children,
}) => {
  const [players, setPlayers] = useState<Record<string, MediaPlayer>>({})

  const removePlayer = useCallback((componentId: string) => {
    setPlayers(prevPlayers => {
      const { [componentId]: _, ...rest } = prevPlayers
      return rest
    })
  }, [])

  const addPlayer = useCallback((player: MediaPlayer, componentId: string) => {
    setPlayers(prev => ({ ...prev, [componentId]: player }))
  }, [])

  return (
    <VideoPlayersContext.Provider
      value={{
        players,
        addPlayer,
        removePlayer,
      }}
    >
      {children}
    </VideoPlayersContext.Provider>
  )
}
