import { PlayerInjury } from '@sporttotal-tv/dip-coaching-client'
import { teamPlayerMockup } from './teamPlayer.mockup'

export const teamPlayerInjuryMockup: PlayerInjury = {
  id: '1',
  current_injury: 'Knee injury',
  date_of_injury: new Date().getTime() / 1000,
  rehab_period_min: 2,
  rehab_period_max: 4,
  rehab_period: 'DAY',
  injury_status: 'INJURY',
  player_id: 'player_id',
  player: teamPlayerMockup,
  created_at: new Date().getTime() / 1000,
  updated_at: new Date().getTime() / 1000,
}
