import { RootState } from 'redux/store'
import { createSelector } from '@reduxjs/toolkit'
import { defaultZoomLevel } from 'modules/telestration/constants'
import { theme } from 'theme'
import { telestrationAdapter } from './telestration.slice'

export const { selectById: selectTelestrationById } =
  telestrationAdapter.getSelectors<RootState>(state => state.telestration)

export const selectTelestrationActiveData = createSelector(
  selectTelestrationById,
  state => ({
    activeGroup: state?.activeGroup,
    activeShape: state?.activeShape,
    shapeOptions: state?.shapeOptions,
  })
)

export const selectActiveShape = createSelector(
  selectTelestrationById,
  state => state?.activeShape
)

// TODO: move to a defaultzoom variable
export const selectZoom = createSelector(
  selectTelestrationById,
  state => state?.zoom ?? defaultZoomLevel
)

export const selectTool = createSelector(
  selectTelestrationById,
  state => state?.tool
)

export const selectToolsData = createSelector(
  selectTelestrationById,
  state => ({
    zoom: state?.zoom ?? defaultZoomLevel,
    tool: state?.tool,
    color: state?.color ?? theme.palette.secondary.main,
  })
)
