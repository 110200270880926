import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import { UpdateUserBody, User } from '@sporttotal-tv/dip-coaching-client'
import { USER_RETRIEVE_CACHE_KEY } from 'modules/user/hooks'
import { MutateContext } from 'modules/core/types/mutate-context.type'

export const USER_UPDATE_CACHE_KEY = 'user-update'

export const useUserUpdate = () => {
  const queryClient = useQueryClient()

  return useMutation<User, Error, UpdateUserBody, MutateContext<User>>(
    USER_UPDATE_CACHE_KEY,
    (params: UpdateUserBody) => ApiService.user.update(params),
    {
      onMutate: async data => {
        await queryClient.cancelQueries(USER_RETRIEVE_CACHE_KEY, {
          exact: false,
        })

        const previousData = queryClient.getQueriesData<User>(
          USER_RETRIEVE_CACHE_KEY
        )

        if (previousData) {
          queryClient.setQueriesData<User | undefined>(
            USER_RETRIEVE_CACHE_KEY,
            oldData => oldData && { ...oldData, ...data }
          )
        }

        return { previousData }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData<User>(prevData[0], prevData[1])
          )
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(USER_RETRIEVE_CACHE_KEY, { exact: false })
      },
    }
  )
}
