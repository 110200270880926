import { v4 } from 'uuid'
import { playerLayoutConfig } from '../PlayerLayoutConfig'
import { playerControlsLayoutConfig } from '../PlayerControlsLayoutConfig'
import { telestrationLayoutConfig } from '../TelestrationLayoutConfig'

export const comparisonLayoutPlayerOneId = v4()
export const comparisonLayoutPlayerOneControlsId = v4()
export const comparisonLayoutTelestrationOneId = v4()

export const comparisonLayoutPlayerTwoId = v4()
export const comparisonLayoutPlayerTwoControlsId = v4()
export const comparisonLayoutTelestrationTwoId = v4()

export const COMPARISON_TAB_LAYOUT = {
  lg: [
    { ...playerLayoutConfig.lg, i: comparisonLayoutPlayerOneId, h: 10, w: 6 },
    {
      ...playerControlsLayoutConfig.lg,
      i: comparisonLayoutPlayerOneControlsId,
      y: 10,
      x: 0,
      w: 6,
      h: 3,
    },
    {
      ...telestrationLayoutConfig.lg,
      i: comparisonLayoutTelestrationOneId,
      y: 13,
      x: 0,
      w: 6,
      h: 7,
    },

    {
      ...playerLayoutConfig.lg,
      i: comparisonLayoutPlayerTwoId,
      h: 10,
      w: 6,
      x: 6,
    },
    {
      ...playerControlsLayoutConfig.lg,
      i: comparisonLayoutPlayerTwoControlsId,
      y: 10,
      x: 6,
      w: 6,
      h: 3,
    },
    {
      ...telestrationLayoutConfig.lg,
      i: comparisonLayoutTelestrationTwoId,
      y: 13,
      x: 6,
      w: 6,
      h: 7,
    },
  ],
  md: [
    { ...playerLayoutConfig.md, i: comparisonLayoutPlayerOneId, h: 10, w: 6 },
    {
      ...playerControlsLayoutConfig.md,
      i: comparisonLayoutPlayerOneControlsId,
      y: 10,
      x: 0,
      w: 6,
      h: 3,
    },
    {
      ...telestrationLayoutConfig.md,
      i: comparisonLayoutTelestrationOneId,
      y: 13,
      x: 0,
      w: 6,
      h: 7,
    },

    {
      ...playerLayoutConfig.md,
      i: comparisonLayoutPlayerTwoId,
      h: 10,
      w: 6,
      x: 6,
    },
    {
      ...playerControlsLayoutConfig.md,
      i: comparisonLayoutPlayerTwoControlsId,
      y: 10,
      x: 6,
      w: 6,
      h: 3,
    },
    {
      ...telestrationLayoutConfig.md,
      i: comparisonLayoutTelestrationTwoId,
      y: 13,
      x: 6,
      w: 6,
      h: 7,
    },
  ],
  sm: [
    { ...playerLayoutConfig.sm, i: comparisonLayoutPlayerOneId, h: 10, w: 6 },
    {
      ...playerControlsLayoutConfig.sm,
      i: comparisonLayoutPlayerOneControlsId,
      y: 10,
      x: 0,
      w: 6,
      h: 3,
    },
    {
      ...telestrationLayoutConfig.sm,
      i: comparisonLayoutTelestrationOneId,
      y: 13,
      x: 0,
      w: 6,
      h: 7,
    },

    {
      ...playerLayoutConfig.sm,
      i: comparisonLayoutPlayerTwoId,
      h: 10,
      w: 6,
      x: 6,
    },
    {
      ...playerControlsLayoutConfig.sm,
      i: comparisonLayoutPlayerTwoControlsId,
      y: 10,
      x: 6,
      w: 6,
      h: 3,
    },
    {
      ...telestrationLayoutConfig.sm,
      i: comparisonLayoutTelestrationTwoId,
      y: 13,
      x: 6,
      w: 6,
      h: 7,
    },
  ],
  xs: [
    { ...playerLayoutConfig.xs, i: comparisonLayoutPlayerOneId, h: 10, w: 6 },
    {
      ...playerControlsLayoutConfig.xs,
      i: comparisonLayoutPlayerOneControlsId,
      y: 10,
      x: 0,
      w: 6,
      h: 3,
    },
    {
      ...telestrationLayoutConfig.xs,
      i: comparisonLayoutTelestrationOneId,
      y: 13,
      x: 0,
      w: 6,
      h: 7,
    },

    {
      ...playerLayoutConfig.xs,
      i: comparisonLayoutPlayerTwoId,
      h: 10,
      w: 6,
      x: 6,
    },
    {
      ...playerControlsLayoutConfig.xs,
      i: comparisonLayoutPlayerTwoControlsId,
      y: 10,
      x: 6,
      w: 6,
      h: 3,
    },
    {
      ...telestrationLayoutConfig.xs,
      i: comparisonLayoutTelestrationTwoId,
      y: 13,
      x: 6,
      w: 6,
      h: 7,
    },
  ],
  xxs: [
    { ...playerLayoutConfig.xxs, i: comparisonLayoutPlayerOneId, h: 10, w: 6 },
    {
      ...playerControlsLayoutConfig.xxs,
      i: comparisonLayoutPlayerOneControlsId,
      y: 10,
      x: 0,
      w: 6,
      h: 3,
    },
    {
      ...telestrationLayoutConfig.xxs,
      i: comparisonLayoutTelestrationOneId,
      y: 13,
      x: 0,
      w: 6,
      h: 7,
    },

    {
      ...playerLayoutConfig.xxs,
      i: comparisonLayoutPlayerTwoId,
      h: 10,
      w: 6,
      x: 6,
    },
    {
      ...playerControlsLayoutConfig.xxs,
      i: comparisonLayoutPlayerTwoControlsId,
      y: 10,
      x: 6,
      w: 6,
      h: 3,
    },
    {
      ...telestrationLayoutConfig.xxs,
      i: comparisonLayoutTelestrationTwoId,
      y: 13,
      x: 6,
      w: 6,
      h: 7,
    },
  ],
}
