import { css, LinearProgress, styled, Typography } from '@mui/material'

export const Root = styled('div')`
  position: relative;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.25)};
  align-items: flex-start;
  justify-content: flex-end;

  padding: ${({ theme }) => theme.spacing(1.5, 0)};
  margin: ${({ theme }) => theme.spacing(0, 2)};

  &:last-of-type {
    padding-bottom: 0;
  }
`

export const EventIconWrapper = styled('div')`
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  margin-right: ${({ theme }) => theme.spacing(0.5)};
  display: flex;
  justify-content: flex-start;

  & svg {
    width: 100%;
    height: 100%;
  }
`

export const MainInfoWrapper = styled('div')`
  width: 95%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
`

export const ClipInfoWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(2)};
`

export const ClipInfoItem = styled('div')`
  flex: 1;
  overflow: hidden;
  position: relative;
`

export const TitleRow = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const Title = styled(Typography)`
  cursor: grabbing;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 18px;
  flex: 1;
  color: ${({ theme }) => theme.palette.common.white};
  margin: ${({ theme }) => theme.spacing(0.5, 0)};
`

export const ClipDescription = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.light};
`

export const ClipDescriptionWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(2)};
`

export const ClipLastEditedWrapper = styled(ClipDescriptionWrapper)`
  margin-bottom: ${({ theme }) => theme.spacing(1.25)};
`

export const ClipPreviewInfoItem = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(1.25)};
  flex: 1;
  overflow: hidden;
  position: relative;
  cursor: pointer;
`

export const ClipPreviewImage = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const Play = styled('div', {
  shouldForwardProp: prop => prop !== 'active',
})<{ active?: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  width: 21px;
  height: 24px;
  background-color: ${({ theme }) => theme.palette.common.white};
  transform: translate(-50%, -50%);
  opacity: 0.7;
  clip-path: polygon(100% 50%, 0 0, 0 100%);

  ${({ active }) =>
    active &&
    css`
      cursor: pointer;
    `}
`

export const DownloadWrapper = styled('div')`
  width: 100%;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
`

export const DownloadProgress = styled(LinearProgress)`
  flex: 1;

  background-color: ${({ theme }) => theme.palette.secondary.light};

  .MuiLinearProgress-bar {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`
