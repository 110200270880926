import { useContext, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import ReactCountryFlag from 'react-country-flag'
// Hooks
import { useTError } from 'modules/core/i18n/hooks'
import { useUser, useUserUpdate } from 'modules/user/hooks'
import { useCustomerPortal } from 'modules/stripe/hooks'
import { useSubscription } from 'modules/subscription/hooks'
// Context
import { SnackbarContext } from 'modules/core/context'
// schema
import { profileSchema } from 'modules/user/schemas'
// constants
import { countryDictionary } from 'modules/core/constants'
// components
import { Icon } from 'modules/core/components'

import {
  FormContainer,
  Header,
  Main,
  SaveButton,
  Title,
  Label,
  InputText,
  InputWrapper,
  FullWidthFormControl,
  Row,
  EditContainer,
  SaveContainer,
  ErrorMessage,
  AutocompleteOption,
  StyledAutocomplete,
  InputTextField,
  StyledPopper,
  StaticWrapper,
  StaticText,
  StaticButton,
  DescriptionComment,
} from './ProfileEdit.styled'
import { ProfileForm } from './ProfileEdit.interface'

export const ProfileEdit = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ProfileForm>({
    resolver: yupResolver(profileSchema),
  })

  const { showSnackbar } = useContext(SnackbarContext)
  const { t } = useTranslation('pages', { keyPrefix: 'profile' })
  const { tError } = useTError('pages', { keyPrefix: 'profile' })
  const { data: customerPortal, refetch } = useCustomerPortal()
  const { data: subscription } = useSubscription()

  const user = useUser()
  const userUpdate = useUserUpdate()

  const countryOptions = useMemo(() => {
    return (
      Object.entries(countryDictionary).map(([countryKey, country]) => ({
        label: country,
        value: countryKey,
      })) ?? []
    )
  }, [])

  const handleOpenEditEmailLink = () => {
    window.open('https://sso.sporttotal.tv/users/edit', '_blank')
  }

  const handleOpenCustomerPortal = () => {
    refetch()
    window.open(customerPortal?.url, '_blank')
  }

  const onSubmit = (data: ProfileForm) => {
    userUpdate.mutate(
      {
        firstname: data.firstname,
        lastname: data.lastname,
      },
      {
        onSuccess: () => {
          showSnackbar({
            type: 'success',
            message: t('notification.profileUpdated'),
          })
        },
      }
    )
  }

  if (!user.isFetched)
    return (
      <Main>
        <Header>
          <Title>{t('form.profile.update.disabled')}</Title>
        </Header>
      </Main>
    )

  return (
    <Main>
      <Header>
        <Title>{t('form.profile.update.title')}</Title>
      </Header>
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <EditContainer>
            <Row>
              <InputWrapper>
                <Label>{t('form.profile.update.email')}</Label>
                <StaticWrapper>
                  <StaticText>{user.data?.email ?? ''}</StaticText>
                  <StaticButton onClick={handleOpenEditEmailLink}>
                    <Icon name='pencil' />
                    <div>Manage by Sporttotal</div>
                  </StaticButton>
                </StaticWrapper>
              </InputWrapper>
              <InputWrapper>
                <Label>
                  {t('form.profile.update.address')}
                  <sup>*</sup>
                </Label>
                <FullWidthFormControl>
                  <InputText
                    error={!!errors.address?.message}
                    inputProps={{ 'aria-label': 'address' }}
                    defaultValue={''}
                    disableUnderline
                    {...register('address')}
                  />
                  <ErrorMessage>{tError(errors.address?.message)}</ErrorMessage>
                </FullWidthFormControl>
              </InputWrapper>
            </Row>
            <Row>
              <InputWrapper>
                <Label>
                  {t('form.profile.update.firstName')}
                  <sup>*</sup>
                </Label>
                <FullWidthFormControl>
                  <InputText
                    error={!!errors.firstname?.message}
                    inputProps={{ 'aria-label': 'firstname' }}
                    defaultValue={user.data?.firstname}
                    disableUnderline
                    {...register('firstname')}
                  />
                  <ErrorMessage>
                    {tError(errors.firstname?.message)}
                  </ErrorMessage>
                </FullWidthFormControl>
              </InputWrapper>
              <InputWrapper>
                <Label>
                  {t('form.profile.update.lastName')}
                  <sup>*</sup>
                </Label>
                <FullWidthFormControl>
                  <InputText
                    error={!!errors.lastname?.message}
                    inputProps={{ 'aria-label': 'lastname' }}
                    defaultValue={user.data?.lastname}
                    disableUnderline
                    {...register('lastname')}
                  />
                  <ErrorMessage>
                    {tError(errors.lastname?.message)}
                  </ErrorMessage>
                </FullWidthFormControl>
              </InputWrapper>
            </Row>
            <Row>
              <InputWrapper>
                <Label>{t('form.profile.update.country')}</Label>
                <FullWidthFormControl>
                  <Controller
                    name='country'
                    control={control}
                    render={({ field: { onChange, value, ref, ...field } }) => (
                      <StyledAutocomplete
                        onChange={(_, data: any) => {
                          onChange(data?.value ?? '')
                        }}
                        value={
                          countryOptions.find(
                            nationality => nationality.value === value
                          ) ?? null
                        }
                        PopperComponent={StyledPopper}
                        renderOption={(props, option: any) => (
                          <AutocompleteOption {...props}>
                            <ReactCountryFlag
                              width={20}
                              countryCode={option.value}
                              svg
                            />
                            {option.label} ({option.value})
                          </AutocompleteOption>
                        )}
                        renderInput={params => (
                          <InputTextField
                            {...field}
                            {...params}
                            inputProps={{
                              'aria-label': 'country',
                              ...params.inputProps,
                            }}
                            inputRef={ref}
                          />
                        )}
                        options={countryOptions}
                        error={!!errors.country?.message}
                      />
                    )}
                  />
                  <ErrorMessage>{tError(errors.country?.message)}</ErrorMessage>
                </FullWidthFormControl>
              </InputWrapper>
              <InputWrapper>
                <Label>{t('form.profile.update.officeNumber')}</Label>
                <FullWidthFormControl>
                  <InputText
                    error={!!errors.officeNumber?.message}
                    inputProps={{ 'aria-label': 'officeNumber' }}
                    defaultValue={''}
                    disableUnderline
                    type='number'
                    {...register('officeNumber')}
                  />
                  <ErrorMessage>
                    {tError(errors.officeNumber?.message)}
                  </ErrorMessage>
                </FullWidthFormControl>
              </InputWrapper>
            </Row>
            <Row>
              <InputWrapper>
                <Label>
                  {t('form.profile.update.description')}
                  <DescriptionComment>
                    {t('form.profile.update.descriptionRestriction')}
                  </DescriptionComment>
                </Label>
                <FullWidthFormControl>
                  <InputText
                    error={!!errors.description?.message}
                    inputProps={{ 'aria-label': 'about me' }}
                    defaultValue={''}
                    disableUnderline
                    multiline
                    rows={4}
                    {...register('description')}
                  />
                  <ErrorMessage>
                    {tError(errors.description?.message)}
                  </ErrorMessage>
                </FullWidthFormControl>
              </InputWrapper>
            </Row>
            {subscription && (
              <Row>
                <InputWrapper>
                  <Label>{t('form.profile.update.subscription')}</Label>
                  <StaticWrapper>
                    <StaticText capitalize>
                      {user.data?.feature_tier.toLowerCase()}
                    </StaticText>
                    <StaticButton onClick={handleOpenCustomerPortal}>
                      <Icon name='pencil' />
                      <div>Manage by Stripe</div>
                    </StaticButton>
                  </StaticWrapper>
                </InputWrapper>
              </Row>
            )}
          </EditContainer>
          <SaveContainer>
            <SaveButton
              disabled={userUpdate.isLoading}
              type='submit'
              color='secondary'
              variant='contained'
            >
              {t('form.profile.update.save')}
            </SaveButton>
          </SaveContainer>
        </form>
      </FormContainer>
    </Main>
  )
}
