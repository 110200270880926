import { useCallback, useContext, useMemo, useState } from 'react'
import { Player } from '@sporttotal-tv/dip-coaching-client'
// components
import { TeamPlayersToolbar } from 'modules/team-player/components'
// hooks
import {
  useDebounce,
  useReduxDispatch,
  useReduxSelector,
} from 'modules/core/hooks'
// context
import { ComponentInfoContext } from 'modules/generic/context'
// redux
import { selectLayoutCacheById, updateLayoutCache } from 'modules/layout/redux'
// constants
import { playersComponents, PlayersView } from 'modules/team-player/constants'

import { PlayersWrapper, Root } from './TeamPlayers.styled'

export const TeamPlayers = () => {
  const { componentId } = useContext(ComponentInfoContext)
  const dispatch = useReduxDispatch()
  const teamPlayerCache = useReduxSelector(state =>
    selectLayoutCacheById(state, componentId)
  )
  const [view, setView] = useState<PlayersView>(
    teamPlayerCache?.view ?? PlayersView.GENERAL
  )
  const [search, setSearch] = useState<string>('')
  const [teamPlayer, setTeamPlayer] = useState<Player | null>(
    teamPlayerCache?.player ?? null
  )
  // hooks
  const debounceSearchValue = useDebounce(search, 500)

  const Component = useMemo(() => playersComponents[view].component, [view])

  const setTeamPlayerView = useCallback(
    (view: PlayersView) => {
      setView(view)
      dispatch(updateLayoutCache({ id: componentId, view }))
    },
    [componentId, dispatch]
  )

  const setTeamPlayerItem = useCallback(
    (teamPlayerItem: Player | null) => {
      setTeamPlayer(teamPlayerItem)
      dispatch(updateLayoutCache({ id: componentId, player: teamPlayerItem }))
    },
    [componentId, dispatch]
  )

  // TODO: because we have multiple component there is an issue when we have a lot of elements displayed,
  // tables are really heavy and we need to find a way to optimize them
  return (
    <Root>
      <TeamPlayersToolbar
        search={search}
        setSearch={setSearch}
        view={view}
        setView={setTeamPlayerView}
        teamPlayer={teamPlayer}
        setTeamPlayer={setTeamPlayerItem}
      />
      <PlayersWrapper compact={view === PlayersView.PROFILE}>
        <Component
          search={debounceSearchValue}
          teamPlayer={teamPlayer}
          setTeamPlayer={setTeamPlayerItem}
          setView={setTeamPlayerView}
        />
      </PlayersWrapper>
    </Root>
  )
}
