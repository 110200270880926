import { css, styled, Typography } from '@mui/material'
// Components
import { Icon } from 'modules/core/components/Icon'
import {
  LayoutItemToolbarDivider,
  ToolbarIcon,
} from 'modules/generic/components/ToolbarHeader/ToolbarHeader.styled'
import { LayoutItemToolbarContainer } from 'modules/layout/components/LayoutItemToolbar/LayoutItemToolbar.styled'
import { Tooltip } from 'modules/core/styled/Tooltip'
// import { ActionCheckbox } from 'modules/core/styled'
// import { MenuItem } from 'modules/core/components'

export const ToolbarWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(1.25)};
  padding: ${({ theme }) => theme.spacing(1.25)};
`

export const ToolbarContainer = styled(LayoutItemToolbarContainer)`
  padding: 0;
`

export const ToolbarDivider = styled(LayoutItemToolbarDivider)`
  column-gap: ${({ theme }) => theme.spacing(1.5)};
`

export const ViewControls = styled('div')`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(1.5)};

  & > span {
    font-size: 0;
  }
`

export const VolumeControls = styled('div')`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(0.75)};
  position: relative;
`

export const VolumeIcon = styled(Icon, {
  shouldForwardProp: prop => prop !== 'disabled',
})<{ disabled?: boolean }>`
  cursor: pointer;

  & path {
    transition: all 0.3s;
  }

  &:hover path {
    fill: ${({ theme }) => theme.palette.primary.contrastText};
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      cursor: not-allowed;
      user-select: none;

      & path {
        ${theme.styles.disabled}
      }

      &:hover path {
        fill: ${theme.palette.primary.light};
        ${theme.styles.disabled}
      }
    `}
`

export const VolumeTooltip = styled(Tooltip)`
  & .MuiTooltip-tooltip {
    width: 50px;
    height: 60px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: end;
  }
`

export const VolumeTrackWrapper = styled('div')`
  border: 1px solid #96989b;
  border-radius: 10px;
  height: 45px;
  width: 12px;
  background-color: #383939;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const VolumeTrack = styled('div')`
  height: calc(100% - 10px);
  width: 100%;
`

export const VolumeThumb = styled('div')`
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: #fff;

  &:focus {
    outline: unset;
  }
`

export const VolumeVerticalSlider = styled('input')`
  width: 45px;
  height: 12px;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 5px;
    height: 5px;
    border-radius: 10px;
    background-color: var(--SliderColor);
    overflow: visible;
    cursor: pointer;
  }
  /* -------------------------- Firefox */
  &::-moz-range-thumb {
    -moz-appearance: none;
    width: 5px;
    height: 5px;
    border-radius: 10px;
    background-color: var(--SliderColor);
    overflow: visible;
  }
`

export const ViewIcon = styled(Icon, {
  shouldForwardProp: prop => prop !== 'disabled' && prop !== 'active',
})<{ disabled?: boolean; active?: boolean }>`
  cursor: pointer;
  height: 16px;

  & path {
    transition: all 0.3s;
  }

  &:hover path {
    fill: ${({ theme }) => theme.palette.primary.contrastText};
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      cursor: not-allowed;
      user-select: none;

      & path {
        ${theme.styles.disabled}
      }

      &:hover path {
        fill: ${theme.palette.primary.light};
        ${theme.styles.disabled}
      }
    `}

  ${({ active, theme }) =>
    active &&
    css`
      & path {
        fill: ${theme.palette.secondary.main};
      }
    `}
`

export const MatchControls = styled(ViewControls)`
  column-gap: ${({ theme }) => theme.spacing(0.5)};
`

export const MatchText = styled(Typography, {
  shouldForwardProp: prop => prop !== 'accent',
})<{ accent?: boolean }>`
  font-size: 16px;
  color: ${({ theme, accent }) =>
    accent ? theme.palette.primary.contrastText : theme.palette.primary.light};
`

export const Playtime = styled(MatchText)`
  flex: 1;
  text-align: center;
  color: ${({ theme }) => theme.palette.primary.contrastText};
`

// Other
export const Divider = styled('div')`
  height: 29px;
  border-right: 1px solid ${({ theme }) => theme.palette.primary.light};
`

export const PlayerIcon = styled(ToolbarIcon, {
  shouldForwardProp: prop => prop !== 'active',
})<{ active?: boolean }>`
  cursor: pointer;
  & path {
    transition: all 0.3s;
    fill: ${({ theme, color }) => color ?? theme.palette.secondary.main};
  }

  ${({ active }) =>
    !active &&
    css`
      opacity: 0.5;
    `}
`

export const FlexContainer = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;
`

export const SettingsContainer = styled(FlexContainer)`
  justify-content: space-between;
`

export const MobilePlayerContainer = styled('div')`
  display: flex;
  justify-content: center;
`

export const StyledMenuItemContent = styled('div')`
  display: flex;
  justify-content: space-between;
`
