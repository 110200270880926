type Reverse<Tuple> = Tuple extends [infer Head, ...infer Rest]
  ? [...Reverse<Rest>, Head]
  : []

export const reverseParams = <T extends (...args: any) => any>(func: T) => {
  return function () {
    const args = Array.from(arguments).reverse()
    return func(...args)
  } as (...args: Reverse<Parameters<T>>) => ReturnType<typeof func>
}
