import { CalendarLayoutVariant } from 'modules/calendar/types'
import { Translation } from 'modules/core/components/Translation'
import { ReactNode } from 'react'

export const defaultEventDuration = 60
export const calendarLayouts: {
  value: CalendarLayoutVariant
  label: NonNullable<ReactNode>
}[] = [
  {
    value: 'day',
    label: Translation('pages:calendar.day'),
  },
  {
    value: 'week',
    label: Translation('pages:calendar.week'),
  },
  {
    value: 'month',
    label: Translation('pages:calendar.month'),
  },
]
