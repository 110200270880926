import * as yup from 'yup'

export const assetEditSchema = yup.object().shape({
  title: yup.string().required('error.title').max(255, 'error.maxNumber'),
  description: yup
    .string()
    .required('error.description')
    .max(255, 'error.maxNumber'),
  tags: yup.string(),
})
