import { useEffect, useRef, FC } from 'react'
// Hooks
import { useDebounceWithSetter } from 'modules/core/hooks'
import {
  usePreciseCurrentTime,
  usePreciseDuration,
} from 'modules/video-player/hooks'
// Utils
import { convertSecondsToTime } from 'modules/core/utils'
// Styled
import { PlayTrackStyled } from './PlayTrack.styled'
// Types
import { SliderProps } from '@mui/material'
import { PlayTrackProps } from './PlayTrack.interface'

export const PlayTrack: FC<PlayTrackProps> = ({
  player,
  disabled,
  onChange,
  ...others
}) => {
  const thumbRef = useRef<HTMLSpanElement>(null)

  const currentTimestamp = usePreciseCurrentTime(player)
  const duration = usePreciseDuration(player)
  const [seekedTimestamp, setSeekedTimestamp] = useDebounceWithSetter(-1, 350)

  const isSeeking = seekedTimestamp > 0
  const isDisabled = disabled || !duration

  useEffect(() => {
    const thumbNode = thumbRef.current
    if (!thumbNode || !player) return

    const thumbPosition = (currentTimestamp / player.duration) * 100
    thumbNode.style.left = `${thumbPosition}%`
  }, [player, currentTimestamp, thumbRef])

  useEffect(() => {
    if (!isSeeking || !player) return

    player.currentTime = seekedTimestamp
    player.addEventListener('playing', () => setSeekedTimestamp(-1))
  }, [seekedTimestamp, player, isSeeking])

  const handleOnChange: SliderProps['onChange'] = (
    event,
    value,
    activeThumbs
  ) => {
    setSeekedTimestamp(Array.isArray(value) ? value[0] : value)
    if (onChange) onChange(event, value, activeThumbs)
  }

  return (
    <PlayTrackStyled
      {...others}
      thumbRef={thumbRef}
      max={duration}
      disabled={isDisabled}
      onChange={handleOnChange}
      valueLabelFormat={() =>
        convertSecondsToTime(isSeeking ? seekedTimestamp : currentTimestamp)
      }
    />
  )
}
