import { css, styled, Typography } from '@mui/material'
import { IconButton } from 'modules/core/components/IconButton'
import { Icon } from 'modules/core/components/Icon'

export const Root = styled('div', {
  shouldForwardProp: prop => prop !== 'droppable' && prop !== 'isDragOver',
})<{
  droppable?: boolean
  isDragOver?: boolean
}>`
  position: relative;

  padding: ${({ theme }) => theme.spacing(2.5, 0, 1.75)};
  margin: ${({ theme }) => theme.spacing(0, 2)};

  &:before {
    content: '';
    width: calc(100% + ${({ theme }) => theme.spacing(8)});
    left: -${({ theme }) => theme.spacing(4)};
    top: 1px;
    height: 100%;
    position: absolute;
    transition: all 0.3s;
    background-color: unset;
    border-bottom: 1px solid transparent;

    ${({ droppable, theme }) =>
      droppable &&
      css`
        background-color: ${theme.palette.primary.dark};
        border-bottom: 1px solid ${theme.palette.primary.light};
      `}

    ${({ isDragOver, theme }) =>
      isDragOver &&
      css`
        background-color: ${theme.palette.darkest};
        border-bottom: 1px solid ${theme.palette.secondary.main};
      `}
  }

  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};

  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.palette.divider};
  }
`

export const PlaylistInfoWrapper = styled('div')`
  display: flex;
  position: relative;
  column-gap: ${({ theme }) => theme.spacing(2)};
`

export const PlaylistInfoItem = styled('div')`
  flex: 1;
  overflow: hidden;
  position: relative;
`

export const TitleRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TitleIcon = styled(Icon)`
  min-width: 16px;
  width: 16px;
  height: 12px;
`

export const TitleWrapper = styled('div')`
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(0.75)};
  overflow: hidden;
`

export const Title = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.common.white};
  margin: ${({ theme }) => theme.spacing(0.5, 0)};
`

export const PlaylistDescriptionItem = styled(Typography)`
  font-size: 14px;
  color: inherit;
`

export const PlaylistDescriptionWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  color: ${({ theme }) => theme.palette.primary.light};
  column-gap: ${({ theme }) => theme.spacing(1.75)};
`

export const ContentWrapper = styled('div')`
  display: flex;
  color: ${({ theme }) => theme.palette.primary.light};
  column-gap: ${({ theme }) => theme.spacing(1.75)};
  justify-content: space-between;
`

export const CollapseControlsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-right: ${({ theme }) => theme.spacing(0.25)};
  padding-top: ${({ theme }) => theme.spacing(1)};
`

export const AnimatedChevronIcon = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'chevronUp',
})<{ chevronUp: boolean }>`
  transition: all 0.3s;
  transform: ${({ chevronUp }) => (chevronUp ? 'rotate(0)' : 'rotate(180deg)')};
`

export const IconWrapper = styled('div')`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
