import { FC } from 'react'
import { PeriodSelector, Discount } from './BillingPeriodSelector.styled'
import { BillingPeriodSelectorProps } from './BillingPeriodSelector.interface'

export const BillingPeriodSelector: FC<BillingPeriodSelectorProps> = ({
  active,
  period,
  onClick,
  discount,
}) => (
  <PeriodSelector active={active} onClick={onClick}>
    {period}
    {period === 'YEARLY' && <Discount>Save {discount}%</Discount>}
  </PeriodSelector>
)
