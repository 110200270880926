import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { useQueryRetry } from 'modules/core/hooks'

export const TEAM_PLAYER_PROFILE_RETRIEVE_CACHE_KEY =
  'team-player-profile-retrieve'

export const useTeamPlayerProfileRetrieve = (id?: string) => {
  const handleQueryRetry = useQueryRetry()

  return useQuery(
    [TEAM_PLAYER_PROFILE_RETRIEVE_CACHE_KEY, id],
    () => ApiService.teamPlayer.teamPlayerProfileRetrieve(id),
    {
      enabled: Boolean(id),
      retry: handleQueryRetry,
    }
  )
}
