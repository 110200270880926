import {
  AssociationList,
  ListAssociationListOptions,
} from '@sporttotal-tv/dip-coaching-client'
import { api } from 'modules/core/api'
import { transformApiResults } from 'modules/core/helpers'

export const AssociationApiService = {
  list: transformApiResults<ListAssociationListOptions, AssociationList>(
    api.association.list
  ),
}
