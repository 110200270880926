import { styled, Switch, SwitchProps } from '@mui/material'

const ToggleSwitch = styled(
  (props: SwitchProps) => <Switch disableRipple {...props} />,
  { shouldForwardProp: prop => prop !== 'sameBackground' }
)<{ sameBackground?: boolean }>`
  width: 28px;
  height: 16px;
  padding: 0;
  display: flex;
  &:active {
    & .MuiSwitch-thumb {
      width: 15px;
    }
    & .MuiSwitch-switchBase.Mui-checked {
      transform: unset;
    }
  }

  & .MuiSwitch-switchBase {
    width: 100%;
    height: 100%;
    padding: 2px;
    color: ${({ theme }) => theme.palette.primary.dark};
    &.Mui-checked {
      transform: unset;

      & .MuiSwitch-thumb {
        left: calc(100% - 2px);
        transform: translateX(-100%);
      }

      & + .MuiSwitch-track {
        opacity: 1;
        background-color: ${({ theme }) => theme.palette.primary.contrastText};
      }
    }
  }

  & .MuiSwitch-thumb {
    position: absolute;
    left: 2px;
    transform: translateX(0);
    box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
    width: 12px;
    height: 12px;
    border-radius: 6px;
    transition: all 0.3s;
  }

  & .MuiSwitch-track {
    border-radius: 16 / 2;
    opacity: 1;
    background-color: ${({ theme, sameBackground }) =>
      sameBackground
        ? theme.palette.primary.contrastText
        : theme.palette.primary.light};
    box-sizing: border-box;
  }
`

export { ToggleSwitch }
