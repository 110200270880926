import { FC, useCallback, useContext, useMemo, useRef, useState } from 'react'
// components
import { ToolbarControls, ToolbarHeader } from 'modules/generic/components'
import { Icon, IconButton, SearchInput } from 'modules/core/components'
import { TeamPlayerSelector } from 'modules/team-player/components'
// hooks
import { useAnchor, useResizeObserver } from 'modules/core/hooks'
// constants
import { teamLayoutPalette } from 'modules/team/constants'
import { playersComponents, PlayersView } from 'modules/team-player/constants'
// context
import { ComponentInfoContext } from 'modules/generic/context'
// styled
import {
  DisplayMenu,
  DisplayMenuItem,
  DisplayText,
  DisplayIconWrapper,
} from 'modules/core/styled'

import {
  Divider,
  PlayersToolbarContainer,
  SelectorContainer,
  ViewSelectorWrapper,
  ViewName,
  ViewSelectIcon,
  ViewIcon,
  Toolbar,
  PlayerSelectorWrapper,
} from './TeamPlayersToolbar.styled'
import { TeamPlayersToolbarProps } from './TeamPlayersToolbar.interface'

export const TeamPlayersToolbar: FC<TeamPlayersToolbarProps> = ({
  view,
  setView,
  setSearch,
  search,
  teamPlayer,
  setTeamPlayer,
}) => {
  const [searchActive, setSearchActive] = useState<boolean>(false)
  const { layoutIndex, componentId } = useContext(ComponentInfoContext)
  const { isOpen, handleClose, handleOpen, anchor } = useAnchor()
  const containerRef = useRef<HTMLDivElement>(null)

  const { width } = useResizeObserver(containerRef)

  const handleSelectView = useCallback(
    (playersView: PlayersView) => () => {
      handleClose()
      setView(playersView)
    },
    [setView, handleClose]
  )

  const activePlayerComponent = useMemo(() => playersComponents[view], [view])
  const isCompact = useMemo(() => width < 648, [width])

  return (
    <PlayersToolbarContainer ref={containerRef}>
      <SelectorContainer>
        <ToolbarHeader
          layoutId={componentId}
          layoutIndex={layoutIndex}
          paletteItems={teamLayoutPalette}
        />
        <Divider />
        <ViewSelectorWrapper onClick={handleOpen}>
          <ViewIcon name={activePlayerComponent.icon} />
          <ViewName>{activePlayerComponent.name}</ViewName>
          <ViewSelectIcon name='chevron-small-down' chevronUp={!isOpen} />
        </ViewSelectorWrapper>
        <DisplayMenu
          id='players-view-selector'
          anchorEl={anchor}
          open={isOpen}
          onClose={handleClose}
        >
          {Object.entries(playersComponents)?.map(
            ([playersView, playersInfo]) => (
              <DisplayMenuItem
                key={playersView}
                onClick={handleSelectView(playersView as PlayersView)}
              >
                <DisplayIconWrapper>
                  <Icon name={playersInfo.icon} />
                </DisplayIconWrapper>
                <DisplayText>{playersInfo.name}</DisplayText>
              </DisplayMenuItem>
            )
          )}
        </DisplayMenu>
      </SelectorContainer>
      <Toolbar />
      {searchActive && (
        <SearchInput
          sx={{ height: 26, maxWidth: 180 }}
          startAdornment={undefined}
          placeholder='Search'
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      )}
      {view !== PlayersView.PROFILE && (
        <IconButton
          name='search'
          onClick={() => setSearchActive(prev => !prev)}
        />
      )}
      {view === PlayersView.PROFILE && !isCompact && teamPlayer && (
        <PlayerSelectorWrapper>
          <TeamPlayerSelector
            showError={false}
            player={teamPlayer}
            setPlayer={setTeamPlayer}
          />
        </PlayerSelectorWrapper>
      )}
      <ToolbarControls layoutId={componentId} layoutIndex={layoutIndex} />
    </PlayersToolbarContainer>
  )
}
