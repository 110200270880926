import { FC, useRef } from 'react'
// hooks
import { useResizeObserver } from 'modules/core/hooks'
// components
import { Icon } from 'modules/core/components'
// constants
import { fields } from 'modules/sport/constants'

import {
  PositionIconWrapper,
  PositionsWrapper,
  StyledIcon,
} from './FieldPositions.styled'
import { FieldPositionsProps } from './FieldPositions.interface'

export const FieldPositions: FC<FieldPositionsProps> = ({
  type,
  selectedPositions,
  ...props
}) => {
  const fieldContainerRef = useRef<HTMLDivElement>(null)
  const { height: fieldContainerHeight } = useResizeObserver(fieldContainerRef)

  const fieldData = fields[type]

  return (
    <PositionsWrapper ref={fieldContainerRef} {...props}>
      <StyledIcon className='field' name={fieldData.icon} />
      {Object.entries(fieldData.placements).map(
        ([position, placement], index) => (
          <PositionIconWrapper
            top={placement.top}
            left={placement.left}
            scale={fieldContainerHeight / fieldData.height}
            key={`${position}-${index}`}
          >
            <Icon
              name={
                selectedPositions === position
                  ? 'position-selected'
                  : 'position'
              }
            />
          </PositionIconWrapper>
        )
      )}
    </PositionsWrapper>
  )
}
