import { FC, useState } from 'react'
// Hooks
import { useTranslation } from 'react-i18next'
// Components
import { IconButton } from 'modules/core/components'
import { Menu } from 'modules/core/components/Menu/PopoverMenu'
import {
  HeaderElement,
  HeaderElementSmall,
  Row,
  Table,
  BodyElementSmall,
  BodyElement,
} from 'modules/core/styled'
// Types
import { AdminCamera } from '@sporttotal-tv/dip-coaching-client'
import { AdminCameraTableProps } from './AdminCameraTable.interface'

export const AdminCameraTable: FC<AdminCameraTableProps> = ({ cameras }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'camera' })

  const [selectedCamera, setSelectedCamera] = useState<AdminCamera>()
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()

  const handleMenuClick =
    (camera: AdminCamera) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      setSelectedCamera(camera)
      setAnchorEl(event.currentTarget)
    }

  const handleClose = () => {
    setAnchorEl(undefined)
    setSelectedCamera(undefined)
  }

  return (
    <>
      <Table>
        <Row>
          <HeaderElement>{t('table.row.camera_name')}</HeaderElement>
          <HeaderElement>{t('table.row.display_name')}</HeaderElement>
          <HeaderElementSmall />
        </Row>
        {cameras.map(camera => (
          <Row key={camera.camera_name}>
            <BodyElement>{camera.camera_name}</BodyElement>
            <BodyElement>{camera.display_name}</BodyElement>
            <BodyElementSmall>
              <IconButton
                name='vertical-menu'
                onClick={handleMenuClick(camera)}
              />
            </BodyElementSmall>
          </Row>
        ))}
      </Table>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {/* <MenuItem
          onClick={() => {
            handleClose()
            selectedCamera && onStartStop(selectedCamera)
          }}
        >
          Start/Stop Recording
        </MenuItem> */}
        {/* <MenuItem
          onClick={() => {
            handleClose()
            selectedCamera && onUpdate(selectedCamera)
          }}
        >
          Update Software
        </MenuItem> */}
      </Menu>
    </>
  )
}
