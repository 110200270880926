import { Match } from '@sporttotal-tv/dip-coaching-client'

export const isMatchLive = (match?: Match) => {
  if (!match) return false
  const { match_assets, event_end } = match

  const now = new Date()
  const end = new Date(event_end * 1000)

  const isEventFinished = now > end
  const hasBroadcastLive = match_assets?.some(
    ({ type, is_playable }) => type === 'broadcast_live' && is_playable
  )

  return hasBroadcastLive && !isEventFinished
}
