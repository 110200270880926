import { FC, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
// Components
import { IconButton, SearchInput } from 'modules/core/components'
import { ToolbarControls, ToolbarHeader } from 'modules/generic/components'
import { VideoPlayerSelector } from 'modules/video-player/components'
import { ToolbarDivider } from 'modules/generic/styled'
// context
import { ComponentInfoContext } from 'modules/generic/context'

import {
  ToolbarContainer,
  ToolbarTitle,
  ToolbarActions,
  EventSelectIcon,
  EventSelectorWrapper,
  ToolbarControlsWrapper,
} from './AssetToolbar.styled'
import { AssetToolbarProps } from './AssetToolbar.interface'

export const AssetToolbar: FC<AssetToolbarProps> = ({
  filterOpen,
  handleFilterToggle,
  search,
  setSearch,
}) => {
  const { layoutIndex, componentId: layoutId } =
    useContext(ComponentInfoContext)
  const [searchActive, setSearchActive] = useState<boolean>(false)
  const { t: filterT } = useTranslation('components', { keyPrefix: 'filter' })

  return (
    <ToolbarContainer>
      <ToolbarTitle>
        <ToolbarHeader layoutId={layoutId} layoutIndex={layoutIndex}>
          <ToolbarDivider />
          <VideoPlayerSelector />
        </ToolbarHeader>
      </ToolbarTitle>
      <ToolbarActions full={searchActive}>
        {searchActive && (
          <SearchInput
            sx={{ height: 26 }}
            startAdornment={undefined}
            placeholder='Search'
            value={search}
            onChange={e => setSearch(e.target.value)}
            autoFocus
          />
        )}
        {!searchActive && (
          <>
            <EventSelectorWrapper onClickCapture={handleFilterToggle}>
              <Typography>{filterT('title')}</Typography>
              <EventSelectIcon
                name='chevron-small-down'
                chevronUp={!filterOpen}
              />
            </EventSelectorWrapper>
          </>
        )}
      </ToolbarActions>
      <ToolbarControlsWrapper>
        <IconButton
          name='search'
          onClick={() => setSearchActive(prev => !prev)}
        />
        <ToolbarControls layoutId={layoutId} layoutIndex={layoutIndex} />
      </ToolbarControlsWrapper>
    </ToolbarContainer>
  )
}
