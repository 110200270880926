import { ApiService } from 'services/Api.service'
import { createMutationHook } from 'modules/core/api/hooks/utils'
import { USER_ORGANIZATIONS_RETRIEVE_CACHE_KEY } from 'modules/user/hooks'
import { ADMIN_ORGANIZATION_LIST_CACHE_KEY } from './useAdminOrganizationList'
import { ORG_LIST } from './useOrganizationList'

export const [useAdminOrganizationCreate, ADMIN_ORG_CREATE] =
  createMutationHook(ApiService.admin.organization.create, queryClient => ({
    onSuccess: () => {
      queryClient.invalidateQueries(ADMIN_ORGANIZATION_LIST_CACHE_KEY)
      queryClient.invalidateQueries(USER_ORGANIZATIONS_RETRIEVE_CACHE_KEY)
      queryClient.invalidateQueries(ORG_LIST)
    },
  }))
