import React from 'react'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import CalendarSmall from './CalendarSmall'
import { styled } from '@mui/material'

const drawerWidth = 260

const CustomDrawer = styled(Drawer)`
  width: ${drawerWidth}px;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.palette.primary.main};

  & .MuiDrawer-paper {
    width: ${drawerWidth}px;
    position: inherit;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`

const CalendarSmallStyled = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(4)};
  margin-right: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  margin-left: ${({ theme }) => theme.spacing(1)};
  min-height: 265px;
  min-width: 240px;
  background: ${({ theme }) => theme.palette.primary.main};
`

const CalendarDrawer = () => {
  return (
    <CustomDrawer variant='persistent' open={true}>
      <CalendarSmallStyled>
        <CalendarSmall />
      </CalendarSmallStyled>
      <Divider />
    </CustomDrawer>
  )
}

export default CalendarDrawer
