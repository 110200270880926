import { useQuery } from 'react-query'
import { useQueryRetry } from 'modules/core/hooks'
import { ApiService } from 'services/Api.service'
import { RetrieveUserCacheOptions } from '@sporttotal-tv/dip-coaching-client'
import { UseQueryOptions } from 'modules/core/types'
import { UserCacheData } from 'modules/user/types'

export const USER_CACHE_RETRIEVE_CACHE_KEY = 'user-cache-retrieve'

export const useUserCache = <Cache extends Record<string, any>>(
  params: RetrieveUserCacheOptions,
  queryOptions?: UseQueryOptions<Partial<UserCacheData<Cache>>, any>
) => {
  const handleQueryRetry = useQueryRetry({ maxRetries: 0 })

  return useQuery<any, any, Partial<UserCacheData<Cache>>>(
    [USER_CACHE_RETRIEVE_CACHE_KEY, params],
    () => ApiService.cache.retrieve(params),
    {
      ...queryOptions,
      staleTime: Infinity,
      retry: handleQueryRetry,
    }
  )
}
