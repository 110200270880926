import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  addMinutes,
  addWeeks,
  differenceInMinutes,
  endOfMonth,
  format,
  isWithinInterval,
  startOfMonth,
} from 'date-fns'
import Grid from '@mui/material/Grid'
import { useDrop } from 'react-dnd'
import LineDivisor from './LineDivisor'
import createEditEvent from './createEditEvent'
import EventMark from './EventMark'
import { styled } from '@mui/material'
import { useReduxDispatch, useReduxSelector } from 'modules/core/hooks'
import {
  CalendarEditData,
  resetCalendarDraggingEvent,
  selectCalendarLayout,
  selectCalendarSelectedDate,
  selectDraggingEventId,
  setCalendarEditEvent,
} from 'modules/calendar/redux'
import { useMarkers } from '../hooks/useMarkers'
import { MarkersService } from '../services/Markers.service'
import { SnackbarContext } from 'modules/core/context'
import { useUserTeams } from 'modules/user/hooks'
import { useTranslation } from 'react-i18next'

const CurrentTimeDot = styled('div')`
  background: rgb(226, 57, 43);
  border-radius: 50%;
  content: '';
  position: absolute;
  height: 12px;
  width: 12px;
  z-index: 52;
  margin-top: -1000px;
  margin-left: -6.5px;
`

const CurrentTimeLine = styled('div')`
  position: absolute;
  z-index: 51;
  border-top: 2px solid rgb(226, 57, 43);
  left: 0;
  right: -1px;
`

const EventsContainer = styled('div')`
  background-color: transparent;
  position: relative;
  height: 100%;
  width: 100%;
`

const ColumnDivisior = styled('div')`
  height: 100%;
  padding-left: 8px;
  border-right: 1px solid #dadce0;
`

type CalendarBoardProps = {
  selectedWeekIndex: number
  selectedWeek: Date[]
}

const CalendarBoard: FC<CalendarBoardProps> = ({
  selectedWeekIndex,
  selectedWeek,
}) => {
  const dispatch = useReduxDispatch()
  const selectedDate = useReduxSelector(selectCalendarSelectedDate)
  const layout = useReduxSelector(selectCalendarLayout)
  const draggingEventId = useReduxSelector(selectDraggingEventId)
  const { showSnackbar } = useContext(SnackbarContext)
  const { data: userTeams } = useUserTeams({ expand: ['team'] })
  const { t } = useTranslation('pages', { keyPrefix: 'calendar' })

  const markers = useMarkers({
    startDate: addWeeks(startOfMonth(selectedDate), -1),
    endDate: addWeeks(endOfMonth(selectedDate), 2),
  })

  const [currentTimePosition, setCurrentTimePosition] = useState<number>()

  useEffect(() => {
    const currentTimeInterval = setInterval(() => {
      const now = new Date()
      const initTime = new Date(format(now, 'yyyy/MM/dd 0:0:0'))
      const position = differenceInMinutes(now, initTime)
      setCurrentTimePosition(position)
    }, 1000)

    return () => {
      clearInterval(currentTimeInterval)
    }
  }, [])

  const viewLayout = Array.from(
    Array(layout === 'week' ? 7 : layout === 'day' ? 1 : 0).keys()
  )

  const getEventData = (day: Date) => {
    const monthEvents = markers.sort((a, b) => {
      return new Date(a.begin).getTime() - new Date(b.begin).getTime()
    })

    const dayEvents = monthEvents.filter(event =>
      isWithinInterval(day, {
        start: new Date(event.begin).setHours(0, 0, 0, 0),
        end: new Date(event.end).setHours(23, 59, 59, 59),
      })
    )

    const dayHoursEvents = dayEvents
      .map(event => new Date(event.begin).getHours())
      .sort((numberA: number, numberB: number) => numberA - numberB)

    const eventsByHour = dayHoursEvents.reduce<{ hour: number; len: number }[]>(
      (acc, hour: number) => {
        const len = dayHoursEvents.filter(
          (eventHour: number) => eventHour === hour
        ).length

        !acc.some(accItem => accItem.hour === hour) && acc.push({ hour, len })

        return acc
      },
      []
    )

    return eventsByHour.map(evHour => {
      return dayEvents
        .filter(event => new Date(event.begin).getHours() === evHour.hour)
        .map((event, index) => (
          <EventMark
            key={`event-${event.id}`}
            selectedDay={day}
            calendarEvent={event}
            sq={index}
            len={evHour.len}
            isEditable={false}
          />
        ))
    })
  }

  const CurrentTimeMark = (props: { marginTop?: number }) => {
    const { marginTop = -1000 } = props
    return (
      <>
        <CurrentTimeDot style={{ marginTop: marginTop - 5 }} />
        <CurrentTimeLine style={{ marginTop: marginTop }} />
      </>
    )
  }

  const onDrop = () => {
    const eventID = draggingEventId

    const eventMarkGhost = document.querySelector('[data-ghost]')
    if (!eventMarkGhost) return false

    const datasetDate = eventMarkGhost.getAttribute('data-date')

    if (!datasetDate) return false

    const eventBeginDate = new Date(datasetDate)
    if (!eventBeginDate) return

    const draggedEvent = markers.find(markEvent => markEvent.id === eventID)

    if (!draggedEvent) return false

    const duration = differenceInMinutes(
      new Date(draggedEvent.end),
      new Date(draggedEvent.begin)
    )

    const marker = {
      ...draggedEvent,
      begin: format(eventBeginDate, 'yyyy/MM/dd HH:mm'),
      end: format(addMinutes(eventBeginDate, duration), 'yyyy/MM/dd HH:mm'),
    }

    MarkersService.getInstance().updateMarkers([
      ...markers.filter(markEvent => markEvent.id !== eventID),
      marker,
    ])

    dispatch(resetCalendarDraggingEvent)
  }

  const setCalendarEditEventFunc = useCallback(
    (data: CalendarEditData) => {
      if (userTeams?.total === 0) {
        showSnackbar({
          type: 'error',
          message: t('modal.error.noTeams'),
        })

        return
      }

      dispatch(setCalendarEditEvent(data))
    },
    [dispatch, userTeams, showSnackbar, t]
  )

  const [, drop] = useDrop({
    accept: 'box',
    drop() {
      return undefined
    },
  })

  const viewLayoutEl = useMemo(() => {
    return viewLayout.map(index => {
      const day = layout === 'week' ? selectedWeek[index] : selectedDate
      const isToday = format(day, 'ddMMyyyy') === format(new Date(), 'ddMMyyyy')
      const eventsOfDay = getEventData(day)

      return (
        <Grid
          item
          xs
          id={`day${index + 1}`}
          data-group='day-column'
          data-date={day}
          sx={{
            borderRight: '1px solid #e0e0e0',
            position: 'relative',
            flex: '1 1 auto',
            height: '100%',
          }}
          key={`board-day-column-${layout}-${selectedWeekIndex}-${day}-${index}`}
          onClick={eventEl =>
            createEditEvent({
              eventEl,
              setStateCalendar: setCalendarEditEventFunc,
            })
          }
        >
          {isToday && <CurrentTimeMark marginTop={currentTimePosition} />}

          {eventsOfDay && eventsOfDay.length > 0 && (
            <EventsContainer data-date={day}>{eventsOfDay}</EventsContainer>
          )}
        </Grid>
      )
    })
  }, [
    getEventData,
    layout,
    selectedDate,
    selectedWeek,
    selectedWeekIndex,
    viewLayout,
    markers,
    setCalendarEditEventFunc,
  ])

  return (
    <Grid
      ref={drop}
      onDrop={onDrop}
      container
      spacing={0}
      direction='row'
      justifyItems='center'
      alignItems='flex-start'
      sx={{
        minWidth: '100%',
        height: '100%',
        flex: 'none',
        verticalAlign: 'top',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <LineDivisor />
      <ColumnDivisior />

      {viewLayoutEl}
    </Grid>
  )
}

export default CalendarBoard
