import {
  FC,
  Fragment,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Popover } from '@mui/material'
import ReactCountryFlag from 'react-country-flag'
import { useTranslation } from 'react-i18next'
// Components
import {
  ConfirmDialog,
  ConfirmDialogState,
  IconButton,
} from 'modules/core/components'
// Hooks
import { usePageScrolling, useAnchor } from 'modules/core/hooks'
// Constants
import { TEAM_PAGE_LIMIT } from 'modules/team/constants'
// Styled
import {
  ActionCheckbox,
  DisplayIconWrapper,
  DisplayMenu,
  DisplayMenuItem,
  DisplayText,
  GridTable,
  GridTableCell,
  GridTableHeaderCell,
} from 'modules/core/styled'

import {
  ActionIcon,
  ActionName,
  ActionNameWrapper,
  ActionSelectedNumber,
  ActionsWrapper,
  AnimatedChevronIcon,
  InteractiveActionWrapper,
  MenuCell,
  MenuIconsWrapper,
  NationalityCell,
  PlayerAddIcon,
  PlayerAddText,
  PlayerAddWrapper,
  Root,
  TableWrapper,
  StyledRating,
} from './InfoTable.styled'
import {
  InfoTableProps,
  InfoItem,
  InfoTableComponent,
  InfoTableHeaderComponent,
  InfoTableHeaderType,
  InfoTableType,
  InfoTableHeaderElement,
  InfoTableElement,
} from './InfoTable.interface'

export const InfoTable: FC<InfoTableProps> = ({
  useList,
  useDelete,
  useListArgs,
  tableRows,
  tableHeaderRows,
  resource,
  transformName,
  Modal,
  columnsTemplate,
}) => {
  // state
  const [selected, setSelected] = useState<Set<string>>(new Set())
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
    useState<boolean>(false)
  const [editingItem, setEditingItem] = useState<InfoItem | null>(null)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  // hooks
  const { t } = useTranslation()
  const { containerRef, isBottom, resetIsBottom } = usePageScrolling(100)
  const deleteItem = useDelete()
  // TODO: Fix typing for union types
  //@ts-ignore
  const listItems = useList({
    page_limit: TEAM_PAGE_LIMIT,
    ...useListArgs,
  })

  const {
    anchor: popoverAnchor,
    isOpen: isPopoverOpen,
    handleOpen: handleOpenPopover,
    handleClose: onPopoverClose,
  } = useAnchor()

  const {
    anchor: actionAnchor,
    isOpen: isActionContextMenuOpen,
    handleOpen: handleActionContextOpen,
    handleClose: handleActionContextClose,
  } = useAnchor()

  useEffect(() => {
    if (
      isBottom &&
      listItems.hasNextPage &&
      !listItems.isFetchingNextPage &&
      !listItems.isLoading
    ) {
      resetIsBottom()
      listItems.fetchNextPage()
    }
  }, [listItems, isBottom, resetIsBottom])

  const handleBulkDeleteItems = useCallback(() => {
    // TODO: Need to add bulk actions to the players
    handleActionContextClose()
  }, [handleActionContextClose])

  const handleSetEditingItem = useCallback(
    (item: InfoItem) => (event: MouseEvent<HTMLDivElement>) => {
      setEditingItem(item)
      handleOpenPopover(event)
    },
    [handleOpenPopover]
  )

  const toggleSelected = useCallback(
    (id: string) => () => {
      setSelected(prev => {
        if (prev.has(id)) {
          prev.delete(id)
          return new Set(prev)
        }
        prev.add(id)
        return new Set(prev)
      })
    },
    []
  )

  const handleClosePopover = useCallback(() => {
    setEditingItem(null)
    onPopoverClose()
  }, [onPopoverClose])

  const handleConfirmRemoveItem = useCallback(
    (result: ConfirmDialogState) => {
      setConfirmDeleteDialogOpen(false)
      if (result === ConfirmDialogState.SUBMITTED && editingItem) {
        // TODO: fix typing for union types
        deleteItem.mutate(editingItem.id)
        handleClosePopover()
      }
    },
    [editingItem, handleClosePopover, deleteItem]
  )

  const handleModalOpen = useCallback(() => setModalOpen(true), [])
  const handleModalClose = useCallback(() => setModalOpen(false), [])

  const handleConfirmDialogOpen = useCallback(
    () => setConfirmDeleteDialogOpen(true),
    []
  )

  const tableHeaderComponents: Record<
    InfoTableHeaderType,
    InfoTableHeaderComponent
  > = useMemo(
    () => ({
      text: ({ children, justify }) => (
        <GridTableHeaderCell justify={justify}>{children}</GridTableHeaderCell>
      ),
      actions: ({ translation }) => (
        <GridTableHeaderCell leftSticky>
          <InteractiveActionWrapper className='lg-element-shown'>
            <ActionSelectedNumber>
              {t(translation?.main ?? '', { count: selected.size })}
            </ActionSelectedNumber>
            <ActionNameWrapper onClick={handleActionContextOpen}>
              <ActionName>{t(translation?.secondary ?? '')}</ActionName>
              <AnimatedChevronIcon name='chevron-small-down' chevronUp />
            </ActionNameWrapper>
          </InteractiveActionWrapper>
        </GridTableHeaderCell>
      ),
      add: ({ translation, disabled }) => (
        <GridTableHeaderCell justify='flex-end'>
          <PlayerAddWrapper
            onClick={!disabled ? handleModalOpen : void 0}
            className='lg-element-shown'
          >
            <PlayerAddText>{t(translation?.main ?? '')}</PlayerAddText>
            <PlayerAddIcon name='plus-circle' />
          </PlayerAddWrapper>
        </GridTableHeaderCell>
      ),
      stickyLeftText: ({ children }) => (
        <GridTableHeaderCell leftSticky sx={{ left: 28 }}>
          {children}
        </GridTableHeaderCell>
      ),
    }),
    [handleActionContextOpen, handleModalOpen, selected.size, t]
  )

  const tableComponents: Record<InfoTableType, InfoTableComponent> = useMemo(
    () => ({
      text: ({ children, justify, props }) => (
        <GridTableCell justify={justify} {...props}>
          {children}
        </GridTableCell>
      ),
      stickyLeftText: ({ children, props }) => (
        <GridTableCell leftSticky sx={{ left: 28 }} {...props}>
          {children}
        </GridTableCell>
      ),
      rating: ({ item, justify, props }) => (
        <GridTableCell justify={justify} {...props}>
          <StyledRating
            defaultValue={item && 'rating' in item ? item.rating : 0}
            precision={0.25}
            readOnly
          />
        </GridTableCell>
      ),
      checkbox: ({ item, props }) => (
        <GridTableCell leftSticky {...props}>
          <ActionCheckbox
            size='small'
            checked={selected.has(item?.id ?? '')}
            onClick={toggleSelected(item?.id ?? '')}
          />
        </GridTableCell>
      ),
      menu: ({ item, props }) => (
        <MenuCell {...props}>
          <IconButton
            name='vertical-menu'
            onClick={item && handleSetEditingItem(item)}
          />
        </MenuCell>
      ),
      nationality: ({ children, justify, item, props }) => (
        <NationalityCell justify={justify} {...props}>
          <ReactCountryFlag
            countryCode={item && 'nationality' in item ? item.nationality : ''}
            svg
          />
          {children}
        </NationalityCell>
      ),
    }),
    [selected, handleSetEditingItem, toggleSelected]
  )

  return (
    <Root>
      <ActionsWrapper className='lg-element-hidden'>
        <InteractiveActionWrapper>
          <ActionSelectedNumber>
            {t(`components:teamPlayers.table.${resource}.selected`, {
              count: selected.size,
            })}
          </ActionSelectedNumber>
          <ActionNameWrapper onClick={handleActionContextOpen}>
            <ActionName>
              {t(`components:teamPlayers.table.${resource}.action`)}
            </ActionName>
            <AnimatedChevronIcon
              name='chevron-small-down'
              chevronUp={!isActionContextMenuOpen}
            />
          </ActionNameWrapper>
        </InteractiveActionWrapper>
        <PlayerAddWrapper onClick={handleModalOpen}>
          <PlayerAddText>
            {t(`components:teamPlayers.table.${resource}.add`)}
          </PlayerAddText>
          <PlayerAddIcon name='plus-circle' />
        </PlayerAddWrapper>
      </ActionsWrapper>
      <TableWrapper className='table-wrapper'>
        <GridTable
          columns={tableHeaderRows.length}
          ref={containerRef}
          columnsTemplate={columnsTemplate}
        >
          {tableHeaderRows.map((tableHeaderItem, index) => (
            <Fragment key={index}>
              {tableHeaderItem.render({
                Component: tableHeaderComponents[tableHeaderItem.type],
              })}
            </Fragment>
          ))}
          {listItems.data?.pages.map(page =>
            page.results.map(item =>
              tableRows.map((tableItem, index) => (
                <Fragment key={`${item.id}-${tableItem.type}-${index}`}>
                  {tableItem.render({
                    item,
                    Component: tableComponents[tableItem.type],
                  })}
                </Fragment>
              ))
            )
          )}
        </GridTable>
      </TableWrapper>
      <Popover
        open={isPopoverOpen}
        anchorEl={popoverAnchor}
        onClose={handleClosePopover}
        elevation={1}
        // TODO: try to remove hardcoded values
        anchorOrigin={{
          vertical: -4,
          horizontal: -54,
        }}
      >
        <MenuIconsWrapper>
          <IconButton name='pencil' onClick={handleModalOpen} />
          <IconButton name='trash' onClick={handleConfirmDialogOpen} />
          <IconButton name='vertical-menu' onClick={handleClosePopover} />
        </MenuIconsWrapper>
      </Popover>
      <DisplayMenu
        id='action-selector'
        anchorEl={actionAnchor}
        open={isActionContextMenuOpen}
        onClose={handleActionContextClose}
      >
        <DisplayMenuItem onClick={handleBulkDeleteItems}>
          <DisplayIconWrapper>
            <ActionIcon name='trash' />
          </DisplayIconWrapper>
          <DisplayText>
            {t('components:teamPlayers.actions.delete')}
          </DisplayText>
        </DisplayMenuItem>
      </DisplayMenu>
      <ConfirmDialog
        open={confirmDeleteDialogOpen}
        onResult={handleConfirmRemoveItem}
        options={{
          title: t(`components:teamPlayers.dialog.${resource}.delete.title`),
          description: t(
            `components:teamPlayers.dialog.${resource}.delete.message`,
            {
              name: editingItem && transformName(editingItem),
            }
          ),
        }}
      />
      <Modal
        key={editingItem?.id}
        title={t(
          editingItem === null
            ? `components:teamPlayers.modal.${resource}.addTitle`
            : `components:teamPlayers.modal.${resource}.editTitle`
        )}
        open={modalOpen}
        onClose={handleModalClose}
        item={editingItem}
      />
    </Root>
  )
}

export type { InfoItem, InfoTableHeaderElement, InfoTableElement }
