import { Playlist, PlaylistList } from '@sporttotal-tv/dip-coaching-client'

export const playlistMockup: Playlist[] = [
  {
    id: 'e6fa4003-5fb3-4283-a01c-e37b25c4d653',
    title: 'Senior Tactics Representative',
    description: 'reprehenderit laboriosam error qui id',
    public: false,
    created_by: '5a21e10e-0620-4375-93d4-ce9d0959fa26',
    created_at: 1655801910214,
    updated_at: 1655801910214,
    assets: [
      {
        id: '72c018a8-d548-4378-b655-7d3f98f80bc1',
        title: 'Internal Marketing Facilitator',
        description: 'Chief',
        start_time: 176,
        duration: 18468,
        tags: ['Health', 'Metal'],
        status: 'completed',
        event_id: '819a6bd0-9e04-468b-a567-561bc3bf5e46',
        match_id: '11e7d10f-c808-4fff-9968-527b89ca9e52',
        created_by: 'd0dd340a-3d1e-49ea-976d-f4d24e55d253',
        created_at: 1655886764,
        updated_at: 1655886764,
      },
    ],
  },
  {
    id: 'c2d52bf5-2ef4-4c9f-bb04-1f6ea812e60b',
    title: 'Corporate Implementation Associate',
    description: 'temporibus voluptatum quam harum officia',
    public: false,
    created_by: '',
    created_at: 1655801910,
    updated_at: 1655801910,
    assets: [],
  },
  {
    id: '3494077c-c5b8-48c3-b7ec-aa7822258429',
    title: 'Direct Security Liaison',
    description: 'est quia fugit vel velit',
    public: false,
    created_by: '7783f6b9-dd49-4b99-b94b-4b2fd369cc58',
    created_at: 1655801910438,
    updated_at: 1655801910438,
    assets: [],
  },
  {
    id: '34910e22-d774-4560-893b-c1429e86160b',
    title: 'updated',
    description: 'enim deserunt quo vel sed',
    public: false,
    created_by: 'f0c095ef-d3ad-4398-a150-ea894b4ed882',
    created_at: 1655801910452,
    updated_at: 1655801910452,
    assets: [],
  },
  {
    id: '64ece4a6-04b3-45d5-9b62-9e8ac054732f',
    title: '',
    description: '',
    public: false,
    created_by: '',
    created_at: 1655801910,
    updated_at: 1655801910,
    assets: [
      {
        id: '3d1bc472-6e6b-496a-a1e7-812f1fce3934',
        title: 'Internal Assurance Director',
        description: 'Direct',
        start_time: 9056,
        duration: 17625,
        tags: [],
        status: 'completed',
        event_id: '889ca0f2-c732-40bd-a547-412dce90b726',
        match_id: 'a876d49a-f32e-4fe8-9576-7ec0599084c2',
        created_by: '',
        created_at: 1655801910,
        updated_at: 1655801910,
      },
    ],
  },
  {
    id: 'f84ecbbd-bd5b-4c92-a9ff-d579646de8c4',
    title: 'Principal Configuration Associate',
    description: 'quia ab tempore incidunt et',
    public: false,
    created_by: '035d3c28-41f6-4bc0-b24d-8db4b3071685',
    created_at: 1655801910531,
    updated_at: 1655801910531,
    assets: [
      {
        id: '35270799-20b4-4790-8028-c8ecac70d905',
        title: 'Global Creative Administrator',
        description: 'Dynamic',
        start_time: 8518,
        duration: 11064,
        tags: ['Switzerland'],
        status: 'completed',
        event_id: 'b6557b31-a6d4-4b3b-81c3-53a33eb8c82d',
        match_id: 'd1076aa8-cf76-4953-93d0-83474691abf3',
        created_by: 'id',
        created_at: 1655801911,
        updated_at: 1655801911,
      },
    ],
  },
]

export const playlistListMockup: PlaylistList = {
  results: playlistMockup,
  total: 6,
}

export const playlistItemMockup: Playlist = {
  id: 'e6fa4003-5fb3-4283-a01c-e37b25c4d653',
  title: 'Senior Tactics Representative',
  description: 'reprehenderit laboriosam error qui id',
  public: true,
  created_by: '5a21e10e-0620-4375-93d4-ce9d0959fa26',
  created_at: 1655801910214,
  updated_at: 1655801910214,
  assets: [],
}
