import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
// Hooks
import { useAdminOrganizationList } from 'modules/organization/hooks'
// Components
import { IconButton } from 'modules/core/components'
import {
  OrganizationCreateModal,
  OrganizationList,
} from 'modules/organization/components'
// Constants
import { routeNames } from 'modules/core/constants'

import {
  Wrapper,
  Root,
  HeaderRow,
  OrganizationCreateWrapper,
} from './AdminOrganizations.styled'

export const AdminOrganizations = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('pages', { keyPrefix: 'organization' })

  const { data: organizations } = useAdminOrganizationList({
    expand: ['owner'],
  })

  const [isCreateOrganizationDialogOpen, setIsCreateOrganizationDialogOpen] =
    useState(false)

  const handleOrganizationNavigation = useCallback(
    (id: string) => {
      navigate(`${routeNames.admin.path}/organization/${id}`)
    },
    [navigate]
  )

  return (
    <Root>
      <Wrapper>
        <HeaderRow>
          <div />
          <OrganizationCreateWrapper
            onClick={() => setIsCreateOrganizationDialogOpen(true)}
          >
            {t('modal.createOrganization.create')}{' '}
            <IconButton name='plus-circle' />
          </OrganizationCreateWrapper>
        </HeaderRow>
        <OrganizationList
          organizations={organizations}
          handleRowClick={handleOrganizationNavigation}
        />
      </Wrapper>
      <OrganizationCreateModal
        open={isCreateOrganizationDialogOpen}
        onClose={() => setIsCreateOrganizationDialogOpen(false)}
      />
    </Root>
  )
}
