import { FC, useState } from 'react'
import { ModalDialog, TextInput, ActionButton } from 'modules/core/components'
import { useSnackbar } from 'modules/core/hooks/useSnackbar'
import { CameraReportIssueProps } from './CameraReportIssue.interface'
import { ButtonContainer, FormContainer } from './CameraReportIssue.styled'
import { useCameraReportIssue } from 'modules/camera/hooks'

export const CameraReportIssue: FC<CameraReportIssueProps> = ({
  open,
  onClose,
  camera,
}) => {
  const showSnackbar = useSnackbar()

  const { mutate } = useCameraReportIssue(
    camera?.system_status?.serial_number ?? ''
  )

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  const handleSubmit = () => {
    mutate(
      {
        title,
        description,
      },
      {
        onSuccess: () => {
          showSnackbar({
            message: `Issue reported successfully for ${camera?.system_status?.serial_number}`,
            type: 'success',
          })

          onClose()
          setTitle('')
          setDescription('')
        },
        onError: () => {
          showSnackbar({
            message: `Issue report failed for ${camera?.system_status?.serial_number}`,
            type: 'error',
          })
        },
      }
    )
  }

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={`Report Issue ${camera?.system_information?.serial_number || ''}`}
      icon='error'
    >
      <FormContainer>
        <TextInput
          label='Issue Title'
          value={title}
          onChange={e => setTitle(e.target.value)}
          placeholder='Brief description of the issue'
        />
        <TextInput
          label='Description'
          value={description}
          onChange={e => setDescription(e.target.value)}
          placeholder='Detailed description of the issue'
          multiline
          rows={4}
        />
        <ButtonContainer>
          <ActionButton
            text='Submit'
            onClick={handleSubmit}
            variant='contained'
            disabled={!title || !description}
          />
        </ButtonContainer>
      </FormContainer>
    </ModalDialog>
  )
}
