import { UserFeatureTier, UserRole, UserStatus } from '../types'
import { AdminUserInvite, User } from '@sporttotal-tv/dip-coaching-client'

export const usersMockup: User[] = [
  {
    id: '6340216f25198751ebed0df6',
    firstname: 'Lydia',
    lastname: 'Fox',
    email: '@undefined.industries',
    avatar: '',
    data: {},
    role: 'ADMIN' as UserRole,
    status: 'SUSPENDED' as UserStatus,
    feature_tier: 'professional' as UserFeatureTier,
    system_ref: '',
    created_at: 1637560159240,
    updated_at: 1637646559000,
  },
  {
    id: '6340216f1fa4a094e8155749',
    firstname: 'Deanna',
    lastname: 'Hardy',
    email: '@undefined.pw',
    avatar: '',
    data: {},
    role: 'COACH' as UserRole,
    status: 'ACTIVE' as UserStatus,
    feature_tier: 'professional' as UserFeatureTier,
    system_ref: '',
    created_at: 1639440625827,
    updated_at: 1639527025000,
  },
  {
    id: '6340216fd3c88e6cd4ec6cae',
    firstname: 'Crystal',
    lastname: 'Barton',
    email: '@undefined.gov',
    avatar: '',
    data: {},
    role: 'COACH' as UserRole,
    status: 'PENDING' as UserStatus,
    feature_tier: 'professional' as UserFeatureTier,
    system_ref: '',
    created_at: 1618412166760,
    updated_at: 1618498566000,
  },
  {
    id: '6340216f2f4326dfe385c2ef',
    firstname: 'Molly',
    lastname: 'Valdez',
    email: '@undefined.vet',
    avatar: '',
    data: {},
    role: 'COACH' as UserRole,
    status: 'SUSPENDED' as UserStatus,
    feature_tier: 'professional' as UserFeatureTier,
    system_ref: '',
    created_at: 1629644017495,
    updated_at: 1629730417000,
  },
  {
    id: '6340216f3abfdff722c13550',
    firstname: 'Dyer',
    lastname: 'Landry',
    email: '@undefined.money',
    avatar: '',
    data: {},
    role: 'ADMIN' as UserRole,
    status: 'ACTIVE' as UserStatus,
    feature_tier: 'professional' as UserFeatureTier,
    system_ref: '',
    created_at: 1621722873243,
    updated_at: 1621809273000,
  },
  {
    id: '6340216f8f3a9879a0a239da',
    firstname: 'Small',
    lastname: 'Washington',
    email: '@undefined.one',
    avatar: '',
    data: {},
    role: 'ADMIN' as UserRole,
    status: 'PENDING' as UserStatus,
    feature_tier: 'professional' as UserFeatureTier,
    system_ref: '',
    created_at: 1611529234469,
    updated_at: 1611615634000,
  },
  {
    id: '6340216f4f230609e61b4bea',
    firstname: 'Espinoza',
    lastname: 'Hurst',
    email: '@undefined.sm',
    avatar: '',
    data: {},
    role: 'PLAYER' as UserRole,
    status: 'PENDING' as UserStatus,
    feature_tier: 'professional' as UserFeatureTier,
    system_ref: '',
    created_at: 1617009443040,
    updated_at: 1617095843000,
  },
  {
    id: '6340216f0f49b131a4ef74f1',
    firstname: 'Celina',
    lastname: 'Benson',
    email: '@undefined.sydney',
    avatar: '',
    data: {},
    role: 'ROOT' as UserRole,
    status: 'PENDING' as UserStatus,
    feature_tier: 'professional' as UserFeatureTier,
    system_ref: '',
    created_at: 1616759193351,
    updated_at: 1616845593000,
  },
  {
    id: '6340216f3458b8ecfabd9873',
    firstname: 'Saunders',
    lastname: 'Gilbert',
    email: '@undefined.host',
    avatar: '',
    data: {},
    role: 'ROOT' as UserRole,
    status: 'ACTIVE' as UserStatus,
    feature_tier: 'professional' as UserFeatureTier,
    system_ref: '',
    created_at: 1618718440054,
    updated_at: 1618804840000,
  },
  {
    id: '6340216fa1c0e15ca4c27944',
    firstname: 'Amber',
    lastname: 'Wallace',
    email: '@undefined.construction',
    avatar: '',
    data: {},
    role: 'PLAYER' as UserRole,
    status: 'SUSPENDED' as UserStatus,
    feature_tier: 'professional' as UserFeatureTier,
    system_ref: '',
    created_at: 1617254681713,
    updated_at: 1617341081000,
  },
  {
    id: '6340216fea0f60dcaadedbf1',
    firstname: 'Lana',
    lastname: 'Finley',
    email: '@undefined.ht',
    avatar: '',
    data: {},
    role: 'ADMIN' as UserRole,
    status: 'SUSPENDED' as UserStatus,
    feature_tier: 'professional' as UserFeatureTier,
    system_ref: '',
    created_at: 1634174171736,
    updated_at: 1634260571000,
  },
  {
    id: '6340216fec0308184df1d6a9',
    firstname: 'Sandra',
    lastname: 'Ford',
    email: '@undefined.no',
    avatar: '',
    data: {},
    role: 'ROOT' as UserRole,
    status: 'PENDING' as UserStatus,
    feature_tier: 'professional' as UserFeatureTier,
    system_ref: '',
    created_at: 1611373935006,
    updated_at: 1611460335000,
  },
  {
    id: '6340216f71a10d25280b46db',
    firstname: 'Ward',
    lastname: 'Spears',
    email: '@undefined.gmo',
    avatar: '',
    data: {},
    role: 'ROOT' as UserRole,
    status: 'SUSPENDED' as UserStatus,
    feature_tier: 'professional' as UserFeatureTier,
    system_ref: '',
    created_at: 1622147731439,
    updated_at: 1622234131000,
  },
  {
    id: '6340216f919e86a470601a87',
    firstname: 'Potts',
    lastname: 'Frye',
    email: '@undefined.mini',
    avatar: '',
    data: {},
    role: 'COACH' as UserRole,
    status: 'SUSPENDED' as UserStatus,
    feature_tier: 'professional' as UserFeatureTier,
    system_ref: '',
    created_at: 1634844021732,
    updated_at: 1634930421000,
  },
  {
    id: '6340216fb68b5b56bcafb56b',
    firstname: 'Leola',
    lastname: 'Morse',
    email: '@undefined.love',
    avatar: '',
    data: {},
    role: 'ADMIN' as UserRole,
    status: 'ACTIVE' as UserStatus,
    feature_tier: 'professional' as UserFeatureTier,
    system_ref: '',
    created_at: 1622321391288,
    updated_at: 1622407791000,
  },
  {
    id: '6340216fe0c7be7fea7a2d4e',
    firstname: 'Cotton',
    lastname: 'Schmidt',
    email: '@undefined.social',
    avatar: '',
    data: {},
    role: 'ADMIN' as UserRole,
    status: 'PENDING' as UserStatus,
    feature_tier: 'professional' as UserFeatureTier,
    system_ref: '',
    created_at: 1615182606316,
    updated_at: 1615269006000,
  },
  {
    id: '6340216fbfc098e111fe0d38',
    firstname: 'Gonzales',
    lastname: 'Mccall',
    email: '@undefined.bargains',
    avatar: '',
    data: {},
    role: 'PLAYER' as UserRole,
    status: 'ACTIVE' as UserStatus,
    feature_tier: 'professional' as UserFeatureTier,
    system_ref: '',
    created_at: 1639592559539,
    updated_at: 1639678959000,
  },
  {
    id: '6340216f4fcf08367fb63e9a',
    firstname: 'Cynthia',
    lastname: 'Mccormick',
    email: '@undefined.dclk',
    avatar: '',
    data: {},
    role: 'COACH' as UserRole,
    status: 'SUSPENDED' as UserStatus,
    feature_tier: 'professional' as UserFeatureTier,
    system_ref: '',
    created_at: 1639780847252,
    updated_at: 1639867247000,
  },
  {
    id: '6340216f46777059e078720c',
    firstname: 'Gibbs',
    lastname: 'Maddox',
    email: '@undefined.dm',
    avatar: '',
    data: {},
    role: 'PLAYER' as UserRole,
    status: 'PENDING' as UserStatus,
    feature_tier: 'professional' as UserFeatureTier,
    system_ref: '',
    created_at: 1636229371396,
    updated_at: 1636315771000,
  },
  {
    id: '6340216f98f8da0472b43423',
    firstname: 'Amparo',
    lastname: 'Norman',
    email: '@undefined.accountants',
    avatar: '',
    data: {},
    role: 'ADMIN' as UserRole,
    status: 'SUSPENDED' as UserStatus,
    feature_tier: 'professional' as UserFeatureTier,
    system_ref: '',
    created_at: 1611702255967,
    updated_at: 1611788655000,
  },
]

export const userMockup: User = {
  id: 'e6fa4003-5fb3-4283-a01c-e37b25c4d653',
  firstname: 'First name',
  lastname: 'Last name',
  email: 'some@email.com',
  avatar: '',
  data: {},
  role: 'ROOT' as UserRole,
  status: 'ACTIVE' as UserStatus,
  feature_tier: 'advanced' as UserFeatureTier,
  system_ref: '',
  created_at: 1655801910214,
  updated_at: 1655801910214,
}

export const inviteMockup: AdminUserInvite = {
  id: 'e6fa4003-5fb3-4283-a01c-e37b25c4d653',
  email: 'some@email.com',
  token: 'token',
  issued_by_id: 'id',
  issued_to_id: 'id',
  expires_at: 1655801910214,
  created_at: 1655801910214,
  updated_at: 1655801910214,
}
