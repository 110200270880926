import {
  transformApiResults,
  transformIdApiResults,
} from 'modules/core/helpers'
import {
  PlayerTransfer,
  PlayerTransferListParams,
  PlayerTransferList,
  CreatePlayerTransferBody,
  UpdatePlayerTransferBody,
} from '@sporttotal-tv/dip-coaching-client'
import { api } from 'modules/core/api'

export const TeamPlayerTransferApiService = {
  list: transformApiResults<PlayerTransferListParams, PlayerTransferList>(
    api.playerTransfer.list
  ),
  retrieve: transformApiResults<string, PlayerTransfer>(
    api.playerTransfer.retrieve
  ),
  create: transformApiResults<CreatePlayerTransferBody, PlayerTransfer>(
    api.playerTransfer.create
  ),
  delete: transformApiResults<string, PlayerTransfer>(
    api.playerTransfer.delete
  ),
  update: transformIdApiResults<UpdatePlayerTransferBody, PlayerTransfer>(
    api.playerTransfer.update
  ),
}
