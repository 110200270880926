import { Button, Typography, styled } from '@mui/material'

export const PeriodSelector = styled(Button, {
  shouldForwardProp: prop => prop !== 'active',
})<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  padding: ${({ theme }) => theme.spacing(1.75, 2.5)};
  width: 225px;
  transition: all 0.3s;
  user-select: none;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  background-color: ${({ theme, active }) =>
    active ? theme.palette.secondary.main : theme.palette.primary.main};

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`

export const Discount = styled(Typography)`
  font-size: 10px;
  font-weight: 400;
`
