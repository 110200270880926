import { BILLING_PERIODS } from 'modules/subscription/constants'
import { PRODUCT_ORDER } from '../constants'
import { BilligPeriod } from 'modules/subscription/types'
import { UserFeatureTier } from 'modules/user/types'

type TransformedProductData = Record<BilligPeriod, any>

const sortProducts = (products: any[]) =>
  products.sort((a, b) => {
    const firstProductTier = a.metadata.TIER as UserFeatureTier
    const secondProductTier = b.metadata.TIER as UserFeatureTier

    return PRODUCT_ORDER[firstProductTier] > PRODUCT_ORDER[secondProductTier]
      ? 1
      : -1
  })

export const transformProductsData = (data: any[]) => {
  const groupedProducts = data.reduce<TransformedProductData>(
    (acc, { product, active, recurring, ...price }) => {
      if (!active) return acc

      const isMonthly: BilligPeriod =
        recurring.interval === 'month' ? 'MONTHLY' : 'YEARLY'

      acc[isMonthly].push({ ...product, price })
      return acc
    },
    {
      [BILLING_PERIODS.MONTHLY]: [],
      [BILLING_PERIODS.YEARLY]: [],
    }
  )

  groupedProducts[BILLING_PERIODS.MONTHLY] = sortProducts(
    groupedProducts[BILLING_PERIODS.MONTHLY]
  )
  groupedProducts[BILLING_PERIODS.YEARLY] = sortProducts(
    groupedProducts[BILLING_PERIODS.YEARLY]
  )

  return groupedProducts
}
