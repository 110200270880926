import { styled } from '@mui/material'
import BaseDragIcon from '@mui/icons-material/ViewCompact'

export const ToolbarDivider = styled('div')`
  display: flex;
  align-items: center;
`

export const ChevronContainer = styled('div')`
  transform: rotate(180deg);
`

export const DragIcon = styled(BaseDragIcon)`
  width: 22px;
  cursor: move;
  margin-right: 5px;
  color: ${({ theme }) => theme.palette.primary.light};
`
