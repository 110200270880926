import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { translateServerProperty } from 'modules/core/i18n/utils'
import { ServerTranslatedProperty } from 'modules/core/i18n/types'

export const useServerTranslatedProperty = () => {
  const { i18n } = useTranslation()
  const language = i18n.language as keyof ServerTranslatedProperty

  return useCallback(
    (translationField?: ServerTranslatedProperty) => {
      if (!translationField) return

      return translateServerProperty(translationField, language)
    },
    [language]
  )
}
