import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { LayoutIndex } from 'modules/layout/constants'
import { Layout, Layouts } from 'react-grid-layout'
import { LayoutConfigBreakpoints } from 'modules/layout/types'
import {
  UserLayoutTypesVariant,
  UserTabsDataVariant,
  UserTabsVariant,
} from 'modules/user/types'

export type LayoutKeys = {
  tabsDataKey: UserTabsDataVariant
  tabsKey: UserTabsVariant
  layoutTypesKey: UserLayoutTypesVariant
}

export interface LayoutState {
  activeTab: string
  // Layout
  layoutsWithBreakpoints: Layouts
  layoutTypes: Record<string, LayoutIndex>
  layoutsPositionStack: string[]
  breakpoint: LayoutConfigBreakpoints
  layoutKeys: LayoutKeys
}

const initialState: LayoutState = {
  activeTab: 'dashboard',
  layoutsWithBreakpoints: {},
  layoutTypes: {},
  layoutsPositionStack: [],
  breakpoint: 'lg',
  layoutKeys: {
    tabsDataKey: 'tabsData',
    tabsKey: 'tabs',
    layoutTypesKey: 'layoutTypes',
  },
}

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setLayoutKeys: (state, action: PayloadAction<LayoutKeys>) => {
      state.layoutKeys.tabsKey = action.payload.tabsKey
      state.layoutKeys.tabsDataKey = action.payload.tabsDataKey
      state.layoutKeys.layoutTypesKey = action.payload.layoutTypesKey
    },
    updateLayout: (state, action: PayloadAction<Layout[]>) => {
      state.layoutsWithBreakpoints = {
        ...state.layoutsWithBreakpoints,
        [state.breakpoint]: action.payload,
      }
    },
    setBreakpoint: (state, action: PayloadAction<LayoutConfigBreakpoints>) => {
      state.breakpoint = action.payload
    },

    setLayoutsPositionStack: (state, action: PayloadAction<string>) => {
      const [lastPositionStackItem] = state.layoutsPositionStack.slice(-1)

      if (lastPositionStackItem === action.payload) return

      state.layoutsPositionStack = [
        ...state.layoutsPositionStack.filter(item => item !== action.payload),
        action.payload,
      ]
    },
    setLayoutsWithBreakpoints: (state, action: PayloadAction<Layouts>) => {
      state.layoutsWithBreakpoints = action.payload
    },
    setActiveTab: (
      state,
      action: PayloadAction<{
        tab: string
        layoutsWithBreakpoints: Layouts
      }>
    ) => {
      state.activeTab = action.payload.tab
      state.layoutsWithBreakpoints = action.payload.layoutsWithBreakpoints
    },

    addLayoutTypes: (
      state,
      action: PayloadAction<{ id: string; type: LayoutIndex }>
    ) => {
      state.layoutTypes = {
        ...state.layoutTypes,
        [action.payload.id]: action.payload.type,
      }
    },

    setLayoutTypes: (
      state,
      action: PayloadAction<Record<string, LayoutIndex>>
    ) => {
      state.layoutTypes = action.payload
    },

    removeLayout: (state, action: PayloadAction<string>) => {
      state.layoutsWithBreakpoints = {
        ...Object.entries(state.layoutsWithBreakpoints).reduce<Layouts>(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value.filter(layout => layout.i !== action.payload),
          }),
          {}
        ),
      }
    },
    setLockStatus: (
      state,
      action: PayloadAction<{ layoutId: string; locked: boolean }>
    ) => {
      state.layoutsWithBreakpoints = {
        ...Object.entries(state.layoutsWithBreakpoints).reduce<Layouts>(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value.map(layout =>
              layout.i !== action.payload.layoutId
                ? layout
                : {
                    ...layout,
                    isDraggable: action.payload.locked,
                    isResizable: action.payload.locked,
                  }
            ),
          }),
          {}
        ),
      }
    },
  },
})

export const {
  updateLayout,
  removeLayout,
  setActiveTab,
  setBreakpoint,
  setLayoutsPositionStack,
  setLayoutsWithBreakpoints,
  addLayoutTypes,
  setLockStatus,
  setLayoutTypes,
  setLayoutKeys,
} = layoutSlice.actions

export const layoutReducer = layoutSlice.reducer
