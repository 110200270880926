import { useInfiniteQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import {
  PlayerList,
  PlayerListParams,
} from '@sporttotal-tv/dip-coaching-client'
import { useQueryRetry } from 'modules/core/hooks'
import { getNextPageParamWithLimit } from 'modules/core/utils'
import { TEAM_PAGE_LIMIT } from 'modules/team/constants'

export const TEAM_PLAYER_LIST_CACHE_KEY = 'team-player-list'

export const useTeamPlayerList = (options?: PlayerListParams) => {
  const handleQueryRetry = useQueryRetry()

  return useInfiniteQuery(
    [TEAM_PLAYER_LIST_CACHE_KEY, options],
    ({ pageParam = 0 }) =>
      ApiService.teamPlayer.list({ ...options, page: pageParam }),
    {
      retry: handleQueryRetry,
      staleTime: Infinity,
      getNextPageParam: getNextPageParamWithLimit<PlayerList>(TEAM_PAGE_LIMIT),
    }
  )
}
