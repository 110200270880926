import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  EventTime,
  CalendarEvent,
  CalendarLayoutVariant,
} from 'modules/calendar/types'

export interface CalendarState {
  selectedDate: number // timestamp
  layout: CalendarLayoutVariant
  openDialog: boolean
  openViewDialog: boolean
  eventBeginDate?: number // timestamp
  eventBeginTime?: EventTime
  eventEndDate?: number // timestamp
  eventEndTime?: EventTime
  title?: string
  description?: string
  calendarEvent?: CalendarEvent
  draggingEventId?: number | string
  eventID?: number | string
  ghostProperties: { width: number; height: number }
}

export type CalendarEditData = Required<
  Pick<
    CalendarState,
    | 'eventBeginTime'
    | 'eventEndTime'
    | 'eventBeginDate'
    | 'eventEndDate'
    | 'eventID'
    | 'title'
    | 'description'
  >
>

const initialState: CalendarState = {
  selectedDate: new Date().getTime(),
  layout: 'week',
  openDialog: false,
  openViewDialog: false,
  ghostProperties: { width: 0, height: 0 },
}

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    resetCalendarDraggingEvent: state => {
      state.draggingEventId = undefined
    },
    setEditDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.openDialog = action.payload
    },
    setViewDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.openViewDialog = action.payload
    },
    setCalendarLayout: (
      state,
      action: PayloadAction<CalendarLayoutVariant>
    ) => {
      state.layout = action.payload
    },
    setCalendarSelectedDate: (state, action: PayloadAction<number>) => {
      state.selectedDate = action.payload
    },
    setActiveCalendarEvent: (state, action: PayloadAction<CalendarEvent>) => {
      state.calendarEvent = action.payload
      state.openViewDialog = true
    },
    setCalendarEditEvent: (state, action: PayloadAction<CalendarEditData>) => {
      state.openDialog = true
      state.openViewDialog = false
      state.eventBeginDate = action.payload.eventBeginDate
      state.eventBeginTime = action.payload.eventBeginTime
      state.eventEndDate = action.payload.eventEndDate
      state.eventEndTime = action.payload.eventEndTime
      state.eventID = action.payload.eventID
      state.title = action.payload.title
      state.description = action.payload.description
    },
    setCalendarDraggingEvent: (
      state,
      action: PayloadAction<
        Required<
          Pick<
            CalendarState,
            'draggingEventId' | 'calendarEvent' | 'ghostProperties'
          >
        >
      >
    ) => {
      state.draggingEventId = action.payload.draggingEventId
      state.calendarEvent = action.payload.calendarEvent
      state.ghostProperties = action.payload.ghostProperties
    },
  },
})

export const {
  setActiveCalendarEvent,
  setCalendarDraggingEvent,
  setCalendarEditEvent,
  resetCalendarDraggingEvent,
  setCalendarLayout,
  setCalendarSelectedDate,
  setEditDialogOpen,
  setViewDialogOpen,
} = calendarSlice.actions

export const calendarReducer = calendarSlice.reducer
