import { teamPlayerTransferMockup } from 'modules/team-player/utils'
import {
  PlayerTransfer,
  CreatePlayerTransferBody,
} from '@sporttotal-tv/dip-coaching-client'
import { generateId } from 'modules/core/helpers/generateId'

export const placeholderPlayerTransfer = (
  payload?: CreatePlayerTransferBody
): PlayerTransfer => ({
  ...teamPlayerTransferMockup,
  id: generateId(),
  ...payload,
})
