import { useState, useEffect } from 'react'
import { format, getMonth, addMonths, subMonths } from 'date-fns'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Typography from '@mui/material/Typography'
import getWeekDays from '../utils/getWeekDays'
import { css, styled } from '@mui/material'
import { useReduxDispatch, useReduxSelector } from 'modules/core/hooks'
import {
  selectCalendarSelectedDate,
  setCalendarSelectedDate,
} from 'modules/calendar/redux'
import { useTranslation } from 'react-i18next'

export const Root = styled('section')`
  min-height: 264px;
  min-width: 240px;
  background: ${({ theme }) => theme.palette.primary.main};
`

export const Title = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacing(1)};
  text-transform: capitalize;
`

export const DayHeader = styled(Typography)`
  text-align: center;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.secondary.main};
  line-height: 26px;
  padding: ${({ theme }) => theme.spacing(0.2)};
  border-color: ${({ theme }) => theme.palette.primary.main};
  border-style: solid;
  text-transform: capitalize;
  background: ${({ theme }) => theme.palette.primary.main};
  height: 34.3px;
  width: 34.3px;
`

export const Day = styled(Typography, {
  shouldForwardProp: prop =>
    prop !== 'isToday' && prop !== 'isSelected' && prop !== 'isCurrentMonth',
})<{ isToday: boolean; isSelected: boolean; isCurrentMonth: boolean }>`
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  border-radius: 50%;
  border-width: ${({ theme }) => theme.spacing(0.4)};
  line-height: 26px;
  padding: ${({ theme }) => theme.spacing(0.2)};
  background: ${({ theme }) => theme.palette.primary.main};
  height: 34.3px;
  width: 34.3px;

  &:hover {
    color: #ffffff;
    background-color: ${({ theme }) =>
      theme.createAlpha(theme.palette.secondary.main, 0.7)};
  }

  ${({ isToday, theme }) =>
    isToday
      ? css`
          color: ${theme.palette.common.white};
          background: ${theme.palette.primary.main};
          border-color: ${theme.palette.primary.main};
          border-style: solid;
          background-color: ${theme.createAlpha(
            theme.palette.secondary.main,
            0.8
          )};
          &:hover {
            background-color: ${theme.palette.secondary.main};
          }
        `
      : css`
          background-color: ${theme.palette.primary.main};
          border-color: ${theme.palette.primary.main};
          border-style: solid;
          &:hover {
            background-color: ${theme.palette.grey[700]};
          }
        `}

  ${({ isSelected, isToday, theme }) =>
    !isToday &&
    isSelected &&
    css`
      color: #ffffff;
      border-color: ${theme.palette.primary.main};
      border-style: solid;
      background-color: ${theme.createAlpha(theme.palette.secondary.main, 0.5)};

      &:hover {
        color: #ffffff;
        background-color: ${theme.createAlpha(
          theme.palette.secondary.main,
          0.7
        )};
      }
    `}
  
    ${({ isCurrentMonth, theme }) =>
    !isCurrentMonth &&
    css`
      color: ${theme.palette.grey[500]};
      background: ${theme.palette.primary.main};
    `}
`

export const Navigation = styled(Grid)`
  margin-right: ${({ theme }) => theme.spacing(0.5)};
`

const CalendarSmall = () => {
  const dispatch = useReduxDispatch()
  const selectedDate = useReduxSelector(selectCalendarSelectedDate)
  const { t } = useTranslation('pages', { keyPrefix: 'calendar' })

  const [internalDate, setInternalDate] = useState(selectedDate)
  const [selectedInternalDate, setSelectedInternalDate] = useState<Date | null>(
    null
  )

  useEffect(() => {
    setInternalDate(selectedDate)
    selectedDate.getTime() !== selectedInternalDate?.getTime() &&
      setSelectedInternalDate(null)
  }, [selectedDate, selectedInternalDate])

  const weeks = getWeekDays(internalDate, 7)

  const findNewDate = (direction: string) => () => {
    setInternalDate(
      direction === '<'
        ? subMonths(internalDate, 1)
        : addMonths(internalDate, 1)
    )
  }

  const selectDate = (newDate: Date) => () => {
    dispatch(setCalendarSelectedDate(newDate.getTime()))
    setSelectedInternalDate(newDate)
  }

  return (
    <Root>
      <Grid
        container
        direction='row'
        alignItems='center'
        spacing={0}
        wrap='nowrap'
        justifyItems='flex-end'
      >
        <Grid item xs={8} alignItems='center'>
          <Title>{format(internalDate, 'MMMM yyyy')}</Title>
        </Grid>
        <Navigation
          item
          xs={4}
          container
          direction='row'
          justifyItems='flex-end'
          alignItems='center'
          spacing={0}
          wrap='nowrap'
        >
          <Tooltip title={t('previous.month')}>
            <IconButton size='small' onClick={findNewDate('<')}>
              <ChevronLeftIcon />
            </IconButton>
          </Tooltip>{' '}
          <Tooltip title={t('next.month')}>
            <IconButton size='small' onClick={findNewDate('>')}>
              <ChevronRightIcon />
            </IconButton>
          </Tooltip>
        </Navigation>
      </Grid>

      <Grid
        container
        spacing={0}
        direction='row'
        justifyItems='center'
        alignItems='center'
        wrap='nowrap'
      >
        {weeks[0].map((weekDay: Date, index: number) => {
          return (
            <Grid item xs key={`small-calendar-column-header-${index}`}>
              <DayHeader>{format(weekDay, 'EEEEEE')}</DayHeader>
            </Grid>
          )
        })}
      </Grid>

      {weeks.map((week, weekIndex: number) => (
        <Grid
          container
          spacing={0}
          direction='row'
          justifyItems='center'
          alignItems='center'
          wrap='nowrap'
          key={`small-calendar-line-${weekIndex}`}
        >
          {week.map((day, dayIndex: number) => {
            const isToday =
              format(day, 'ddMMyyyy') === format(new Date(), 'ddMMyyyy')

            const isSelected =
              selectedInternalDate !== null &&
              !isToday &&
              format(day ?? new Date(), 'ddMMyyyy') ===
                format(
                  Boolean(selectedInternalDate)
                    ? selectedInternalDate
                    : new Date(),
                  'ddMMyyyy'
                )

            const isCurrentMonth = getMonth(internalDate) === getMonth(day)

            return (
              <Grid
                item
                xs
                key={`small-calendar-line-${weekIndex}-column-${dayIndex}`}
              >
                <Day
                  isToday={isToday}
                  isSelected={isSelected}
                  isCurrentMonth={isCurrentMonth}
                  onClick={selectDate(day)}
                >
                  {day.getDate()}
                </Day>
              </Grid>
            )
          })}
        </Grid>
      ))}
    </Root>
  )
}

export default CalendarSmall
