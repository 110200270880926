import { styled } from '@mui/material'

export const Loader = styled('div', {
  shouldForwardProp: prop => prop !== 'size',
})<{ size?: number }>`
  display: inline-block;
  width: ${({ size }) => (size ? size + 16 : 80)}px;
  height: ${({ size }) => (size ? size + 16 : 80)}px;

  &:after {
    content: ' ';
    display: block;
    width: ${({ size }) => size ?? 64}px;
    height: ${({ size }) => size ?? 64}px;
    margin: 8px;
    border-radius: 50%;
    border-width: 6px;
    border-style: solid;
    border-color: ${({ theme }) => theme.palette.common.white} transparent
      ${({ theme }) => theme.palette.common.white} transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const HorizontalLoader = styled('div')`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  color: ${({ theme }) => theme.palette.common.white};
  box-sizing: border-box;
  animation: animloader 2s linear infinite;

  @keyframes animloader {
    0% {
      box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
    }
    25% {
      box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;
    }
    50% {
      box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;
    }
    75% {
      box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
    }
    100% {
      box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;
    }
  }
`
