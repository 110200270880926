import {
  useEffect,
  useRef,
  useContext,
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { contain } from 'intrinsic-scale'
// Hooks
import { useTranslation } from 'react-i18next'
import { useReduxSelector, useResizeObserver } from 'modules/core/hooks'
import { useSource } from 'modules/video-player/hooks'
import { useMatch } from 'modules/match/hooks'
// Redux
import {
  selectActiveUrl,
  selectIsKeyboardControlsEnabled,
  selectVideoPlayerTelestrationComponentId,
} from 'modules/video-player/redux'
// Context
import { ComponentInfoContext } from 'modules/generic/context'
import {
  VideoPlayerContext,
  VideoPlayersContext,
} from 'modules/video-player/context'
// Components
import { BaseVideoPlayer } from 'modules/video-player/components'
import { Annotator } from 'modules/annotator/components'
import { NoMatchWrapper } from './VideoRender.styled'
// types
import { RenderElementProps } from 'modules/video-player/types'
import { MediaPlayer } from 'modules/media-render/types'
// Utils
import { fetchAvailableMatchLocators } from 'modules/match/utils'
// Images
import LoadingSrc from 'assets/images/spinning-circles.svg'
import { selectActiveShape } from 'modules/telestration/redux'

export const VideoRender = forwardRef<
  MediaPlayer | undefined,
  RenderElementProps
>(({ isTelestrationToolAvailable, setIsLoadingShape }, videoRef) => {
  const [isLoadingNeeded, setIsLoadingNeeded] = useState<boolean>(true)
  const { t } = useTranslation('components', { keyPrefix: 'player' })

  const { componentId } = useContext(ComponentInfoContext)
  const { match } = useMatch()
  const activeUrl = useReduxSelector(state =>
    selectActiveUrl(state, componentId)
  )

  const telestrationId = useReduxSelector(state =>
    selectVideoPlayerTelestrationComponentId(state, componentId)
  )
  const activeShape = useReduxSelector(state =>
    selectActiveShape(state, telestrationId ?? '')
  )

  const isKeyboardControlEnabled = useReduxSelector(state =>
    selectIsKeyboardControlsEnabled(state, componentId)
  )

  const { setPlayer: setPlayerContext } = useContext(VideoPlayerContext)
  const { addPlayer: addPlayerToPlayersContext } =
    useContext(VideoPlayersContext)

  const isAnyViewAvailable = useMemo(
    () => Object.values(fetchAvailableMatchLocators(match)).some(view => view),
    [match]
  )

  const playerRef = useRef<HTMLVideoElement>(null)

  const { height: playerContainerHeight, width: playerContainerWidth } =
    useResizeObserver(playerRef)

  useSource(playerRef, activeUrl, setIsLoadingNeeded)

  useImperativeHandle(
    videoRef,
    () => {
      if (!playerRef.current) return undefined

      return playerRef.current
    },
    []
  )

  useEffect(() => {
    if (playerRef.current) {
      // This block will change to specific time if we returned from frame to start playing from that time
      // To reduce render I compare the data to the actual url instead of checking media locator
      try {
        const newTime = JSON.parse(localStorage.getItem('returnTime') ?? '{}')
        if (playerRef.current.src === newTime?.url) {
          playerRef.current.currentTime = Number(newTime?.time ?? 0)
        }
      } catch (e) {}

      setPlayerContext(playerRef.current, componentId)
      addPlayerToPlayersContext(playerRef.current, componentId)
      localStorage.removeItem('returnTime')
    }
  }, [playerRef, componentId, setPlayerContext, addPlayerToPlayersContext])

  useEffect(() => {
    const playerData = playerRef.current

    playerData?.addEventListener('loadedmetadata', () =>
      setIsLoadingNeeded(false)
    )

    return () => {
      playerData?.removeEventListener('loadedmetadata', () =>
        setIsLoadingNeeded(true)
      )
    }
  }, [])

  const {
    width: videoWidth,
    height: videoHeight,
    x: videoLeft,
    y: videoTop,
  } = contain(
    playerContainerWidth,
    playerContainerHeight,
    playerRef.current?.videoWidth ?? 0,
    playerRef.current?.videoHeight ?? 0
  )

  return (
    <BaseVideoPlayer
      autoPlay={true}
      ref={playerRef}
      poster={isLoadingNeeded && match ? LoadingSrc : undefined}
      isKeyboardControlEnabled={!activeShape && isKeyboardControlEnabled}
    >
      {isTelestrationToolAvailable && (
        <Annotator
          onLoadingStatusChange={setIsLoadingShape}
          containerHeight={playerContainerHeight}
          containerWidth={playerContainerWidth}
          elementHeight={videoHeight}
          elementWidth={videoWidth}
          elementLeft={videoLeft}
          elementTop={videoTop}
        />
      )}

      {match && !isAnyViewAvailable && (
        <NoMatchWrapper>{t('noSourceAvailable')}</NoMatchWrapper>
      )}
    </BaseVideoPlayer>
  )
})
