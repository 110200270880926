import { PropsWithChildren, FC } from 'react'
import { LinkProps as MUILinkProps } from '@mui/material/Link'
import { DefaultStyledLink, SocialStyledLink } from './styled'
import { LINK_VARIANTS, LinkProps } from './utils'

interface Props extends PropsWithChildren {}

export const Link: FC<Props & LinkProps & Omit<MUILinkProps, 'variant'>> = ({
  variant = LINK_VARIANTS.default,
  children,
  ...linkProps
}) => {
  const linkVariants = {
    [LINK_VARIANTS.default]: (
      <DefaultStyledLink {...linkProps}>{children}</DefaultStyledLink>
    ),
    [LINK_VARIANTS.social]: (
      <SocialStyledLink {...linkProps}>{children}</SocialStyledLink>
    ),
  }
  return linkVariants[variant]
}
