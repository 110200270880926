import { IKonvaMouseEvent, ShapeHandler } from 'modules/annotator/types'
import { ShapeOptions, TelestrationArrow } from 'modules/telestration/types'

const Arrow: ShapeHandler<TelestrationArrow> = {
  onCreate({ currentTarget }: IKonvaMouseEvent, options?: ShapeOptions) {
    const points = currentTarget.getStage()?.getPointerPosition()
    const X = points?.x ?? null
    const Y = points?.y ?? null

    if (!X || !Y) return

    return {
      ...options,
      type: 'ARROW',
      points: [X, Y],
    }
  },

  onShaping(arrow, { currentTarget }: IKonvaMouseEvent) {
    const point = currentTarget.getStage()?.getPointerPosition()
    const X = point?.x ?? null
    const Y = point?.y ?? null

    if (!X || !Y) return

    const [startX, startY] = arrow.points
    const points = [startX, startY, X, Y]

    return { ...arrow, points }
  },

  onShapeUpdated({ currentTarget }) {
    return currentTarget
  },
}

export { Arrow }
