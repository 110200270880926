import { api } from 'modules/core/api'
import { transformApi } from 'modules/core/helpers'
import { reverseParams } from 'modules/core/utils'

export const OrganizationAdminUserInviteApiService = {
  list: transformApi(api.organizationGroupAdminUserInvite.list),
  create: transformApi(api.organizationGroupAdminUserInvite.create),
  delete: transformApi(
    reverseParams(api.organizationGroupAdminUserInvite.delete)
  ),
}
