import { useState, useRef, forwardRef, useEffect } from 'react'
import { useResizeObserver } from 'modules/core/hooks'
import { Stage, Layer } from 'react-konva'
import { Transformer, Shape } from 'modules/annotator/components'
import { shapeHandlers } from 'modules/annotator/shapes'
import { generateSelfRect } from 'modules/annotator/utils'
import {
  MouseState,
  SELECTION_AREA_NAME,
  SELECTION_GROUP_NAME,
  SELECTION_TRANSFORMER_NAME,
} from 'modules/annotator/constants'
import {
  AnnotatorProps,
  IKonvaStage,
  IKonvaShape,
  IKonvaMouseEvent,
  IKonvaWheelEvent,
} from 'modules/annotator/types'
import { TelestrationShapeType } from 'modules/telestration/types'

const BaseAnnotator = forwardRef<IKonvaStage, AnnotatorProps>(
  (
    {
      telestrationTool,
      telestrationOptions,
      onShapeDrawn,
      onShapeSelected,
      onShapeAction,
      color,
      children,
      height,
      width,
      top,
      left,
      allowShapeRotate,
      allowShapeResize,
    },
    ref
  ) => {
    const canvasContainerRef = useRef<HTMLDivElement>(null)
    const { width: canvasWidth, height: canvasHeight } =
      useResizeObserver(canvasContainerRef)

    const [mouseState, setMouseState] = useState<MouseState>(MouseState.UP)
    const [selectedShape, setSelectedShape] = useState<IKonvaShape>()
    const [selectionAreaShape, setSelectionAreaShape] = useState<IKonvaShape>()
    const [editingShape, setEditingShape] = useState<string | null>(null)

    const [telestrationPreview, setTelestrationPreview] =
      useState<TelestrationShapeType>()

    useEffect(() => {
      if (!selectedShape) return

      generateSelfRect(selectedShape)
    }, [selectedShape])

    const handleShapeSelect = ({ target }: IKonvaMouseEvent) => {
      if (target instanceof IKonvaShape) {
        // If we click on konva shape that acts as transformer we need to ignore it
        if (target?.name().includes(SELECTION_TRANSFORMER_NAME)) return
        // To check if shape has selection area we need to check if it has a parent with selection group name
        // If our konva shape in this group we need set selectedShape and selectionAreaShape.
        // so we will move both shapes together
        if (
          target?.parent?.id().includes(SELECTION_GROUP_NAME) &&
          target.parent?.children?.[1]
        ) {
          onShapeSelected && onShapeSelected(target.parent.children[0].id())
          setSelectionAreaShape(target.parent.children[1] as IKonvaShape)
          target.parent?.children?.[1].setDraggable(true)
          return setSelectedShape(target.parent.children[0] as IKonvaShape)
        }

        setSelectionAreaShape(undefined)
        onShapeSelected && onShapeSelected(target.id())
        return setSelectedShape(target)
      }

      setSelectedShape(undefined)
      setSelectionAreaShape(undefined)
      setEditingShape(null)
      onShapeSelected && onShapeSelected(null)
      onShapeAction && onShapeAction(null)
    }

    const handleMouseUp = () => {
      setMouseState(MouseState.UP)
      if (!telestrationPreview) return

      onShapeDrawn && onShapeDrawn(telestrationPreview)
      setTelestrationPreview(undefined)
    }

    const handleDoubleClick = ({ target }: IKonvaMouseEvent) => {
      if (!(target instanceof IKonvaShape)) return

      onShapeAction && onShapeAction(target.id())
      setEditingShape(target.id())
      setSelectedShape(undefined)
      setSelectionAreaShape(undefined)
    }

    const handleMouseDown = (e: IKonvaMouseEvent) => {
      setMouseState(MouseState.DOWN)

      // With our current approach we have an issue that selection area for the element can be dragged independently,
      // so we need to disable dragging for selection area.
      if (
        e.target.id().includes(SELECTION_AREA_NAME) &&
        selectedShape?.id() &&
        !e.target.id().includes(selectedShape.id())
      ) {
        e.target.setDraggable(false)
        return
      }
      if (selectedShape) return
      if (editingShape) return
      if (!telestrationTool) return
      if (e.target instanceof IKonvaShape && !telestrationPreview) return
      if (telestrationPreview) {
        const handler = shapeHandlers[telestrationTool]
        const createdShape = handler.onShaping(telestrationPreview, e)

        setTelestrationPreview(
          createdShape && { ...createdShape, stroke: color }
        )
        return
      }

      const handler = shapeHandlers[telestrationTool]
      const createdShape = handler.onCreate(e, {
        ...telestrationOptions,
        telestrationColor: color,
      })

      setTelestrationPreview(createdShape && { ...createdShape, stroke: color })
    }

    const handleMouseMove = (e: IKonvaMouseEvent) => {
      if (mouseState === MouseState.UP) return
      if (e.target instanceof IKonvaShape) return
      if (selectedShape) return
      if (!telestrationPreview) return
      if (!telestrationTool) return

      const handler = shapeHandlers[telestrationTool]
      const paintingShape = handler.onShaping(telestrationPreview, e)

      setTelestrationPreview(paintingShape)
    }

    const handleMouseWheel = ({ evt }: IKonvaWheelEvent) => evt.preventDefault()

    return (
      <div
        ref={canvasContainerRef}
        style={{
          position: 'absolute',
          width: width ?? '100%',
          height: height ?? '100%',
          top: top,
          left: left,
        }}
      >
        <Stage
          ref={ref}
          width={canvasWidth}
          height={canvasHeight}
          onTap={handleShapeSelect}
          onClick={handleShapeSelect}
          onDblClick={handleDoubleClick}
          onWheel={handleMouseWheel}
          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        >
          <Layer>
            <Transformer
              shape={selectedShape}
              selectionShape={selectionAreaShape}
              allowShapeRotate={allowShapeRotate}
              allowShapeResize={allowShapeResize}
            />
            {telestrationPreview &&
              Shape({ ...telestrationPreview, preview: true })}
            {children}
          </Layer>
        </Stage>
      </div>
    )
  }
)

export { BaseAnnotator }
