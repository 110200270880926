export const FOOTBALL_EVENTS = {
  GOAL: 1,
  YELLOW_CARD: 2,
  RED_CARD: 3,
  SUBSITUTE: 4,
  CORNER_BALL: 5,
  OFFSIDE: 6,
  FREE_KICK: 7,
  PENALTY: 8,
  FOUL: 9,
  SHOOT: 10,
  SAVE: 11,
  DIRECT_KICK: 12,
  INDIRECT_KICK: 13,
  ATT_THROW: 14,
  COUNTER_OFF: 15,
  CROSS: 16,
  START_OF_HALF: 17,
  END_OF_HALF: 18,
  KICK_OFF: 19,
  TRAINING_START: 20,
  TRAINING_END: 21,
  GOAL_KICK: 22,
} as const
