import { FC, ReactNode, useRef } from 'react'
import { Responsive } from 'react-grid-layout'
import _ from 'lodash'
// layout
import { LAYOUT_COLUMNS } from 'modules/layout/constants'
// hooks
import {
  useReduxDispatch,
  useReduxSelector,
  useResizeObserver,
} from 'modules/core/hooks'
import { useUserStorage, useUserStorageUpdate } from 'modules/user/hooks'
// redux
import {
  selectCurrentLayout,
  selectLayoutState,
  setBreakpoint,
  setLayoutsPositionStack,
  setLayoutsWithBreakpoints,
  addLayoutTypes,
  updateLayout,
  selectLayoutKeys,
} from 'modules/layout/redux/'
import { selectDropState } from 'modules/analyser/redux'
// types
import {
  updateUserTabsData,
  updateUserTabsDataWithParsingLayoutItem,
} from 'modules/user/utils'
import { convertToLayoutWithBreakpoints } from 'modules/layout/utils/converters'
// types
import { LayoutConfigBreakpoints } from 'modules/layout/types'
// constants
import { numberOfRowsInGridLayout } from 'modules/analyser/constants'

interface LayoutProps {
  children: ReactNode
}

export const GridComponentsLayout: FC<LayoutProps> = ({ children }) => {
  const dispatch = useReduxDispatch()
  const { dropConfig, dropItem, insideDragging } =
    useReduxSelector(selectDropState)
  const { layoutsWithBreakpoints, breakpoint, activeTab } =
    useReduxSelector(selectLayoutState)
  const layouts = useReduxSelector(selectCurrentLayout)
  const { tabsDataKey, layoutTypesKey } = useReduxSelector(selectLayoutKeys)

  const userTabsData = useUserStorage({ keys: [tabsDataKey] })
  const userLayoutTypes = useUserStorage({ keys: [layoutTypesKey] })
  const userStorageUpdate = useUserStorageUpdate()

  const layoutRef = useRef<HTMLDivElement>(null)
  const { width, height } = useResizeObserver(layoutRef)

  return (
    <Responsive
      isBounded
      allowOverlap
      innerRef={layoutRef}
      width={width}
      rowHeight={height / numberOfRowsInGridLayout}
      maxRows={numberOfRowsInGridLayout}
      margin={[0, 0]}
      cols={LAYOUT_COLUMNS}
      layouts={layoutsWithBreakpoints}
      isDroppable={insideDragging}
      droppingItem={dropItem}
      resizeHandles={['s', 'w', 'e', 'sw', 'se']}
      draggableHandle={'.draggable-handle'}
      style={{
        height: '100%',
        transition: 'all 0.3s',
        padding: '5px 5px',
        overflow: 'hidden',
      }}
      onDrop={(layout, layoutItem, _event) => {
        const dropItemData = { ...layoutItem, ...dropConfig }
        const updateLayout = [
          ...layouts.filter(item => item.i !== layoutItem.i),
          dropItemData,
        ]

        const updatedLayoutsWithBreakpoints =
          updateUserTabsDataWithParsingLayoutItem(
            userTabsData.data?.[tabsDataKey],
            activeTab,
            breakpoint,
            updateLayout,
            layoutItem.i,
            dropItem.type,
            dropItemData
          )

        userStorageUpdate.mutate({
          [tabsDataKey]: updatedLayoutsWithBreakpoints,
          [layoutTypesKey]: {
            ...userLayoutTypes.data?.[layoutTypesKey],
            [layoutItem.i]: dropItem.type,
          },
        })

        dispatch(
          setLayoutsWithBreakpoints(
            convertToLayoutWithBreakpoints(
              layoutsWithBreakpoints,
              layoutItem.i,
              dropItem.type,
              breakpoint,
              updateLayout
            )
          )
        )

        dispatch(addLayoutTypes({ id: layoutItem.i, type: dropItem.type }))
        dispatch(setLayoutsPositionStack(layoutItem.i))
      }}
      onDragStart={(currentLayout, oldItem, newItem) => {
        if (_.findIndex(layouts, { i: newItem.i }) !== -1) {
          dispatch(setLayoutsPositionStack(newItem.i))
        }
      }}
      onBreakpointChange={(newBreakpoint: LayoutConfigBreakpoints) => {
        dispatch(setBreakpoint(newBreakpoint))
      }}
      onDragStop={currentLayout => {
        if (_.isEqual(currentLayout, layouts)) return

        userStorageUpdate.mutate({
          [tabsDataKey]: updateUserTabsData(
            userTabsData.data?.[tabsDataKey],
            activeTab,
            breakpoint,
            currentLayout
          ),
        })
        dispatch(updateLayout(currentLayout))
      }}
      onResizeStart={(currentLayout, oldItem, newItem) => {
        if (_.findIndex(layouts, { i: newItem.i }) !== -1) {
          dispatch(setLayoutsPositionStack(newItem.i))
        }
      }}
      onResizeStop={currentLayout => {
        if (_.isEqual(currentLayout, layouts)) return

        userStorageUpdate.mutate({
          [tabsDataKey]: updateUserTabsData(
            userTabsData.data?.[tabsDataKey],
            activeTab,
            breakpoint,
            currentLayout
          ),
        })
        dispatch(updateLayout(currentLayout))
      }}
    >
      {children}
    </Responsive>
  )
}
