import {
  styled,
  Paper,
  Typography,
  List as BaseList,
  ListItem as BaseListItem,
  Button as BaseButton,
} from '@mui/material'

const CONTAINER_XY_PADDING = 7

export const Container = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: ${({ theme }) => theme.spacing(4, CONTAINER_XY_PADDING)};
  min-width: 350px;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.palette.primary.main};
`

export const Header = styled('div')`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(3)};
`

export const TitleContainer = styled('div')`
  margin: ${({ theme }) => theme.spacing(0, 0, 2, 0)};
`

export const Title = styled(Typography)`
  font-size: 30px;
  font-weight: 500;
`

export const List = styled(BaseList)`
  margin: ${({ theme }) => theme.spacing(1.6, 0, 'auto', 0)};
  padding-bottom: ${({ theme }) => theme.spacing(2.5)};
`

export const ListItem = styled(BaseListItem)`
  padding: ${({ theme }) => theme.spacing(1, 0)};
  color: ${({ theme }) => theme.palette.primary.contrastText};
`

export const ListItemIcon = styled('span')`
  padding-right: ${({ theme }) => theme.spacing(1.5)};
  user-select: none;
`

export const PricingContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(2.5)};
  border-top: 1px solid ${({ theme }) => theme.palette.divider};
  width: calc(100% + ${({ theme }) => theme.spacing(CONTAINER_XY_PADDING * 2)});
  margin-left: ${({ theme }) => theme.spacing(CONTAINER_XY_PADDING * -1)};
`

export const PricingInfoContainer = styled('div')`
  display: flex;
  align-items: center;
`

export const Price = styled(Typography)`
  font-size: 35px;
  font-weight: 500;
`

export const PricePeriod = styled(Typography)`
  font-size: 18px;
  margin-left: 4px;
  color: ${({ theme }) => theme.palette.primary.light};
`

export const Button = styled(BaseButton)`
  color: ${({ theme }) => theme.palette.secondary.contrastText};
  background-color: ${({ theme }) => theme.palette.primary.main};
  margin-top: ${({ theme }) => theme.spacing(3)};
  padding: ${({ theme }) => theme.spacing(1.25, 4)};

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`

export const IconWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  & > img {
    width: 70px;
    height: 70px;
  }
`
