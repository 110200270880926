import { PANORAMIC_SIZE } from '../constants'
import { Size, Point } from '../types'

export const getMidpoint = (point: Point, area: Size): Point => ({
  x: point.x + area.width / 2,
  y: point.y + area.height / 2,
})

export const getTopLeft = (point: Point, area: Size): Point => ({
  x: Math.max(point.x - area.width / 2, 0),
  y: Math.max(point.y - area.height / 2, 0),
})

export const transformToRelative = (
  point: Point,
  parentContainer: Size
): Point => ({
  x: point.x / parentContainer.width,
  y: point.y / parentContainer.height,
})

export const transformToAbsolute = (
  point: Point,
  targetContainer: Size
): Point => ({
  x: point.x * targetContainer.width,
  y: point.y * targetContainer.height,
})

export const getCameraTarget = (
  viewMidpoint: Point,
  parentContainer: Size
): Point => {
  const normalizedMidpoint = transformToRelative(viewMidpoint, parentContainer)
  return transformToAbsolute(normalizedMidpoint, PANORAMIC_SIZE)
}

export const getViewTopLeft = (
  cameraTarget: Point,
  viewSize: Size,
  parentContainer: Size
): Point => {
  const normalizedMidpoint = transformToRelative(cameraTarget, PANORAMIC_SIZE)
  const viewMidpoint = transformToAbsolute(normalizedMidpoint, parentContainer)

  return getTopLeft(viewMidpoint, viewSize)
}
