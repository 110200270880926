import { FC } from 'react'
// Layout
import {
  LayoutIndexPaletteItem,
  componentItemColorsWithDefault,
} from 'modules/layout/constants'
// Hooks
import { useReduxSelector } from 'modules/core/hooks'
// redux
import { selectComponentNumberInLayout } from 'modules/layout/redux'
// Components
import {
  LayoutItemTitle,
  LayoutItemToolbar,
  ToolbarIcon,
} from './ToolbarHeader.styled'
// Constants
import { defaultIconName } from 'modules/core/constants'

import { ToolbarHeaderProps } from './ToolbarHeader.interface'

export const ToolbarHeader: FC<ToolbarHeaderProps> = ({
  layoutId,
  layoutIndex,
  children,
  paletteItems = LayoutIndexPaletteItem,
}) => {
  const componentNumberInLayout = useReduxSelector(state =>
    selectComponentNumberInLayout(state, layoutId, layoutIndex)
  )
  const layoutItem = layoutIndex ? paletteItems[layoutIndex] : null

  return (
    <LayoutItemToolbar>
      <ToolbarIcon
        color={componentItemColorsWithDefault[componentNumberInLayout + 1]}
        name={layoutItem?.icon ?? defaultIconName}
      />
      <LayoutItemTitle>{layoutItem?.name}</LayoutItemTitle>
      {children}
    </LayoutItemToolbar>
  )
}
