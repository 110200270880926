import { useMutation } from 'react-query'
import { ApiService } from 'services/Api.service'
import { RequestDemoContactBody } from '@sporttotal-tv/dip-coaching-client'
import { MutateContext } from 'modules/core/types/mutate-context.type'

export const REQUEST_DEMO_CACHE_KEY = 'request-demo-create'

export const useRequestDemo = () =>
  useMutation<any, Error, RequestDemoContactBody, MutateContext<any>>(
    REQUEST_DEMO_CACHE_KEY,
    (params: RequestDemoContactBody) => ApiService.contact.requestDemo(params)
  )
