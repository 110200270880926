import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {
  ShapeOptions,
  TelestrationTool,
  ZoomValue,
} from 'modules/telestration/types'

export interface TelestrationItem {
  id: string
  tool: TelestrationTool | null
  activeGroup?: string
  activeShape?: string | null
  shapeOptions?: ShapeOptions
  zoom: ZoomValue
  color: string
}

export type TelestrationUpdate<T> = {
  id: string
} & T

export const telestrationAdapter = createEntityAdapter<TelestrationItem>()

const initialState = telestrationAdapter.getInitialState()

export const telestrationSlice = createSlice({
  name: 'telestration',
  initialState,
  reducers: {
    setTelestration: (state, action: PayloadAction<TelestrationItem>) => {
      const existingItem = state.entities[action.payload.id]
      if (!existingItem) {
        telestrationAdapter.addOne(state, action.payload)
      }
    },
    removeTelestration: (state, action: PayloadAction<{ id: string }>) => {
      telestrationAdapter.removeOne(state, action.payload.id)
    },
    setTool: (
      state,
      action: PayloadAction<
        TelestrationUpdate<{
          tool: TelestrationTool | null
          toolOptions?: ShapeOptions
        }>
      >
    ) => {
      const existingItem = state.entities[action.payload.id]
      if (existingItem) {
        existingItem.tool = action.payload.tool
        existingItem.shapeOptions =
          action.payload.tool !== null ? action.payload.toolOptions : undefined
      }
    },
    setActiveGroup: (
      state,
      action: PayloadAction<TelestrationUpdate<{ group: string | undefined }>>
    ) => {
      const existingItem = state.entities[action.payload.id]
      if (existingItem) {
        existingItem.activeGroup = action.payload.group
      }
    },
    setZoom: (
      state,
      action: PayloadAction<TelestrationUpdate<{ zoom: ZoomValue }>>
    ) => {
      const existingItem = state.entities[action.payload.id]
      if (existingItem) {
        existingItem.zoom = action.payload.zoom
      }
    },
    setActiveShape: (
      state,
      action: PayloadAction<TelestrationUpdate<{ shape: string | null }>>
    ) => {
      const existingItem = state.entities[action.payload.id]
      if (existingItem) {
        existingItem.activeShape = action.payload.shape
      }
    },
    setColor: (
      state,
      action: PayloadAction<TelestrationUpdate<{ color: string }>>
    ) => {
      const existingItem = state.entities[action.payload.id]
      if (existingItem) {
        existingItem.color = action.payload.color
      }
    },
  },
})

export const {
  setTool,
  setActiveShape,
  setActiveGroup,
  setZoom,
  setColor,
  removeTelestration,
  setTelestration,
} = telestrationSlice.actions

export const telestrationReducer = telestrationSlice.reducer
