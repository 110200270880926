import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import { UserCacheData } from 'modules/user/types'
import { MutateContext } from 'modules/core/types/mutate-context.type'
import { USER_STORAGE_RETRIEVE_CACHE_KEY } from 'modules/user/hooks'

export const USER_STORAGE_UPDATE_CACHE_KEY = 'user-storage-update'

export const useUserStorageUpdate = () => {
  const queryClient = useQueryClient()

  return useMutation<
    UserCacheData,
    Error,
    UserCacheData,
    MutateContext<UserCacheData>
  >(
    USER_STORAGE_UPDATE_CACHE_KEY,
    (params: UserCacheData) => ApiService.user.updateStorage(params),
    {
      onMutate: async data => {
        const keys = Object.keys(data)
        await queryClient.cancelQueries([
          USER_STORAGE_RETRIEVE_CACHE_KEY,
          { keys },
        ])
        for (const key of keys) {
          await queryClient.cancelQueries([
            USER_STORAGE_RETRIEVE_CACHE_KEY,
            { keys: [key] },
          ])
        }

        const previousData = queryClient.getQueriesData<UserCacheData>(
          USER_STORAGE_RETRIEVE_CACHE_KEY
        )

        if (previousData) {
          queryClient.setQueriesData<UserCacheData | undefined>(
            USER_STORAGE_RETRIEVE_CACHE_KEY,
            oldData => oldData && { ...oldData, ...data }
          )
        }

        return { previousData }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData<UserCacheData>(prevData[0], prevData[1])
          )
        }
      },
      onSettled: (_, __, variables) => {
        const keys = Object.keys(variables)
        queryClient.invalidateQueries([
          USER_STORAGE_RETRIEVE_CACHE_KEY,
          { keys },
        ])
        for (const key of keys) {
          queryClient.invalidateQueries([
            USER_STORAGE_RETRIEVE_CACHE_KEY,
            { keys: [key] },
          ])
        }
      },
    }
  )
}
