import {
  useQuery,
  useQueryClient,
  QueryClient,
  UseQueryResult,
} from 'react-query'
import { useQueryRetry } from 'modules/core/hooks'
import { uniqueId } from 'lodash'
import { UseQueryOptions } from 'modules/core/types'

export const createFetchHook = <
  Func extends (...args: any) => any,
  Response = Awaited<ReturnType<Func>>,
  Params = Parameters<Func>[0]
>(
  fetchFunc: Func,
  createOptions?:
    | UseQueryOptions<Response, (string | Params | undefined)[]>
    | ((
        queryClient: QueryClient,
        params?: Params
      ) => UseQueryOptions<Response, (string | Params | undefined)[]>)
) => {
  const cacheKey = uniqueId()

  const useHook = (
    params?: Params,
    additionalQueryOptions?: UseQueryOptions<
      Response,
      (string | Params | undefined)[]
    >
  ) => {
    const queryClient = useQueryClient()
    const handleQueryRetry = useQueryRetry()

    const queryOptions =
      typeof createOptions === 'function'
        ? createOptions(queryClient, params)
        : createOptions ?? {}

    return useQuery([cacheKey, params], () => fetchFunc(params), {
      staleTime: Infinity,
      ...queryOptions,
      ...additionalQueryOptions,
      retry: handleQueryRetry,
    }) as UseQueryResult<Response, any>
  }

  return [useHook, cacheKey] as [typeof useHook, string]
}
