// Hooks
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSubscription } from 'modules/subscription/hooks'
// Components
import { LoaderContainer } from 'modules/core/components'
import {
  Main,
  StatusIcon,
  StatusText,
  CoachingButton,
} from './PaymentStatus.styled'
import { IconName } from 'modules/core/components/Icon/Icon.interface'
// Constants
import { routeNames } from 'modules/core/constants'

export const PaymentStatus = () => {
  const navigate = useNavigate()

  const [isLoaded, setIsLoaded] = useState(false)
  const [refetchInterval, setRefetchInterval] = useState<number | false>(3500)

  const { search } = useLocation()
  const { data: subscription } = useSubscription({ refetchInterval })
  const { t } = useTranslation('pages', { keyPrefix: 'paymentStatus' })

  const params = useMemo(() => new URLSearchParams(search), [search])
  const paymentIntent = params.get('payment_intent')

  const status = params.get('redirect_status')
  const paymentSucceeded = status === 'succeeded'

  const icon: IconName = paymentSucceeded ? 'check-circle' : 'error'
  const statusText = paymentSucceeded
    ? t('payment.success')
    : t('payment.failed')

  useEffect(() => {
    if (isLoaded || !paymentSucceeded) return

    if (subscription?.status === 'SUCCESSFUL') {
      setIsLoaded(true)
      setRefetchInterval(false)
    }
  }, [subscription, paymentSucceeded, isLoaded])

  useEffect(() => {
    if (!paymentIntent) navigate(routeNames.analysis.path)
  }, [navigate, params, paymentIntent])

  return (
    <Main>
      <LoaderContainer isLoading={!isLoaded}>
        <StatusText>{statusText}</StatusText>
        <StatusIcon name={icon} />
        <CoachingButton onClick={() => navigate('/')}>
          {t('button.start')}
        </CoachingButton>
      </LoaderContainer>
    </Main>
  )
}
