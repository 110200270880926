import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import { SUBSCRIPTION_CREATE_CACHE_KEY } from './useSubscriptonCreate'

export const SUBSCRIPTION_DELETE_CACHE_KEY = 'subscription-delete'

export const useSubscriptionDelete = () => {
  const queryClient = useQueryClient()

  return useMutation(
    SUBSCRIPTION_DELETE_CACHE_KEY,
    () => ApiService.subscription.delete(),
    {
      onSettled: () => {
        queryClient.invalidateQueries(SUBSCRIPTION_CREATE_CACHE_KEY, {
          exact: false,
        })
      },
    }
  )
}
