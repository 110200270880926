import * as yup from 'yup'

export const teamPlayerStatsSchema = yup.object().shape({
  goals: yup
    .number()
    .typeError('components:teamPlayers.modal.stats.error.goals.required')
    .required('components:teamPlayers.modal.stats.error.goals.required')
    .min(0, 'components:teamPlayers.modal.stats.error.goals.invalid'),
  shots: yup
    .number()
    .typeError('components:teamPlayers.modal.stats.error.shots.required')
    .required('components:teamPlayers.modal.stats.error.shots.required')
    .min(0, 'components:teamPlayers.modal.stats.error.shots.invalid'),
  shotsOnTarget: yup
    .number()
    .typeError(
      'components:teamPlayers.modal.stats.error.shotsOnTarget.required'
    )
    .required('components:teamPlayers.modal.stats.error.shotsOnTarget.required')
    .min(0, 'components:teamPlayers.modal.stats.error.shotsOnTarget.invalid'),
  penaltiesScored: yup
    .number()
    .typeError(
      'components:teamPlayers.modal.stats.error.penaltiesScored.required'
    )
    .required(
      'components:teamPlayers.modal.stats.error.penaltiesScored.required'
    )
    .min(0, 'components:teamPlayers.modal.stats.error.penaltiesScored.invalid'),
  penaltiesTaken: yup
    .number()
    .typeError(
      'components:teamPlayers.modal.stats.error.penaltiesTaken.required'
    )
    .required(
      'components:teamPlayers.modal.stats.error.penaltiesTaken.required'
    )
    .min(0, 'components:teamPlayers.modal.stats.error.penaltiesTaken.invalid'),
  passesCompleted: yup
    .number()
    .typeError(
      'components:teamPlayers.modal.stats.error.passesCompleted.required'
    )
    .required(
      'components:teamPlayers.modal.stats.error.passesCompleted.required'
    )
    .min(0, 'components:teamPlayers.modal.stats.error.passesCompleted.invalid'),
  passesAttempt: yup
    .number()
    .typeError(
      'components:teamPlayers.modal.stats.error.passesAttempt.required'
    )
    .required('components:teamPlayers.modal.stats.error.passesAttempt.required')
    .min(0, 'components:teamPlayers.modal.stats.error.passesAttempt.invalid'),
  mistakes: yup
    .number()
    .typeError('components:teamPlayers.modal.stats.error.mistakes.required')
    .required('components:teamPlayers.modal.stats.error.mistakes.required')
    .min(0, 'components:teamPlayers.modal.stats.error.mistakes.invalid'),
  crossAttempted: yup
    .number()
    .typeError(
      'components:teamPlayers.modal.stats.error.crossAttempted.required'
    )
    .required(
      'components:teamPlayers.modal.stats.error.crossAttempted.required'
    )
    .min(0, 'components:teamPlayers.modal.stats.error.crossAttempted.invalid'),
  crossCompleted: yup
    .number()
    .typeError(
      'components:teamPlayers.modal.stats.error.crossCompleted.required'
    )
    .required(
      'components:teamPlayers.modal.stats.error.crossCompleted.required'
    )
    .min(0, 'components:teamPlayers.modal.stats.error.crossCompleted.invalid'),
  redCard: yup
    .number()
    .typeError('components:teamPlayers.modal.stats.error.redCard.required')
    .required('components:teamPlayers.modal.stats.error.redCard.required')
    .min(0, 'components:teamPlayers.modal.stats.error.redCard.invalid'),
  yellowCard: yup
    .number()
    .typeError('components:teamPlayers.modal.stats.error.yellowCard.required')
    .required('components:teamPlayers.modal.stats.error.yellowCard.required')
    .min(0, 'components:teamPlayers.modal.stats.error.yellowCard.invalid'),
})
