import { Asset } from '@sporttotal-tv/dip-coaching-client'
import _ from 'lodash'

type Collection = {
  total: number
  results: Array<{ assets?: Array<Asset> }>
}

export const removeAssetFromCollection = <R extends Collection>(
  page: R | undefined,
  assetId: string
): undefined | { total: number; results: any } =>
  page && {
    total: page.total,
    results: page.results.map(collectionData => ({
      ...collectionData,
      assets: _.filter(collectionData.assets, asset => asset.id !== assetId),
    })),
  }
