import { PlayerTransfer } from '@sporttotal-tv/dip-coaching-client'
import { teamPlayerMockup } from './teamPlayer.mockup'

export const teamPlayerTransferMockup: PlayerTransfer = {
  id: '1',
  agent_id: 'agent_id',
  agent: {
    id: 'agent_id',
    firstname: 'firstname',
    lastname: 'lastname',
    email: 'email',
    avatar: 'avatar',
    created_at: new Date().getTime() / 1000,
    updated_at: new Date().getTime() / 1000,
  },
  option: 2,
  transfer_status: null,
  loan: 200,
  loan_currency_id: 'loan_currency_id',
  loan_currency: {
    id: 'loan_currency_id',
    name: {
      en: 'Dollar',
      de: 'Dollar',
    },
    currency_code: 'USD',
    currency_symbol: '$',
    created_at: new Date().getTime() / 1000,
    updated_at: new Date().getTime() / 1000,
  },
  is_loan_available: true,
  asking_price: 100,
  asking_price_currency_id: 'asking_price_currency_id',
  asking_price_currency: {
    id: 'asking_price_currency_id',
    name: {
      en: 'Dollar',
      de: 'Dollar',
    },
    currency_code: 'USD',
    currency_symbol: '$',
    created_at: new Date().getTime() / 1000,
    updated_at: new Date().getTime() / 1000,
  },
  transfer_value: 100,
  transfer_value_min: 100,
  transfer_value_max: 300,
  transfer_value_currency_id: 'transfer_value_currency_id',
  transfer_value_currency: {
    id: 'transfer_value_currency_id',
    name: {
      en: 'Dollar',
      de: 'Dollar',
    },
    currency_code: 'USD',
    currency_symbol: '$',
    created_at: new Date().getTime() / 1000,
    updated_at: new Date().getTime() / 1000,
  },
  player_id: 'player_id',
  player: teamPlayerMockup,
  created_at: new Date().getTime() / 1000,
  updated_at: new Date().getTime() / 1000,
}
