import { forwardRef, useCallback, useContext, useRef } from 'react'
import { Frame } from '@sporttotal-tv/dip-coaching-client'
// components
import { Icon } from 'modules/core/components'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
// constants
import { PX_PER_SECOND } from 'modules/telestration/constants'
// context
import { VideoPlayerContext } from 'modules/video-player/context'
// redux
import { setMediaItem } from 'modules/video-player/redux'
// hooks
import { useReduxDispatch, useResizeObserver } from 'modules/core/hooks'

import {
  AmountOfFrames,
  StyledFixedSizeList,
  TelestrationFrameButton,
  Timeline as StyledTimeline,
  TimelineNotch,
  TimelineNotchContainer,
} from './Timeline.styled'
import { TimelineProps } from './Timeline.interface'

const Timeline = forwardRef<HTMLDivElement, TimelineProps>(
  (
    {
      duration,
      notchInterval,
      notchDuration,
      offset,
      rightOffset,
      zoom,
      frames,
    },
    ref
  ) => {
    const durationWithZoom = notchDuration * zoom
    const notchCount = Math.ceil(duration / durationWithZoom)
    const wrapperRef = useRef<HTMLDivElement>(null)
    const { width, height } = useResizeObserver(wrapperRef)
    const { playerId } = useContext(VideoPlayerContext)

    const dispatch = useReduxDispatch()

    const handleFrameClick = useCallback(
      (frame: Frame) => () => {
        if (frame && frame.media_locator) {
          dispatch(
            setMediaItem({
              mediaLocator: frame.media_locator,
              activeVideoItemId: frame.id,
              activeVideoItemType: 'frame',
              id: playerId ?? '',
            })
          )
        }
      },
      [dispatch, playerId]
    )

    // react window will hide some items as we have left offset, so we need to add items that won't show to display it right
    const additionalItems = Math.floor(offset / (notchDuration * PX_PER_SECOND))

    return (
      <StyledTimeline ref={wrapperRef}>
        <StyledFixedSizeList
          offset={offset - additionalItems * (notchDuration * PX_PER_SECOND)}
          outerRef={ref}
          overscanCount={3}
          rightOffset={rightOffset}
          direction={'horizontal'}
          itemCount={notchCount + additionalItems}
          itemSize={notchDuration * PX_PER_SECOND}
          height={height}
          width={width}
        >
          {({ index, style }) => {
            const timestamp = (index - additionalItems) * durationWithZoom

            const allFrames =
              frames.filter(
                frame =>
                  frame.start_time / 1000 >= timestamp &&
                  frame.start_time / 1000 < timestamp + durationWithZoom
              ) || []

            const currentFrame = allFrames[0]

            return (
              <TimelineNotch
                style={{
                  ...style,
                }}
                hidden={index < additionalItems}
                key={index}
                timestamp={timestamp}
                showTimestamp={(index - additionalItems) % notchInterval === 0}
              >
                {currentFrame && (
                  <TimelineNotchContainer
                    offset={
                      ((currentFrame.start_time / 1000 - timestamp) * 100) /
                      durationWithZoom
                    }
                  >
                    <TelestrationFrameButton
                      disabled={currentFrame.media_locator === null}
                      onClick={handleFrameClick(currentFrame)}
                      size='small'
                    >
                      {currentFrame.is_presentation ? (
                        <OndemandVideoIcon style={{ width: 10 }} />
                      ) : (
                        <Icon name='camera' />
                      )}
                    </TelestrationFrameButton>
                    {allFrames.length > 1 && (
                      <AmountOfFrames>{allFrames.length}+</AmountOfFrames>
                    )}
                  </TimelineNotchContainer>
                )}
              </TimelineNotch>
            )
          }}
        </StyledFixedSizeList>
      </StyledTimeline>
    )
  }
)

export { Timeline }
