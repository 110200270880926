import { SyntheticEvent, useEffect, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Button } from 'modules/landing/components/shared/Button/Button'
import { BUTTON_VARIANTS } from 'modules/landing/components/shared/Button/utils'
import { StyledTriangleShape, StyledVideo, StyledWrapper } from '.'
import { useVideoPlayerControls } from 'modules/video-player/hooks'
import { VIDEO_SRC } from 'modules/landing/constants'

export const VideoSection = () => {
  const videoRef = useRef<HTMLVideoElement>(null)

  const { pause, play, status } = useVideoPlayerControls(videoRef.current)

  useEffect(() => {
    pause()
  }, [])

  const onVideoClick = (event: SyntheticEvent<HTMLVideoElement>) => {
    if (status !== 'play') {
      event.preventDefault()
    }
  }

  return (
    <StyledWrapper>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, repeat: 0 }}
        >
          <StyledVideo
            isVisible={status === 'play'}
            ref={videoRef}
            controls={true}
            as={motion.video}
            src={VIDEO_SRC}
            onClick={e => onVideoClick(e)}
          />
        </motion.div>
      </AnimatePresence>

      {status !== 'play' && (
        <Button variant={BUTTON_VARIANTS.video} onClick={play}>
          <StyledTriangleShape />
        </Button>
      )}
    </StyledWrapper>
  )
}
