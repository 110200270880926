import {
  Telestration,
  TelestrationCreatePayload,
  TelestrationList,
  TelestrationListParams,
  UpdateTelestrationBody,
} from '@sporttotal-tv/dip-coaching-client'
import {
  transformApiResults,
  transformIdApiResults,
} from 'modules/core/helpers'
import { api } from 'modules/core/api'

export const TelestrationApiService = {
  list: transformApiResults<TelestrationListParams, TelestrationList>(
    api.telestration.list
  ),
  create: transformApiResults<TelestrationCreatePayload, Telestration>(
    api.telestration.create
  ),
  update: transformIdApiResults<UpdateTelestrationBody, Telestration>(
    api.telestration.update
  ),
  delete: transformIdApiResults<void, Telestration>(api.telestration.delete),
}
