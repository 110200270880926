import { TelestrationTool } from 'modules/telestration/types'
import { TransformerConfig } from 'konva/lib/shapes/Transformer'

export const TelestrationShapesTransformParams: Record<
  TelestrationTool,
  Partial<TransformerConfig> & { isCustom?: boolean }
> = {
  LINE: {},
  RECT: {},
  CIRCLE: {},
  PLAYER_SELECTION: {
    rotateEnabled: false,
    enabledAnchors: [
      'top-left',
      'top-right',
      'bottom-left',
      'bottom-right',
    ] as string[],
  },
  CURVED_LINE: {
    isCustom: true,
  },
  MULTI_LINE: {
    isCustom: true,
  },
  HIGHLIGHT: {
    rotateEnabled: false,
    enabledAnchors: [] as string[],
  },
  ARROW: {},
  TEXT: {},
} as const
