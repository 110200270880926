import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { TeamList, TeamListParams } from '@sporttotal-tv/dip-coaching-client'
import { useQueryRetry } from 'modules/core/hooks'
import { UseQueryOptions } from 'modules/core/types'

export const TEAM_LIST_CACHE_KEY = 'team-list'

export const useTeamList = (
  options?: TeamListParams,
  queryOptions?: UseQueryOptions<
    TeamList,
    (string | TeamListParams | undefined)[]
  >
) => {
  const handleQueryRetry = useQueryRetry()

  return useQuery(
    [TEAM_LIST_CACHE_KEY, options],
    () => ApiService.team.list(options),
    {
      ...queryOptions,
      retry: handleQueryRetry,
      staleTime: Infinity,
    }
  )
}
