import { ApiService } from 'services/Api.service'
import { createMutationHook } from 'modules/core/api/hooks/utils'
import { MATCH_RETRIEVE_CACHE_KEY } from 'modules/match/hooks'
import { HIGHLIGHT_LIST_CACHE_KEY } from './useHighlightList'

export const [useHighlightCreate] = createMutationHook(
  ApiService.highlight.create,
  queryClient => ({
    onSettled: () => {
      const opts = {
        exact: false,
      }

      queryClient.invalidateQueries(HIGHLIGHT_LIST_CACHE_KEY, opts)
      queryClient.invalidateQueries(MATCH_RETRIEVE_CACHE_KEY, opts)
    },
  })
)
