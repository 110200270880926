import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface LayoutCacheItem {
  id: string
  [key: string]: any
}

export const layoutCacheAdapter = createEntityAdapter<LayoutCacheItem>()

const initialState = layoutCacheAdapter.getInitialState()

export const layoutCacheSlice = createSlice({
  name: 'layoutCache',
  initialState,
  reducers: {
    setLayoutCache: (state, action: PayloadAction<object>) => {
      const layoutCacheData = Object.entries(action.payload).map(
        ([key, value]) => ({ ...value, id: key })
      )
      layoutCacheAdapter.upsertMany(state, layoutCacheData)
    },
    removeLayoutCache: layoutCacheAdapter.removeOne,
    updateLayoutCache: (state, action: PayloadAction<LayoutCacheItem>) => {
      const existingItem = state.entities[action.payload.id]
      if (!existingItem) {
        layoutCacheAdapter.addOne(state, action.payload)
        return
      }

      const { id, ...rest } = action.payload
      layoutCacheAdapter.updateOne(state, { id: id, changes: rest })
    },
    setDefaultLayoutCacheComponentDataSuccess: (
      state,
      action: PayloadAction<LayoutCacheItem>
    ) => {
      const existingItem = state.entities[action.payload.id]
      if (!existingItem) {
        layoutCacheAdapter.addOne(state, action.payload)
        return
      }
      if (!existingItem.createdAt) {
        const { id, ...rest } = action.payload
        layoutCacheAdapter.updateOne(state, { id: id, changes: rest })
      }
    },
    setDefaultLayoutCacheComponentDataRequest: (
      state,
      action: PayloadAction<LayoutCacheItem>
    ) => {},
  },
})

export const {
  setLayoutCache,
  removeLayoutCache,
  updateLayoutCache,
  setDefaultLayoutCacheComponentDataRequest,
  setDefaultLayoutCacheComponentDataSuccess,
} = layoutCacheSlice.actions

export const layoutCacheReducer = layoutCacheSlice.reducer
