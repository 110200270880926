import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import {
  UpdatePlaylistBody,
  Playlist,
  PlaylistList,
} from '@sporttotal-tv/dip-coaching-client'
import { PLAYLIST_CACHE_KEY } from 'modules/playlist/hooks'
import { MutateContext, InfiniteQuery } from 'modules/core/types'

export const PLAYLIST_UPDATE_CACHE_KEY = 'playlist-update'

export type PlaylistUpdateParams = {
  id: string
  params: UpdatePlaylistBody
}

export const usePlaylistUpdate = () => {
  const queryClient = useQueryClient()

  return useMutation<
    Playlist,
    Error,
    PlaylistUpdateParams,
    MutateContext<InfiniteQuery<PlaylistList>>
  >(
    PLAYLIST_UPDATE_CACHE_KEY,
    ({ id, params }: PlaylistUpdateParams) =>
      ApiService.playlist.update(id, params),
    {
      onMutate: async updatedPlaylist => {
        await queryClient.cancelQueries(PLAYLIST_CACHE_KEY, { exact: false })

        const previousData =
          queryClient.getQueriesData<InfiniteQuery<PlaylistList>>(
            PLAYLIST_CACHE_KEY
          )

        if (previousData) {
          queryClient.setQueriesData<InfiniteQuery<PlaylistList> | undefined>(
            PLAYLIST_CACHE_KEY,
            oldData =>
              oldData && {
                pageParams: oldData.pageParams,
                pages: oldData.pages.map(
                  page =>
                    page && {
                      total: page.total,
                      results: page.results.map(playlist =>
                        playlist.id === updatedPlaylist.id
                          ? { ...playlist, ...updatedPlaylist.params }
                          : playlist
                      ),
                    }
                ),
              }
          )
        }

        return { previousData }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData<InfiniteQuery<PlaylistList>>(
              prevData[0],
              prevData[1]
            )
          )
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(PLAYLIST_CACHE_KEY, { exact: false })
      },
    }
  )
}
