import {
  ProfileContainer,
  Main,
  ProfileEditContainer,
} from './AdminUserProfile.styled'
import { ProfileInfo, ProfileAdminEdit } from 'modules/user/components'
// hooks
import { useAdminUser } from 'modules/user/hooks'
import { useParams } from 'react-router-dom'

export const AdminUserProfile = () => {
  const { id } = useParams()
  const user = useAdminUser(id ?? '', {
    enabled: !!id,
  })
  return (
    <Main>
      <ProfileContainer>
        <ProfileInfo user={user.data} />
      </ProfileContainer>
      <ProfileEditContainer>
        {user.isFetched && <ProfileAdminEdit user={user.data} />}
      </ProfileEditContainer>
    </Main>
  )
}
