import { useEffect, RefObject } from 'react'

export const useOutsideClick = <
  T extends HTMLElement = HTMLElement,
  W extends HTMLElement = HTMLElement
>(
  ref: RefObject<T>,
  handler: (event: Event) => void,
  wrapperRef?: RefObject<W>,
  customElementClass?: string
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const el = ref?.current
      const wrapper = wrapperRef?.current
      if (customElementClass) {
        const rootEl = document.getElementsByClassName(customElementClass)[0]
        if (rootEl && rootEl.contains((event?.target as Node) || null)) {
          return
        }
      }

      if (!el || el.contains((event?.target as Node) || null)) {
        return
      }

      if (wrapper && wrapper.contains((event?.target as Node) || null)) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchend', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchend', listener)
    }
  }, [ref, handler, wrapperRef, customElementClass])
}
