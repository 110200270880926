import { styled } from '@mui/material'

export const StyledImage = styled('img')`
  width: 60px;
  height: 60px;
  margin-bottom: -30px;
  z-index: 1;
`

export const StyledDivider = styled('div')`
  width: 240px;
  height: 1px;
  margin-top: 10px;
  background: ${({ theme }) => theme.palette.lightBlue};
`
