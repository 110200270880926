/* 
    We need to translate scroll distance from the timeline & telestration
    tracks from 'px' to a timestamp from the video. Needed for resizing 
    telestrations and understanding the currentTime on dragging the timeline
*/

type TranslateScrollToTimestampArgs = (
  scrollDistance: number,
  pxPerSecond: number,
  zoomFactor: number
) => number

const translateScrollToTimestamp: TranslateScrollToTimestampArgs = (
  scrollDistance,
  pxPerSecond,
  zoomFactor
) => {
  const timestamp = scrollDistance / pxPerSecond

  return timestamp * zoomFactor
}

export { translateScrollToTimestamp }
