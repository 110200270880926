import { useCallback, useContext, useEffect, useState } from 'react'
import { Match } from '@sporttotal-tv/dip-coaching-client'
// hooks
import { useMatchList } from 'modules/match/hooks'
import {
  useReduxDispatch,
  useDebounce,
  usePageScrolling,
  useReduxSelector,
  useFilter,
} from 'modules/core/hooks'
// components
import { MatchPreview } from 'modules/match/components'
import { FilterModal, LoaderContainer } from 'modules/core/components'
import { AssetToolbar } from 'modules/asset/components'
// context
import { VideoPlayerContext } from 'modules/video-player/context'
// utils
import { convertDateToTimestamp } from 'modules/core/utils'
import { hasAnyMatchLocators } from 'modules/match/utils'
// constants
import { MATCH_PAGE_LIMIT } from 'modules/match/constants'
// styled
import { HorizontalLoader } from 'modules/core/styled'
// redux
import { setNextMatch, setPrevMatch } from 'modules/match/redux'
import { selectMatchId } from 'modules/video-player/redux'
// types
import { AssetFilterOptions } from 'modules/asset/types'

import {
  AssetListWrapper,
  AssetWrapper,
  Root,
  FilterContainer,
} from './AssetList.styled'

export const AssetList = () => {
  const { filterInfo, setFilterData, handleResetFilters } =
    useFilter<AssetFilterOptions>({
      filterOpen: { type: 'boolean', default: false },
      competition: { type: 'toggle', default: undefined },
      startDate: { type: 'input', default: '' },
      endDate: { type: 'input', default: '' },
      tags: { type: 'array', default: [] },
      events: { type: 'set', default: new Set() },
      sportId: { type: 'input', default: undefined },
    })
  const [searchValue, setSearchValue] = useState<string>('')
  // hooks
  const debounceSearchValue = useDebounce(searchValue, 500)
  const { playerId } = useContext(VideoPlayerContext)
  const activeMatchId = useReduxSelector(state =>
    selectMatchId(state, playerId ?? '')
  )
  const reduxDispatch = useReduxDispatch()
  const { containerRef, isBottom, resetIsBottom } = usePageScrolling(100)

  const matchList = useMatchList({
    expand: [
      'assets',
      'teams',
      'assets.event',
      'match_assets',
      'match_assets.media_locator',
      'assets.media_locator',
    ],
    page_limit: MATCH_PAGE_LIMIT,
    search_by: ['title'],
    search: debounceSearchValue,
    // TODO: find the issue
    // competition_id: filterInfo.competition as string | undefined,
    start_date: convertDateToTimestamp(filterInfo.startDate as string),
    end_date: convertDateToTimestamp(filterInfo.endDate as string),
    sport_id: filterInfo.sportId as string | undefined,
  })

  const handleOpenFilter = useCallback(() => {
    setFilterData({
      type: 'filterOpen',
      value: !filterInfo.filterOpen,
    })
  }, [filterInfo.filterOpen, setFilterData])

  // used to prefetch matches until we load currently playing match,
  // so we can find prev and next match this way, primarily used when we reload page.
  useEffect(() => {
    if (!activeMatchId) {
      return
    }

    const allMatches =
      matchList.data?.pages
        .reduce<Match[]>((acc, page) => {
          return [...acc, ...(page.results || [])]
        }, [])
        .filter(
          match => hasAnyMatchLocators(match) || match.id === activeMatchId
        ) || []

    const matchIndex = allMatches.findIndex(({ id }) => id === activeMatchId)

    if (
      matchIndex === -1 &&
      matchList.hasNextPage &&
      !matchList.isFetchingNextPage &&
      !matchList.isLoading
    ) {
      matchList.fetchNextPage()
      return
    }

    if (matchIndex !== -1) {
      const prevMatchData = matchIndex > 0 ? allMatches[matchIndex - 1] : null
      const nextMatchData =
        matchIndex < allMatches.length - 1 ? allMatches[matchIndex + 1] : null

      reduxDispatch(setPrevMatch(prevMatchData))
      reduxDispatch(setNextMatch(nextMatchData))
    }
  }, [
    matchList.data,
    matchList.hasNextPage,
    matchList.isFetchingNextPage,
    matchList.isLoading,
    activeMatchId,
    setPrevMatch,
    setNextMatch,
    reduxDispatch,
  ])

  // used when we scroll down to the bottom of the component,
  // so we can load next page of assets
  useEffect(() => {
    if (
      isBottom &&
      matchList.hasNextPage &&
      !matchList.isFetchingNextPage &&
      !matchList.isLoading
    ) {
      resetIsBottom()
      matchList.fetchNextPage()
    }
  }, [matchList, isBottom, resetIsBottom])

  return (
    <Root>
      <FilterContainer>
        <FilterModal
          open={filterInfo.filterOpen as boolean}
          filterData={filterInfo}
          setFilterData={setFilterData}
          resetFilter={handleResetFilters}
          fields={['events', 'liga', 'tags', 'date']}
        />
      </FilterContainer>
      <AssetToolbar
        handleFilterToggle={handleOpenFilter}
        filterOpen={filterInfo.filterOpen as boolean}
        search={searchValue}
        setSearch={setSearchValue}
      />
      <AssetListWrapper>
        <AssetWrapper ref={containerRef}>
          <LoaderContainer isLoading={matchList.isLoading}>
            {matchList.data?.pages.map(page =>
              page.results.map(match => (
                <MatchPreview
                  key={match.id}
                  match={match}
                  active={activeMatchId === match.id}
                  filter={{
                    tags: filterInfo.tags as string[],
                    events: Array.from(filterInfo.events as Set<string>),
                  }}
                />
              ))
            )}
          </LoaderContainer>
          {matchList.isFetchingNextPage && <HorizontalLoader />}
        </AssetWrapper>
      </AssetListWrapper>
    </Root>
  )
}
