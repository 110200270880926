export const parseSystemRef = (systemRef: string) => {
  const spittedSystemRef = systemRef.split(':')

  if (spittedSystemRef.length !== 5) {
    throw new Error(
      `Invalid System-Ref length, expected 5 and received ${spittedSystemRef.length} with Ref ${systemRef}`
    )
  }

  const [company, service, version, resource, identifier] = spittedSystemRef
  return { company, service, version, resource, identifier }
}
