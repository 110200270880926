import { FC, ReactNode } from 'react'
// Components
import { AppBar } from 'modules/core/components'
import { LoaderContainer } from 'modules/core/components'
// Hooks
import { useUser } from 'modules/user/hooks'

import { Main } from './ProfilePageLayout.styled'

export const ProfilePageLayout: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const user = useUser()

  return (
    <>
      <AppBar
        tabs={[
          { id: 'view', name: 'Profile', path: 'view', static: true },
          { id: 'settings', name: 'Settings', path: 'settings', static: true },
        ]}
      />
      <LoaderContainer isLoading={!user.isFetched} transitionDelay={0.5}>
        <Main>{children}</Main>
      </LoaderContainer>
    </>
  )
}
