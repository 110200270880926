import ReactCalendar from 'libraries/react-calendar'
import { AppBar } from 'modules/core/components'

export const Calendar = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <AppBar tabs={[]} />
      <ReactCalendar />
    </div>
  )
}
