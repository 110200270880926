import * as yup from 'yup'

export const teamPlayerTransferSchema = yup.object().shape({
  agent: yup
    .string()
    .required('components:teamPlayers.modal.transfer.error.agent'),
  option: yup
    .number()
    .typeError('components:teamPlayers.modal.transfer.error.option.required')
    .required('components:teamPlayers.modal.transfer.error.option.required'),
  loan: yup
    .number()
    .typeError('components:teamPlayers.modal.transfer.error.loan.required')
    .required('components:teamPlayers.modal.transfer.error.loan.required')
    .min(0, 'components:teamPlayers.modal.transfer.error.loan.invalid'),
  isLoanAvailable: yup.boolean(),
  askingPrice: yup
    .number()
    .typeError(
      'components:teamPlayers.modal.transfer.error.askingPrice.required'
    )
    .required(
      'components:teamPlayers.modal.transfer.error.askingPrice.required'
    ),
  transferValue: yup
    .number()
    .typeError(
      'components:teamPlayers.modal.transfer.error.transferValue.required'
    )
    .required(
      'components:teamPlayers.modal.transfer.error.transferValue.required'
    )
    .min(
      0,
      'components:teamPlayers.modal.transfer.error.transferValue.invalid'
    ),
  transferValueMin: yup
    .number()
    .typeError(
      'components:teamPlayers.modal.transfer.error.transferValueMin.required'
    )
    .required(
      'components:teamPlayers.modal.transfer.error.transferValueMin.required'
    )
    .min(
      0,
      'components:teamPlayers.modal.transfer.error.transferValueMin.invalid'
    ),
  transferValueMax: yup
    .number()
    .typeError(
      'components:teamPlayers.modal.transfer.error.transferValueMax.required'
    )
    .required(
      'components:teamPlayers.modal.transfer.error.transferValueMax.required'
    )
    .min(
      0,
      'components:teamPlayers.modal.transfer.error.transferValueMax.invalid'
    ),
})
