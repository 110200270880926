import { styled, ButtonGroup, Button } from '@mui/material'

export const Root = styled('div')`
  min-width: 500px;
  padding: ${({ theme }) => theme.spacing(1.25)};
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(1.25)};
`

export const ControlsHeader = styled('header')`
  display: flex;
  justify-content: space-between;
`

export const ToggleSwitchContainer = styled('div')`
  display: flex;
  flex: 1;
  justify: left;
  gap: 10px;
`

export const PanoramicViewport = styled('div')`
  position: relative;
  aspect-ratio: 3 / 1;
  box-shadow: 1px 1px 5px #14161a inset;
`

export const ViewPresetButtonGroup = styled(ButtonGroup)`
  min-width: 100%;
  min-height: 100%;
  position: absolute;
`

export const ViewPresetButton = styled(Button)`
  outline: none;
  color: ${({ theme }) => theme.palette.primary.light};
`
