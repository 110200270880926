import { FC, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { Controller, useForm } from 'react-hook-form'
// components
import { Icon, IconButton } from 'modules/core/components'
// hooks
import { useInviteCreate, useUser } from 'modules/user/hooks'
import { useSportList } from 'modules/sport/hooks'
import { useTeamList } from 'modules/team/hooks'
// constants
import {
  nonInternalUserRoles,
  nonSensitiveUserRoles,
  userRoles,
} from 'modules/user/constants'
// schemas
import { requestSchema } from 'modules/user/schemas'
// types
import { UserRequestFormData } from 'modules/user/types'
// context
import { SnackbarContext } from 'modules/core/context'

import {
  ButtonWrapper,
  DialogBody,
  DialogHeader,
  DialogTitle,
  DialogTitleWrapper,
  FormControlWrapper,
  FullWidthFormControl,
  ErrorMessage,
  InputText,
  InputTextField,
  Label,
  RoleSelector,
  SaveButton,
  SaveIcon,
  StyledAutocomplete,
  StyledDialog,
  StyledMenuItem,
  StyledPopper,
} from './UserInviteModal.styled'
import { TextField, UserAddModalProps } from './UserInviteModal.interface'
import { doesUserHaveRole } from 'modules/user/utils'
import { useTError } from 'modules/core/i18n/hooks'

const additionalDataPageLimit = 1e4

export const UserInviteModal: FC<UserAddModalProps> = ({ open, setOpen }) => {
  const { t } = useTranslation(['pages', 'userRole'])
  const { tError } = useTError(['pages, userRole'])

  const { showSnackbar } = useContext(SnackbarContext)
  const [selectedSportId, setSelectedSportId] = useState<string | null>(null)
  const user = useUser()
  const isRoot = doesUserHaveRole(user.data, [userRoles.ROOT])

  const roles = useMemo(
    () => Object.entries(isRoot ? nonSensitiveUserRoles : nonInternalUserRoles),
    [isRoot]
  )

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<UserRequestFormData>({
    resolver: yupResolver(requestSchema),
    defaultValues: {
      role: '',
      team: null,
      sport: null,
    },
  })
  const inviteCreate = useInviteCreate()
  const sports = useSportList({
    page_limit: additionalDataPageLimit,
  })
  const teams = useTeamList(
    {
      page_limit: additionalDataPageLimit,
      sport_id: selectedSportId ?? undefined,
    },
    {
      enabled: selectedSportId !== null,
    }
  )

  const teamOptions = useMemo(() => {
    if (selectedSportId === null) {
      return []
    }
    return (
      teams.data?.results?.map(team => ({
        label: team.name,
        value: team.id,
      })) ?? []
    )
  }, [teams.data, selectedSportId])

  const sportOptions = useMemo(() => {
    return (
      sports.data?.results?.map(sport => ({
        label: sport.name,
        value: sport.id,
      })) ?? []
    )
  }, [sports.data])

  const onSubmit = (data: UserRequestFormData) => {
    inviteCreate.mutate(
      {
        email: data.email,
        first_name: data.name,
        last_name: data.surname,
        role: data.role === '' ? 'PLAYER' : data.role,
        team: data.team === '' || data.team === null ? undefined : data.team,
      },
      {
        onError: () => {
          showSnackbar({
            message: 'Something went wrong. Please try again later.',
            type: 'error',
          })
        },
        onSuccess: () => {
          setOpen(false)
          setSelectedSportId(null)
        },
      }
    )
  }

  const textInputs: TextField[] = useMemo(
    () => [
      {
        field: 'name',
      },
      {
        field: 'surname',
      },
      {
        field: 'email',
      },
    ],
    []
  )

  return (
    <StyledDialog
      PaperProps={{
        elevation: 0,
      }}
      open={open}
      onClose={() => {
        setOpen(false)
        setSelectedSportId(null)
      }}
    >
      <DialogHeader>
        <DialogTitleWrapper>
          <Icon name='pencil' />
          <DialogTitle>{t('pages:admin.context.inviteUser')}</DialogTitle>
        </DialogTitleWrapper>
        <IconButton name='close' onClick={() => setOpen(false)} />
      </DialogHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogBody>
          {textInputs.map((textInput, index) => (
            <FormControlWrapper key={`${textInput.field}-${index}`}>
              <Label>
                {t(`pages:admin.modal.inviteUser.${textInput.field}`)}
              </Label>
              <FullWidthFormControl>
                <InputText
                  inputProps={{ 'aria-label': textInput.field }}
                  placeholder=''
                  disableUnderline
                  error={!!errors[textInput.field]?.message}
                  {...register(textInput.field)}
                />
                <ErrorMessage>
                  {tError(errors[textInput.field]?.message)}
                </ErrorMessage>
              </FullWidthFormControl>
            </FormControlWrapper>
          ))}
          <FormControlWrapper>
            <Label>{t('pages:admin.modal.inviteUser.role')}</Label>
            <FullWidthFormControl>
              <Controller
                name='role'
                control={control}
                render={({ field }) => (
                  <RoleSelector
                    inputProps={{ 'aria-label': 'role' }}
                    error={!!errors.role?.message}
                    defaultValue=''
                    {...field}
                  >
                    {roles.map(([key, role]) => (
                      <StyledMenuItem key={key} value={key}>
                        {t(`userRole:${role}`)}
                      </StyledMenuItem>
                    ))}
                  </RoleSelector>
                )}
              />
              <ErrorMessage>{tError(errors.role?.message)}</ErrorMessage>
            </FullWidthFormControl>
          </FormControlWrapper>
          <FormControlWrapper>
            <Label>{t(`pages:admin.modal.inviteUser.sport`)}</Label>
            <FullWidthFormControl>
              <Controller
                name='sport'
                control={control}
                render={({ field: { onChange, ref, ...field } }) => (
                  <StyledAutocomplete
                    // TODO: fix typing
                    onChange={(_, data: any) => {
                      onChange(data?.value)
                      setSelectedSportId(data?.value ?? null)
                      setValue('team', null)
                    }}
                    PopperComponent={StyledPopper}
                    renderInput={params => (
                      <InputTextField
                        {...field}
                        {...params}
                        inputProps={{
                          'aria-label': 'sport',
                          ...params.inputProps,
                        }}
                        inputRef={ref}
                      />
                    )}
                    options={sportOptions}
                    error={!!errors.sport?.message}
                  />
                )}
              />
              <ErrorMessage>{tError(errors.sport?.message)}</ErrorMessage>
            </FullWidthFormControl>
          </FormControlWrapper>
          <FormControlWrapper>
            <Label>{t(`pages:admin.modal.inviteUser.team`)}</Label>
            <FullWidthFormControl>
              <Controller
                name='team'
                control={control}
                render={({ field: { onChange, ref, ...field } }) => (
                  <StyledAutocomplete
                    key={`${selectedSportId}`}
                    onChange={(_, data: any) => onChange(data?.value ?? '')}
                    PopperComponent={StyledPopper}
                    renderInput={params => (
                      <InputTextField
                        {...field}
                        {...params}
                        inputProps={{
                          'aria-label': 'team',
                          ...params.inputProps,
                        }}
                        inputRef={ref}
                        value={field.value}
                      />
                    )}
                    options={teamOptions}
                    error={!!errors.team?.message}
                  />
                )}
              />
              <ErrorMessage>{tError(errors.team?.message)}</ErrorMessage>
            </FullWidthFormControl>
          </FormControlWrapper>

          <ButtonWrapper>
            <SaveButton type='submit'>
              <SaveIcon name='save' />
              {t('pages:admin.button.save')}
            </SaveButton>
          </ButtonWrapper>
        </DialogBody>
      </form>
    </StyledDialog>
  )
}
