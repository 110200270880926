import { ServerTranslatedProperty } from 'modules/core/i18n/types/'
import { DEFAULT_LANGUAGE_CODE } from 'modules/core/i18n/constants'

type ServerLanguages = keyof ServerTranslatedProperty

export const translateServerProperty = (
  translationField: ServerTranslatedProperty,
  language: ServerLanguages
) =>
  translationField[language] ??
  translationField[DEFAULT_LANGUAGE_CODE as ServerLanguages] ??
  ''
