import { MatchLocatorType } from 'modules/match/types/match.types'
import { IconName } from 'modules/core/components/Icon/Icon.interface'

export type MatchTypeOption = {
  view: MatchLocatorType
  displayName: string
  icon: IconName
}

export const MATCH_LOCATOR_TYPE: MatchTypeOption[] = [
  {
    view: 'pano',
    displayName: 'Panoramic',
    icon: 'panorama',
  },
  {
    view: 'broadcast',
    displayName: 'Broadcast',
    icon: 'broadcast',
  },
  {
    view: 'broadcast_live',
    displayName: 'Broadcast Live',
    icon: 'aspect-ratio',
  },
  {
    view: 'tactical',
    displayName: 'Tactical View',
    icon: 'aspect-ratio',
  },
]
