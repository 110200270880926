import { useQueryClient, useMutation } from 'react-query'
import { ApiService } from 'services/Api.service'
import {
  TelestrationGroup,
  TelestrationGroupList,
  BulkUpdateTelestrationGroupBody,
} from '@sporttotal-tv/dip-coaching-client'
import { MutateContext } from 'modules/core/types'
import { TELESTRATION_GROUPS_LIST_CACHE_KEY } from './useTelestrationGroupsList'

export const TELETSTRATION_GROUP_BULK_UPDATE_CACHE_KEY =
  'telestration-group-bulk-update'

export const useTelestrationGroupBulkUpdate = () => {
  const queryClient = useQueryClient()

  return useMutation<
    TelestrationGroup[],
    Error,
    BulkUpdateTelestrationGroupBody,
    MutateContext<TelestrationGroupList>
  >(
    TELETSTRATION_GROUP_BULK_UPDATE_CACHE_KEY,
    (body: BulkUpdateTelestrationGroupBody) =>
      ApiService.telestrationGroup.bulkUpdate(body),
    {
      onMutate: async params => {
        await queryClient.cancelQueries(TELESTRATION_GROUPS_LIST_CACHE_KEY, {
          exact: false,
        })

        const previousData = queryClient.getQueriesData<TelestrationGroupList>(
          TELESTRATION_GROUPS_LIST_CACHE_KEY
        )

        if (previousData) {
          queryClient.setQueriesData<TelestrationGroupList | undefined>(
            TELESTRATION_GROUPS_LIST_CACHE_KEY,
            oldData =>
              oldData && {
                total: oldData.total,
                results: oldData.results.map(telestrationGroup => {
                  const updatedTelestrationGroup = params.find(
                    el => el.id === telestrationGroup.id
                  )
                  if (!updatedTelestrationGroup) {
                    return telestrationGroup
                  }

                  return {
                    ...telestrationGroup,
                    ...updatedTelestrationGroup,
                  }
                }),
              }
          )
        }

        return { previousData }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData<TelestrationGroupList>(
              prevData[0],
              prevData[1]
            )
          )
        }
      },
      onSettled: () =>
        queryClient.invalidateQueries(TELESTRATION_GROUPS_LIST_CACHE_KEY, {
          exact: false,
        }),
    }
  )
}
