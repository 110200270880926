import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { useQueryRetry } from 'modules/core/hooks'
import { ListClubListOptions } from '@sporttotal-tv/dip-coaching-client'

export const CLUB_LIST_CACHE_KEY = 'club-list'

// @TODO: Move to club module
export const useClubList = (options?: ListClubListOptions) => {
  const handleQueryRetry = useQueryRetry({ maxRetries: 0 })

  return useQuery(
    [CLUB_LIST_CACHE_KEY, options],
    () => ApiService.club.list(options),
    {
      staleTime: Infinity,
      retry: handleQueryRetry,
    }
  )
}
