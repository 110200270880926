import {
  Autocomplete,
  css,
  FormControl,
  Popper,
  styled,
  TextField,
} from '@mui/material'

export const InputTextField = styled(TextField, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>`
  background-color: ${({ theme }) => theme.palette.darkest};

  & .MuiInputBase-input {
    padding: ${({ theme }) => theme.spacing(1)};
    font-size: 14px;
  }

  & .MuiInputBase-formControl {
    border-radius: 0;
    padding: 0;
  }

  & .MuiInputAdornment-root {
    width: 30px;
  }

  & .MuiOutlinedInput-notchedOutline {
    outline: none;
    border: none;
    border-radius: 0;
  }

  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.palette.error.main};
    `}
`

export const StyledAutocomplete = styled(Autocomplete, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>`
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiAutocomplete-endAdornment {
    display: flex;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
  }

  & .MuiOutlinedInput-root {
    padding: 0;
  }

  & .MuiOutlinedInput-root {
    border: 1px solid transparent;
  }

  ${({ error, theme }) =>
    error &&
    css`
      & .MuiOutlinedInput-root {
        border: 1px solid ${theme.palette.error.main};
      }
    `}
`

export const AutocompleteOption = styled('li')`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(1)};
`

export const StyledPopper = styled(Popper)`
  & .MuiPaper-root {
    background-color: ${({ theme }) => theme.palette.primary.dark};
    box-shadow: ${({ theme }) => theme.shadow.main};
  }

  & .MuiAutocomplete-listbox {
    background-color: ${({ theme }) => theme.palette.primary.dark};
  }

  & .MuiAutocomplete-option {
    background-color: ${({ theme }) => theme.palette.primary.dark};
    font-size: 14px;
  }
`

export const InputWrapper = styled('div')`
  width: 100%;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.75)};
  margin-bottom: ${({ theme }) => theme.spacing(0.75)};
`

export const RatingWrapper = styled(InputWrapper)`
  align-items: center;
`

export const FormControlWrapper = styled(FormControl)`
  flex: 3;
`

export const RatingFormControlWrapper = styled('div')`
  display: flex;
  align-items: center;
`

export const HeightWeightWrapper = styled('div')`
  display: flex;
`
