import { css, styled, Typography } from '@mui/material'
import { Icon } from 'modules/core/components/Icon'

export const TitleContainer = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1)};
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.light};
`

export const PlayControls = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;
  margin: ${({ theme }) => theme.spacing(2, 0, 1)};
  justify-content: center;
`

export const PlayControlsIcon = styled(Icon, {
  shouldForwardProp: prop => prop !== 'active' && prop !== 'disabledClick',
})<{ active?: boolean; disabledClick?: boolean }>`
  cursor: pointer;

  ${({ theme, active }) =>
    !active &&
    css`
      &:hover path {
        fill: ${theme.palette.primary.contrastText};
        filter: brightness(70%);
      }
    `}

  & path {
    transition: all 0.3s;
    fill: ${({ theme, active }) =>
      active
        ? theme.palette.primary.contrastText
        : theme.palette.primary.light};
  }

  ${({ theme, disabledClick }) =>
    disabledClick &&
    css`
      cursor: not-allowed;

      & path {
        fill: ${theme.palette.primary.contrastText};
        filter: brightness(45%);
      }

      &:hover path {
        fill: ${theme.palette.primary.contrastText};
        filter: brightness(45%);
      }
    `}
`
