import * as yup from 'yup'
import { convertTimeToSeconds } from 'modules/core/utils'

export const highlightSchema = yup.object().shape({
  event_id: yup.string().uuid().required('error.event_id'),
  start_time: yup
    .number()
    .min(0)
    .integer()
    .transform((_, val) => convertTimeToSeconds(val))
    .required('error.start_time'),
  duration: yup
    .number()
    .min(0)
    .integer()
    .transform((_, val) => convertTimeToSeconds(val))
    .required('error.duration'),
  description: yup.string().nullable(),
})
