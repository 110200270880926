import { styled } from '@mui/material';

export const StyledInput = styled('input')({
  width: '100%',
  height: '40px',
  padding: '12px 16px',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  border: '1px solid #C7C7C7',
  borderRadius: '4px',
  font: 'normal normal normal 14px/16px Arial',
});
