import { createTheme } from '@mui/material'
import 'styles/index.css'
import 'theme/fonts'

interface CustomThemeProps {
  drawerWidth: number
  closedDrawerWidth: number
  toolbarHeight: number
  componentToolbarDefaultHeight: number
  shadow: {
    main: string
  }
  reactGridLayout: {
    placeholder: number
  }
  styles: {
    disabled: string
  }
  table: {
    primaryRow: string
    secondaryRow: string
  }
  createAlpha: (color: string, alpha: number) => string
}
interface CustomPaletteProps {
  darkest: string
  divider: string
  lightBlue: string

  componentIcons: {
    default: string
    first: string
    second: string
    third: string
  }
}

declare module '@mui/material/styles' {
  interface Theme extends CustomThemeProps {}
  interface ThemeOptions extends CustomThemeProps {}
  interface Palette extends CustomPaletteProps {}
  interface PaletteOptions extends CustomPaletteProps {}
}

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#96989B',
      main: '#383939',
      dark: '#2C2C2C',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#565757',
      main: '#FF0044',
      dark: '#56303B',
    },
    background: {
      default: '#2C2C2C',
    },
    lightBlue: '#BCE0FD',
    darkest: '#1D1D1D',
    divider: '#707070',
    componentIcons: {
      default: '#96989B',
      first: '#FF0044',
      second: '#d4d45f',
      third: '#00ED09',
    },
    error: {
      main: '#E54242',
    },
    success: {
      main: '#00ED09',
    },
    warning: {
      main: '#D6A213',
    },
  },
  shadow: {
    main: '0 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
  },
  reactGridLayout: {
    placeholder: 100,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: '16px',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          width: '22px',
          height: '22px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        action: {
          margin: 'auto',
          paddingTop: 0,
        },
        standardSuccess: {
          backgroundColor: '#1f541f',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: '#96989B',
        },
        colorPrimary: { '&.Mui-checked': { color: '#FF0044' } },
        track: {
          '.Mui-checked.Mui-checked + &': {
            opacity: 0.5,
            backgroundColor: '#FF0044',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: '#96989b',
        },
      },
    },
  },

  drawerWidth: 240,
  closedDrawerWidth: 65,
  toolbarHeight: 64,
  componentToolbarDefaultHeight: 46,
  styles: {
    disabled: `
      cursor: not-allowed;
      filter: brightness(0.5);
      user-select: none;
        `,
  },
  table: {
    primaryRow: '#414242',
    secondaryRow: '#4C4D4D',
  },
  createAlpha: (color: string, alpha: number) => {
    return `${color}${alpha * 100}`
  },
})

export { theme }
