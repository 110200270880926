import { Input, styled } from '@mui/material'

export const InputText = styled(Input, {
  shouldForwardProp: prop => prop !== 'color',
})<{ color?: string }>`
  padding: 0;
  & textarea {
    color: #ffffff;
    font-size: 30px;
    letter-spacing: 4px;
    font-weight: bold;
    line-height: 30px;
  }

  ${({ color }) => color && `& textarea { color: ${color}; }`}
`
