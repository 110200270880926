import { useTranslation } from 'react-i18next'
import { Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  FlexColumn,
  FlexRow,
} from 'modules/landing/components/shared/Flex/styled'
import { Text } from 'modules/landing/components/shared/Text/Text'
import { Link } from 'modules/landing/components/shared/Link/Link'
import { useAdaptive } from 'modules/landing/hooks/useAdaptive'

import logo from 'modules/landing/assets/logo-with-text.svg'
import { BREAKPOINTS, FOOTER, SOCIAL_LINKS } from 'modules/landing/constants'

export const Footer = () => {
  const theme = useTheme()

  const { t } = useTranslation(['pages'])

  const isMobile = useAdaptive(0, BREAKPOINTS.mobile)
  const isTablet = useAdaptive(BREAKPOINTS.mobile, BREAKPOINTS.tablet)
  const navigate = useNavigate()

  return (
    <FlexColumn
      as='footer'
      sx={{
        width: '100%',
        height: '247px',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#000',
        position: 'relative',
      }}
    >
      <img src={logo} alt='logo' />

      {(isTablet || isMobile) && (
        <FlexRow as='nav' sx={{ gap: '30px', paddingTop: '40px' }}>
          {SOCIAL_LINKS.map(social => (
            <Link key={`link-${social.name}`} href={social.href}>
              <img src={social.img} alt={social.name} />
            </Link>
          ))}
        </FlexRow>
      )}

      <FlexRow
        sx={{ width: '100%', justifyContent: 'center', marginTop: '40px' }}
      >
        {FOOTER.map((link, i) => (
          <FlexRow key={link.name}>
            <Text
              key={`footer-${link}`}
              variant='caption'
              sx={{
                font: 'normal normal normal 11px/18px Roboto',
                letterSpacing: '0px',
                color: theme.palette.secondary.contrastText,
                width: 'fit-content',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate(`/${link.name}`)
              }}
            >
              {t(`landing.footer.${link.name}`)}
              {i !== FOOTER.length - 1 ? ' - ' : ''}
            </Text>
          </FlexRow>
        ))}
      </FlexRow>
      <FlexRow>
        <Typography sx={{ fontSize: '11px', marginTop: '10px' }}>
          {t('landing.footer.copyright')}
        </Typography>
      </FlexRow>
    </FlexColumn>
  )
}
