import { useMemo, FC, useEffect } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
// Hooks
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useUser } from 'modules/user/hooks'
import { useMatch } from 'modules/match/hooks'
import { useEventList } from 'modules/event/hooks'
import { useHighlightUpdate } from 'modules/highlight/hooks'
import { useServerTranslatedProperty } from 'modules/core/hooks'
// Components
import { DialogActions } from '@mui/material'
import {
  TextInput,
  TextInputLabel,
  TimeMaskInput,
} from 'modules/core/components'
import { ActionButton, ModalDialog } from 'modules/core/components'
import { OptionsSelectorMenuItem } from 'modules/core/styled'
import {
  FormControlWrapper,
  InputText,
} from 'modules/core/components/TextInput/TextInput.styled'
import {
  InputWrapper,
  StyledOptionsSelector,
} from './HighlightUpdateModal.styled'
// Utils
import { convertSecondsToTime } from 'modules/core/utils'
// Schemas
import { highlightSchema } from 'modules/highlight/schemas'
// Types
import {
  HighlightUpdateFormData,
  HighlightUpdateModalProps,
} from './HighlightUpdateModal.interface'

export const HighlightUpdateModal: FC<HighlightUpdateModalProps> = ({
  open,
  onClose,
  highlight,
}) => {
  const user = useUser()

  const { t } = useTranslation('components', { keyPrefix: 'highlight' })
  const tServer = useServerTranslatedProperty()

  const { match } = useMatch()
  const { mutate } = useHighlightUpdate(highlight?.id ?? '')
  const { data: events } = useEventList({ sport_id: match?.sport?.id })

  const { handleSubmit, control, setValue, formState } =
    useForm<HighlightUpdateFormData>({
      resolver: yupResolver(highlightSchema),
      defaultValues: highlight,
    })

  useEffect(() => {
    if (!highlight) return

    setValue('event_id', highlight.event_id)

    // Use ignore because TimeMask expects string but type is number
    // @ts-ignore
    setValue('start_time', convertSecondsToTime(highlight.start_time))
    // @ts-ignore
    setValue('duration', convertSecondsToTime(highlight.duration))
  }, [highlight])

  const eventOptions = useMemo(
    () =>
      events?.results.map(({ id, name }) => ({
        label: tServer(name) ?? '',
        value: id,
      })) ?? [],
    [events]
  )

  const handleHighlightUpdate = (data: HighlightUpdateFormData) => {
    if (!user.data) return

    mutate(data, {
      onSuccess: onClose,
      // TODO: Notify error occurred
      onError: console.error,
    })
  }

  return (
    <ModalDialog title={t('modal.update.title')} open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(handleHighlightUpdate)}>
        <InputWrapper>
          <TextInputLabel>{t('modal.update.event')}</TextInputLabel>
          <FormControlWrapper>
            <Controller
              name='event_id'
              control={control}
              render={({ field: { value, ...others } }) => (
                <StyledOptionsSelector
                  {...others}
                  inputProps={{ value }}
                  defaultValue={highlight?.event_id}
                >
                  {eventOptions.map(({ label, value }) => (
                    <OptionsSelectorMenuItem key={value} value={value}>
                      {label}
                    </OptionsSelectorMenuItem>
                  ))}
                </StyledOptionsSelector>
              )}
            />
          </FormControlWrapper>
        </InputWrapper>

        <InputWrapper>
          <TextInputLabel>{t('modal.update.start_time')}</TextInputLabel>
          <FormControlWrapper>
            <Controller
              name='start_time'
              control={control}
              render={({ field }) => (
                <InputText
                  {...field}
                  disableUnderline
                  inputComponent={TimeMaskInput as any}
                />
              )}
            />
          </FormControlWrapper>
        </InputWrapper>

        <InputWrapper>
          <TextInputLabel>{t('modal.update.duration')}</TextInputLabel>
          <FormControlWrapper>
            <Controller
              name='duration'
              control={control}
              render={({ field }) => (
                <InputText
                  {...field}
                  disableUnderline
                  inputComponent={TimeMaskInput as any}
                />
              )}
            />
          </FormControlWrapper>
        </InputWrapper>

        <InputWrapper>
          <TextInputLabel>{t('modal.update.description')}</TextInputLabel>
          <FormControlWrapper>
            <Controller
              name='description'
              control={control}
              render={({ field }) => <TextInput {...field} />}
              defaultValue={highlight?.description}
            />
          </FormControlWrapper>
        </InputWrapper>

        <DialogActions>
          <ActionButton
            type='submit'
            icon='save'
            text={t('modal.update.save')}
            position='end'
          />
        </DialogActions>
      </form>
    </ModalDialog>
  )
}
