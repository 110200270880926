import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import { TEAM_PLAYER_TRANSFER_LIST_CACHE_KEY } from 'modules/team-player/hooks'
import { MutateContext } from 'modules/core/types/mutate-context.type'
import { InfiniteQuery } from 'modules/core/types'
import {
  PlayerTransfer,
  PlayerTransferList,
} from '@sporttotal-tv/dip-coaching-client'

export const TEAM_PLAYER_TRANSFER_DELETE_CACHE_KEY =
  'team-player-transfer-delete'

export const useTeamPlayerTransferDelete = () => {
  const queryClient = useQueryClient()

  return useMutation<
    PlayerTransfer,
    Error,
    string,
    MutateContext<InfiniteQuery<PlayerTransferList>>
  >(
    TEAM_PLAYER_TRANSFER_DELETE_CACHE_KEY,
    (id: string) => ApiService.teamPlayerTransfer.delete(id),
    {
      onMutate: async id => {
        await queryClient.cancelQueries(TEAM_PLAYER_TRANSFER_LIST_CACHE_KEY, {
          exact: false,
        })

        const previousData = queryClient.getQueriesData<
          InfiniteQuery<PlayerTransferList>
        >(TEAM_PLAYER_TRANSFER_LIST_CACHE_KEY)

        if (previousData) {
          queryClient.setQueriesData<
            InfiniteQuery<PlayerTransferList> | undefined
          >(
            TEAM_PLAYER_TRANSFER_LIST_CACHE_KEY,
            oldData =>
              oldData && {
                pageParams: oldData.pageParams,
                pages: oldData.pages.map(
                  page =>
                    page && {
                      total: Math.max(page.total - 1, 0),
                      results: page.results.filter(item => item.id !== id),
                    }
                ),
              }
          )
        }

        return { previousData }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData(prevData[0], prevData[1])
          )
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(TEAM_PLAYER_TRANSFER_LIST_CACHE_KEY, {
          exact: false,
        })
      },
    }
  )
}
