import { useCallback, useEffect, useState } from 'react'
// Constants
import { PLAYBACK_RATE_LIMIT } from '../constants'
// Types
import { PlaybackStatus } from 'modules/video-player-controller/types'
import { MediaPlayer } from 'modules/media-render/types/media-render.types'

export const useVideoPlayerControls = (player: MediaPlayer | null) => {
  const [playbackRate] = useState<number>(1)
  const [status, setStatus] = useState<PlaybackStatus>('idle')
  const [volume, setVolumeState] = useState<number>(player?.volume || 0)

  useEffect(() => {
    if (!player) return

    const onPlay = () => setStatus('play')
    const onPause = () => setStatus('pause')
    const onVolumeChange = () => setVolumeState(player.volume)

    const onRateChange = () =>
      setStatus(player.playbackRate === 1 ? 'play' : 'fast-forward')

    player.addEventListener('play', onPlay)
    player.addEventListener('pause', onPause)
    player.addEventListener('ratechange', onRateChange)
    player.addEventListener('volumechange', onVolumeChange)

    return () => {
      player.removeEventListener('play', onPlay)
      player.removeEventListener('pause', onPause)
      player.removeEventListener('ratechange', onRateChange)
      player.removeEventListener('volumechange', onVolumeChange)
    }
  }, [player])

  const play = useCallback(() => player?.play(), [player])
  const pause = useCallback(() => player?.pause(), [player])

  const playPause = useCallback(() => {
    player?.paused ? player?.play() : player?.pause()
  }, [player])

  const stop = useCallback(() => {
    if (!player) return

    player.pause()
    player.currentTime = 0
    setStatus('stop')
  }, [player])

  const fastForward = useCallback(() => {
    if (!player) return

    const updatedRate = Math.min(
      Math.max(player.playbackRate * 2, 0),
      PLAYBACK_RATE_LIMIT
    )

    player.play()
    player.playbackRate = updatedRate
  }, [player])

  const setVolume = useCallback(
    (volume: number) => {
      if (!player) return

      player.volume = Math.min(Math.max(volume, 0), 1)
      player.muted = player.volume === 0
    },
    [player]
  )

  const setPlaybackRate = useCallback(
    (rate: number) => {
      if (player) player.playbackRate = rate
    },
    [player]
  )

  return {
    play,
    pause,
    playPause,
    stop,
    fastForward,
    // rewind, // TODO: Implement later
    status,
    playbackRate,
    volume,
    setVolume,
    setPlaybackRate,
  }
}

//   const handleRewind = useCallback(() => {
//     if (intervalRewind) {
//       clearInterval(intervalRewind)
//     }

//     if (player?.currentTime) {
//       player.currentTime -= 5
//     }
//   }, [player, intervalRewind])

//   // TODO: find a better way to show video backward
//   const handleBackward = useCallback(() => {
//     let newPlaybackSpeed = playbackSpeed * 2
//     if (playbackStatus !== 'backward') {
//       newPlaybackSpeed = 2
//       setPlaybackStatus('backward')
//     }

//     if (intervalRewind) {
//       clearInterval(intervalRewind)
//     }

//     if (player) {
//       const startSystemTime = new Date().getTime()
//       const startVideoTime = player.currentTime

//       setIntervalRewind(
//         setInterval(function () {
//           player.playbackRate = 1.0
//           player.pause()
//           if (player.currentTime === 0 && intervalRewind) {
//             clearInterval(intervalRewind)
//           }
//           const elapsed = new Date().getTime() - startSystemTime
//           player.currentTime = Math.max(
//             startVideoTime - (elapsed * Math.sqrt(newPlaybackSpeed)) / 500.0,
//             0
//           )
//         }, 200)
//       )
//     }

//     setPlaybackSpeed(newPlaybackSpeed)
//   }, [player, intervalRewind, playbackStatus, playbackSpeed])
