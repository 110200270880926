import {
  PlayerStats,
  PlayerStatsList,
  PlayerStatsListParams,
  CreatePlayerStatsBody,
  UpdatePlayerStatsBody,
} from '@sporttotal-tv/dip-coaching-client'
import {
  transformApiResults,
  transformIdApiResults,
} from 'modules/core/helpers'
import { api } from 'modules/core/api'

export const TeamPlayerStatsApiService = {
  list: transformApiResults<PlayerStatsListParams, PlayerStatsList>(
    api.playerStats.list
  ),
  retrieve: transformApiResults<string, PlayerStats>(api.playerStats.retrieve),
  create: transformApiResults<CreatePlayerStatsBody, PlayerStats>(
    api.playerStats.create
  ),
  delete: transformApiResults<string, PlayerStats>(api.playerStats.delete),
  update: transformIdApiResults<UpdatePlayerStatsBody, PlayerStats>(
    api.playerStats.update
  ),
}
