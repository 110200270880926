import { useEffect, useRef } from 'react'
import { Box, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Root, Title, Wrapper, Content } from './Cookies.styled'

export const Cookies = () => {
  const body = useRef<HTMLDivElement>()
  const { t } = useTranslation('pages', { keyPrefix: 'cookies' })

  useEffect(() => {
    const script = document.createElement('script')
    script.src =
      'https://consent.cookiebot.com/5d11cbe1-9b75-4c3f-b4f5-1085597a44d0/cd.js'
    script.type = 'text/javascript'
    script.setAttribute(
      'data-domain-script',
      '835517fb-d753-4129-bee4-b28cdfe6bb24-test'
    )
    if (!body.current) return
    body.current.appendChild(script)
  }, [])

  return (
    <Root>
      <Wrapper>
        <Divider />
        <Title variant='h1'>{t('title')}</Title>
        <Content>
          <Box ref={body}>
            <Box id='CookieDeclaration' />
          </Box>
        </Content>
      </Wrapper>
    </Root>
  )
}
