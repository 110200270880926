import { styled, DialogTitle, Dialog } from '@mui/material'

export const Title = styled(DialogTitle)`
  text-align: center;
`

export const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 5px;
  }
`
