import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Arrow, Circle, Group, Rect, Shape } from 'react-konva'
import Konva from 'konva'
// constants
import {
  SELECTION_AREA_NAME,
  SELECTION_GROUP_NAME,
  SELECTION_TRANSFORMER_NAME,
} from 'modules/annotator/constants'
// types
import {
  IKonvaNode,
  MultiLineConnector,
  MultiLineTarget,
} from 'modules/annotator/types'
import { TelestrationShapeType } from 'modules/telestration/types'
// utils
import {
  generateMultiLineTargetsByPoints,
  getSmallerVector,
} from 'modules/annotator/utils'
import { getContrastColor } from 'modules/core/helpers'
// hooks
import { useDebounceWithSetter } from 'modules/core/hooks'

type ShapeMultiLineProps = TelestrationShapeType & {
  onChange?: (shape: IKonvaNode) => void
  preview?: boolean
  isActive?: boolean
}

const ShapeMultiLine: FC<ShapeMultiLineProps> = ({
  type,
  preview,
  id,
  name,
  onChange,
  isActive,
  ...props
}) => {
  const shapeRef = useRef<any>(null)
  const layerRef = useRef<any>(null)
  const [updateCounter, setUpdateCounter] = useState(0)
  const [debounceUpdateCounter, setDebounceUpdateCounter] =
    useDebounceWithSetter(updateCounter, 500)

  // targets are points which used as target to which draw arrow
  const [targets, setTargets] = useState<MultiLineTarget[]>(
    generateMultiLineTargetsByPoints(props.points)
  )

  // connectors are the arrows that create shape
  const connectors = useMemo(() => {
    return targets.reduce<MultiLineConnector[]>((acc, target, index) => {
      if (index === targets.length - 1) {
        return [
          ...acc,
          {
            id: 'connector-' + index,
            points: getSmallerVector(
              [target.x, target.y],
              [targets[0].x, targets[0].y]
            ).flat(),
          },
        ]
      }
      return [
        ...acc,
        {
          id: 'connector-' + index,
          points: getSmallerVector(
            [target.x, target.y],
            [targets[index + 1].x, targets[index + 1].y]
          ),
        },
      ]
    }, [])
  }, [targets])

  useEffect(() => {
    if (debounceUpdateCounter !== 0) {
      if (shapeRef.current) {
        onChange && onChange(shapeRef.current)
      }
      setUpdateCounter(0)
      setDebounceUpdateCounter(0)
    }
  }, [onChange, debounceUpdateCounter, setDebounceUpdateCounter])

  const [previewTargets, setPreviewTargets] = useState<number[][]>([])
  const [shadowTarget, setShadowTarget] = useState<number[]>([-10, -10])

  // this is points for arrow that will dynamically update when we in preview mode
  const shadowArrowPoints = useMemo(() => {
    const startPointX = previewTargets[previewTargets.length - 1]?.[0] ?? 0
    const startPointY = previewTargets[previewTargets.length - 1]?.[1] ?? 0
    const endPointX = shadowTarget[0] ?? 0
    const endPointY = shadowTarget[1] ?? 0

    if (
      (Math.abs(startPointX - endPointX) < 10 &&
        Math.abs(startPointY - endPointY) < 10) ||
      shadowTarget[0] === -10
    ) {
      return []
    }
    return getSmallerVector([startPointX, startPointY], [endPointX, endPointY])
  }, [shadowTarget, previewTargets])

  const previewConnectors = useMemo(() => {
    return previewTargets.reduce<number[][]>((acc, curr, index) => {
      if (index === previewTargets.length - 1) {
        return acc
      }

      return [...acc, getSmallerVector(curr, previewTargets[index + 1])]
    }, [])
  }, [previewTargets])

  const [cratingShapes, setCreatingShapes] = useState<boolean>(true)

  if (preview) {
    return (
      <Group
        key={id || name}
        onMouseDown={e => {
          if (
            e.target instanceof Konva.Circle &&
            e.target.name().includes(SELECTION_TRANSFORMER_NAME)
          ) {
            setCreatingShapes(false)
          }
        }}
        onMouseMove={e => {
          // @ts-ignore
          setShadowTarget([e.evt.layerX, e.evt.layerY])
        }}
        onMouseUp={e => {
          if (cratingShapes) {
            e.cancelBubble = true
            // @ts-ignore
            setPreviewTargets(prev => [...prev, [e.evt.layerX, e.evt.layerY]])
          } else {
            e.cancelBubble = false
          }
        }}
      >
        <Rect
          width={window.innerWidth}
          height={window.innerHeight}
          name={`${SELECTION_TRANSFORMER_NAME}-rect`}
        />

        {previewConnectors.map((connector, index) => (
          <Arrow
            key={`arrow-${index}`}
            name={`${SELECTION_TRANSFORMER_NAME}-arrow-${index}`}
            stroke={props.stroke}
            fill={`${props.stroke}`}
            points={connector}
          />
        ))}
        <Arrow
          name={`${SELECTION_TRANSFORMER_NAME}-arrow-shadow}`}
          points={shadowArrowPoints}
          stroke={props.stroke}
          fill={`${props.stroke}`}
        />
        {previewTargets.map((target, index) => (
          <Circle
            key={`circle-${index}`}
            name={`${SELECTION_TRANSFORMER_NAME}-circle-${index}`}
            stroke={getContrastColor(`${props.stroke}`)}
            fill={getContrastColor(`${props.stroke}`)}
            radius={7}
            shadowBlur={1}
            draggable={false}
            x={target[0]}
            y={target[1]}
          />
        ))}
      </Group>
    )
  }

  return (
    <Group
      ref={layerRef}
      id={`${SELECTION_GROUP_NAME}-${id}`}
      name={`${SELECTION_GROUP_NAME}-${id}`}
    >
      <Shape
        ref={shapeRef}
        {...props}
        shapeToolType={type}
        id={id}
        name={name}
        points={[...targets.map(el => [el.x, el.y]).flat()]}
      />
      <Shape />
      {connectors.map(connector => (
        <Arrow
          name={`${SELECTION_TRANSFORMER_NAME}-arrow-${connector.id}`}
          key={connector.id}
          id={connector.id}
          stroke={props.stroke}
          fill={`${props.stroke}`}
          points={connector.points}
        />
      ))}
      <Shape
        id={`${SELECTION_AREA_NAME}-${id}`}
        stroke={'transparent'}
        strokeWidth={0}
        fill={'transparent'}
        sceneFunc={(context, shape) => {
          const [startX, startY, ...points] = [
            ...targets.map(el => [el.x, el.y]).flat(),
          ]

          context.beginPath()
          context.moveTo(startX, startY)
          points.forEach((point, index) => {
            if (index % 2 === 0) {
              context.lineTo(points[index], points[index + 1])
            }
          })
          context.closePath()
          context.fillStrokeShape(shape)
        }}
      />
      {isActive &&
        targets.map(target => (
          <Circle
            key={target.id}
            id={target.id}
            name={`${SELECTION_TRANSFORMER_NAME}-circle-${target.id}`}
            stroke={getContrastColor(`${props.stroke}`)}
            fill={getContrastColor(`${props.stroke}`)}
            radius={7}
            shadowBlur={1}
            draggable={true}
            x={target.x}
            y={target.y}
            onDragMove={node => {
              target.x = node.target.x()
              target.y = node.target.y()
              setUpdateCounter(prev => prev + 1)
              setTargets(prev =>
                prev.map(el => {
                  if (el.id === target.id) {
                    return { ...target, x: node.target.x(), y: node.target.y() }
                  }
                  return el
                })
              )
            }}
          />
        ))}
    </Group>
  )
}

export { ShapeMultiLine }
