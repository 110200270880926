import { Player, PlayerCreatePayload } from '@sporttotal-tv/dip-coaching-client'
import { teamPlayerMockup } from 'modules/team-player/utils/teamPlayer.mockup'
import { generateId } from 'modules/core/helpers/generateId'

export const placeholderTeamPlayer = (
  payload?: PlayerCreatePayload
): Player => ({
  ...teamPlayerMockup,
  id: generateId(),
  ...payload,
})
