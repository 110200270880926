import { useTranslation } from 'react-i18next'
import { FlexColumn, FlexRow } from 'modules/landing/components/shared/Flex'
import { Text } from 'modules/landing/components/shared/Text'
import { MainWrapper, StyledDeviceImage, Wrapper } from './styled'
import { useAdaptive } from 'modules/landing/hooks/useAdaptive'
import { BREAKPOINTS } from 'modules/landing/constants'
import { partners, texts } from './utils'
import devicesImg from 'modules/landing/assets/devices.png'
import { useTheme } from '@mui/material'

export const AboutSection = () => {
  const theme = useTheme()

  const { t } = useTranslation(['pages'])

  const isMobile = useAdaptive(0, BREAKPOINTS.mobile)
  const isTablet = useAdaptive(BREAKPOINTS.mobile, BREAKPOINTS.tablet)
  const isBigDesktop = useAdaptive(BREAKPOINTS.desktop, BREAKPOINTS.bigDesktop)

  return (
    <MainWrapper
      id='about'
      sx={{
        padding: !isBigDesktop ? '58px 0 0 20px' : '58px 0 0 30px',
        alignItems: 'center',
      }}
    >
      <Wrapper sx={{ width: !isMobile ? '100%' : '80%' }}>
        <FlexColumn
          sx={{
            alignItems: isMobile ? 'center' : 'left',
            justifyContent: 'space-between',
          }}
        >
          <Text
            variant='h2'
            sx={{
              width: '100%',
              font: 'normal normal normal 42px/40px Bebas Neue',
              textAlign: isMobile ? 'center' : 'left',
              fontWeight: '800',
              letterSpacing: '0',
              color: theme.palette.secondary.main,
              marginBottom: '18px',
            }}
          >
            {t(`landing.sections.about.title`)}
          </Text>
          <FlexColumn
            sx={{
              width: isMobile ? '100%' : '480px',
              minHeight: '315px',
              gap: '26px',
              marginBottom: '26px',
            }}
          >
            {texts.map((_, i) => (
              <Text
                key={`about-text-${i}`}
                sx={{
                  font: `normal normal normal 14px/24px 'Exo 2'`,
                  textAlign: isMobile ? 'center' : 'left',
                }}
              >
                {t(`landing.sections.about.texts.${i}`)}
              </Text>
            ))}
          </FlexColumn>

          <FlexColumn
            sx={{
              marginBottom: isTablet || isMobile ? '50px' : '100px',
            }}
          >
            <Text
              sx={{
                font: 'normal normal normal 16px/30px Bebas Neue',
                letterSpacing: '0px',
                color: theme.palette.secondary.main,
                fontWeight: '600',
              }}
            >
              {t(`landing.sections.about.partners`)}
            </Text>
            <FlexRow
              sx={{ marginTop: '30px', marginLeft: '15px', gap: '70px' }}
            >
              {partners.map(({ logo, url }, i) => (
                <a key={url} href={url} target='_blank' rel='noreferrer'>
                  <img alt='partner' key={`partner-img-${i}`} src={logo} />
                </a>
              ))}
            </FlexRow>
          </FlexColumn>
        </FlexColumn>

        <FlexColumn sx={{ width: 'auto' }}>
          <StyledDeviceImage src={devicesImg} />
        </FlexColumn>
      </Wrapper>
    </MainWrapper>
  )
}
