import React, { MouseEventHandler, useCallback } from 'react'

export const useWithBlur = (onClick?: MouseEventHandler) => {
  const onClickHandler = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.currentTarget.blur()
      if (onClick) onClick(e)
    },
    [onClick]
  )

  return onClickHandler
}
