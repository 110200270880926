import { FC, ReactNode } from 'react'
// components
import { AppBar } from 'modules/core/components'
import { LoaderContainer } from 'modules/core/components'
// hooks
import { useUser } from 'modules/user/hooks'

import { Main } from './PlaylistPageLayout.styled'

export const PlaylistPageLayout: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const user = useUser()

  return (
    <>
      <AppBar editable tabs={[]} />
      <LoaderContainer isLoading={user.isLoading} transitionDelay={0.5}>
        <Main>{children}</Main>
      </LoaderContainer>
    </>
  )
}
