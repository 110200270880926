import { forwardRef } from 'react'
import { InputProps, Tooltip } from '@mui/material'

import {
  Label,
  InputWrapper,
  InputText,
  ErrorMessage,
  FormControlWrapper,
} from './TextInput.styled'
import { InputTextProps } from './TextInput.interface'

export const TextInput = forwardRef<
  HTMLInputElement,
  InputProps & InputTextProps
>(({ label, errorMessage, ...props }, ref) => {
  return (
    <InputWrapper>
      {label && <Label>{label}</Label>}
      <FormControlWrapper>
        <InputText
          error={!!errorMessage}
          ref={ref}
          disableUnderline
          {...props}
        />
        <Tooltip title={errorMessage ?? ''}>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Tooltip>
      </FormControlWrapper>
    </InputWrapper>
  )
})

export { Label as TextInputLabel, ErrorMessage as TextInputErrorMessage }
