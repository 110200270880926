import { FC } from 'react'
import {
  Container,
  Header,
  Title,
  List,
  ListItem,
  ListItemIcon,
  PricingContainer,
  PricingInfoContainer,
  Price,
  PricePeriod,
  Button,
  IconWrapper,
} from './TierCard.styled'
import { TierCardProps } from './TierCard.interface'

export const TierCard: FC<TierCardProps> = ({
  name,
  price,
  config,
  billingPeriod,
  onClick,
  disabled,
}) => {
  const isMonthly = billingPeriod === 'MONTHLY'
  const isComingSoon = config.comingSoon?.isComingSoon
  const showPrice = !config.comingSoon || config.comingSoon?.showPrice

  return (
    <Container key={name}>
      <IconWrapper>
        <img src={config.icon} alt={name} />
      </IconWrapper>
      <Header>
        <Title>{name}</Title>
      </Header>
      <List>
        {config.tierFeatures.map(item => (
          <ListItem key={item}>
            <ListItemIcon>🟢</ListItemIcon>
            {item}
          </ListItem>
        ))}
      </List>
      <PricingContainer>
        {showPrice && (
          <PricingInfoContainer>
            <Price>€{price}</Price>
            <PricePeriod>/{isMonthly ? 'month' : 'year'}</PricePeriod>
          </PricingInfoContainer>
        )}
        <Button onClick={onClick} disabled={disabled || isComingSoon}>
          {isComingSoon ? 'Coming Soon' : `Let's go`}
        </Button>
      </PricingContainer>
    </Container>
  )
}
