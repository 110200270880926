import {
  useEffect,
  useRef,
  useCallback,
  useState,
  SyntheticEvent,
  useMemo,
  useContext,
} from 'react'
import { Box } from '@mui/material'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
// Hooks
import {
  useReduxDispatch,
  useReduxSelector,
  useResizeObserver,
} from 'modules/core/hooks'
import {
  useTelestrationGroupCreate,
  useTelestrationGroupDelete,
  useTelestrationGroupUpdate,
  useTelestrationUpdate,
  useTelestrationGroupsList,
  useTelestrationGroupBulkUpdate,
} from 'modules/telestration/hooks'
import {
  useAnalyserPlayer,
  useAnalyserPlayerTimestamp,
} from 'modules/analyser/hooks'
import { usePreciseDuration } from 'modules/video-player/hooks'
import { useFrameList } from 'modules/frame/hooks'
// redux
import {
  removeTelestration,
  selectTelestrationActiveData,
  selectZoom,
  setActiveGroup,
  setActiveShape,
  setTelestration,
} from 'modules/telestration/redux'
import { selectMediaLocator, setMediaItem } from 'modules/video-player/redux'
import { selectLayoutCacheById } from 'modules/layout/redux'
// Components
import { InputEdit, IconButton, Icon } from 'modules/core/components'
import ScrollContainer, { ScrollEvent } from 'react-indiana-drag-scroll'
import { OverComponentSkeleton } from 'modules/core/styled'
import {
  Root,
  RootContainer,
  TimeContainer,
  EventDisplayTime,
  GroupContainer,
  GroupListContainer,
  Divider,
  GroupTracksContainer,
  GroupMetaContainer,
  GroupMetaNameContainer,
  GroupMetaNameText,
  GroupMetaOptionContainer,
  GroupTrack,
  GroupTrackShape,
  GroupActionsContainer,
  PlaybackHead,
  GroupTrackShapeResizable,
  DraggableWrapper,
  GroupActionsFullContainer,
  ClipTimeLineWrapper,
  TelestrationElement,
  Track,
  TrackSecondLine,
  ScrollWrapper,
} from './Telestration.styled'
import {
  TELESTRATION_GROUP_HEIGHT,
  PX_PER_SECOND,
  TELESTRATION_CELL_HEIGHT,
} from 'modules/telestration/constants'
import { TelestrationToolbar, Timeline } from 'modules/telestration/components'
// Utils
import { translateScrollToTimestamp } from 'modules/telestration/utils'
import { convertSecondsToTime, stringToHex } from 'modules/core/utils'
// Types
import { ResizeCallbackData } from 'react-resizable'
// context
import { VideoPlayerContext } from 'modules/video-player/context'
import { ComponentInfoContext } from 'modules/generic/context'
// Constants
import {
  LEFT_OFFSET_PERCENTAGE,
  RIGHT_OFFSET_PERCENTAGE,
  telestrationIcons,
} from 'modules/telestration/constants'
import { theme } from 'theme'

// TODO: It would be a good idea to split this component
export const Telestration = () => {
  const player = useAnalyserPlayer()
  const currentTimestamp = useAnalyserPlayerTimestamp({ precise: true })

  const { componentId } = useContext(ComponentInfoContext)
  const dispatch = useReduxDispatch()
  const { activeGroup, activeShape } = useReduxSelector(state =>
    selectTelestrationActiveData(state, componentId)
  )
  const { playerId } = useContext(VideoPlayerContext)
  const mediaLocator = useReduxSelector(state =>
    selectMediaLocator(state, playerId ?? '')
  )
  const zoom = useReduxSelector(state => selectZoom(state, componentId))

  const clipPlayTrackRef = useRef<HTMLDivElement>(null)
  const { width: clipPlayTrackWidth } = useResizeObserver(clipPlayTrackRef)

  const telestrationGroups = useTelestrationGroupsList({
    media_locator_id: mediaLocator?.id,
    expand: ['telestrations'],
    sort_by: 'created_at',
  })

  const activeTelestrationGroup = useMemo(() => {
    return telestrationGroups.data?.results.find(
      group => group.id === activeGroup
    )
  }, [telestrationGroups, activeGroup])

  const { mutate: telestrationGroupCreate } = useTelestrationGroupCreate()
  const { mutate: telestrationGroupUpdate } = useTelestrationGroupUpdate()
  const { mutate: telestrationGroupDelete } = useTelestrationGroupDelete()
  const { mutate: telestrationUpdate } = useTelestrationUpdate()
  const { mutate: telestrationGroupBulkUpdate } =
    useTelestrationGroupBulkUpdate()

  const componentCache = useReduxSelector(state =>
    selectLayoutCacheById(state, componentId)
  )

  const frames = useFrameList({
    parent_media_locator_id: mediaLocator?.id ?? '',
    expand: ['media_locator', 'parent_media_locator'],
  })

  const [editingGroup, setEditingGroup] = useState<string | null>(null)

  const trackWrapperRef = useRef<HTMLDivElement>(null)
  const scrollInnerRef = useRef<HTMLDivElement>(null)
  const groupContainerRef = useRef<HTMLDivElement>(null)
  const timelineRef = useRef<HTMLDivElement>(null)

  const { width: groupContainerWidth, height: groupContainerHeight } =
    useResizeObserver(trackWrapperRef)

  const offsetSize = Math.round(groupContainerWidth * LEFT_OFFSET_PERCENTAGE)
  const rightOffsetSize = Math.round(
    groupContainerWidth * RIGHT_OFFSET_PERCENTAGE
  )
  const eventDuration = usePreciseDuration(player)

  useEffect(() => {
    const scrollContainer = scrollInnerRef.current
    const timeline = timelineRef.current
    if (!scrollContainer || !timeline) return

    const scrollOffset = (currentTimestamp * PX_PER_SECOND) / zoom

    scrollContainer.scrollLeft = scrollOffset
    timeline.scrollLeft = scrollOffset
  }, [currentTimestamp, zoom, dispatch])

  useEffect(() => {
    const timeDifference = 0.3
    const currentFrame = frames.data?.results.find(
      frame =>
        frame.start_time / 1000 <= (player?.currentTime ?? 0) &&
        (player?.currentTime ?? 0) - frame.start_time / 1000 <= timeDifference
    )

    if (
      componentCache?.autoFrameEnabled &&
      currentFrame &&
      currentFrame.media_locator &&
      player &&
      !player.paused &&
      !('mockedPlayer' in player)
    ) {
      // We use local storage as it supposed to be a value that would remove in a short time
      // we need to add a delay to the time, so we won't play the same frame twice
      localStorage.setItem(
        'returnTime',
        JSON.stringify({
          time: currentFrame.start_time / 1000 + timeDifference + 0.05,
          url: currentFrame.parent_media_locator?.url,
        })
      )
      dispatch(
        setMediaItem({
          mediaLocator: currentFrame.media_locator,
          activeVideoItemId: currentFrame.id,
          activeVideoItemType: 'frame',
          id: playerId ?? '',
        })
      )
    }
  }, [frames, dispatch, playerId, player, componentCache?.autoFrameEnabled])

  useEffect(() => {
    if (componentId) {
      dispatch(
        setTelestration({
          id: componentId,
          tool: null,
          zoom: 1.3,
          color: theme.palette.secondary.main,
        })
      )
    }

    return () => {
      dispatch(
        removeTelestration({
          id: componentId,
        })
      )
    }
  }, [dispatch, componentId])

  useEffect(() => {
    if (
      !telestrationGroups.isLoading &&
      telestrationGroups.isSuccess &&
      telestrationGroups.data?.results?.length > 0
    ) {
      const isActiveGroupInGroups = telestrationGroups.data.results.some(
        group => group.id === activeGroup
      )
      if (!isActiveGroupInGroups) {
        const firstGroup = telestrationGroups.data.results[0]
        dispatch(
          setActiveGroup({
            id: componentId,
            group: firstGroup.id,
          })
        )
      }
    }
    if (
      !telestrationGroups.isLoading &&
      telestrationGroups.isSuccess &&
      telestrationGroups.data?.results?.length === 0
    ) {
      dispatch(
        setActiveGroup({
          id: componentId,
          group: undefined,
        })
      )
    }
  }, [
    activeGroup,
    dispatch,
    telestrationGroups.isLoading,
    telestrationGroups.isSuccess,
    telestrationGroups.data?.results,
    componentId,
  ])

  const handleSetActiveShape = useCallback(
    (shapeUuid: string | null) =>
      dispatch(
        setActiveShape({
          id: componentId,
          shape: shapeUuid,
        })
      ),
    [dispatch, componentId]
  )

  const handleTrackScroll = useCallback(
    ({ external }: ScrollEvent) => {
      if (!timelineRef.current || !scrollInnerRef.current) return

      timelineRef.current.scrollLeft = scrollInnerRef.current.scrollLeft

      if (!external) {
        const timelineTimestamp = translateScrollToTimestamp(
          timelineRef.current.scrollLeft,
          PX_PER_SECOND,
          zoom
        )

        if (player) player.currentTime = timelineTimestamp
      }
    },
    [player, zoom]
  )

  const handleEndTrackScroll = useCallback(
    ({ external }: ScrollEvent) => {
      if (!timelineRef.current || !scrollInnerRef.current) return

      timelineRef.current.scrollLeft = scrollInnerRef.current.scrollLeft

      if (!external) {
        const timelineTimestamp = translateScrollToTimestamp(
          timelineRef.current.scrollLeft,
          PX_PER_SECOND,
          zoom
        )

        if (player) player.currentTime = timelineTimestamp
      }
    },
    [player, zoom]
  )

  const handleSetActiveGroup = useCallback(
    (uuid: string) => {
      setEditingGroup(null)

      dispatch(
        setActiveGroup({
          id: componentId,
          group: uuid,
        })
      )
    },
    [dispatch, componentId]
  )

  const handleSetEditGroup = useCallback(
    (uuid: string) => {
      setEditingGroup(uuid)

      dispatch(
        setActiveGroup({
          id: componentId,
          group: uuid,
        })
      )
    },
    [dispatch, componentId]
  )

  const handleGroupLockToggle = useCallback(
    (uuid: string, lock: boolean) => {
      telestrationGroupUpdate({
        id: uuid,
        params: { is_locked: lock },
      })
    },
    [telestrationGroupUpdate]
  )

  const handleGroupVisibilityToggle = useCallback(
    (uuid: string, visibility: boolean) => {
      telestrationGroupUpdate({
        id: uuid,
        params: { is_visible: visibility },
      })
    },
    [telestrationGroupUpdate]
  )

  const handleAddNewGroup = useCallback(() => {
    if (mediaLocator?.id) {
      const number = telestrationGroups.data?.results?.length ?? 0
      telestrationGroupCreate(
        {
          name: `Group ${number + 1}`,
          media_locator_id: mediaLocator.id,
          is_visible: true,
          is_locked: false,
        },
        {
          onSuccess: result => {
            if (number === 0) {
              dispatch(
                setActiveGroup({
                  id: componentId,
                  group: result.id,
                })
              )
            }
          },
        }
      )
    }
  }, [
    dispatch,
    telestrationGroupCreate,
    mediaLocator?.id,
    telestrationGroups.data,
    componentId,
  ])

  const handleRemoveGroup = useCallback(
    (uuid: string) => {
      telestrationGroupDelete(uuid, {
        onSuccess: () => {
          if (activeGroup === uuid) {
            dispatch(
              setActiveGroup({
                id: componentId,
                group: undefined,
              })
            )
          }
        },
      })
    },
    [telestrationGroupDelete, activeGroup, dispatch, componentId]
  )

  const allGroupsLocked = useMemo(
    () =>
      telestrationGroups?.data?.results?.every(({ is_locked }) => is_locked),
    [telestrationGroups]
  )

  const allGroupsVisible = useMemo(
    () =>
      telestrationGroups?.data?.results?.every(({ is_visible }) => is_visible),
    [telestrationGroups]
  )

  const handleUpdateLockStatusAll = useCallback(
    () =>
      telestrationGroupBulkUpdate(
        telestrationGroups.data?.results.map(group => ({
          id: group.id,
          is_locked: !allGroupsLocked,
        })) ?? []
      ),
    [telestrationGroups.data, telestrationGroupBulkUpdate, allGroupsLocked]
  )

  const handleUpdateVisibilityStatus = useCallback(
    () =>
      telestrationGroupBulkUpdate(
        telestrationGroups.data?.results.map(group => ({
          id: group.id,
          is_visible: !allGroupsVisible,
        })) ?? []
      ),
    [telestrationGroups.data, telestrationGroupBulkUpdate, allGroupsVisible]
  )

  const handleSaveGroupName = useCallback(
    (newGroupName: string) => {
      if (editingGroup) {
        telestrationGroupUpdate({
          id: editingGroup,
          params: { name: newGroupName },
        })

        setEditingGroup(null)
      }
    },
    [editingGroup, telestrationGroupUpdate]
  )

  const widthPerSecond = useMemo(() => {
    if (player?.duration && player?.duration !== 0 && clipPlayTrackWidth) {
      return clipPlayTrackWidth / player.duration
    }
    return 1
  }, [player?.duration, clipPlayTrackWidth])

  const handleDragStop = useCallback(
    (startTime: number, duration: number, shapeUuid: string) =>
      (e: DraggableEvent, data: DraggableData) => {
        const newStartTime = startTime + (data.x / PX_PER_SECOND) * zoom

        const minTime = 0
        const maxTime = eventDuration - duration

        telestrationUpdate({
          id: shapeUuid,
          params: {
            start_time: Math.max(minTime, Math.min(maxTime, newStartTime)),
          },
        })
      },
    [eventDuration, telestrationUpdate, zoom]
  )

  const handleResizeStop = useCallback(
    (startTime: number, shapeUuid: string) =>
      (e: SyntheticEvent, data: ResizeCallbackData) => {
        const maxDuration = eventDuration - startTime

        telestrationUpdate({
          id: shapeUuid,
          params: {
            duration:
              Math.min(data.size.width / PX_PER_SECOND, maxDuration) * zoom,
          },
        })
      },
    [eventDuration, telestrationUpdate, zoom]
  )

  const handleSeek = useCallback(
    (time: number | undefined) => () => {
      if (time === undefined) return

      if (player) player.currentTime = time
    },
    [player]
  )

  return (
    <Root>
      <TelestrationToolbar />
      <RootContainer>
        <TimeContainer>
          <EventDisplayTime>
            {convertSecondsToTime(currentTimestamp)}
          </EventDisplayTime>
          <Timeline
            ref={timelineRef}
            duration={eventDuration}
            notchInterval={2}
            notchDuration={2}
            zoom={zoom}
            offset={offsetSize}
            rightOffset={rightOffsetSize}
            frames={frames.data?.results ?? []}
          />
        </TimeContainer>
        <GroupContainer ref={groupContainerRef}>
          <GroupListContainer>
            {telestrationGroups.data?.results?.map(
              ({ name, id, is_locked, is_visible }) => (
                <GroupMetaContainer
                  key={id}
                  active={id === activeGroup}
                  draggable={false}
                >
                  <GroupMetaNameContainer>
                    {id === editingGroup ? (
                      <InputEdit
                        variant='small'
                        defaultValue={name}
                        onAccept={handleSaveGroupName}
                        onCancel={() => setEditingGroup(null)}
                      />
                    ) : (
                      <>
                        <IconButton
                          name='pencil'
                          onClick={() => handleSetEditGroup(id)}
                        />
                        <GroupMetaNameText
                          onClick={() => handleSetActiveGroup(id)}
                        >
                          {name}
                        </GroupMetaNameText>
                      </>
                    )}
                  </GroupMetaNameContainer>
                  {id !== editingGroup && (
                    <GroupMetaOptionContainer>
                      <IconButton
                        name='delete'
                        onClick={() => handleRemoveGroup(id)}
                      />
                      <IconButton
                        name={is_locked ? 'lock' : 'unlock'}
                        onClick={() => handleGroupLockToggle(id, !is_locked)}
                      />
                      <IconButton
                        name='eye'
                        filter={is_visible ? 'brightness(2)' : ''}
                        onClick={() =>
                          handleGroupVisibilityToggle(id, !is_visible)
                        }
                      />
                    </GroupMetaOptionContainer>
                  )}
                  <OverComponentSkeleton hidden={!id.includes('draft')} />
                </GroupMetaContainer>
              )
            )}
            <Divider />
          </GroupListContainer>

          <ScrollWrapper ref={trackWrapperRef}>
            <ScrollContainer
              vertical={false}
              onClick={() => handleSetActiveShape(null)}
              onScroll={handleTrackScroll}
              onEndScroll={handleEndTrackScroll}
              innerRef={scrollInnerRef}
              ignoreElements='[data-disable-drag-scroll]'
              style={{ position: 'relative', height: '100%' }}
            >
              <PlaybackHead offset={offsetSize} height={groupContainerHeight} />
              <GroupTracksContainer
                duration={eventDuration * PX_PER_SECOND || 0}
                offset={offsetSize}
                rightOffset={rightOffsetSize}
                zoom={zoom}
              >
                {telestrationGroups.data?.results?.map(
                  ({ telestrations, id }) => (
                    <GroupTrack key={id}>
                      {telestrations?.map(
                        (
                          {
                            telestration_tool,
                            data,
                            start_time,
                            duration,
                            id: shapeUuid,
                          },
                          index
                        ) => (
                          <DraggableWrapper
                            data-disable-drag-scroll
                            key={shapeUuid}
                            active={shapeUuid === activeShape}
                            className='draggable-wrapper-telestration'
                          >
                            <Draggable
                              axis='x'
                              position={{ x: 0, y: 0 }}
                              cancel={'.react-resizable-handle'}
                              onStart={() => {
                                handleSetActiveGroup(id)
                                handleSetActiveShape(shapeUuid)
                              }}
                              onStop={handleDragStop(
                                start_time,
                                duration,
                                shapeUuid
                              )}
                            >
                              <GroupTrackShapeResizable
                                axis='x'
                                // Sets handle to be on the east side of the shape
                                resizeHandles={['e']}
                                width={(duration * PX_PER_SECOND) / zoom}
                                height={TELESTRATION_CELL_HEIGHT}
                                startTime={(start_time * PX_PER_SECOND) / zoom}
                                onResizeStop={handleResizeStop(
                                  start_time,
                                  shapeUuid
                                )}
                                onResizeStart={() => {
                                  handleSetActiveGroup(id)
                                  handleSetActiveShape(shapeUuid)
                                }}
                                minConstraints={[
                                  TELESTRATION_GROUP_HEIGHT,
                                  TELESTRATION_CELL_HEIGHT,
                                ]}
                                maxConstraints={[
                                  Infinity,
                                  TELESTRATION_CELL_HEIGHT,
                                ]}
                              >
                                <GroupTrackShape
                                  key={index}
                                  data-disable-drag-scroll
                                  active={shapeUuid === activeShape}
                                  color={
                                    // @ts-ignore
                                    data.stroke ??
                                    stringToHex(telestration_tool)
                                  }
                                >
                                  <Icon
                                    name={telestrationIcons[telestration_tool]}
                                  />
                                </GroupTrackShape>
                              </GroupTrackShapeResizable>
                            </Draggable>
                          </DraggableWrapper>
                        )
                      )}
                    </GroupTrack>
                  )
                )}
              </GroupTracksContainer>
            </ScrollContainer>
          </ScrollWrapper>
        </GroupContainer>
        <GroupActionsFullContainer>
          <GroupActionsContainer>
            <Box flex={1}>{/* <IconButton name='options' /> */}</Box>
            <IconButton name='layers' />
            <IconButton name='plus' onClick={handleAddNewGroup} />
            <IconButton
              onClick={handleUpdateLockStatusAll}
              name={allGroupsLocked ? 'lock' : 'unlock'}
            />
            <IconButton
              onClick={handleUpdateVisibilityStatus}
              name='eye'
              filter={allGroupsVisible ? 'brightness(2)' : ''}
            />
          </GroupActionsContainer>
          <ClipTimeLineWrapper ref={clipPlayTrackRef}>
            {activeTelestrationGroup?.telestrations?.map(telestration => (
              <TelestrationElement
                onClick={handleSeek(telestration.start_time)}
                durationWidth={telestration.duration * widthPerSecond}
                left={telestration.start_time * widthPerSecond}
                key={telestration.id}
                // @ts-ignore
                color={telestration.data.stroke ?? ''}
              />
            ))}
            <Track />
            <TrackSecondLine
              leftPosition={currentTimestamp * widthPerSecond ?? 0}
            />
          </ClipTimeLineWrapper>
        </GroupActionsFullContainer>
      </RootContainer>
    </Root>
  )
}
