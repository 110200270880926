import { createContext, FC, useCallback, useState } from 'react'

import {
  ISnackbarContext,
  ISnackbarContextProvider,
  SnackBar,
} from './SnackbarContext.interface'

export const SnackbarContext = createContext<ISnackbarContext>({
  snackbar: {
    message: '',
    type: 'info',
  },
  open: false,
  showSnackbar: () => {},
  handleClose: () => {},
})

export const SnackbarProvider: FC<ISnackbarContextProvider> = ({
  children,
}) => {
  const [open, setOpen] = useState(false)
  const [snackbar, setSnackbar] = useState<SnackBar>({
    message: '',
    type: 'info',
  })

  const handleClose = useCallback(() => setOpen(false), [])

  const showSnackbar = useCallback(({ message, type = 'info' }: SnackBar) => {
    setSnackbar({ message, type })
    setOpen(true)
  }, [])

  return (
    <SnackbarContext.Provider
      value={{ open, snackbar, handleClose, showSnackbar }}
    >
      {children}
    </SnackbarContext.Provider>
  )
}
