import { styled } from '@mui/material/styles'
import {
  css,
  // IconButton,
  Toolbar,
  AppBar as MuiAppBar,
  Avatar,
  Typography,
  Menu,
  MenuItem,
} from '@mui/material'
import { Icon } from 'modules/core/components/Icon'
import { IconButton } from 'modules/core/components/IconButton'
import { OptionsSelector } from 'modules/core/styled'

export const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<{ open?: boolean }>`
  z-index: ${({ theme }) => theme.zIndex.drawer - 1};
  transition: ${({ theme }) =>
    theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })};

  ${({ open, theme }) =>
    open &&
    css`
      margin-left: ${theme.drawerWidth};
      width: calc(100% - ${theme.drawerWidth}px);
      transition: ${theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      })};
    `}
`

export const StyledToolbar = styled(Toolbar)`
  background: ${({ theme }) => theme.palette.primary.main};
  align-items: stretch;
  justify-content: space-between;
  flex: 1;
`

export const TabsContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'open',
})<{ open: boolean }>`
  display: flex;
  flex: 1;
  position: relative;
  transition: all 0.3s;
  opacity: ${({ open }) => (open ? 1 : 0)};
  overflow: auto;
`

export const ToolbarActionsContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  column-gap: 20px;
  min-width: 15%;
`

export const UserContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'hidden',
})<{ hidden?: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 15px;
  opacity: 1;

  transition: opacity 0.3s;

  ${({ hidden }) =>
    hidden &&
    css`
      opacity: 0;
    `}
`

export const UserFullName = styled(Typography)`
  font-size: 1.15rem;
`

export const UserTierRoleWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  align-items: center;
`

export const UserTier = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.main};
  text-transform: capitalize;
`

export const UserRole = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.light};
  text-transform: capitalize;
`

export const UserAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.palette.primary.light};
  border: 2px solid ${({ theme }) => theme.palette.primary.contrastText};
`

export const UserAvatarWrapper = styled('div')`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(1)};
  cursor: pointer;
`

export const UserText = styled('div')`
  text-align: end;
`

export const Tab = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'active' && prop !== 'editable' && prop !== 'clickable',
})<{ active?: boolean; editable?: boolean; clickable?: boolean }>`
  position: relative;
  border-top: 7px solid
    ${({ theme, active }) =>
      active ? theme.palette.secondary.main : theme.palette.primary.light};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  display: flex;
  min-width: 115px;
  margin-right: ${({ theme }) => theme.spacing(2.5)};
  padding: 0 ${({ theme }) => theme.spacing(2)};
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  ${({ editable }) =>
    editable &&
    css`
      & .MuiButtonBase-root {
        display: none;
      }

      &:hover .MuiButtonBase-root {
        display: inline-flex;
      }
    `}
`

export const CloseIconWrapper = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'hidden',
})<{ hidden?: boolean }>`
  position: absolute;
  right: 0;
  top: 6px;
  padding: 4px;

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`

export const EditIconWrapper = styled(CloseIconWrapper)`
  left: 0;
  right: unset;
`

export const TabIcon = styled(Icon)`
  height: 8px;
  width: 8px;
`

export const Divider = styled('div')`
  height: 30px;
  border-right: 1px solid ${({ theme }) => theme.palette.primary.light};
`

export const AddTabWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(1.75)};
`

export const AnimatedChevronIcon = styled(Icon, {
  shouldForwardProp: prop => prop !== 'chevronUp',
})<{ chevronUp?: boolean }>`
  transition: all 0.3s;
  transform: ${({ chevronUp }) => (chevronUp ? 'rotate(0)' : 'rotate(180deg)')};
  height: 10px;
  width: 10px;
`

export const UserMenu = styled(Menu)`
  transform: translateX(${({ theme }) => theme.spacing(2)});

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    transform: translateX(${({ theme }) => theme.spacing(3)});
  }
  & .MuiList-root {
    padding: 0;
    min-width: 192px;
  }
`

export const UserMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1.5, 2)};

  transition: all 0.3s;
  background-color: ${({ theme }) => theme.palette.darkest};
  color: ${({ theme }) => theme.palette.primary.light};

  &:hover {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background-color: ${({ theme }) => theme.palette.background.default};
  }

  &:focus {
    background-color: ${({ theme }) => theme.palette.darkest};
    color: ${({ theme }) => theme.palette.primary.light};

    &:hover {
      color: ${({ theme }) => theme.palette.primary.contrastText};
      background-color: ${({ theme }) => theme.palette.background.default};
    }
  }
`

export const UserMenuText = styled(Typography)`
  font-size: 16px;
`

export const LanguageWrapper = styled('div')`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 20px;
`

export const LanguagesList = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 20px;
  column-gap: 10px;
`

export const LanguageIcon = styled('img')`
  border-radius: 50%;
  aspect-ratio: 1/1;
  height: 20px;
  cursor: pointer;
`

export const OrganizationWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const OrganizationOptionsSelector = styled(OptionsSelector)`
  border: 1px solid ${({ theme }) => theme.palette.secondary.light};
  border-radius: 5px;
`
