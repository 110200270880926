import { Link } from '@mui/material'
import { styled } from '@mui/system'

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
`

export const DefaultStyledLink = styled(StyledLink)`
  text-transform: uppercase;
` as typeof Link
export const SocialStyledLink = styled(StyledLink)`
  text-transform: lowercase;
` as typeof Link
