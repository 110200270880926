import InstagramSrc from 'modules/landing/assets/socials/instagram.svg'
import TwitterSrc from 'modules/landing/assets/socials/twitter.svg'
import FacebookSrc from 'modules/landing/assets/socials/facebook.svg'

// TODO: Use Theme and not const
export const BREAKPOINTS = {
  mobile: 480,
  tablet: 1000,
  desktop: 1300,
  bigDesktop: 1600,
}

export const LINKS = ['about', 'features', 'pricing', 'contact']

export const SOCIAL_LINKS = [
  {
    name: 'instagram',
    img: InstagramSrc,
    href: 'https://www.instagram.com/sporttotal.tv/',
  },
  {
    name: 'twitter',
    img: TwitterSrc,
    href: 'https://twitter.com/sporttotal_tv',
  },
  {
    name: 'facebook',
    img: FacebookSrc,
    href: 'https://www.facebook.com/sporttotal.tv/',
  },
]

export const FOOTER = [
  { name: 'privacy', href: '' },
  { name: 'cookie', href: '' },
  { name: 'terms', href: '' },
]

export const INITIAL_VALUE = {
  email: '',
  message: '',
}

export const VIDEO_SRC =
  'https://mam-uploads.azureedge.net/upload/abdul/video/efe38c49-b75f-4482-8d5c-af99efe5b4c0/sporttotal-commercial-updated.mp4'
