import { TelestrationTool } from 'modules/telestration/types'

export const getToolIcon: Record<TelestrationTool, string> = {
  LINE: 'crosshair',
  RECT: 'crosshair',
  CIRCLE: 'crosshair',
  ARROW: 'crosshair',
  TEXT: 'text',
  PLAYER_SELECTION: 'crosshair',
  HIGHLIGHT: 'crosshair',
  CURVED_LINE: 'crosshair',
  MULTI_LINE: 'crosshair',
}
