import * as yup from 'yup'

export const requestSchema = yup.object().shape({
  name: yup.string().required('pages:admin.modal.error.name'),
  surname: yup.string().required('pages:admin.modal.error.surname'),
  email: yup
    .string()
    .required('pages:admin.modal.error.email.required')
    .email('pages:admin.modal.error.email.invalid'),
  role: yup
    .string()
    .notOneOf([''], 'pages:admin.modal.error.role')
    .required('pages:admin.modal.error.role'),
  team: yup
    .string()
    .nullable()
    .when('sport', (sport: null | string, schema) =>
      sport === null || sport === ''
        ? schema
        : schema.required('pages:admin.modal.error.team')
    ),
  sport: yup.string().nullable(),
})
