import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { useQueryRetry } from 'modules/core/hooks'
import { User } from '@sporttotal-tv/dip-coaching-client'
import { UseQueryOptions } from 'modules/core/types'
import { UserCacheData } from 'modules/user/types'

export const USER_ADMIN_USER_RETRIEVE_CACHE_KEY = 'admin-user-retrieve'

export const useAdminUser = (
  id: string,
  queryOptions?: UseQueryOptions<User & { data: UserCacheData }, string[]>
) => {
  const handleQueryRetry = useQueryRetry({ maxRetries: 0 })

  return useQuery(
    [USER_ADMIN_USER_RETRIEVE_CACHE_KEY, id],
    () => ApiService.user.adminUserRetrieve(id),
    {
      ...queryOptions,
      retry: handleQueryRetry,
    }
  )
}
