import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
// components
import { ActionButton, ModalDialog, TextInput } from 'modules/core/components'
// hooks
import { useAssetUpdate } from 'modules/asset/hooks'
import { useSnackbar, useTagControls } from 'modules/core/hooks'
import { useTError } from 'modules/core/i18n/hooks'
// schemas
import { assetEditSchema } from 'modules/asset/schemas'

import { FormWrapper, TagItem, TagsListWrapper } from './ClipEditModal.styled'
import { ClipEditModalProps, ClipEditFormData } from './ClipEditModal.interface'

export const ClipEditModal: FC<ClipEditModalProps> = ({
  clip,
  open,
  onClose,
}) => {
  const { tError } = useTError('components', { keyPrefix: 'asset' })
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const assetUpdate = useAssetUpdate()

  const { tagValue, tags, setTagValue, handleTagRemove, handleKeyPress } =
    useTagControls(clip.tags)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ClipEditFormData>({
    resolver: yupResolver(assetEditSchema),
    defaultValues: {
      title: clip.title,
      description: clip.description,
    },
  })

  const onSubmit = (data: ClipEditFormData) => {
    const tagsArray = tagValue.length > 0 ? tagValue.split(' ') : []
    const allTags = new Set([...tagsArray, ...Array.from(tags)])

    assetUpdate.mutate(
      {
        id: clip.id,
        params: {
          title: data.title,
          description: data.description,
          tags: Array.from(allTags),
        },
      },
      {
        onSuccess: () => {
          snackbar({
            message: t('components:asset.notification.updateSuccess'),
          })
        },
      }
    )
  }

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={t('components:asset.form.clip.update.formTitle')}
    >
      <div>
        <FormWrapper>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              label={t('components:asset.form.clip.update.title')}
              defaultValue={clip.title}
              errorMessage={tError(errors.title?.message)}
              inputProps={{ 'aria-label': 'title' }}
              {...register('title')}
            />
            <TextInput
              label={t('components:asset.form.clip.update.description')}
              defaultValue={clip.description}
              errorMessage={tError(errors.description?.message)}
              inputProps={{ 'aria-label': 'description' }}
              {...register('description')}
            />
            <TextInput
              label={t('components:asset.form.clip.update.tags')}
              value={tagValue}
              errorMessage={tError(errors.tags?.message)}
              onChange={e => setTagValue(e.target.value)}
              inputProps={{ 'aria-label': 'tags' }}
              onKeyDown={handleKeyPress}
            />
            <TagsListWrapper>
              {Array.from(tags)
                .reverse()
                .map((tag, index) => (
                  <TagItem key={index} onClick={handleTagRemove(tag)}>
                    {tag}
                  </TagItem>
                ))}
            </TagsListWrapper>

            <ActionButton
              position='end'
              type='submit'
              text={t('components:asset.form.clip.update.save')}
              icon='save'
            />
          </form>
        </FormWrapper>
      </div>
    </ModalDialog>
  )
}
