import { PlayerStats } from '@sporttotal-tv/dip-coaching-client'
import { teamPlayerMockup } from './teamPlayer.mockup'

export const teamPlayerStatMockup: PlayerStats = {
  id: '1',
  goals: 1,
  shots: 1,
  shots_on_target: 1,
  penalties_scored: 1,
  penalties_taken: 1,
  passes_completed: 1,
  passes_attempt: 1,
  mistake_leading_to_goal: 1,
  cross_attempted: 1,
  cross_completed: 1,
  red_card: 1,
  yellow_card: 1,
  player_id: 'player_id',
  player: teamPlayerMockup,
  created_at: new Date().getTime() / 1000,
  updated_at: new Date().getTime() / 1000,
}
