import { ApiService } from 'services/Api.service'
import { createMutationHook } from 'modules/core/api/hooks/utils'
import { ADMIN_ORGANIZATION_COMPETITION_LIST_CACHE_KEY } from './useList'

export const [useAdminOrganizationCompetitionCreate] = createMutationHook(
  ApiService.admin.organization.competition.create,
  queryClient => ({
    onSuccess: () =>
      queryClient.invalidateQueries(
        ADMIN_ORGANIZATION_COMPETITION_LIST_CACHE_KEY
      ),
  })
)
