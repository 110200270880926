import { Box, styled } from '@mui/material'

export const Root = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  // TODO should be in LAYOUT_PALETTE_WIDTH
  max-width: calc(100% - 60px);
  overflow-x: hidden;
`
