import { Layout, Layouts } from 'react-grid-layout'
import _ from 'lodash'
import {
  BREAKPOINTS,
  LAYOUT_CONFIGS,
  LayoutIndex,
} from 'modules/layout/constants'
import { LayoutConfigBreakpoints } from 'modules/layout/types'

export const convertUserTabDataToCurrentConfig = (
  userTabData: Layouts | undefined,
  layoutTypes: Record<string, LayoutIndex>
): Layouts => {
  return Object.entries(userTabData ?? {})?.reduce<Layouts>(
    (acc, [key, value]) => {
      return {
        ...acc,
        [key]: value.map(item => {
          const layoutType = layoutTypes[item.i]
          if (!layoutType) return item
          const defaultLayoutConfig = _.pick(
            LAYOUT_CONFIGS[layoutType][key as LayoutConfigBreakpoints],
            ['minW', 'maxW', 'minH', 'maxH']
          )
          return { ...item, ...defaultLayoutConfig }
        }),
      }
    },
    {}
  )
}

export const convertToLayoutWithBreakpoints = (
  layouts: Layouts,
  id: string,
  type: LayoutIndex,
  breakpointToChange: LayoutConfigBreakpoints | undefined,
  dataToChange: Layout[]
) => {
  return {
    ...layouts,
    ...BREAKPOINTS.reduce((acc, breakpoint) => {
      const itemsData = layouts?.[breakpoint] || []

      if (breakpoint === breakpointToChange) {
        return {
          ...acc,
          [breakpoint]: dataToChange,
        }
      }

      return {
        ...acc,
        [breakpoint]: [
          ...itemsData,
          ...(itemsData.find(item => item.i === id)
            ? []
            : [{ ...LAYOUT_CONFIGS[type]?.[breakpoint], i: id }]),
        ],
      }
    }, {}),
  }
}
