import { ApiService } from 'services/Api.service'
import { createMutationHook } from 'modules/core/api/hooks/utils'
import { ADMIN_USER_ACCESS_LIST_CACHE_KEY } from './useAdminUserAccessList'

export const [useAdminUserAccessUpdate, ADMIN_USER_ACCESS_UPDATE_CACHE_KEY] =
  createMutationHook(ApiService.admin.access.user.update, queryClient => ({
    onSuccess: () => {
      queryClient.invalidateQueries(ADMIN_USER_ACCESS_LIST_CACHE_KEY)
    },
  }))
