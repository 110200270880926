export const VOLLEYBALL_EVENTS = {
  SERVE: 1001,
  SERVE_RETURN: 1002,
  SERVE_ROTATION: 1003,
  HIT_ON_THE_PITCH: 1004,
  HIT_OUT_OF_THE_PITCH: 1005,
  ONE_PASS_AND_HIT: 1006,
  TWO_PASSES_AND_HIT: 1007,
  BLOCK: 1008,
  NET_TOUCH_BY_THE_BALL: 1009,
  NET_TOUCH_BY_THE_PLAYER: 1010,
  SERVE_FOOT_FOUL: 1011,
  END_OF_1ST_SET: 1012,
  END_OF_2ND_SET: 1013,
  END_OF_3RD_SET: 1014,
  END_OF_4TH_SET: 1015,
  END_OF_5TH_SET: 1016,
  HIGHLIGHT: 1017,
  SCORE: 1018,
  SUBSTITUTION: 1019,
  LIBERO_IN: 1020,
  LIBERO_OUT: 1021,
  LIBERO_SWITCH: 1022,
  CHANGE_SERVING: 1023,
  CROWD_CHEER: 1024,
  START_OF_GAME: 1025,
  END_OF_GAME: 1026,
  START_OF_1ST_SET: 1027,
  START_OF_2ND_SET: 1028,
  START_OF_3RD_SET: 1029,
  START_OF_4TH_SET: 1030,
  START_OF_5TH_SET: 1031,
  NO_ACTION: 1032,
} as const
