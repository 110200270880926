import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
// Hooks
import { useOrganizationAdminUserList } from 'modules/organization-admin/hooks'
// Components
import { LoaderContainer } from 'modules/core/components'
import { OrganizationUserList } from 'modules/organization/components'

import { Root, Main, Wrapper } from './OrganizationUsers.styled'

export const OrganizationUsers = () => {
  const { id } = useParams()

  const organizationUsers = useOrganizationAdminUserList({
    group: id ?? '',
    expand: ['user'],
  })

  const users = useMemo(
    () =>
      organizationUsers.data?.results.map(({ id, user }) => ({
        ...user!,
        organizationUserId: id,
      })) || [],
    [organizationUsers.data?.results]
  )

  return (
    <Root>
      <Main>
        <Wrapper>
          <LoaderContainer isLoading={organizationUsers.isLoading}>
            {organizationUsers && (
              <OrganizationUserList organizationId={id ?? ''} users={users} />
            )}
          </LoaderContainer>
        </Wrapper>
      </Main>
    </Root>
  )
}
