import { Player } from '@sporttotal-tv/dip-coaching-client'

type VolleyballPositions = Extract<
  Player['normal_position'],
  | 'SETTER'
  | 'LIBERO'
  | 'MIDDLE-HITTER'
  | 'OPPOSITE-HITTER'
  | 'OUTSIDE-HITTER'
  | 'MIDDLE-BLOCKER'
>

export const volleyballPositionPlacements: Record<
  VolleyballPositions,
  { left: string; top: string }
> = {
  SETTER: { left: '50%', top: '85%' },
  LIBERO: { left: '50%', top: '74%' },
  'MIDDLE-HITTER': { left: '50%', top: '62%' },
  'OPPOSITE-HITTER': { left: '50%', top: '50%' },
  'OUTSIDE-HITTER': { left: '50%', top: '38%' },
  'MIDDLE-BLOCKER': { left: '50%', top: '26%' },
}
