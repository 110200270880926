import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { Layouts } from 'react-grid-layout'
// utils
import { isValidPathName, convertStringToPathName } from 'modules/core/utils'
import { convertUserTabDataToCurrentConfig } from 'modules/layout/utils/converters'
// constants
import { EMPTY_LAYOUT, LayoutIndex } from 'modules/layout/constants'
// types
import {
  TabDto,
  UserLayoutTypesVariant,
  UserTabsVariant,
  UserTabsDataVariant,
} from 'modules/user/types'
// hooks
import { useUserStorage, useUserStorageUpdate } from 'modules/user/hooks'
import { useReduxDispatch, useReduxSelector } from 'modules/core/hooks'
// helpers
import { generateId } from 'modules/core/helpers/generateId'
// redux
import { selectLayoutTypes, setActiveTab } from 'modules/layout/redux'

interface UseTabStoreParams {
  tabsKey: UserTabsVariant
  tabsDataKey: UserTabsDataVariant
  layoutTypesKey: UserLayoutTypesVariant
  defaultTabPath: string
  defaultTabStore: TabDto[]
  defaultTabsData?: Record<string, Layouts>
  defaultLayoutTypes?: Record<string, LayoutIndex>
}

// TODO: better to move to redux and get data from there
export const useTabStore = ({
  tabsKey,
  tabsDataKey,
  layoutTypesKey,
  defaultTabPath,
  defaultTabStore,
  defaultTabsData = {},
  defaultLayoutTypes = {},
}: UseTabStoreParams) => {
  const userTabs = useUserStorage({ keys: [tabsKey] })
  const userTabsData = useUserStorage({ keys: [tabsDataKey] })
  const userStorageUpdate = useUserStorageUpdate()
  const layoutTypes = useReduxSelector(selectLayoutTypes)
  const [tabs, setTabs] = useState<TabDto[]>([])
  const [isDefaultSet, setIsDefaultSet] = useState<boolean>(false)
  const [isDefaultTabsSet, setIsDefaultTabsSet] = useState<boolean>(false)
  const dispatch = useReduxDispatch()

  useEffect(() => {
    if (
      !isDefaultSet &&
      !userTabs.isLoading &&
      (!userTabs.data?.[tabsKey] || userTabs.data?.[tabsKey]?.length === 0)
    ) {
      setIsDefaultSet(true)
      setTabs(defaultTabStore)
      userStorageUpdate.mutate({
        [tabsDataKey]: defaultTabsData,
        [tabsKey]: defaultTabStore,
        [layoutTypesKey]: defaultLayoutTypes,
      })
    }
  }, [
    isDefaultSet,
    userTabs.data,
    userTabs.isLoading,
    userStorageUpdate,
    defaultTabStore,
    defaultTabsData,
    defaultLayoutTypes,
    tabsKey,
    tabsDataKey,
    layoutTypesKey,
  ])

  useEffect(() => {
    const userTabsInfo = userTabs.data?.[tabsKey]
    if (userTabsInfo && !isDefaultTabsSet) {
      setIsDefaultTabsSet(true)
      setTabs(userTabsInfo)
    }
  }, [userTabs.data, isDefaultTabsSet, tabsKey])

  const addTab = useCallback(
    (tabName: string) => {
      const newId = generateId()
      let newPath = convertStringToPathName(tabName)
      const tabWithNewPath = _.find(tabs, { path: newPath })

      if (tabWithNewPath) {
        newPath = `${newPath}-${newId}`
      }

      const newTab = {
        id: newId,
        name: tabName,
        static: false,
        path: newPath,
      }

      userStorageUpdate.mutate({
        [tabsDataKey]: {
          ...userTabsData.data?.[tabsDataKey],
          [newPath]: EMPTY_LAYOUT,
        },
        [tabsKey]: [...tabs, newTab],
      })

      setTabs([...tabs, newTab])
    },
    [tabs, userStorageUpdate, userTabsData.data, tabsDataKey, tabsKey]
  )

  const removeTab = useCallback(
    (tabId: string, active?: boolean) => {
      const selectedTab = _.find(tabs, { id: tabId })
      if (!selectedTab || selectedTab.static === true) {
        return
      }

      const newData = _.omit(userTabsData.data?.[tabsDataKey], [
        selectedTab.path,
      ])

      const filteredTabs = tabs.filter(el => el.id !== tabId)

      userStorageUpdate.mutate({
        [tabsKey]: filteredTabs,
        [tabsDataKey]: newData,
      })

      setTabs(filteredTabs)

      if (active) {
        const updatedTabData = convertUserTabDataToCurrentConfig(
          userTabsData.data?.[tabsDataKey]?.[defaultTabPath],
          layoutTypes
        )

        dispatch(
          setActiveTab({
            tab: defaultTabPath,
            layoutsWithBreakpoints: updatedTabData ?? {},
          })
        )
      }
    },
    [
      tabs,
      userStorageUpdate,
      userTabsData.data,
      dispatch,
      layoutTypes,
      defaultTabPath,
      tabsDataKey,
      tabsKey,
    ]
  )

  const renameTab = useCallback(
    (tabId: string, tabName: string) => {
      const selectedTab = _.find(tabs, { id: tabId })
      if (
        !selectedTab ||
        selectedTab.static === true ||
        !isValidPathName(tabName)
      ) {
        return
      }

      let newPath = convertStringToPathName(tabName)
      const tabWithNewPath = _.find(tabs, { path: newPath })

      if (tabWithNewPath) {
        newPath = `${newPath}-${tabId}`
      }

      const oldPathData = _.pick(userTabsData.data?.[tabsDataKey], [
        selectedTab.path,
      ])

      const newData = _.omit(userTabsData.data?.[tabsDataKey], [
        selectedTab.path,
      ])

      const newTabs = tabs.map(tab => {
        return tab.id === tabId ? { ...tab, name: tabName, path: newPath } : tab
      })

      const updatedTabData = convertUserTabDataToCurrentConfig(
        oldPathData[selectedTab.path],
        layoutTypes
      )

      dispatch(
        setActiveTab({
          tab: newPath,
          layoutsWithBreakpoints: updatedTabData ?? {},
        })
      )

      userStorageUpdate.mutate({
        [tabsDataKey]: { ...newData, [newPath]: oldPathData[selectedTab.path] },
        [tabsKey]: newTabs,
      })

      setTabs(newTabs)

      return newPath
    },
    [
      tabs,
      userStorageUpdate,
      userTabsData.data,
      dispatch,
      layoutTypes,
      tabsDataKey,
      tabsKey,
    ]
  )

  const switchTab = useCallback(
    (path: string) => {
      const updatedTabData = convertUserTabDataToCurrentConfig(
        userTabsData.data?.[tabsDataKey]?.[path],
        layoutTypes
      )

      dispatch(
        setActiveTab({
          tab: path,
          layoutsWithBreakpoints: updatedTabData ?? {},
        })
      )
    },
    [userTabsData.data, dispatch, layoutTypes, tabsDataKey]
  )

  return { tabs, addTab, removeTab, renameTab, switchTab }
}
