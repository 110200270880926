import { useCallback, useMemo } from 'react'
import _ from 'lodash'
// hooks
import { useUserStorage, useUserStorageUpdate } from 'modules/user/hooks'
import { useUserCacheDelete } from 'modules/cache/hooks'
import { useReduxDispatch, useReduxSelector } from 'modules/core/hooks'
// redux
import {
  selectCurrentLayout,
  removeLayout,
  setLockStatus,
  selectLayoutKeys,
} from 'modules/layout/redux'
// utils
import {
  updateUserTabsDataWithRemovedItem,
  updateUserTabsDataWithLockingStatusItem,
} from 'modules/user/utils'
// constants
import { LayoutIndex } from 'modules/layout/constants'

export const useLayoutItemControl = (
  layoutId?: string,
  layoutIndex?: LayoutIndex
) => {
  const layouts = useReduxSelector(selectCurrentLayout)
  const { tabsDataKey, layoutTypesKey } = useReduxSelector(selectLayoutKeys)
  const dispatch = useReduxDispatch()

  const userTabsData = useUserStorage({ keys: [tabsDataKey] })
  const userLayoutTypes = useUserStorage({ keys: [layoutTypesKey] })
  const userStorageUpdate = useUserStorageUpdate()
  const userCacheDelete = useUserCacheDelete()

  const isLocked = useMemo(() => {
    const itemLayout = _.find(layouts, { i: layoutId })

    return itemLayout?.isDraggable === false
  }, [layouts, layoutId])

  const handleRemoveLayoutItem = useCallback(() => {
    if (!layoutId) return

    const { [layoutId]: _, ...rest } =
      userLayoutTypes.data?.[layoutTypesKey] ?? {}

    userStorageUpdate.mutate({
      [tabsDataKey]: updateUserTabsDataWithRemovedItem(
        userTabsData.data?.[tabsDataKey],
        layoutId
      ),
      [layoutTypesKey]: rest,
    })

    dispatch(removeLayout(layoutId))

    userCacheDelete.mutate([`${layoutIndex}-${layoutId}`])
    userCacheDelete.mutate([layoutId])
  }, [
    dispatch,
    layoutId,
    layoutIndex,
    userStorageUpdate,
    userCacheDelete,
    userTabsData.data,
    tabsDataKey,
    userLayoutTypes.data,
    layoutTypesKey,
  ])

  const handleLockingLayoutItem = useCallback(() => {
    const itemLayout = _.find(layouts, { i: layoutId })

    if (!itemLayout || !layoutId) {
      return
    }

    userStorageUpdate.mutate({
      [tabsDataKey]: updateUserTabsDataWithLockingStatusItem(
        userTabsData.data?.[tabsDataKey],
        layoutId,
        !(itemLayout.isDraggable ?? true)
      ),
    })

    dispatch(
      setLockStatus({
        layoutId,
        locked: !(itemLayout.isDraggable ?? true),
      })
    )
  }, [
    dispatch,
    layouts,
    layoutId,
    userStorageUpdate,
    userTabsData.data,
    tabsDataKey,
  ])

  return {
    isLocked,
    handleRemoveLayoutItem,
    handleLockingLayoutItem,
  }
}
