import { FC, ReactNode } from 'react'
// Components
import { AppBar } from 'modules/core/components'

import { Main } from './OrganizationAdminOverviewPageLayout.styled'
import { APP_BAR_TABS } from './constants'

export const OrganizationAdminOverviewPageLayout: FC<{
  children: ReactNode
}> = ({ children }) => (
  <>
    <AppBar tabs={APP_BAR_TABS} />
    <Main>{children}</Main>
  </>
)
