import { api } from 'modules/core/api'
import { transformApi } from 'modules/core/helpers'

import { AdminUserAssociationService } from './admin-user-association.service'
import { AdminUserCompetitionService } from './admin-user-competition.service'
import { AdminUserClubService } from './admin-user-club.service'
import { AdminUserTeamService } from './admin-user-team.service'

export const AdminUserService = {
  delete: transformApi(api.adminUsers.delete),

  association: AdminUserAssociationService,
  competition: AdminUserCompetitionService,
  club: AdminUserClubService,
  team: AdminUserTeamService,
}
