// Hooks
import { useMemo, useState, MouseEvent } from 'react'
import { useMatch } from 'modules/match/hooks'
import { useTranslation } from 'react-i18next'
// Components
import { Popover } from '@mui/material'
import { CameraPositionControl } from 'modules/camera/components'
import { Tooltip } from 'modules/core/styled'
import { Icon } from 'modules/core/components'
// Utils
import { isInternalMatch, isMatchLive } from 'modules/match/utils'

import { CameraControlWrapper } from './VideoPlayerToolbar.styled'

export const CameraPositionToolbarControl = () => {
  const { t } = useTranslation(['components'])
  const { match } = useMatch()

  const [manualControlsAnchor, setManualControlsAnchor] =
    useState<HTMLElement | null>(null)
  const isManualControlsOpen = Boolean(manualControlsAnchor)

  const isCurrentMatchLive = useMemo(() => isMatchLive(match), [match])
  const isScheduleMatch = useMemo(
    () => isInternalMatch(match?.system_ref ?? ''),
    [match]
  )

  const showCameraPositionControls = isCurrentMatchLive && isScheduleMatch

  const handleManualControlsOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setManualControlsAnchor(event.currentTarget)
  }

  if (!showCameraPositionControls) return null

  return (
    <CameraControlWrapper>
      <Tooltip
        title={t('components:playerControls.manualControl')}
        placement='top'
        arrow
      >
        <Icon name='panorama' onClick={handleManualControlsOpen} />
      </Tooltip>
      <Popover
        anchorEl={manualControlsAnchor}
        open={isManualControlsOpen}
        onClose={() => setManualControlsAnchor(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <CameraPositionControl />
      </Popover>
    </CameraControlWrapper>
  )
}
