import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useQueryClient } from 'react-query'
// Hooks
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAssetCreate } from 'modules/asset/hooks/useAssetCreate'
import {
  useAnalyserPlayer,
  useAnalyserPlayerTimestamp,
} from 'modules/analyser/hooks'
import {
  USER_CACHE_RETRIEVE_CACHE_KEY,
  useUserCacheCreate,
} from 'modules/cache/hooks'
import { useEventList } from 'modules/event/hooks'
import {
  useResizeObserver,
  useTagControls,
  useServerTranslatedProperty,
  useReduxSelector,
  useAnchor,
  useOutsideClick,
  useSnackbar,
} from 'modules/core/hooks'
import { useTError } from 'modules/core/i18n/hooks'
import { useMatch } from 'modules/match/hooks'
// Components
import { MenuItem, Tooltip } from '@mui/material'
import { TimeMaskInput, Icon } from 'modules/core/components'
// Schemas
import { assetSchema } from 'modules/asset/schemas'
// Context
import { VideoPlayerContext } from 'modules/video-player/context'
// Redux
import { selectMediaLocator } from 'modules/video-player/redux'
import { selectCurrentLayout, selectLayoutTypes } from 'modules/layout/redux'
// Constants
import {
  defaultIconName,
  eventIndexToIconDictionary,
} from 'modules/core/constants'
import { EventIndex } from 'modules/event/types'
import { LayoutIndex } from 'modules/layout/constants'
import { presetTags } from 'modules/asset/constants'
// Utils
import { yupResolver } from '@hookform/resolvers/yup'
import { convertSecondsToTime, convertTimeToSeconds } from 'modules/core/utils'

import GpsFixedIcon from '@mui/icons-material/GpsFixed'

// Styled
import {
  InputText,
  Label,
  Root,
  InputTextField,
  EventSelector,
  SaveButton,
  SaveIcon,
  ButtonWrapper,
  TagsListWrapper,
  LabelWrapper,
  TagItem,
  FullWidthFormControl,
  FluidWidthFormControl,
  GRID_TITLE_LABEL,
  GRID_TITLE_INPUT,
  GRID_DESCRIPTION_INPUT,
  GRID_TAGS_INPUT,
  GRID_DESCRIPTION_LABEL,
  GRID_END_INPUT,
  GRID_END_LABEL,
  GRID_START_INPUT,
  GRID_EVENT_INPUT,
  GRID_START_LABEL,
  GRID_TAGS_LABEL,
  GRID_EVENT_LABEL,
  GRID_TAGS_ITEMS,
  GRID_SAVE_BUTTON,
  SaveButtonText,
  StyledForm,
  EndAdornment,
  EventIcon,
  ErrorMessage,
  LabelItem,
  TagsPopper,
  TagsCategoryWrapper,
  TagsCategory,
  CategoryTagsList,
  TagsPopperBody,
  CategoryTagItem,
} from './ClipCreateForm.styled'
import {
  ClipCreateFormProps,
  ClipCreateFormData,
} from './ClipCreateForm.interface'
import { SportType } from 'modules/sport/types'

const tagListClassName = 'clip-create-form-tag-list'

export const ClipCreateForm: FC<ClipCreateFormProps> = ({ hidden }) => {
  const { playerId } = useContext(VideoPlayerContext)
  const { match } = useMatch()
  const activeMediaLocator = useReduxSelector(state =>
    selectMediaLocator(state, playerId ?? '')
  )
  const currentTimestamp = useAnalyserPlayerTimestamp()
  const player = useAnalyserPlayer()

  const layoutTypes = useReduxSelector(selectLayoutTypes)
  const layouts = useReduxSelector(selectCurrentLayout)

  const queryClient = useQueryClient()
  const snackbar = useSnackbar()

  const eventList = useEventList({ sport_id: match?.sport?.id })
  const { mutate: userCacheCreate } = useUserCacheCreate()
  const [startTime, setStartTime] = useState(convertSecondsToTime(0))
  const [endTime, setEndTime] = useState(convertSecondsToTime(0))
  const modalRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const onAssetCreationComplete = useCallback(
    () =>
      snackbar({
        type: 'success',
        message: t('components:asset.notification.clipCreatedRequestCompleted'),
      }),
    []
  )

  const createClip = useAssetCreate({
    onAssetCreationComplete,
  })

  const {
    anchor: popoverAnchor,
    isOpen: isPopoverOpen,
    handleOpen: handleOpenPopover,
    handleClose: handleClosePopover,
  } = useAnchor()

  const {
    tagValue,
    tags,
    setTagValue,
    handleResetTag,
    handleTagRemove,
    handleKeyPress,
    handleTagAdd,
  } = useTagControls()

  const { t } = useTranslation(['components', 'presetTags'])
  const tServer = useServerTranslatedProperty()

  const sportPresetTags = useMemo(
    () => (match?.sport ? presetTags[match?.sport?.name as SportType] : []),
    [match?.sport]
  )

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<ClipCreateFormData>({
    context: { maxTime: Math.floor(player?.duration ?? 0) },
    resolver: yupResolver(assetSchema),
    defaultValues: {
      event: '',
    },
  })
  const { tError } = useTError('components', { keyPrefix: 'asset' })

  const handleCopyCurrentTime = useCallback(
    (valueType: 'end' | 'start') => () => {
      // @ts-ignore
      setValue(valueType, convertSecondsToTime(currentTimestamp))
      if (valueType === 'start') {
        setStartTime(convertSecondsToTime(currentTimestamp))
      }
      if (valueType === 'end') {
        setEndTime(convertSecondsToTime(currentTimestamp))
      }
    },
    [setValue, currentTimestamp]
  )
  const { width: inputWidth } = useResizeObserver(wrapperRef)

  useOutsideClick<HTMLDivElement>(
    modalRef,
    handleClosePopover,
    wrapperRef,
    tagListClassName
  )

  useEffect(() => {
    // @ts-ignore
    setValue('start', startTime)
    clearErrors('start')
  }, [startTime, setValue, clearErrors])

  useEffect(() => {
    // @ts-ignore
    setValue('end', endTime)
    clearErrors('end')
  }, [endTime, setValue, clearErrors])

  // used to set clip selection on playback line
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      const startValue = convertTimeToSeconds(`${value.start}`, true)
      const endValue = convertTimeToSeconds(`${value.end}`, true)
      const differenceInTime = endValue - startValue
      const maxTime = Math.floor(player?.duration ?? 0)

      if (
        isNaN(startValue) ||
        isNaN(endValue) ||
        differenceInTime < 0 ||
        startValue > maxTime ||
        endValue > maxTime
      ) {
        return
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, setValue, player?.duration])

  const onSubmit = (data: ClipCreateFormData) => {
    if (!match?.id) return null
    if (!activeMediaLocator) return null

    const tagsArray = tagValue.length > 0 ? tagValue.split(' ') : []
    const allTags = new Set([...tagsArray, ...Array.from(tags)])

    const activeMatchAsset = match.match_assets?.find(
      asset => asset.media_locator.id === activeMediaLocator.id
    )

    const description =
      data.description?.trim() === '' ? undefined : data.description

    if (activeMatchAsset) {
      createClip.mutate(
        {
          title: data.title,
          start_time: data.start,
          duration: data.end - data.start,
          match_asset_id: activeMatchAsset.id,
          event_id: data.event,
          tags: Array.from(allTags),
          description,
        },
        {
          onSuccess: () => {
            snackbar({
              message: t(
                'components:asset.notification.clipCreatedRequestSuccess'
              ),
            })
          },
        }
      )
    }

    reset({
      title: '',
      description: undefined,
      // @ts-ignore
      start: convertSecondsToTime(0),
      // @ts-ignore
      end: convertSecondsToTime(0),
      event: '',
      note: '',
    })

    handleResetTag()
    setTagValue('')

    const layoutsIds = layouts.map(layout => layout.i)
    const assetComponent = Object.entries(layoutTypes)
      .filter(([key]) => layoutsIds.includes(key))
      .find(([_, value]) => value === LayoutIndex.ASSETS)

    if (match.id && assetComponent) {
      const [assetComponentId] = assetComponent
      const cacheKey = `${LayoutIndex.ASSETS}-${assetComponentId}`
      const data: Record<string, object> | undefined = queryClient.getQueryData(
        [
          USER_CACHE_RETRIEVE_CACHE_KEY,
          {
            keys: [cacheKey],
          },
        ]
      )

      userCacheCreate({
        [cacheKey]: {
          ...data?.[cacheKey],
          [match.id]: {
            collapsed: false,
            focused: true,
          },
        },
      })
    }
  }

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)} hidden={hidden}>
      <Root hidden={hidden} className='create-form-root'>
        <Label align='right' area={GRID_TITLE_LABEL}>
          {t('components:asset.form.clip.create.title')}
        </Label>

        <FullWidthFormControl area={GRID_TITLE_INPUT}>
          <InputText
            error={!!errors.title?.message}
            inputProps={{ 'aria-label': 'title' }}
            placeholder=''
            disableUnderline
            {...register('title')}
          />
          <Tooltip title={tError(errors.title?.message) ?? ''}>
            <ErrorMessage>{tError(errors.title?.message)}</ErrorMessage>
          </Tooltip>
        </FullWidthFormControl>
        <Label align='right' area={GRID_DESCRIPTION_LABEL}>
          {t('components:asset.form.clip.create.description')}
        </Label>
        <FullWidthFormControl area={GRID_DESCRIPTION_INPUT}>
          <InputText
            error={!!errors.description?.message}
            inputProps={{ 'aria-label': 'description' }}
            placeholder=''
            disableUnderline
            {...register('description')}
          />
          <Tooltip title={tError(errors.description?.message) ?? ''}>
            <ErrorMessage>{tError(errors.description?.message)}</ErrorMessage>
          </Tooltip>
        </FullWidthFormControl>
        <LabelWrapper area={GRID_START_LABEL}>
          <LabelItem>
            <Icon name='start-path' />
            <Label align='left'>
              {t('components:asset.form.clip.create.startTime')}
            </Label>
          </LabelItem>
        </LabelWrapper>
        <FluidWidthFormControl area={GRID_START_INPUT}>
          <InputText
            error={!!errors.start?.message}
            {...register('start')}
            inputProps={{ 'aria-label': 'start' }}
            value={startTime}
            onChange={e => setStartTime(e.target.value)}
            placeholder=''
            disableUnderline
            inputComponent={TimeMaskInput as any}
            endAdornment={
              <Tooltip title='Copy current time'>
                <EndAdornment
                  position='start'
                  onClick={handleCopyCurrentTime('start')}
                >
                  <GpsFixedIcon />
                </EndAdornment>
              </Tooltip>
            }
          />
          <Tooltip title={tError(errors.start?.message) ?? ''}>
            <ErrorMessage>{tError(errors.start?.message)}</ErrorMessage>
          </Tooltip>
        </FluidWidthFormControl>
        <LabelWrapper area={GRID_END_LABEL}>
          <LabelItem>
            <Icon name='end-path' />
            <Label align='left'>
              {t('components:asset.form.clip.create.endTime')}
            </Label>
          </LabelItem>
        </LabelWrapper>
        <FluidWidthFormControl area={GRID_END_INPUT}>
          <InputText
            error={!!errors.end?.message}
            {...register('end')}
            inputProps={{ 'aria-label': 'end' }}
            value={endTime}
            onChange={e => setEndTime(e.target.value)}
            placeholder=''
            disableUnderline
            inputComponent={TimeMaskInput as any}
            endAdornment={
              <Tooltip title='Copy current time'>
                <EndAdornment
                  position='start'
                  onClick={handleCopyCurrentTime('end')}
                >
                  <GpsFixedIcon />
                </EndAdornment>
              </Tooltip>
            }
          />

          <Tooltip title={tError(errors.end?.message) ?? ''}>
            <ErrorMessage>{tError(errors.end?.message)}</ErrorMessage>
          </Tooltip>
        </FluidWidthFormControl>

        <LabelWrapper area={GRID_EVENT_LABEL}>
          <LabelItem>
            <Icon name='pin' />
            <Label align='left'>
              {t('components:asset.form.clip.create.event')}
            </Label>
          </LabelItem>
        </LabelWrapper>
        <FluidWidthFormControl area={GRID_EVENT_INPUT}>
          <Controller
            name='event'
            control={control}
            render={({ field }) => (
              <>
                <EventSelector
                  error={!!errors.event?.message}
                  defaultValue=''
                  disabled={eventList.data?.results.length ? false : true}
                  inputProps={{ 'aria-label': 'event' }}
                  {...field}
                >
                  {eventList.data?.results.map(event => (
                    <MenuItem key={event.id} value={event.id}>
                      <EventIcon
                        name={
                          eventIndexToIconDictionary[
                            event.type as EventIndex
                          ] ?? defaultIconName
                        }
                      />
                      {tServer(event.name)}
                    </MenuItem>
                  ))}
                </EventSelector>
                <Tooltip title={tError(errors.event?.message) ?? ''}>
                  <ErrorMessage>{tError(errors.event?.message)}</ErrorMessage>
                </Tooltip>
              </>
            )}
          />
        </FluidWidthFormControl>
        <Label align='right' area={GRID_TAGS_LABEL}>
          {t('components:asset.form.clip.create.tags')}
        </Label>
        <FullWidthFormControl area={GRID_TAGS_INPUT}>
          {sportPresetTags.length ? (
            <TagsPopper
              width={inputWidth}
              ref={modalRef}
              open={isPopoverOpen}
              anchorEl={popoverAnchor}
              placement='top'
            >
              <TagsPopperBody>
                {sportPresetTags.map((tagSet, index) => (
                  <TagsCategoryWrapper key={index}>
                    <TagsCategory
                      onClick={handleTagAdd(t(tagSet.category.identifier))}
                    >
                      {tagSet.category.name}
                    </TagsCategory>
                    <CategoryTagsList>
                      {tagSet.tags.map((tag, index) => (
                        <CategoryTagItem
                          selected={tags.has(t(tag.identifier))}
                          key={index}
                          onClick={handleTagAdd(t(tag.identifier))}
                        >
                          {tag.name}
                        </CategoryTagItem>
                      ))}
                    </CategoryTagsList>
                  </TagsCategoryWrapper>
                ))}
              </TagsPopperBody>
            </TagsPopper>
          ) : null}
          <InputTextField
            ref={wrapperRef}
            value={tagValue}
            placeholder=''
            onChange={e => setTagValue(e.target.value)}
            onKeyDown={handleKeyPress}
            onFocus={handleOpenPopover}
            disableUnderline={true}
            inputProps={{ 'aria-label': 'tags' }}
          />
        </FullWidthFormControl>
        <TagsListWrapper className={tagListClassName} area={GRID_TAGS_ITEMS}>
          {Array.from(tags)
            .reverse()
            .map((tag, index) => (
              <TagItem key={index} onClick={handleTagRemove(tag)}>
                {tag}
              </TagItem>
            ))}
        </TagsListWrapper>

        <ButtonWrapper area={GRID_SAVE_BUTTON}>
          <SaveButton type='submit' color='secondary' variant='contained'>
            <SaveIcon name='save' />
            <SaveButtonText>
              {t('components:asset.form.clip.create.save')}
            </SaveButtonText>
          </SaveButton>
        </ButtonWrapper>
      </Root>
    </StyledForm>
  )
}
