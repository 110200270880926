import { styled, Typography } from '@mui/material'

export const Main = styled('div')`
  padding: ${({ theme }) => theme.spacing(3.5, 3.75)};
`

export const ImageContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 135px;
  height: 135px;
  background-color: ${({ theme }) => theme.palette.primary.light};
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  & > svg {
    width: 48px;
    height: 48px;
  }
`

export const TierPositionWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
`

export const Name = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
`

export const Tier = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 16px;
  text-transform: capitalize;
`

export const Position = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 16px;
  text-transform: capitalize;
`

export const Location = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 14px;
`

export const LocationWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(0.5)};
  column-gap: ${({ theme }) => theme.spacing(0.5)};
`
