import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import { AdminUserInvite } from '@sporttotal-tv/dip-coaching-client'
import { INVITE_LIST_CACHE_KEY, USER_LIST_CACHE_KEY } from 'modules/user/hooks'

export const INVITE_DELETE_CACHE_KEY = 'invite-delete'

export const useInviteDelete = () => {
  const queryClient = useQueryClient()

  return useMutation<AdminUserInvite, Error, string, void>(
    INVITE_DELETE_CACHE_KEY,
    (id: string) => ApiService.invite.delete(id),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(INVITE_LIST_CACHE_KEY, {
          exact: false,
        })
      },
      onError: () => {
        queryClient.invalidateQueries(INVITE_LIST_CACHE_KEY, { exact: false })
      },
      onSuccess: () => {
        queryClient.invalidateQueries(INVITE_LIST_CACHE_KEY, { exact: false })
        queryClient.invalidateQueries(USER_LIST_CACHE_KEY, { exact: false })
      },
    }
  )
}
