import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import { USER_LIST_CACHE_KEY } from 'modules/user/hooks'

export const ADMIN_USER_DELETE_CACHE_KEY = 'admin-user-delete'

export const useAdminUserDelete = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ADMIN_USER_DELETE_CACHE_KEY,
    (id: string) => ApiService.admin.user.delete(id),
    {
      onSettled: () => {
        queryClient.invalidateQueries(USER_LIST_CACHE_KEY)
      },
    }
  )
}
