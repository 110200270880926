import { Typography } from '@mui/material'
import { styled } from '@mui/system'

// export const textVariantsStyles = {
//   [TEXT_VARIANTS.heading]: {
//     ...defaultTextStyles,
//     textTransform: 'uppercase',
//   },
//   [TEXT_VARIANTS.text]: {
//     ...defaultTextStyles,
//     textTransform: 'lowerace',
//   },
// }

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.contrastText};
` as typeof Typography
