import {
  User,
  AdminUserList,
  RetrieveDataUserOptions,
  UpdateDataUserBody,
  ListAdminUserListOptions,
  AdminUsersUpdatePayload,
} from '@sporttotal-tv/dip-coaching-client'
import { api } from 'modules/core/api'
import {
  transformApi,
  transformApiResults,
  transformIdApiResults,
} from 'modules/core/helpers'
import { UserCacheData } from 'modules/user/types'

export const UserApiService = {
  retrieve: transformApiResults<void, User & { data: UserCacheData }>(
    api.user.retrieve
  ),
  adminUserRetrieve: transformIdApiResults<
    void,
    User & { data: UserCacheData }
  >(api.adminUsers.retrieve),
  adminUserUpdate: transformIdApiResults<
    AdminUsersUpdatePayload,
    User & { data: UserCacheData }
  >(api.adminUsers.update),
  list: transformApiResults<ListAdminUserListOptions, AdminUserList>(
    api.adminUsers.list
  ),
  retrieveStorage: transformApiResults<
    RetrieveDataUserOptions,
    User & UserCacheData
  >(api.user.retrieveData),
  updateStorage: transformApiResults<
    UpdateDataUserBody & UserCacheData,
    UserCacheData
  >(api.user.updateData),
  update: transformApi(api.user.update),
  teams: transformApi(api.user.team),
  sports: transformApi(api.user.sport),
  competitions: transformApi(api.user.competitions),
  organiations: transformApi(api.user.organizations),
  accessGroups: transformApi(api.user.accessGroups),
}
