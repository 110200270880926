export const VIDEO_FPS = 25

export const PLAYER_CLASS_COLOR_MAP: Record<number, string> = {
  0: 'black', // Referee
  1: 'blue', // Home Team Player
  2: 'red', // Away Team Player
  3: 'purple', // Home Team Goalie
  4: 'purple', // Away Team Goalie
}

export const PENALTY_BOX_LENGTH = 16.5
