import { forwardRef, useRef, useImperativeHandle } from 'react'
import { BaseVideoPlayerProps } from './BaseVideoPlayer.interface'
import { useKey } from 'modules/core/hooks'
import { keyCodes } from '@fluentui/keyboard-keys'
import { useVideoPlayerControls } from 'modules/video-player/hooks'

export const BaseVideoPlayer = forwardRef<
  HTMLVideoElement,
  BaseVideoPlayerProps
>(({ isKeyboardControlEnabled = true, children, style, ...others }, ref) => {
  const videoPlayerRef = useRef<HTMLVideoElement>(null)
  const { playPause, fastForward, volume, setVolume } = useVideoPlayerControls(
    videoPlayerRef.current
  )

  const useKeyOptions = { disabled: !isKeyboardControlEnabled }

  useImperativeHandle(ref, () => videoPlayerRef.current as HTMLVideoElement)

  useKey(keyCodes.Space, playPause, useKeyOptions)
  useKey(keyCodes.d, fastForward, useKeyOptions)
  // useKey(keyCodes.a, handleBackward, useKeyOptions)

  useKey(
    [keyCodes.ArrowUp, keyCodes.ArrowDown],
    key => setVolume(key === keyCodes.ArrowUp ? volume + 0.1 : volume - 0.1),
    useKeyOptions
  )

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '100%',
      }}
    >
      <video
        {...others}
        style={{ width: '100%', background: '#000', ...style }}
        ref={videoPlayerRef}
      />
      {children && (
        <div style={{ position: 'absolute', inset: 0 }}>{children}</div>
      )}
    </div>
  )
})
