import { styled } from '@mui/system'
import { TagsWrapper } from 'modules/core/styled'
import { Typography } from '@mui/material'

export const FormWrapper = styled('div')`
  padding: ${({ theme }) => theme.spacing(0, 3, 0, 1.5)};
`

export const TagsListWrapper = styled(TagsWrapper)`
  display: flex;
  height: 100%;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  margin-left: calc(25% + ${({ theme }) => theme.spacing(1.75)});
`

export const TagItem = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.light};
  padding: ${({ theme }) => theme.spacing(0.5, 2)};
  text-transform: uppercase;
  font-size: 10px;
  cursor: pointer;
`
