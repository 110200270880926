import { useState } from 'react'
import { useTranslation } from 'react-i18next'
// components
import { IconButton, SearchInput } from 'modules/core/components'
import { UserInviteModal, UserList } from 'modules/user/components'
// hooks
import { useDebounce } from 'modules/core/hooks'

import {
  Wrapper,
  Root,
  HeaderRow,
  SearchWrapper,
  UserAddWrapper,
} from './Users.styled'

export const Users = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'admin' })
  const [dialogOpen, setDialogOpen] = useState(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const debounceSearchValue = useDebounce(searchValue, 500)

  return (
    <Root>
      <Wrapper>
        <HeaderRow>
          <SearchWrapper>
            <SearchInput
              placeholder={t('context.search')}
              onChange={e => setSearchValue(e.target.value)}
            />
          </SearchWrapper>

          <UserAddWrapper onClick={() => setDialogOpen(true)}>
            {t('context.inviteUser')} <IconButton name='plus-circle' />
          </UserAddWrapper>
        </HeaderRow>
        <UserList search={debounceSearchValue} />
      </Wrapper>
      <UserInviteModal open={dialogOpen} setOpen={setDialogOpen} />
    </Root>
  )
}
