import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { useQueryRetry } from 'modules/core/hooks'
import { ListGroupAdminAccessGroupListOptions } from '@sporttotal-tv/dip-coaching-client'

export const ACCESS_LIST_CACHE_KEY = 'access-list'

export const useAccessList = (
  options?: ListGroupAdminAccessGroupListOptions
) => {
  const handleQueryRetry = useQueryRetry({ maxRetries: 0 })

  return useQuery(
    [ACCESS_LIST_CACHE_KEY, options],
    () => ApiService.admin.access.list(options ?? {}),
    {
      staleTime: Infinity,
      retry: handleQueryRetry,
    }
  )
}
