import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'
import { Icon } from 'modules/core/components/Icon'

export const ButtonWrapper = styled('div')`
  display: flex;
  align-items: end;
`

export const StyledButton = styled(Button)`
  font-size: 14px;
  box-shadow: none;
  padding: 0 ${({ theme }) => theme.spacing(1.5)};
  border-radius: 0;
  text-transform: capitalize;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
`

export const ActionIcon = styled(Icon)`
  height: 11px;
  width: 11px;

  & path {
    fill: ${({ theme }) => theme.palette.common.white};
  }
`
