import { FC, useCallback, useContext, useEffect } from 'react'
import { Match } from '@sporttotal-tv/dip-coaching-client'
// hooks
import { useVideoPlayerControls } from 'modules/video-player/hooks'
import { useReduxDispatch, useReduxSelector } from 'modules/core/hooks'
// context
import { VideoPlayerContext } from 'modules/video-player/context'
import { ComponentInfoContext } from 'modules/generic/context'
// redux
import { selectNextMatch, selectPrevMatch } from 'modules/match/redux'
import { setMediaItem } from 'modules/video-player/redux'

import { PlayControls, PlayControlsIcon } from './VideoPlayerControls.styled'
import { VideoPlayerControlsProps } from './VideoPlayerControls.interface'
import { createSetMatchPayload } from 'modules/match/utils'

export const VideoPlayerControls: FC<VideoPlayerControlsProps> = ({
  player,
  onPlaybackStateChange,
  size = 'default',
}) => {
  const { status, play, playPause, fastForward, stop, setPlaybackRate } =
    useVideoPlayerControls(player ?? null)

  const { playerId } = useContext(VideoPlayerContext)
  const { componentId } = useContext(ComponentInfoContext)
  const dispatch = useReduxDispatch()
  const prevMatch = useReduxSelector(selectPrevMatch)
  const nextMatch = useReduxSelector(selectNextMatch)

  const isPlaying = status === 'play' || status === 'fast-forward'

  const handlePlayPause = useCallback(() => {
    if (status !== 'fast-forward') return playPause()

    setPlaybackRate(1)
    play()
  }, [playPause, setPlaybackRate, play, status])

  const handleChangeVideo = useCallback(
    (match: Match | null) => () => {
      if (match) {
        dispatch(
          setMediaItem(
            createSetMatchPayload(playerId ?? componentId, match, 'pano')
          )
        )
      }
    },
    [dispatch, playerId, componentId]
  )

  useEffect(() => {
    if (typeof onPlaybackStateChange === 'function') {
      onPlaybackStateChange(status)
    }
  }, [onPlaybackStateChange, status])

  return (
    <PlayControls size={size}>
      <PlayControlsIcon
        disabledClick={!prevMatch}
        name='skip-start'
        onClick={handleChangeVideo(prevMatch)}
      />
      {/* <PlayControlsIcon
        active={status === 'backward'}
        name='skip-backward'
        onClick={handleBackward}
      /> */}
      <PlayControlsIcon name='stop' onClick={stop} />
      <PlayControlsIcon
        active={status === 'play'}
        name={isPlaying ? 'pause' : 'play'}
        onClick={handlePlayPause}
      />
      <PlayControlsIcon
        active={status === 'fast-forward'}
        name='skip-forward'
        onClick={fastForward}
      />
      <PlayControlsIcon
        disabledClick={!nextMatch}
        name='skip-end'
        onClick={handleChangeVideo(nextMatch)}
      />
    </PlayControls>
  )
}
