import { TabDto } from 'modules/user/types'
import { generateId } from 'modules/core/helpers/generateId'

export const analyserPageDefaultTabStore: TabDto[] = [
  { id: generateId('v1'), name: 'Dashboard', static: true, path: 'dashboard' },
  { id: generateId('v1'), name: 'Clipping', static: false, path: 'clipping' },
  {
    id: generateId('v1'),
    name: 'Telestration',
    static: false,
    path: 'telestration',
  },
  {
    id: generateId('v1'),
    name: 'Comparison',
    static: false,
    path: 'comparison',
  },
]
