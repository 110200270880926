import { css, styled } from '@mui/material'
import { LoaderContainerVariant } from './LoaderContainer.interface'

export const LoaderWrapper = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'hidden' &&
    prop !== 'transitionTime' &&
    prop !== 'transitionDelay' &&
    prop !== 'variant',
})<{
  hidden: boolean
  transitionTime: number
  transitionDelay: number
  variant: LoaderContainerVariant
}>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  z-index: 1000;
  top: 0;
  left: 0;

  transition: opacity 0.3s 0.1s, transform 0.1s 0s;

  ${({ hidden, transitionTime, transitionDelay }) =>
    hidden &&
    css`
      transition: opacity ${transitionTime}s ${transitionDelay}s,
        transform 0s ${transitionDelay + transitionTime}s;
      opacity: 0;
      transform: translateY(-100000px);
      overflow: hidden;
    `}

  ${({ variant, theme }) =>
    variant === 'full' &&
    css`
      z-index: ${theme.zIndex.drawer + 1};
    `}
`
