import { styled } from '@mui/material'

export const Root = styled('div')`
  position: relative;
  width: 100%;
`

export const Slider = styled('div', {
  shouldForwardProp: prop => prop !== 'withScroll',
})<{ withScroll?: boolean }>`
  position: relative;
  width: 100%;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.palette.common.black};

  ${({ withScroll }) => withScroll && 'padding-bottom: 0;'}
`

export const Track = styled('div')`
  display: flex;
  width: fit-content;
  user-select: none;
  cursor: grab;
`
