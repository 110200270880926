import {
  Button,
  css,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputAdornment,
  Popper,
  Select,
  styled,
  Typography,
} from '@mui/material'
import { Icon } from 'modules/core/components/Icon'
import { Tag, TagsWrapper } from 'modules/core/styled'

export const GRID_TITLE_LABEL = 'title-label'
export const GRID_TITLE_INPUT = 'title-input'
export const GRID_DESCRIPTION_LABEL = 'description-label'
export const GRID_DESCRIPTION_INPUT = 'description-input'
export const GRID_TAGS_LABEL = 'tags-label'
export const GRID_TAGS_INPUT = 'tags-input'
export const GRID_TAGS_ITEMS = 'tags-items'
export const GRID_SAVE_BUTTON = 'save-button'
export const GRID_START_LABEL = 'start-label'
export const GRID_START_INPUT = 'start-input'
export const GRID_END_LABEL = 'end-label'
export const GRID_END_INPUT = 'end-input'
export const GRID_EVENT_LABEL = 'event-label'
export const GRID_EVENT_INPUT = 'event-input'
export const GRID_SPACE = '.'

export const StyledForm = styled('form', {
  shouldForwardProp: prop => prop !== 'hidden',
})<{ hidden?: boolean }>`
  overflow-x: hidden;
  overflow-y: auto;
  container-type: inline-size;
  container-name: clip-create-form-wrapper;

  @container clip-create-form-wrapper (max-width: 964px) {
    & div.create-form-root {
      grid-template-columns: 60px minmax(120px, 1fr) 60px 114px 60px 114px;
      grid-template-areas:
        '${GRID_TITLE_LABEL} ${GRID_TITLE_INPUT} ${GRID_START_LABEL} ${GRID_START_INPUT} ${GRID_END_LABEL} ${GRID_END_INPUT} '
        '${GRID_DESCRIPTION_LABEL} ${GRID_DESCRIPTION_INPUT} ${GRID_EVENT_LABEL} ${GRID_EVENT_INPUT} ${GRID_SPACE} ${GRID_SPACE}'
        '${GRID_TAGS_LABEL} ${GRID_TAGS_INPUT} ${GRID_TAGS_INPUT} ${GRID_TAGS_INPUT} ${GRID_TAGS_INPUT} ${GRID_TAGS_INPUT}'
        '${GRID_SPACE} ${GRID_TAGS_ITEMS} ${GRID_TAGS_ITEMS} ${GRID_TAGS_ITEMS} ${GRID_TAGS_ITEMS} ${GRID_SAVE_BUTTON}';
    }
  }

  @container clip-create-form-wrapper (max-width: 660px) {
    & div.create-form-root {
      column-gap: 10px;
      grid-template-columns: 60px auto 75px 60px auto 75px;
      grid-template-areas:
        '${GRID_TITLE_LABEL} ${GRID_TITLE_INPUT} ${GRID_TITLE_INPUT} ${GRID_TITLE_INPUT} ${GRID_TITLE_INPUT} ${GRID_TITLE_INPUT}'
        '${GRID_DESCRIPTION_LABEL} ${GRID_DESCRIPTION_INPUT} ${GRID_DESCRIPTION_INPUT} ${GRID_DESCRIPTION_INPUT} ${GRID_DESCRIPTION_INPUT} ${GRID_DESCRIPTION_INPUT}'
        '${GRID_START_LABEL} ${GRID_START_INPUT} ${GRID_START_INPUT} ${GRID_END_LABEL} ${GRID_END_INPUT} ${GRID_END_INPUT}'
        '${GRID_EVENT_LABEL} ${GRID_EVENT_INPUT} ${GRID_EVENT_INPUT} ${GRID_SPACE} ${GRID_SPACE} ${GRID_SPACE}'
        '${GRID_TAGS_LABEL} ${GRID_TAGS_INPUT} ${GRID_TAGS_INPUT} ${GRID_TAGS_INPUT} ${GRID_TAGS_INPUT} ${GRID_TAGS_INPUT}'
        '${GRID_SPACE} ${GRID_TAGS_ITEMS} ${GRID_TAGS_ITEMS} ${GRID_TAGS_ITEMS} ${GRID_TAGS_ITEMS} ${GRID_SAVE_BUTTON}';
    }
  }

  @container clip-create-form-wrapper (max-width: 390px) {
    & div.create-form-root {
      column-gap: 10px;
      grid-template-columns: 60px auto auto;
      grid-template-areas:
        '${GRID_TITLE_LABEL} ${GRID_TITLE_INPUT} ${GRID_TITLE_INPUT}'
        '${GRID_DESCRIPTION_LABEL} ${GRID_DESCRIPTION_INPUT} ${GRID_DESCRIPTION_INPUT}'
        '${GRID_START_LABEL} ${GRID_START_INPUT} ${GRID_START_INPUT}'
        '${GRID_END_LABEL} ${GRID_END_INPUT} ${GRID_END_INPUT}'
        '${GRID_EVENT_LABEL} ${GRID_EVENT_INPUT} ${GRID_EVENT_INPUT}'
        '${GRID_TAGS_LABEL} ${GRID_TAGS_INPUT} ${GRID_TAGS_INPUT}'
        '${GRID_TAGS_ITEMS} ${GRID_TAGS_ITEMS} ${GRID_SAVE_BUTTON}';
    }
  }

  ${({ hidden }) =>
    hidden &&
    css`
      overflow-y: hidden;
    `}
`

export const Root = styled('div', {
  shouldForwardProp: prop => prop !== 'hidden',
})<{ hidden?: boolean }>`
  display: grid;
  padding: ${({ theme }) => theme.spacing(0.5, 1.75)};
  column-gap: 20px;
  row-gap: 12px;
  transition: padding 0.3s, opacity 0.3s, transform 0s 0s;
  overflow: hidden;

  grid-template-columns: 60px minmax(120px, 1fr) 60px 114px 60px 114px 60px 1.5fr 80px;
  grid-template-rows: auto;
  grid-template-areas:
    '${GRID_TITLE_LABEL} ${GRID_TITLE_INPUT} ${GRID_START_LABEL} ${GRID_START_INPUT} ${GRID_END_LABEL} ${GRID_END_INPUT} ${GRID_TAGS_LABEL} ${GRID_TAGS_INPUT} ${GRID_TAGS_INPUT}'
    '${GRID_DESCRIPTION_LABEL} ${GRID_DESCRIPTION_INPUT} ${GRID_EVENT_LABEL} ${GRID_EVENT_INPUT} ${GRID_SPACE} ${GRID_SPACE} ${GRID_SPACE} ${GRID_TAGS_ITEMS} ${GRID_SAVE_BUTTON}';

  ${({ hidden }) =>
    hidden &&
    css`
      transition: padding 0.3s, opacity 0.3s, transform 0s 0.3s;
      opacity: 0;
      padding: 0;
      transform: translateY(1000px);
      overflow: hidden;
    `}
`

export const EventIcon = styled(Icon)`
  width: 12px;
  height: 12px;
  margin-right: ${({ theme }) => theme.spacing(0.5)}; ;
`

export const EventSelector = styled(Select, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  padding: 0;
  font-size: 14px;
  outline: none;
  border-radius: 0;
  border: 1px solid transparent;

  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.palette.error.main};
    `}

  & .MuiSelect-select {
    padding: 8px;
    display: flex;
    align-items: center;
  }

  & .MuiOutlinedInput-notchedOutline {
    outline: none;
    border: none;
    border-radius: 0;
  }
`

export const TagsListWrapper = styled(TagsWrapper, {
  shouldForwardProp: prop => prop !== 'area',
})<{ area?: string }>`
  ${({ area }) => area && `grid-area: ${area};`}
  display: flex;
  height: 100%;
  width: 100%;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: ${({ theme }) => theme.spacing(0.25)};
`

export const TagItem = styled(Tag)`
  cursor: pointer;
`

export const LabelWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'area',
})<{ area?: string }>`
  ${({ area }) => area && `grid-area: ${area};`}
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  display: flex;
  align-items: start;
  justify-content: end;
`

export const LabelItem = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  align-items: center;
`

export const Label = styled(FormLabel, {
  shouldForwardProp: prop => prop !== 'area' && prop !== 'align',
})<{ area?: string; align: 'left' | 'right' }>`
  ${({ area }) => area && `grid-area: ${area};`}
  font-size: 12px;
  text-align: ${({ align }) => align};
  padding: 10px 0;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const InputText = styled(Input, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  padding: 0;
  transition: border 0.3s;
  border: 1px solid transparent;

  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.palette.error.main};
    `}

  & .MuiInput-input {
    padding: 8px;
    font-size: 14px;
    width: 100%;
  }
`

export const FullWidthFormControl = styled(FormControl, {
  shouldForwardProp: prop => prop !== 'area',
})<{ area?: string }>`
  ${({ area }) => area && `grid-area: ${area};`}
  width: 100%;
`

export const FluidWidthFormControl = styled(FormControl, {
  shouldForwardProp: prop => prop !== 'area',
})<{ area?: string }>`
  ${({ area }) => area && `grid-area: ${area};`}
  width: 100%;
`

export const InputTextField = styled(Input)`
  background-color: ${({ theme }) => theme.palette.primary.dark};

  & .MuiInputBase-multiline {
    padding: 0;
  }

  & .MuiInput-input {
    padding: 8px;
    font-size: 14px;
  }
`

export const ButtonWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'area',
})<{ area?: string }>`
  ${({ area }) => area && `grid-area: ${area};`}
  display: flex;
  justify-content: end;
  align-items: start;
`

export const SaveButton = styled(Button)`
  box-shadow: none;
  padding: 0 ${({ theme }) => theme.spacing(1.5)};
  border-radius: 0;

  display: flex;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
`

export const SaveButtonText = styled('div')`
  font-size: 14px;
  text-transform: capitalize;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const SaveIcon = styled(Icon)`
  height: 11px;
  width: 11px;

  & path {
    fill: ${({ theme }) => theme.palette.common.white};
  }
`

export const EndAdornment = styled(InputAdornment)`
  height: 80%;
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing(0.75)};
  margin-left: 0;

  & svg {
    width: 16px;

    & path {
      fill: ${({ theme }) => theme.palette.primary.light};
    }
  }
`

export const ErrorMessage = styled(FormHelperText)`
  margin: ${({ theme }) => theme.spacing(0.25, 1, 0)};
  color: ${({ theme }) => theme.palette.error.main};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const TagsPopper = styled(Popper, {
  shouldForwardProp: prop => prop !== 'width',
})<{ width: number }>`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  width: ${({ width }) => width}px;
  z-index: ${({ theme }) => theme.zIndex.modal};
  margin-bottom: ${({ theme }) => theme.spacing(1)} !important;
  padding: ${({ theme }) => theme.spacing(1)};
`

export const TagsPopperBody = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(2)};
`

export const TagsCategoryWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(2)};
  align-content: center;
`

export const TagsCategory = styled(Typography)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.light};
  font-weight: bold;
`

export const CategoryTagsList = styled('div')`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => theme.spacing(1)};
  row-gap: ${({ theme }) => theme.spacing(1)};
`

export const CategoryTagItem = styled(Tag, {
  shouldForwardProp: prop => prop !== 'selected',
})<{ selected?: boolean }>`
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;

  ${({ selected, theme }) =>
    !selected &&
    css`
      background-color: ${theme.palette.primary.main};
      color: ${theme.palette.primary.light};
    `}
`
