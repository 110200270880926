import { css, LinearProgress, styled, Typography } from '@mui/material'
import { Icon } from 'modules/core/components/Icon'

export const Root = styled('div', {
  shouldForwardProp: prop => prop !== 'active' && prop !== 'isDragging',
})<{ active?: boolean; isDragging?: boolean }>`
  position: relative;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.25)};
  width: 90%;

  padding: ${({ theme }) => theme.spacing(1.5, 0)};
  margin: ${({ theme }) => theme.spacing(0, 2)};
  margin-left: auto;

  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};

  &:before {
    content: '';
    width: 2px;
    height: calc(100% + 2px);
    top: -1px;
    transition: all 0.3s;
    position: absolute;
    left: -${({ theme }) => theme.spacing(4)};
    background-color: ${({ theme }) => theme.palette.secondary.main};
    opacity: 0;
  }

  ${({ active }) =>
    active &&
    css`
      &:before {
        opacity: 1;
      }
    `}

  ${({ isDragging }) =>
    isDragging &&
    css`
      opacity: 0.4;
    `}
`

export const EventAndStatusIconWrapper = styled('div')`
  min-width: 20px;
  margin-top: ${({ theme }) => theme.spacing(1)};
  justify-content: flex-start;
  row-gap: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const EventIcon = styled(Icon)`
  width: 100%;
  max-height: 20px;
  height: auto;
`

export const PendingIcon = styled(Icon)`
  width: 26px;
  height: 26px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`

export const MainInfoWrapper = styled('div')`
  width: calc(100% - 30px);
`

export const ClipInfoWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(2)};
`

export const ClipInfoItem = styled('div')`
  flex: 1;
  overflow: hidden;
  position: relative;
`

export const TitleRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const FlexSpaceConsumer = styled('div')`
  flex: 1;
`

export const Title = styled(Typography, {
  shouldForwardProp: prop => prop !== 'active',
})<{ active?: boolean }>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  margin: ${({ theme }) => theme.spacing(0.5, 0.2, 0.75, 0)};

  ${({ active }) =>
    active &&
    css`
      cursor: pointer;
    `}
`

export const ClipDescription = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.light};
`

export const DownloadProgress = styled(LinearProgress)`
  flex: 1;

  background-color: ${({ theme }) => theme.palette.secondary.light};

  .MuiLinearProgress-bar {
    background-color: ${({ theme }) => theme.palette.secondary.main};
  }
`

export const DownloadWrapper = styled('div')`
  width: 100%;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
`

export const ClipDescriptionWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'active',
})<{ active?: boolean }>`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(2)};

  ${({ active }) =>
    active &&
    css`
      cursor: grabbing;
    `}
`

export const ClipDescriptionInfo = styled('div', {
  shouldForwardProp: prop => prop !== 'active',
})<{ active?: boolean }>`
  padding: ${({ theme }) => theme.spacing(1.5, 0, 0)};
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.light};

  ${({ active }) =>
    active &&
    css`
      cursor: grabbing;
    `}
`

export const CollapseControlsWrapper = styled('div')`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(3)};
`

export const TagWrapper = styled('div')`
  display: flex;
  flex: 1;
  height: 23px;
  column-gap: ${({ theme }) => theme.spacing(1)};
  row-gap: ${({ theme }) => theme.spacing(1)};
  flex-wrap: wrap;
  overflow: hidden;
`

export const Tag = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.light};
  padding: ${({ theme }) => theme.spacing(0.5, 2)};
  text-transform: uppercase;
  font-size: 10px;
`

export const PendingOverlay = styled('div', {
  shouldForwardProp: prop => prop !== 'hidden',
})<{ hidden?: boolean }>`
  background-color: rgba(44 44 44 / 0.75);
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`
