import React, { memo } from 'react'
import { format } from 'date-fns'
import { useReduxSelector } from 'modules/core/hooks'
import {
  selectCalendarEvent,
  selectGhostProperties,
} from 'modules/calendar/redux'

const styles = {
  display: 'inline-block',
}
const backgroundColor = '#FF004450'

type EventMarkGhostProps = {
  dataDate: number | null
  dataHour: number
  dataMin: number
}

const EventMarkGhost = memo<EventMarkGhostProps>(
  ({ dataDate, dataHour, dataMin }) => {
    const ghostProperties = useReduxSelector(selectGhostProperties)
    const calendarEvent = useReduxSelector(selectCalendarEvent)

    const newDate = new Date(
      format(
        dataDate ? new Date(dataDate) : new Date(),
        `yyyy/MM/dd ${dataHour}:${dataMin}:s`
      )
    )

    const stylesBox = {
      backgroundColor: 'drakgreen',
      lineHeight: '31px',
      cursor: 'move',
      fontSize: 10,
      width: ghostProperties.width,
      height: ghostProperties.height,
    }
    const Ghost = () => {
      return (
        <div
          style={{ ...stylesBox, backgroundColor }}
          data-ghost={true}
          data-date={newDate}
        >
          <p style={{ lineHeight: '8px' }}>{calendarEvent?.title}</p>
          <p style={{ lineHeight: '8px' }}>
            [{format(newDate, `yyyy/MM/dd HH:mm:ss`)}]
          </p>
        </div>
      )
    }

    return (
      <div style={styles}>
        <Ghost />
      </div>
    )
  }
)
export default EventMarkGhost
