import { FC, PropsWithChildren, useState } from 'react'
import { PopupContext } from './context'

export const PopupContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isRequestDemoPopupOpened, setIsRequestDemoPopupOpened] =
    useState(false)

  const [isBurgerOpened, setIsBurgerOpened] = useState(false)

  return (
    <PopupContext.Provider
      value={{
        isRequestDemoPopupOpened,
        setIsRequestDemoPopupOpened,
        isBurgerOpened,
        setIsBurgerOpened,
      }}
    >
      {children}
    </PopupContext.Provider>
  )
}
