import { ShapeHandler } from 'modules/annotator/types'

import { ShapeOptions, TelestrationText } from 'modules/telestration/types'

export const Text: ShapeHandler<TelestrationText> = {
  onCreate({ currentTarget }, options?: ShapeOptions) {
    const points = currentTarget.getStage()?.getPointerPosition()
    const X = points?.x ?? null
    const Y = points?.y ?? null

    if (!X || !Y) return

    const {
      fontSize = 30,
      fontFamily = 'Roboto',
      letterSpacing = 4,
      text = 'Double click to edit',
      ...other
    } = options || {}

    return {
      ...other,
      type: 'TEXT',
      x: X,
      y: Y,
      text,
      fontSize,
      fontFamily,
      letterSpacing,
    }
  },

  onShaping(text, { currentTarget }) {
    const point = currentTarget.getStage()?.getPointerPosition()
    const X = point?.x ?? null
    const Y = point?.y ?? null

    if (!X || !Y) return

    return {
      ...text,
    }
  },

  onShapeUpdated({ currentTarget }) {
    return currentTarget
  },
}
