import {
  Button,
  css,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@mui/material'
import { ActionButton } from 'modules/core/components/ActionButton'

export const Main = styled('div')`
  padding: ${({ theme }) => theme.spacing(4)} 10%;
`

export const Header = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const Title = styled(Typography)`
  font-size: 36px;
  line-height: 36px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.common.white};
`

export const FormContainer = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(5)};
`

export const AddButton = styled(Button)`
  width: 100%;
  font-size: 14px;
  box-shadow: none;
  margin: ${({ theme }) => theme.spacing(1, 0)};
  padding: ${({ theme }) => theme.spacing(0.25, 0)};
  border-radius: 0;
  text-transform: capitalize;
`

export const Label = styled(FormLabel)`
  font-size: 24px;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.common.white};
`

export const FullWidthFormControl = styled(FormControl)`
  width: 100%;
`

export const InputWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const Row = styled('div')`
  margin-top: ${({ theme }) => theme.spacing(2)};
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(2)};

  &:first-of-type {
    margin-top: ${({ theme }) => theme.spacing(0)};
  }
`

export const EditContainer = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(8)};
`

export const ResultContainer = styled('div')`
  position: relative;
  height: 100px;
  overflow-y: auto;
`

export const ResultRow = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(1)};
  transition: all 0.3s;
  padding: ${({ theme }) => theme.spacing(1)};

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.primary.dark};
  }
`

export const ResultName = styled(Typography)``

export const RoleSelector = styled(Select, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  padding: 0;
  font-size: 14px;
  outline: none;
  border-radius: 0;

  & .MuiSelect-select {
    padding: 8px;
    border: 1px solid transparent;
  }

  & .MuiOutlinedInput-notchedOutline {
    outline: none;
    border: none;
    border-radius: 0;
  }

  ${({ error, theme }) =>
    error &&
    css`
      & .MuiSelect-select {
        border-radius: 0;
        border: 1px solid ${theme.palette.error.main};
      }
    `}
`

export const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
`

export const Spacer = styled('div')`
  height: ${({ theme }) => theme.spacing(4)};
`

export const DeleteButton = styled(ActionButton)`
  font-size: 18px;
`
