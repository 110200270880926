import {
  styled,
  Tooltip as BaseTooltip,
  TooltipProps as BaseTooltipProps,
} from '@mui/material'

interface TooltipProps extends BaseTooltipProps {
  backgroundColor?: string
  textColor?: string
}

export const Tooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <BaseTooltip {...props} classes={{ popper: className }} />
  ),
  {
    shouldForwardProp: prop =>
      prop !== 'backgroundColor' && prop !== 'textColor',
  }
)`
  & .MuiTooltip-arrow {
    color: ${({ theme, backgroundColor }) =>
      backgroundColor ?? theme.palette.primary.dark};
  }

  & .MuiTooltip-tooltip {
    background-color: ${({ theme, backgroundColor }) =>
      backgroundColor ?? theme.palette.primary.dark};
    color: ${({ theme, textColor }) =>
      textColor ?? theme.palette.primary.contrastText};
  }
`
