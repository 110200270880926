export type LinkPropsVariants = {
  [key in LINK_VARIANTS]: string
}

export enum LINK_VARIANTS {
  default = 'DEFAULT',
  social = 'SOCIAL',
}

export interface LinkProps {
  variant?: LINK_VARIANTS
}
