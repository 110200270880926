import { forwardRef } from 'react'
import { Rnd, Props } from 'react-rnd'
import { StyledRnd } from './Camera.styled'

export const CameraViewport = forwardRef<Rnd, Props>((props, ref) => (
  <StyledRnd
    ref={ref}
    bounds={'parent'}
    lockAspectRatio={16 / 9}
    resizeHandleClasses={{ bottomRight: 'react-resizable-handle-se' }}
    resizeHandleStyles={{ bottomRight: { right: '0px', bottom: '0px' } }}
    enableResizing={{
      bottom: false,
      bottomLeft: false,
      left: false,
      topLeft: false,
      top: false,
      topRight: false,
      right: false,
      bottomRight: false,
    }}
    {...props}
  />
))
