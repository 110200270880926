import { FC, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { Tooltip } from '@mui/material'
import { Player } from '@sporttotal-tv/dip-coaching-client'
import { DatePicker } from '@mui/x-date-pickers'
// components
import {
  ActionButton,
  ModalDialog,
  TextInput,
  TextInputErrorMessage,
  TextInputLabel,
} from 'modules/core/components'
import { TeamPlayerSelector } from 'modules/team-player/components'
// hooks
import { useTError } from 'modules/core/i18n/hooks'
import {
  useTeamPlayerInjuryUpdate,
  useTeamPlayerInjuryCreate,
} from 'modules/team-player/hooks'
// styled
import { OptionsSelector, OptionsSelectorMenuItem } from 'modules/core/styled'
// constants
import {
  teamPlayerInjuryRehabPeriods,
  teamPlayerInjuryStatuses,
} from 'modules/team-player/constants'
// schema
import { teamPlayerInjurySchema } from 'modules/team-player/schemas'
// types
import { TeamPlayerInjuryFormData } from 'modules/team-player/types'

import {
  InputTextField,
  InputWrapper,
  FormControlWrapper,
  PlayerSelectorWrapper,
} from './InjuriesView.styled'
import { InjuryModalProps } from './InjuriesView.interface'

export const InjuryModal: FC<InjuryModalProps> = ({
  open,
  onClose,
  title,
  item,
}) => {
  const { t } = useTranslation('components', { keyPrefix: 'teamPlayers' })
  const { tError } = useTError(['components'])
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null)
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
  } = useForm<TeamPlayerInjuryFormData>({
    resolver: yupResolver(teamPlayerInjurySchema),
    defaultValues: {
      injury: item?.current_injury,
      dateInjury: item ? new Date(+item?.date_of_injury * 1000) : undefined,
      rehabPeriod: item?.rehab_period,
      injuryStatus: item?.injury_status,
      rehabPeriodMin: item?.rehab_period_min,
      rehabPeriodMax: item?.rehab_period_max,
    },
  })

  const teamPlayerInjuryUpdate = useTeamPlayerInjuryUpdate()
  const teamPlayerInjuryCreate = useTeamPlayerInjuryCreate()

  const onSubmit = (data: TeamPlayerInjuryFormData) => {
    if (item) {
      teamPlayerInjuryUpdate.mutate(
        {
          id: item.id,
          params: {
            current_injury: data.injury,
            date_of_injury: new Date(data.dateInjury).getTime() / 1000,
            rehab_period: data.rehabPeriod,
            injury_status: data.injuryStatus,
            rehab_period_min: data.rehabPeriodMin,
            rehab_period_max: data.rehabPeriodMax,
          },
        },
        {
          onSuccess: onClose,
        }
      )
      return
    }

    if (selectedPlayer) {
      teamPlayerInjuryCreate.mutate(
        {
          player_id: selectedPlayer.id,
          current_injury: data.injury,
          date_of_injury: new Date(data.dateInjury).getTime() / 1000,
          rehab_period: data.rehabPeriod,
          injury_status: data.injuryStatus,
          rehab_period_min: data.rehabPeriodMin,
          rehab_period_max: data.rehabPeriodMax,
        },
        {
          onSuccess: onClose,
        }
      )
    }
  }

  return (
    <ModalDialog open={open} onClose={onClose} title={title}>
      <>
        {!item && (
          <PlayerSelectorWrapper>
            <TeamPlayerSelector
              showError={isSubmitted}
              player={selectedPlayer}
              setPlayer={setSelectedPlayer}
            />
          </PlayerSelectorWrapper>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            label={t('modal.injury.fields.injury')}
            errorMessage={tError(errors.injury?.message)}
            inputProps={{ 'aria-label': 'injury' }}
            {...register('injury')}
          />

          <InputWrapper>
            <TextInputLabel>
              {t('modal.injury.fields.dateInjury')}
            </TextInputLabel>
            <FormControlWrapper>
              <Controller
                name='dateInjury'
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    renderInput={params => (
                      <InputTextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: params.InputProps?.endAdornment,
                          endAdornment: undefined,
                        }}
                        inputProps={{
                          ...params.inputProps,
                          'aria-label': 'dateInjury',
                        }}
                        error={!!errors.dateInjury?.message}
                      />
                    )}
                  />
                )}
              />
              <Tooltip title={tError(errors.dateInjury?.message)}>
                <TextInputErrorMessage>
                  {tError(errors.dateInjury?.message)}
                </TextInputErrorMessage>
              </Tooltip>
            </FormControlWrapper>
          </InputWrapper>
          <TextInput
            type='number'
            label={t('modal.injury.fields.rehabPeriodMin')}
            errorMessage={tError(errors.rehabPeriodMin?.message)}
            inputProps={{ 'aria-label': 'rehabPeriodMin' }}
            {...register('rehabPeriodMin')}
          />
          <TextInput
            type='number'
            label={t('modal.injury.fields.rehabPeriodMax')}
            errorMessage={tError(errors.rehabPeriodMax?.message)}
            inputProps={{ 'aria-label': 'rehabPeriodMax' }}
            {...register('rehabPeriodMax')}
          />
          <InputWrapper>
            <TextInputLabel>
              {t('modal.injury.fields.rehabPeriod')}
            </TextInputLabel>
            <FormControlWrapper>
              <Controller
                name='rehabPeriod'
                control={control}
                render={({ field }) => (
                  <OptionsSelector
                    inputProps={{ 'aria-label': 'rehabPeriod' }}
                    error={!!errors.rehabPeriod?.message}
                    {...field}
                    value={field.value ?? ''}
                  >
                    {teamPlayerInjuryRehabPeriods.map(item => (
                      <OptionsSelectorMenuItem key={item} value={item}>
                        {t(`modal.injury.values.rehabPeriod.${item}`)}
                      </OptionsSelectorMenuItem>
                    ))}
                  </OptionsSelector>
                )}
              />
              <TextInputErrorMessage>
                {tError(errors.rehabPeriod?.message)}
              </TextInputErrorMessage>
            </FormControlWrapper>
          </InputWrapper>
          <InputWrapper>
            <TextInputLabel>
              {t('modal.injury.fields.injuryStatus')}
            </TextInputLabel>
            <FormControlWrapper>
              <Controller
                name='injuryStatus'
                control={control}
                render={({ field }) => (
                  <OptionsSelector
                    inputProps={{ 'aria-label': 'injuryStatus' }}
                    error={!!errors.injuryStatus?.message}
                    {...field}
                    value={field.value ?? ''}
                  >
                    {teamPlayerInjuryStatuses.map(item => (
                      <OptionsSelectorMenuItem key={item} value={item}>
                        {t(`modal.injury.values.injuryStatus.${item}`)}
                      </OptionsSelectorMenuItem>
                    ))}
                  </OptionsSelector>
                )}
              />
              <TextInputErrorMessage>
                {tError(errors.injuryStatus?.message)}
              </TextInputErrorMessage>
            </FormControlWrapper>
          </InputWrapper>

          <ActionButton
            type='submit'
            icon='save'
            text={t('actions.save')}
            position='end'
          />
        </form>
      </>
    </ModalDialog>
  )
}
