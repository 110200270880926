import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import { CreateScheduleScheduleBody } from '@sporttotal-tv/dip-coaching-client'
import { MATCH_LIST_CACHE_KEY } from 'modules/match/hooks'

export const SCHEDULE_CREATE_CACHE_KEY = 'schedule-create'

export const useScheduleCreate = () => {
  const queryClient = useQueryClient()

  return useMutation(
    SCHEDULE_CREATE_CACHE_KEY,
    (params: CreateScheduleScheduleBody) =>
      ApiService.calendar.scheduleCreate(params),
    {
      onSettled: () => {
        queryClient.invalidateQueries(MATCH_LIST_CACHE_KEY, { exact: false })
      },
    }
  )
}
