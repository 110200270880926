import { useCallback, FC, HTMLAttributes } from 'react'
import { AutocompleteProps, Option } from './Autocomplete.interface'
import {
  StyledAutocomplete,
  StyledPopper,
  InputTextField,
} from './Autocomplete.styled'

export const Autocomplete: FC<AutocompleteProps> = ({
  options,
  onChange,
  inputProps = {},
}) => {
  const renderOption = useCallback(
    (props: HTMLAttributes<HTMLElement>, option: Option) => (
      <li {...props} key={option.value}>
        {option.label}
      </li>
    ),
    []
  )

  return (
    <StyledAutocomplete
      // TODO: fix typing
      onChange={(_, data: any) => onChange(data?.value ?? null)}
      options={options}
      PopperComponent={StyledPopper}
      // @ts-ignore
      renderOption={renderOption}
      renderInput={params => (
        <InputTextField
          {...params}
          inputProps={{ ...inputProps, ...params.inputProps }}
        />
      )}
    />
  )
}
