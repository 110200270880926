import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import {
  CreatePlaylistAssetBody,
  PlaylistAsset,
  MatchList,
  PlaylistList,
} from '@sporttotal-tv/dip-coaching-client'
import { MutateContext } from 'modules/core/types/mutate-context.type'
import { PLAYLIST_CACHE_KEY } from 'modules/playlist/hooks'
import { MATCH_LIST_CACHE_KEY } from 'modules/match/hooks'
import { getAssetFromMatchCollection } from 'modules/asset/helpers'
import { InfiniteQuery } from 'modules/core/types'

export const PLAYLIST_ASSET_CREATE_CACHE_KEY = 'playlist-asset-create'

export const usePlaylistAssetCreate = () => {
  const queryClient = useQueryClient()

  return useMutation<
    PlaylistAsset,
    Error,
    CreatePlaylistAssetBody,
    MutateContext<InfiniteQuery<PlaylistList>>
  >(
    PLAYLIST_ASSET_CREATE_CACHE_KEY,
    (params: CreatePlaylistAssetBody) => ApiService.playlistAsset.add(params),
    {
      onMutate: async data => {
        await queryClient.cancelQueries(PLAYLIST_CACHE_KEY, { exact: false })

        const previousData =
          queryClient.getQueriesData<InfiniteQuery<PlaylistList>>(
            PLAYLIST_CACHE_KEY
          )

        const previousMatchData =
          queryClient.getQueriesData<InfiniteQuery<MatchList>>(
            MATCH_LIST_CACHE_KEY
          )

        const asset = getAssetFromMatchCollection(
          previousMatchData,
          data.asset_ids
        )

        if (previousData && asset) {
          queryClient.setQueriesData<InfiniteQuery<PlaylistList> | undefined>(
            PLAYLIST_CACHE_KEY,
            oldData =>
              oldData && {
                pageParams: oldData.pageParams,
                pages: oldData.pages.map(
                  page =>
                    page && {
                      total: page.total,
                      results: page.results.map(playlist => {
                        if (playlist.id === data.playlist_id) {
                          return {
                            ...playlist,
                            assets: [...(playlist.assets || []), asset],
                          }
                        }

                        return playlist
                      }),
                    }
                ),
              }
          )
        }

        return { previousData }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData(prevData[0], prevData[1])
          )
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(PLAYLIST_CACHE_KEY, { exact: false })
      },
    }
  )
}
