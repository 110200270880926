import { UserFeatureTier } from 'modules/user/types'
import { TierCardConfig } from '../types'

import BasicIcon from 'assets/images/tiers/ico-basic.png'
import AdvancedIcon from 'assets/images/tiers/ico-advanced.png'
import ProIcon from 'assets/images/tiers/ico-pro.png'

const BASIC_TIER_FEATURES = [
  'First Month Free',
  'Broadcast Feed',
  'Panorama Feed',
  'Telestration ',
  'Clipping',
  'Playlists ',
  'Tagging ',
  '& more',
]

const ADVANCED_TIER_FEATURES = [
  'First Month Free',
  'Basic Features',
  'Tactical Feed',
  'Team & Player Stats',
  'Heatmaps',
  'Minimaps',
  'Match Momentum',
  '& more',
]

const PROFESSIONAL_TIER_FEATURES = [
  'Basic Features',
  'Advanced Features ',
  'Automated Training Scheduling',
  'Volumetric Technologies ',
  '& more',
]

export const TIERS: Record<UserFeatureTier, TierCardConfig> = {
  basic: {
    tierFeatures: BASIC_TIER_FEATURES,
    icon: BasicIcon,
  },
  advanced: {
    tierFeatures: ADVANCED_TIER_FEATURES,
    icon: AdvancedIcon,
    comingSoon: {
      isComingSoon: true,
      showPrice: true,
    },
  },
  professional: {
    tierFeatures: PROFESSIONAL_TIER_FEATURES,
    icon: ProIcon,
    comingSoon: {
      isComingSoon: true,
      showPrice: false,
    },
  },
} as const
