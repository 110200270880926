import { css, styled, TextField, Typography } from '@mui/material'
import { Tag } from 'modules/core/styled'
import { SearchInput } from 'modules/core/components/SearchInput'

export const Filter = styled('div', {
  shouldForwardProp: prop => prop !== 'hidden',
})<{ hidden?: boolean }>`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  position: absolute;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 20%);
  z-index: 1;
  width: calc(100% - ${({ theme }) => theme.spacing(4)});
  padding: ${({ theme }) => theme.spacing(1.5, 2)};
  transition: padding 0.3s, opacity 0.3s, transform 0s 0s;
  max-height: 75%;
  overflow: auto;

  ${({ hidden }) =>
    hidden &&
    css`
      transition: padding 0.3s, opacity 0.3s, transform 0s 0.3s;
      opacity: 0;
      transform: translateY(-100000px);
      overflow: hidden;
    `}
`

export const ResetFilters = styled(Typography)`
  position: absolute;
  right: ${({ theme }) => theme.spacing(1)};
  top: ${({ theme }) => theme.spacing(1.25)};
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 12px;
  cursor: pointer;
`

export const FilterTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 12px;
  margin-bottom: ${({ theme }) => theme.spacing(1.25)};
`

export const FilterBlock = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(1.75)};
`

export const TagBlock = styled('div')`
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(0.5)};
  margin: ${({ theme }) => theme.spacing(1.75, 0)};
`

export const DateBlock = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1)};
`

export const DateWrapper = styled('div')``

export const DateLabel = styled('div')`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
`

export const DateTitle = styled(Typography)`
  display: flex;
  color: ${({ theme }) => theme.palette.primary.light};
  column-gap: ${({ theme }) => theme.spacing(1)};
`

export const FilterElementsWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.75)};
  row-gap: ${({ theme }) => theme.spacing(1.25)};
  flex-wrap: wrap;
`

export const FilterElement = styled(Tag, {
  shouldForwardProp: prop => prop !== 'selected',
})<{ selected?: boolean }>`
  min-width: 70px;
  text-align: center;
  text-transform: capitalize;
  padding: ${({ theme }) => theme.spacing(0.5)};
  cursor: pointer;
  transition: all 0.3s;

  ${({ selected, theme }) =>
    !selected &&
    css`
      color: ${theme.palette.primary.light};
      background-color: ${theme.palette.darkest};
    `}
`

export const TagInput = styled(SearchInput)`
  background-color: ${({ theme }) => theme.palette.darkest};
`

export const ClickableTag = styled(Tag)`
  cursor: pointer;
`

export const InputTextField = styled(TextField)`
  background-color: ${({ theme }) => theme.palette.darkest};

  & .MuiInputBase-input {
    padding: ${({ theme }) => theme.spacing(1)};
    font-size: 14px;
  }

  & .MuiInputBase-formControl {
    border-radius: 0;
    padding: 0;
  }

  & .MuiInputAdornment-root {
    width: 30px;
  }
`
