import { playerLayoutConfig } from '../PlayerLayoutConfig'
import { playerControlsLayoutConfig } from '../PlayerControlsLayoutConfig'
import { assetsLayoutConfig } from '../AssetsLayoutConfig'
import { playlistLayoutConfig } from '../PlaylistLayoutConfig'
import { generateId } from 'modules/core/helpers/generateId'

export const clippingLayoutPlayerId = generateId('v1')
export const clippingLayoutPlayerControlsId = generateId('v1')
export const clippingLayoutAssetsId = generateId('v1')
export const clippingLayoutPlaylistId = generateId('v1')

export const CLIPPING_TAB_LAYOUT = {
  lg: [
    { ...playerLayoutConfig.lg, i: clippingLayoutPlayerId, w: 9, h: 14 },
    {
      ...playerControlsLayoutConfig.lg,
      i: clippingLayoutPlayerControlsId,
      y: 14,
      x: 0,
      w: 9,
    },
    { ...assetsLayoutConfig.lg, i: clippingLayoutAssetsId, h: 11 },
    {
      ...playlistLayoutConfig.lg,
      i: clippingLayoutPlaylistId,
      h: 9,
      x: 10,
      y: 11,
      w: 3,
    },
  ],
  md: [
    { ...playerLayoutConfig.md, i: clippingLayoutPlayerId },
    {
      ...playerControlsLayoutConfig.md,
      i: clippingLayoutPlayerControlsId,
      y: 14,
      x: 0,
      w: 6,
    },
    { ...assetsLayoutConfig.md, i: clippingLayoutAssetsId, h: 11 },
    {
      ...playlistLayoutConfig.md,
      i: clippingLayoutPlaylistId,
      h: 9,
      x: 6,
      y: 11,
      w: 2,
    },
  ],
  sm: [
    { ...playerLayoutConfig.sm, i: clippingLayoutPlayerId },
    {
      ...playerControlsLayoutConfig.sm,
      i: clippingLayoutPlayerControlsId,
      y: 14,
      x: 0,
      w: 3,
    },
    { ...assetsLayoutConfig.sm, i: clippingLayoutAssetsId, h: 11 },
    {
      ...playlistLayoutConfig.sm,
      i: clippingLayoutPlaylistId,
      h: 9,
      x: 4,
      y: 11,
      w: 1,
    },
  ],
  xs: [
    { ...playerLayoutConfig.xs, i: clippingLayoutPlayerId },
    {
      ...playerControlsLayoutConfig.xs,
      i: clippingLayoutPlayerControlsId,
    },
    { ...assetsLayoutConfig.xs, i: clippingLayoutAssetsId },
    { ...playlistLayoutConfig.xs, i: clippingLayoutPlaylistId },
  ],
  xxs: [
    { ...playerLayoutConfig.xxs, i: clippingLayoutPlayerId },
    {
      ...playerControlsLayoutConfig.xxs,
      i: clippingLayoutPlayerControlsId,
    },
    { ...assetsLayoutConfig.lg, i: clippingLayoutAssetsId },
    { ...playlistLayoutConfig.xxs, i: clippingLayoutPlaylistId },
  ],
}
