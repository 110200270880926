// Events to not show in the highlight component
export const OMITTED_HIGHLIGHT_EVENT_TYPES: number[] = [12, 13, 14]
export const HIGHLIGHT_PAGE_LIMIT = 100

export const OFFICIAL_HIGHLIGHT_CREATION_ACCESS_GROUPS = [
  'admin',
  'scoreboarder',
]

export const HIGHLIGHT_COLORS = [
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FFFF00',
  '#00FFFF',
  '#FF00FF',
  '#C0C0C0',
  '#808080',
  '#800000',
  '#808000',
  '#008000',
  '#800080',
  '#008080',
  '#000080',
  '#FF6666',
  '#FFFF66',
  '#66FF66',
  '#66FFFF',
  '#6666FF',
  '#FF66FF',
  '#FFCCCC',
  '#FFFFCC',
  '#CCFFCC',
  '#CCFFFF',
  '#CCCCFF',
  '#FFCCFF',
]
