import { DialogTitle, Menu, MenuItem, styled, Typography } from '@mui/material'
import { Icon } from 'modules/core/components/Icon'
import { BodyElement } from 'modules/core/styled/Table'

export const TableWrapper = styled('div')`
  overflow-y: scroll;
  flex: 1;
  position: relative;
`

export const RoleElement = styled(BodyElement)`
  text-transform: capitalize;
`

export const TierElement = styled(BodyElement)`
  display: flex;
  align-items: center;
  justify-content: start;
`

export const TeamWrapper = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
`

export const TierBadge = styled('div')`
  border-radius: 14px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0.5)};
  min-width: 78px;
  text-transform: uppercase;
  font-size: 11px;
  background-color: rgba(150, 152, 155, 20%);
  color: ${({ theme }) => theme.palette.primary.light};
`

export const DisplayMenu = styled(Menu)`
  transform: translate(20px, 0px);

  & .MuiList-root {
    background-color: ${({ theme }) => theme.palette.darkest};
    padding: 0;
    min-width: 110px;
  }
`

export const DisplayItem = styled(MenuItem)`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.5)};
  padding: ${({ theme }) => theme.spacing(1, 1.75)};
`

export const DisplayIcon = styled(Icon)`
  width: 16px;
  height: 16px;
`

export const DisplayItemText = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.light};
`

export const Title = styled(DialogTitle)`
  text-align: center;
`
