import { Input, InputAdornment, styled } from '@mui/material'

export const SearchInputComponent = styled(Input)`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  width: 100%;
  padding: 0;

  & .MuiInput-input {
    padding: ${({ theme }) => theme.spacing(1, 1, 1, 0.5)};
    font-size: 14px;
  }
`

export const SearchIconAdornment = styled(InputAdornment)`
  margin-right: 0;
  margin-left: ${({ theme }) => theme.spacing(1.75)};

  & svg {
    width: 16px;

    & path {
      fill: ${({ theme }) => theme.palette.primary.light};
    }
  }
`
