import { useQueryClient } from 'react-query'
import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { TokenService } from 'services/Token.service'
import { SnackbarContext } from 'modules/core/context'
import {
  AUTH_ERROR_CODE,
  NOT_FOUND_CODE,
  SERVER_ERROR_CODE,
} from 'modules/core/constants'
import { SnackBar } from '../context/SnackbarContext/SnackbarContext.interface'

interface UseQueryRetryOptions {
  maxRetries?: number
  suppressError?: boolean
}

const DEFAULT_OPTS: Required<UseQueryRetryOptions> = {
  maxRetries: 2,
  suppressError: false,
}

export const useQueryRetry = (opts?: UseQueryRetryOptions) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('core')
  const { showSnackbar } = useContext(SnackbarContext)

  const { maxRetries, suppressError } = { ...DEFAULT_OPTS, ...opts }

  const triggerSnackbar = useCallback(
    (snackbar: SnackBar) => {
      showSnackbar(snackbar)
      if (suppressError) return
    },
    [suppressError, showSnackbar]
  )

  return useCallback(
    (count: number, error: any) => {
      const hasExceededRetries = count > maxRetries

      if (error?.status === SERVER_ERROR_CODE && hasExceededRetries) {
        triggerSnackbar({
          message: t('alerts.serverError'),
          type: 'error',
        })

        return false
      }

      if (error?.status === NOT_FOUND_CODE) {
        triggerSnackbar({
          message: t('alerts.accessError'),
          type: 'error',
        })

        return false
      }

      if (error?.status === AUTH_ERROR_CODE) {
        TokenService.getInstance().removeAllTokens()
        triggerSnackbar({
          message: t('alerts.activationError'),
          type: 'error',
        })
        queryClient.removeQueries()

        return false
      }

      return count <= maxRetries
    },
    [maxRetries, queryClient, triggerSnackbar, t]
  )
}
