import { css, styled, Typography } from '@mui/material'
import { ResizableBox } from 'react-resizable'
import {
  MENU_WIDTH,
  TELESTRATION_GROUP_HEIGHT,
} from 'modules/telestration/constants'
import { getContrastColor } from 'modules/core/helpers/getContrastColor'

export const Root = styled('div')`
  height: 100%;
`

export const RootContainer = styled('div')`
  // TODO: 46px should be const  change when fixed issue with tests
  height: calc(100% - 46px);
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(1.5)};
  position: relative;
`

export const BlockContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'hidden',
})<{ hidden?: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #000;
  z-index: 10;
  cursor: not-allowed;
  opacity: 0.5;
  transition: height 0.3s, opacity 0.3s;

  ${({ hidden }) =>
    hidden &&
    css`
      height: 0;
      opacity: 0;
      transition: opacity 0.3s 0s, height 0s 0.3s;
      display: none;
    `}
`

export const TimeContainer = styled('div')`
  display: flex;
  position: relative;
`

export const EventDisplayTime = styled(Typography)`
  min-width: ${MENU_WIDTH}%;
  font-size: 12px;
  font-weight: 600;
  padding-top: ${({ theme }) => theme.spacing(1.8)};
  padding-bottom: ${({ theme }) => theme.spacing(1.8)};
`

export const GroupContainer = styled('div')`
  display: flex;
  position: relative;
  border-top: 1px solid ${({ theme }) => theme.palette.common.black};
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.black};
  flex: 1;
`
export const Divider = styled('div')`
  position: absolute;
  width: 1px;
  top: 0;
  bottom: -8%;
  left: ${MENU_WIDTH}%;
  background: ${({ theme }) => theme.palette.secondary.light};
  z-index: 1;
`

export const GroupListContainer = styled('div')`
  min-width: ${MENU_WIDTH}%;
  max-width: ${MENU_WIDTH}%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const ScrollWrapper = styled('div')`
  overflow: hidden;
`

export const GroupMetaContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'active',
})<{ active: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  height: ${TELESTRATION_GROUP_HEIGHT}px;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  border-top: 1px solid ${({ theme }) => theme.palette.secondary.light};
  cursor: pointer;

  ${({ active, theme }) =>
    !active &&
    css`
      color: ${theme.palette.primary.light};
    `}

  &:nth-last-of-type(2) {
    border-bottom: 1px solid ${({ theme }) => theme.palette.secondary.light};
  }
`

export const GroupMetaNameContainer = styled('div')`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
`

export const GroupMetaNameText = styled(Typography)`
  flex: 1;
  font-size: 12px;
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const GroupMetaOptionContainer = styled('div')`
  display: flex;
  align-items: center;
`

export const GroupTracksContainer = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'duration' &&
    prop !== 'offset' &&
    prop !== 'rightOffset' &&
    prop !== 'zoom',
})<{ duration: number; offset: number; rightOffset: number; zoom: number }>`
  width: ${({ duration, zoom }) => duration / zoom}px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: ${({ offset }) => offset}px;
  margin-right: ${({ rightOffset }) => rightOffset}px;

  & > div {
    border-top: 1px solid ${({ theme }) => theme.palette.secondary.light};

    &:last-child {
      border-bottom: 1px solid ${({ theme }) => theme.palette.secondary.light};
    }
  }
`

export const GroupTrack = styled('div')`
  width: 100%;
  height: ${TELESTRATION_GROUP_HEIGHT}px;
  position: relative;
  background-color: ${({ theme }) => theme.palette.primary.dark};
`

export const GroupTrackShape = styled('div', {
  shouldForwardProp: prop => prop !== 'active',
})<{
  color: string
  active?: boolean
}>`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-left: ${({ theme }) => theme.spacing(1)};
  font-size: 13px;
  cursor: pointer;
  user-select: none;
  background-color: ${({ color }) => color};

  ${({ color }) =>
    color &&
    css`
      & path {
        fill: ${getContrastColor(color)};
      }
    `}

  ${({ active, theme }) =>
    active &&
    css`
      &:after {
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        top: 0;
        left: 0;
        clip-path: polygon(0 0, 0% 100%, 100% 0);
        background-color: ${theme.palette.common.white};
      }
    `}
`

export const DraggableWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'active',
})<{
  active: boolean
}>`
  ${({ active }) =>
    active &&
    css`
      .react-draggable {
        z-index: 1;
      }
    `}
`

export const GroupTrackShapeResizable = styled(ResizableBox, {
  shouldForwardProp: prop => prop !== 'startTime' && prop !== 'onTop',
})<{
  startTime: number
}>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: absolute;
  min-width: 35px;
  top: 0;
  left: ${({ startTime }) => startTime}px;
`

export const GroupActionsContainer = styled('div')`
  display: flex;
  position: relative;
  width: ${MENU_WIDTH}%;
  padding-bottom: ${({ theme }) => theme.spacing(1)};
  margin-right: ${({ theme }) => theme.spacing(1)};
`

export const GroupActionsFullContainer = styled('div')`
  display: flex;
  position: relative;
  width: 100%;
`

export const PlaybackHead = styled('div')<{ offset: number; height: number }>`
  position: fixed;
  background: red;
  width: 1px;
  height: ${({ height }) => height}px;
  transform: translateX(${({ offset }) => offset}px);
  z-index: 2;

  opacity: ${({ offset }) => (offset === 0 ? '0' : '1')};

  &:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 9px;
    background: inherit;
    transform: translate(-50%, -100%);
  }
`

export const ClipTimeLineWrapper = styled('div')`
  flex: 1;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
`

export const Track = styled('div')`
  width: 100%;
  height: 2px;
  margin: auto 0;
  background-color: ${({ theme }) => theme.palette.primary.light};
`

export const TrackSecondLine = styled('div', {
  shouldForwardProp: prop => prop !== 'leftPosition',
})<{ leftPosition: number }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  left: ${({ leftPosition }) => leftPosition}px;
  height: 75%;
  width: 1px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
`

export const TelestrationElement = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'left' && prop !== 'durationWidth' && prop !== 'color',
})<{
  left: number
  durationWidth: number
  color: string
}>`
  cursor: pointer;
  position: absolute;
  z-index: 2;
  overflow: hidden;
  column-gap: 10px;
  left: ${({ left }) => left}px;
  min-width: 3px;
  height: 10px;
  transform: translateY(-50%);
  top: 50%;

  background-color: ${({ color }) => color};
  width: ${({ durationWidth }) => durationWidth}px;
`
