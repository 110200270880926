import { Checkbox, css, styled } from '@mui/material'

export const GridTable = styled('div', {
  shouldForwardProp: prop => prop !== 'columns' && prop !== 'columnsTemplate',
})<{ columns: number; columnsTemplate?: string[] }>`
  max-height: 100%;
  overflow: auto;
  display: grid;
  padding-right: ${({ theme }) => theme.spacing(0.5)};
  grid-template-columns: repeat(${({ columns }) => columns}, auto);

  // every odd row of table (excluded header)
  ${({ columns, theme }) => {
    const oddRows = Array(columns)
      .fill(null)
      .map(
        (el, index) =>
          `& > div:nth-of-type(${columns * 2}n + ${index + 1 + columns})`
      )
      .join(',')
    return css`
      ${oddRows} {
        background-color: ${theme.table.primaryRow};
      }
    `
  }}

  ${({ columnsTemplate }) =>
    columnsTemplate &&
    css`
      grid-template-columns: ${columnsTemplate.join(' ')};
    `}
`

export const GridTableCell = styled('div', {
  shouldForwardProp: prop => prop !== 'justify' && prop !== 'leftSticky',
})<{ justify?: 'flex-start' | 'center' | 'flex-end'; leftSticky?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0.75)};
  background-color: ${({ theme }) => theme.table.secondaryRow};
  min-height: 30px;
  font-size: 14px;
  line-height: 16px;

  ${({ justify }) =>
    justify &&
    css`
      justify-content: ${justify};
    `}

  ${({ justify }) =>
    justify === 'center' &&
    css`
      text-align: center;
    `}

  ${({ leftSticky }) =>
    leftSticky &&
    css`
      position: sticky;
      left: 0;
      z-index: 1;
    `}
`

export const GridTableHeaderCell = styled(GridTableCell, {
  shouldForwardProp: prop => prop !== 'leftSticky',
})<{ leftSticky?: boolean }>`
  align-items: flex-end;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.light};
  position: sticky;
  top: 0;
  z-index: 2;

  ${({ leftSticky }) =>
    leftSticky &&
    css`
      left: 0;
      z-index: 3;
    `}
`

export const ActionCheckbox = styled(Checkbox)`
  padding: 0;

  & svg {
    background-color: ${({ theme }) => theme.palette.background.default};
    border: 1px solid ${({ theme }) => theme.palette.secondary.light};
    border-radius: 3px;
    height: 16px;
    width: 16px;

    & path {
      fill: ${({ theme }) => theme.palette.background.default};
    }
  }

  &.Mui-checked {
    & svg {
      background-color: ${({ theme }) => theme.palette.background.default};

      & path {
        fill: ${({ theme }) => theme.palette.primary.light};
      }
    }
  }
`
