import { useNavigate } from 'react-router-dom'
import { Main, Title, CoachingButton } from './NotFound.styled'

export const NotFound = () => {
  const navigate = useNavigate()

  return (
    <Main>
      <Title>404</Title>
      <CoachingButton onClick={() => navigate('/')}>
        Return to home
      </CoachingButton>
    </Main>
  )
}
