import { createMutationHookWithBody } from 'modules/core/api/hooks/utils'
import { ApiService } from 'services/Api.service'
import { PLAYING_SURFACE_LIST_CACHE_KEY } from './usePlayingSurfaceList'

export const [usePlayingSurfaceUpdate] = createMutationHookWithBody(
  ApiService.playingSurface.update,
  queryClient => ({
    onSuccess: () => {
      queryClient.invalidateQueries(PLAYING_SURFACE_LIST_CACHE_KEY, {
        exact: false,
      })
    },
  })
)
