import { PropsWithChildren, FC } from 'react';
import { TypographyProps } from '@mui/material';
import { StyledText } from '.';

interface Props extends PropsWithChildren {
  tt?: string;
}

export const Text: FC<Props & TypographyProps> = ({
  children,
  sx,
  ...textProps
}) => {
  return (
    <StyledText {...textProps} sx={{ ...sx, whiteSpace: 'pre-wrap' } as never}>
      {children}
    </StyledText>
  );
};
