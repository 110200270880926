import { styled } from '@mui/material'

export const Root = styled('div')`
  margin: ${({ theme }) => theme.spacing(6)} 10%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
  // theme.spacing(12) needs because we have margin theme.spacing(6) at the top and bottom
  height: calc(
    100vh - ${({ theme }) => theme.toolbarHeight}px -
      ${({ theme }) => theme.spacing(12)}
  );
`

export const Wrapper = styled('div')`
  height: 100%;
  padding: ${({ theme }) => theme.spacing(2.5)};
  background-color: ${({ theme }) => theme.palette.primary.main};

  overflow: hidden;
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(5)};
`
export const TableWrapper = styled('div')`
  overflow-y: auto;
  flex: 1;
  margin-top: ${({ theme }) => theme.spacing(3)};
`
