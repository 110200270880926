import { FC, ReactNode } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { User } from '@sporttotal-tv/dip-coaching-client'
// components
import { LoaderContainer } from 'modules/core/components'
// Constants
import { routes } from 'modules/core/routes'
// Hooks
import { useObservable } from 'modules/core/hooks'
import { useUser } from 'modules/user/hooks'
// Services
import { TokenService } from 'services/Token.service'
// utils
import { doesUserHaveRole, doesUserHaveTierAccess } from 'modules/user/utils'

interface IProtectedRoute {
  restricted?: boolean
  redirectPath?: string
  children: ReactNode
}

interface ProtectedGlobalRouteProps {
  roles?: string[]
  tier?: User['feature_tier']
  redirectPath?: string
  children: ReactNode
}

const ProtectedRoute: FC<IProtectedRoute> = ({
  restricted = true,
  redirectPath = '/',
  children,
}) => {
  const token = useObservable(TokenService.getInstance().getToken())
  const isAuthenticated = token !== null

  if (!isAuthenticated && restricted) {
    return <Navigate to={redirectPath} replace />
  }

  return <>{children}</>
}

const ProtectedGlobalRoute: FC<ProtectedGlobalRouteProps> = ({
  roles,
  tier,
  redirectPath = '/profile/dashboard',
  children,
}) => {
  const token = useObservable(TokenService.getInstance().getToken())
  const isAuthenticated = token !== null

  const enabledRoles = !!roles?.length && isAuthenticated
  const enabledTiers = !!tier && isAuthenticated
  const enabled = enabledRoles || enabledTiers
  const user = useUser({ enabled: enabled })

  if (user.isLoading && enabled) {
    return (
      <LoaderContainer variant='full' isLoading={true}>
        <div />
      </LoaderContainer>
    )
  }

  if (enabledRoles && !doesUserHaveRole(user.data, roles)) {
    return <Navigate to={redirectPath} replace />
  }

  if (enabledTiers && !doesUserHaveTierAccess(user.data, tier)) {
    return <Navigate to={redirectPath} replace />
  }

  return <>{children}</>
}

export const Router = () => {
  return (
    <Routes>
      {routes.map(
        (
          { layoutPath, routes, requiredRoles, requiredTier, layout: Layout },
          index
        ) => (
          <Route
            key={index}
            path={layoutPath}
            element={
              <ProtectedGlobalRoute roles={requiredRoles} tier={requiredTier}>
                <Routes>
                  {routes.map(
                    (
                      {
                        path,
                        element,
                        restricted,
                        layout: NestedLayout = Layout,
                      },
                      routeIndex
                    ) => (
                      <Route
                        key={routeIndex}
                        path={path}
                        element={
                          <NestedLayout>
                            <ProtectedRoute restricted={restricted}>
                              {element}
                            </ProtectedRoute>
                          </NestedLayout>
                        }
                      />
                    )
                  )}
                </Routes>
              </ProtectedGlobalRoute>
            }
          />
        )
      )}
    </Routes>
  )
}
