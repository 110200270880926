import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
import {
  FlexColumn,
  FlexRow,
} from 'modules/landing/components/shared/Flex/styled'
import { Text } from 'modules/landing/components/shared/Text/Text'
import { Card } from 'modules/landing/components/shared/Card/Card'
import { Button } from 'modules/landing/components/shared/Button/Button'
import { BUTTON_VARIANTS } from '../../shared/Button/utils'
import { FEATURES, PRICING, ProductInterface } from './utils'
import { StyledDivider, StyledImage } from './styled'
import { useProductList } from 'modules/product/hooks'

import pricingBg from 'modules/landing/assets/backgrounds/pricing-section-bg.png'
import { TIERS } from 'modules/subscription/constants'

export const PricingSection = () => {
  const theme = useTheme()

  const { t, i18n } = useTranslation(['pages'])

  const isSmallDesktop = useMediaQuery({
    minWidth: `${1000}px`,
  })

  const { data: productListData } = useProductList()

  return (
    <FlexColumn
      id='pricing'
      sx={{
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '26px',
        height: 'auto',
        background: `transparent url(${pricingBg}) 0% 0% no-repeat padding-box`,
        backgroundSize: 'cover',
        padding: '18px 0 35px',
      }}
    >
      <Text
        variant='h2'
        sx={{
          font: 'normal normal normal 33px/30px Bebas Neue',
          fontWeight: '800',
          letterSpacing: '0',
          textAlign: 'center',
          color: theme.palette.secondary.contrastText,
        }}
      >
        {t(`landing.sections.pricing.title`)}
      </Text>

      <FlexRow
        sx={{
          width: '100%',
          height: 'auto',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '30px',
          marginTop: '-10px',
          flexWrap: !isSmallDesktop ? 'wrap' : 'auto',
        }}
      >
        {productListData?.MONTHLY.map(
          ({ active, price, metadata }: ProductInterface, i: number) => {
            const productTier = metadata.TIER in TIERS
            if (!productTier) return null

            const tier = TIERS[metadata.TIER as keyof typeof TIERS]
            const isComingSoon = tier.comingSoon?.isComingSoon

            return (
              <FlexColumn
                key={`pricing-${i}`}
                sx={{ width: '280px', alignItems: 'center' }}
              >
                <StyledImage src={PRICING[i].icon} />
                <Card
                  sx={{
                    width: '280px',
                    height: 'auto',
                    background: theme.palette.background.default,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'end',
                  }}
                >
                  <FlexColumn
                    sx={{
                      padding: '38px 20px 19px 20px',
                      alignItems: 'center',
                    }}
                  >
                    <FlexColumn
                      sx={{
                        alignItems: 'center',
                        height: '243px',
                        paddingBottom: '20px',
                      }}
                    >
                      <Text
                        sx={{
                          font: 'normal normal bold 20px/30px "Exo 2"',
                          textAlign: 'center',
                          color: theme.palette.primary.contrastText,
                          paddingBottom: '9px',
                        }}
                      >
                        {t(`landing.sections.pricing.item.${i}.title`)}
                      </Text>

                      {i < 2 && (
                        <>
                          <FlexRow
                            sx={{ justifyContent: 'start', padding: '3px' }}
                          >
                            <Text
                              sx={{
                                font: 'normal normal bold 18px/34px "Exo 2"',
                                textAlign: 'center',
                                color: theme.palette.secondary.main,
                                paddingBottom: '3px',
                              }}
                            >
                              {t(`landing.sections.pricing.item.${i}.currency`)}
                            </Text>
                            <Text
                              sx={{
                                font: 'normal normal bold 40px/48px "Exo 2"',
                                textAlign: 'center',
                                color: theme.palette.secondary.main,
                                paddingBottom: '3px',
                              }}
                            >
                              {price.unit_amount / 100}
                            </Text>
                          </FlexRow>
                          <Text
                            sx={{
                              font: 'normal normal normal 14px/17px "Exo 2"',
                              textAlign: 'center',
                              color: theme.palette.primary.contrastText,
                            }}
                          >
                            {t(`landing.sections.pricing.item.${i}.period`)}
                          </Text>
                        </>
                      )}

                      <Text
                        sx={{
                          font: 'normal normal normal 14px/24px "Exo 2"',
                          letterSpacing: '0px',
                          color: theme.palette.primary.contrastText,
                          textAlign: 'center',
                          width: i18n.language === 'en' ? '200px' : '240px',
                          paddingTop: '18px',
                          paddingBottom: '18px',
                        }}
                      >
                        {t(`landing.sections.pricing.item.${i}.text`)}
                      </Text>
                    </FlexColumn>

                    {isSmallDesktop && (
                      <>
                        <StyledDivider />
                        <FlexColumn
                          sx={{
                            height: '250px',
                            paddingTop: '27px',
                            paddingBottom: '23px',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {FEATURES[i].map((_, j) => (
                            <Text
                              key={`feature-${j}`}
                              sx={{
                                font: 'normal normal normal 18px/30px Bebas Neue',
                                fontWeight: '700',
                                letterSpacing: '0px',
                                textAlign: 'center',
                                color: theme.palette.secondary.main,
                                textTransform: 'uppercase',
                              }}
                            >
                              {t(
                                `landing.sections.pricing.item.${i}.features.${j}`
                              )}
                            </Text>
                          ))}
                        </FlexColumn>
                        <StyledDivider />
                      </>
                    )}

                    <FlexColumn sx={{ height: '62px' }}>
                      <Button
                        disabled={!active || isComingSoon}
                        variant={BUTTON_VARIANTS.primary}
                        href={PRICING[i].href}
                        sx={{
                          marginTop: '22px',
                          font: "normal normal bold 10px/12px 'Exo 2'",
                        }}
                      >
                        {t(`landing.sections.pricing.item.${i}.buttons`)}
                      </Button>
                    </FlexColumn>
                  </FlexColumn>
                </Card>
              </FlexColumn>
            )
          }
        )}
      </FlexRow>
    </FlexColumn>
  )
}
