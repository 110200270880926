import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { ListAdminUserAccessListOptions } from '@sporttotal-tv/dip-coaching-client'
import { useQueryRetry } from 'modules/core/hooks'

export const ADMIN_USER_ACCESS_LIST_CACHE_KEY = 'admin-user-access-list'

export const useAdminUserAccessList = (
  options?: ListAdminUserAccessListOptions
) => {
  const handleQueryRetry = useQueryRetry()

  return useQuery(
    [ADMIN_USER_ACCESS_LIST_CACHE_KEY, options],
    () => ApiService.admin.access.user.list(options ?? {}),
    {
      retry: handleQueryRetry,
      staleTime: Infinity,
    }
  )
}
