import React, { DragEvent, FC, useEffect } from 'react'
import { format, differenceInMinutes } from 'date-fns'
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { styled } from '@mui/material'
import { useReduxDispatch } from 'modules/core/hooks'
import {
  setActiveCalendarEvent,
  setCalendarDraggingEvent,
} from 'modules/calendar/redux'
import { CalendarEvent } from 'modules/calendar/types'

const Marker = styled('div')`
  overflow: hidden;
  position: absolute;
  border: 1px solid #ff004490;
  background-color: #ff004490;
  padding: 1px 3px;
  border-radius: 3px;
  cursor: pointer;
  z-index: 50;
  min-height: 24px;
`

const MarkerText = styled('div')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const BeginEnd = styled('div')`
  white-space: nowrap;
  overflow: hidden;
  font-size: 10px;
`

const ExtraInfo = styled(MarkerText)`
  font-size: 7px;
`

function getStyles(
  left: number,
  top: number,
  isDragging: boolean,
  partOfStyle: React.CSSProperties
): React.CSSProperties {
  const transform = `translate3d(${left}px, ${top}px, 0)`

  return {
    position: 'absolute',
    transform: isDragging ? transform : 'initial',
    WebkitTransform: isDragging ? transform : 'initial',
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : '',
    ...partOfStyle,
  }
}

type EventMarkProps = {
  calendarEvent: CalendarEvent
  selectedDay: Date
  len: number
  sq: number
  isEditable?: boolean
}

const EventMark: FC<EventMarkProps> = ({
  calendarEvent,
  selectedDay,
  len,
  sq,
  isEditable = false,
}) => {
  const dispatch = useReduxDispatch()

  const beginDate = new Date(calendarEvent.begin)
  const endDate = new Date(calendarEvent.end)

  const beginDateFormatted = format(
    beginDate,
    format(beginDate, 'mm') === '00' ? 'HH' : 'HH:mm'
  )
  const endDateFormatted = format(
    endDate,
    format(endDate, 'mm') === '00' ? 'HH' : 'HH:mm'
  )

  const currentDay =
    selectedDay.getDate() === beginDate.getDate() ? beginDate : selectedDay
  const initTime = new Date(format(currentDay, 'yyyy/MM/dd 0:0:0'))
  const position = differenceInMinutes(currentDay, initTime) + 2

  const duration = differenceInMinutes(endDate, currentDay) - 3

  const viewEvent = (calendarEvent: CalendarEvent) => () => {
    dispatch(setActiveCalendarEvent(calendarEvent))
  }

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'box',
    item: { type: 'box' },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: isEditable,
  }))

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: false })
  }, [preview])

  const left = (100 / len) * sq + 1

  const partOfStyle: React.CSSProperties = {
    marginTop: position,
    height: duration,
    width: `calc((100% / ${len}) - 2px)`,
    marginLeft: `calc(100% / ${len} * ${sq})`,
  }

  const onDragStart =
    (calendarEvent: CalendarEvent) => (eventEl: DragEvent<HTMLDivElement>) => {
      const width =
        eventEl.currentTarget.parentElement?.parentElement?.offsetWidth ?? 0
      const height = eventEl.currentTarget.clientHeight + 5

      dispatch(
        setCalendarDraggingEvent({
          draggingEventId: calendarEvent.id,
          calendarEvent,
          ghostProperties: { width, height },
        })
      )
    }

  return (
    <Marker
      id={`${calendarEvent.id}`}
      ref={drag}
      onDragStart={onDragStart(calendarEvent)}
      style={getStyles(left, position / 57 - 2, isDragging, partOfStyle)}
      onClick={viewEvent(calendarEvent)}
    >
      <MarkerText>{calendarEvent.title}</MarkerText>
      <BeginEnd>
        <span>{beginDateFormatted}</span>
        <span> - </span>
        <span>{endDateFormatted}</span>
      </BeginEnd>
      <ExtraInfo>{`[${calendarEvent.id}]`}</ExtraInfo>
    </Marker>
  )
}

export default EventMark
