import { css, styled } from '@mui/material'

export const Badge = styled('div', {
  shouldForwardProp: prop => prop !== 'variant',
})<{ variant: 'default' | 'success' | 'error' | 'warning' }>`
  border-radius: 14px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0.5)};
  min-width: 78px;
  text-transform: uppercase;
  font-size: 11px;
  max-height: 20px;

  ${({ variant, theme }) =>
    variant === 'default' &&
    css`
      background-color: rgba(150, 152, 155, 20%);
      color: ${theme.palette.primary.light};
    `}

  ${({ variant, theme }) =>
    variant === 'success' &&
    css`
      background-color: rgba(0, 237, 9, 20%);
      color: ${theme.palette.success.main};
    `}

  ${({ variant, theme }) =>
    variant === 'error' &&
    css`
      background-color: rgba(229, 66, 66, 20%);
      color: ${theme.palette.error.main};
    `}

  ${({ variant, theme }) =>
    variant === 'warning' &&
    css`
      background-color: rgba(214, 162, 19, 20%);
      color: ${theme.palette.warning.main};
    `}
`
