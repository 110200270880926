import { ApiService } from 'services/Api.service'
import { createMutationHookWithBody } from 'modules/core/api/hooks/utils'
import { merge } from 'lodash'
import { CAMERA_DATA_CACHE_KEY } from './'

export const [useCameraDataUpdate] = createMutationHookWithBody(
  ApiService.camera.updateData,
  (queryClient, params) => ({
    onMutate: variables => {
      const currentData = queryClient.getQueryData([
        CAMERA_DATA_CACHE_KEY,
        params,
      ])
      const updatedData = merge(currentData, variables)

      queryClient.setQueryData([CAMERA_DATA_CACHE_KEY, params], updatedData)
    },
    onSuccess: () => {
      // Camera shadow update takes a moment before changes are present on camera shadow fetch
      setTimeout(
        () =>
          queryClient.invalidateQueries(CAMERA_DATA_CACHE_KEY, {
            exact: false,
          }),
        750
      )
    },
  })
)
