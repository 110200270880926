import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { matchReducer } from 'modules/match/redux'
import { telestrationReducer } from 'modules/telestration/redux'
import { rootSaga } from 'modules/core/redux'
import { dropReducer } from 'modules/analyser/redux'
import { calendarReducer } from 'modules/calendar/redux'
import { layoutReducer, layoutCacheReducer } from 'modules/layout/redux'
import { videoPlayersReducer } from 'modules/video-player/redux'
import { queryClient } from 'providers/QueryProvider'

const sagaMiddleware = createSagaMiddleware({
  context: {
    queryClient,
  },
})

export const store = configureStore({
  reducer: {
    match: matchReducer,
    telestration: telestrationReducer,
    drop: dropReducer,
    layout: layoutReducer,
    videoPlayers: videoPlayersReducer,
    layoutCache: layoutCacheReducer,
    calendar: calendarReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().prepend(sagaMiddleware),
})

sagaMiddleware.run(rootSaga)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
