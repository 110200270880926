// Hooks
import { useEffect, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { useReduxDispatch, useReduxSelector } from 'modules/core/hooks'
import { useScheduleCreate } from 'modules/schedule/hooks'
import { useUserTeams } from 'modules/user/hooks'
// Context
import { SnackbarContext } from 'modules/core/context'
// Redux
import {
  selectEventDialogData,
  selectOpenDialog,
  setEditDialogOpen,
  setViewDialogOpen,
} from 'modules/calendar/redux'
// Utils
import { format } from 'date-fns'
// Constants
import { EVENT_CREATE_DIALOG_DATE_FORMAT } from '../constants'

// Components
import {
  css,
  Input,
  styled,
  Tooltip,
  Button,
  DialogActions,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import {
  ModalDialog,
  TextInput,
  TextInputErrorMessage,
  TextInputLabel,
  Autocomplete,
} from 'modules/core/components'
import {
  FormControlWrapper,
  InputTextField,
  InputWrapper,
} from 'modules/team-player/components/GeneralView/GeneralView.styled'

export const InputText = styled(Input, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>`
  background-color: ${({ theme }) => theme.palette.darkest};
  padding: 0;
  transition: border 0.3s;
  border: 1px solid transparent;

  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.palette.error.main};
    `}

  & .MuiInput-input {
    padding: 8px;
    font-size: 14px;
    width: 100%;
  }
`

export const StyledButton = styled(Button)`
  font-size: 14px;
  box-shadow: none;
  padding: 0 ${({ theme }) => theme.spacing(1.5)};
  border-radius: 0;
  text-transform: capitalize;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
`

type CalendarEventFormData = {
  title: string
  eventBeginDateTime: Date
  eventEndDateTime: Date
  teamId: string
}

const CalendarEventDialog = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'calendar' })

  const dispatch = useReduxDispatch()
  const openDialog = useReduxSelector(selectOpenDialog)
  const eventDialogData = useReduxSelector(selectEventDialogData)

  const schedule = useScheduleCreate()
  const { showSnackbar } = useContext(SnackbarContext)

  const { data: userTeams } = useUserTeams({
    expand: ['team'],
    page_limit: 50,
  })

  const { title, eventBeginDateTime, eventEndDateTime } = eventDialogData

  const {
    register,
    handleSubmit,
    setError,
    control,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<CalendarEventFormData>({
    defaultValues: {
      title,
      eventBeginDateTime: eventBeginDateTime ?? undefined,
      eventEndDateTime: eventEndDateTime ?? undefined,
      teamId: undefined,
    },
  })

  const [formEventBeginDateTime, formEventEndDateTime] = watch([
    'eventBeginDateTime',
    'eventEndDateTime',
  ])

  useEffect(() => {
    if (eventBeginDateTime) {
      setValue('eventBeginDateTime', eventBeginDateTime)
      clearErrors('eventBeginDateTime')
    }
    if (eventEndDateTime) {
      setValue('eventEndDateTime', eventEndDateTime)
      clearErrors('eventEndDateTime')
    }
  }, [eventBeginDateTime, eventEndDateTime, setValue, clearErrors])

  useEffect(() => {
    if (title) setValue('title', title)
  }, [title, setValue])

  const handleCloseDialog = () => {
    dispatch(setEditDialogOpen(false))
    dispatch(setViewDialogOpen(false))
  }

  const handleOnSubmit = (data: CalendarEventFormData) => {
    const today = new Date()
    const teamId = data.teamId

    if (!teamId) return setError('teamId', { type: 'manual', message: 'Error' })

    const isStartDateInvalid = data.eventBeginDateTime < today
    const isEndDateInvalid = data.eventEndDateTime < today

    if (isStartDateInvalid || isEndDateInvalid) {
      isStartDateInvalid &&
        setError('eventBeginDateTime', {
          type: 'manual',
          message: 'Error',
        })

      isEndDateInvalid &&
        setError('eventEndDateTime', {
          type: 'manual',
          message: 'Error',
        })

      return
    }

    schedule.mutate(
      {
        start: data.eventBeginDateTime.getTime(),
        end: data.eventEndDateTime.getTime(),
        title: data.title,
        teamId: teamId,
      },
      {
        onError: () => {
          showSnackbar({
            message: t('modal.create.status.error'),
            type: 'error',
          })
        },
      }
    )

    showSnackbar({
      message: t('modal.create.status.request'),
      type: 'info',
    })

    handleCloseDialog()
  }

  const onSubmit = (data: CalendarEventFormData) => handleOnSubmit(data)

  const teamOptions = useMemo(() => {
    if (!userTeams) return []

    return userTeams.results.map(({ team }) => ({
      value: team?.id ?? '',
      label: team?.name ?? '',
    }))
  }, [userTeams])

  const buttonDisabled =
    formEventBeginDateTime > formEventEndDateTime ? true : false

  return (
    <ModalDialog
      title={t('modal.create.modalTitle')}
      open={openDialog}
      onClose={handleCloseDialog}
      keepMounted={true}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          label={t('modal.create.title')}
          inputProps={{ 'aria-label': 'title' }}
          {...register('title')}
        />
        <InputWrapper>
          <TextInputLabel>{t('modal.create.team')}</TextInputLabel>
          <FormControlWrapper>
            <Controller
              name='teamId'
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={onChange}
                  options={teamOptions}
                  inputProps={{ value }}
                />
              )}
            />
          </FormControlWrapper>
        </InputWrapper>
        <InputWrapper>
          <TextInputLabel>{t('modal.create.beginDate')}</TextInputLabel>
          <FormControlWrapper>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                columnGap: 4,
              }}
            >
              <Controller
                name='eventBeginDateTime'
                control={control}
                render={({ field }) => (
                  <DateTimePicker
                    {...field}
                    minDate={new Date()}
                    renderInput={params => (
                      <InputTextField
                        sx={{ flex: 1 }}
                        {...params}
                        error={Boolean(errors.eventBeginDateTime)}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: params.InputProps?.endAdornment,
                          endAdornment: undefined,
                        }}
                        inputProps={{
                          ...params.inputProps,
                          value: format(
                            field.value ?? new Date(),
                            EVENT_CREATE_DIALOG_DATE_FORMAT
                          ),
                          disabled: true,
                          'aria-label': 'eventBeginDate',
                        }}
                      />
                    )}
                  />
                )}
              />
            </div>

            <Tooltip title={''}>
              <TextInputErrorMessage></TextInputErrorMessage>
            </Tooltip>
          </FormControlWrapper>
        </InputWrapper>
        <InputWrapper>
          <TextInputLabel>{t('modal.create.endDate')}</TextInputLabel>
          <FormControlWrapper>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                columnGap: 4,
              }}
            >
              <Controller
                name='eventEndDateTime'
                control={control}
                render={({ field }) => (
                  <DateTimePicker
                    {...field}
                    minDate={new Date()}
                    renderInput={params => (
                      <InputTextField
                        sx={{ flex: 1 }}
                        {...params}
                        error={Boolean(errors.eventEndDateTime)}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: params.InputProps?.endAdornment,
                          endAdornment: undefined,
                        }}
                        inputProps={{
                          ...params.inputProps,
                          value: format(
                            field.value ?? new Date(),
                            EVENT_CREATE_DIALOG_DATE_FORMAT
                          ),
                          disabled: true,
                          'aria-label': 'eventEndDate',
                        }}
                      />
                    )}
                  />
                )}
              />
            </div>

            <Tooltip title={''}>
              <TextInputErrorMessage>{''}</TextInputErrorMessage>
            </Tooltip>
          </FormControlWrapper>
        </InputWrapper>

        <DialogActions>
          <StyledButton
            type={'submit'}
            color='secondary'
            variant='contained'
            disabled={buttonDisabled}
          >
            {t('modal.buttons.save')}
          </StyledButton>
        </DialogActions>
      </form>
    </ModalDialog>
  )
}

export default CalendarEventDialog
