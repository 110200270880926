import { ApiService } from 'services/Api.service'
import { createMutationHook } from 'modules/core/api/hooks/utils'
import { RESOURCE_CACHE_KEYS } from '../constants'

export const [useResourceTransfer] = createMutationHook(
  ApiService.resource.transfer,
  queryClient => ({
    onSuccess: (_, body) => {
      const cacheKeys = RESOURCE_CACHE_KEYS[body.resource]

      for (const key of cacheKeys) {
        queryClient.invalidateQueries(key)
      }
    },
  })
)
