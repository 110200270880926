import { LayoutIndex } from 'modules/layout/constants/LayoutIndex'
import { generateId } from 'modules/core/helpers/generateId'

const id = generateId('v1')

export const minimapLayoutConfig = {
  type: LayoutIndex.MINIMAP,
  lg: {
    i: id,
    x: 3,
    y: 15,
    w: 3,
    h: 7,
    minW: 3,
    minH: 3,
    maxW: 5,
    maxH: 20,
    isDraggable: false,
    isResizable: false,
  },
  md: {
    i: id,
    x: 2,
    y: 15,
    w: 2,
    h: 6,
    minW: 2,
    minH: 3,
    maxW: 4,
    maxH: 20,
    isDraggable: false,
    isResizable: false,
  },
  sm: {
    i: id,
    x: 1,
    y: 15,
    w: 1,
    h: 3,
    minW: 1,
    minH: 3,
    maxW: 5,
    maxH: 20,
    isDraggable: false,
    isResizable: false,
  },
  xs: {
    i: id,
    x: 0,
    y: 0,
    w: 1,
    h: 3,
    minW: 1,
    minH: 3,
    maxW: 2,
    maxH: 20,
    isDraggable: false,
    isResizable: false,
  },
  xxs: {
    i: id,
    x: 0,
    y: 0,
    w: 1,
    h: 3,
    minW: 1,
    minH: 3,
    maxW: 1,
    maxH: 20,
    isDraggable: false,
    isResizable: false,
  },
}
