import { useEffect, Dispatch, RefObject, SetStateAction } from 'react'
import Hls from 'hls.js'

export const useSource = (
  player: RefObject<HTMLVideoElement>,
  source?: string,
  setLoader?: Dispatch<SetStateAction<boolean>>
) => {
  useEffect(() => {
    if (!source) return

    setLoader && setLoader(true)
    const videoPlayer = player.current
    const isHls = source.endsWith('m3u8')

    if (!videoPlayer) return

    if (!Hls.isSupported() || !isHls) {
      videoPlayer.src = source
      return
    }

    const hls = new Hls()
    hls.loadSource(source)
    hls.attachMedia(player.current)

    return () => hls.destroy()
  }, [source, player, setLoader])
}
