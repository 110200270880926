import { css, styled, Typography } from '@mui/material'
import { IconButton } from 'modules/core/components/IconButton'

export const Root = styled('div', {
  shouldForwardProp: prop => prop !== 'isHidden',
})<{ isHidden?: boolean }>`
  margin-top: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(0.5)};
  padding-right: ${({ theme }) => theme.spacing(1.25)};

  ${({ isHidden }) =>
    isHidden &&
    css`
      height: 0;
      overflow: hidden;
    `}
`

export const AnimatedChevronIcon = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'chevronUp',
})<{ chevronUp: boolean }>`
  transition: all 0.3s;
  transform: ${({ chevronUp }) => (chevronUp ? 'rotate(0)' : 'rotate(180deg)')};
`

export const EventInfoWrapper = styled('div')`
  flex: 1;
  position: relative;
`
export const RowWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.5)};
  margin-bottom: ${({ theme }) => theme.spacing(1.25)};
`

export const RowTitleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  user-select: none;
  align-items: end;
  padding-left: ${({ theme }) => theme.spacing(1.1)};

  & > svg {
    width: 18px;
    height: 18px;
  }
`

export const RowTitleBigText = styled(Typography)`
  font-size: 12px;
`

export const RowControlWrapper = styled('div')`
  width: 60px;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 0 ${({ theme }) => theme.spacing(1.25)};
`
