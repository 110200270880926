import { UserTabsData } from 'modules/user/types'

export const updateUserTabsDataWithLockingStatusItem = (
  tabsData: UserTabsData | undefined,
  id: string | undefined,
  locked: boolean
): UserTabsData => ({
  ...Object.entries(tabsData || {}).reduce(
    (accTop, [keyTop, valueTop]) => ({
      ...accTop,
      [keyTop]: {
        ...Object.entries(valueTop || {}).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value.map(item =>
              item.i !== id
                ? item
                : {
                    ...item,
                    isDraggable: locked,
                    isResizable: locked,
                  }
            ),
          }),
          {}
        ),
      },
    }),
    {}
  ),
})
