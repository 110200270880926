// Hooks
import { useContext, useState } from 'react'
import { useAdaptive } from 'modules/landing/hooks/useAdaptive'
import { useTranslation } from 'react-i18next'
// Contexts
import { PopupContext } from 'modules/landing/contexts/PopupContext/context'
// Components
import {
  FlexRow,
  Link,
  Button,
  Text,
  RequestADemoForm,
} from 'modules/landing/components/shared'
import { HeaderTablet } from './Header.tablet'
import { HeaderMobile } from './Header.mobile'
import {
  LanguageWrapper,
  LanguagesList,
  Logo,
  StyledLanguageIcon,
} from './styled'
// Utils
import { handleRedirect } from 'modules/auth/utils'
// Constants
import { BREAKPOINTS, LINKS, SOCIAL_LINKS } from 'modules/landing/constants'
import { BUTTON_VARIANTS } from 'modules/landing/components/shared/Button/utils'
import { APP_SSO_LOGIN_URL, APP_SSO_REGISTER_URL } from 'modules/auth/constants'
import logo from 'modules/landing/assets/logo.svg'
// Language
import { Tooltip } from '@mui/material'
import { changeLanguage } from 'i18next'
import { languages } from 'modules/core/i18n/constants'
import LanguageIcon from 'modules/landing/assets/socials/lang.svg'
import { useTheme } from '@mui/material'

export const Header = () => {
  const theme = useTheme()

  const [languageMenuOpened, setLanguageMenuOpened] = useState<boolean>(false)

  const { t } = useTranslation(['pages'])
  const isMobile = useAdaptive(0, BREAKPOINTS.mobile)
  const isTablet = useAdaptive(BREAKPOINTS.mobile, BREAKPOINTS.tablet)
  const { isRequestDemoPopupOpened, setIsRequestDemoPopupOpened } =
    useContext(PopupContext)!

  const onRequestDemoClick = () =>
    setIsRequestDemoPopupOpened(!isRequestDemoPopupOpened)

  const handleLanguageMenuClick = () =>
    setLanguageMenuOpened(!languageMenuOpened)

  if (isTablet) return <HeaderTablet />
  if (isMobile) return <HeaderMobile />

  return (
    <FlexRow
      as='header'
      sx={{
        padding: '14px 25px',
        background: theme.palette.common.black,
        minHeight: 'calc(70px - 28px)',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <FlexRow sx={{ alignItems: 'center' }}>
        <Logo src={logo} alt='sporttotal' />

        <FlexRow as='nav' sx={{ gap: '50px', marginLeft: '50px' }}>
          {LINKS.map(link => (
            <Link key={`link-${link}`} href={`#${link}`}>
              <Text
                sx={{
                  font: 'normal normal normal 12px/12px "Exo 2"',
                  textTransform: 'uppercase',
                }}
              >
                {t(`landing.header.links.${link}`)}
              </Text>
            </Link>
          ))}
        </FlexRow>
      </FlexRow>
      <FlexRow sx={{ alignItems: 'center' }}>
        <FlexRow
          as='nav'
          sx={{ gap: '30px', alignItems: 'center', justifyContent: 'center' }}
        >
          {SOCIAL_LINKS.map(social => (
            <Link key={`link-${social.name}`} href={social.href}>
              <img src={social.img} alt={social.name} />
            </Link>
          ))}
          <LanguageWrapper onClick={handleLanguageMenuClick}>
            {languageMenuOpened && (
              <LanguagesList>
                {languages.map(lang => (
                  <Tooltip key={lang.languageCode} title={lang.displayName}>
                    <StyledLanguageIcon
                      className='lang'
                      src={lang.iconSrc}
                      alt={lang.displayName}
                      onClick={() => changeLanguage(lang.languageCode)}
                    />
                  </Tooltip>
                ))}
              </LanguagesList>
            )}
            <StyledLanguageIcon src={LanguageIcon} alt='lang' />
          </LanguageWrapper>
        </FlexRow>

        <FlexRow sx={{ margin: '0 30px', gap: '10px' }}>
          <Button
            variant={BUTTON_VARIANTS.default}
            onClick={() => handleRedirect(APP_SSO_LOGIN_URL)}
          >
            {t(`landing.header.buttons.login`)}
          </Button>
          <Button
            variant={BUTTON_VARIANTS.default}
            onClick={() => handleRedirect(APP_SSO_REGISTER_URL)}
          >
            {t(`landing.header.buttons.sign_up`)}
          </Button>
        </FlexRow>

        <Button
          variant={BUTTON_VARIANTS.primary}
          sx={{
            width: '150px',
            whiteSpace: 'nowrap',
          }}
          onClick={onRequestDemoClick}
        >
          {t(`landing.header.buttons.request_demo`)}
        </Button>
      </FlexRow>

      {isRequestDemoPopupOpened && <RequestADemoForm />}
    </FlexRow>
  )
}
