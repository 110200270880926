import { useCallback, useContext, useRef, useState } from 'react'
// Hooks
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useReduxSelector } from 'modules/core/hooks'
// Context
import { ComponentInfoContext } from 'modules/generic/context'
import { VideoPlayerContext } from 'modules/video-player/context'
// Redux
import {
  selectMediaItemTitle,
  setIsFullscreen,
  selectIsKeyboardControlsEnabled,
  setIsKeyboardControlsEnabled,
} from 'modules/video-player/redux'
// Services
import { ContentsDataService } from 'services/PlayersData.service'
// Components
import { ToolbarControls, ToolbarHeader } from 'modules/generic/components'
import { Menu, MenuItem } from 'modules/core/components/Menu'
import { LayoutItemToolbarContainer } from 'modules/layout/components/LayoutItemToolbar/LayoutItemToolbar.styled'

import {
  SettingsContainer,
  Text,
  Flex,
  StyledDots,
  StyledActionCheckbox,
} from './VideoPlayerToolbar.styled'
import { MatchSearch } from './MatchSearch'
import { PlaylistSearch } from './PlaylistSearch'
import { CameraPositionToolbarControl } from './CameraPositionToolbarControl'

export const VideoPlayerToolbar = () => {
  const { t } = useTranslation(['core', 'components'])
  const dispatch = useDispatch()

  const { playerId } = useContext(VideoPlayerContext)
  const { layoutIndex, componentId: layoutId } =
    useContext(ComponentInfoContext)

  const isKeyboardControlsEnabled = useReduxSelector(
    state => selectIsKeyboardControlsEnabled(state, layoutId) ?? false
  )

  const title = useReduxSelector(state => selectMediaItemTitle(state, layoutId))

  const menuAnchorRef = useRef<HTMLButtonElement>(null)
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)

  const handleOpenFullscreen = useCallback(() => {
    dispatch(
      setIsFullscreen({
        isFullscreen: true,
        id: playerId ?? '',
      })
    )

    setIsContextMenuOpen(false)
  }, [dispatch, playerId])

  const handleToggleKeyboardControls = useCallback(() => {
    const updatedValue = !isKeyboardControlsEnabled

    dispatch(
      setIsKeyboardControlsEnabled({
        isKeyboardControlsEnabled: updatedValue,
        id: playerId ?? '',
      })
    )

    ContentsDataService.updateContentItem(layoutId, {
      isKeyboardControlsEnabled: updatedValue,
    })

    setIsContextMenuOpen(false)
  }, [dispatch, playerId, layoutId, isKeyboardControlsEnabled])

  const menu = (
    <Menu
      open={isContextMenuOpen}
      anchorEl={menuAnchorRef.current}
      onClose={() => setIsContextMenuOpen(false)}
      autoFocus={false}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuItem icon='fullscreen' onClick={handleOpenFullscreen}>
        {t('components:playerControls.fullscreen')}
      </MenuItem>
      <MenuItem icon='controls' onClick={handleToggleKeyboardControls}>
        {t('core:keyboardControls')}
        <StyledActionCheckbox checked={isKeyboardControlsEnabled} />
      </MenuItem>
    </Menu>
  )

  return (
    <LayoutItemToolbarContainer>
      <Flex>
        <ToolbarHeader layoutId={layoutId} layoutIndex={layoutIndex} />
      </Flex>
      <Text>{title}</Text>
      <SettingsContainer>
        <CameraPositionToolbarControl />
        <PlaylistSearch />
        <MatchSearch />
        <StyledDots
          active={true}
          ref={menuAnchorRef}
          onClick={() => setIsContextMenuOpen(!isContextMenuOpen)}
        />
        {menu}
        <ToolbarControls layoutId={layoutId} layoutIndex={layoutIndex} />
      </SettingsContainer>
    </LayoutItemToolbarContainer>
  )
}
