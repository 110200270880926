import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { RetrieveDataUserOptions } from '@sporttotal-tv/dip-coaching-client'
import { useQueryRetry } from 'modules/core/hooks'
import { UserCacheData } from 'modules/user/types'
import { UseQueryOptions } from 'modules/core/types'

export const USER_STORAGE_RETRIEVE_CACHE_KEY = 'user-storage-retrieve'

export const useUserStorage = (
  params: RetrieveDataUserOptions,
  queryOptions?: UseQueryOptions<
    UserCacheData,
    (RetrieveDataUserOptions | string)[]
  >
) => {
  const handleQueryRetry = useQueryRetry({ maxRetries: 0 })

  return useQuery(
    [USER_STORAGE_RETRIEVE_CACHE_KEY, params],
    () => ApiService.user.retrieveStorage(params),
    {
      ...queryOptions,
      staleTime: Infinity,
      retry: handleQueryRetry,
    }
  )
}
