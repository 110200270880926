import { forwardRef } from 'react'
import { IconButton as BaseIconButton } from '@mui/material'
import { useWithBlur } from 'modules/core/hooks'
import { Icon } from 'modules/core/components'
import { IconButtonProps } from './IconButton.interface'

// TODO: Reuse this and remove materials icon button!!
export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      sx,
      size = 'small',
      className,
      edge,
      color,
      onClick,
      disabled,
      disableRipple,
      ...others
    },
    ref
  ) => {
    const onClickHandler = useWithBlur(onClick)
    const isIcon = 'name' in others

    return (
      <BaseIconButton
        ref={ref}
        // MUI Checks for this to validate if props were correctly forwarded
        // Manually add it to remove errors since we have desired outcome
        data-mui-internal-clone-element
        sx={sx}
        size={size}
        edge={edge}
        color={color}
        onClick={onClickHandler}
        disabled={disabled}
        disableRipple={disableRipple}
        autoFocus={false}
        className={className}
      >
        {isIcon ? <Icon {...others} /> : others.children}
      </BaseIconButton>
    )
  }
)
