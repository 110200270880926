import {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  useCallback,
  useState,
} from 'react'
import {
  InputIconWrapper,
  InputWrapper,
  ActionIcon,
  InputElement,
} from './InputEdit.styled'
import { InputEditProps } from './InputEdit.interface'

export const InputEdit = forwardRef<HTMLInputElement, InputEditProps>(
  ({ defaultValue, onCancel, onAccept, variant }, ref) => {
    const [value, setValue] = useState<string>(defaultValue ?? '')

    const handleValueChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value)
      },
      []
    )

    const handleAccept = useCallback(() => {
      onAccept(value)
    }, [value, onAccept])

    const handleKeyPress = useCallback(
      (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
          handleAccept()
        }
      },
      [handleAccept]
    )

    return (
      <InputWrapper>
        <InputIconWrapper onClick={onCancel}>
          <ActionIcon name='close' />
        </InputIconWrapper>
        <InputElement
          ref={ref}
          defaultValue={defaultValue}
          onChange={handleValueChange}
          onKeyPress={handleKeyPress}
          componentVariant={variant}
          disableUnderline={variant === 'small'}
        />
        <InputIconWrapper onClick={handleAccept}>
          <ActionIcon name='check' />
        </InputIconWrapper>
      </InputWrapper>
    )
  }
)
