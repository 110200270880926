import { createContext, FC, useCallback, useState } from 'react'

import {
  IVideoPlayerProvider,
  IVideoPlayerContext,
} from './VideoPlayerContext.interface'
import { MediaPlayer } from 'modules/media-render/types/media-render.types'

export const VideoPlayerContext = createContext<IVideoPlayerContext>({
  removePlayer: () => {},
  setPlayer: () => {},
  updatePlayer: () => {},
})

export const PlayerContextProvider: FC<IVideoPlayerProvider> = ({
  children,
}) => {
  const [player, setPlayer] = useState<MediaPlayer | undefined>(undefined)
  const [playerId, setPlayerId] = useState<string | undefined>(undefined)

  const removePlayer = useCallback(() => {
    setPlayer(undefined)
    setPlayerId(undefined)
  }, [])

  const setPlayerData = useCallback(
    (player: MediaPlayer, componentId: string) => {
      setPlayer(player)
      setPlayerId(componentId)
    },
    []
  )

  const updatePlayer = useCallback((player: MediaPlayer) => {
    setPlayer(player)
  }, [])

  return (
    <VideoPlayerContext.Provider
      value={{
        player,
        playerId,
        setPlayer: setPlayerData,
        removePlayer,
        updatePlayer,
      }}
    >
      {children}
    </VideoPlayerContext.Provider>
  )
}
