import { Frame, FrameList } from '@sporttotal-tv/dip-coaching-client'

export const framesMockup: Frame[] = [
  {
    id: '7c9a8a03-a032-48f1-b245-501a1b8e98b9',
    created_at: 1648580100,
    updated_at: 1648581400,
    media_locator_id: 'e12c9d99-b3b3-4b8e-a19e-4a15a4c3ecce',
    parent_media_locator_id: 'e12c9d29-b3b3-4b8e-a19e-4a15a4c3ecce',
    start_time: 25,
    duration: 150,
    media_locator: undefined,
    parent_media_locator: undefined,
    user_id: 'c7535b5a-3fcd-4d4b-82dd-8b08971dce79',
  },
  {
    id: 'ea2d8e14-1577-4352-8851-7c2cf4846205',
    created_at: 1648579000,
    updated_at: 1648582000,
    media_locator_id: 'f1ab7c2b-aa20-414f-bf5a-57a0b0aef711',
    parent_media_locator_id: 'f1ab7c2b-aa21-414f-bf5a-57a0b0aef711',
    start_time: 120,
    duration: 200,
    media_locator: undefined,
    parent_media_locator: undefined,
    user_id: 'c7535b5a-3fcd-4d4b-82dd-8b08971dce79',
  },
  {
    id: 'b10dcf12-9b7e-4dca-92d0-efda90f556e2',
    created_at: 1648567000,
    updated_at: 1648571000,
    media_locator_id: 'c7535b5a-3fcd-4d4b-82dd-8b08973dce79',
    parent_media_locator_id: 'c7535b5a-3fcd-4d4b-82dd-8b08971dce79',
    start_time: 75,
    duration: 200,
    media_locator: undefined,
    parent_media_locator: undefined,
    user_id: 'c7535b5a-3fcd-4d4b-82dd-8b08971dce79',
  },
  {
    id: '8f6f2c03-0e20-4e0e-b54c-3d3d43ea0c0f',
    created_at: 1648555000,
    updated_at: 1648560000,
    media_locator_id: '2c2b8f50-59d9-4e1a-b0fb-2d908a45a72d',
    parent_media_locator_id: '2c2b8f50-59d9-4e1a-b0fb-2d908a15a72d',
    start_time: 20,
    duration: 90,
    media_locator: undefined,
    parent_media_locator: undefined,
    user_id: 'c7535b5a-3fcd-4d4b-82dd-8b08971dce79',
  },
  {
    id: '8de3a3e3-33e6-4831-9138-327399f7e018',
    created_at: 1648543000,
    updated_at: 1648549000,
    media_locator_id: '62b16c09-1d12-4c4b-ae4b-4b4f0dbd2a97',
    parent_media_locator_id: '62b16c09-1d12-4c4b-ae4b-4b4f0dbd1a97',
    start_time: 50,
    duration: 250,
    media_locator: undefined,
    parent_media_locator: undefined,
    user_id: 'c7535b5a-3fcd-4d4b-82dd-8b08971dce79',
  },
]

export const frameMockup: Frame = framesMockup[0]

export const framesListMockup: FrameList = {
  results: framesMockup,
  total: 5,
}
