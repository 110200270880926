import { useCallback } from 'react'
import { useTranslation, UseTranslationOptions } from 'react-i18next'

// TODO: Refactor this
const tryParseJSONObject = (jsonString: string) => {
  try {
    const o = JSON.parse(jsonString)

    if (o && typeof o === 'object') {
      return o
    }
  } catch (e) {}
}

export const useTError = (
  path?: string | string[],
  options?: UseTranslationOptions<string>
) => {
  const { t } = useTranslation(path, options)

  const getTranslation = useCallback(
    (error?: string) => {
      if (!error) return ''

      const json = tryParseJSONObject(error)

      if (!json) return t(error)
      if (json && json.translation) return t(json.translation, json.params)

      return ''
    },
    [t]
  )

  return { tError: getTranslation }
}
