import { forwardRef } from 'react'
import { IMaskInput } from 'react-imask'
import { TimeMaskInputProps } from './TimeMaskInput.interface'

export const TimeMaskInput = forwardRef<HTMLElement, TimeMaskInputProps>(
  (props, ref) => {
    const { onChange, ...other } = props

    return (
      <IMaskInput
        {...other}
        mask='00:#0:#0'
        definitions={{
          '#': /[0-5]/,
        }}
        // @ts-ignore
        inputRef={ref}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    )
  }
)
