import { FC } from 'react'
import { ButtonProps as MUIButtonProps } from '@mui/material/Button'
import {
  DefaultStyledButton,
  PrimaryStyledButton,
  VideoStyledButton,
} from './styled'
import { BUTTON_VARIANTS, ButtonProps } from './utils'

type Props = ButtonProps & Omit<MUIButtonProps, 'variant'>

export const Button: FC<Props> = ({ children, variant, ...buttonProps }) => {
  const buttonVariants = {
    [BUTTON_VARIANTS.default]: (
      <DefaultStyledButton children={children} {...buttonProps} />
    ),
    [BUTTON_VARIANTS.primary]: (
      <PrimaryStyledButton children={children} {...buttonProps} />
    ),
    [BUTTON_VARIANTS.video]: (
      <VideoStyledButton children={children} {...buttonProps} />
    ),
  }

  return buttonVariants[variant] || buttonVariants['DEFAULT']
}
