import {
  CreatePlayerContractBody,
  PlayerContract,
} from '@sporttotal-tv/dip-coaching-client'
import { teamPlayerContractMockup } from 'modules/team-player/utils'
import { generateId } from 'modules/core/helpers/generateId'

export const placeholderContractPlayer = (
  payload?: CreatePlayerContractBody
): PlayerContract => ({
  ...teamPlayerContractMockup,
  id: generateId(),
  ...payload,
})
