import { v1, v4 } from 'uuid'

export const generateId = (variant: 'v1' | 'v4' = 'v4'): string => {
  if (variant === 'v1') {
    return `${new Date().getTime()}-${v1()}`
  }
  if (variant === 'v4') {
    return `${new Date().getTime()}-${v4()}`
  }

  return `${new Date().getTime()}-${v4()}`
}
