import { ShapeConfig } from 'konva/lib/Shape'
import {
  MultiLineTarget,
  IKonvaMouseEvent,
  IKonvaShape,
} from 'modules/annotator/types'
import {
  TelestrationShapeType,
  TelestrationTool,
} from 'modules/telestration/types'

export const computeShapeUpdate = ({ currentTarget }: IKonvaMouseEvent) => {
  const stage = currentTarget.getStage()!
  const stageWidth = stage.width()
  const stageHeight = stage.height()

  const { width, height } = currentTarget.size()
  const scale = currentTarget.scale()

  return { stageWidth, stageHeight, scale, width, height, currentTarget }
}

export const transformShapeToPercentage = (
  shape: TelestrationShapeType,
  width: number,
  height: number
) => {
  let newShape = { ...shape }

  if (shape.points !== undefined) {
    newShape.points = shape.points.map((point: number, index: number) => {
      if (index % 2 === 0) {
        return (point * 100) / width
      }

      return (point * 100) / height
    })
  }
  if (shape.x !== undefined) {
    newShape.x = (shape.x * 100) / width
  }
  if (shape.width !== undefined) {
    newShape.width = (shape.width * 100) / width
  }

  if (shape.y !== undefined) {
    newShape.y = (shape.y * 100) / height
  }
  if (shape.height !== undefined) {
    newShape.height = (shape.height * 100) / width
  }

  if (shape.radius !== undefined) {
    newShape.radius = (shape.radius * 100) / height
  }

  return newShape
}

export const transformShapeFromPercentage = (
  shape: Record<string, any>,
  width: number,
  height: number
) => {
  let newShape = { ...shape }

  if (shape.points !== undefined) {
    newShape.points = shape.points.map((point: number, index: number) => {
      if (index % 2 === 0) {
        return (point * width) / 100
      }

      return (point * height) / 100
    })
  }
  if (shape.x !== undefined) {
    newShape.x = (shape.x * width) / 100
  }
  if (shape.width !== undefined) {
    newShape.width = (shape.width * width) / 100
  }

  if (shape.y !== undefined) {
    newShape.y = (shape.y * height) / 100
  }
  if (shape.height !== undefined) {
    newShape.height = (shape.height * width) / 100
  }

  if (shape.radius !== undefined) {
    newShape.radius = (shape.radius * height) / 100
  }
  return newShape
}

/**
 * Generating selection shape for Konva shapes that have different size for height or width depending on position
 * on the stage. There is no option to do this via react-konva library at the moment.
 *
 * Used for next tools: HIGHLIGHT.
 *
 * @param {IKonvaShape<ShapeConfig>} shape - Konva shape
 */
export const generateSelfRect = (shape: IKonvaShape<ShapeConfig>) => {
  const toolType: TelestrationTool | undefined = shape.attrs?.shapeToolType
  if (toolType === 'HIGHLIGHT') {
    shape.getSelfRect = () => {
      return {
        x: 0 - 35,
        y: -shape.y(),
        width: 75,
        height: shape.y() + 35,
      }
    }
  }
}

export const getDefaultQuadraticPoints = (start: number[], end: number[]) => {
  const pointAx = start[0]
  const pointAy = start[1]
  const pointBx = end[0]
  const pointBy = end[1]
  const pointABx = (start[0] + end[0]) / 2
  const pointABy = (start[1] + end[1]) / 2
  const sideB = Math.sqrt(
    (pointABx - pointAx) * (pointABx - pointAx) +
      (pointABy - pointAy) * (pointABy - pointAy)
  )

  const resultSide = sideB === 0 ? 1 : sideB
  const unitVector1x = (pointABx - pointAx) / resultSide
  const unitVector1y = (pointABy - pointAy) / resultSide
  const point1x = pointAx + unitVector1y * resultSide
  const point1y = pointAy + -unitVector1x * resultSide

  const unitVector2x = (pointABx - pointBx) / resultSide
  const unitVector2y = (pointABy - pointBy) / resultSide
  const point2x = pointBx + -unitVector2y * resultSide
  const point2y = pointBy + unitVector2x * resultSide

  return {
    start: {
      x: pointAx,
      y: pointAy,
    },
    control1: {
      x: point1x,
      y: point1y,
    },
    control2: {
      x: point2x,
      y: point2y,
    },
    end: {
      x: pointBx,
      y: pointBy,
    },
  }
}

/**
 * Generating new vector for each arrow of MultiLine tool
 *
 * Used for next tools: MultiLine.
 *
 * @param {number[]} start - Start point of vector
 * @param {number[]} end - End point of vector
 * @param {number} percentage - Percentage of how small result vector should be, default is 5.
 */
export const getSmallerVector = (
  start: number[],
  end: number[],
  percentage: number = 5
) => {
  const pointAx = start[0]
  const pointAy = start[1]
  const pointBx = end[0]
  const pointBy = end[1]
  const fullLength = Math.sqrt(
    (pointBx - pointAx) * (pointBx - pointAx) +
      (pointBx - pointAy) * (pointBx - pointAy)
  )
  const newLength = fullLength - (fullLength * percentage) / 100
  const unitVectorX = (pointBx - pointAx) / fullLength
  const unitVectorY = (pointBy - pointAy) / fullLength
  const newPointBx = pointAx + unitVectorX * newLength
  const newPointBy = pointAy + unitVectorY * newLength
  const unitVectorReverseX = (pointAx - pointBx) / fullLength
  const unitVectorReverseY = (pointAy - pointBy) / fullLength

  const newPointAx = pointBx + unitVectorReverseX * newLength
  const newPointAy = pointBy + unitVectorReverseY * newLength

  return [newPointAx, newPointAy, newPointBx, newPointBy]
}

/**
 * Generating target for MultiLine by points that we have in the shape
 *
 * Used for next tools: MultiLine.
 *
 * @param {number[]} points - Points from which we will generate vector
 */
export const generateMultiLineTargetsByPoints = (
  points: number[]
): MultiLineTarget[] => {
  const groupedPoints = points.reduce<number[][]>((acc, point, i) => {
    if (i % 2 === 0) {
      return [...acc, points.slice(i, i + 2)]
    }
    return acc
  }, [])

  const filteredPoints = groupedPoints.filter((point1, index1) => {
    const closePoint = groupedPoints.find((point2, index2) => {
      const closeX = Math.abs(point1[0] - point2[0]) < 10
      const closeY = Math.abs(point1[1] - point2[1]) < 10
      return closeX && closeY && index1 > index2
    })
    return !closePoint
  })

  return filteredPoints.reduce<MultiLineTarget[]>(
    (acc, group, index) => [
      ...acc,
      {
        id: `target-${index}`,
        x: group[0],
        y: group[1],
      },
    ],
    []
  )
}
