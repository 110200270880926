import { createSelector } from '@reduxjs/toolkit'
import { RootState } from 'redux/store'
import { LayoutIndex } from 'modules/layout/constants'

export const selectLayoutState = (state: RootState) => state.layout

export const selectLayoutTypes = createSelector(
  selectLayoutState,
  state => state.layoutTypes
)

export const selectActiveTab = createSelector(
  selectLayoutState,
  state => state.activeTab
)

export const selectLayoutKeys = createSelector(
  selectLayoutState,
  state => state.layoutKeys
)

export const selectCurrentLayout = createSelector(
  selectLayoutState,
  ({ breakpoint, layoutsWithBreakpoints }) => {
    if (!breakpoint || !layoutsWithBreakpoints?.[breakpoint]) {
      return []
    }

    return layoutsWithBreakpoints[breakpoint]
  }
)

export const selectComponentNumberInLayout = createSelector(
  [
    selectCurrentLayout,
    selectLayoutTypes,
    (state, componentId: string, layoutIndex?: LayoutIndex) => ({
      componentId,
      layoutIndex,
    }),
  ],
  (currentLayout, layoutTypes, { componentId, layoutIndex }) => {
    const layoutsIds = currentLayout.map(layout => layout.i)

    return Object.entries(layoutTypes)
      .filter(([_, value]) => value === layoutIndex)
      .filter(([key]) => layoutsIds.includes(key))
      .findIndex(([key]) => key === componentId)
  }
)
