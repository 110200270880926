import React from 'react'
import getWeekDays from '../utils/getWeekDays'
import getSelectedWeekIndex from '../utils/getSelectedWeekIndex'
import CalendarLayoutMonth from './CalendarLayoutMonth'
import CalendarLayoutDayWeek from './CalendarLayoutDayWeek'
import { css, styled } from '@mui/material'
import { useReduxSelector } from 'modules/core/hooks'
import {
  selectCalendarLayout,
  selectCalendarSelectedDate,
} from 'modules/calendar/redux'

const drawerWidth = 260

const Content = styled('div', {
  shouldForwardProp: prop => prop !== 'open',
})<{ open?: boolean }>`
  flex-grow: 1;
  transition: ${({ theme }) =>
    theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })};
  margin-left: -${drawerWidth}px;
  height: 100%;
  width: 100%;
  ${({ theme, open }) =>
    open &&
    css`
      margin-left: 0;
      transition: ${theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      })};
    `}
`

const CalendarMain = () => {
  const selectedDate = useReduxSelector(selectCalendarSelectedDate)
  const layout = useReduxSelector(selectCalendarLayout)

  const weeks = getWeekDays(selectedDate, 7)
  const selectedWeekIndex = getSelectedWeekIndex(selectedDate, weeks, 0)
  const selectedWeek = weeks[selectedWeekIndex]

  return (
    <Content open={true}>
      {layout === 'month' && <CalendarLayoutMonth weeks={weeks} />}

      {(layout === 'week' || layout === 'day') && (
        <CalendarLayoutDayWeek
          selectedWeekIndex={selectedWeekIndex}
          selectedWeek={selectedWeek}
        />
      )}
    </Content>
  )
}

export default CalendarMain
