import { FC } from 'react'
import { Group, Shape as KonvaShape } from 'react-konva'
import {
  TelestrationShapesParams,
  TelestrationShapes,
  SELECTION_GROUP_NAME,
  SELECTION_AREA_NAME,
} from 'modules/annotator/constants'
import { shapeHandlers } from 'modules/annotator/shapes'
import { IKonvaNode, IKovaEvent } from 'modules/annotator/types'
import { TelestrationShapeType } from 'modules/telestration/types'

export type ShapeProps = TelestrationShapeType & {
  onChange?: (shape: IKonvaNode) => void
  preview?: boolean
  isActive?: boolean
}

const Shape: FC<ShapeProps> = ({
  type,
  preview = false,
  isActive = false,
  id,
  name,
  onChange,
  ...props
}) => {
  const ShapeComponent = TelestrationShapes[type]
  const shapeParams = TelestrationShapesParams[type]
  const {
    generateParams,
    shapeSelectionArea,
    shapeSelectionAreaComponent,
    generateSelectionAreaParams,
    CustomShapeTransformComponent,
    ...restShapeParams
  } = shapeParams

  const handleShapeUpdate = (e: IKovaEvent<any>) => {
    if (!onChange) return

    const shapeHandler = shapeHandlers[type]
    onChange(shapeHandler.onShapeUpdated(e))
  }

  if (CustomShapeTransformComponent) {
    return (
      // TODO: Resolve issue with typing
      // @ts-ignore
      <CustomShapeTransformComponent
        type={type}
        preview={preview}
        id={id}
        name={name}
        onChange={onChange}
        isActive={isActive}
        {...props}
      />
    )
  }

  // The main idea is that we create custom shape that will work as selection area for our custom shape.
  // This approach allows us to expand clickable area of our shape and make it easier to select it.
  if (shapeSelectionArea || shapeSelectionAreaComponent) {
    const ShapeSelectionComponent = shapeSelectionAreaComponent ?? KonvaShape
    return (
      <Group
        key={id || name}
        id={`${SELECTION_GROUP_NAME}-${id}`}
        name={`${SELECTION_GROUP_NAME}-${id}`}
      >
        <ShapeComponent
          id={id}
          name={name}
          draggable
          stroke='blue'
          lineCap='round'
          strokeWidth={3}
          onDragEnd={handleShapeUpdate}
          onTransformEnd={handleShapeUpdate}
          shapeToolType={type}
          {...restShapeParams}
          {...props}
          {...(generateParams && generateParams(props))}
        />
        <ShapeSelectionComponent
          id={`${SELECTION_AREA_NAME}-${id}`}
          sceneFunc={shapeSelectionArea}
          {...props}
          stroke={undefined}
          strokeWidth={0}
          fill={undefined}
          {...(generateSelectionAreaParams &&
            generateSelectionAreaParams(props))}
        />
      </Group>
    )
  }

  return (
    <ShapeComponent
      id={id}
      key={id || name}
      name={name}
      draggable
      stroke='blue'
      lineCap='round'
      strokeWidth={3}
      onDragEnd={handleShapeUpdate}
      onTransformEnd={handleShapeUpdate}
      shapeToolType={type}
      {...restShapeParams}
      {...props}
      {...(generateParams && generateParams(props))}
    />
  )
}

export { Shape }
