import { styled } from '@mui/system'
import { Typography } from '@mui/material'

export const Root = styled('div')`
  padding: ${({ theme }) => theme.spacing(1.25)};
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const StatisticsContainer = styled('div')`
  padding: ${({ theme }) => theme.spacing(1.25)};
  overflow-y: auto;
`

export const StatisticsHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`

export const TeamContainer = styled('div')`
  display: flex;
  align-items: center;
`

export const TeamIcon = styled('img')`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 6px;
  object-fit: fill;
`

export const Divider = styled('div')`
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
`

export const Statistic = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(1)};
  text-transform: capitalize;
`

export const StatisticText = styled(Typography)`
  width: 10%;
  text-align: center;
`

export const MiddleColumn = styled(Typography)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`

export const NoStatsMessage = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
`
