import { styled } from '@mui/material'
import { IconButton } from 'modules/core/components/IconButton'

export const Root = styled('div')`
  height: 100%;
`

export const PlaylistWrapper = styled('div')`
  padding: ${({ theme }) => theme.spacing(1.25, 0, 2)};
  // TODO: 46px should be in const  change when fixed issue with tests
  height: calc(100% - 46px);
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing(2.75)};
`

export const AnimatedChevronIcon = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'chevronUp',
})<{ chevronUp: boolean }>`
  transition: all 0.3s;
  transform: ${({ chevronUp }) => (chevronUp ? 'rotate(0)' : 'rotate(180deg)')};
`

export const AssetWrapper = styled('div')`
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 ${({ theme }) => theme.spacing(2)};
  flex: 1;
`

export const FilterContainer = styled('div')`
  position: absolute;
  top: 46px;
  height: calc(100% - 46px);
  width: 100%;
  display: flex;
  justify-content: center;
`
