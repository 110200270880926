import { PlayerStatus } from '@sporttotal-tv/dip-coaching-client'

export const teamPlayerStatusMathLoads: Array<PlayerStatus['math_load']> = [
  'LIGHT',
  'MEDIUM',
  'HIGH',
]

export const teamPlayerStatusTrainingLoads: Array<
  PlayerStatus['training_load']
> = ['LIGHT', 'MEDIUM', 'HIGH']

export const teamPlayerStatusInjurySusceptible: Array<
  PlayerStatus['injury_suscetible']
> = ['LOW', 'AVERAGE', 'ABOVE_AVERAGE', 'VERY_HIGH']

export const teamPlayerStatusOverallRisks: Array<PlayerStatus['overall_risk']> =
  ['LOW', 'INCREASED', 'HIGH']
