import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import { TEAM_PLAYER_INJURY_LIST_CACHE_KEY } from 'modules/team-player/hooks'
import { MutateContext } from 'modules/core/types/mutate-context.type'
import { InfiniteQuery } from 'modules/core/types'
import {
  PlayerInjury,
  PlayerInjuryList,
  UpdatePlayerInjuryBody,
} from '@sporttotal-tv/dip-coaching-client'

export const TEAM_PLAYER_INJURY_UPDATE_CACHE_KEY = 'team-player-injury-update'

export type InjuryUpdateParams = {
  id: string
  params: UpdatePlayerInjuryBody
}

export const useTeamPlayerInjuryUpdate = () => {
  const queryClient = useQueryClient()

  return useMutation<
    PlayerInjury,
    Error,
    InjuryUpdateParams,
    MutateContext<any>
  >(
    TEAM_PLAYER_INJURY_UPDATE_CACHE_KEY,
    ({ id, params }: InjuryUpdateParams) =>
      ApiService.teamPlayerInjury.update(id, params),
    {
      onMutate: async data => {
        await queryClient.cancelQueries(TEAM_PLAYER_INJURY_LIST_CACHE_KEY, {
          exact: false,
        })

        const previousData = queryClient.getQueriesData<
          InfiniteQuery<PlayerInjuryList>
        >(TEAM_PLAYER_INJURY_LIST_CACHE_KEY)

        if (previousData) {
          queryClient.setQueriesData<
            InfiniteQuery<PlayerInjuryList> | undefined
          >(
            TEAM_PLAYER_INJURY_LIST_CACHE_KEY,
            oldData =>
              oldData && {
                pageParams: oldData.pageParams,
                pages: oldData.pages.map(
                  page =>
                    page && {
                      total: page.total,
                      results: page.results.map(item =>
                        item.id === data.id ? { ...item, ...data.params } : item
                      ),
                    }
                ),
              }
          )
        }

        return { previousData }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData(prevData[0], prevData[1])
          )
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(TEAM_PLAYER_INJURY_LIST_CACHE_KEY, {
          exact: false,
        })
      },
    }
  )
}
