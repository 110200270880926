import { playerLayoutConfig } from '../PlayerLayoutConfig'
import { playerControlsLayoutConfig } from '../PlayerControlsLayoutConfig'
import { telestrationLayoutConfig } from '../TelestrationLayoutConfig'
import { generateId } from 'modules/core/helpers/generateId'

export const telestrationLayoutPlayerId = generateId('v1')
export const telestrationLayoutPlayerControlsId = generateId('v1')
export const telestrationLayoutAssetsId = generateId('v1')
export const telestrationLayoutTelestrationId = generateId('v1')

export const TELESTRATION_TAB_LAYOUT = {
  lg: [
    { ...playerLayoutConfig.lg, i: telestrationLayoutPlayerId, h: 12, w: 12 },
    {
      ...playerControlsLayoutConfig.lg,
      i: telestrationLayoutPlayerControlsId,
      x: 9,
      w: 3,
      y: 12,
      h: 8,
    },
    {
      ...telestrationLayoutConfig.lg,
      i: telestrationLayoutTelestrationId,
      w: 9,
      h: 8,
      y: 12,
    },
  ],
  md: [
    { ...playerLayoutConfig.md, i: telestrationLayoutPlayerId, h: 10, w: 12 },
    {
      ...playerControlsLayoutConfig.md,
      i: telestrationLayoutPlayerControlsId,
      w: 12,
      y: 10,
      h: 3,
    },
    {
      ...telestrationLayoutConfig.md,
      i: telestrationLayoutTelestrationId,
      w: 12,
      h: 7,
      y: 13,
    },
  ],
  sm: [
    { ...playerLayoutConfig.sm, i: telestrationLayoutPlayerId, h: 10, w: 12 },
    {
      ...playerControlsLayoutConfig.sm,
      i: telestrationLayoutPlayerControlsId,
      w: 12,
      y: 10,
      h: 3,
    },
    {
      ...telestrationLayoutConfig.sm,
      i: telestrationLayoutTelestrationId,
      w: 12,
      h: 7,
      y: 13,
    },
  ],
  xs: [
    { ...playerLayoutConfig.xs, i: telestrationLayoutPlayerId },
    {
      ...playerControlsLayoutConfig.xs,
      i: telestrationLayoutPlayerControlsId,
    },
    { ...telestrationLayoutConfig.xs, i: telestrationLayoutTelestrationId },
  ],
  xxs: [
    { ...playerLayoutConfig.xxs, i: telestrationLayoutPlayerId },
    {
      ...playerControlsLayoutConfig.xxs,
      i: telestrationLayoutPlayerControlsId,
    },
    { ...telestrationLayoutConfig.xxs, i: telestrationLayoutTelestrationId },
  ],
}
