import { MenuItem, styled } from '@mui/material'
import { Icon } from 'modules/core/components/Icon'
import { OptionsSelector } from 'modules/core/styled/OptionsSelector'

export const ToolbarIcon = styled(Icon, {
  shouldForwardProp: prop => prop !== 'color',
})<{ color?: string }>`
  & path {
    transition: all 0.3s;
    fill: ${({ theme, color }) => color ?? theme.palette.secondary.main};
  }
`

export const Selector = styled(OptionsSelector)`
  background-color: ${({ theme }) => theme.palette.primary.main};
`

export const SelectorOption = styled(MenuItem)`
  font-size: 14px;

  background-color: ${({ theme }) => theme.palette.primary.dark};
  color: ${({ theme }) => theme.palette.primary.light};

  &:hover {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background-color: ${({ theme }) => theme.palette.primary.main};
  }

  &:focus {
    background-color: ${({ theme }) => theme.palette.primary.dark};
    color: ${({ theme }) => theme.palette.primary.light};

    &:hover {
      color: ${({ theme }) => theme.palette.primary.contrastText};
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`
