import {
  Asset,
  AssetList,
  PlaylistAsset,
} from '@sporttotal-tv/dip-coaching-client'

export const assetMockup: Asset = {
  id: '72c018a8-d548-4378-b655-7d3f98f80bc1',
  title: 'Internal Marketing Facilitator',
  description: 'Chief',
  start_time: 176,
  duration: 18468,
  tags: ['Health', 'Metal'],
  status: 'completed',
  event_id: '819a6bd0-9e04-468b-a567-561bc3bf5e46',
  match_id: '11e7d10f-c808-4fff-9968-527b89ca9e52',
  created_by: 'd0dd340a-3d1e-49ea-976d-f4d24e55d253',
  created_at: 1655886764,
  updated_at: 1655886764,
  event: {
    id: 'eventId',
    name: {
      en: 'event',
      de: 'event',
    },
    type: 1,
    sport_id: '',
    created_at: 1655886764,
    updated_at: 1655886764,
  },
  media_locator: {
    id: 'mediaLocatorId',
    url: 'mediaLocatorUrl',
    type: 'VIDEO',
    created_at: 1655886764,
    updated_at: 1655886764,
  },
}

export const assetsMockup: Asset[] = [
  {
    id: '72c018a8-d548-4378-b655-7d3f98f80bc1',
    title: 'Internal Marketing Facilitator',
    description: 'Chief',
    start_time: 176,
    duration: 18468,
    tags: ['Health', 'Metal'],
    status: 'completed',
    event_id: '819a6bd0-9e04-468b-a567-561bc3bf5e46',
    match_id: '11e7d10f-c808-4fff-9968-527b89ca9e52',
    created_by: 'd0dd340a-3d1e-49ea-976d-f4d24e55d253',
    created_at: 1655886764,
    updated_at: 1655886764,
  },
  {
    id: '3d1bc472-6e6b-496a-a1e7-812f1fce3934',
    title: 'Internal Assurance Director',
    description: 'Direct',
    start_time: 9056,
    duration: 17625,
    tags: [],
    status: 'completed',
    event_id: '889ca0f2-c732-40bd-a547-412dce90b726',
    match_id: 'a876d49a-f32e-4fe8-9576-7ec0599084c2',
    created_by: '',
    created_at: 1655886764,
    updated_at: 1655886764,
  },
  {
    id: '35270799-20b4-4790-8028-c8ecac70d905',
    title: 'Global Creative Administrator',
    description: 'Dynamic',
    start_time: 8518,
    duration: 11064,
    tags: ['Switzerland'],
    status: 'completed',
    event_id: 'b6557b31-a6d4-4b3b-81c3-53a33eb8c82d',
    match_id: 'd1076aa8-cf76-4953-93d0-83474691abf3',
    created_by: '',
    created_at: 1655801911,
    updated_at: 1655801911,
  },
  {
    id: '9ef9ea04-80f2-4595-b5fa-dba716d8a4f7',
    title: 'Forward Assurance Liaison',
    description: 'Dynamic',
    start_time: 4592,
    duration: 27196,
    tags: ['Refined'],
    status: 'completed',
    event_id: '71234458-93b2-4fae-be70-04b8d6eb6454',
    match_id: '86ed244c-cd1c-49ee-956d-8cb50cafb1aa',
    created_by: '38cfec1d-fe05-496f-8ab3-336ef17f42fe',
    created_at: 1655886764,
    updated_at: 1655886764,
  },
  {
    id: 'e71d4e78-4564-495a-bac0-a6754df5c91c',
    title: 'updated',
    description: 'Chief',
    start_time: 5616,
    duration: 18988,
    tags: [],
    status: 'error',
    event_id: '5db159fb-78d1-472d-8dc8-cb458bada798',
    match_id: '4333fdf2-84ee-488c-b386-a3279d76defd',
    created_by: 'adfbefcc-1229-4470-a699-5de9dd64ded5',
    created_at: 1655886764,
    updated_at: 1655886764,
  },
]

export const assetListMockup: AssetList = {
  results: assetsMockup,
  total: 5,
}

export const playlistAssetsMockup: PlaylistAsset = [
  {
    id: '72c018a8-d548-4378-b655-7d3f98f80bc1',
    playlist_id: '72c018a8-d548-4378-b655-7d3f98f80bc1',
    asset_id: '72c018a8-d548-4378-b655-7d3f98f80bc1',
  },
]

export const playlistAssetMockup: PlaylistAsset = [
  {
    id: '72c018a8-d548-4378-b655-7d3f98f80bc1',
    playlist_id: '72c018a8-d548-4378-b655-7d3f98f80bc1',
    asset_id: '72c018a8-d548-4378-b655-7d3f98f80bc1',
  },
]
