import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { useQueryRetry } from 'modules/core/hooks'

export const CUSTOMER_PORTAL_RETRIEVE_CACHE_KEY = 'customer-portal-retrieve'

export const useCustomerPortal = () => {
  const handleQueryRetry = useQueryRetry()

  return useQuery(
    [CUSTOMER_PORTAL_RETRIEVE_CACHE_KEY],
    () => ApiService.stripe.retriveCustomerPortal(),
    {
      retry: handleQueryRetry,
    }
  )
}
