import { useState, FC } from 'react'
import {
  MatchPeriodContext,
  MatchPeriodContextProviderProps,
} from 'modules/match-period/context'
import { MatchPeriod } from 'modules/match-period/types'

export const MatchPeriodProvider: FC<MatchPeriodContextProviderProps> = ({
  children,
}) => {
  const [matchPeriod, setMatchPeriod] = useState<MatchPeriod>('first_half')

  return (
    <MatchPeriodContext.Provider value={{ matchPeriod, setMatchPeriod }}>
      {children}
    </MatchPeriodContext.Provider>
  )
}
