import { useCallback, useMemo, useState } from 'react'

export type FilterType = 'input' | 'toggle' | 'boolean' | 'array' | 'set'

export type FilterValues = boolean | string | string[] | Set<string> | undefined

export type FilterElement = {
  type: FilterType
  default?: string | boolean | string[] | Set<string>
}

export type FilterParams<Values extends string> = {
  [key in Values]: FilterElement
}

export type FilterSetData<Values> = {
  type: Values
  value: FilterValues
}

export type FilterData<Values extends string> = {
  [key in Values]: FilterValues
}

export const useFilter = <Values extends string>(
  params: FilterParams<Values>
) => {
  const [filterInfo, setFilterInfo] = useState<FilterData<Values>>(
    Object.entries<FilterElement>(params).reduce(
      (filterData, [key, paramsData]) => {
        return {
          ...filterData,
          [key]: paramsData.default,
        }
      },
      {} as FilterData<Values>
    )
  )

  const handleChangeSet = (value: FilterValues, set: FilterValues) => {
    if (
      typeof value !== 'string' ||
      typeof set === 'string' ||
      typeof set === 'undefined' ||
      typeof set === 'boolean' ||
      Array.isArray(set)
    ) {
      return set
    }

    if (set.has(value)) {
      const newEvents = new Set(set)
      newEvents.delete(value)
      return newEvents
    } else {
      return new Set([...Array.from(set), value])
    }
  }

  const info = useMemo(() => filterInfo, [filterInfo])

  const setFilterData = useCallback(
    ({ type, value }: FilterSetData<Values>) => {
      const typeOfChange = Object.entries(params).find(([paramKey]) => {
        return paramKey === type
      })

      if (typeOfChange) {
        const [key, paramsData] = typeOfChange as [Values, FilterElement]

        switch (paramsData.type) {
          case 'input':
          case 'boolean':
          case 'array':
            setFilterInfo(prev => ({
              ...prev,
              [key]: value,
            }))
            break

          case 'toggle':
            setFilterInfo(prev => ({
              ...prev,
              [key]: prev[key] === value ? undefined : value,
            }))
            break

          case 'set':
            setFilterInfo(prev => ({
              ...prev,
              [key]: handleChangeSet(value, prev[key]),
            }))
            break

          default:
            break
        }
      }
    },
    [params]
  )

  const handleResetFilters = useCallback(() => {
    setFilterInfo(
      Object.entries<FilterElement>(params).reduce(
        (filterData, [key, paramsData]) => {
          return {
            ...filterData,
            [key]: paramsData.default,
          }
        },
        {} as FilterData<Values>
      )
    )
  }, [params])

  return {
    setFilterData,
    handleResetFilters,
    filterInfo: info,
  }
}
