import * as yup from 'yup'

export const teamPlayerInjurySchema = yup.object().shape({
  injury: yup
    .string()
    .required('components:teamPlayers.modal.injury.error.injury'),
  dateInjury: yup
    .string()
    .required('components:teamPlayers.modal.injury.error.dateInjury.required')
    .test(
      'is-valid',
      'components:teamPlayers.modal.injury.error.dateInjury.invalid',
      value => {
        const yesterday = new Date()
        yesterday.setDate(new Date().getDate() - 1)

        return new Date(value ?? '') < yesterday
      }
    ),
  rehabPeriod: yup
    .string()
    .required('components:teamPlayers.modal.injury.error.rehabPeriod'),
  rehabPeriodMin: yup
    .number()
    .typeError(
      'components:teamPlayers.modal.injury.error.rehabPeriodMin.required'
    )
    .required(
      'components:teamPlayers.modal.injury.error.rehabPeriodMin.required'
    )
    .min(0, 'components:teamPlayers.modal.injury.error.rehabPeriodMin.invalid'),

  rehabPeriodMax: yup
    .number()
    .typeError(
      'components:teamPlayers.modal.injury.error.rehabPeriodMax.required'
    )
    .required(
      'components:teamPlayers.modal.injury.error.rehabPeriodMax.required'
    )
    .min(0, 'components:teamPlayers.modal.injury.error.rehabPeriodMax.invalid'),
  injuryStatus: yup
    .string()
    .required('components:teamPlayers.modal.injury.error.injuryStatus'),
})
