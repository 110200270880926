import {
  FC,
  MouseEvent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { format } from 'date-fns'
import { useDrag } from 'react-dnd'
import LinesEllipsis from 'react-lines-ellipsis'
import useDownloader from 'react-use-downloader'
// hooks
import { useTranslation } from 'react-i18next'
import { useReduxDispatch } from 'modules/core/hooks'
import { useAssetDelete } from 'modules/asset/hooks'
import { useOrganizationContext } from 'modules/organization/hooks'
// components
import {
  ResourceControls,
  IconButton,
  Menu,
  MenuItem,
} from 'modules/core/components'
import { ClipEditModal } from 'modules/asset/components'
import { TransferResourceModal } from 'modules/resource/components'
// constants
import { defaultIconName } from 'modules/core/constants'
import { DragItem } from 'modules/core/constants'
// utils
import { convertSecondsToTime } from 'modules/core/utils'
import { eventIndexToIconDictionary } from 'modules/core/constants'
// redux
import { setMediaItem } from 'modules/video-player/redux'
// context
import { VideoPlayerContext } from 'modules/video-player/context'

import { ClipPreviewProps } from './ClipPreview.interface'
import {
  ClipDescription,
  ClipDescriptionWrapper,
  ClipInfoItem,
  ClipInfoWrapper,
  Root,
  Tag,
  TagWrapper,
  Title,
  CollapseControlsWrapper,
  EventAndStatusIconWrapper,
  MainInfoWrapper,
  ClipDescriptionInfo,
  TitleRow,
  EventIcon,
  FlexSpaceConsumer,
  PendingOverlay,
  PendingIcon,
  DownloadWrapper,
  DownloadProgress,
} from './ClipPreview.styled'
import { Organization } from '@sporttotal-tv/dip-coaching-client'

export const ClipPreview: FC<ClipPreviewProps> = ({ clip, active = false }) => {
  const assetDelete = useAssetDelete()
  const { activeOrganizationId, organizations } = useOrganizationContext()

  const dispatch = useReduxDispatch()
  const { t } = useTranslation()
  const { percentage, download, cancel, isInProgress } = useDownloader()

  const { playerId } = useContext(VideoPlayerContext)
  const [editModalOpen, setEditModalOpen] = useState(false)

  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization>()

  const [transferResourceAnchorRef, setTransferResourceAnchorRef] =
    useState<HTMLElement>()

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DragItem.MATCH_ASSET,
      item: clip,
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    []
  )

  const handleDelete = useCallback(
    () => assetDelete.mutate(clip.id),
    [clip.id, assetDelete]
  )

  const clipUrl = useMemo(
    () => clip.media_locator?.url,
    [clip.media_locator?.url]
  )

  const canTransfer = useMemo(
    () => organizations.length && !activeOrganizationId,
    [activeOrganizationId, organizations.length]
  )

  const handleDownload = useCallback(() => {
    if (clipUrl) {
      download(clipUrl, `${clip.title}.mp4`)
    }
  }, [clipUrl, clip, download])

  const handleEditOpen = useCallback(() => setEditModalOpen(true), [])
  const handleEditClose = useCallback(() => setEditModalOpen(false), [])

  const handleTransferOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) =>
      setTransferResourceAnchorRef(event.target as HTMLElement),
    []
  )

  const handleTransferClose = useCallback(() => {
    setTransferResourceAnchorRef(undefined)
    setSelectedOrganization(undefined)
  }, [])

  const handlePlay = useCallback(() => {
    if (!clip) return

    dispatch(
      setMediaItem({
        id: playerId ?? '',
        activeVideoItemId: clip.id,
        activeVideoItemType: 'clip',
        mediaLocator: clip.media_locator,
        title: clip.title,
      })
    )
  }, [clip, dispatch, playerId])

  return (
    <>
      <Root
        active={active}
        isDragging={isDragging}
        ref={clip.status === 'completed' ? drag : undefined}
      >
        <PendingOverlay hidden={clip.status !== 'pending'}>
          <PendingIcon name='pending' />
        </PendingOverlay>
        <EventAndStatusIconWrapper>
          <EventIcon
            name={
              clip.status === 'error'
                ? 'error'
                : eventIndexToIconDictionary[clip.event?.type ?? ''] ??
                  defaultIconName
            }
          />
        </EventAndStatusIconWrapper>
        <MainInfoWrapper>
          <ClipInfoWrapper>
            <ClipInfoItem>
              <TitleRow>
                <Title
                  onClick={clip.status === 'completed' ? handlePlay : undefined}
                  active={clip.status === 'completed'}
                >
                  {clip.title}
                </Title>

                <FlexSpaceConsumer />
                <ResourceControls
                  onDelete={handleDelete}
                  onDownload={Boolean(clipUrl) ? handleDownload : undefined}
                  onEdit={handleEditOpen}
                  onTransfer={canTransfer ? undefined : handleTransferOpen}
                />
              </TitleRow>
              {isInProgress && (
                <DownloadWrapper>
                  <DownloadProgress value={percentage} variant='determinate' />
                  <IconButton name='close-circle' onClick={() => cancel()} />
                </DownloadWrapper>
              )}
              <ClipDescriptionWrapper active={clip.status === 'completed'}>
                <ClipDescription>
                  {format(new Date(+clip.created_at * 1000), 'dd/MM/yy')}
                </ClipDescription>
                <ClipDescription>
                  {convertSecondsToTime(clip.duration)}
                </ClipDescription>
                <ClipDescription>
                  {t('time:lastEdited')}:{' '}
                  {format(new Date(+clip.updated_at * 1000), 'dd/MM/yy')}
                </ClipDescription>
              </ClipDescriptionWrapper>
              <ClipDescriptionInfo active={clip.status === 'completed'}>
                <LinesEllipsis text={clip.description ?? ''} maxLine='3' />
              </ClipDescriptionInfo>
            </ClipInfoItem>
          </ClipInfoWrapper>
          {clip.tags && clip.tags.length > 0 && (
            <CollapseControlsWrapper>
              <TagWrapper>
                {clip.tags.slice(0, 6).map(tag => (
                  <Tag key={tag}>{tag}</Tag>
                ))}
              </TagWrapper>
            </CollapseControlsWrapper>
          )}
        </MainInfoWrapper>
      </Root>
      <ClipEditModal
        clip={clip}
        open={editModalOpen}
        onClose={handleEditClose}
      />
      <Menu
        open={Boolean(transferResourceAnchorRef)}
        onClose={handleTransferClose}
        anchorEl={transferResourceAnchorRef}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {organizations.map(organization => (
          <MenuItem
            key={organization.id}
            onClick={() => setSelectedOrganization(organization)}
          >
            {organization.name}
          </MenuItem>
        ))}
      </Menu>
      <TransferResourceModal
        open={Boolean(selectedOrganization)}
        type='asset'
        resource={clip}
        organization={selectedOrganization as Organization}
        onClose={handleTransferClose}
      />
    </>
  )
}
