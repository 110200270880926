import { styled, Typography } from '@mui/material'
import { Icon } from 'modules/core/components/Icon'
import { IconButton } from 'modules/core/components/IconButton'
import { LayoutItemToolbar } from 'modules/generic/components/ToolbarHeader/ToolbarHeader.styled'

export const Divider = styled('div')`
  height: 14px;
  background-color: ${({ theme }) => theme.palette.primary.light};
  width: 1px;
  margin: 0 5px;
`

export const SelectorContainer = styled('div')`
  display: flex;
  align-items: center;
`

export const PlayerSelectorWrapper = styled('div')`
  margin-right: ${({ theme }) => theme.spacing(1)};
`

export const PlayersToolbarContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1)};
  height: ${({ theme }) => theme.componentToolbarDefaultHeight}px;
`

export const ViewSelectorWrapper = styled('div')`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing(1)};
`

export const ViewName = styled(Typography)`
  white-space: nowrap;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.common.white};
`

export const ViewIcon = styled(Icon)`
  width: 10px;
  height: 10px;
`

export const AnimatedChevronIcon = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'chevronUp',
})<{ chevronUp: boolean }>`
  transition: all 0.3s;
  transform: ${({ chevronUp }) => (chevronUp ? 'rotate(0)' : 'rotate(180deg)')};
`

export const ViewSelectIcon = styled(AnimatedChevronIcon)`
  width: 10px;
  height: 10px;
`

export const Toolbar = styled(LayoutItemToolbar)`
  column-gap: unset;
  height: 24px;
`
