import { Slider, styled } from '@mui/material'
import { MUISliderProps } from './PlayTrack.interface'

export const PlayTrackStyled = styled(
  ({ thumbRef, ...props }: MUISliderProps) => (
    <Slider
      track={false}
      min={0}
      step={1}
      valueLabelDisplay='auto'
      {...props}
      slotProps={{ thumb: { ref: thumbRef } }}
    />
  )
)`
  display: flex;
  width: 100%;
  & .MuiSlider-rail {
    background-color: ${({ theme }) => theme.palette.primary.contrastText};
    height: 1px;
    opacity: 0.8;
  }

  & .MuiSlider-thumb {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.palette.secondary.light : theme.palette.secondary.main};
    width: 12px;
    height: 12px;

    &:after {
      width: 21px;
      height: 21px;
    }
  }

  & .MuiSlider-valueLabel {
    background-color: ${({ theme }) => theme.palette.primary.dark};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`
