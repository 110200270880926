import { InfiniteData, useInfiniteQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import {
  HighlightList,
  ListHighlightListOptions,
} from '@sporttotal-tv/dip-coaching-client'
import { useQueryRetry } from 'modules/core/hooks'
import { getNextPageParamWithLimit } from 'modules/core/utils'
import { HIGHLIGHT_PAGE_LIMIT } from 'modules/highlight/constants'
import { UseQueryOptions } from 'react-query/types/react/types'

export const HIGHLIGHT_LIST_CACHE_KEY = 'highlight-list'

export const useHighlightList = (
  options?: ListHighlightListOptions,
  queryOptions?: Omit<
    UseQueryOptions<
      any,
      unknown,
      InfiniteData<HighlightList>,
      (string | ListHighlightListOptions | undefined)[]
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const handleQueryRetry = useQueryRetry()

  return useInfiniteQuery(
    [HIGHLIGHT_LIST_CACHE_KEY, options],
    ({ pageParam = 0 }) =>
      ApiService.highlight.list({
        ...options,
        page: pageParam,
        page_limit: HIGHLIGHT_PAGE_LIMIT,
      }),
    {
      retry: handleQueryRetry,
      getNextPageParam: getNextPageParamWithLimit(HIGHLIGHT_PAGE_LIMIT),
      ...queryOptions,
    }
  )
}
