import { useCallback, FC } from 'react'
// Hooks
import { useTranslation } from 'react-i18next'
import { useResourceTransfer } from 'modules/resource/hooks'
// Components
import { ConfirmDialog, ConfirmDialogState } from 'modules/core/components'
// Interfaces
import { TransferResourceModalProps } from './TransferResourceModal.interface'

export const TransferResourceModal: FC<TransferResourceModalProps> = ({
  open,
  onClose,
  type,
  resource,
  organization,
}) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'resource',
  })
  const { mutate } = useResourceTransfer()

  const handleTransferResource = useCallback(
    (result: ConfirmDialogState) => {
      if (!resource || !organization) return
      if (!(result === 'SUBMITTED')) return onClose()

      mutate(
        {
          resource: type,
          resource_id: resource.id,
          organization_id: organization.id,
        },
        {
          onSuccess: onClose,
          onError: console.error,
        }
      )
    },
    [mutate, type, resource, organization, onClose]
  )

  if (!resource || !organization) return null

  return (
    <ConfirmDialog
      open={open}
      options={{
        title: t(`modal.transfer.title-${type}`),
        description: t(`modal.transfer.confirm`, {
          resourceName: resource.title || resource.name,
          organizationName: organization.name,
        }),
      }}
      onResult={handleTransferResource}
    />
  )
}
