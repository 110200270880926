import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { AssetList, AssetListParams } from '@sporttotal-tv/dip-coaching-client'
import { UseQueryOptions } from 'modules/core/types'

export const ASSET_LIST_CACHE_KEY = 'asset-list'

export const useAssetList = (
  options?: AssetListParams,
  queryOptions?: UseQueryOptions<
    AssetList,
    (string | AssetListParams | undefined)[]
  >
) => {
  return useQuery(
    [ASSET_LIST_CACHE_KEY, options],
    () => ApiService.asset.list(options),
    {
      ...queryOptions,
    }
  )
}
