import {
  Button,
  css,
  FormControl,
  FormLabel,
  Input,
  Modal,
  styled,
  Typography,
} from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Icon } from 'modules/core/components/Icon'

import { LayoutItemToolbarContainer } from 'modules/layout/components/LayoutItemToolbar/LayoutItemToolbar.styled'
import {
  LayoutItemToolbar,
  LayoutItemToolbarDivider,
} from 'modules/generic/components/ToolbarHeader/ToolbarHeader.styled'
import { AnimatedChevronIcon } from '../Playlist/Playlist.styled'

export const ToolbarContainer = styled(LayoutItemToolbarContainer)`
  position: relative;
  padding: ${({ theme }) => theme.spacing(1.25)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  height: ${({ theme }) => theme.componentToolbarDefaultHeight}px;
`

export const ToolbarTitle = styled(LayoutItemToolbarDivider)`
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  padding-right: ${({ theme }) => theme.spacing(0.5)};
  flex: 1;
`

export const ToolbarActions = styled(LayoutItemToolbarDivider, {
  shouldForwardProp: prop => prop !== 'full',
})<{ full?: boolean }>`
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  flex: 1;

  ${({ full }) =>
    full &&
    css`
      flex-grow: 5;
    `}
`

export const ToolbarControlsWrapper = styled(LayoutItemToolbarDivider)`
  display: flex;
  justify-content: end;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  padding-right: ${({ theme }) => theme.spacing(0.5)};
  flex: 1;
`

export const Toolbar = styled(LayoutItemToolbar)`
  column-gap: unset;
  height: 24px;
`

export const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalContent = styled('div')`
  width: 30%;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  position: absolute;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 20%);
  z-index: 1;
  padding: ${({ theme }) => theme.spacing(1.5, 1)};
  transition: padding 0.3s, opacity 0.3s, transform 0s 0s;
`

export const ModalHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`

export const ModalTitleWrapper = styled('div')`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(1.25)};
`

export const ModalTitle = styled(Typography)`
  font-size: 14px;
`

export const FormWrapper = styled('div')`
  padding: ${({ theme }) => theme.spacing(0, 3, 0, 1.5)};
`

export const InputWrapper = styled('div')`
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(1.75)};
  margin-bottom: ${({ theme }) => theme.spacing(1.75)};
`

export const Label = styled(FormLabel)`
  font-size: 12px;
  text-align: right;
  padding: 10px 0;
  position: relative;
  width: 25%;
`

export const FullWidthFormControl = styled(FormControl)`
  width: 100%;
`

export const InfoErrorIcon = styled(ErrorOutlineIcon, {
  shouldForwardProp: prop => prop !== 'hidden',
})<{ hidden?: boolean }>`
  position: absolute;
  right: 0;
  transform: translate(calc(100% + 2px), 100%);
  fill: ${({ theme }) => theme.palette.secondary.main};
  width: 12px;
  height: 12px;
  cursor: ${({ hidden }) => (hidden ? 'default' : 'pointer')};
  transition: 0.3s;
  opacity: ${({ hidden }) => (hidden ? '0' : '1')};
`

export const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: end;
  align-items: end;
  margin-top: ${({ theme }) => theme.spacing(4)};
`

export const SaveButton = styled(Button)`
  font-size: 14px;
  box-shadow: none;
  padding: 0 ${({ theme }) => theme.spacing(1.5)};
  border-radius: 0;
  text-transform: capitalize;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
`

export const SaveIcon = styled(Icon)`
  height: 11px;
  width: 11px;

  & path {
    fill: ${({ theme }) => theme.palette.common.white};
  }
`

export const InputText = styled(Input)`
  background-color: ${({ theme }) => theme.palette.darkest};
  padding: 0;

  & .MuiInput-input {
    padding: 8px;
    font-size: 14px;
  }
`

export const EventSelectorWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'disabled',
})<{ disabled: boolean }>`
  flex: 1;
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  color: ${({ theme }) => theme.palette.primary.light};
  opacity: ${({ disabled }) => disabled && '.5'};
  height: 26px;
`

export const EventSelectIcon = styled(AnimatedChevronIcon)`
  width: 10px;
  height: 10px;
`
