import { useCallback, useEffect, useRef, useState } from 'react'

export const usePageScrolling = (bottomPadding: number = 0) => {
  const [isBottom, setIsBottom] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const container = containerRef.current
    function handleScroll() {
      if (container) {
        const { scrollTop, scrollHeight, clientHeight } = container
        const isEnd =
          clientHeight === scrollHeight ||
          scrollTop + clientHeight >= scrollHeight - bottomPadding
        setIsBottom(isEnd)
      }
    }

    if (container) {
      container.addEventListener('scroll', handleScroll)
    }

    handleScroll()

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [bottomPadding])

  const resetIsBottom = useCallback(() => setIsBottom(false), [])

  return { containerRef, isBottom, resetIsBottom }
}
