import React from 'react'
import { styled } from '@mui/material'

const LineDivisorContainer = styled('div')`
  border-top: 1px solid #dadce0;
`

const LineDivisorElement = styled('div')`
  height: 60px;
  &:after {
    content: '';
    border-bottom: 1px solid #dadce0;
    position: absolute;
    width: 100%;
    margin-top: -1px;
    z-index: 3;
    pointer-events: none;
  }
`

const LineDivisor = () => {
  return (
    <LineDivisorContainer>
      {Array.from(Array(24).keys()).map((_, ix: number) => (
        <LineDivisorElement
          key={`time-line-divisor-${ix}`}
          data-group='time-line'
        />
      ))}
    </LineDivisorContainer>
  )
}

export default LineDivisor
