import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import {
  CreateFramesPresentationBody,
  FramesPresentation,
  FrameList,
} from '@sporttotal-tv/dip-coaching-client'
import { MutateContext } from 'modules/core/types/mutate-context.type'
import { FRAME_LIST_CACHE_KEY } from 'modules/frame/hooks/useFrameList'
import { placeholderFrame } from 'modules/frame/constants'

export const FRAME_PRESENTATION_CREATE_CACHE_KEY = 'frame-presentation-create'

export const useFramePresentationCreate = () => {
  const queryClient = useQueryClient()

  return useMutation<
    FramesPresentation,
    Error,
    CreateFramesPresentationBody,
    MutateContext<any>
  >(
    FRAME_PRESENTATION_CREATE_CACHE_KEY,
    (params: CreateFramesPresentationBody) =>
      ApiService.frame.createPresentation(params),
    {
      onMutate: async data => {
        await queryClient.cancelQueries(FRAME_LIST_CACHE_KEY, { exact: false })

        const previousData =
          queryClient.getQueriesData<FrameList>(FRAME_LIST_CACHE_KEY)

        if (previousData) {
          queryClient.setQueriesData<FrameList | undefined>(
            FRAME_LIST_CACHE_KEY,
            oldData => {
              if (!oldData) return

              return {
                total: oldData.total,
                results: [...oldData.results, placeholderFrame(data)],
              }
            }
          )
        }

        return { previousData: [...previousData] }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData(prevData[0], prevData[1])
          )
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(FRAME_LIST_CACHE_KEY, { exact: false })
      },
    }
  )
}
