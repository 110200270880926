import { useMutation, useQueryClient } from 'react-query'
import { ApiService } from 'services/Api.service'
import {
  CreatePlayerBody,
  Player,
  PlayerList,
} from '@sporttotal-tv/dip-coaching-client'
import { TEAM_PLAYER_LIST_CACHE_KEY } from 'modules/team-player/hooks'
import { MutateContext } from 'modules/core/types/mutate-context.type'
import { InfiniteQuery } from 'modules/core/types'
import { placeholderTeamPlayer } from 'modules/team-player/constants'

export const TEAM_PLAYER_CREATE_CACHE_KEY = 'team-player-create'

export const useTeamPlayerCreate = () => {
  const queryClient = useQueryClient()

  return useMutation<
    Player,
    Error,
    CreatePlayerBody,
    MutateContext<InfiniteQuery<PlayerList>>
  >(
    TEAM_PLAYER_CREATE_CACHE_KEY,
    (params: CreatePlayerBody) => ApiService.teamPlayer.create(params),
    {
      onMutate: async data => {
        await queryClient.cancelQueries(TEAM_PLAYER_LIST_CACHE_KEY, {
          exact: false,
        })

        const previousData = queryClient.getQueriesData<
          InfiniteQuery<PlayerList>
        >(TEAM_PLAYER_LIST_CACHE_KEY)

        if (previousData) {
          queryClient.setQueriesData<InfiniteQuery<PlayerList> | undefined>(
            TEAM_PLAYER_LIST_CACHE_KEY,
            oldData =>
              oldData && {
                pageParams: oldData.pageParams,
                pages: oldData.pages.map(
                  (page, pageIndex) =>
                    page && {
                      total: page.total + 1,
                      results:
                        pageIndex + 1 === oldData.pages.length
                          ? [...page.results, placeholderTeamPlayer(data)]
                          : page.results,
                    }
                ),
              }
          )
        }

        return { previousData }
      },
      onError: (err, variables, context) => {
        if (context?.previousData) {
          context?.previousData.forEach(prevData =>
            queryClient.setQueriesData(prevData[0], prevData[1])
          )
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(TEAM_PLAYER_LIST_CACHE_KEY, {
          exact: false,
        })
      },
    }
  )
}
