import { HttpResponse } from '@sporttotal-tv/dip-coaching-client'
import { ResponseError } from 'modules/core/types'

const isApiError = (data: any): data is ResponseError =>
  data instanceof Object &&
  'message' in data &&
  typeof data.message === 'string'

type ControllerRoute = (...args: any) => Promise<HttpResponse<any>>

export const transformApi = <F extends ControllerRoute>(func: F) => {
  return async (...params: Parameters<F>) => {
    const response = await func(...(Array.from(params) ?? []))

    if (response.error) throw response.error
    if (isApiError(response.data)) throw response.data.message

    return response.data as Awaited<ReturnType<F>>['data']
  }
}

export const transformApiResults =
  <Params, Response>(func: Function) =>
  async (params?: Params): Promise<Response> => {
    const response: HttpResponse<Response | ResponseError> = await func(
      params ?? {}
    )

    if (response.error) throw response.error
    if (isApiError(response.data)) throw response.data.message

    return response.data as Response
  }

export const transformIdApiResults =
  <Params, Response>(func: Function) =>
  async (id: string, params?: Params): Promise<Response> => {
    const response: HttpResponse<Response | ResponseError> = await func(
      id,
      params ?? {}
    )

    if (response.error) throw new Error('Unexpected error')
    if (isApiError(response.data)) throw response.data.message

    return response.data as Response
  }
