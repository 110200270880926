import { useEffect, useRef, useContext, useMemo, useState } from 'react'
import {
  TransformWrapper,
  TransformComponent,
} from '@kokarn/react-zoom-pan-pinch'
// Hooks
import { useUserStorage } from 'modules/user/hooks'
import { useAnalyserPlayerTimestamp } from 'modules/analyser/hooks'
import { useReduxDispatch, useReduxSelector } from 'modules/core/hooks'
import { useTelestrationId } from 'modules/telestration/hooks'
// Redux
import {
  selectTelestrationActiveData,
  selectTool,
} from 'modules/telestration/redux'
import {
  removePlayer,
  selectIsFullscreen,
  setVideoPlayer,
  setIsFullscreen,
  selectVideoPlayerById,
  selectMediaLocator,
  selectIsKeyboardControlsEnabled,
} from 'modules/video-player/redux'
import { selectCurrentLayout, selectLayoutKeys } from 'modules/layout/redux'
// Context
import { ComponentInfoContext } from 'modules/generic/context'
import { VideoPlayerContext } from 'modules/video-player/context'
// Components
import {
  VideoPlayerControls,
  VideoPlayerToolbar,
} from 'modules/video-player/components'
import { IconButton } from 'modules/core/components'
import {
  Root,
  PlayerWrapper,
  transformStyles,
  ControlsWrapper,
  FillSpace,
} from './VideoPlayer.styled'
// Services
import { ContentsDataService } from 'services/PlayersData.service'
// types
import { TelestrationTool } from 'modules/telestration/types'
import { MediaPlayer } from 'modules/media-render/types/media-render.types'
// context
import { VideoPlayersContext } from 'modules/video-player/context'
// Utils
import { getToolIcon } from 'modules/telestration/utils'
// constants
import { videoPlayerRenderComponent } from 'modules/video-player/constants'

export const VideoPlayer = () => {
  const componentRootRef = useRef<HTMLDivElement>(null)
  const playerRef = useRef<MediaPlayer>(null)

  const {
    addPlayer: addPlayerToPlayersContext,
    removePlayer: removePlayerFromPlayersContext,
  } = useContext(VideoPlayersContext)

  const { componentId } = useContext(ComponentInfoContext)
  const dispatch = useReduxDispatch()
  const telestrationId = useTelestrationId()

  const { setPlayer: setPlayerContext, removePlayer: removePlayerContext } =
    useContext(VideoPlayerContext)

  const tool = useReduxSelector(state => selectTool(state, telestrationId))

  const videoPlayerData = useReduxSelector(state =>
    selectVideoPlayerById(state, componentId)
  )

  const mediaLocator = useReduxSelector(state =>
    selectMediaLocator(state, componentId ?? '')
  )

  const isFullscreen = useReduxSelector(state =>
    selectIsFullscreen(state, componentId)
  )
  const { activeShape } = useReduxSelector(state =>
    selectTelestrationActiveData(state, telestrationId)
  )
  const currentTimestamp = useAnalyserPlayerTimestamp()

  const [isLoadingShape, setIsLoadingShape] = useState<boolean>(false)
  const layouts = useReduxSelector(selectCurrentLayout)
  const { layoutTypesKey } = useReduxSelector(selectLayoutKeys)
  const userLayoutTypes = useUserStorage({ keys: [layoutTypesKey] })
  const cachedPlayer = useMemo(() => {
    return ContentsDataService.getContentData().find(
      player => player.mediaRenderComponentId === componentId
    )
  }, [componentId])

  const isTelestrationToolAvailable = useMemo(() => {
    const layoutIds = layouts.map(layout => layout.i)

    const layoutTypesInfo = userLayoutTypes?.data?.[layoutTypesKey]

    if (!layoutTypesInfo) return false

    return Object.entries(layoutTypesInfo).some(
      ([key, value]) => layoutIds.includes(key) && value === 'TELESTRATION'
    )
  }, [layouts, userLayoutTypes, layoutTypesKey])

  const isKeyboardControlEnabled = useReduxSelector(state =>
    selectIsKeyboardControlsEnabled(state, componentId)
  )

  // TODO: move PlayersDataService to the layoutCache, currently there are issues with this, so it might take time
  useEffect(() => {
    if (!videoPlayerData || videoPlayerData.id !== componentId) return
    const componentPlayer = ContentsDataService.getContentData().find(
      player => player.mediaRenderComponentId === componentId
    )

    if (!componentPlayer) {
      ContentsDataService.addContentItem({
        mediaRenderComponentId: videoPlayerData.id,
        mediaLocator: videoPlayerData.mediaLocator,
        currentTime: currentTimestamp,
        activeVideoItemId: videoPlayerData.activeVideoItemId,
        activeVideoItemType: videoPlayerData.activeVideoItemType,
        matchType: videoPlayerData.matchType,
        title: videoPlayerData.title,
      })

      return
    }

    ContentsDataService.updateContentItem(componentId, {
      mediaLocator: videoPlayerData.mediaLocator,
      currentTime: currentTimestamp,
      activeVideoItemId: videoPlayerData.activeVideoItemId,
      activeVideoItemType: videoPlayerData.activeVideoItemType,
      matchType: videoPlayerData.matchType,
      title: videoPlayerData.title,
    })
  }, [videoPlayerData, componentId, currentTimestamp])

  // initial add player and player data when components rendered
  useEffect(() => {
    if (playerRef.current && componentId) {
      const componentPlayer = ContentsDataService.getContentData().find(
        player => player.mediaRenderComponentId === componentId
      )

      playerRef.current.currentTime = componentPlayer?.currentTime ?? 0

      dispatch(
        setVideoPlayer({
          id: componentId,
          isFullscreen: false,
          linkedComponents: [],
          mediaLocator: componentPlayer?.mediaLocator,
          mediaRenderComponentId: componentId,
          currentTime: componentPlayer?.currentTime ?? 0,
          title: componentPlayer?.title,
          activeVideoItemId: componentPlayer?.activeVideoItemId,
          activeVideoItemType: componentPlayer?.activeVideoItemType,
          matchType: componentPlayer?.matchType,
        })
      )
      setPlayerContext(playerRef.current, componentId)
      addPlayerToPlayersContext(playerRef.current, componentId)
    }

    return () => {
      dispatch(removePlayer({ id: componentId }))
      removePlayerContext()
      removePlayerFromPlayersContext(componentId)
    }
  }, [
    dispatch,
    playerRef,
    componentId,
    setPlayerContext,
    removePlayerContext,
    addPlayerToPlayersContext,
    removePlayerFromPlayersContext,
  ])

  useEffect(() => {
    if (isFullscreen) componentRootRef.current?.requestFullscreen()
  }, [isFullscreen])

  useEffect(() => {
    if (componentRootRef.current) {
      componentRootRef.current.onfullscreenchange = () => {
        dispatch(
          setIsFullscreen({
            id: componentId,
            isFullscreen:
              document.fullscreenElement === componentRootRef.current,
          })
        )
      }
    }
  }, [dispatch, componentId])

  const isTelestrationActive = tool !== null || activeShape !== null
  const isPanningDisabled = isTelestrationToolAvailable && isTelestrationActive

  // TODO: remove ternary operator
  const MediaRendererComponent = mediaLocator
    ? videoPlayerRenderComponent[mediaLocator.type]
    : cachedPlayer?.mediaLocator
    ? videoPlayerRenderComponent[cachedPlayer?.mediaLocator.type]
    : videoPlayerRenderComponent['VIDEO']

  return (
    <Root>
      <VideoPlayerToolbar />
      <PlayerWrapper
        ref={componentRootRef}
        isLoadingShape={isLoadingShape}
        cursor={
          tool && isTelestrationToolAvailable
            ? getToolIcon[tool as TelestrationTool]
            : undefined
        }
      >
        <TransformWrapper
          panning={{
            disabled: isPanningDisabled,
          }}
          doubleClick={{
            disabled: isPanningDisabled,
          }}
        >
          <TransformComponent
            wrapperStyle={transformStyles}
            contentStyle={transformStyles}
          >
            <MediaRendererComponent
              ref={playerRef}
              setIsLoadingShape={setIsLoadingShape}
              isTelestrationToolAvailable={
                isTelestrationToolAvailable &&
                videoPlayerData?.activeVideoItemType !== 'match'
              }
              isKeyboardControlEnabled={
                !activeShape && isKeyboardControlEnabled
              }
            />
          </TransformComponent>
        </TransformWrapper>
        {isFullscreen && (
          <ControlsWrapper>
            <FillSpace />
            <VideoPlayerControls player={playerRef.current ?? null} />
            <FillSpace>
              <IconButton
                name='fullscreen-exit'
                onClick={() => document?.exitFullscreen()}
              />
            </FillSpace>
          </ControlsWrapper>
        )}
      </PlayerWrapper>
    </Root>
  )
}
