type DefaultList = { total: number; results: any[] }

export const getNextPageParamWithLimit =
  <T>(pageLimit: number) =>
  (lastPage: T & DefaultList, allPages: Array<T & DefaultList>) => {
    const allResults = allPages.reduce(
      (numberOfResults, page) => numberOfResults + (page?.results?.length ?? 0),
      0
    )

    if (allResults >= lastPage.total) {
      return undefined
    }

    return allResults / pageLimit
  }
