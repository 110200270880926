import { v4 as uuid } from 'uuid'
import { Frame, CreateFrameBody } from '@sporttotal-tv/dip-coaching-client'
import { frameMockup } from 'modules/frame/utils'

export const placeholderFrame = (payload?: CreateFrameBody): Frame => ({
  ...frameMockup,
  id: uuid(),
  ...payload,
  media_locator_id: `draft-${uuid()}`,
  parent_media_locator_id: `draft-${uuid()}`,
  media_locator: undefined,
  parent_media_locator: undefined,
})
