import { useQuery } from 'react-query'
import { ApiService } from 'services/Api.service'
import { useQueryRetry } from 'modules/core/hooks'

export const STRIPE_CONFIG_RETRIEVE_CACHE_KEY = 'stripe-config-retrieve'

export const useStripeConfig = () => {
  const handleQueryRetry = useQueryRetry()

  return useQuery(
    [STRIPE_CONFIG_RETRIEVE_CACHE_KEY],
    () => ApiService.stripe.retrieveConfig(),
    {
      retry: handleQueryRetry,
      staleTime: Infinity,
    }
  )
}
