import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'

export const useTabData = () => {
  const location = useLocation()
  // memo
  const tabIndex = useMemo(
    () => location.pathname.lastIndexOf('/'),
    [location.pathname]
  )
  const currentTabPath = useMemo(
    () => location.pathname.slice(tabIndex + 1),
    [location.pathname, tabIndex]
  )

  const rootPath = useMemo(
    () => location.pathname.slice(0, tabIndex),
    [location.pathname, tabIndex]
  )

  return { currentTabPath, rootPath }
}
