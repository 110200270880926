import { useMutation, useQueryClient } from 'react-query'
import { CreateSubscriptionBody } from '@sporttotal-tv/dip-coaching-client'
import { ApiService } from 'services/Api.service'

export const SUBSCRIPTION_CREATE_CACHE_KEY = 'subscription-create'

export const useSubscriptionCreate = () => {
  const queryClient = useQueryClient()

  return useMutation(
    SUBSCRIPTION_CREATE_CACHE_KEY,
    (params: CreateSubscriptionBody) => ApiService.subscription.create(params),
    {
      onSettled: () => {
        queryClient.invalidateQueries(SUBSCRIPTION_CREATE_CACHE_KEY, {
          exact: false,
        })
      },
    }
  )
}
