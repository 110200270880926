import { DEFAULT_EVENT_NAME } from 'modules/event/constants/defaultEventName'
import { assetMockup } from 'modules/asset/utils'
import { Asset, AssetCreatePayload } from '@sporttotal-tv/dip-coaching-client'
import { generateId } from 'modules/core/helpers/generateId'

export const placeholderAsset = (payload?: AssetCreatePayload): Asset => ({
  ...assetMockup,
  id: generateId(),
  ...payload,
  status: 'pending',
  event: {
    id: payload ? payload.event_id : generateId(),
    type: null,
    sport_id: '',
    name: DEFAULT_EVENT_NAME,
    created_at: Date.now(),
    updated_at: Date.now(),
  },
})
