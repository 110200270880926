import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { Player } from '@sporttotal-tv/dip-coaching-client'
// components
import { ActionButton, ModalDialog, TextInput } from 'modules/core/components'
import { TeamPlayerSelector } from 'modules/team-player/components'
// hooks
import { useTError } from 'modules/core/i18n/hooks'
import {
  useTeamPlayerStatsUpdate,
  useTeamPlayerStatsCreate,
} from 'modules/team-player/hooks'
// schema
import { teamPlayerStatsSchema } from 'modules/team-player/schemas'
// types
import { TeamPlayerStatsFormData } from 'modules/team-player/types'

import { StatsModalProps } from './StatsView.interface'
import { PlayerSelectorWrapper } from './StatsView.styled'

const teamPlayerStatsFields: (keyof TeamPlayerStatsFormData)[] = [
  'goals',
  'shots',
  'shotsOnTarget',
  'penaltiesScored',
  'penaltiesTaken',
  'passesCompleted',
  'passesAttempt',
  'mistakes',
  'crossAttempted',
  'crossCompleted',
  'redCard',
  'yellowCard',
]

export const StatsModal: FC<StatsModalProps> = ({
  open,
  onClose,
  title,
  item,
}) => {
  const { t } = useTranslation('components', { keyPrefix: 'teamPlayers' })
  const { tError } = useTError(['components'])
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null)
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<TeamPlayerStatsFormData>({
    resolver: yupResolver(teamPlayerStatsSchema),
    defaultValues: {
      goals: item?.goals,
      shots: item?.shots,
      shotsOnTarget: item?.shots_on_target,
      penaltiesScored: item?.penalties_scored,
      penaltiesTaken: item?.penalties_taken,
      passesCompleted: item?.passes_completed,
      passesAttempt: item?.passes_attempt,
      mistakes: item?.mistake_leading_to_goal,
      crossAttempted: item?.cross_attempted,
      crossCompleted: item?.cross_completed,
      redCard: item?.red_card,
      yellowCard: item?.yellow_card,
    },
  })

  const teamPlayerStatsUpdate = useTeamPlayerStatsUpdate()
  const teamPlayerStatsCreate = useTeamPlayerStatsCreate()

  const onSubmit = (data: TeamPlayerStatsFormData) => {
    if (item) {
      teamPlayerStatsUpdate.mutate(
        {
          id: item.id,
          params: {
            goals: data?.goals,
            shots: data?.shots,
            shots_on_target: data?.shotsOnTarget,
            penalties_scored: data?.penaltiesScored,
            penalties_taken: data?.penaltiesTaken,
            passes_completed: data?.passesCompleted,
            passes_attempt: data?.passesAttempt,
            mistake_leading_to_goal: data?.mistakes,
            cross_attempted: data?.crossAttempted,
            cross_completed: data?.crossCompleted,
            red_card: data?.redCard,
            yellow_card: data?.yellowCard,
          },
        },
        {
          onSuccess: onClose,
        }
      )
      return
    }

    if (selectedPlayer) {
      teamPlayerStatsCreate.mutate(
        {
          player_id: selectedPlayer.id,
          goals: data?.goals,
          shots: data?.shots,
          shots_on_target: data?.shotsOnTarget,
          penalties_scored: data?.penaltiesScored,
          penalties_taken: data?.penaltiesTaken,
          passes_completed: data?.passesCompleted,
          passes_attempt: data?.passesAttempt,
          mistake_leading_to_goal: data?.mistakes,
          cross_attempted: data?.crossAttempted,
          cross_completed: data?.crossCompleted,
          red_card: data?.redCard,
          yellow_card: data?.yellowCard,
        },
        {
          onSuccess: onClose,
        }
      )
    }
  }

  return (
    <ModalDialog open={open} onClose={onClose} title={title}>
      <>
        {!item && (
          <PlayerSelectorWrapper>
            <TeamPlayerSelector
              showError={isSubmitted}
              player={selectedPlayer}
              setPlayer={setSelectedPlayer}
            />
          </PlayerSelectorWrapper>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          {teamPlayerStatsFields.map(field => (
            <TextInput
              key={field}
              type='number'
              label={t(`modal.stats.fields.${field}`)}
              errorMessage={tError(errors[field]?.message)}
              inputProps={{ 'aria-label': field }}
              {...register(field)}
            />
          ))}
          <ActionButton
            type='submit'
            icon='save'
            text={t('actions.save')}
            position='end'
          />
        </form>
      </>
    </ModalDialog>
  )
}
