import { createContext, FC, useCallback, useState } from 'react'
import { Asset } from '@sporttotal-tv/dip-coaching-client'
import {
  IPlaylistPageContext,
  IPlaylistPageContextProvider,
} from 'modules/playlist/context/PlaylistPageContext/PlaylistPageContext.interface'

export const PlaylistPageContext = createContext<IPlaylistPageContext>({
  activeClip: null,
  nextClip: null,
  previousClip: null,
  clipEnded: false,
  setActiveClip: () => {},
  setNextClip: () => {},
  setPreviousClip: () => {},
  setClipEnded: () => {},
})

export const PlaylistPageContextProvider: FC<IPlaylistPageContextProvider> = ({
  children,
}) => {
  const [activeClip, setActiveClip] = useState<Asset | null>(null)
  const [nextClip, setNextClip] = useState<Asset | null>(null)
  const [previousClip, setPreviousClip] = useState<Asset | null>(null)
  const [clipEnded, setClipEnded] = useState<boolean>(false)

  const handleActiveClipChange = useCallback(
    (clip: Asset | null) => {
      setActiveClip(clip)
      setClipEnded(false)
    },
    [setActiveClip, setClipEnded]
  )

  const handleNextClipChange = useCallback(
    (clip: Asset | null) => {
      setNextClip(clip)
      setClipEnded(false)
    },
    [setNextClip, setClipEnded]
  )

  const handlePreviousClipChange = useCallback(
    (clip: Asset | null) => {
      setPreviousClip(clip)
      setClipEnded(false)
    },
    [setPreviousClip, setClipEnded]
  )

  return (
    <PlaylistPageContext.Provider
      value={{
        activeClip,
        nextClip,
        previousClip,
        setNextClip: handleNextClipChange,
        setPreviousClip: handlePreviousClipChange,
        setActiveClip: handleActiveClipChange,
        clipEnded,
        setClipEnded,
      }}
    >
      {children}
    </PlaylistPageContext.Provider>
  )
}
