import { ApiService } from 'services/Api.service'
import { createMutationHookWithBody } from 'modules/core/api/hooks/utils'
import { ADMIN_ORGANIZATION_USER_LIST_CACHE_KEY } from 'modules/organization/hooks/useAdminOrganizationUserList'
import { ORGANIZATION_ADMIN_USER_LIST_CACHE_KEY } from './useOrganizationAdminUserList'

export const [useOrganizationAdminUserDelete] = createMutationHookWithBody(
  ApiService.organizationAdmin.user.delete,
  queryClient => ({
    onSuccess: () => {
      queryClient.invalidateQueries(ORGANIZATION_ADMIN_USER_LIST_CACHE_KEY)
      queryClient.invalidateQueries(ADMIN_ORGANIZATION_USER_LIST_CACHE_KEY)
    },
  })
)
