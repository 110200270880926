import { styled } from '@mui/material'
import { OptionsSelector } from 'modules/core/styled'

export const ToolbarContainer = styled('div')`
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  justify-content: center;
`

export const MatchPeriodOptionsSelector = styled(OptionsSelector)`
  background-color: ${({ theme }) => theme.palette.primary.main};
`
